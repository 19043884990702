import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {BaseModelSamplesViewerProps} from '../homepage-model-samples-viewer.types';
import {HomepageAnnotation} from '../../../../../../../objects/models/homepage.model';
import classes from './homepage-funnel-viewer.module.scss';
import {useTranslation} from 'react-i18next';
import {
  ChartPyramidLightIcon,
  FunnelChartMode,
  GraphIcon,
  Select,
  SwitchAction,
  SwitchActions,
  useLocalStorage,
  useRemoteSourceStated,
} from 'ui-components';
import {getHomepageFunnelDataNetworkRequest} from '../../../../../../../http/homepage.network-requests';
import {
  useHomepageChartConfig,
  useHomepageModelData,
  useModelGranularity,
  useModelSampleTimeframe,
} from '../../../hooks/homepage-summary.hooks';
import TransKeys from 'translations';
import {GenericLoading} from '../../../../../../shared/components/general/generic-loading/generic-loading.component';
import {HomepageModelViewerHeader} from '../components/homepage-model-viewer-header/homepage-model-viewer-header.component';
import {HomepageFunnelChart} from './homepage-funnel-chart/homepage-funnel-chart.component';
import {HomepageGranularityOptions} from '../components/homepage-granularity-options/homepage-granularity-options.component';
import {HomepageTimeframeOptions} from '../components/homepage-timeframe-options/homepage-timeframe-options.component';
import {HomepageChartOptions} from '../components/homepage-chart-options/homepage-chart-options.component';
import {HomepageFunnelOvertimeChart} from './homepage-funnel-overtime-chart/homepage-funnel-overtime-chart.component';
import {PanelKey} from '../../../../../../../constants/panels';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../../../constants/time-formats';
import {PanelsContext} from '../../../../../../../core/contexts/panels.context';
import {transformModelName} from '../../../homepage-summary.utils';
import {HomepageChartEmptyState} from '../components/homepage-chart-empty-state/homepage-chart-empty-state.component';
import {HomepageChartModeOptions} from '../components/homepage-chart-mode-options/homepage-chart-mode-options.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../../../constants/feature-flags';
import {ChartMode} from '../../../homepage-summary.types';
import {useAmplitude} from '../../../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../../constants/amplitude-event';

interface OwnProps extends BaseModelSamplesViewerProps {
  funnelId: number;
  onViewFunnel?: () => void;
  onViewFunnelOpportunities?: () => void;
  className?: string;
  variant?: 'ghost' | 'contained';
}

type AllProps = OwnProps;

enum FunnelViewerMode {
  FUNNEL = 'funnel',
  CHART = 'chart',
}

const HOMEPAGE_FUNNEL_MODE_KEY = 'homepage-funnel-mode';
const HOMEPAGE_FUNNEL_CHART_MODE_KEY = 'homepage-funnel-chart-mode';
const FUNNEL_CHART_MODE_OPTIONS = [
  FunnelChartMode.TOTALS,
  FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP,
  FunnelChartMode.CONVERSION_FROM_INITIAL_STEP,
];

export const HomepageFunnelViewer = (props: AllProps) => {
  const {
    funnelId,
    toDate,
    onViewFunnel,
    onViewFunnelOpportunities,
    configuration,
    variant = 'ghost',
    isLoading: isLoadingFromProps,
    showHeader = true,
    showOptions = true,
    className,
  } = props;
  const {t} = useTranslation();
  const [mode, setMode] = useLocalStorage<FunnelViewerMode>(
    HOMEPAGE_FUNNEL_MODE_KEY,
    FunnelViewerMode.FUNNEL
  );
  const [funnelChartMode, setFunnelChartMode] = useLocalStorage<FunnelChartMode>(
    HOMEPAGE_FUNNEL_CHART_MODE_KEY,
    FunnelChartMode.TOTALS
  );
  const [chartMode, setChartMode] = useState(ChartMode.INCOMPLETE);
  const [reviewedSignalId, setReviewedSignalId] = useState<number | null>(null);
  const {
    source: data,
    exec: getFunnel,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getHomepageFunnelDataNetworkRequest,
  });
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {funnel: funnelFromData, annotations = []} = data || {};
  const funnel = useMemo(() => {
    if (!funnelFromData) {
      return;
    }
    return {
      ...funnelFromData,
      name: transformModelName(funnelFromData),
    };
  }, [funnelFromData]);
  const funnelInstanceId = funnel?.id;
  // Hooks
  const {granularity, setGranularity, granularityOptions} = useModelGranularity(funnel);
  const {reviewedSeries, minSampleDate, maxSampleDate} = useHomepageModelData(funnel, granularity);
  const {chartConfig, setChartConfig} = useHomepageChartConfig();
  const showForecast = useFeatureIsOn(FeatureFlag.HOMEPAGE_PREDICTIONS as any);
  const notify = useAmplitude();
  const {
    filters,
    isCustomRange,
    showAllPartialPoints,
    onTimeframeOptionSelected,
    onCustomDatesSelected,
    onChangeShowPartialPoints,
    isTimeframeSelected,
  } = useModelSampleTimeframe({toDate});
  const onAnnotationsClicked = useCallback(
    (annotations: HomepageAnnotation[]) => {
      openSecondaryPanel(PanelKey.HOMEPAGE_ANNOTATIONS_PANEL, {
        date: moment(annotations[0].timestamp).format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
      });
    },
    [openSecondaryPanel]
  );
  const onChangeMode = useCallback(
    (mode: FunnelViewerMode) => {
      setMode(mode);
      if (mode === FunnelViewerMode.FUNNEL) {
        setReviewedSignalId(null);
      }
    },
    [setMode, setReviewedSignalId]
  );
  const onExploreConversion = useCallback(
    (stepIdx: number) => {
      setReviewedSignalId(funnel.steps[stepIdx + 1]?.id);
      onChangeMode(FunnelViewerMode.CHART);
      if (funnelChartMode === FunnelChartMode.TOTALS) {
        setFunnelChartMode(FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP);
      }
    },
    [setFunnelChartMode, setReviewedSignalId, onChangeMode, funnel, funnelChartMode]
  );
  const modes: SwitchAction[] = useMemo(
    () => [
      {
        helperText: t(TransKeys.HOMEPAGE.FUNNEL_VIEW.MODES.FUNNEL_CHART),
        icon: ChartPyramidLightIcon,
        onClick: () => onChangeMode(FunnelViewerMode.FUNNEL),
        isActive: mode === FunnelViewerMode.FUNNEL,
      },
      {
        helperText: t(TransKeys.HOMEPAGE.FUNNEL_VIEW.MODES.LINE_CHART),
        icon: GraphIcon,
        onClick: () => onChangeMode(FunnelViewerMode.CHART),
        isActive: mode === FunnelViewerMode.CHART,
      },
    ],
    [mode, onChangeMode, t]
  );
  const extraChartActions = useMemo(
    () => [
      {
        title: showAllPartialPoints
          ? t(TransKeys.HOMEPAGE.CHART.ACTIONS.SHOW_FIRST_PARTIAL_POINT)
          : t(TransKeys.HOMEPAGE.CHART.ACTIONS.SHOW_ALL_PARTIAL_POINTS),
        onClick: () => onChangeShowPartialPoints(!showAllPartialPoints),
        hide: isCustomRange,
      },
    ],
    [showAllPartialPoints, onChangeShowPartialPoints, isCustomRange, t]
  );
  const funnelModeOptions = useMemo(
    () =>
      FUNNEL_CHART_MODE_OPTIONS.map(m => ({
        label: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.MODES[m.toUpperCase()]),
        value: m,
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.MODES[`${m.toUpperCase()}_HELPER`]
        ),
      })),
    [t]
  );
  const onChangeChartMode = useCallback(
    (mode: ChartMode) => {
      setChartMode(mode);
      if (mode === ChartMode.PREDICTED) {
        notify(AmplitudeEvent.HOMEPAGE_FORECAST_CLICKED);
      }
    },
    [setChartMode, notify]
  );

  useEffect(() => {
    funnelId &&
      getFunnel({
        funnelId,
        filters,
      });
  }, [getFunnel, funnelId, configuration?.anomalyMode, configuration?.anomalyThreshold, filters]);

  const showEmptyState = !reviewedSeries || reviewedSeries.samples.length === 0;
  if (funnel && showEmptyState) {
    return (
      <HomepageChartEmptyState
        text={t(TransKeys.HOMEPAGE.SAMPLING_IN_PROGRESS_EMPTY_STATE)}
        loading
      />
    );
  }

  if (!funnel || isLoading) {
    return <GenericLoading />;
  }

  const renderChart = () => {
    if (mode === FunnelViewerMode.FUNNEL) {
      return (
        <HomepageFunnelChart
          funnel={funnel}
          funnelChartMode={funnelChartMode}
          onExploreConversion={onExploreConversion}
        />
      );
    }
    if (mode === FunnelViewerMode.CHART) {
      return (
        <HomepageFunnelOvertimeChart
          key={funnelInstanceId}
          funnel={funnel}
          reviewedSignalId={reviewedSignalId}
          granularity={granularity}
          chartConfig={chartConfig}
          annotations={annotations}
          onAnnotationsClicked={onAnnotationsClicked}
          funnelChartMode={funnelChartMode}
          chartMode={chartMode}
          configuration={configuration}
        />
      );
    }
  };

  return (
    <div
      className={classNames(
        classes.HomepageFunnelViewer,
        variant === 'contained' && classes.Contained,
        className
      )}
    >
      {isLoading && !isLoadingFromProps && <GenericLoading />}
      {funnel && !showEmptyState && showHeader && (
        <HomepageModelViewerHeader
          model={funnel}
          onView={onViewFunnel}
          onViewOpportunities={onViewFunnelOpportunities}
          // onViewSegmentation={onViewSegmentation}
          configuration={configuration}
        />
      )}
      {showOptions && (
        <div className={classes.Options}>
          <div className={classes.Right}>
            {mode === FunnelViewerMode.CHART && (
              <>
                <HomepageGranularityOptions
                  granularity={granularity}
                  onChange={setGranularity}
                  options={granularityOptions}
                  disabled={isLoading}
                />
                {showForecast &&
                  reviewedSeries.predictedSamples &&
                  reviewedSeries.predictedSamples.length > 0 && (
                    <HomepageChartModeOptions
                      mode={chartMode}
                      onChange={onChangeChartMode}
                      series={reviewedSeries}
                      isPercentageValue={true}
                    />
                  )}
              </>
            )}
            <Select
              options={{options: funnelModeOptions}}
              value={funnelChartMode}
              onChange={v => setFunnelChartMode(v as FunnelChartMode)}
              dropdownButtonClassName={classes.ModeSelection}
              capitalize={false}
              clearable={false}
              searchable={false}
              sortValues={false}
            />
          </div>
          <div className={classes.Left}>
            <SwitchActions actions={modes} size={'small'} />
            <HomepageTimeframeOptions
              filters={filters}
              isCustomRange={isCustomRange}
              onTimeframeOptionSelected={onTimeframeOptionSelected}
              onCustomDatesSelected={onCustomDatesSelected}
              isTimeframeSelected={isTimeframeSelected}
              minSampleDate={minSampleDate}
              maxSampleDate={maxSampleDate}
              disabled={isLoading}
            />
            {mode === FunnelViewerMode.CHART && (
              <HomepageChartOptions
                chartConfig={chartConfig}
                onChange={setChartConfig}
                extraActions={extraChartActions}
              />
            )}
          </div>
        </div>
      )}
      <div className={classes.Chart}>{renderChart()}</div>
    </div>
  );
};
