import * as React from 'react';
import classes from './info-box.module.scss';
import classNames from 'classnames';
import {CSSProperties, useMemo} from 'react';

interface OwnProps {
  title: string;
  children?: any;
  actions?: JSX.Element;
  onClick?: () => void;
  className?: string;
  color?: string;
  style?: CSSProperties;
  isActive?: boolean;
  icon?: any;
}

type AllProps = OwnProps;

export const InfoBox: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    children,
    actions,
    onClick,
    className,
    color,
    isActive,
    icon: Icon,
    style: style_,
  } = props;

  const style: CSSProperties = useMemo(
    () => ({
      ...style_,
      borderColor: isActive ? color : 'transparent',
    }),
    [style_, isActive, color]
  );

  return (
    <div style={style} className={classNames(classes.KPIInfoBox, className)}>
      {Icon && (
        <div className={classes.RootIconWrapper}>
          <div className={classes.IconWrapper}>
            <Icon className={classes.Icon} />
          </div>
        </div>
      )}
      <div className={classNames(classes.KPIInfoBoxBody)}>
        <div className={classes.TitleWrapper}>
          <div
            onClick={onClick}
            className={classNames(classes.Title, onClick && classes.Clickable)}
          >
            {title}
          </div>
          <div className={classes.Actions}>{actions}</div>
        </div>
        <div className={classes.Children}>{children}</div>
      </div>
    </div>
  );
};

InfoBox.defaultProps = {
  actions: null,
};
