import {createContext, useMemo} from 'react';
import * as React from 'react';
import {InteractionPostHandler, PostMessage} from './interaction-context.types';

export interface IInteractionContext {
  postMessage: PostMessage;
}

export const InteractionContext = createContext<IInteractionContext>({
  postMessage: undefined,
});

interface OwnProps {
  handler: InteractionPostHandler;
  children: any;
}

export const InteractionProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {children, handler} = props;

  const contextValue = useMemo(
    () => ({
      postMessage: handler,
    }),
    [handler]
  );

  return <InteractionContext.Provider value={contextValue}>{children}</InteractionContext.Provider>;
};
