import * as React from 'react';
import classNames from 'classnames';
import classes from './query-actions.module.scss';
import {IconButton} from '../../../../simple/controls/icon-button/icon-button.component';
import {Tooltip} from '@material-ui/core';
import {useMemo} from 'react';
import {get} from 'lodash';
import {
  ActionItem,
  ActionsDropdown,
} from '../../../../simple/controls/actions-dropdown/actions-dropdown.component';
import {MoreIcon} from '../../../../simple/controls/icons/icons.component';

export interface QueryIconAction {
  label: string;
  onClick: () => void;
  icon: any;
  hidden?: boolean;
  extended?: boolean;
}

export interface RenderQueryAction {
  render?: () => any;
  hidden?: boolean;
}

export type QueryAction = QueryIconAction | RenderQueryAction;

interface OwnProps {
  actions: QueryAction[];
  className?: string;
  iconButton?: boolean;
  show?: boolean;
}

type AllProps = OwnProps;

export const QueryActions: React.FC<AllProps> = (props: AllProps) => {
  const {actions: actions_, className, iconButton, show} = props;
  const actions = useMemo(() => actions_.filter(a => !a.hidden), [actions_]);
  const renderedActions = useMemo(() => actions.filter(a => !get(a, 'extended')), [actions]);
  const extendedActions: ActionItem[] = useMemo(
    () =>
      actions
        .filter(a => get(a, 'extended') === true)
        .map((a: any) => ({
          title: a.label,
          onClick: a.onClick,
        })),
    [actions]
  );

  const renderAction = (a: QueryAction, idx: number) => {
    const {label, onClick, icon: Icon} = a as QueryIconAction;

    if ('render' in a) {
      return <React.Fragment key={`render_${idx}`}>{a.render()}</React.Fragment>;
    }

    return (
      <React.Fragment key={`${label}_${idx}`}>
        {iconButton ? (
          <IconButton
            className={classes.Action}
            tooltipText={label}
            onClick={onClick}
            icon={Icon}
          />
        ) : (
          <Tooltip title={label} placement={'top'}>
            <Icon className={classes.Icon} onClick={onClick} />
          </Tooltip>
        )}
      </React.Fragment>
    );
  };

  const renderExtendedActions = () => {
    const moreProps: any = {};

    if (!iconButton) {
      moreProps.buttonComponent = (props: any) => (
        <Tooltip title={props.label} placement={'top'}>
          <MoreIcon className={classes.Icon} onClick={props.onClick} />
        </Tooltip>
      );
    }

    return (
      <ActionsDropdown
        className={classes.Action}
        label={'Options'}
        actions={extendedActions}
        icon={MoreIcon}
        iconDropdown
        {...moreProps}
      />
    );
  };

  return (
    <div className={classNames(classes.QueryActions, show && classes.Show, className)}>
      {renderedActions.map((a, idx) => renderAction(a, idx))}
      {extendedActions.length > 0 && renderExtendedActions()}
    </div>
  );
};

QueryActions.defaultProps = {
  iconButton: true,
  show: true,
};
