import {
  ColumnType,
  CommandType,
  DocumentElement,
  DocumentElementType,
  InterestType,
  RCASegmentationFigureOptions,
  SmartTableFigure,
  SmartTableFigureColumn,
} from '../../../../../types';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import * as React from 'react';
import {useContext, useMemo} from 'react';
import {VersusWithUpliftDisplay} from '../../../../shared/display-columns/versus-with-uplift-display.component';
import {ImpactPPDisplay} from '../../../../shared/display-columns/impact-pp-display.component';
import {MAX_INTEREST_CHIP_TO_SHOW} from '../rca-segmentation-viewer.component';
import {ModelType} from '../../../../../../../../consts/model-type';
import pluralize from 'pluralize';
import {ExplainabilityLabelDisplay} from '../../components/explainability-label-display/explainability-label-display.component';
import {TableMutedTest} from '../../../../base-viewers/smart-table-viewer/components/table-muted-text.component';
import {InterestChipDisplay} from '../components/segment-explainers-table/components/explainers-table/components/interest-chip-display/interest-chip-display.component';
import {take} from 'lodash';
import {RCAFigureViewerContext} from '../../rca-figure/rca-figure-viewer.context';
import {useRCAVsLabels} from '../../rca-figure/hooks/use-rca-vs-labels.hook';
import {RCASegmentationViewMode} from '../../rca-figure/rca-figure-viewer.types';
import {UnifiedRCASegmentationItem} from '../rca-segmentation-extended.types';
import {exists} from 'front-core';

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

interface Params {
  segments: UnifiedRCASegmentationItem[];
  populationTrend: number;
  options: RCASegmentationFigureOptions;
  showInsignificant?: boolean;
  viewMode: RCASegmentationViewMode;
  groupName?: boolean;
  key?: string;
  isCompositeMode?: boolean;
}

const DEFAULT_SORT = {
  orderBy: 'order',
  order: 'desc',
};

export const useTopExplainersSmartTable = (params: Params): SmartTableFigure => {
  const {
    segments,
    populationTrend,
    options,
    viewMode,
    groupName = true,
    showInsignificant = true,
    key = 'segment_explainers_table',
    isCompositeMode = false,
  } = params;
  const {t} = useDocumentTranslation();
  const {mode: rcaMode} = useContext(RCAFigureViewerContext);
  const vsLabels = useRCAVsLabels(rcaMode, options.entity);

  const tableData = useMemo(
    () =>
      segments
        .map(s => ({
          ...s,
          // We need to multiply by 100 so the table filters will work correctly
          // in the column renderers we will divide by 100 to show the correct value
          share: s.share * 100,
          expectedShare: s.expectedShare * 100,
          value: options.isPercentageValue ? s.value * 100 : s.value,
          expectedValue: options.isPercentageValue ? s.expectedValue * 100 : s.expectedValue,
          order: exists(s.order)
            ? s.order
            : viewMode === RCASegmentationViewMode.EXPLAIN
              ? s.explainabilityScore
              : s.interestScore,
          populationTrend,
          isSignificant:
            viewMode === RCASegmentationViewMode.EXPLAIN
              ? s.isSignificant
              : s.isInterestSignificant,
          command: s.signalId
            ? {
                type: CommandType.REF,
                payload: {
                  type: ModelType.SIGNAL,
                  refId: s.signalId,
                },
              }
            : undefined,
        }))
        .filter(s => (showInsignificant ? true : s.isSignificant)),
    [segments, populationTrend, showInsignificant, options.isPercentageValue, viewMode]
  );

  const tableColumns: SmartTableFigureColumn<any>[] = useMemo(() => {
    const columns = [];
    const entityPlural = pluralize(options.entity);

    if (groupName) {
      columns.push({
        key: 'name',
        type: ColumnType.LABEL_VALUE,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.TITLE),
        typeOptions: {
          labelDataKey: 'segmentGroupName',
          labelDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_GROUP_NAME.TITLE),
          valueDataKey: 'segmentName',
          valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.TITLE),
          commandDataKey: 'command',
        },
        options: {
          sortable: !isCompositeMode,
          filterable: !isCompositeMode,
          bold: true,
          width: options.isCountEntitiesMode ? '40%' : '30%',
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.HELPER_TEXT),
          helperTextDataKey:
            viewMode === RCASegmentationViewMode.EXPLAIN ? 'helperText' : 'interestHelperText',
        },
      });
    } else {
      columns.push({
        key: 'segmentName',
        type: ColumnType.DEFAULT,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.TITLE),
        options: {
          sortable: !isCompositeMode,
          filterable: !isCompositeMode,
          bold: true,
          width: options.isCountEntitiesMode ? '35%' : '25%',
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.HELPER_TEXT),
          helperTextDataKey: 'helperText',
        },
        typeOptions: {
          commandDataKey: 'command',
        },
      });
    }
    columns.push(
      {
        key: 'explainabilityScore',
        type: ColumnType._RENDER_COLUMN,
        asType: ColumnType.DEFAULT,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.EXPLAINABILITY_SCORE.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.EXPLAINABILITY_SCORE.SUB_TITLE),
        options: {
          sortable: true,
          filterable: false,
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.EXPLAINABILITY_SCORE.HELPER_TEXT),
          width: '16rem',
          exportDataKey: 'explainabilityLabel',
        },
        hidden: !options.showExplainability || viewMode !== RCASegmentationViewMode.EXPLAIN,
        render: segment => (
          <ExplainabilityLabelDisplay
            label={segment.explainabilityLabel}
            isSignificant={segment.isSignificant}
          />
        ),
      },
      {
        key: 'interestScore',
        type: ColumnType._RENDER_COLUMN,
        asType: ColumnType.DEFAULT,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CHANGE.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CHANGE.SUB_TITLE),
        options: {
          sortable: true,
          filterable: false,
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CHANGE.HELPER_TEXT),
          width: '20rem',
          exportDataKey: 'interestTypes',
        },
        hidden: !options.showExplainability || viewMode !== RCASegmentationViewMode.CHANGE,
        render: segment =>
          segment.interestTypes &&
          segment.interestTypes.length > 0 &&
          segment.isInterestSignificant ? (
            <div style={{display: 'flex', alignItems: 'center', gap: '0.4rem'}}>
              {take(segment.interestTypes, MAX_INTEREST_CHIP_TO_SHOW).map(
                (interestType: InterestType) => (
                  <InterestChipDisplay
                    key={interestType}
                    interestType={interestType}
                    data={{
                      ...segment,
                      share: segment.share / 100,
                      expectedShare: segment.expectedShare / 100,
                    }}
                    isPercentageValue={options.isPercentageValue}
                    entity={options.entity}
                  />
                )
              )}
            </div>
          ) : (
            <TableMutedTest text={'--'} />
          ),
      },
      {
        key: 'switch_share',
        type: ColumnType.SWITCH_COLUMN,
        hidden: options.isCountEntitiesMode === true,
        typeOptions: {
          columns: [
            {
              key: 'share',
              type: ColumnType._RENDER_COLUMN,
              asType: ColumnType.VERSUS,
              title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE.TITLE, {
                entity: entityPlural,
              }),
              subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE.SUB_TITLE, {
                entity: entityPlural,
              }),
              options: {
                sortable: true,
                filterable: true,
                helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE.HELPER_TEXT, {
                  entity: entityPlural,
                }),
                unit: '%',
              },
              typeOptions: {
                valueDataKey: 'share',
                valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE.TITLE, {
                  entity: entityPlural,
                }),
                versusDataKey: 'expectedShare',
                versusDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE.SUB_TITLE, {
                  entity: entityPlural,
                }),
              },
              render: segment => (
                <VersusWithUpliftDisplay
                  // see the tableData mapping above for the reason of this division
                  value={segment.share / 100}
                  vsValue={segment.expectedShare / 100}
                  isPercentageValue={true}
                  {...vsLabels.share}
                />
              ),
            },
            {
              key: 'count',
              type: ColumnType._RENDER_COLUMN,
              asType: ColumnType.VERSUS,
              title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT.TITLE, {
                entity: entityPlural,
              }),
              subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT.SUB_TITLE, {
                entity: entityPlural,
              }),
              options: {
                sortable: true,
                filterable: true,
                helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT.HELPER_TEXT, {
                  entity: entityPlural,
                }),
              },
              typeOptions: {
                valueDataKey: 'count',
                valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT.TITLE, {
                  entity: entityPlural,
                }),
                versusDataKey: 'expectedCount',
                versusDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT.SUB_TITLE, {
                  entity: entityPlural,
                }),
              },
              render: segment => (
                <VersusWithUpliftDisplay
                  value={segment.count}
                  vsValue={segment.expectedCount}
                  isPercentageValue={false}
                  {...vsLabels.count}
                />
              ),
            },
          ],
        },
      },
      {
        key: 'value',
        type: ColumnType._RENDER_COLUMN,
        asType: ColumnType.VERSUS,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.TITLE, {
          entity: options.entity,
        }),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.SUB_TITLE, {
          entity: options.entity,
        }),
        options: {
          sortable: true,
          filterable: true,
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.HELPER_TEXT),
        },
        typeOptions: {
          valueDataKey: 'value',
          valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.TITLE, {
            entity: options.entity,
          }),
          versusDataKey: 'expectedValue',
          versusDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.SUB_TITLE, {
            entity: options.entity,
          }),
        },
        render: segment => (
          <VersusWithUpliftDisplay
            // see the tableData mapping above for the reason of this division
            value={options.isPercentageValue ? segment.value / 100 : segment.value}
            vsValue={
              options.isPercentageValue ? segment.expectedValue / 100 : segment.expectedValue
            }
            isPercentageValue={options.isPercentageValue}
            {...vsLabels.value}
          />
        ),
      },
      {
        key: 'ctcPp',
        type: ColumnType._RENDER_COLUMN,
        asType: ColumnType.VERSUS,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.SUB_TITLE),
        options: {
          sortable: true,
          filterable: true,
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.HELPER_TEXT),
        },
        typeOptions: {
          valueDataKey: 'ctcPp',
          valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.TITLE, {
            entity: options.entity,
          }),
          versusDataKey: 'populationTrend',
          versusDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP.SUB_TITLE, {
            entity: options.entity,
          }),
        },
        render: segment => (
          <ImpactPPDisplay value={segment.ctcPp} outOfValue={segment.populationTrend} />
        ),
      }
    );
    return columns;
  }, [options, t, groupName, viewMode, vsLabels, isCompositeMode]);

  const tableFigure: DocumentElement = useMemo(
    () =>
      ({
        id: key,
        dataKey: 'key',
        type: DocumentElementType.SMART_TABLE,
        columns: tableColumns,
        data: tableData,
        options: {
          defaultSort: DEFAULT_SORT,
          pagination: false,
          stickyFooter: true,
          significantDataKey: 'isSignificant',
        },
      }) as any,
    [tableColumns, segments, showInsignificant, key, viewMode]
  );

  return tableFigure as any;
};
