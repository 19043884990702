import * as React from 'react';
import classNames from 'classnames';
import {DateColumnOptions} from '../../../../../types';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import classes from './data-columns.module.scss';
import {exists} from 'front-core';
import {useCallback, useMemo} from 'react';
import moment from 'moment';
import {DEFAULT_DATE_FORMAT, DEFAULT_INPUT_DATE_FORMAT} from '../../../../../../../../consts/ui';
import {DataColumnProps} from '../../smart-table.types';

interface OwnProps extends DataColumnProps<DateColumnOptions> {}

type AllProps = OwnProps;

const DateDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;

  const value = useMemo(() => data[column.key], [column.key, data]);
  const formatDate = useCallback(
    (value: string) => {
      if (!exists(value)) {
        return null;
      }
      const dateFormat = column.typeOptions.dateFormat || DEFAULT_DATE_FORMAT;
      const inputDateFormat = column.typeOptions.dateInputFormat || DEFAULT_INPUT_DATE_FORMAT;
      return moment(value, inputDateFormat).format(dateFormat);
    },
    [column.typeOptions]
  );
  const endDate = useMemo(() => {
    const {endDateDataKey} = column.typeOptions;
    if (!exists(endDateDataKey)) {
      return null;
    }
    const inputEndDate = data[endDateDataKey];
    return formatDate(inputEndDate);
  }, [column.typeOptions, data]);

  const renderDate = useMemo(() => {
    return formatDate(value);
  }, [value]);

  return (
    <div className={classNames(classes.DefaultDataColumn, className)}>
      {renderDate} {endDate && ` - ${endDate}`}
    </div>
  );
};

const protector: Protector = (props: OwnProps) => exists(props.data[props.column.key]);
export const DateDataColumn = withDataColumnProtector(protector)(DateDataColumnComponent);
