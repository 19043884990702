import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';

import {
  AddMetricToTreeDTO,
  GetMetricTreeFiltersDTO,
  AddInputMetricDTO,
  RemoveMetricFromTreeDTO,
  SetKPITreeMetricPositionsDTO,
  RemoveInputMetricDTO,
  GetMetricNodeOptionsDTO,
  ChangeInputMetricRelationshipTypeDTO,
  SetKPITreeMetricFormulaDTO,
} from '../objects/dto/metric-tree.dto';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';

export const getMetricTreeNetworkRequest: NRC<void, GetMetricTreeFiltersDTO> = (
  query: GetMetricTreeFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metric-tree`,
  query: query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const addMetricToTreeNetworkRequest: NRC<AddMetricToTreeDTO, void> = (
  data: AddMetricToTreeDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metric-tree/metrics`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const removeMetricToTreeNetworkRequest: NRC<RemoveMetricFromTreeDTO, void> = (
  data: RemoveMetricFromTreeDTO
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/metric-tree/metrics`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const addInputMetricNetworkRequest: NRC<AddInputMetricDTO, void> = (
  data: AddInputMetricDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metric-tree/metrics/input`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const removeInputMetricNetworkRequest: NRC<RemoveInputMetricDTO, void> = (
  data: RemoveInputMetricDTO
) => ({
  method: RequestType.DELETE,
  relativeUrl: `/app/metric-tree/metrics/input`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const changeInputMetricRelationshipTypeNetworkRequest: NRC<
  ChangeInputMetricRelationshipTypeDTO,
  void
> = (data: ChangeInputMetricRelationshipTypeDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metric-tree/metrics/input/change-relationship-type`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const setKPITreeMetricPositionsNetworkRequest: NRC<SetKPITreeMetricPositionsDTO, void> = (
  data: SetKPITreeMetricPositionsDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metric-tree/positions`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getMetricNodeOptionsNetworkRequest: NRC<void, GetMetricNodeOptionsDTO> = (
  query: GetMetricNodeOptionsDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/metric-tree/options`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const setMetricTreeKPIFormulaNetworkRequest: NRC<SetKPITreeMetricFormulaDTO, void> = (
  data: SetKPITreeMetricFormulaDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/metric-tree/formula`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});
