import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import classNames from 'classnames';
import classes from './kpi-overview-viewer.module.scss';
import {
  ColumnType,
  CommandType,
  DocumentElementType,
  FollowUpType,
  KPIAnalysisLineChartFollowUpCommandPayload,
  KPIAnalysisLineChartFollowUpParameters,
  KPIOverviewFigure,
} from '../../../../types';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {keyBy, last} from 'lodash';
import {Select} from '../../../../../../forms/inputs/select/select.component';
import {
  GraphIcon,
  TableIcon,
  UserGroupLightIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {SwitchActions} from '../../../../../../simple/controls/switch-actions/switch-actions.component';
import {FancyBlock} from '../../../../../../simple/data-display/fancy-block/fancy-block.component';
import TransKeys from 'translations';
import pluralize from 'pluralize';
import {LineChart} from '../../../../../charts/charts/line-chart/line-chart.component';
import {exists} from 'front-core';
import {useTranslation} from '../../../../../../../core/translations/use-translation';
import {OnDatasetPointClickParam} from '../../../../../charts/charts/line-chart/line-chart-dataset-container.component';
import {ChartXYValue} from '../../../../../charts/chart-data.types';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {ChildRenderer} from '../../../core/child-renderer.component';
import {GlobalDocumentDataContext} from '../../../../contexts/global-document-data/global-document-data.context';
import {TeamSelector} from '../../../../../../simple/controls/team-selector/team-selector.component';
import {Team} from '../../../../contexts/global-document-data/global-document-data.types';
import {getFirstAndLastPartialSamples} from '../../utils/samples.utils';
import moment from 'moment/moment';

interface OwnProps extends KPIOverviewFigure {
  className?: string;
}

type AllProps = OwnProps;

enum Mode {
  CHART,
  TABLE,
}

interface Filters {
  selected?: string;
  mode?: Mode;
}

const ALL_POPULATION_SPECIAL_KEY = 'all_population';
const ALL_POPULATION_DATA = {
  key: ALL_POPULATION_SPECIAL_KEY,
  signalId: null,
  segmentGroupName: '',
  segmentName: '',
};

const DEFAULT_FILTERS: Filters = {
  selected: ALL_POPULATION_SPECIAL_KEY,
  mode: Mode.CHART,
};

export const isSignificantValue = (value: number, lower: number, upper: number) =>
  exists(upper) && exists(lower) ? value > upper || value < lower : false;

const GOOD_COLOR = 'green';
const BAD_COLOR = 'red';

export const KPIOverviewViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data, options, className} = props;
  const {items: itemsFromProps = [], allPopulation, granularity} = data;
  const {t} = useTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(id, DEFAULT_FILTERS);
  // todo remove teams in the future - overview doesn't display segments any more
  const {teams, selectedFavoriteSegmentsMap, teamId, setTeamId} =
    useContext(GlobalDocumentDataContext);
  const hasTeams = teams.length > 0;

  const onFilterChange = useCallback(
    (newFilters: Filters) =>
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      })),
    [setFilters]
  );
  const items = useMemo(() => {
    if (!teamId) {
      return itemsFromProps;
    }
    return itemsFromProps.filter(s => {
      const fv = selectedFavoriteSegmentsMap[s.signalId];
      if (fv === undefined) {
        return false;
      }
      if (!fv.classes) {
        return true;
      }
      return fv.classes.includes(s.segmentName);
    });
  }, [itemsFromProps, teamId, selectedFavoriteSegmentsMap]);
  const teamsOptions: Team[] = useMemo(() => {
    return [
      {
        name: 'All',
        id: null,
        color: '',
        icon: UserGroupLightIcon,
      },
      ...teams,
    ];
  }, [teams]);
  const onTeamChange = useCallback(
    (teamId: number) => {
      setTeamId(teamId);
      onFilterChange({selected: ALL_POPULATION_SPECIAL_KEY});
    },
    [setTeamId]
  );
  const segmentsData = useMemo(() => {
    const res = keyBy(items, 'key');
    res[ALL_POPULATION_SPECIAL_KEY] = {
      ...ALL_POPULATION_DATA,
      samples: allPopulation,
    };
    return res;
  }, [items, allPopulation]);
  const selectedSegment =
    segmentsData[filters.selected] || segmentsData[ALL_POPULATION_SPECIAL_KEY];

  const segmentOptions = useMemo(() => {
    const options = items.map(s => ({
      label: `${s.segmentGroupName} - ${s.segmentName}`,
      value: s.key,
    }));
    return [{value: ALL_POPULATION_SPECIAL_KEY, label: 'All Segments'}, ...options];
  }, [items]);
  const modeOptions = useMemo(() => {
    return [
      {
        icon: GraphIcon,
        onClick: () => onFilterChange({mode: Mode.CHART}),
        isActive: filters.mode === Mode.CHART,
      },
      {
        icon: TableIcon,
        onClick: () => onFilterChange({mode: Mode.TABLE}),
        isActive: filters.mode === Mode.TABLE,
      },
    ];
  }, [filters.mode, onFilterChange]);

  const onFollowUp = useCallback(
    (param: OnDatasetPointClickParam) => {
      const {point, dataset} = param;
      if (!point.isDate || point.itemIndex === 0) {
        return null;
      }
      const previousPoint = dataset.data[point.itemIndex - 1] as ChartXYValue;
      const increasedFromPreviousPoint = point.y > previousPoint.y;
      const change = t(
        TransKeys.GENERAL.LABELS[increasedFromPreviousPoint ? 'INCREASED' : 'DECREASED']
      );
      let parameters: KPIAnalysisLineChartFollowUpParameters = {
        goal: data.goalSignalId,
        fromToLabel: point.label,
        startDateAnomaly: point.x,
        timeAggregation: data.granularity,
        title: t(TransKeys.DOCUMENT_VIEWER.LINE_CHART_WITH_FOLLOW_UP_RCA.PANEL.TITLE, {
          change,
          timeGranularity: t(
            TransKeys.GENERAL.LABELS.TIME_GRANULARITY[data.granularity.toUpperCase()]
          ),
        }),
        segment: selectedSegment.signalId ? selectedSegment.signalId : undefined,
        segmentClass: selectedSegment.segmentName ? [selectedSegment.segmentName] : undefined,
      };
      const payload: KPIAnalysisLineChartFollowUpCommandPayload = {
        type: FollowUpType.RCA_FROM_KPI_ANALYSIS_LINE_CHART,
        parameters,
      };
      emitEvent({
        type: CommandType.FOLLOW_UP,
        payload,
      });
    },
    [data, emitEvent, t, selectedSegment]
  );
  const chartProps = useMemo(() => {
    const datasets = [];
    const {samples} = selectedSegment;

    const kpiDataset = samples.map(s => {
      const isSignificant = isSignificantValue(s.value, s.lower, s.upper);
      let markColor = undefined;
      let pointTooltipTitle = undefined;
      if (isSignificant && !s.isPartial) {
        if (s.value > s.upper) {
          markColor = GOOD_COLOR;
          pointTooltipTitle = t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_TITLE);
        } else {
          markColor = BAD_COLOR;
          pointTooltipTitle = t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_TITLE);
        }
      }
      return {
        x: s.datetime,
        y: options.isPercentageValue ? s.value * 100 : s.value,
        dashed: s.isPartial,
        clickable: !s.isPartial,
        upper: !s.isPartial ? (options.isPercentageValue ? s.upper * 100 : s.upper) : null,
        lower: !s.isPartial ? (options.isPercentageValue ? s.lower * 100 : s.lower) : null,
        markColor,
        pointTooltipTitle,
      };
    });
    let entityDataset: any;
    if (options.hasEntityCount) {
      entityDataset = samples.map(s => ({
        x: s.datetime,
        y: s.entityCount,
        dashed: s.isPartial,
      }));
    }
    datasets.push({
      id: 'kpi',
      label: options.kpiName,
      data: kpiDataset,
    });
    if (entityDataset) {
      datasets.push({
        id: 'users',
        label: `Number of ${pluralize(options.entity)}`,
        data: entityDataset,
        yAxis: 'secondary',
      });
    }

    const {firstPartial, lastPartial} = getFirstAndLastPartialSamples(samples);
    let markArea: any;
    if (firstPartial && lastPartial) {
      markArea = {
        from: moment.utc(firstPartial.datetime).subtract(1, granularity).format('YYYY-MM-DD'),
        to: lastPartial.datetime,
        title: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_TITLE),
        description: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_DESCRIPTION),
      };
    } else if (options.showIncompleteMark) {
      const lastDate = last(samples).datetime;
      markArea = {
        from: lastDate,
        to: moment.utc(lastDate).add(1, data.granularity).format('YYYY-MM-DD'),
        title: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_TITLE),
        description: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_DESCRIPTION),
      };
    }

    return {
      datasets: datasets,
      displayedDatasetIds: ['kpi'],
      options: {
        markAreas: markArea ? [markArea] : undefined,
        labels: {
          dateFormat: 'DD MMM',
          type: 'date',
          timeUnit: data.granularity,
        },
        yLabelSuffix: options.isPercentageValue ? '%' : undefined,
        yAxisMaxTicks: 5,
        errorBar: true,
        showHideAnnotation: false,
        xLabel: 'date',
      },
      pointTooltipCta: t(TransKeys.HOMEPAGE.METRIC_CHART.TOOLTIP_CTA),
      onDatasetPointClick: onFollowUp,
    } as any;
  }, [selectedSegment, options, data.granularity, onFollowUp]);
  const tableFigure = useMemo(() => {
    const data = selectedSegment.samples.map(s => ({
      date: s.datetime,
      count: s.entityCount,
      value: options.isPercentageValue ? s.value * 100 : s.value,
    }));

    return {
      id: 'kpi-overview-viewer-table',
      type: DocumentElementType.SMART_TABLE,
      dataKey: 'date',
      data,
      columns: [
        {
          key: 'date',
          type: ColumnType.DATE,
          title: 'Date',
          options: {
            sortable: true,
            filterable: true,
          },
        },
        {
          key: 'count',
          type: ColumnType.NUMERIC,
          title: `Number of ${pluralize(options.entity)}`,
          options: {
            sortable: true,
            filterable: true,
          },
          hidden: !options.hasEntityCount,
        },
        {
          key: 'value',
          type: ColumnType.GRADIENT,
          title: 'KPI Value',
          options: {
            unit: options.isPercentageValue ? '%' : undefined,
            helperText: `The value of the analyzed KPI ('${options.kpiName}') per week`,
          },
          typeOptions: {
            higherIsBetter: true,
          },
        },
      ],
      options: {
        pagination: true,
        perPage: 10,
        paginationMode: 'pages',
        defaultSort: {
          orderBy: 'date',
          order: 'asc',
        },
      },
    };
  }, [selectedSegment, options]);

  return (
    <div className={classNames(classes.KPIOverviewViewer, className)}>
      <div className={classes.Actions}>
        <div className={classes.Left}>
          {hasTeams && itemsFromProps.length > 0 && (
            <TeamSelector teams={teamsOptions} value={teamId} onChange={onTeamChange} />
          )}
          {items.length > 0 && (
            <Select
              value={filters.selected}
              onChange={(v: string) => onFilterChange({selected: v})}
              options={{options: segmentOptions}}
              searchable={segmentOptions.length > 5}
              clearable={false}
              dropdownButtonClassName={classes.SelectSegment}
            />
          )}
        </div>
        <div className={classes.Right}>
          <SwitchActions actions={modeOptions} />
        </div>
      </div>
      <div className={classes.Content}>
        {filters.mode === Mode.CHART && (
          <FancyBlock title={'KPI PERFORMANCE OVER TIME'} addPadding>
            <div className={classes.ChartWrapper}>
              <LineChart {...chartProps} />
            </div>
          </FancyBlock>
        )}
        {filters.mode === Mode.TABLE && <ChildRenderer children_={tableFigure} />}
      </div>
    </div>
  );
};

KPIOverviewViewer.defaultProps = {};
