import * as React from 'react';
import classNames from 'classnames';
import {useContext, useMemo} from 'react';
import {CommandColumnOptions, DocumentCommand} from '../../../../../types';
import {ArrowRightIcon, Button, IconButton} from '../../../../../../../../index';
import classes from './data-columns.module.scss';
import {COMMAND_ICON} from '../../smart-table.config';
import {DataColumnProps} from '../../smart-table.types';
import {exists, withStopPropagation} from 'front-core';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';

interface OwnProps extends DataColumnProps<CommandColumnOptions> {}

type AllProps = OwnProps;

export const CommandDataColumn: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const value: DocumentCommand<any> = useMemo(() => data[column.key], [column.key, data]);
  const Icon = useMemo(() => COMMAND_ICON[value?.type as any] || ArrowRightIcon, [value]);
  const helperText = useMemo(() => {
    if (column.typeOptions.helperTextDataKey) {
      return data[column.typeOptions.helperTextDataKey];
    }
  }, [data, column.typeOptions]);
  const renderButton = () => {
    if (column.typeOptions.buttonType === 'icon' || !exists(column.typeOptions.buttonType)) {
      return (
        <IconButton
          icon={Icon}
          tooltipText={helperText}
          onClick={withStopPropagation(_ => emitEvent(value))}
        />
      );
    }
    return (
      <div className={classes.ButtonWrapper}>
        <Button
          onClick={withStopPropagation(_ => emitEvent(value))}
          className={classes.CommandLink}
          caps={false}
          variant={column.typeOptions.buttonVariant === 'secondary' ? 'outlined' : undefined}
        >
          <span className={classes.ButtonText}>{value.payload.text}</span>
        </Button>
      </div>
    );
  };

  return (
    <div className={classNames(classes.CommandDataColumn, className)}>
      {value && value.payload && renderButton()}
    </div>
  );
};
