import * as React from 'react';
import classNames from 'classnames';
import classes from './dialog.module.scss';
import {Checkbox} from '../inputs/checkbox/checkbox.component';
import {Button} from '../../simple/controls/button/button.component';
import {useEffect, useState} from 'react';
import {DialogLayout} from '../../simple/generic/dialog-layout/dialog-layout.component';

interface OwnProps {
  className?: string;
  value?: boolean;
  onSubmit?: (value: boolean) => void;
  label?: string;
}

type AllProps = OwnProps;

export const BooleanDialog: React.FC<AllProps> = (props: AllProps) => {
  const {onSubmit, label, value: value_, className} = props;

  const [value, setValue] = useState(value_);
  useEffect(() => {
    setValue(value_);
  }, [value_]);

  return (
    <DialogLayout className={classNames(classes.BooleanFilter, className)}>
      <div className={classes.Form}>
        <div onClick={() => setValue(true)} className={classes.Option}>
          <div className={classes.Checkbox}>
            <Checkbox checked={value === true} />
          </div>
          <div className={classes.Label}>Yes</div>
        </div>
        <div onClick={() => setValue(false)} className={classes.Option}>
          <div className={classes.Checkbox}>
            <Checkbox checked={value === false} />
          </div>
          <div className={classes.Label}>No</div>
        </div>
        <div className={classes.ApplyBtnWrapper}>
          <Button className={classes.ApplyBtn} onClick={() => onSubmit(value)}>
            Apply
          </Button>
        </div>
      </div>
    </DialogLayout>
  );
};

BooleanDialog.defaultProps = {};
