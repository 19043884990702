import * as React from 'react';
import classNames from 'classnames';
import {SimilarityType} from '../../../../../../../../../../types';
import classes from './similarity-indication.module.scss';
import {
  Circle1LightIcon,
  CircleThreeQuartersStrokeLightIcon,
  DiagramVennLightIcon,
} from '../../../../../../../../../../../../simple/controls/icons/icons.component';
import {useMemo} from 'react';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  helperText?: string;
  similarityType?: SimilarityType;
  isPrimary?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const ICON_FOR_SIMILARITY_TYPE = {
  [SimilarityType.OVERLAPPING]: DiagramVennLightIcon,
  [SimilarityType.CONTAINED]: CircleThreeQuartersStrokeLightIcon,
};

export const SimilarityIndication: React.FC<AllProps> = (props: AllProps) => {
  const {similarityType, isPrimary, helperText, className} = props;

  const Icon = useMemo(() => {
    if (isPrimary) {
      return Circle1LightIcon;
    }
    return ICON_FOR_SIMILARITY_TYPE[similarityType];
  }, [isPrimary, similarityType]);

  if (!Icon) {
    return null;
  }

  return (
    <Tooltip title={helperText || ''} placement={'top'} interactive={false}>
      <div className={classNames(classes.SimilarityIndication, className)}>
        <Icon />
      </div>
    </Tooltip>
  );
};

SimilarityIndication.defaultProps = {};
