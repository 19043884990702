import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './input-chip-inline.module.scss';
import {isArray, get, keyBy} from 'lodash';
import {Tooltip} from '@material-ui/core';
import moment from 'moment';
import {EditIcon, CloseIcon} from '../../../simple/controls/icons/icons.component';
import {InputType} from '../../inputs.types';
import {extractValue, useRemoteSource} from '../../../../hooks/use-remote-source';
import {exists} from 'front-core';

interface OwnProps {
  id?: string;
  inputType: InputType;
  options?: any;
  label?: string;
  value?: any;
  isActive?: boolean;
  onDelete?: (e) => void;
  onEdit?: (e) => void;
  editable?: boolean;
  muted?: boolean;
  className?: string;
  dateFormat?: string;
  variant?: 'light' | 'dark';
  freeText?: boolean;
  noneLabel?: string;
}

type AllProps = OwnProps;

const DEFAULT_DATE_FORMAT = 'DD/MM/YY';
const SHOW_TOOLTIP_MIN_LEN = 18;

export const InputChipInline: React.FC<AllProps> = (props: AllProps) => {
  const {
    id,
    inputType,
    options,
    label,
    value: value_,
    isActive,
    editable,
    muted,
    onDelete,
    onEdit,
    dateFormat,
    className,
    variant,
    freeText,
    noneLabel,
  } = props;

  const [remoteOptions, setRemoteOptions] = useState({});
  const {exec: searchOptions} = useRemoteSource({
    type: 'source-list',
    networkRequest: options?.networkRequest,
    transformer: item => ({
      value: extractValue(item, options.valueAttributePath),
      label: extractValue(item, options.labelAttributePath),
    }),
  });

  const value = useMemo(() => {
    if (!exists(value_)) {
      return noneLabel;
    }
    switch (inputType) {
      case InputType.FREE_TEXT:
        return value_;
      case InputType.DATE:
        return moment(value_).format(dateFormat);
      case InputType.DATE_RANGE:
        const start = moment(value_.from).format(dateFormat);
        const end = moment(value_.to).format(dateFormat);
        return `${start} - ${end}`;
      case InputType.BOOLEAN:
        return value_ ? 'Yes' : 'No';
      case InputType.ENUM:
        const valueLabels = keyBy(options.options, 'value');
        const returnValue = isArray(value_)
          ? value_.map(v => valueLabels[v]?.label).join(', ')
          : valueLabels[value_]?.label;
        if (returnValue === undefined && freeText) {
          return value_;
        }
        return returnValue;
      case InputType.API_CALL:
        return isArray(value_)
          ? value_.map(v => get(remoteOptions[v], 'label')).join(', ')
          : get(remoteOptions[value_], 'label');
    }
  }, [value_, inputType, dateFormat, options, freeText, remoteOptions]);

  const populateRemoteOptions = useCallback(
    async value => {
      if (inputType !== InputType.API_CALL) {
        return;
      }
      try {
        const res: any = await searchOptions('', {
          [options.valueAttributePath]: value,
        });
        setRemoteOptions(keyBy(res, 'value'));
      } catch (e) {
        setRemoteOptions([]);
      }
    },
    [inputType, searchOptions, options?.valueAttributePath]
  );

  useEffect(() => {
    populateRemoteOptions(value_);
  }, [value_, populateRemoteOptions]);

  return (
    <div
      id={id}
      className={classNames(
        classes.InputChipInline,
        isActive && classes.Active,
        !editable && classes.Disabled,
        muted && classes.Muted,
        classes[variant],
        className
      )}
    >
      {label && <span className={classes.Label}>{label}:</span>}
      <Tooltip title={value?.length > SHOW_TOOLTIP_MIN_LEN ? value : ''} placement={'top'}>
        <span className={classes.Values}>
          <span>{value}</span>
        </span>
      </Tooltip>
      {editable && (
        <div className={classes.Actions}>
          {onEdit && (
            <Tooltip title={'Edit'} placement={'top'}>
              <div onClick={onEdit} className={classes.FilterAction}>
                <EditIcon className={classes.EditFilterIcon} />
              </div>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title={'Remove'} placement={'top'}>
              <div onClick={onDelete} className={classes.FilterAction}>
                <CloseIcon className={classes.FilterIcon} />
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

InputChipInline.defaultProps = {
  editable: true,
  variant: 'light',
  dateFormat: DEFAULT_DATE_FORMAT,
  noneLabel: 'None',
};
