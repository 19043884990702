import * as React from 'react';
import {ErrorFigure} from '../../../types';
import classes from './error-viewer.module.scss';
import classNames from 'classnames';
import {ImagesIndex} from '../../../../../../assets/images';

export interface OwnProps extends ErrorFigure {
  className?: string;
}

export const ErrorViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    data: {code, message},
  } = props;

  return (
    <div className={classes.ErrorFigure}>
      <div className={classes.Error}>
        <div className={classes.ImageWrapper}>
          <img src={ImagesIndex.telescopeV2} alt={''} />
        </div>
        <div className={classes.Title}>Sorry, something went wrong</div>
        <div className={classNames(classes.Title, classes.Light)}>
          {`${message} (Code: ${code}).`}
        </div>
      </div>
    </div>
  );
};
