import {chartClasses} from '../../../chart.consts';
import {EnhancedGroup, EnhancedRect} from '../../../../../../core/konva/components';
import {Html} from '../../../../../../core/konva/hoc/html.hoc';
import {LoadingIcon} from '../../../../../simple/controls/icons/icons.component';
import {useTranslation} from '../../../../../../core/translations/use-translation';
import TransKeys from 'translations';

import * as React from 'react';

interface OwnProps {
  height: number;
  x: number;
  xLabel: string;
  incompleteMarkDescription: string;
  incompleteMarkWidth: number;
}

type AllProps = OwnProps;

export const IncompleteMark: React.FC<AllProps> = (props: AllProps) => {
  const {x, xLabel, incompleteMarkDescription, incompleteMarkWidth, height} = props;
  const {t} = useTranslation();
  return (
    <EnhancedGroup
      x={x}
      y={0}
      tooltipEnabled
      tooltipData={{
        label: (
          <div className={chartClasses.TooltipLabelContainer}>
            <span className={chartClasses.Label}>{xLabel}</span>
          </div>
        ),
        value: (
          <span>
            {incompleteMarkDescription ||
              t(TransKeys.CHARTS.INCOMPLETE_MARK.INCOMPLETE_DATA_DEFAULT_TEXT)}
          </span>
        ),
      }}
    >
      <EnhancedRect
        x={0}
        y={0}
        width={incompleteMarkWidth}
        height={height}
        opacity={0.7}
        fill={'#f3f3f3'}
      />
      <Html
        divProps={{
          style: {
            pointerEvents: 'none',
          },
        }}
        groupProps={{
          x: incompleteMarkWidth / 2 - 12, // center to the middle while taking into account half the width of LoadingIcon (horizontal)
          y: height / 2 - 12, // center to the middle while taking into account half the height of LoadingIcon (vertical)
          width: 24,
          height: 24,
        }}
      >
        <LoadingIcon />
      </Html>
    </EnhancedGroup>
  );
};
