import * as React from 'react';
import {useMemo} from 'react';
import {TeamIconChip} from '../../data-display/team-icon-chip/team-icon-chip.component';
import {Select} from '../../../forms/inputs/select/select.component';
import classes from './team-selector.module.scss';
import classNames from 'classnames';

export interface TeamItem {
  id: number;
  name: string;
  color?: string;
  icon?: React.FC;
  [other: string]: any;
}

interface OwnProps {
  teams: TeamItem[];
  value: any;
  onChange: (v: any) => void;
  clearable?: boolean;
  withPrefix?: boolean;
  // small is not supported yet!
  size?: 'small' | 'medium' | 'large';
  className?: string;
  placeholder?: React.ReactNode;
  dropdownButtonClassName?: string;
  hideButtonLabel?: boolean;
  disabled?: boolean;
  dropdownButtonIconPosition?: 'start' | 'end';
}

type AllProps = OwnProps;

export const TeamSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    value,
    onChange,
    teams,
    clearable,
    withPrefix,
    size = 'medium',
    className,
    dropdownButtonIconPosition,
    placeholder,
    hideButtonLabel,
    disabled,
    dropdownButtonClassName,
  } = props;

  const teamOptions = useMemo(
    () =>
      teams.map(t => ({
        value: t.id,
        label: t.name,
        icon: t.icon ? t.icon : props => <TeamIconChip {...props} name={t.name} color={t.color} />,
      })),
    [teams]
  );

  const options = useMemo(() => {
    return {options: teamOptions};
  }, [teamOptions]);

  return (
    <Select
      disabled={disabled}
      hideButtonLabel={hideButtonLabel}
      dropdownButtonIconPosition={dropdownButtonIconPosition}
      className={className}
      dropdownButtonClassName={classNames(dropdownButtonClassName, classes[size])}
      options={options}
      value={value}
      placeholder={placeholder}
      prefix={withPrefix ? 'Team' : undefined}
      onChange={onChange}
      sortValues={false}
      searchable={false}
      capitalize={false}
      clearable={clearable}
    />
  );
};
