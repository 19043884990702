import * as React from 'react';
import classes from './expandable-label.module.scss';
import classNames from 'classnames';
import {useCallback} from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

export interface ExpandableLabelProps {
  label: string;
  className?: string;
  isExpanded?: boolean;
  onExpand?: () => void;
  children?: any;
  withArrow?: boolean;
}

type AllProps = ExpandableLabelProps;

export const ExpandableLabel: React.FC<AllProps> = (props: ExpandableLabelProps) => {
  const {label, className, isExpanded, onExpand, withArrow, children} = props;

  const onExpand_ = useCallback(e => onExpand && onExpand(), [onExpand]);

  return (
    <div
      className={classNames(
        classes.ExpandableLabel,
        isExpanded && classes.Expanded,
        withArrow && classes.WithArrow,
        className
      )}
    >
      <div onClick={onExpand_} className={classes.Label}>
        {label}
      </div>
      {onExpand && (
        <div className={classes.Arrow}>
          <ArrowRightIcon className={classes.ArrowIcon} />
        </div>
      )}
      {onExpand && (
        <div className={classes.ExpandableWrapper}>
          <div className={classes.Expandable}>{children}</div>
        </div>
      )}
    </div>
  );
};

ExpandableLabel.defaultProps = {
  isExpanded: false,
  withArrow: true,
};
