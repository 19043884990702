import * as React from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {useContext, useMemo} from 'react';
import {calculateBbox} from '../../../../../core/konva/stage.utils';
import {
  BaseChartOptions,
  ChartWithLabelsOptions,
  LabelBasedChart,
  NumberDataset,
} from '../../chart-data.types';
import {AreaChartDatasetContainer} from './funnel-area-chart-dataset-container.component';
import {StepsLayout} from '../../layouts/steps-layout.component';
import {useChartLabels} from '../../chart.hooks';
import {ChartTooltip} from '../../components/chart-tooltip.component';
import {max, range} from 'lodash';
import {ChartContext, ChartContextProvider} from '../../chart.context';
import {ResizeRender} from '../../../../hoc/resize-render/resize-render.component';
import classes from '../../chart.module.scss';

export interface FunnelAreaChartOptions extends BaseChartOptions, ChartWithLabelsOptions {}

interface OwnProps extends LabelBasedChart<NumberDataset, FunnelAreaChartOptions> {}

type AllProps = OwnProps;

const FunnelAreaChartController: React.FC<AllProps> = (props: AllProps) => {
  const {controller, style} = useContext(StageContext);
  const {datasets: datasets_, datasetLabels} = useContext(ChartContext);
  const {labels: labelsFromProps, options} = props;
  const {legendsLabel, showLegend} = options;
  const {layoutPadding} = style;
  /**
   * Computed properties
   */
  const datasetDataLength: number = useMemo(
    () => max(datasets_.map(ds => ds.data.length)),
    [datasets_]
  );
  const datasets: NumberDataset[] = useMemo(
    () =>
      datasets_.map((ds, index) => ({
        ...ds,
        data: range(0, datasetDataLength).map(i => (ds.data[i] ? Number(ds.data[i]) : 0)),
      })),
    [datasets_, datasetDataLength]
  );
  const labels: string[] = useChartLabels(labelsFromProps, options.labels, datasetDataLength);
  const layoutBbox = useMemo(
    () => calculateBbox(controller?.getSize().width, controller?.getSize().height, layoutPadding),
    [controller, layoutPadding]
  );
  /**
   * Render
   */
  return (
    <StepsLayout
      tickLabels={labels}
      legendsTitle={legendsLabel || undefined}
      legendLabels={[datasetLabels[0]]}
      renderFirstLine={false}
      renderLastLine={false}
      showLegend={showLegend}
      {...layoutBbox}
    >
      {props => <AreaChartDatasetContainer {...props} dataset={datasets[0]} />}
    </StepsLayout>
  );
};

export const FunnelAreaChart: React.FC<AllProps> = (props: AllProps) => {
  return (
    <div className={classes.ChartContainer}>
      <ResizeRender>
        <ChartContextProvider {...props} TooltipComponent={ChartTooltip}>
          <FunnelAreaChartController {...props} />
        </ChartContextProvider>
      </ResizeRender>
    </div>
  );
};

FunnelAreaChart.defaultProps = {
  options: {},
};
