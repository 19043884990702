import {useCallback, useContext} from 'react';
import {
  CommandType,
  DocumentElementType,
  DocumentTracker,
  DocumentTrackingType,
  NotifyCommandPayload,
  TrackingFilterInfo,
  TrackingFilterValue,
  TrackingInputInfo,
  TrackingInputValue,
  TrackingItemClickedInfo,
  TrackingItemClickedValue,
  TrackingItemTabChangedValue,
  TrackingItemToggledInfo,
  TrackingItemToggledValue,
  TrackingItemViewedValue,
  TrackingModalOpenedInfo,
  TrackingModalOpenedValue,
  TrackingModeChangedValue,
  TrackingNavigationValue,
  TrackingPaginationInfo,
  TrackingPaginationValue,
  TrackingScrollValue,
  TrackingSortInfo,
  TrackingSortValue,
} from '../types';
import {DocumentCommandEmitterContext} from '../contexts/document-command-emitter.context';

type TrackPayload = Omit<NotifyCommandPayload<any, any>, 'name' | 'id' | 'elementType'>;

export function useDocumentTracking(id: string, type: DocumentElementType) {
  const {emitEvent} = useContext(DocumentCommandEmitterContext);

  const track = useCallback(
    (payload: TrackPayload) => {
      emitEvent({
        type: CommandType.NOTIFY,
        payload: {
          id,
          elementType: type,
          ...payload,
        },
      });
    },
    [emitEvent]
  );

  const trackNavigation: DocumentTracker<TrackingNavigationValue> = useCallback(
    (target: TrackingNavigationValue) => {
      track({
        trackingType: DocumentTrackingType.NAVIGATION,
        value: target,
      });
    },
    [track]
  );

  const trackScroll: DocumentTracker<TrackingScrollValue> = useCallback(
    (scrollTop: TrackingScrollValue) => {
      track({
        trackingType: DocumentTrackingType.SCROLL,
        value: scrollTop,
      });
    },
    [track]
  );

  const trackItemViewed: DocumentTracker<TrackingItemViewedValue> = useCallback(
    (itemTitle: TrackingItemViewedValue) => {
      track({
        trackingType: DocumentTrackingType.ITEM_VIEWED,
        value: itemTitle,
      });
    },
    [track]
  );

  const trackItemClicked: DocumentTracker<TrackingItemClickedValue, TrackingItemClickedInfo> =
    useCallback(
      (target: TrackingItemClickedValue, info?: TrackingItemClickedInfo) => {
        track({
          trackingType: DocumentTrackingType.ITEM_CLICKED,
          value: target,
          info,
        });
      },
      [track]
    );

  const trackItemToggled: DocumentTracker<TrackingItemToggledValue, TrackingItemToggledInfo> =
    useCallback(
      (isOpen: TrackingItemToggledValue, info?: TrackingItemToggledInfo) => {
        track({
          trackingType: DocumentTrackingType.ITEM_TOGGLED,
          value: isOpen,
          info: info,
        });
      },
      [track]
    );

  const trackItemTabChanged: DocumentTracker<TrackingItemTabChangedValue> = useCallback(
    (target: TrackingItemTabChangedValue) => {
      track({
        trackingType: DocumentTrackingType.ITEM_TAB_CHANGED,
        value: target,
      });
    },
    [track]
  );

  const trackSort: DocumentTracker<TrackingSortValue, TrackingSortInfo> = useCallback(
    (column: TrackingSortValue, info?: TrackingSortInfo) => {
      track({
        trackingType: DocumentTrackingType.SORT,
        value: column,
        info,
      });
    },
    [track]
  );

  const trackFilter: DocumentTracker<TrackingFilterValue, TrackingFilterInfo> = useCallback(
    (dataKey: TrackingFilterValue, info?: TrackingFilterInfo) => {
      track({
        trackingType: DocumentTrackingType.FILTER,
        value: dataKey,
        info: info,
      });
    },
    [track]
  );

  const trackExport: DocumentTracker<void, void> = useCallback(() => {
    track({
      trackingType: DocumentTrackingType.EXPORT,
    });
  }, [track]);

  const trackPagination: DocumentTracker<TrackingPaginationValue, TrackingPaginationInfo> =
    useCallback(
      (page: TrackingPaginationValue, info?: TrackingPaginationInfo) => {
        track({
          trackingType: DocumentTrackingType.PAGINATION,
          value: page,
          info,
        });
      },
      [track]
    );

  const trackModeChanged: DocumentTracker<TrackingModeChangedValue> = useCallback(
    (mode: TrackingModeChangedValue) => {
      track({
        trackingType: DocumentTrackingType.MODE_CHANGED,
        value: mode,
      });
    },
    [track]
  );

  const trackInput: DocumentTracker<TrackingInputValue, TrackingInputInfo> = useCallback(
    (inputName: TrackingInputValue, info?: TrackingInputInfo) => {
      track({
        trackingType: DocumentTrackingType.INPUT,
        value: inputName,
        info,
      });
    },
    [track]
  );

  const trackModalOpened: DocumentTracker<TrackingModalOpenedValue, TrackingModalOpenedInfo> =
    useCallback(
      (target: TrackingModalOpenedValue, info?: TrackingModalOpenedInfo) => {
        track({
          trackingType: DocumentTrackingType.MODAL_OPENED,
          value: target,
          info,
        });
      },
      [track]
    );

  return {
    trackItemToggled,
    trackNavigation,
    trackItemViewed,
    trackItemClicked,
    trackScroll,
    trackExport,
    trackFilter,
    trackPagination,
    trackModalOpened,
    trackSort,
    trackItemTabChanged,
    trackInput,
    trackModeChanged,
  };
}
