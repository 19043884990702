import * as React from 'react';
import classes from './grid-layout-viewer.module.scss';
import {GridLayout} from '../../../types';
import classNames from 'classnames';
import {ChildRenderer} from '../../core/child-renderer.component';
import {useMemo} from 'react';
import {ChildRendererInjectedProps} from '../../../document-viewer.types';

export interface OwnProps extends GridLayout, Partial<ChildRendererInjectedProps> {
  className?: string;
}

export const GridLayoutViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {data, options} = props;

  const styles = useMemo(
    () =>
      data.map(d =>
        options.direction === 'row'
          ? {
              flex: d.weight,
            }
          : undefined
      ),
    [data, options.direction]
  );

  return (
    <div
      className={classNames(
        classes.GridLayoutViewer,
        options.direction === 'column' && classes.Column,
        props.className
      )}
    >
      {data.map((d, idx) => (
        <div className={classes.ContentWrapper} style={styles[idx]} key={idx}>
          <ChildRenderer children_={d.data} className={classes.MarginBottom} />
        </div>
      ))}
    </div>
  );
};

GridLayoutViewer.defaultProps = {
  options: {},
};
