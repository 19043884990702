import classNames from 'classnames';
import classes from './kpi-tree-popover-layout.module.scss';
import {HtmlTooltip} from 'ui-components';

interface Props {
  title: string | JSX.Element;
  subTitleItems: Array<{
    label: string;
    content: string | JSX.Element;
  }>;
  content: JSX.Element;
  children: any;
  className?: string;
}

export const KPITreePopoverLayout = (props: Props) => {
  const {title, subTitleItems, content, children, className} = props;

  return (
    <HtmlTooltip
      placement={'top'}
      interactive
      title={
        <div className={classNames(classes.KPITreePopoverLayout, className)}>
          <div className={classes.Header}>
            <div className={classes.Title}>{title}</div>
            <div className={classes.SubTitleItems}>
              {subTitleItems
                .filter(i => i)
                .map((item, index) => (
                  <div className={classes.Item} key={index}>
                    <span className={classes.Label}>{item.label}</span>
                    <span className={classes.Content}>{item.content}</span>
                  </div>
                ))}
            </div>
          </div>
          <div className={classes.PopoverContent}>{content}</div>
        </div>
      }
    >
      {children}
    </HtmlTooltip>
  );
};
