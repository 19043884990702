import {CSSProperties, memo, useCallback, useContext, useMemo} from 'react';
import classes from './kpi-edge.module.scss';
import {BaseEdge, Edge, EdgeLabelRenderer, EdgeProps, getBezierPath} from '@xyflow/react';
import {
  Checkbox,
  ChevronFullDownIcon,
  CloseIcon,
  DialogLayout,
  GearLightIcon,
  PopoverWrapper,
} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {
  MetricNode,
  MetricTreeRelationshipType,
} from '../../../../../../objects/models/metric-tree.model';
import {KPITreeContext} from '../../kpi-tree.context';
import {
  ICON_FOR_RELATIONSHIP_TYPE,
  INSIGNIFICANT_CORRELATION_COLOR,
  TreeMode,
} from '../../kpi-tree.consts';
import {
  correlationToText,
  getCorrelationBackgroundColor,
  getCorrelationTextColor,
} from '../../kpi-tree.utils.ts';
import classNames from 'classnames';
import {values} from 'lodash';
import {exists} from 'front-core';
import {KPIEdgePopover} from './kpi-edge-popover/kpi-edge-popover.component.tsx';

export type KPIEdgeData = {
  correlation: number;
  isSignificantCorrelation: boolean;
  relationshipType: MetricTreeRelationshipType;
  sourceMetric: MetricNode;
  targetMetric: MetricNode;
};

interface OwnProps extends EdgeProps<Edge<KPIEdgeData>> {}

const STYLE = {
  strokeWidth: 3,
};

export const KPIEdge = memo((props: OwnProps) => {
  const {id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data} = props;
  const {sourceMetric, targetMetric, correlation, isSignificantCorrelation, relationshipType} =
    data;
  const {t} = useTranslation();
  const {mode, onRemoveInputKPI, onChangeInputKPIRelationshipType} = useContext(KPITreeContext);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const onDeleteEdge = useCallback(
    () => onRemoveInputKPI(targetMetric.id, sourceMetric.id),
    [onRemoveInputKPI, targetMetric, sourceMetric]
  );
  const changeRelationshipType = useCallback(
    (relationshipType: MetricTreeRelationshipType) =>
      onChangeInputKPIRelationshipType(targetMetric.id, sourceMetric.id, relationshipType),
    [onChangeInputKPIRelationshipType, targetMetric, sourceMetric]
  );
  const bgColor = useMemo(() => {
    if (relationshipType === MetricTreeRelationshipType.CORRELATION) {
      return getCorrelationBackgroundColor(correlation, isSignificantCorrelation);
    }
    return INSIGNIFICANT_CORRELATION_COLOR;
  }, [correlation, isSignificantCorrelation, relationshipType]);
  const edgeStyle = useMemo(() => ({...STYLE, stroke: bgColor}), [bgColor]);
  const edgeContent = useMemo(() => {
    if (relationshipType === MetricTreeRelationshipType.CORRELATION) {
      return exists(correlation) ? (
        <span className={classes.Label}>{correlationToText(correlation)}</span>
      ) : null;
    }
    const Icon = ICON_FOR_RELATIONSHIP_TYPE[relationshipType];
    if (Icon) {
      return <Icon className={classes.RelationshipEdgeIcon} />;
    }
    if (!exists(Icon) && mode === TreeMode.EDIT) {
      return <GearLightIcon className={classes.RelationshipEdgeIcon} />;
    }
  }, [relationshipType, correlation, mode]);
  const correlationTextColor = useMemo(
    () => getCorrelationTextColor(correlation, isSignificantCorrelation),
    [isSignificantCorrelation, correlation]
  );
  const relationshipOptions = useMemo(
    () =>
      values(MetricTreeRelationshipType).map(type => ({
        value: type,
        label: t(TransKeys.KPI_TREE.INPUT_METRIC_RELATIONSHIP_TYPE[type.toUpperCase()].LABEL),
        icon: ICON_FOR_RELATIONSHIP_TYPE[type],
        isSelected: relationshipType === type,
      })),
    [relationshipType, t]
  );
  const styles: CSSProperties = useMemo(
    () => ({
      position: 'absolute',
      transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
      background: relationshipType === MetricTreeRelationshipType.CORRELATION ? bgColor : 'white',
      color:
        relationshipType === MetricTreeRelationshipType.CORRELATION
          ? correlationTextColor
          : undefined,
    }),
    [labelX, labelY, bgColor, correlationTextColor, relationshipType]
  );

  return (
    <>
      <BaseEdge id={id} {...props} path={edgePath} style={edgeStyle} />
      <EdgeLabelRenderer>
        {mode === TreeMode.VIEW && edgeContent && (
          <KPIEdgePopover
            correlation={correlation}
            isSignificantCorrelation={isSignificantCorrelation}
            relationshipType={relationshipType}
            sourceMetric={sourceMetric}
            targetMetric={targetMetric}
          >
            <div
              style={styles}
              className={classNames(
                classes.KPIEdge,
                relationshipType !== MetricTreeRelationshipType.CORRELATION && classes.Icon
              )}
            >
              {edgeContent}
            </div>
          </KPIEdgePopover>
        )}
        {mode === TreeMode.EDIT && (
          <PopoverWrapper
            buttonRenderer={({isOpen, onClick}) => (
              <div
                style={styles}
                className={classNames(classes.KPIEdge, classes.EditMode, isOpen && classes.Active)}
                onClick={onClick}
              >
                {edgeContent}
                <div
                  className={classes.Divider}
                  style={{
                    backgroundColor:
                      relationshipType === MetricTreeRelationshipType.CORRELATION
                        ? correlationTextColor
                        : undefined,
                  }}
                />
                <div className={classes.ChevronWrapper}>
                  <ChevronFullDownIcon className={classes.Chevron} />
                </div>
              </div>
            )}
          >
            <DialogLayout>
              <div className={classes.EdgeActions}>
                <div className={classes.RelationTypeTitle}>
                  {t(TransKeys.KPI_TREE.ACTIONS.RELATION_TYPE_SELECT_TITLE)}
                </div>
                <div className={classes.RelationshipOptions}>
                  {relationshipOptions.map(option => (
                    <div
                      onClick={
                        option.isSelected ? undefined : () => changeRelationshipType(option.value)
                      }
                      className={classNames(classes.Option, option.isSelected && classes.Selected)}
                      key={option.value}
                    >
                      <Checkbox checked={option.isSelected} />
                      <div className={classes.OptionLabel}>
                        {option.icon && <option.icon />}
                        <span>{option.label}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.ActionsDivider} />
                <div className={classes.RemoveAction} onClick={() => onDeleteEdge()}>
                  <CloseIcon className={classes.RemoveIcon} />
                  <span>{t(TransKeys.KPI_TREE.ACTIONS.REMOVE_INPUT_KPI)}</span>
                </div>
              </div>
            </DialogLayout>
          </PopoverWrapper>
        )}
      </EdgeLabelRenderer>
    </>
  );
});
