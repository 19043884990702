import * as React from 'react';

export interface OwnProps {
  type: string;
}

// todo: make is look better
export const UnsupportedViewer: React.FC<OwnProps> = (props: OwnProps) => {
  return <div>UNSUPPORTED TYPE [{props.type}]</div>;
};
