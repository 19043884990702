import {exists, number2k} from 'front-core';

export const getPercentageValueFormatter = (isPercentage: boolean = true) => {
  return (value: number) => {
    if (!exists(value)) {
      return null;
    }
    if (!isPercentage) {
      return number2k(value);
    }
    return `${number2k(value * 100)}%`;
  };
};
