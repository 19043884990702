import * as React from 'react';
import classNames from 'classnames';
import {SequenceColumnOptions} from '../../../../../types';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import classes from './data-columns.module.scss';
import {exists} from 'front-core';
import {useContext, useMemo} from 'react';
import {get, isArray} from 'lodash';
import {ArrowRightIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {DataColumnProps} from '../../smart-table.types';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';

interface OwnProps extends DataColumnProps<SequenceColumnOptions> {}

type AllProps = OwnProps;

const SequenceDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {mode, commandDataKey} = column.typeOptions;

  const value = useMemo(
    () => (isArray(data[column.key]) ? data[column.key] : [data[column.key]]),
    [data, column]
  );
  const command = useMemo(
    () => (commandDataKey ? get(data, commandDataKey) : undefined),
    [data, commandDataKey]
  );
  const separator = useMemo(() => {
    if (mode === 'arrow') {
      return (
        <div className={classNames(classes.Separator, classes.Arrow)}>
          <ArrowRightIcon className={classes.Icon} />
        </div>
      );
    }
    return <div className={classes.Separator}>,</div>;
  }, [mode]);

  return (
    <div className={classNames(classes.SequenceDataColumn, className)}>
      {value.map((v, idx) => (
        <React.Fragment key={idx}>
          <div
            onClick={get(command, idx) ? () => emitEvent(command[idx]) : undefined}
            className={classNames(classes.Value, get(command, idx) && classes.Clickable)}
          >
            {v}
          </div>
          {separator}
        </React.Fragment>
      ))}
    </div>
  );
};

const protector: Protector = (props: OwnProps) => exists(props.data[props.column.key]);
export const SequenceDataColumn = withDataColumnProtector(protector)(SequenceDataColumnComponent);
