import {TrendModelSampleInsight} from './objects.ts';
import {formatPercentageValueWithPrefix, getArrowIndication} from '../../insights.utils.ts';
import {
  SampledModelMeasuredItemCompoundNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import {useMemo} from 'react';
import {InfinityLightIcon} from 'ui-components';

export const TrendModelSampleInsightFormatter = (props: TrendModelSampleInsight) => {
  const {
    sentenceParts,
    sampledModelMeasuredItemCompoundName: compoundName,
    granularityPlural,
  } = props;
  const formattedPercentageValue = useMemo(() => {
    if (sentenceParts.value === null) {
      return <InfinityLightIcon />;
    }
    return `${getArrowIndication(sentenceParts.value)} ${formatPercentageValueWithPrefix(
      sentenceParts.value
    )}`;
  }, [sentenceParts]);

  return (
    <SentenceContainer>
      <SentencePart text={'The'} />
      <SampledModelMeasuredItemCompoundNameSentencePart compoundName={compoundName} />
      <SentencePart
        bold
        text={`${sentenceParts.trendDescription}d for ${sentenceParts.trendNumberOfPeriods}-${granularityPlural}-in-a-row,`}
      />
      <SentencePart
        text={`Starting at ${sentenceParts.trendStartDate} with total ${sentenceParts.changeDescription} of`}
      />
      <SentencePart bold text={formattedPercentageValue} />
    </SentenceContainer>
  );
};
