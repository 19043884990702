import * as React from 'react';
import {useContext, useMemo} from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {EnhancedGroup, EnhancedRect} from '../../../../../core/konva/components';
import {ItemDataset, ItemValue} from '../../chart-data.types';
import {HorizontalGridLayoutInjectedProps} from '../../layouts/grid-layout.component';
import {zip, max} from 'lodash';
import {chartClasses} from '../../chart.consts';
import {pixelInRangeFactory} from '../../chart.utils';
import {ConfidenceInterval} from '../../components/confidence-interval.component';
import {colorAlphaTransformer} from '../../../../../utils/colors';

interface OwnProps extends HorizontalGridLayoutInjectedProps {
  datasets: ItemDataset[];
  onDatasetClick?: (ds: ItemDataset) => void;
  yLabelSuffix?: string;
  errorBar?: boolean;
}

type AllProps = OwnProps;

const BAR_MARGIN = 8;
const MIN_BAR_WIDTH = 4;
const BAR_STROKE_WIDTH = 1;

export const BarChartDatasetContainer: React.FC<AllProps> = (props: AllProps) => {
  const {
    width,
    height,
    x,
    y,
    datasets,
    maxValue,
    xTickSize,
    onDatasetClick,
    labels,
    yLabelSuffix,
    errorBar,
  } = props;
  const context = useContext(StageContext);
  const {style} = context;
  /**
   * Data computed properties
   */
  const barsValues = useMemo(() => zip(...datasets.map(d => d.data)), [datasets]);
  const bars: any[][] = useMemo(
    () =>
      barsValues.map((barGroupValues: ItemValue[], dataIndex) => {
        const barWidth = max([(xTickSize - BAR_MARGIN / 2) / datasets.length, MIN_BAR_WIDTH]);
        const margin = xTickSize - barWidth * barGroupValues.length;
        return barGroupValues.map((item, datasetIndex) => {
          const dataset = datasets[datasetIndex];
          const calcY = pixelInRangeFactory([0, maxValue], height);

          return {
            value: item.value,
            upper: item.upper,
            lower: item.lower,
            width: barWidth - BAR_STROKE_WIDTH,
            height: calcY(item.value),
            color: dataset.color,
            x: dataIndex * xTickSize + datasetIndex * barWidth - xTickSize / 2 + margin,
            y: height - calcY(item.value),
            highlight: dataset.highlight,
            datasetLabel: dataset.label,
            label: labels[dataIndex],
          };
        });
      }),
    [barsValues, xTickSize, datasets, maxValue, height, labels]
  );
  const errorBars: any[][] = useMemo(
    () =>
      barsValues.map((barGroupValues: ItemValue[], dataIndex) => {
        const barWidth = (xTickSize - BAR_MARGIN) / datasets.length;
        const margin = (xTickSize - barWidth * barGroupValues.length) / 2;

        return barGroupValues.map((item, datasetIndex) => {
          const dataset = datasets[datasetIndex];
          const calcY = pixelInRangeFactory([0, maxValue], height);
          const y = height - calcY(item.value);
          const upperDiff = calcY(Math.abs(item.value - item.upper));
          const lowerDiff = calcY(Math.abs(item.value - item.lower));

          return {
            y,
            x:
              dataIndex * xTickSize +
              datasetIndex * barWidth +
              margin / 2 -
              xTickSize / 2 +
              barWidth / 2,
            upperDiff,
            lowerDiff,
            color: dataset.color,
            maxWidth: barWidth - BAR_STROKE_WIDTH,
          };
        });
      }),
    [barsValues, xTickSize, datasets, maxValue, height]
  );
  /**
   * Render
   */
  return (
    <EnhancedGroup width={width} height={height} x={x} y={y}>
      {bars.map((barGroup: any[], i) =>
        barGroup.map((bar, datasetIndex) => (
          <EnhancedRect
            key={`bar_${i}_${datasetIndex}`}
            x={bar.x}
            y={bar.y}
            width={bar.width}
            height={bar.height}
            cornerRadius={7}
            stroke={bar.highlight ? style.highlightColor : 'transparent'}
            strokeWidth={bar.height > 0 ? BAR_STROKE_WIDTH : 0}
            fillLinearGradientStartPoint={{x: 0, y: 0}}
            fillLinearGradientEndPoint={{x: bar.width, y: bar.height}}
            fillLinearGradientColorStops={[
              0,
              colorAlphaTransformer(bar.color, 0.6, true),
              0.25,
              colorAlphaTransformer(bar.color, 0.8, true),
              1,
              colorAlphaTransformer(bar.color, 1, true),
            ]}
            onClick={onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined}
            tooltipEnabled
            tooltipData={{
              value: (
                <div className={chartClasses.ChartTooltipInfo}>
                  <div className={chartClasses.Value}>
                    {bar.value.toLocaleString()}
                    {yLabelSuffix}
                    {errorBar && (
                      <span className={chartClasses.CI}>
                        ({bar.lower}
                        {yLabelSuffix}, {bar.upper}
                        {yLabelSuffix})
                      </span>
                    )}
                  </div>
                </div>
              ),
              label: bar.label,
              datasetName: bar.datasetLabel,
              color: bar.color,
            }}
          />
        ))
      )}
      {errorBar &&
        errorBars.map((barGroup: any[], i) =>
          barGroup.map((errorBar, datasetIndex) => (
            <ConfidenceInterval key={`error_bar_${i}_${datasetIndex}`} {...errorBar} />
          ))
        )}
    </EnhancedGroup>
  );
};

BarChartDatasetContainer.defaultProps = {
  yLabelSuffix: '',
};
