import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './app-tabs.module.scss';
import {Badge} from '../../data-display/badge/badge.component';
import {exists} from 'front-core';
import {takeRight} from 'lodash';
import {Select} from '../../../forms/inputs/select/select.component';
import {PendoAnchors, usePendoAnchor} from '../../../../hooks/use-pendo-anchor.hook';
import {TooltipIfOverflow} from '../../generic/tooltips/tooltips.component';

interface AppTab {
  key: string;
  label: string | JSX.Element;
  count?: number;
  disabled?: boolean;
  tabRef?: React.RefObject<HTMLDivElement>;
}

interface OwnProps {
  prefix?: string;
  tabs: AppTab[];
  selected: string;
  onChange: (tabKey: string) => void;
  renderRight?: () => any;
  border?: boolean;
  className?: string;
  visibleTabsCount?: number;
  fullWidth?: boolean;
}

type AllProps = OwnProps;

const MIN_COUNT_FOR_SELECT_SEARCH = 6;

export const AppTabs: React.FC<AllProps> = (props: AllProps) => {
  const {
    prefix,
    tabs: tabsFromProps,
    selected,
    onChange,
    renderRight,
    border,
    fullWidth,
    visibleTabsCount,
    className,
  } = props;
  const tabsRef = usePendoAnchor(PendoAnchors.APP_TABS);
  const tabs = useMemo(
    () => (exists(visibleTabsCount) ? tabsFromProps.slice(0, visibleTabsCount) : tabsFromProps),
    [tabsFromProps, visibleTabsCount]
  );
  const moreTabs = useMemo(
    () =>
      exists(visibleTabsCount)
        ? takeRight(tabsFromProps, tabsFromProps.length - visibleTabsCount).map(s => ({
            value: s.key,
            label: s.label as string,
          }))
        : [],
    [tabsFromProps, visibleTabsCount]
  );
  const moreSelected = useMemo(
    () => moreTabs.findIndex(v => v.value === selected) > -1,
    [moreTabs, selected]
  );

  const renderTabContent = (t: AppTab) =>
    t.count ? (
      <Badge badgeContent={t.count} badgeContentClassName={classes.TabBadgeCount}>
        <span className={classes.Label}>{t.label}</span>
      </Badge>
    ) : (
      <TooltipIfOverflow title={t.label}>
        <span className={classes.Label}>{t.label}</span>
      </TooltipIfOverflow>
    );

  if (visibleTabsCount === 0) {
    return (
      <Select
        value={selected}
        placeholder={`${tabsFromProps.length - visibleTabsCount} More...`}
        onChange={onChange}
        options={{
          options: moreTabs,
        }}
        dropdownButtonClassName={classes.SelectItemButton}
        clearable={false}
        searchable={moreTabs.length > MIN_COUNT_FOR_SELECT_SEARCH}
      />
    );
  }

  return (
    <div
      className={classNames(
        classes.AppTabs,
        border && classes.Border,
        fullWidth && classes.FullWidth,
        className
      )}
      ref={tabsRef}
    >
      {prefix && <div className={classes.Prefix}>{prefix}</div>}
      {tabs.map(t => (
        <div
          key={t.key}
          onClick={t.disabled ? undefined : e => onChange(t.key)}
          className={classNames(
            classes.TabButton,
            t.key === selected && classes.Selected,
            t.disabled && classes.Disabled
          )}
          {...(t.tabRef ? {ref: t.tabRef} : {})}
        >
          {renderTabContent(t)}
        </div>
      ))}
      {moreTabs.length > 0 && (
        <div
          key={'more'}
          className={classNames(classes.TabButton, moreSelected && classes.Selected)}
        >
          <Select
            value={selected}
            placeholder={`${tabsFromProps.length - visibleTabsCount} More...`}
            onChange={onChange}
            options={{
              options: moreTabs,
            }}
            dropdownButtonClassName={classNames(classes.More, moreSelected && classes.Selected)}
            className={classNames(classes.SelectItem)}
            clearable={false}
            searchable={moreTabs.length > MIN_COUNT_FOR_SELECT_SEARCH}
          />
        </div>
      )}
      {renderRight && <div className={classes.Right}>{renderRight()}</div>}
    </div>
  );
};

AppTabs.defaultProps = {
  border: true,
};
