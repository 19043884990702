import * as React from 'react';
import classNames from 'classnames';
import classes from './confusion-matrix-viewer.module.scss';
import {ConfusionMatrix, ConfusionMatrixIndex} from '../../../../../../types';
import {useMemo} from 'react';
import {calcPerformanceMetricsForConfusionMatrix} from '../../habit-moment-viewer.utils';
import {number2k} from 'front-core';
import TransKeys from 'translations';
import {HoverHelperTip} from '../../../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {sum} from 'lodash';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';

interface OwnProps {
  matrix: ConfusionMatrix;
  className?: string;
}

type AllProps = OwnProps;

export const ConfusionMatrixViewer: React.FC<AllProps> = (props: AllProps) => {
  const {matrix, className} = props;
  const metrics = useMemo(() => calcPerformanceMetricsForConfusionMatrix(matrix), [matrix]);
  const {t} = useDocumentTranslation();
  const values = useMemo(() => {
    const total = sum(matrix);
    return [
      {
        label: t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.TRUE_POSITIVE.LABEL),
        helperText: t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.TRUE_POSITIVE.HELPER_TEXT),
        percentage: `${number2k((matrix[ConfusionMatrixIndex.TRUE_POSITIVE] / total) * 100)}%`,
        abs: matrix[ConfusionMatrixIndex.TRUE_POSITIVE],
      },
      {
        label: t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.TRUE_NEGATIVE.LABEL),
        helperText: t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.TRUE_NEGATIVE.HELPER_TEXT),
        percentage: `${number2k((matrix[ConfusionMatrixIndex.TRUE_NEGATIVE] / total) * 100)}%`,
        abs: matrix[ConfusionMatrixIndex.TRUE_NEGATIVE],
      },
      {
        label: t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.FALSE_POSITIVE.LABEL),
        helperText: t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.FALSE_POSITIVE.HELPER_TEXT),
        percentage: `${number2k((matrix[ConfusionMatrixIndex.FALSE_POSITIVE] / total) * 100)}%`,
        abs: matrix[ConfusionMatrixIndex.FALSE_POSITIVE],
      },
      {
        label: t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.FALSE_NEGATIVE.LABEL),
        helperText: t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.FALSE_NEGATIVE.HELPER_TEXT),
        percentage: `${number2k((matrix[ConfusionMatrixIndex.FALSE_NEGATIVE] / total) * 100)}%`,
        abs: matrix[ConfusionMatrixIndex.FALSE_NEGATIVE],
      },
    ];
  }, [t, matrix]);

  const renderItem = (idx: number) => (
    <div className={classes.Item} key={idx}>
      <div className={classes.Title}>
        <span>{values[idx].label}</span>
        {values[idx].helperText && (
          <HoverHelperTip title={values[idx].helperText} className={classes.Helper} />
        )}
      </div>
      <div className={classes.Value}>
        <span className={classes.Percentage}>{values[idx].percentage}</span>
        <span className={classes.Abs}>{values[idx].abs}</span>
      </div>
    </div>
  );

  return (
    <div className={classNames(classes.ConfusionMatrixViewer, className)}>
      {/* Matrix */}
      <div className={classes.Matrix}>
        <div className={classNames(classes.Label, classes.XLabel)}>
          {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.X_LABEL)}
        </div>
        <div className={classNames(classes.Label, classes.YLabel)}>
          <div className={classes.Rotate}>
            {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.Y_LABEL)}
          </div>
        </div>
        <div className={classNames(classes.BooleanLabel, classes.TrueY)}>
          <div className={classes.Rotate}>
            {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.TRUE_LABEL)}
          </div>
        </div>
        <div className={classNames(classes.BooleanLabel, classes.FalseY)}>
          <div className={classes.Rotate}>
            {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.FALSE_LABEL)}
          </div>
        </div>
        <div className={classNames(classes.BooleanLabel, classes.TrueX)}>
          {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.TRUE_LABEL)}
        </div>
        <div className={classNames(classes.BooleanLabel, classes.FalseX)}>
          {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.FALSE_LABEL)}
        </div>
        <div className={classes.Content}>
          {renderItem(ConfusionMatrixIndex.TRUE_NEGATIVE)}
          {renderItem(ConfusionMatrixIndex.FALSE_POSITIVE)}
          {renderItem(ConfusionMatrixIndex.FALSE_NEGATIVE)}
          {renderItem(ConfusionMatrixIndex.TRUE_POSITIVE)}
        </div>
      </div>
      {/* Metrics */}
      <div className={classes.Metrics}>
        <div className={classes.Label}>
          {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.ACCURACY.LABEL)}
          <HoverHelperTip
            className={classes.Helper}
            title={t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.ACCURACY.HELPER_TEXT)}
          />
        </div>
        <div className={classes.Value}>{number2k(metrics.accuracy)}</div>
        <div className={classes.Label}>
          {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.PRECISION.LABEL)}
          <HoverHelperTip
            className={classes.Helper}
            title={t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.PRECISION.HELPER_TEXT)}
          />
        </div>
        <div className={classes.Value}>{number2k(metrics.precision)}</div>
        <div className={classes.Label}>
          {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.RECALL.LABEL)}
          <HoverHelperTip
            className={classes.Helper}
            title={t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.RECALL.HELPER_TEXT)}
          />
        </div>
        <div className={classes.Value}>{number2k(metrics.recall)}</div>
        <div className={classes.Label}>
          {t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.F1_SCORE.LABEL)}
          <HoverHelperTip
            className={classes.Helper}
            title={t(TransKeys.DOCUMENT_VIEWER.CONFUSION_MATRIX.F1_SCORE.HELPER_TEXT)}
          />
        </div>
        <div className={classes.Value}>{number2k(metrics.f1Score)}</div>
      </div>
    </div>
  );
};

ConfusionMatrixViewer.defaultProps = {};
