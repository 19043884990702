import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {modelCreated, modelDeleted, modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {workflowToastCreator} from '../toasts.actions';
import {WorkflowsActionType} from './workflows.actions';
import {
  createWorkflowNetworkRequest,
  deleteWorkflowNetworkRequest,
  exportWorkflowNetworkRequest,
  renameWorkflowNetworkRequest,
  setWorkflowOwnerNetworkRequest,
  setWorkflowTeamNetworkRequest,
  updateWorkflowNetworkRequest,
  updateWorkflowStatusNetworkRequest,
} from '../../http/workflows.network-requests';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {demoProductLimitedActionsFilter} from '../store.utils';
import {showToastMessage} from '../interface/interface.actions.ts';
import {ToastType} from '../../objects/system/toast-type.enum.ts';

export const createWorkflowEpic: Epic = createRequestEpic(
  {
    types: [WorkflowsActionType.CREATE_WORKFLOW],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.CREATE_WORKFLOW,
    request: createWorkflowNetworkRequest,
    onSuccess: workflow => [
      notifyEvent(AmplitudeEvent.WORKFLOW_CREATED, {id: workflow.id}),
      workflowToastCreator('CREATE_SUCCESS'),
      modelCreated(workflow, ModelKey.WORKFLOW),
    ],
    onError: err => [workflowToastCreator('CREATE_ERROR')],
  },
  HttpClient
);

export const exportWorkflowEpic: Epic = createRequestEpic(
  {
    types: [WorkflowsActionType.EXPORT_WORKFLOW],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.EXPORT_WORKFLOW,
    request: exportWorkflowNetworkRequest,
    onSuccess: data => [
      notifyEvent(AmplitudeEvent.WORKFLOW_EXPORTED, data),
      showToastMessage('Processing your request', ToastType.SUCCESS),
    ],
    onError: err => [showToastMessage('Error while exporting data', ToastType.ERROR)],
  },
  HttpClient
);

export const updateWorkflowEpic: Epic = createRequestEpic(
  {
    types: [WorkflowsActionType.UPDATE_WORKFLOW],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_WORKFLOW,
    request: updateWorkflowNetworkRequest,
    onSuccess: workflow => [
      notifyEvent(AmplitudeEvent.WORKFLOW_UPDATED, {id: workflow.id}),
      workflowToastCreator('UPDATE_SUCCESS'),
      modelCreated(workflow, ModelKey.WORKFLOW),
    ],
    onError: err => [workflowToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const deleteWorkflowEpic: Epic = createRequestEpic(
  {
    types: [WorkflowsActionType.DELETE_WORKFLOW],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.DELETE_WORKFLOW,
    request: deleteWorkflowNetworkRequest,
    onSuccess: (res, payload) => [
      notifyEvent(AmplitudeEvent.WORKFLOW_DELETED, {id: payload}),
      workflowToastCreator('DELETE_SUCCESS'),
      modelDeleted(payload, ModelKey.WORKFLOW),
    ],
    onError: err => [workflowToastCreator('DELETE_ERROR')],
  },
  HttpClient
);

export const setWorkflowOwnerEpic: Epic = createRequestEpic(
  {
    types: [WorkflowsActionType.SET_WORKFLOW_OWNER],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.SET_WORKFLOW_OWNER,
    request: setWorkflowOwnerNetworkRequest,
    onSuccess: workflow => [
      notifyEvent(AmplitudeEvent.SET_WORKFLOW_OWNER, {
        id: workflow.id,
        owner_id: workflow.ownerId,
      }),
      modelUpdated(workflow, ModelKey.WORKFLOW),
    ],
    onError: err => [workflowToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const setWorkflowTeamEpic: Epic = createRequestEpic(
  {
    types: [WorkflowsActionType.UPDATE_WORKFLOW_TEAM],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_WORKFLOW_TEAM,
    request: setWorkflowTeamNetworkRequest,
    onSuccess: workflow => [
      notifyEvent(AmplitudeEvent.UPDATE_WORKFLOW_TEAM, {
        id: workflow.id,
        team_id: workflow.team_id,
      }),
      modelUpdated(workflow, ModelKey.WORKFLOW),
    ],
    onError: err => [workflowToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const updateWorkflowStatusEpic: Epic = createRequestEpic(
  {
    types: [WorkflowsActionType.UPDATE_WORKFLOW_STATUS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.UPDATE_WORKFLOW_STATUS,
    request: updateWorkflowStatusNetworkRequest,
    onSuccess: workflow => [
      notifyEvent(AmplitudeEvent.UPDATE_WORKFLOW_STATUS, {
        id: workflow.id,
        status: workflow.status,
      }),
      modelUpdated(workflow, ModelKey.WORKFLOW),
    ],
    onError: err => [workflowToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const renameWorkflowEpic: Epic = createRequestEpic(
  {
    types: [WorkflowsActionType.RENAME_WORKFLOW],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.RENAME_WORKFLOW,
    request: renameWorkflowNetworkRequest,
    onSuccess: workflow => [
      notifyEvent(AmplitudeEvent.RENAME_WORKFLOW, {
        id: workflow.id,
        name: workflow.name,
      }),
      modelUpdated(workflow, ModelKey.WORKFLOW),
    ],
    onError: err => [workflowToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);
