export enum ModelType {
  SIGNAL = 'SIGNAL',
  METRIC = 'METRIC',
  MILESTONE = 'MILESTONE',
  FUNNEL = 'FUNNEL',
  OPPORTUNITY = 'OPPORTUNITY',
  LOOP = 'LOOP',
  EXPERIMENT = 'EXPERIMENT',
  ANALYSIS = 'ANALYSIS',
  ANALYSIS_TYPE = 'ANALYSIS_TYPE',
  ANALYSIS_RESULT = 'ANALYSIS_RESULT',
  ANALYSIS_FOLDER = 'ANALYSIS_FOLDER',
  FEATURE = 'FEATURE',
  SEGMENT = 'SEGMENT',
  CONTENT = 'CONTENT',
  PHENOMENA_DOC = 'PHENOMENA_DOC',
  PHENOMENA = 'PHENOMENA',
  EVENT_GROUP = 'EVENT_GROUP',
  POWER_USERS_SEGMENT = 'POWER_USERS_SEGMENT',
  SEGMENT_PREFERENCES = 'SEGMENT_PREFERENCES',
}
