import * as React from 'react';
import classNames from 'classnames';
import {OutOfColumnOptions} from '../../../../../types';
import {useMemo} from 'react';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists, number2k} from 'front-core';
import classes from './data-columns.module.scss';
import tableClasses from '../../smart-table.module.scss';
import {getDataValueKey} from '../../smart-table.utils';
import {DataColumnProps} from '../../smart-table.types';

interface OwnProps extends DataColumnProps<OutOfColumnOptions> {}

type AllProps = OwnProps;

const OutOfDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, dataColumnOptions, className} = props;
  const {outOfDataKey} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);

  const value = useMemo(() => data[valueDataKey], [valueDataKey, data]);
  const versus = useMemo(() => data[outOfDataKey], [outOfDataKey, data]);
  const unit = useMemo(
    () => dataColumnOptions?.unit || column.options.unit || '',
    [column.options, dataColumnOptions]
  );

  return (
    <div className={classNames(classes.OutOfDataColumn, className)}>
      <span className={classes.Value}>
        {number2k(value)}
        {unit}
      </span>
      {exists(versus) && (
        <span className={classNames(classes.Secondary, tableClasses.ShowOnHover)}>
          <span className={classes.SecondaryStr}>/</span>
          {number2k(versus)}
          {unit}
        </span>
      )}
    </div>
  );
};
const protector: Protector = (props: OwnProps) =>
  exists(props.data[getDataValueKey(props.column, 'valueDataKey')]);
export const OutOfDataColumn = withDataColumnProtector(protector)(OutOfDataColumnComponent);
