import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {
  confoundersValadator,
  startEndDatesValidator,
  timeRangeValidator,
  treatmentValidator,
  validateSignalBoundingDays,
  validateSignalTimeframeBoundingFor,
} from '../shared-validators';
import {ParameterType} from 'ui-components';
import pluralize from 'pluralize';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
  confoundersAnalysisParametersTransformer,
} from '../shared-transformers';

import {querySchemaValidator} from '../../../../../objects/dto/query-builder.dto.ts';

export const analysis107ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal: querySchemaValidator(),
      ui_goal_type: yup.string().oneOf(['retention', 'conversion', 'existing']).required(),
      is_order_matter: yup.boolean().nullable(),
      filter_not_engaging_users: yup.boolean().required(),
      is_unique: yup.boolean().nullable(),
      ...treatmentValidator('event_tag'),
      n_days_after_ref_date: yup
        .number()
        .min(1)
        .required()
        .test(
          validateSignalBoundingDays({
            signalQueryParameter: 'goal',
          })
        ),
      ...confoundersValadator,
      excluded_events: yup.array().of(yup.string()).nullable(),
      population_filter: querySchemaValidator(false),
      max_length: yup.number().min(2).max(10).nullable(),
      filter_min_adoption: yup.number().min(0).max(100).nullable(),
    })
    .required(),
  runParameters: startEndDatesValidator
    .required()
    .test(
      timeRangeValidator(
        'n_days_after_ref_date',
        null, // default day
        'time frame can’t be shorter than maximum days to analyze parameter',
        true
      )
    )
    .test(
      validateSignalTimeframeBoundingFor({
        signalQueryParameter: 'goal',
        parameterName: 'analysis goal',
      })
    ),
});

export const analysis107ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  [values, schema] = confoundersAnalysisParametersTransformer(values, schema);
  const newValues = {...values};

  if ('goal' in schema) {
    schema['goal'].name = 'Goal';
    schema['goal'].order = 1;
  }
  if ('reference_date' in schema) {
    schema['reference_date'].order = 2;
  }
  if ('is_order_matter' in schema) {
    schema['is_order_matter'].name = 'Event sequence';
    schema['is_order_matter'].order = 3;
  }

  // remove user journey parameters from UI
  delete schema['sequence_filter_event_min_adoption'];
  delete schema['sequence_filter_event_max_adoption'];
  delete schema['sequence_filter_included_events'];
  delete schema['sequence_filter_excluded_events'];

  delete schema['event_tag'];
  delete schema['treatments_signals'];
  schema['event_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'event_tag',
    order: 4,
  };
  newValues['event_tag'] = [
    ...(newValues['event_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  if ('filter_not_engaging_users' in schema) {
    const entity = newValues['entity'].toLowerCase();
    const entityName = productEntities[entity]?.name || entity;
    schema['filter_not_engaging_users'].name = `Filter ${pluralize(
      entityName || ''
    )} with 0 events`;
    schema['filter_not_engaging_users'].order = 5;
  }
  if ('n_days_after_ref_date' in schema) {
    schema['n_days_after_ref_date'].name = 'Maximum days to analyze';
    schema['n_days_after_ref_date'].order = 6;
  }
  if ('max_length' in schema) {
    schema['max_length'].order = 8;
  }
  if ('filter_min_adoption' in schema) {
    schema['filter_min_adoption'].order = 9;
  }
  return [newValues, schema];
};
