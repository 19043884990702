import {TabDefinition} from './components/milestone-tab-content.component.tsx';
import {MilestoneType} from '../../../../objects/models/milestone.model.ts';
import {ActiveQueryBuilder} from '../../../shared/core/query-builders/active-query-builder/active-query-builder.component.tsx';
import {BehavioralMilestoneQueryBuilder} from './behavioral-milestone-query-builder/behavioral-milestone-query-builder.component.tsx';
import {LiteralValueType} from 'ui-components';
import {UserPropertyColumnQueryBuilder} from '../../../shared/core/query-builders/user-property-column-query-builder/user-property-column-query-builder.component.tsx';
import {RevenueChurnQueryBuilder} from '../../../shared/core/query-builders/revenue-churn-query-builder/revenue-churn-query-builder.component.tsx';
import {EventGroupQueryBuilder} from '../../../shared/core/query-builders/event-group-query-builder/event-group-query-builder.component.tsx';
import {TableEventsQueryBuilder} from '../../../shared/core/query-builders/table-events-query-builder/table-events-query-builder.component.tsx';

export const MILESTONE_FORM_TABS: TabDefinition[] = [
  {
    key: MilestoneType.ACTIVE,
    Builder: ActiveQueryBuilder,
  },
  {
    key: MilestoneType.JOIN_DATE,
    Builder: BehavioralMilestoneQueryBuilder,
    signalFilters: {
      literalType: LiteralValueType.DATE,
    },
    fallbackBuilder: UserPropertyColumnQueryBuilder,
  },
  {
    key: MilestoneType.TRIAL_START,
    Builder: BehavioralMilestoneQueryBuilder,
    signalFilters: {
      literalType: LiteralValueType.DATE,
    },
    fallbackBuilder: UserPropertyColumnQueryBuilder,
  },
  {
    key: MilestoneType.CHURN,
    Builder: RevenueChurnQueryBuilder,
  },
  {
    key: MilestoneType.ERROR,
    Builder: EventGroupQueryBuilder,
  },
  {
    key: MilestoneType.PAYMENT,
    Builder: TableEventsQueryBuilder,
  },
  {
    key: MilestoneType.FIRST_PAYMENT,
    Builder: BehavioralMilestoneQueryBuilder,
    signalFilters: {
      literalType: LiteralValueType.DATE,
    },
    fallbackBuilder: UserPropertyColumnQueryBuilder,
  },
  {
    key: MilestoneType.PAYMENT_FAILURE,
    Builder: EventGroupQueryBuilder,
  },
  {
    key: MilestoneType.CUSTOM,
    Builder: BehavioralMilestoneQueryBuilder,
    signalFilters: {
      literalType: LiteralValueType.DATE,
    },
    allowManyDefinitions: true,
    fallbackBuilder: UserPropertyColumnQueryBuilder,
  },
];
