import * as React from 'react';
import classes from './rca-trends-viewer.module.scss';
import {RCATrendsFigure} from '../../../../types';
import {useContext} from 'react';
import {exists} from 'front-core';
import classNames from 'classnames';
import {SentenceViewer} from '../../../base-viewers/sentence-viewer/sentence-viewer.component';
import {ArrowRightIcon} from '../../../../../../simple/controls/icons/icons.component';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';

export interface OwnProps extends RCATrendsFigure {}

const SENTENCE_OPTIONS = {
  size: 'medium',
};

const isPositive = (value: number, higherIsBetter: boolean) => {
  if (!exists(value)) {
    return undefined;
  }
  const res = value > 0;
  if (higherIsBetter === false) {
    return !res;
  }
  return res;
};

export const RCATrendsViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {data, total, options} = props;
  const {higherIsBetter} = options;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);

  return (
    <div className={classes.RCATrendsViewer}>
      {data.map((d, idx) => (
        <div className={classes.TrendRow} key={idx}>
          <div className={classes.Sentence}>
            <SentenceViewer data={d.sentence} options={SENTENCE_OPTIONS} />
          </div>
          {d.value && (
            <div className={classes.Impact}>
              <div className={classes.Label}>Impact</div>
              <div
                className={classNames(
                  classes.Value,
                  isPositive(d.value, higherIsBetter) && classes.Positive,
                  !isPositive(d.value, higherIsBetter) && classes.Negative
                )}
              >
                {d.value}%
              </div>
              <div className={classes.Label}>/ {total}%</div>
            </div>
          )}
          {d.command && (
            <div className={classes.Command}>
              <ArrowRightIcon className={classes.Arrow} onClick={() => emitEvent(d.command)} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
