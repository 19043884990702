import {BaseDataset} from '../../charts/chart-data.types';
import {useEffect, useMemo, useState} from 'react';

interface UseDisplayedDatasetsProps<D extends BaseDataset> {
  initialDisplayedDatasetIds: string[];
  datasets: D[];
}

export const useDisplayedDatasets = <D extends BaseDataset>(
  props: UseDisplayedDatasetsProps<D>
) => {
  const {datasets, initialDisplayedDatasetIds: initialDisplayedDatasetIds} = props;
  const [displayedDatasetIds, setDisplayedDatasetIds] = useState<string[]>(
    initialDisplayedDatasetIds || datasets.map(d => d?.id)
  );
  useEffect(() => {
    if (initialDisplayedDatasetIds) {
      setDisplayedDatasetIds(initialDisplayedDatasetIds);
    }
  }, [initialDisplayedDatasetIds, setDisplayedDatasetIds]);

  const displayedDatasets: D[] = useMemo(
    () => datasets.filter(ds => displayedDatasetIds?.indexOf(ds.id) > -1),
    [datasets, displayedDatasetIds]
  );

  return {
    displayedDatasets: displayedDatasets.length > 0 ? displayedDatasets : datasets,
    displayedDatasetIds,
    setDisplayedDatasetIds,
  };
};
