import classNames from 'classnames';
import {
  ShieldCheckSolidIcon,
  ShieldExclamationSolidIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import classes from './validation-status-chip.module.scss';

type ValidationStatusChipProps = {
  passed?: boolean;
};

export const ValidationStatusChip = (props: ValidationStatusChipProps) => {
  const {passed} = props;

  if (passed) {
    return (
      <div className={classes.ValidationStatusChip}>
        <ShieldCheckSolidIcon />
        PASSED
      </div>
    );
  }
  return (
    <div className={classNames(classes.ValidationStatusChip, classes.Warning)}>
      <ShieldExclamationSolidIcon />
      WARNING
    </div>
  );
};
