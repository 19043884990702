import classNames from 'classnames';
import classes from './dmp-signal-validation-error.module.scss';

import {VALIDATE_QUERY_WITH_API_TEST_NAME} from '../../../../objects/dto/query-builder.dto.ts';

interface Props {
  error: any;
  marginBottom?: boolean;
  className?: string;
}

export const DMPSignalValidationError = (props: Props) => {
  const {error, marginBottom, className} = props;

  if (!error || error.type !== VALIDATE_QUERY_WITH_API_TEST_NAME) {
    return null;
  }

  return (
    <div
      className={classNames(
        classes.DMPSignalValidationError,
        marginBottom && classes.MarginBottom,
        className
      )}
    >
      <div className={classes.Title}>Definition errors</div>
      <div className={classes.Message}>{error.message}</div>
    </div>
  );
};
