import {Button, CampaignIcon, FancyHeader, ModalLayout} from 'ui-components';
import {HomepageSubscriptionCreatedSuccessScreen} from '../../components/homepage-summary/components/homepage-subscription-created-success-screen/homepage-subscription-created-success-screen.component';
import {HomepageSubscription} from '../../../../objects/models/homepage.model';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import classes from './homepage-subscription-panel-success-panel.module.scss';
import {HOMEPAGE_ID_PATH_PARAM, SUBSCRIPTION_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {useCallback, useContext} from 'react';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';

type HomepageSubscriptionSuccessPanelProps = {
  subscription: HomepageSubscription;
  onClose?: VoidFunction;
  [HOMEPAGE_ID_PATH_PARAM]: number;
  action: 'created' | 'edited';
};

export const HomepageSubscriptionSuccessPanel = (props: HomepageSubscriptionSuccessPanelProps) => {
  const {subscription, onClose, action, [HOMEPAGE_ID_PATH_PARAM]: homepageId} = props;
  const {t} = useTranslation();
  const {openSecondaryPanel} = useContext(PanelsContext);

  const handleCreateAnotherSubscription = useCallback(() => {
    onClose?.();
    openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_PANEL, {
      [HOMEPAGE_ID_PATH_PARAM]: homepageId,
    });
  }, [onClose, openSecondaryPanel, homepageId]);

  const onEditHomepageSubscription = useCallback(() => {
    onClose?.();
    openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_PANEL, {
      [HOMEPAGE_ID_PATH_PARAM]: homepageId,
      [SUBSCRIPTION_ID_PATH_PARAM]: subscription.id,
    });
  }, [openSecondaryPanel, onClose, homepageId, subscription]);

  return (
    <div className={classes.HomepageSubscriptionSuccessPanelContainer}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <Button onClick={handleCreateAnotherSubscription} variant={'outlined'}>
              Create another subscription
            </Button>
            <Button onClick={onClose}>{t(TransKeys.GENERAL.ACTIONS.DONE)}</Button>
          </div>
        }
      >
        <FancyHeader
          icon={CampaignIcon}
          title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.HEADER_TITLE)}
        />

        <HomepageSubscriptionCreatedSuccessScreen
          onEditSubscription={onEditHomepageSubscription}
          action={action}
          subscription={subscription}
        />
      </ModalLayout>
    </div>
  );
};
