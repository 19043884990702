import classNames from 'classnames';
import classes from './analysis-simple-form.module.scss';
import {HelperTipType, HoverHelperTip, UITheme, UIThemeProvider} from 'ui-components';
import TransKeys from '../../../../../constants/translation-keys';
import {EntitySelector} from '../ui-selectors/entity-selector/entity-selector.component';
import {AnalysisSelectorVariant} from '../ui-selectors/analysis-selector.types';
import {useTranslation} from 'react-i18next';
import {TableEntity} from '../../../../../objects/models/table.model';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {useCallback, useMemo} from 'react';
import {TimeFrameSelector} from '../ui-selectors/time-frame-selector/time-frame-selector.component';
import {SegmentFilterSelector} from '../ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {SqlElementToTextUtils} from '../../../../../utils/sql-element-to-text.utils';

interface SchemaKeysMapping {
  entity: string;
  population_filter: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  entity: 'entity',
  population_filter: 'population_filter',
};

interface OwnProps {
  className?: string;
  parameters: any;
  errors: any;
  onChangeEntityContext?: (entity: TableEntity) => void;
  changeParametersValue: (parameters: any) => void;
  schemaKeysMapping?: SchemaKeysMapping;
  children: any;
}

type AllProps = OwnProps;

const theme: UITheme = {
  DropdownButton: {
    rootClassName: classes.DropdownButtonOverride,
  },
};

interface PartProps {
  children: any;
  className?: string;
}

export const AnalysisFormSentence = ({children, className}: PartProps) => (
  <div className={classNames(classes.AnalysisSentence, className)}>{children}</div>
);
export const AnalysisFormPart = ({children, className}: PartProps) => (
  <div className={classNames(classes.Part, className)}>{children}</div>
);

export const AnalysisSimpleForm = (props: AllProps) => {
  const {
    parameters,
    changeParametersValue,
    onChangeEntityContext: onChangeEntityContextFromProps,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    errors,
    children,
    className,
  } = props;
  const {t} = useTranslation();
  const {productEntities, numberOfEntities} = useProductData();
  const entityContext = parameters[schemaKeysMapping.entity];
  const populationFilterLabel = useMemo(
    () => SqlElementToTextUtils.translate(parameters[schemaKeysMapping.population_filter]),
    [parameters, schemaKeysMapping]
  );
  const onChangeEntityContext = useCallback(
    (v: TableEntity) => {
      if (onChangeEntityContextFromProps) {
        onChangeEntityContextFromProps(v);
        return;
      }
      changeParametersValue({[schemaKeysMapping.entity]: v});
    },
    [onChangeEntityContextFromProps, changeParametersValue, schemaKeysMapping]
  );

  return (
    <UIThemeProvider theme={theme}>
      <div className={classNames(classes.AnalysisSimpleForm, className)}>
        {/* TOP - Entity picker */}
        {numberOfEntities > 1 && (
          <div className={classes.Top}>
            <AnalysisFormSentence className={classes.EntitySelector}>
              <AnalysisFormPart className={classes.EntitySelectorPart}>
                {t(TransKeys.CREATE_ANALYSIS_FORM.SIMPLE.SELECT_ENTITY_PREFIX)}
              </AnalysisFormPart>
              <AnalysisFormPart>
                <EntitySelector
                  value={parameters}
                  productEntities={productEntities}
                  schemaKeysMapping={schemaKeysMapping}
                  onChange={v => onChangeEntityContext(v[schemaKeysMapping.entity])}
                  variant={AnalysisSelectorVariant.INLINE}
                />
              </AnalysisFormPart>
            </AnalysisFormSentence>
          </div>
        )}
        {/* MAIN */}
        <div className={classes.Main}>{children}</div>
        {/* BOTTOM - Timeframe & Population selectors */}
        <div className={classes.Bottom}>
          {/* Timeframe selector */}
          <AnalysisFormSentence>
            <AnalysisFormPart>
              {t(TransKeys.CREATE_ANALYSIS_FORM.SIMPLE.TIME_SELECTOR_PREFIX)}
            </AnalysisFormPart>
            <AnalysisFormPart>
              <TimeFrameSelector
                errors={errors}
                variant={AnalysisSelectorVariant.INLINE}
                value={parameters}
                onChange={changeParametersValue}
                simpleMode
              />
            </AnalysisFormPart>
            {errors['start_date'] && (
              <AnalysisFormPart>
                <HoverHelperTip type={HelperTipType.ERROR} title={errors['start_date']?.message} />
              </AnalysisFormPart>
            )}
          </AnalysisFormSentence>
          {/* Population selector */}
          <AnalysisFormSentence>
            <AnalysisFormPart>
              {t(TransKeys.CREATE_ANALYSIS_FORM.SIMPLE.POPULATION_FILTER_PREFIX)}
            </AnalysisFormPart>
            <AnalysisFormPart>
              {entityContext && (
                <SegmentFilterSelector
                  onChange={changeParametersValue}
                  value={parameters}
                  entityContext={entityContext}
                  schemaKeysMapping={schemaKeysMapping}
                  label={populationFilterLabel}
                  errors={errors}
                  variant={AnalysisSelectorVariant.INLINE}
                />
              )}
            </AnalysisFormPart>
          </AnalysisFormSentence>
        </div>
      </div>
    </UIThemeProvider>
  );
};
