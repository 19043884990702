import {useCallback, useState} from 'react';
import {exists} from 'front-core';
import {max, min} from 'lodash';

export const useListActiveIndex = (config: {
  totalCount: number;
  onEnter?: (activeIndex: number, e) => void;
  listParent?: any;
  getClassNameForIndex?: (index: number) => void;
}) => {
  const {totalCount, onEnter, listParent, getClassNameForIndex} = config;
  const [activeIndex, setActiveIndex] = useState(null);

  const moveActiveIndex = useCallback(
    (moveNext = true) => {
      const startIndex = exists(activeIndex) ? activeIndex : -1;
      const next = startIndex + ((moveNext ? 1 : -1) % totalCount);
      const newActiveIndex = max([min([next, totalCount - 1]), 0]);
      setActiveIndex(newActiveIndex);

      if (getClassNameForIndex) {
        const elem: any = (listParent || document).getElementsByClassName(
          getClassNameForIndex(newActiveIndex)
        )[0];
        elem?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    [totalCount, listParent, getClassNameForIndex, activeIndex]
  );

  const keyUpHandler = useCallback(
    e => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          e.stopPropagation();
          moveActiveIndex(true);
          break;
        case 'ArrowUp':
          e.preventDefault();
          e.stopPropagation();
          moveActiveIndex(false);
          break;
        case 'Enter':
          e.preventDefault();
          e.stopPropagation();
          onEnter(activeIndex, e);
          break;
      }
    },
    [moveActiveIndex, activeIndex, onEnter]
  );

  return {
    activeIndex,
    setActiveIndex,
    moveActiveIndex,
    keyUpHandler,
  };
};
