import * as React from 'react';
import {useMemo} from 'react';
import {QueryBlock} from '../components/query-block/query-block.component';
import {DateBinaryOperation, DateBinaryOperator, Part} from '../query-builder.types';
import {values} from 'lodash';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {InlineToken} from '../components/parts';
import {DateBinaryOperatorName, DatePartName} from '../query-builder.config';
import {queryBuilderClasses} from '../query-builder.component';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {Select} from '../../../forms/inputs/select/select.component';
import {useQueryElement} from '../query-builder.hooks';
import {withViewMode} from '../core/with-view-mode.hoc';

interface OwnProps {
  data: DateBinaryOperation;
  inline?: boolean;
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const DateBinaryOpBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled, inline, box} = props;
  const {localErrors, actions} = useQueryElement(props);
  const opOptions = useMemo(
    () =>
      values(DateBinaryOperator).map(o => ({
        label: DateBinaryOperatorName[o],
        value: o,
      })),
    []
  );
  const partOptions = useMemo(
    () =>
      values(Part).map(o => ({
        label: DatePartName[o],
        value: o,
      })),
    []
  );

  return (
    <QueryBlock
      disabled={disabled}
      className={className}
      label={'Date Binary Operation'}
      actions={actions}
      inline={inline}
      box={box}
    >
      <InlineToken>EQUAL</InlineToken>
      <QueryElementBuilder
        error={Boolean(localErrors.left)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'left')}
        data={data.left as any}
        box={false}
        inline
      />
      <Select
        className={queryBuilderClasses.Inline}
        dropdownButtonClassName={queryBuilderClasses.Button}
        placeholder={'Operator'}
        value={data.op}
        error={Boolean(localErrors.op)}
        onChange={v => onChange(concatPath(path, 'op'), v)}
        searchable={false}
        clearable={false}
        options={{options: opOptions}}
        disabled={disabled}
      />
      <QueryElementBuilder
        error={Boolean(localErrors.right)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'right')}
        data={data.right as any}
        box={false}
        inline
      />
      <InlineToken>PART</InlineToken>
      <Select
        className={queryBuilderClasses.Inline}
        dropdownButtonClassName={queryBuilderClasses.Button}
        placeholder={'Part'}
        value={data.part}
        error={Boolean(localErrors.part)}
        onChange={v => onChange(concatPath(path, 'part'), v)}
        searchable={false}
        clearable={false}
        options={{options: partOptions}}
        disabled={disabled}
      />
    </QueryBlock>
  );
};

DateBinaryOpBuilderComponent.defaultProps = {};

export const DateBinaryOpViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, className} = props;

  return (
    <QueryBlock
      label={'Date Binary Operation'}
      className={className}
      disabled={true}
      inline={true}
      box={false}
      fitContent
    >
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'left')}
        data={data.left as any}
        box={false}
        inline
      />
      <InlineToken>{DateBinaryOperatorName[data.op]}</InlineToken>
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'right')}
        data={data.right as any}
        box={false}
        inline
      />
      <InlineToken>{DatePartName[data.part]}</InlineToken>
    </QueryBlock>
  );
};

export const DateBinaryOpBuilder = withViewMode(
  DateBinaryOpBuilderComponent,
  DateBinaryOpViewerComponent
);
