import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DocumentElementType, DocumentIconType, InfoBoxFigure} from '../../../types';
import classes from './info-box-viewer.module.scss';
import classNames from 'classnames';
import {DocumentIcon} from '../../internal-viewers/document-icon.component';
import {MarkdownViewer} from '../markdown-viewer/markdown-viewer.component';
import {ChevronDownIcon} from '../../../../../simple/controls/icons/icons.component';
import {useDocumentTracking} from '../../../hooks/use-document-tracking.hook';

export interface OwnProps extends InfoBoxFigure {
  className?: string;
}

const CHANGE_OPEN_ELEMENT_ID = '__change_open';

const DEFAULT_ICON = DocumentIconType.DATABASE;

export const InfoBoxViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {id, data, options, className} = props;
  const {trackItemToggled} = useDocumentTracking(id, DocumentElementType.INFO_BOX_FIGURE);
  const {collapsible = true} = options || {};
  const [isOpen, setIsOpen_] = useState(!collapsible);
  const splittedText = useMemo(() => data.content.split('\n'), [data.content]);
  const hasOverflow = useMemo(
    () => splittedText.length > 1 && collapsible,
    [splittedText, collapsible]
  );
  const displayedText = useMemo(() => {
    // if no overflow just render the content
    if (!hasOverflow) {
      return data.content;
    }
    // if is open show Minimize at the end
    if (isOpen) {
      return `${data.content}\n
<span class='${classes.CloseButton}' id='${CHANGE_OPEN_ELEMENT_ID}'>Minimize</span>`;
    }
    // if close, take first sentence and add Read All to the end (inline)
    let previewText = splittedText[0];
    if (previewText.endsWith('.')) {
      previewText = previewText.slice(0, -1);
    }
    return `${previewText}... <span class='${classes.OpenButton}' id='${CHANGE_OPEN_ELEMENT_ID}'>Read All</span>`;
  }, [data.content, isOpen, hasOverflow, splittedText]);
  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setIsOpen_(isOpen);
      trackItemToggled(isOpen, {
        title: splittedText[0],
      });
    },
    [setIsOpen_, trackItemToggled, splittedText]
  );

  useEffect(() => {
    // Set onclick to the span added to the markdown to change open state
    const elem = document.getElementById(CHANGE_OPEN_ELEMENT_ID);
    if (elem) {
      elem.onclick = () => setIsOpen(!isOpen);
    }
  }, [isOpen]);

  return (
    <div className={classNames(classes.InfoBoxViewer, isOpen && classes.Open, className)}>
      <DocumentIcon icon={data.icon || DEFAULT_ICON} className={classes.Icon} />
      <div className={classes.Content}>
        <MarkdownViewer type={DocumentElementType.MARKDOWN} data={displayedText} />
      </div>
      {hasOverflow && (
        <div className={classes.Arrow}>
          <ChevronDownIcon onClick={() => setIsOpen(!isOpen)} className={classes.ArrowIcon} />
        </div>
      )}
    </div>
  );
};

InfoBoxViewer.defaultProps = {};
