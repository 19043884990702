import * as React from 'react';
import {Popover} from '@material-ui/core';
import {uniqueId} from 'lodash';
import {forwardRef, useImperativeHandle, useMemo} from 'react';
import sharedClasses from '../../../shared.module.scss';
import {withStopPropagation} from 'front-core';

interface PopoverWrapperButtonProps {
  isOpen?: boolean;
  onClick: (e) => void;
}

interface OwnProps {
  buttonRenderer: (props: PopoverWrapperButtonProps) => JSX.Element;
  children: any;
  popoverDirection?: 'left' | 'right' | 'center';
  placement?: 'top' | 'bottom';
  disablePortal?: boolean;
  ref?: any;
}

type AllProps = OwnProps;

export const PopoverWrapper: React.FC<AllProps> = forwardRef((props: AllProps, ref) => {
  const {buttonRenderer, popoverDirection, placement, disablePortal, children} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const id = useMemo(() => uniqueId('popover-wrapper'), []);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  useImperativeHandle(ref, () => ({
    close: () => {
      setAnchorEl(null);
    },
  }));

  return (
    <>
      {buttonRenderer({
        isOpen: open,
        onClick: withStopPropagation(e => handlePopoverOpen(e)),
      })}
      <Popover
        id={id}
        open={open}
        className={sharedClasses.BlankPaperAllowOverflow}
        disablePortal={disablePortal}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: placement,
          horizontal: popoverDirection,
        }}
        transformOrigin={{
          vertical: placement === 'top' ? 'bottom' : 'top',
          horizontal: popoverDirection,
        }}
        classes={{
          paper: sharedClasses.BlankPaperAllowOverflow,
        }}
        onClose={withStopPropagation(() => handlePopoverClose())}
      >
        {children}
      </Popover>
    </>
  );
});

PopoverWrapper.defaultProps = {
  popoverDirection: 'center',
  placement: 'bottom',
};
