import {useContext, useMemo} from 'react';
import classes from './module-structure.module.scss';
import {PanelType} from '../../../../objects/system/panel-type.enum';
import classNames from 'classnames';
import {Backdrop, Fade, Modal} from '@material-ui/core';
import {PanelKey} from '../../../../constants/panels';
import {sharedClasses} from '../../index';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {exists} from 'front-core';

type Panels = {
  [key in PanelKey]: any;
};

interface OwnProps {
  children: any;
  panels?: Partial<Panels>;
}

type AllProps = OwnProps;

export enum PanelHierarchy {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export const ModuleStructure = (props: AllProps) => {
  const {children, panels = {}} = props;
  const {primaryPanel, secondaryPanels, closePanel} = useContext(PanelsContext);

  const isPanelOpen = useMemo(
    () => primaryPanel && primaryPanel.type === PanelType.SIDE_PANEL,
    [primaryPanel]
  );
  const isModalOpen = useMemo(
    () => primaryPanel && primaryPanel.type === PanelType.MODAL,
    [primaryPanel]
  );

  const renderPanel = (index?: number) => {
    const isSecondaryPanel = index !== undefined;
    let panel = primaryPanel;
    if (isSecondaryPanel) {
      panel = secondaryPanels[index];
    }

    const Panel = panel ? panels[panel.key] : undefined;
    if (!Panel) {
      return <div />;
    }

    const props = {
      panelId: panel.id,
      panelHierarchy: isSecondaryPanel ? PanelHierarchy.SECONDARY : PanelHierarchy.PRIMARY,
      key: panel.key,
      onClose: () => closePanel(panel.id),
      ...panel.parameters,
    };

    return <Panel {...props} key={panel.id} />;
  };

  return (
    <>
      <div className={classes.ModuleLayout}>
        <div className={classes.ModuleContent}>
          <div className={classNames(classes.Panel, isPanelOpen && classes.Open)}>
            {isPanelOpen && renderPanel()}
          </div>
          {children}
        </div>
      </div>
      {primaryPanel?.id && (
        <Modal
          className={sharedClasses.ModalWrapper}
          open={isModalOpen || false}
          onClose={() => closePanel(primaryPanel?.id)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 250,
          }}
        >
          <Fade in={isModalOpen || false}>{renderPanel()}</Fade>
        </Modal>
      )}
      {secondaryPanels.map((p, idx) => {
        const hideBackdrop = exists(primaryPanel) || (secondaryPanels.length > 1 && idx !== 0);
        return (
          <Modal
            className={sharedClasses.ModalWrapper}
            key={p.key}
            open={true}
            onClose={() => closePanel(p.id)}
            closeAfterTransition
            BackdropComponent={props => <Backdrop {...props} invisible={hideBackdrop} />}
            BackdropProps={{
              timeout: 250,
            }}
          >
            <Fade in={isModalOpen || false}>{renderPanel(idx)}</Fade>
          </Modal>
        );
      })}
    </>
  );
};

export default ModuleStructure;
