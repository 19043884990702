import {NetworkRequestCreator} from 'front-core';

export enum InputType {
  ENUM = 'ENUM',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  FREE_TEXT = 'FREE_TEXT',
  BOOLEAN = 'BOOLEAN',
  API_CALL = 'API_CALL',
}

export interface InputDefinition {
  type: InputType;
  label: string;
  key: string;
  options?: any;
  multi?: boolean;
}

/**
 * Enum Input
 */
export interface EnumInputOption {
  value: string | number;
  label: string;
  labelRenderer?: (option: EnumInputOption) => JSX.Element;
  icon?: any;
  disabled?: boolean;
  helperText?: string;
  className?: string;
  [other: string]: any;
}

export interface EnumInputOptions {
  options: EnumInputOption[];
}

export interface EnumInput extends Omit<InputDefinition, 'options'> {
  type: InputType.ENUM;
  options: EnumInputOptions;
}

export type SelectedEnum = string;

/**
 * Date Input
 */
export interface DateInputOptions {
  minDate?: Date;
  maxDate?: Date;
}

export interface DateInput extends Omit<InputDefinition, 'options'> {
  type: InputType.DATE;
  options: DateInputOptions;
}

export type SelectedDate = string;

/**
 * Date Range Input
 */

export interface DateRangeInput extends Omit<InputDefinition, 'options'> {
  type: InputType.DATE_RANGE;
  options: DateInputOptions;
}

export type DateRange = {
  from: Date;
  to: Date;
};
export type SelectedDateRange = DateRange[];

/**
 * Free Text Input
 */
export interface FreeTextInput extends Omit<InputDefinition, 'options' | 'multi'> {
  type: InputType.FREE_TEXT;
  options: void;
  multi: false;
}

export type SelectedFreeText = string;

/**
 * Boolean Input
 */
export interface BooleanInput extends Omit<InputDefinition, 'options' | 'multi'> {
  type: InputType.BOOLEAN;
  options: void;
  multi: false;
}

export type SelectedBoolean = boolean;

/**
 * API Call Input
 */
export type Extractor = (value: any) => string;
export interface APICallInputOptions {
  networkRequest: NetworkRequestCreator<any, any>;
  labelAttributePath: Extractor | string;
  valueAttributePath: Extractor | string;
  selectionKey?: string;
  exclusionKey?: string;
  requestPayload?: any;
}

export interface APICallInput extends Omit<InputDefinition, 'options'> {
  type: InputType.API_CALL;
  options: APICallInputOptions;
}

export type SelectedOption = string | number | string[] | number[];
