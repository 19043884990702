export enum SqlElementModel {
  SIGNAL = 'SIGNAL',
  TABLE = 'TABLE',
  SEGMENT = 'SEGMENT',
}

export enum SqlElementType {
  CASES = 'Cases',
  WINDOW = 'Window',
  AGGREGATION = 'Aggregation',
  BINARY_OPERATION = 'BinaryOperation',
  COALESCE = 'Coalesce',
  FILL_NULL = 'FillNull',
  NULLIF = 'NullIf',
  IMPLICIT_COLUMN = 'ImplicitColumn',
  TABLE_COLUMN = 'TableColumn',
  SIGNAL_COLUMN = 'SignalColumn',
  CONDITION = 'Condition',
  AND_CONDITION = 'And',
  OR_CONDITION = 'Or',
  DATE_BINARY_OPERATION = 'DateBinaryOperation',
  DATE_PART = 'DatePart',
  LITERAL = 'Literal',
  NUMERIC_FUNCTION = 'NumericFunction',
  RAW_QUERY = 'RawQuery',
  TEMPLATE = 'Template',
  TRANSFORMER = 'Transformer',
}

export enum ExtendedSqlElementType {
  NULL = 'LITERAL_NULL',
  // deprecated
  SEGMENT_CONDITION = 'SegmentCondition',
}

export type QueryBuilderElementType = SqlElementType | ExtendedSqlElementType;

export enum ConditionOperator {
  EQ = '==',
  NE = '!=',
  GT = '>',
  GTE = '>=',
  LT = '<',
  LTE = '<=',
  IS = 'is',
  LIKE = 'like',
  ILIKE = 'ilike',
  NLIKE = 'not like',
  IN = 'in',
  NIN = 'not in',
  BETWEEN = 'between',
  IS_NULL = 'is null',
  IS_NOT_NULL = 'is not null',
  NIN_OR_NULL = 'not in or null',
}

export enum AggregationFunction {
  MAX = 'max',
  SUM = 'sum',
  // SUM_BOOLEAN = 'sum-boolean',
  MIN = 'min',
  AVG = 'avg',
  COUNT_DISTINCT = 'count-distinct',
}

export enum BinaryOperator {
  ADD = 'add',
  SUB = 'sub',
  MUL = 'mul',
  MOD = 'mod',
  DIV = 'div',
  POW = 'pow',
}

export enum DateBinaryOperator {
  ADD = 'add',
  SUB = 'sub',
  DIFF = 'diff',
}

export enum Part {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum NumericFunctionType {
  GREATEST = 'greatest',
  LEAST = 'least',
}

export enum WindowFunctionType {
  SUM = 'sum',
  RANK = 'rank',
  DENSE_RANK = 'dense-rank',
  FIRST_VALUE = 'first-value',
  LAST_VALUE = 'last-value',
  LAG = 'lag',
  LEAD = 'lead',
}

export enum LiteralValueType {
  STRING = 'string',
  INTEGER = 'integer',
  FLOAT = 'float',
  DATE = 'date',
  BOOLEAN = 'boolean',
  STRING_LIST = 'string_list',
  INTEGER_LIST = 'integer_list',
  FLOAT_LIST = 'float_list',
  NULL = 'null',
}

export interface SqlElement {
  type: SqlElementType;
  extended_type?: ExtendedSqlElementType;
  children?: SqlElement[];
  ui_metadata?: any;
}

export interface ConditionExpression extends SqlElement {}

export interface Condition extends ConditionExpression {
  left: SqlElement;
  op: ConditionOperator;
  right: SqlElement | [SqlElement, SqlElement]; // for between
}

export interface ChainedCondition extends ConditionExpression {
  conditions: Array<ConditionExpression>;
}

export interface AndCondition extends ChainedCondition {}

export interface OrCondition extends ChainedCondition {}

export type LiteralValue = number | string | Date | boolean;

export interface Literal extends SqlElement {
  value: LiteralValue | Array<LiteralValue>;
  value_type: LiteralValueType;
}

export interface TableColumn extends SqlElement {
  table_id: string;
  column: string;
}

export interface ImplicitColumn extends SqlElement {
  column: string;
  table_name: string;
  dependencies: Array<SqlElement>;
}

export interface SignalColumn extends SqlElement {
  signal_id: number;
}

export interface Aggregation extends SqlElement {
  function: AggregationFunction;
  element: SqlElement;
}

export interface BinaryOperation extends SqlElement {
  left: SqlElement;
  op: BinaryOperator;
  right: SqlElement;
}

export interface Coalesce extends SqlElement {
  elements: Array<SqlElement>;
}

export interface NullIf extends SqlElement {
  expression1: SqlElement;
  expression2: SqlElement;
}

export interface DateBinaryOperation extends SqlElement {
  left: SqlElement;
  op: DateBinaryOperator;
  right: SqlElement;
  part: Part;
}

export interface DatePart extends SqlElement {
  value: SqlElement;
  part: Part;
}

export interface NumericFunction extends SqlElement {
  function: NumericFunctionType;
  elements: Array<SqlElement>;
}

export interface RawQuery extends SqlElement {
  dependencies: Array<SqlElement>;
  value: string;
}

export interface Template extends SqlElement {
  template: string;
  parameters?: Array<SqlElement | number>;
}

export interface Window extends SqlElement {
  function: WindowFunctionType;
  partition_by?: SqlElement;
  order_by?: [SqlElement, Boolean];
  parameters?: Array<SqlElement | number>;
}

export interface Transformer extends SqlElement {
  transformer: string;
  value: SqlElement;
  parameters?: Array<any>;
}

export interface Cases extends SqlElement {
  else_value?: SqlElement;
  cases: Array<[ConditionExpression, SqlElement]>;
}

export interface FillNull extends Coalesce {
  default?: Literal;
}
