import {DocumentElement, DocumentElementType} from './document.types';
import {
  RetentionTableData,
  RetentionTableOptions,
} from '../../html-charts/retention-table/retention-table.types';
import {DocumentAnchorCommand, DocumentCommand, NavigationCommand} from './commands.types';
import {DocumentIconType} from './general.types';
import {PhenomenaFilter} from '../contexts/document-phenomenas.context';
import {FunnelDatasetValue} from '../../charts/chart-data.types';

/**
 * Figure
 */
export interface FigureElement<D = any, O = any> extends DocumentElement<D, O, void> {
  title?: string;
  defaultPreview?: boolean;
}

/**
 * Loading state figure
 */
export interface LoadingStateFigureData {
  text?: string;
}

export interface LoadingStateFigureOptions {}

export interface LoadingStateFigure
  extends FigureElement<LoadingStateFigureData, LoadingStateFigureOptions> {
  type: DocumentElementType.LOADING_STATE_FIGURE;
}

/**
 * Sentence
 */
export interface SentenceFigureOptions {
  delimiter?: string;
  size?: 'small' | 'medium' | 'large';
  textCenter?: boolean;
}

export interface SentenceFigure extends FigureElement<string, SentenceFigureOptions> {
  type: DocumentElementType.SENTENCE_FIGURE;
}

/**
 * Image
 */
export interface ImageFigureOptions {
  alt?: string;
  caption?: string;
}

export interface ImageFigure extends FigureElement<string, ImageFigureOptions> {
  type: DocumentElementType.IMAGE;
}

/**
 * Tip Box
 */
export interface TipBoxData {
  key?: string;
  content: string;
}

export interface TipBoxFigure extends FigureElement<TipBoxData> {
  type: DocumentElementType.TIP_BOX;
}

/**
 * Info Box
 */
export interface InfoBoxOptions {
  collapsible: boolean;
}

export interface InfoBoxData {
  content: string;
  icon?: DocumentIconType;
}

export interface InfoBoxFigure extends FigureElement<InfoBoxData, InfoBoxOptions> {
  type: DocumentElementType.INFO_BOX_FIGURE;
}

/**
 * RCA Header
 */
export interface RCAHeaderOptions {
  higherIsBetter?: boolean;
  unit?: string;
}

export interface RCAHeaderData {
  title: string;
  value: number;
  expectedValue: number;
  expectedUpper: number;
  expectedLower: number;
  refDateName: string;
  groupName?: string;
  startDate: string; // DD-MM-YYYY
  endDate?: string;
  refDateCommand?: DocumentCommand<any>;
  titleCommand?: DocumentCommand<any>;
  groupCommand?: DocumentCommand<any>;
}

export interface RCAHeaderFigure extends FigureElement<RCAHeaderData, RCAHeaderOptions> {
  type: DocumentElementType.RCA_HEADER;
}

export type RCAPeriodInfo = {
  startDate: string;
  endDate: string;
  value: number;
};

export interface RCAComparedHeaderData {
  title: string;
  refDateName: string;
  groupName?: string;
  refDateCommand?: DocumentCommand<any>;
  titleCommand?: DocumentCommand<any>;
  groupCommand?: DocumentCommand<any>;
  baselinePeriod: RCAPeriodInfo;
  comparedPeriod: RCAPeriodInfo;
  isSignificant: boolean;
}

export interface RCAComparedHeaderFigure
  extends FigureElement<RCAComparedHeaderData, RCAHeaderOptions> {
  type: DocumentElementType.RCA_COMPARED_HEADER;
}

/**
 * Markdown
 */
export interface MarkdownFigure extends FigureElement<string> {
  type: DocumentElementType.MARKDOWN;
}

/**
 * Error
 */
export interface ErrorData {
  code: number;
  message: string;
  payload: any;
}

export interface ErrorFigure extends FigureElement<ErrorData> {
  type: DocumentElementType.ERROR;
}

/**
 * Phenomenas
 */
export interface DocumentPhenomena {
  id?: number;
  type: string;
  sentence: string;
  documentId?: string;

  [other: string]: any;
}

export interface PhenomenasFigureOptions {
  pagination?: boolean;
  perPage?: number;
  page?: number;
  filters?: PhenomenaFilter[];
}

export interface PhenomenasFigure
  extends FigureElement<DocumentPhenomena[], PhenomenasFigureOptions> {
  type: DocumentElementType.PHENOMENAS_FIGURE;
}

/**
 * Feedback Figure
 */
export interface FeedbackFigureData {
  key: string;
}

export interface FeedbackFigure extends DocumentElement<FeedbackFigureData, void, void> {
  type: DocumentElementType._FEEDBACK;
}

/**
 * Executive Summary Figure
 */
export interface ExecutiveSummaryFigure extends DocumentElement<void, void, void> {
  type: DocumentElementType._EXECUTIVE_SUMMARY_FIGURE;
}

/**
 * Executive Summary Figure
 */
export interface KPITreeFigure extends DocumentElement<void, void, void> {
  type: DocumentElementType._KPI_TREE_FIGURE;
}

/**
 * Time To Event Figure
 */
export interface TimeToEventFigureOptions {
  entityName?: string;
}

export interface TimeToEventData {
  id: string;
  label: string;
  hours: {[totalHour: string]: number};
  minutes?: {[totalMinutes: string]: number};
}

export interface TimeToEventFigure
  extends FigureElement<TimeToEventData[], TimeToEventFigureOptions> {
  type: DocumentElementType.TIME_TO_EVENT;
}

/**
 * Data Validation Figure
 */
export interface DataValidationItem {
  status: 'success' | 'error';
  text: string;
  secondaryText: string;
  learnMoreArticleId?: string;
  command?: NavigationCommand;
}

export interface DataValidationFigure extends FigureElement<DataValidationItem[], void> {
  type: DocumentElementType.DATA_VALIDATION;
}

/**
 * RCA Top Trends Figure
 */
export interface RCATrendItem {
  sentence: string;
  value: number;
  command?: DocumentAnchorCommand;
}

export interface RCATrendsFigureOptions {
  higherIsBetter?: boolean;
}

export interface RCATrendsFigure extends FigureElement<RCATrendItem[], RCATrendsFigureOptions> {
  type: DocumentElementType.RCA_TRENDS;
}

/**
 * Retention Table Figure
 */
export interface RetentionTableFigure
  extends FigureElement<RetentionTableData[], RetentionTableOptions> {
  type: DocumentElementType.RETENTION_TABLE_FIGURE;
}

/**
 * Command Buttons Figure
 */

export interface CommandButton {
  buttonText: string;
  command: DocumentCommand<any>;
  buttonVariant?: 'primary' | 'secondary';
}

export interface CommandButtonsFigureOptions {
  align: 'right' | 'left';
}

export interface CommandButtonsFigureData {
  buttons: CommandButton[];
}

export interface CommandButtonsFigure
  extends FigureElement<CommandButtonsFigureData, CommandButtonsFigureOptions> {
  type: DocumentElementType.COMMAND_BUTTONS;
}

/**
 * Checklist figure
 */

export interface ChecklistFigureItem {
  key: string;
  text: string;
  isCompleted: boolean;
  helperText?: string;
}

export interface ChecklistFigureOptions {}

export interface ChecklistFigureData {
  title: string;
  subTitle?: string;
  items: ChecklistFigureItem[];
}

export interface CheckListFigure
  extends FigureElement<ChecklistFigureData, ChecklistFigureOptions> {
  type: DocumentElementType.CHECKLIST;
}

/**
 * Opportunity Figure + Analysis Figure
 */

export interface PhenomenasContentData {
  title: string;
  filters?: PhenomenaFilter[];
  description?: string;
}

export interface AnalysisHeaderLabel {
  label: string;
  value: string;
  command?: DocumentCommand<any>;
  position: 'right' | 'bottom';
}

export enum AnalysisContentItemType {
  ITEM = 'item',
  SELECTION = 'selection',
}

export interface AnalysisContentItem {
  type: AnalysisContentItemType.ITEM;
  key: string;
  headerLabels?: AnalysisHeaderLabel[];
  title: string;
  data: DocumentElement[];
  icon?: DocumentIconType;
  linkText?: string;
  linkSubText?: string;
  helperText?: string;
  tipBox?: TipBoxFigure;
  infoBox?: InfoBoxFigure;
  phenomenas?: PhenomenasContentData;
  isCausal?: boolean;
}

export interface AnalysisContentItemSelection extends Omit<AnalysisContentItem, 'data' | 'type'> {
  type: AnalysisContentItemType.SELECTION;
  showContentOnMenu?: boolean;
  data: AnalysisContentItem[];
}

export type AcceptedAnalysisContentItem = AnalysisContentItem[] | AnalysisContentItemSelection[];

export interface AnalysisFigureData {
  overview?: AnalysisContentItem;
  validation: AcceptedAnalysisContentItem;
  deepDive: AcceptedAnalysisContentItem;
  appendix: AcceptedAnalysisContentItem;
  hasPhenomenas?: boolean;
}

export interface AnalysisFigureOptions {
  hideNextPrev: boolean;
}

export interface AnalysisFigure extends FigureElement<AnalysisFigureData, AnalysisFigureOptions> {
  type: DocumentElementType.ANALYSIS_FIGURE;
}

export enum AnalysisAlgoMode {
  CORRELATION = 'correlation',
  CAUSATION = 'causation',
}

/**
 * TreatmentAdoptionSimulationFigure
 */
export enum TreatmentAdoptionSimulationItemType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  INSIGNIFICANT_POSITIVE = 'insignificant_positive',
  INSIGNIFICANT_NEGATIVE = 'insignificant_negative',
  NO_RECOMMENDATION = 'no_recommendation',
  // internal type
  INSIGNIFICANT = 'insignificant',
}

export interface TreatmentAdoptionSimulationItem {
  key?: string;
  treatmentName: string[];
  treatmentCommand: DocumentCommand<any>[];
  followUpCommand?: DocumentCommand<any>;
  adoption: number; // [0-1]
  adoptionAbs: number; // in render `{adoption} ({adoption_abs})`
  goalInAdopters: number;
  goalInNonAdopters: number;
  potentialLift?: number; // This is not presented, it used for the calculation of potential KPI
  potentialLiftUpper?: number;
  potentialLiftLower?: number;
  type: TreatmentAdoptionSimulationItemType;
  phenomenaDoc: string;
  info?: string;
  helperText?: string;
}

export interface TreatmentAdoptionSimulationFigureData {
  items: TreatmentAdoptionSimulationItem[];
  goalValue: number;
  mode: AnalysisAlgoMode;
  totalEntities?: number;
}

export interface TreatmentAdoptionSimulationFigureOptions {
  entity: string; // singular
  treatmentModelName: string; // Assuming all other columns should be the same, in UI example "feature"
  isPercentageValue: boolean; // This flag is both for potential_lift and goal
  separator: 'comma' | 'arrow' | 'or' | 'and';
  dataMode?: boolean;
  types?: TreatmentAdoptionSimulationItemType[];
}

export interface TreatmentAdoptionSimulationFigure
  extends FigureElement<
    TreatmentAdoptionSimulationFigureData,
    TreatmentAdoptionSimulationFigureOptions
  > {
  type: DocumentElementType.TREATMENT_ADOPTION_SIMULATION_FIGURE;
}

/**
 * JourneyDriversSimulationFigure
 */

export interface JourneyDriversScopeEvent {
  name: string;
  adoptionAbs: number;
  goalInAdopters: number;
  goalInNonAdopters: number;
}

export interface JourneyDriversScope {
  events: JourneyDriversScopeEvent[];
  calculatedSequencesCount: number;
  displayedSequencesCount: number;
}

export interface JourneyDriversSimulationFigureData extends TreatmentAdoptionSimulationFigureData {
  scope?: JourneyDriversScope;
}

export interface JourneyDriversSimulationFigureOptions
  extends TreatmentAdoptionSimulationFigureOptions {}

export interface JourneyDriversSimulationFigure
  extends FigureElement<JourneyDriversSimulationFigureData, JourneyDriversSimulationFigureOptions> {
  type: DocumentElementType.TREATMENT_ADOPTION_SIMULATION_FIGURE;
}

/**
 * TreatmentAdoptionSimulationModeSwitchWrapperFigure
 */

export interface TreatmentAdoptionSimulationModeSwitchWrapperFigureData {
  correlation: TreatmentAdoptionSimulationFigure;
  causation: TreatmentAdoptionSimulationFigure;
}

export interface TreatmentAdoptionSimulationModeSwitchWrapperFigureOptions {
  showCausalWhy: boolean;
}

export interface TreatmentAdoptionSimulationModeSwitchWrapperFigure
  extends FigureElement<
    TreatmentAdoptionSimulationModeSwitchWrapperFigureData,
    TreatmentAdoptionSimulationModeSwitchWrapperFigureOptions
  > {
  type: DocumentElementType.TREATMENT_ADOPTION_SIMULATION_MODE_SWITCH_WRAPPER_FIGURE;
}

/**
 * HabitMomentFigure
 */
export enum HabitMomentFigureItemInstanceType {
  REPEATED_ACTIONS = 'repeatedActions',
  REPEATED_DAYS = 'repeatedDays',
}

export enum HabitMomentFigureItemCausationType {
  POSITIVE = 'positive',
  INSIGNIFICANT_POSITIVE = 'insignificant_positive',
  NO_RECOMMENDATION = 'no_recommendation',
  // internal
  INSIGNIFICANT = 'insignificant',
}

// Internal use
export const ConfusionMatrixIndex = {
  TRUE_POSITIVE: 0,
  TRUE_NEGATIVE: 1,
  FALSE_POSITIVE: 2,
  FALSE_NEGATIVE: 3,
};

export type ConfusionMatrix = [number, number, number, number]; // [TP, TN, FP, FN] - as ConfusionMatrixIndex

export interface HabitMomentFigureItemInstance {
  repeats: number;
  timeframeDays: number; // days
  adoptionRate: number; // [0,1]
  goalInAdopters: number; // KPI of adopters,
  goalInNonAdopters: number;
  correlation?: number; // [-1,1],
  confusionMatrix: ConfusionMatrix;
  isSignificant: boolean;
  causationType?: HabitMomentFigureItemCausationType;
  potentialLift?: number;
  potentialLiftUpper?: number;
  potentialLiftLower?: number;
  info?: string;
  infoCausation?: string;
  isBestInstance?: boolean;
  isBestInstanceCausation?: boolean;
}

export type Instances = {
  [value in HabitMomentFigureItemInstanceType]?: HabitMomentFigureItemInstance[];
};

export enum HabitMomentDataType {
  BOOLEAN = 'boolean',
  NUMERIC = 'numeric',
}

export interface HabitMomentFigureItem {
  key?: string;
  habitName: string;
  habitCommand: DocumentCommand<any>;
  type: 'any' | 'single'; // might be a "sequence" in the future
  totalUsers?: number;
  instances: Instances;
  dataType: HabitMomentDataType;
  repeatedActionsInfo?: string;
  repeatedActionsInfoCausation?: string;
  repeatedDaysInfo?: string;
  repeatedDaysInfoCausation?: string;
}

export interface HabitMomentFigureData {
  items: HabitMomentFigureItem[];
  goalValue: number;
  totalUsers: number; // this is used to compute adoption_abs of each instance
  mode: AnalysisAlgoMode;
}

export interface HabitMomentFigureOptions {
  entity: string; // singular
  habitModelName: string; // Assuming all other columns should be the same, in UI example "feature",
  isPercentageValue: boolean; // This flag is for goal value
  higherIsBetter: boolean; // used for uplift
}

export interface HabitMomentFigure
  extends FigureElement<HabitMomentFigureData, HabitMomentFigureOptions> {
  type: DocumentElementType.HABIT_MOMENT_FIGURE;
}

/**
 * Heatmap Figure
 */
export interface HeatMapValue {
  key?: string;
  x: string;
  y: string;
  value: number;
  icon?: DocumentIconType;
}

export interface HeatmapFigureData {
  xTicks: string[];
  yTicks: string[];
  values: HeatMapValue[];
}

export interface HeatmapFigureOptions {
  xLabel: string;
  yLabel: string;
  rangeLabel?: string;
  rangeMin?: number;
  rangeMax?: number;
  isPercentage?: boolean;
  higherIsBetter?: boolean;
}

export interface HeatmapFigure extends FigureElement<HeatmapFigureData, HeatmapFigureOptions> {
  type: DocumentElementType.HEATMAP;
}

/**
 * Funnel overview figure
 */

export interface FunnelOverviewFigureStep {
  order: number;
  signalId: number;
  label: string;
}

export interface ConversionOverTimeValue {
  date: string;
  y: number;
}

export interface FunnelConversionOverTime {
  startStepOrder: number;
  endStepOrder: number;
  data: ConversionOverTimeValue[];
}

export interface EntityCountOverTime {
  date: string;
  count: number;
}

export interface FunnelEntityCountOverTime {
  stepOrder: number;
  data: EntityCountOverTime[];
}

export interface FunnelConversionPotentialLift {
  fromStepIndex: number;
  toStepIndex: number;
  stepPotentialLift: number[]; // length = steps.length - 1
  stepPotentialLiftLower: number[];
  stepPotentialLiftUpper: number[];
}

export interface FunnelOverviewFigureData {
  steps: FunnelOverviewFigureStep[];
  funnelId: number;
  funnelData: FunnelDatasetValue;
  conversionOverTime: FunnelConversionOverTime[];
  entityCountOverTime: FunnelEntityCountOverTime[];
  potentialLifts: FunnelConversionPotentialLift[];
  funnelSegmentationContentKey?: string;
}

export interface FunnelOverviewFigureOptions {
  entity: string;
}

export interface FunnelOverviewFigure
  extends FigureElement<FunnelOverviewFigureData, FunnelOverviewFigureOptions> {
  type: DocumentElementType.FUNNEL_OVERVIEW_FIGURE;
}

/**
 * KPI Segmentation figure
 */
export enum KPISegmentationUpliftMode {
  VS_OTHERS = 'vs_others',
  VS_INFORMATIVE_OTHERS = 'vs_informative_others',
  VS_AVERAGE = 'vs_average',
}

export enum KPISegmentationType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  INSIGNIFICANT_POSITIVE = 'insignificant_positive',
  INSIGNIFICANT_NEGATIVE = 'insignificant_negative',
  NO_RECOMMENDATION = 'no_recommendation',
  // internal
  INSIGNIFICANT = 'insignificant',
}

export interface KPISegmentationItem {
  key?: string;
  type: KPISegmentationType;
  segmentName: string[];
  segmentGroupName: string[];
  signalId: number[];
  count: number;
  goalInSegment: number;
  goalInNonSegment: number;
  goalInInformativeNonSegment: number;
  samples: MetricSampleData[];
  potentialLift?: number;
  helperText?: string;
  isInformative?: boolean;
}

export interface KPISegmentationFigureData {
  goalValue: number;
  items: KPISegmentationItem[];
  allPopulation: MetricSampleData[];
  totalEntities: number;
  granularity: 'day' | 'week' | 'month';
}

export interface KPISegmentationFigureOptions {
  kpiName: string;
  entity: string;
  isPercentageValue: boolean;
  higherIsBetter: boolean;
  isSumMode?: boolean;
}

export interface KPISegmentationFigure
  extends FigureElement<KPISegmentationFigureData, KPISegmentationFigureOptions> {
  type: DocumentElementType.KPI_SEGMENTATION_FIGURE;
}

/**
 * Funnel Segmentation Figure
 */
export enum FunnelSegmentationItemType {
  POSITIVE = 'positive',
  INSIGNIFICANT_POSITIVE = 'insignificant_positive',
  NO_RECOMMENDATION = 'no_recommendation',
  // internal
  INSIGNIFICANT = 'insignificant',
}

export interface FunnelSegmentationStep {
  name: string;
  signalId: number;
}

export interface FunnelSegmentationItem {
  key?: string;
  segmentName: string[];
  segmentGroupName: string[];
  signalId: number[];
  countInSegment: number[];
  countInNonSegment: number[];
  countInInformativeNonSegment: number[];
  potentialLift: number[];
  potentialLiftCausation: (number | null)[];
  potentialLiftLowerCausation: (number | null)[];
  potentialLiftUpperCausation: (number | null)[];
  type: FunnelSegmentationItemType[];
  typeCausation: FunnelSegmentationItemType[];
  helperText?: string[];
  helperTextCausation?: string[];
  helperTextFunnelCompletion?: string;
  helperTextFunnelCompletionCausation?: string;
  funnelCompletionType: FunnelSegmentationItemType;
  funnelCompletionTypeCausation: FunnelSegmentationItemType;
  isInformative?: boolean;
  info: string[];
}

export interface FunnelSegmentationFigureData {
  goalValue: number;
  mode: AnalysisAlgoMode;
  funnelId: number;
  totalEntities: number;
  items: FunnelSegmentationItem[];
  steps: FunnelSegmentationStep[];
}

export interface FunnelSegmentationFigureOptions {
  entity: string;
}

export interface FunnelSegmentationFigure
  extends FigureElement<FunnelSegmentationFigureData, FunnelSegmentationFigureOptions> {
  type: DocumentElementType.FUNNEL_SEGMENTATION_FIGURE;
}

/**
 * Composite Segments Figure
 */
export interface SegmentOfCompositeData {
  name: string;
  groupName: string;
  count: number;
  goalInSegment: number;
  goalInNonSegment: number;
  signalId: number;
}

export interface CompositeSegments {
  goalInComposite: number;
  goalInNonComposite: number;
  count: number;
  segments: SegmentOfCompositeData[];
}

export interface CompositeSegmentsFigureData {
  totalEntities: number;
  composites: CompositeSegments[];
}

export interface CompositeSegmentsFigureOptions {
  entity: string;
  higherIsBetter: boolean;
  isPercentageValue: boolean;
  sortOrder?: 'asc' | 'desc';
}

export interface CompositeSegmentsFigure
  extends FigureElement<CompositeSegmentsFigureData, CompositeSegmentsFigureOptions> {
  type: DocumentElementType.COMPOSITE_SEGMENTS_FIGURE;
}

/**
 * Funnel Composite Segments Figure
 */

export interface FunnelCompositeSegments extends CompositeSegments {
  funnelCompletion: boolean;
  funnelStepIndex: number | null;
}

export interface FunnelCompositeSegmentsFigureData extends CompositeSegmentsFigureData {
  funnelId: number;
  steps: FunnelSegmentationStep[];
  composites: FunnelCompositeSegments[];
}

export interface FunnelCompositeSegmentsFigure
  extends FigureElement<FunnelCompositeSegmentsFigureData, void> {
  type: DocumentElementType.FUNNEL_COMPOSITE_SEGMENTS_FIGURE;
}

/**
 * Retention Overview Figure
 */

export interface RetentionValue {
  value: number;
  isPartial: boolean;
}

export interface RetentionOverviewFigureItem {
  date: string;
  total: number;
  values: RetentionValue[];
}

export interface BucketPotentialLift {
  bucketIndex: number;
  bucketPotentialLift: number[];
  bucketPotentialLiftUpper: number[];
  bucketPotentialLiftLower: number[];
  stablePotentialLift: number;
  stablePotentialLiftUpper: number;
  stablePotentialLiftLower: number;
}

export enum RetentionTimeUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface RetentionOverviewFigureData {
  items: RetentionOverviewFigureItem[];
  potentialLifts: BucketPotentialLift[];
  stableValue: number; // like goal value
  timeUnit: RetentionTimeUnit;
  timeUnitN: number;
}

export interface RetentionOverviewFigureOptions {
  entity: string;
  simulatorMode: boolean;
}

export interface RetentionOverviewFigure
  extends FigureElement<RetentionOverviewFigureData, RetentionOverviewFigureOptions> {
  type: DocumentElementType.RETENTION_OVERVIEW_FIGURE;
}

/**
 * Retention Segmentation Figure
 */

export enum RetentionSegmentationItemType {
  POSITIVE = 'positive',
  INSIGNIFICANT_POSITIVE = 'insignificant_positive',
  NO_RECOMMENDATION = 'no_recommendation',
  // internal
  INSIGNIFICANT = 'insignificant',
}

export interface SegmentRetentionBucket {
  bucketIndex: number;
  type: RetentionSegmentationItemType;
  goalInSegment: number;
  goalInNonSegment?: number;
  count: number;
  potentialLift: number;
  potentialLiftUpper: number;
  potentialLiftLower: number;
  helperText?: string;
  info?: string;
}

export interface RetentionSegmentationItem {
  key: string;
  segmentName: string[];
  segmentGroupName: string[];
  signalId: number[];
  buckets: SegmentRetentionBucket[];
  count: number;
  stableValue: number;
  isInformative: boolean;
}

export interface AllPopulationRetentionBucket {
  value: number;
  count: number;
}

export interface AllPopulationRetention {
  stableValue: number;
  buckets: AllPopulationRetentionBucket[];
}

export interface RetentionSegmentationFigureData {
  items: RetentionSegmentationItem[];
  allPopulation: AllPopulationRetention;
  totalEntities: number;
  goalValue: number;
  timeUnit: RetentionTimeUnit;
  timeUnitN: number;
}

export interface RetentionSegmentationFigureOptions {
  entity: string;
  simulatorMode: boolean;
}

export interface RetentionSegmentationFigure
  extends FigureElement<RetentionSegmentationFigureData, RetentionSegmentationFigureOptions> {
  type: DocumentElementType.RETENTION_SEGMENTATION_FIGURE;
}

/**
 * Trend Composite Segments Figure
 */
export interface TrendSegmentOfComposite {
  name: string;
  groupName: string;
  value: number;
  expectedValue: number;
  count: number;
  expectedCount: number;
  signalId: number;
}

export interface TrendCompositeSegments {
  value: number;
  expectedValue: number;
  count: number;
  expectedCount: number;
  segments: TrendSegmentOfComposite[];
}

export interface TrendCompositeSegmentsFigureData {
  totalEntities: number;
  expectedTotalEntities: number;
  composites: TrendCompositeSegments[];
}

export interface TrendCompositeSegmentsFigureOptions {
  entity: string;
  higherIsBetter: boolean;
  isPercentageValue: boolean;
  sortOrder?: 'asc' | 'desc';
}

export interface TrendCompositeSegmentsFigure
  extends FigureElement<TrendCompositeSegmentsFigureData, TrendCompositeSegmentsFigureOptions> {
  type: DocumentElementType.TREND_COMPOSITE_SEGMENTS_FIGURE;
}

/**
 * RCA Composite Segments Figure
 */

export interface RCASegmentOfComposite extends TrendSegmentOfComposite {
  contributionToChange: number;
}

export interface RCACompositeSegments extends TrendCompositeSegments {
  contributionToChange: number;
  segments: RCASegmentOfComposite[];
}

export interface RCACompositeSegmentsFigureData extends TrendCompositeSegmentsFigureData {
  populationTrend: number;
  composites: RCACompositeSegments[];
}

export interface RCACompositeSegmentsFigureOptions extends TrendCompositeSegmentsFigureOptions {}

// this figure is deprecated - should be removed in the future
export interface RCACompositeSegmentsFigure
  extends FigureElement<RCACompositeSegmentsFigureData, RCACompositeSegmentsFigureOptions> {
  type: DocumentElementType.TREND_COMPOSITE_SEGMENTS_FIGURE;
}

/**
 * ExperimentContextFigure
 */

export enum ExperimentStatus {
  ERROR = 'ERROR',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
}

export enum ExperimentValidationStatus {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum ReleaseImpactVariantKey {
  SYNTHETIC_CONTROL = 'synthetic_control',
  NAIVE_CONTROL = 'naive_control',
  POST_RELEASE = 'post_release',
}

export interface ExperimentMetric {
  name: string;
  signalId: number;
  isPrimary: boolean;
  isPercentageValue: boolean;
  command?: NavigationCommand;
}

export interface ExperimentVariant {
  key: string;
  name: string;
  isControl: boolean;
  color: string;
}

export interface ExperimentValidation {
  status: ExperimentValidationStatus;
  title?: string;
  description: string;
  command?: NavigationCommand;
}

export interface ExperimentContextFigureData {
  status: ExperimentStatus;
  variants: ExperimentVariant[];
  metrics: ExperimentMetric[];
  winnerVariantKey?: string;
}

export interface ExperimentContextFigureOptions {
  entity: string;
}

export interface ExperimentContextFigure
  extends DocumentElement<
    ExperimentContextFigureData,
    ExperimentContextFigureOptions,
    DocumentElement
  > {
  type: DocumentElementType.AB_TEST_CONTEXT_WRAPPER_FIGURE;
  children: DocumentElement<any, any>[];
}

/**
 * ExperimentConclusionFigure
 */

export interface ExperimentConclusionFigureData {
  title?: string;
  validations?: ExperimentValidation[];
  // moved to ExperimentContextFigureData, this is for backward compatibility
  status?: ExperimentStatus;
  winnerVariantKey?: string;
}

export interface ExperimentConclusionFigure extends FigureElement<ExperimentConclusionFigureData> {
  type: DocumentElementType.EXPERIMENT_CONCLUSION_FIGURE;
}

/**
 * ABTestSummaryFigure
 */

export interface ABTestSummaryItem {
  signalId: number;
  variantKey: string;
  goalValue: number;
  goalValueUpper: number;
  goalValueLower: number;
  isWinnerVsControl?: boolean;
  isWinnerVsAll?: boolean;
  probabilityVsControl?: number;
  improvementVsControl?: number;
  expectedLossVsControl?: number;
  probabilityVsAll?: number;
  improvementVsAll?: number;
  expectedLossVsAll?: number;
  groupSize: number;
}

export interface ABTestSummaryFigureData {
  items: ABTestSummaryItem[];
}

export interface ABTestSummaryFigureOptions {
  showVsAll: boolean;
  showActions: boolean;
}

export interface ABTestSummaryFigure
  extends FigureElement<ABTestSummaryFigureData, ABTestSummaryFigureOptions> {
  type: DocumentElementType.AB_TEST_SUMMARY_FIGURE;
}

/**
 * ReleaseImpactSummaryFigure
 */

export interface ReleaseImpactSummaryItem {
  signalId: number;
  variantKey: ReleaseImpactVariantKey;
  goalValue: number;
  goalValueUpper: number;
  goalValueLower: number;
  isWinnerVsNaive?: boolean;
  isWinnerVsSynthetic?: boolean;
  probabilityVsNaive?: number;
  improvementVsNaive?: number;
  probabilityVsSynthetic?: number;
  improvementVsSynthetic?: number;
  groupSize: number;
}

export enum ReleaseImpactSummaryValidationConfidenceLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum ReleaseImpactSummaryValidationType {
  NO_NULL_TEST = 'no_null_test',
  LOW_PERCENTILE_NULL_TEST = 'low_percentile_null_test',
  NO_TREE_SPLITS = 'no_tree_splits',
  CAUSAL_EQUAL_NAIVE = 'causal_equal_naive',
  LOW_PHI_COLUMN_DROPPED = 'low_phi_column_dropped',
  FEW_SEGMENT_GROUPS = 'few_segment_groups',
  ERROR_IN_ANALYSIS = 'error_in_analysis',
  MANY_MISSING_DATES = 'many_missing_dates',
  MANY_CONTRADICTING_EFFECTS = 'many_contradicting_effects',
  FEW_POST_DAYS = 'few_post_days',
  UNSTABLE_CONFIDENCE = 'unstable_confidence',
  OUTLIER_NULL_DATES = 'outlier_null_dates',
  OUTLIER_PHI_WEEKS = 'outlier_phi_weeks',
  MANY_ZERO_KPI_DAYS = 'many_zero_kpi_days',
}

export type ReleaseImpactSummaryValidations = {
  validationResults: ReleaseImpactSummaryValidationResults;
  validationConfidenceLevel: ReleaseImpactSummaryValidationConfidenceLevel;
};

export type ReleaseImpactSummaryValidationResults = {
  [key in ReleaseImpactSummaryValidationType]: {
    conclusion: boolean;
    data: Record<string, unknown>;
  };
};

export interface ReleaseImpactSummaryFigureData {
  items: ReleaseImpactSummaryItem[];
  validations: Record<number, ReleaseImpactSummaryValidations>;
}

export interface ReleaseImpactSummaryFigureOptions {
  showActions?: boolean;
}

export interface ReleaseImpactSummaryFigure
  extends FigureElement<ReleaseImpactSummaryFigureData, ReleaseImpactSummaryFigureOptions> {
  type: DocumentElementType.RELEASE_IMPACT_SUMMARY_FIGURE;
}

export interface ReleaseImpactCalculationBreakdownItem {
  groupName: string;
  preCount: number;
  postCount: number;
  preGoalValue: number;
  postGoalValue: number;
  releaseImpactValue: number;
  releaseImpactValueUpper: number;
  releaseImpactValueLower: number;
  helperText: string;
}

export interface ReleaseImpactCalculationBreakdownFigureData {
  items: ReleaseImpactCalculationBreakdownItem[];
  preTotal: number;
  postTotal: number;
}

export interface ReleaseImpactCalculationBreakdownFigureOptions {
  entity: string;
  higherIsBetter: boolean;
  isPercentageValue: boolean;
}

export interface ReleaseImpactCalculationBreakdownFigure
  extends FigureElement<
    ReleaseImpactCalculationBreakdownFigureData,
    ReleaseImpactCalculationBreakdownFigureOptions
  > {
  type: DocumentElementType.RELEASE_IMPACT_CALCULATION_BREAKDOWN_FIGURE;
}

/**
 * ReleaseImpactFeatureSummaryFigure
 */

export interface ReleaseImpactFeatureSummaryItemMetric {
  signalId: number;
  goalInAdoptersPre: number;
  goalInNonAdoptersPre: number;
  goalInAdoptersPost: number;
  goalInNonAdoptersPost: number;
}

export interface ReleaseImpactFeatureSummaryItem {
  name: string;
  signalId: number;
  adoptionAbsNaiveControl: number;
  adoptionAbsPostRelease: number;
  groupSizeNaiveControl: number;
  groupSizePostRelease: number;
  adoptionAbsSyntheticControl?: number;
  metrics: ReleaseImpactFeatureSummaryItemMetric[];
  command: DocumentCommand<any>;
}

export interface ReleaseImpactFeatureSummaryFigureData {
  items: ReleaseImpactFeatureSummaryItem[];
}

export interface ReleaseImpactFeatureSummaryFigure
  extends FigureElement<ReleaseImpactFeatureSummaryFigureData> {
  type: DocumentElementType.RELEASE_IMPACT_FEATURES_SUMMARY_FIGURE;
}

/**
 * ReleaseImpactFeatureEffectOnMetricFigure
 */
export interface ReleaseImpactFeatureEffectOnMetricItem {
  signalId: number;
  goalInAdoptersPre: number;
  goalInNonAdoptersPre: number;
  goalInAdoptersPost: number;
  goalInNonAdoptersPost: number;
  avgSecondsToGoalInAdoptersPre: number;
  avgSecondsToGoalInNonAdoptersPre: number;
  avgSecondsToGoalInAdoptersPost: number;
  avgSecondsToGoalInNonAdoptersPost: number;
}

export interface ReleaseImpactFeatureEffectOnMetricFigureData {
  correlative: ReleaseImpactFeatureEffectOnMetricItem[];
  causal: ReleaseImpactFeatureEffectOnMetricItem[];
}

export interface ReleaseImpactFeatureEffectOnMetricFigure
  extends FigureElement<ReleaseImpactFeatureEffectOnMetricFigureData> {
  type: DocumentElementType.RELEASE_IMPACT_FEATURE_EFFECT_ON_METRIC_FIGURE;
}

/**
 * Power Users Overview Figure
 */

export enum GroupByTimeStrategy {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  FOUR_WEEKS = 'four_weeks',
  MONTH = 'month',
  YEAR = 'year',
}

export enum PowerUsersType {
  DISTINCT_DAYS = 'distinct_days',
  USAGES = 'usages',
  VALUE = 'value',
}

export enum PowerUsersTimeUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface PowerUsersData {
  value: number;
  entityCount: number;
  shareOfEntity: number;
}

export interface PowerUsersOverviewFigureData {
  valueDistribution: PowerUsersData[];
  cumulativeDistribution: PowerUsersData[];
  signalId: number;
  powerN: number;
  powerType: PowerUsersType;
  powerPercentile: number;
  timeStrategy?: GroupByTimeStrategy;
}

export interface PowerUsersOverviewFigureOptions {
  entity: string;
  signalName: string;
  isValuesWeightsAveraged: boolean;
}

export interface PowerUsersOverviewFigure
  extends FigureElement<PowerUsersOverviewFigureData, PowerUsersOverviewFigureOptions> {
  type: DocumentElementType.POWER_USERS_OVERVIEW_FIGURE;
}

/**
 * Power Users Segmentation Figure
 */

export interface PowerUsersSegmentationItem {
  key: string;
  segmentName: string[];
  segmentGroupName: string[];
  signalId: number[];
  count: number;
  valueInSegment: number;
  isInformative: boolean;
  isSignificant: boolean;
  helperText?: string;
}

export interface PowerUsersSegmentationFigureData {
  items: PowerUsersSegmentationItem[];
  totalEntityCount: number;
  powerN: number;
  powerPercentile: number;
  timeUnit: PowerUsersTimeUnit;
  timeUnitN: number;
}

export interface PowerUsersSegmentationFigureOptions {
  entity: string;
  higherIsBetter: boolean;
}

export interface PowerUsersSegmentationFigure
  extends FigureElement<PowerUsersSegmentationFigureData, PowerUsersSegmentationFigureOptions> {
  type: DocumentElementType.POWER_USERS_SEGMENTATION_FIGURE;
}

/**
 * Content Funnel Overview Figure
 */
export interface ContentFunnelOverviewFigureStep {
  order: number;
  signalId?: number;
  label: string;
}

export interface ContentFunnelOverviewItem extends FunnelDatasetValue {
  key: string;
  name: string;
}

export interface ContentFunnelOverviewFigureData {
  steps: ContentFunnelOverviewFigureStep[];
  contentsData: ContentFunnelOverviewItem[];
  overviewByContentContentKey: string;
}

export interface ContentFunnelOverviewFigureOptions {
  entity: string;
}

export interface ContentFunnelOverviewFigure
  extends FigureElement<ContentFunnelOverviewFigureData, ContentFunnelOverviewFigureOptions> {
  type: DocumentElementType.CONTENT_FUNNEL_OVERVIEW_FIGURE;
}

/**
 * RCA Segmentation Figure
 **/
export enum ExplainabilityLabel {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum SimilarityType {
  OVERLAPPING = 'overlapping',
  CONTAINED = 'contained',
}

export enum InterestType {
  KPI_CHANGE = 'kpi_change',
  COUNT = 'count',
  SHARE = 'share',
}

export interface BaseSegmentOfRCASegmentation {
  key: string;
  value: number;
  expectedValue: number;
  ctcPp: number;
  count: number;
  expectedCount: number;
  explainabilityScore: number;
  interestScore: number;
  interestTypes: InterestType[];
  explainabilityLabel: ExplainabilityLabel;
  isInteresting: boolean;
  isSignificant: boolean;
  isInterestSignificant: boolean;
  isNeutralized: boolean;
  helperText: string;
  interestHelperText: string;
  groupKey: string;
  interestGroupKey: string;
  isPrimaryInGroup?: boolean;
  isInterestPrimaryInGroup: boolean;
  isEventProperty: boolean;
  similarityType: SimilarityType;
  interestSimilarityType: SimilarityType;
  samples: MetricSampleData[];
}

export interface SegmentOfRCASegmentation extends BaseSegmentOfRCASegmentation {
  segmentName: string;
  segmentGroupName: string;
  signalId: number;
}

export interface NewRCACompositeSegments extends BaseSegmentOfRCASegmentation {
  segmentKeys: string[];
}

export interface RCASegmentationFigureData {
  segments: SegmentOfRCASegmentation[];
  composites: NewRCACompositeSegments[];
  allPopulation: MetricSampleData[];
  populationTrend: number;
  granularity: 'day' | 'week' | 'month';
  totalEntities: number;
  expectedTotalEntities: number;
  isValidForExplainers: boolean;
  // deprecated in version 1.3, but we need to keep it for backward compatibility
  compositeSegmentsFigure: RCACompositeSegmentsFigure;
}

export interface RCASegmentationFigureOptions {
  kpiName: string;
  entity: string;
  higherIsBetter: boolean;
  isPercentageValue: boolean;
  showExplainability: boolean;
  isCountEntitiesMode: boolean;
  hasDenominator: boolean;
}

export interface RCASegmentationFigure
  extends FigureElement<RCASegmentationFigureData, RCASegmentationFigureOptions> {
  type: DocumentElementType.RCA_SEGMENTATION_FIGURE;
}

/**
 * RCA Population Explainer Figure
 */

export interface RCAPopulationExplainerFigureOptions {
  kpiName: string;
  referenceSignalName: string;
  entity: string;
  higherIsBetter: boolean;
  isPercentageValue: boolean;
}

export interface RCAPopulationExplainerFigureData {
  kpiSamples: MetricSampleData[];
  explainabilityLabel: ExplainabilityLabel;
  explainabilityScore: number;
  isSignificant: boolean;
  trendChange: number;
  granularity: 'day' | 'week' | 'month';
}

export interface RCAPopulationExplainerFigure
  extends FigureElement<RCAPopulationExplainerFigureData, RCAPopulationExplainerFigureOptions> {
  type: DocumentElementType.RCA_POPULATION_EXPLAINER_FIGURE;
}

/**
 * RCA Experiment Explainer Figure
 */

export interface RCAExperimentExplainerVariant {
  name: string;
  value: number;
  expectedValue: number;
  count: number;
  expectedCount: number;
  ctcPp: number;
  explainabilityScore: number;
  explainabilityLabel: ExplainabilityLabel;
  isSignificant: boolean;
  isControl: boolean;
}

export interface RCAExperimentExplainer {
  experimentName: string;
  experimentId: number;
  analysisTypeId: number;
  variants: RCAExperimentExplainerVariant[];
  totalPopulationCount: number;
  expectedPopulationCount: number;
  populationKpiTrend: number;
}

export interface RCAExperimentExplainerFigureOptions {
  kpiName: string;
  entity: string;
  higherIsBetter: boolean;
  isPercentageValue: boolean;
  isCountEntitiesMode: boolean;
}

export interface RCAExperimentExplainerFigureData {
  experiments: RCAExperimentExplainer[];
}

export interface RCAExperimentExplainerFigure
  extends FigureElement<RCAExperimentExplainerFigureData, RCAExperimentExplainerFigureOptions> {
  type: DocumentElementType.RCA_EXPERIMENT_EXPLAINER_FIGURE;
}

/**
 * Release Segmentation Figure
 **/
export interface SegmentOfReleaseSegmentation {
  key: string;
  segmentName: string;
  segmentGroupName: string;
  signalId: number;
  postValue: number;
  preValue: number;
  postCtcPp: number;
  preCtcPp: number;
  postCount: number;
  preCount: number;
  isSignificant: boolean;
  isInformative: boolean;
  helperText: string;
}

export interface ReleaseImpactSegmentationFigureData {
  segments: SegmentOfReleaseSegmentation[];
  postTotalEntities: number;
  preTotalEntities: number;
  postPopulationTrend: number;
  prePopulationTrend: number;
  releaseImpactValue: number;
}

export interface ReleaseImpactSegmentationFigureOptions {
  entity: string;
  higherIsBetter: boolean;
  isPercentageValue: boolean;
}

export interface ReleaseImpactSegmentationFigure
  extends FigureElement<
    ReleaseImpactSegmentationFigureData,
    ReleaseImpactSegmentationFigureOptions
  > {
  type: DocumentElementType.RELEASE_IMPACT_SEGMENTATION_FIGURE;
}

/**
 * RCA Overview Figure
 **/
export enum RCAMode {
  LOOPS_ALGO = 'loops_algo',
  COMPARE_TO_DATE = 'compare_to_date',
}

export interface MetricSampleData {
  value: number;
  expectedValue: number;
  upper?: number;
  lower?: number;
  entityCount?: number;
  datetime: string;
  isPartial: boolean;
}

export interface HistoricMetricSampleData extends MetricSampleData {
  matchingDatetime: string;
}

export interface RCAMetric {
  name: string;
  signalId: number;
  isPercentageValue: boolean;
  higherIsBetter: boolean;
  granularity: 'day' | 'week' | 'month';
  entity: string;
  hasEntityCount: boolean;
  samples: Array<MetricSampleData>;
  lastYearSamples?: Array<HistoricMetricSampleData>;
}

export interface RCAOverviewFigureData {
  metric: RCAMetric;
  analyzedDate: string;
  comparedDate?: string;
  startDate: string;
  endDate: string;
  mode: RCAMode;
  hasPopulationFilter: boolean;
}

export interface RCAOverviewFigureOptions {
  showCompare: boolean;
}

export interface RCAOverviewFigure
  extends FigureElement<RCAOverviewFigureData, RCAOverviewFigureOptions> {
  type: DocumentElementType.RCA_OVERVIEW_FIGURE;
}

/**
 * RCA Figure
 **/
export interface RCASignalDescriptor {
  name: string;
  signalId: number;
}

export enum RCAElementType {
  OVERVIEW = 'overview',
  SEGMENTATION = 'segmentation',
  POPULATION_SIZE = 'populationSize',
  POPULATION_EXPLAINER = 'populationExplainer',
  EXPERIMENTS_EXPLAINER = 'experimentsExplainer',
  EXPERIMENTS = 'experiments',
  ERRORS = 'errors',
  // internal
  _CHANGING_SEGMENTS = '_changingSegments',
}

export type RCAElements = {
  [RCAElementType.OVERVIEW]?: RCAOverviewFigure;
  [RCAElementType.SEGMENTATION]?: RCASegmentationFigure;
  [RCAElementType.POPULATION_EXPLAINER]?: RCAPopulationExplainerFigure;
  [RCAElementType.EXPERIMENTS_EXPLAINER]?: RCAExperimentExplainerFigure;
  // todo: remove this is deprecated
  [RCAElementType.POPULATION_SIZE]?: DocumentElement[];
  [RCAElementType.EXPERIMENTS]?: DocumentElement[];
  [RCAElementType.ERRORS]?: DocumentElement[];
};

export interface RCAFigureData {
  metric: RCASignalDescriptor;
  refDate: RCASignalDescriptor | null;
  group?: RCASignalDescriptor;
  analyzedDate: string;
  comparedDate: string;
  granularity: 'day' | 'week' | 'month';
  value: number;
  expectedValue: number;
  expectedUpper: number;
  expectedLower: number;
  comparedValue: number;
  loopsAlgoElements: RCAElements;
  comparedElements: RCAElements;
  isComparedSignificantChange: boolean;
  isComputingResult: boolean;
}

export interface RCAFigureOptions {
  higherIsBetter: boolean;
  isPercentageValue: boolean;
  entity: string;
  defaultMode?: RCAMode;
}

export interface RCAFigure extends FigureElement<RCAFigureData, RCAFigureOptions> {
  type: DocumentElementType.RCA_FIGURE;
}

export interface RCAFunnelFigureDataItem {
  fromStepSignalId: number;
  toStepSignalId: number;
  rcaFigure: RCAFigure;
}

export interface RCAFunnelFigureData {
  funnelId: string;
  items: RCAFunnelFigureDataItem[];
  steps: FunnelOverviewFigureStep[];
}

export interface RCAFunnelFigureOptions {
  entity: string;
}

export interface RCAFunnelFigure
  extends FigureElement<RCAFunnelFigureData, RCAFunnelFigureOptions> {
  type: DocumentElementType.RCA_FUNNEL_FIGURE;
}
/**
 * KPI Overview Figure
 **/
export interface KPIOverviewSegmentItem {
  key: string;
  signalId: number;
  segmentGroupName: string;
  segmentName: string;
  samples: MetricSampleData[];
}

export interface KPIOverviewFigureData {
  // items is deprecated
  items: KPIOverviewSegmentItem[];
  allPopulation: MetricSampleData[];
  goalValue: number;
  goalSignalId: number;
  granularity: 'day' | 'week' | 'month';
}

export interface KPIOverviewFigureOptions {
  entity: string;
  isPercentageValue: boolean;
  higherIsBetter: boolean;
  hasEntityCount: boolean;
  kpiName: string;
  showIncompleteMark: boolean;
}

export interface KPIOverviewFigure
  extends FigureElement<KPIOverviewFigureData, KPIOverviewFigureOptions> {
  type: DocumentElementType.KPI_OVERVIEW_FIGURE;
}
