import {calculateUplift, number2k} from 'front-core';
import moment, {Moment} from 'moment';
import {useMemo} from 'react';
import TransKeys from 'translations';
import {DEFAULT_INPUT_DATE_FORMAT} from '../../../../../../../../consts/ui';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {RCAFigureData, RCAFigureOptions} from '../../../../../types';
import classes from '../components/rca-header/rca-header.module.scss';

const DATE_FORMAT = 'DD MMM YY';
const DEFAULT_THRESHOLD = 0.2;

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_FIGURE;

export type UseRCAHeaderDataParams = {
  data: RCAFigureData;
  options: RCAFigureOptions;
  shouldUseThreshold: boolean;
};

export type UseRCAPeriodsReturnType = ReturnType<typeof useRCAPeriods>;

export const useRCAPeriods = (params: UseRCAHeaderDataParams) => {
  const {data, options, shouldUseThreshold} = params;
  const {
    value,
    expectedLower,
    expectedUpper,
    expectedValue,
    granularity,
    comparedValue,
    comparedDate,
    analyzedDate,
    isComparedSignificantChange,
    isComputingResult,
  } = data;
  const {t} = useDocumentTranslation();
  const {comparedValueUpper, comparedValueLower} = useMemo(
    () => ({
      comparedValueUpper: comparedValue * (1 + DEFAULT_THRESHOLD),
      comparedValueLower: comparedValue * (1 - DEFAULT_THRESHOLD),
    }),
    [comparedValue, DEFAULT_THRESHOLD]
  );
  let unit = granularity;
  const analyzedStartDateMoment = moment.utc(analyzedDate, DEFAULT_INPUT_DATE_FORMAT);
  const comparedStartDateMoment = moment.utc(comparedDate, DEFAULT_INPUT_DATE_FORMAT);

  let analyzedEndDateMoment: Moment;
  let comparedEndDateMoment: Moment;
  if (unit === 'day') {
    analyzedEndDateMoment = null;
    comparedEndDateMoment = null;
  } else {
    analyzedEndDateMoment = analyzedStartDateMoment.clone().add(1, unit).subtract(1, 'd');
    comparedEndDateMoment = comparedStartDateMoment.clone().add(1, unit).subtract(1, 'd');
  }
  const isSignificantExpected = value < expectedLower || value > expectedUpper;
  const isSignificantCompared = value < comparedValueLower || value > comparedValueUpper;
  const expectedUplift = calculateUplift(value, expectedValue);
  const comparedUplift = calculateUplift(value, comparedValue);

  const current = {
    value: options.isPercentageValue ? number2k(value * 100) : number2k(value),
    startDate: analyzedStartDateMoment.format(DATE_FORMAT),
    label: t(TransKeysPrefix.HEADER.LABELS.ANALYZED_PERIOD),
    endDate: analyzedEndDateMoment ? analyzedEndDateMoment.format(DATE_FORMAT) : null,
  };
  const expected = {
    value: options.isPercentageValue ? number2k(expectedValue * 100) : number2k(expectedValue),
    startDate: analyzedStartDateMoment.format(DATE_FORMAT),
    endDate: analyzedEndDateMoment ? analyzedEndDateMoment.format(DATE_FORMAT) : null,
    uplift: expectedUplift,
    label: t(TransKeysPrefix.HEADER.LABELS.LOOPS_EXPECTED),
    helperText: t(TransKeysPrefix.HEADER.LOOPS_EXPECTED_HELPER_TEXT),
    isSignificant: isSignificantExpected,
    significantText: isSignificantExpected
      ? expectedUplift > 0
        ? t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_TITLE)
        : t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_TITLE)
      : 'not significant',
  };
  const comparedDates = {
    startDate: comparedStartDateMoment.format(DATE_FORMAT),
    endDate: comparedEndDateMoment ? comparedEndDateMoment.format(DATE_FORMAT) : null,
  };

  const compared = {
    value: options.isPercentageValue ? number2k(comparedValue * 100) : number2k(comparedValue),
    startDate: comparedDates.startDate,
    endDate: comparedDates.endDate,
    uplift: comparedUplift,
    isSignificant: isSignificantCompared,
    label: t(TransKeysPrefix.HEADER.LABELS.COMPARED_PERIOD),
    significantText: isSignificantCompared
      ? comparedUplift > 0
        ? t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_RISE_TITLE, {
            percent: DEFAULT_THRESHOLD * 100,
          })
        : t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.WOW_INVESTIGATE_DROP_TITLE, {
            percent: DEFAULT_THRESHOLD * 100,
          })
      : 'not significant',
  };

  if (!shouldUseThreshold) {
    compared.isSignificant = isComparedSignificantChange;
    compared.significantText = isComparedSignificantChange
      ? comparedUplift > 0
        ? t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_RISE_TITLE)
        : t(TransKeys.HOMEPAGE.METRIC_CHART.LABELS.INVESTIGATE_DROP_TITLE)
      : 'not significant';
  }

  if (isComputingResult) {
    compared.significantText = (
      <div className={classes.Calc}>
        {t(TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.CALCULATING_SIGNIFICANCE)}
      </div>
    ) as any;
  }

  return {
    comparedDates,
    current,
    expected,
    compared,
  };
};
