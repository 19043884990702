import {useCallback, useMemo} from 'react';
import {cloneDeep, get} from 'lodash';
import {QueryBuilderComponent} from './query-builder-ui.types';
import {AddIcon, CloseIcon} from '../../simple/controls/icons/icons.component';
import {queryBuilderDefaultComponents} from './query-builder.config';
import {SqlElementType} from './query-builder.types';

export const useQueryElement = (props: QueryBuilderComponent) => {
  const {path, errors, onDelete: onDelete_, actions: actions_, accept, wrapWith} = props;
  const onDelete = useCallback(() => onDelete_(path), [onDelete_, path]);
  const localErrors = useMemo(() => (path ? get(errors, path, {}) : errors), [errors, path]);
  const staticActions = useMemo(
    () => [
      {
        icon: AddIcon,
        label: `Wrap with ${queryBuilderDefaultComponents[SqlElementType.COALESCE].name}`,
        onClick: () => wrapWith(SqlElementType.COALESCE),
        hidden: accept.indexOf(SqlElementType.COALESCE) === -1,
        extended: true,
      },
      {
        icon: AddIcon,
        label: `Wrap with ${queryBuilderDefaultComponents[SqlElementType.FILL_NULL].name}`,
        onClick: () => wrapWith(SqlElementType.FILL_NULL),
        hidden: accept.indexOf(SqlElementType.FILL_NULL) === -1,
        extended: true,
      },
      {
        icon: AddIcon,
        label: `Wrap with ${queryBuilderDefaultComponents[SqlElementType.CASES].name}`,
        onClick: () => wrapWith(SqlElementType.CASES),
        hidden: accept.indexOf(SqlElementType.CASES) === -1,
        extended: true,
      },
      {
        icon: CloseIcon,
        label: 'Remove',
        onClick: onDelete,
      },
    ],
    [accept, onDelete, wrapWith]
  );

  const actions = useMemo(
    () => (actions_ ? [...actions_, ...staticActions] : staticActions),
    [staticActions, actions_]
  );

  return {
    onDelete,
    localErrors,
    actions,
  };
};

export const useQueryArray = (
  data: any,
  onChange: (data: any) => void,
  creator: (...params: any[]) => any = () => {}
) => {
  const addElement = useCallback(
    (...parameters) => onChange([...data, creator(...parameters)]),
    [creator, data, onChange]
  );
  const removeElement = useCallback(
    (index: number) => {
      const newArr = [...data];
      newArr.splice(index, 1);
      onChange(newArr);
    },
    [data, onChange]
  );
  const cloneElement = useCallback(
    (index: number) => {
      const item = data[index];
      const newArr = [...data];
      newArr.splice(index + 1, 0, cloneDeep(item));
      onChange(newArr);
    },
    [data, onChange]
  );
  const moveElement = useCallback(
    (index: number, newIndex: number) => {
      const newArr = [...data];
      newArr[index] = newArr.splice(newIndex, 1, newArr[index])[0];
      onChange(newArr);
    },
    [data, onChange]
  );

  return {
    addElement,
    removeElement,
    cloneElement,
    moveElement,
  };
};
