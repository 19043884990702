import * as React from 'react';
import classNames from 'classnames';
import classes from './funnel-chip.module.scss';
import {
  BackwardFastLightIcon,
  BackwardStepLightIcon,
  UserGroupDuotoneIcon,
} from '../../../../../simple/controls/icons/icons.component';
import {number2k} from 'front-core';
import {FunnelTrendValue} from '../../../../charts/chart-data.types';
import {FunnelChartMode} from '../../funnel-chart.component';
import {FunnelTrend} from '../funnel-trend/funnel-trend.component';
import {useMemo} from 'react';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  mode: FunnelChartMode;
  label?: string;
  total?: number;
  conversionFromPrev?: number;
  conversionFromStart?: number;
  trend?: FunnelTrendValue;
  helperText?: any;
  className?: string;
}

type AllProps = OwnProps;

export const FunnelChip: React.FC<AllProps> = (props: AllProps) => {
  const {
    mode,
    label,
    total,
    conversionFromPrev,
    conversionFromStart,
    trend,
    helperText,
    className,
  } = props;

  const renderValue = useMemo(() => {
    switch (mode) {
      case FunnelChartMode.CONVERSION_FROM_INITIAL_STEP:
        return `${number2k(conversionFromStart * 100)}%`;
      case FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP:
        return `${number2k(conversionFromPrev * 100)}%`;
      case FunnelChartMode.TOTALS:
        return `${number2k(total)}`;
      default:
        return null;
    }
  }, [mode, total, conversionFromPrev, conversionFromStart]);

  return (
    <Tooltip title={helperText} placement={'top'} interactive={false}>
      <div className={classNames(classes.FunnelChip, className)}>
        {label && <span className={classes.Label}>{label}</span>}
        {mode === FunnelChartMode.TOTALS && <UserGroupDuotoneIcon className={classes.Icon} />}
        {mode === FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP && (
          <BackwardStepLightIcon className={classes.Icon} />
        )}
        {mode === FunnelChartMode.CONVERSION_FROM_INITIAL_STEP && (
          <BackwardFastLightIcon className={classes.Icon} />
        )}
        {mode === FunnelChartMode.TRENDS && <FunnelTrend trend={trend} />}
        {renderValue}
      </div>
    </Tooltip>
  );
};

FunnelChip.defaultProps = {};
