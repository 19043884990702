import * as React from 'react';
import {EnhancedGroup, EnhancedRect} from '../components';
import {ShapeProps} from './enhanced-shape.hoc';
import {CanvasElementPositions} from '../stage.types';
import {useMemo, useState} from 'react';
import {min, max} from 'lodash';

interface OwnProps extends CanvasElementPositions {
  children: (offsetY: number) => JSX.Element;
  childrenHeight: number;
  scrollBarColor?: string;
  scrollBarWidth?: number;
}

type AllProps = ShapeProps & OwnProps;

const DEFAULT_SCROLL_BAR_WIDTH = 6;

export const ScrollableView: React.FC<AllProps> = (props: AllProps) => {
  const {children, childrenHeight, height, width, scrollBarColor, scrollBarWidth} = props;

  const [offsetY, setOffsetY] = useState(0);
  const ratio = useMemo(() => height / childrenHeight, [height, childrenHeight]);
  const barHeight = useMemo(() => ratio * height, [height, ratio]);

  const onWheel = e => {
    e.evt.preventDefault();
    e.evt.stopPropagation();
    const {deltaY} = e.evt;
    setOffsetY(offsetY => min([max([offsetY + deltaY, 0]), childrenHeight - height]));
  };

  return (
    <EnhancedGroup x={props.x} y={props.y} width={width} height={height}>
      {/* Overlay which listens to onWheel */}
      {ratio < 1 && (
        <EnhancedRect
          onWheel={onWheel}
          x={0}
          y={0}
          height={height}
          width={width}
          fill={'transparent'}
          opacity={0}
        />
      )}
      {/* Scroll bar */}
      {ratio < 1 && (
        <EnhancedRect
          offsetY={-(offsetY * ratio)}
          x={width - scrollBarWidth}
          y={0}
          width={scrollBarWidth}
          height={barHeight}
          fill={scrollBarColor}
          cornerRadius={8}
        />
      )}
      {children(offsetY)}
    </EnhancedGroup>
  );
};

ScrollableView.defaultProps = {
  scrollBarColor: 'rgba(0, 0, 0, 0.1)',
  scrollBarWidth: DEFAULT_SCROLL_BAR_WIDTH,
};
