import * as React from 'react';
import classNames from 'classnames';
import classes from './dialog.module.scss';
import {useEffect, useMemo, useRef, useState} from 'react';
import {DialogLayout} from '../../simple/generic/dialog-layout/dialog-layout.component';
import {SimpleSearch} from '../../simple/custom-inputs/simple-search/simple-search.component';
import {Checkbox} from '../inputs/checkbox/checkbox.component';
import {Button} from '../../simple/controls/button/button.component';
import {isArray, isNumber} from 'lodash';
import {useListActiveIndex} from '../../../hooks/use-list-active-index.hook';
import {EnumInputOption, EnumInputOptions, SelectedOption} from '../inputs.types';
import {exists} from 'front-core';
import {TooltipIfOverflow} from '../../simple/generic/tooltips/tooltips.component';
import {CircleInfoRegularIcon} from '../../simple/controls/icons/icons.component';
import {HoverHelperTip} from '../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import VirtualScroll from 'react-dynamic-virtual-scroll';

interface OwnProps {
  className?: string;
  value?: SelectedOption;
  onSubmit?: (value: SelectedOption) => void;
  mode?: 'onSubmit' | 'onChange';
  label?: string;
  multi?: boolean;
  options: EnumInputOptions;
  searchable?: boolean;
  sortValues?: boolean;
  freeText?: boolean;
  selectionHelper?: any;
  hideSelectedOption?: boolean;
  fitContent?: boolean;
}

type AllProps = OwnProps;

const fixValue = v => (isArray(v) ? v : exists(v) ? [v] : []);
const generateClassNameForOption = idx => `option_${idx}`;

export const EnumDialog: React.FC<AllProps> = (props: AllProps) => {
  const {
    multi,
    onSubmit: onSubmit_,
    value: value_,
    options: options_,
    searchable,
    sortValues,
    className,
    freeText,
    hideSelectedOption,
    selectionHelper,
    mode,
    fitContent,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [value, setValue] = useState<string[]>(fixValue(value_));
  const listRef = useRef<HTMLDivElement>(null);

  const options = useMemo(() => {
    let options = options_.options || [];
    options = options
      .filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()))
      .map(o => ({
        ...o,
        isSelected: value.indexOf(o.value as any) > -1,
      }));
    if (sortValues) {
      options = options.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
    return options;
  }, [options_, searchValue, value, sortValues, hideSelectedOption]);

  const {activeIndex, setActiveIndex, keyUpHandler} = useListActiveIndex({
    totalCount: options.length,
    listParent: listRef.current,
    getClassNameForIndex: generateClassNameForOption,
    onEnter: index => isNumber(index) && index >= 0 && onOptionClicked(options[index]),
  });

  useEffect(() => {
    setValue(fixValue(value_));
  }, [value_]);

  const onOptionClicked = option => {
    if (!option) {
      return;
    }
    const optionValue = option.value;
    if (!multi) {
      onSubmit_(optionValue);
    } else {
      const set = new Set(value);
      if (set.has(optionValue)) {
        set.delete(optionValue);
      } else {
        set.add(optionValue);
      }
      const newValue = Array.from(set);
      if (mode === 'onChange') {
        onSubmit_(newValue);
        return;
      }
      setValue(newValue);
    }
    setActiveIndex(null);
  };

  const onSubmit = () => onSubmit_(multi ? value : value[0]);

  const renderLabel = (option: EnumInputOption) => {
    if (!option.labelRenderer) {
      return (
        <>
          {option.icon && (
            <div className={classes.IconWrapper}>
              <option.icon className={classes.Icon} />
            </div>
          )}
          <TooltipIfOverflow title={option.label}>
            <div className={classes.Label}>{option.label}</div>
          </TooltipIfOverflow>
          {option.helperText && <HoverHelperTip title={option.helperText} />}
        </>
      );
    }
    return option.labelRenderer(option);
  };

  const renderOption_ = (option, idx) => (
    <div
      onClick={option.disabled ? undefined : e => onOptionClicked(option)}
      key={option.value}
      className={classNames(
        classes.Option,
        idx === activeIndex && classes.Active,
        value.indexOf(option.value) > -1 && classes.Selected,
        option.disabled && classes.Disabled,
        generateClassNameForOption(idx),
        option.className
      )}
    >
      {multi && (
        <Checkbox
          multi={true}
          checked={option.isSelected}
          className={classes.Checkbox}
          onChange={option.disabled ? undefined : () => onOptionClicked(option)}
        />
      )}
      {renderLabel(option)}
    </div>
  );
  const renderAllOptions = () => {
    return (
      <VirtualScroll
        className={classes.VirtualScrollWrapper}
        minItemHeight={24}
        totalLength={options.length}
        renderItem={index => {
          const option = options[index];
          return renderOption_(option, index);
        }}
      />
    );
  };

  const showAdd = useMemo(
    () => options.length === 0 && freeText && exists(searchValue),
    [options, freeText, searchValue]
  );
  const showNoResults = useMemo(() => {
    if (options.length > 0) {
      return false;
    }
    if (showAdd) {
      return false;
    }
    return true;
  }, [options, showAdd]);

  return (
    <DialogLayout
      className={classNames(classes.EnumFilter, fitContent && classes.FitContent, className)}
    >
      {searchable && (
        <div className={classes.SearchWrapper}>
          <SimpleSearch
            className={classes.Search}
            value={searchValue}
            onChange={v => setSearchValue(v)}
            onKeyUp={keyUpHandler}
            placeholder={'Search...'}
          />
        </div>
      )}
      <div ref={listRef} className={classes.Form}>
        {selectionHelper && (
          <div className={classes.SelectionHelper}>
            <CircleInfoRegularIcon className={classes.Icon} />
            {selectionHelper}
          </div>
        )}
        {renderAllOptions()}
        {showNoResults && <div className={classes.NoResults}>No results</div>}
        {showAdd && (
          <div onClick={e => onOptionClicked({value: searchValue})} className={classes.AddOption}>
            Add Option "{searchValue}"
          </div>
        )}
      </div>
      {onSubmit_ && multi && mode === 'onSubmit' && (
        <Button className={classes.ApplyBtn} onClick={() => onSubmit()}>
          Apply
        </Button>
      )}
    </DialogLayout>
  );
};

EnumDialog.defaultProps = {
  searchable: true,
  mode: 'onSubmit',
};
