import {cloneDeep} from 'lodash';
import {CommandType, DocumentCommand, FollowUpType} from '../../../../../../types';

export const combineFollowUpCommands = (commands: DocumentCommand<any>[]) => {
  if (commands.length === 0) {
    return null;
  }

  const commandTypes = new Set(commands.map(command => command.type));
  const commandFollowUpTypes = new Set(commands.map(command => command.payload.type));
  const featureSignalIds = new Set(
    commands.map(command => command.payload.parameters.featureSignal)
  );

  if (commandTypes.size > 1 || commandFollowUpTypes.size > 1) {
    return null;
  }

  if (
    commandTypes.values().next().value !== CommandType.FOLLOW_UP ||
    commandFollowUpTypes.values().next().value !==
      FollowUpType.GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION
  ) {
    return null;
  }

  const cumulativeCommand = cloneDeep(commands[0]);
  cumulativeCommand['payload']['parameters']['featuresSignals'] = Array.from(featureSignalIds);

  return cumulativeCommand;
};
