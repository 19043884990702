import * as React from 'react';
import classNames from 'classnames';
import classes from './simple-search.module.scss';
import {useEffect, useState} from 'react';
import {Button, SearchIcon} from '../../../../index';

interface OwnProps {
  className?: string;
  value?: string;
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  onKeyUp?: (e: any) => void;
  placeholder?: string;
}

type AllProps = OwnProps;

export const SimpleSearch: React.FC<AllProps> = (props: AllProps) => {
  const {onChange, onSubmit: onSubmit_, onKeyUp, value: value_, className, placeholder} = props;

  const [value, setValue] = useState(value_ || '');
  useEffect(() => setValue(value_), [value_]);

  const onSubmit = e => {
    e?.preventDefault();
    onSubmit_ && onSubmit_(value);
  };
  const onValueChange = e => (onChange ? onChange(e.target.value) : setValue(e.target.value));

  return (
    <div className={classNames(classes.SimpleSearch, className)}>
      <form onSubmit={onSubmit} className={classes.Form}>
        <input
          type={'text'}
          className={classes.Input}
          onChange={onValueChange}
          onKeyUp={onKeyUp}
          value={value || ''}
          placeholder={placeholder}
          autoFocus
        />
        <SearchIcon className={classes.SearchIcon} />
        {onSubmit_ && (
          <Button className={classes.ApplyBtn} onClick={onSubmit}>
            Apply
          </Button>
        )}
      </form>
    </div>
  );
};

SimpleSearch.defaultProps = {};
