import * as React from 'react';
import classNames from 'classnames';
import {AdoptionRateOptions} from '../../../../../types';
import {useMemo} from 'react';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists, number2k} from 'front-core';
import classes from './data-columns.module.scss';
import {getDataValueKey} from '../../smart-table.utils';
import {DataColumnProps} from '../../smart-table.types';
import tableClasses from '../../smart-table.module.scss';
import {TooltipIfOverflow} from '../../../../../../../simple/generic/tooltips/tooltips.component';

interface OwnProps extends DataColumnProps<AdoptionRateOptions> {}

type AllProps = OwnProps;

const AdoptionRateDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;
  const {totalDataKey, totalSuffix} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);
  const value = useMemo(() => data[valueDataKey], [valueDataKey, data]);
  const total = useMemo(() => data[totalDataKey], [totalDataKey, data]);
  const totalText = useMemo(() => `${number2k(total)} ${totalSuffix}`, [total, totalSuffix]);

  return (
    <div className={classNames(classes.AdoptionRateColumn, className)}>
      <div className={classNames(classes.Value)}>{number2k(value)}%</div>
      <TooltipIfOverflow title={totalText}>
        <div className={classNames(classes.Total, tableClasses.ShowOnHover)}>({totalText})</div>
      </TooltipIfOverflow>
    </div>
  );
};

const protector: Protector = (props: OwnProps) =>
  exists(props.data[getDataValueKey(props.column, 'valueDataKey')]);
export const AdoptionRateDataColumn = withDataColumnProtector(protector)(
  AdoptionRateDataColumnComponent
);
