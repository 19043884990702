import * as React from 'react';
import classNames from 'classnames';
import classes from './empty-state.module.scss';
import {Button} from '../../../../index';

interface OwnProps {
  image?: any;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'light' | 'dark';
  title?: string;
  subTitle?: string;
  text?: string;
  buttonText?: string;
  buttonVariant?: 'contained' | 'outlined';
  onClick?: () => void;
  error?: boolean;
  background?: boolean;
}

type AllProps = OwnProps;

export const EmptyState: React.FC<AllProps> = (props: AllProps) => {
  const {
    size,
    title,
    subTitle,
    text,
    buttonText,
    onClick,
    variant,
    buttonVariant,
    error,
    image,
    background,
    className,
  } = props;

  const renderImage = () => {
    if (typeof image === 'string') {
      return <img className={classes.Image} src={image} alt={title} />;
    }
    if (typeof image === 'object') {
      return <props.image className={classes.Icon} />;
    }
    return null;
  };

  return (
    <div
      className={classNames(
        classes.EmptyState,
        classes[size],
        classes[variant],
        error && classes.Error,
        className
      )}
    >
      <div className={classNames(classes.Circle, background === false && classes.Transparent)}>
        {renderImage()}
        {title && <div className={classes.Title}>{title}</div>}
        {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
        {buttonText && onClick && (
          <Button onClick={onClick} variant={buttonVariant}>
            {buttonText}
          </Button>
        )}
      </div>
      {text && <div className={classes.Text}>{text}</div>}
    </div>
  );
};

EmptyState.defaultProps = {
  size: 'medium',
  image: undefined,
};
