import * as React from 'react';
import classes from './icon-counter.module.scss';
import classNames from 'classnames';
import {GrowthMapIcon} from '../growth-map-icon/growth-map-icon.component';

export interface IconCounter {
  icon?: any;
  count: number;
  iconColor?: string;
  label?: string;
  onClick?: (e) => void;
}

interface OwnProps {
  counters: IconCounter[];
  className?: string;
  counterClassName?: string;
  isExpanded?: boolean;
  textColor?: string;
}

type AllProps = OwnProps;

export const IconCounters: React.FC<AllProps> = (props: AllProps) => {
  const {counters, className, counterClassName, isExpanded, textColor} = props;

  return (
    <div className={classNames(classes.IconCounters, isExpanded && classes.Expanded, className)}>
      {counters.map(({icon, iconColor, count, label, onClick}, idx) => (
        <div key={idx} className={classes.IconCounterWrapper}>
          {isExpanded && <div className={classes.Label}>{label}</div>}
          <div className={classes.Content}>
            <div className={classNames(classes.IconCounter, counterClassName)}>
              <GrowthMapIcon
                variant={isExpanded ? 'dark' : 'light'}
                icon={icon}
                color={iconColor}
              />
              <div style={{color: textColor}} className={classes.Count}>
                {count || 0}
              </div>
            </div>
            {isExpanded && count > 0 && onClick && (
              <div onClick={onClick} className={classes.SeeAll}>
                see all
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

IconCounters.defaultProps = {
  textColor: 'rgba(41, 50, 101, 1)',
};
