import * as React from 'react';
import {useContext, useMemo} from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {NumberDataset} from '../../chart-data.types';
import {calculateBbox, number2k} from '../../../../../core/konva/stage.utils';
import {StepsLayoutInjectedProps} from '../../layouts/steps-layout.component';
import {max, range, flatten} from 'lodash';
import {Polygon} from '../../components/polygon.component';
import {ChartLabel} from '../../components/chart-label.component';
import {EnhancedGroup, EnhancedText} from '../../../../../core/konva/components';
import {ChartContext} from '../../chart.context';

interface OwnProps extends StepsLayoutInjectedProps {
  dataset: NumberDataset;
}

type AllProps = OwnProps;

export const AreaChartDatasetContainer: React.FC<AllProps> = (props: AllProps) => {
  const {width, height, x, y, dataset} = props;
  const {style, controller} = useContext(StageContext);
  const {darkMode} = useContext(ChartContext);
  /**
   * Data computed properties
   */
  const numOfSteps = useMemo(() => dataset.data.length || 1, [dataset]);
  const stepSize: number = useMemo(() => width / numOfSteps || 10, [width, numOfSteps]);
  const highestValue: number = useMemo(() => max(dataset.data), [dataset]);
  const xPositions = useMemo(
    () => range(0, numOfSteps).map(i => i * stepSize + stepSize / 2),
    [numOfSteps, stepSize]
  );
  const polygonBbox = useMemo(() => calculateBbox(width, height, [12, 0, 60, 0]), [width, height]);
  const percentages = useMemo(
    () =>
      dataset.data.map((_: number, idx: number) => {
        let label: any = idx === 0 ? 100 : (dataset.data[idx] / dataset.data[idx - 1]) * 100;
        label = `${+label.toFixed(2)}%`;
        return {
          label: label,
          width: controller.measureTextWidth(label, style.fontName, 24) + 8,
          x: xPositions[idx],
        };
      }),
    [controller, dataset.data, style.fontName, xPositions]
  );
  const values = useMemo(
    () =>
      dataset.data.map((v: number) => ({
        value: number2k(v),
        width: controller?.measureTextWidth(number2k(v), style.fontName, 12) + 20,
      })),
    [controller, dataset.data, style.fontName]
  );
  const points = useMemo(() => {
    const {width, height} = polygonBbox;
    const coefficient = polygonBbox.height / highestValue;
    const points = dataset.data.map((value: number, idx: number) => {
      const stepHeight = coefficient * value;
      return [idx * stepSize, height - stepHeight];
    });
    points.push([width, height]);
    points.push([0, height]);
    return flatten(points);
  }, [dataset.data, highestValue, polygonBbox, stepSize]);
  /**
   * Render
   */
  return (
    <EnhancedGroup width={width} height={height} x={x} y={y}>
      <EnhancedGroup {...polygonBbox}>
        <Polygon
          points={points}
          color={dataset.color}
          width={width}
          tooltipData={{
            datasetName: dataset.label,
            color: dataset.color,
          }}
        />
      </EnhancedGroup>
      {values.map((value, idx) => (
        <ChartLabel
          key={`value_${idx}`}
          x={xPositions[idx]}
          y={polygonBbox.height}
          width={value.width}
          height={20}
          value={value.value}
          centerX
        />
      ))}
      {percentages.map((value, idx) => (
        <EnhancedText
          key={`percentage_${idx}`}
          x={xPositions[idx]}
          y={polygonBbox.height + 40}
          fill={darkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(41, 50, 101, 1)'}
          fontSize={24}
          fontFamily={style.fontName}
          text={value.label}
          width={value.width}
          height={24}
          centerX
        />
      ))}
    </EnhancedGroup>
  );
};

AreaChartDatasetContainer.defaultProps = {};
