import {useRef, useEffect} from 'react';

export enum PendoAnchors {
  FUNNEL_SEGMENTATION_VIEWER = 'funnel-segmentation-viewer',
  SHARE_ANALYSIS_BTN = 'share-analysis',
  PAGE_LAYOUT_ACTIONS = 'page-layout-actions',
  SIDE_MENU = 'side-menu',
  SIDE_MENU_ITEMS = 'side-menu-items',
  FLEXIBLE_TABLE = 'flexible-table',
  FLEXIBLE_TABLE_ITEMS = 'flexible-table-items',
  TREATMENT_ADOPTION_SIMULATION_VIEWER = 'treatment-adoption-simulation-viewer',
  SIMULATOR_MODE_SWITCH = 'simulator-mode-switch',
  APP_TABS = 'app-tabs',
}

export function usePendoAnchor(anchorName: PendoAnchors) {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute('data-pendo-anchor', anchorName);
    }
  }, [ref?.current, anchorName]);

  return ref;
}
