import {
  MultiLoadResponse,
  SmartSelector,
  SmartSelectorParameters,
  SmartSelectorSource,
  useRemoteSourceStated,
} from 'ui-components';
import classes from './metric-node-selector.module.scss';
import {useCallback, useContext, useEffect, useMemo} from 'react';
import {groupBy} from 'lodash';
import {exists, HttpClientContext} from 'front-core';
import {getMetricNodeOptionsNetworkRequest} from '../../../../../../http/metric-tree.network-requests.ts';
import {getMetricCategoriesNetworkRequest} from '../../../../../../http/metric-categories.network-requests.ts';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import {CorrelationChip} from '../correlation-chip/correlation-chip.component.tsx';
import {sortData} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {KPITreeContext} from '../../kpi-tree.context.tsx';

interface OwnProps {
  relatedMetricId?: number;
  onChange: (value: number, item?: any) => void;
}

type AllProps = OwnProps;
const TOP_CORRELATED_METRICS_KEY = 'top_correlated_metrics';
const MIN_CORRELATION = 0.3;

const generateMetricCategoryKey = (categoryId?: number | string) => {
  if (!exists(categoryId) || categoryId === 'null') {
    categoryId = 'general';
  }
  return `metric_category_${categoryId}`;
};

export const MetricNodeSelector = (props: AllProps) => {
  const {relatedMetricId, onChange} = props;
  const http = useContext(HttpClientContext);
  const {t} = useTranslation();
  const {teamId} = useContext(KPITreeContext);
  const {
    source: metricCategories,
    exec: getMetricCategories,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getMetricCategoriesNetworkRequest,
  });

  const sources: SmartSelectorSource[] = useMemo(() => {
    if (!metricCategories) {
      return [];
    }
    return [
      {
        key: TOP_CORRELATED_METRICS_KEY,
        name: t(TransKeys.KPI_TREE.KPI_SELECTOR.SUGGESTED_CATEGORY_LABEL),
        onSelect: item => onChange(item.id, item),
        startOpen: true,
      },
      ...metricCategories
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(category => ({
          key: generateMetricCategoryKey(category.id),
          name: category.name,
          onSelect: item => onChange(item.id, item),
        })),
      {
        key: generateMetricCategoryKey(),
        name: 'General KPIs',
        onSelect: item => onChange(item.id, item),
      },
    ];
  }, [onChange, metricCategories, t]);
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      const response = await http.exec(
        getMetricNodeOptionsNetworkRequest({
          q: parameters.query,
          relatedMetricId,
          teamId,
        })
      );
      // order by correlation desc
      const metrics = sortData(
        response.data.map(metric => ({
          ...metric,
          correlationAbs: metric.correlation ? Math.abs(metric.correlation) : undefined,
          chip: metric.correlation ? (
            <CorrelationChip
              correlation={metric.correlation}
              isSignificant={metric.isSignificantCorrelation}
            />
          ) : undefined,
        })),
        {order: 'desc', orderBy: 'isSignificantCorrelation'},
        {order: 'desc', orderBy: 'correlationAbs'}
      );
      const res = {};
      // group by category
      const groups = groupBy(metrics, 'categoryId');
      Object.keys(groups).forEach(key => {
        res[generateMetricCategoryKey(key)] = {
          data: groups[key],
          total: groups[key].length,
          hasNext: false,
        };
      });

      // get top correlated metrics
      let topCorrelatedMetrics = [];
      if (relatedMetricId) {
        topCorrelatedMetrics = metrics.filter(
          m => m.correlationAbs > MIN_CORRELATION && m.isSignificantCorrelation
        );
        res[TOP_CORRELATED_METRICS_KEY] = {
          data: topCorrelatedMetrics,
          total: topCorrelatedMetrics.length,
          hasNext: false,
        };
      }

      return res;
    },
    [http, teamId, relatedMetricId]
  );

  useEffect(() => {
    getMetricCategories();
  }, [getMetricCategories]);

  return (
    <div className={classes.MetricSelector}>
      {isLoading && <GenericLoading />}
      {metricCategories && (
        <SmartSelector
          className={classes.Selector}
          sources={sources}
          load={load}
          withPreview={false}
        />
      )}
    </div>
  );
};
