import * as React from 'react';
import classNames from 'classnames';
import {ChangeColumnOptions} from '../../../../../types';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists, number2k} from 'front-core';
import classes from './data-columns.module.scss';
import {ArrowIconUp, MinusIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {useMemo} from 'react';
import {isBoolean} from 'lodash';
import {DataColumnProps} from '../../smart-table.types';
import tableClasses from '../../smart-table.module.scss';

interface OwnProps extends DataColumnProps<ChangeColumnOptions> {}

type AllProps = OwnProps;

const GOOD_COLOR = 'rgba(33, 196, 118, 1)';
const BAD_COLOR = 'rgba(246, 107, 107, 1)';

const ChangeDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, dataColumnOptions, className} = props;
  const value = useMemo(() => data[column.key], [column.key, data]);
  const higherIsBetter = useMemo(
    () => (exists(column.typeOptions.higherIsBetter) ? column.typeOptions.higherIsBetter : true),
    [column]
  );
  const threshold = useMemo(() => column.typeOptions.threshold || 0, [column]);
  const fromValue = useMemo(() => data[column.typeOptions.fromValueDataKey], [column]);
  const toValue = useMemo(() => data[column.typeOptions.toValueDataKey], [column]);
  const fromToValueSuffix = column.typeOptions.fromToValueSuffix || '';
  const isPositive = useMemo(() => {
    if (value === 0) {
      return undefined;
    }
    if (higherIsBetter) {
      return value > 0;
    }
    return value < 0;
  }, [value, threshold, higherIsBetter]);
  const iconColor = useMemo(() => {
    if (dataColumnOptions?.color) {
      return dataColumnOptions?.color;
    }
    if (isPositive === true) {
      return GOOD_COLOR;
    }
    if (isPositive === false) {
      return BAD_COLOR;
    }
  }, [isPositive, value, threshold]);

  return (
    <div className={classNames(classes.ChangeDataColumn, value < 0 && classes.FlipIcon, className)}>
      <div className={classes.IconWrapper}>
        {isBoolean(isPositive) && (
          <ArrowIconUp className={classes.Icon} style={{color: iconColor}} />
        )}
        {!isBoolean(isPositive) && (
          <MinusIcon
            className={classNames(classes.Icon, classes.Flat)}
            style={{color: iconColor}}
          />
        )}
      </div>
      <div className={classes.Value}>
        {value > 0 ? '+' : ''}
        {exists(value) ? `${number2k(value)}%` : '--'}
      </div>
      {fromValue && toValue && (
        <div
          className={classNames(
            classes.FromTo,
            tableClasses.SecondaryText,
            tableClasses.ShowOnHover
          )}
        >
          {number2k(fromValue)}
          {fromToValueSuffix} → {number2k(toValue)}
          {fromToValueSuffix}
        </div>
      )}
    </div>
  );
};

const protector: Protector = (props: OwnProps) => exists(props.data[props.column.key]);
export const ChangeDataColumn = withDataColumnProtector(protector)(ChangeDataColumnComponent);
