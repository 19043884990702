import * as React from 'react';
import classes from './dialog.module.scss';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DialogLayout} from '../../simple/generic/dialog-layout/dialog-layout.component';
import classNames from 'classnames';
import {SimpleSearch} from '../../simple/custom-inputs/simple-search/simple-search.component';
import {Checkbox} from '../inputs/checkbox/checkbox.component';
import {Button} from '../../simple/controls/button/button.component';
import {debounce, isArray} from 'lodash';
import {APICallInputOptions, EnumInputOption, SelectedOption} from '../inputs.types';
import {extractValue, useRemoteSource} from '../../../hooks/use-remote-source';
import {CircularProgress} from '@material-ui/core';

interface OwnProps {
  className?: string;
  value?: SelectedOption;
  onSubmit?: (value: SelectedOption) => void;
  label?: string;
  multi?: boolean;
  options?: APICallInputOptions;
  onOptionsChange?: (options: EnumInputOption[]) => void;
  selectedValues?: SelectedOption[];
  hideSelectedOption?: boolean;
  mode?: 'onSubmit' | 'onChange';
}

type AllProps = OwnProps;

const fixValue = v => (isArray(v) ? v : v ? [v] : []);

export const APICallDialog: React.FC<AllProps> = (props: AllProps) => {
  const {
    onSubmit: onSubmit_,
    value: valueFromProps,
    multi,
    mode,
    className,
    options,
    onOptionsChange,
    selectedValues,
    hideSelectedOption = false,
  } = props;
  const [value, setValue] = useState(fixValue(valueFromProps));
  const [searchValue, setSearchValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [enumOptions, setEnumOptions] = useState<EnumInputOption[]>([]);
  const selectionKey = useMemo(() => options.selectionKey || 'id', [options]);
  const exclusionKey = useMemo(() => options?.exclusionKey || 'exclude_id', [options]);
  // Search API callback
  const {exec: searchOptions, isLoading} = useRemoteSource({
    type: 'source-list',
    networkRequest: options.networkRequest,
    transformer: item => ({
      value: extractValue(item, options.valueAttributePath),
      label: extractValue(item, options.labelAttributePath),
    }),
    requestPayload: options.requestPayload,
  });
  const setExcludeIdsFilterOnRequestPayload = useCallback(
    requestPayload => {
      if (!hideSelectedOption) {
        return requestPayload;
      }

      return {
        ...requestPayload,
        [exclusionKey]: isArray(selectedValues)
          ? selectedValues.map(Number)
          : [Number(selectedValues)],
      };
    },
    [selectedValues, exclusionKey, hideSelectedOption]
  );

  // Debounced function to search API for query
  // Being used in the case of multi
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAndSetOptions = useCallback(
    debounce(
      async (searchValue: string, requestPayload: any = {}) => {
        const results = await searchOptions(
          searchValue,
          setExcludeIdsFilterOnRequestPayload(requestPayload)
        );
        setEnumOptions(results.filter(o => value.indexOf(o.value) < 0));
        onOptionsChange && onOptionsChange(results);
      },
      250,
      {trailing: true}
    ),
    [value, setExcludeIdsFilterOnRequestPayload]
  );
  // Being used in the case of a single selection
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAndSetSelectedOptions = useCallback(
    debounce(
      async (ids: number[]) => {
        const results = await searchOptions('', {[selectionKey]: ids});
        setSelectedOptions(results);
      },
      250,
      {trailing: true}
    ),
    [searchOptions, options]
  );
  const stringValue = useMemo(() => fixValue(valueFromProps).join(','), [valueFromProps]);
  // Fix value to array when changed
  useEffect(() => {
    const newValue = fixValue(valueFromProps);
    setValue(newValue);
    stringValue && searchAndSetSelectedOptions(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringValue]);
  // Re-search API when search value changed
  useEffect(() => {
    searchAndSetOptions(searchValue); // <-- debounced callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onOptionClicked = option => {
    const optionValue = option.value;
    const set = new Set(value);

    if (!multi) {
      onSubmit_(optionValue);
    } else {
      // Multi selection mode
      if (set.has(optionValue)) {
        set.delete(optionValue);
        const newValue = Array.from(set);
        setValue(newValue);
        setSelectedOptions(selectedOptions => selectedOptions.filter(s => s.value !== optionValue));
        setEnumOptions(enumOptions => [option, ...enumOptions]);
        mode === 'onChange' && onSubmit(newValue);
      } else {
        const newValue = [...value, optionValue];
        setValue(newValue);
        setSelectedOptions([option, ...selectedOptions]);
        setEnumOptions(enumOptions => enumOptions.filter(o => o.value !== optionValue));
        mode === 'onChange' && onSubmit(newValue);
      }
    }
  };

  const onSubmit = (newValue = undefined) => {
    const submitValue = newValue || value;
    onSubmit_(multi ? submitValue : submitValue[0]);
  };

  return (
    <DialogLayout className={classNames(classes.EnumFilter, className)}>
      <div className={classes.SearchWrapper}>
        <SimpleSearch
          className={classes.Search}
          value={searchValue}
          onChange={v => setSearchValue(v)}
          placeholder={'Search...'}
        />
      </div>
      <div className={classNames(classes.Form, classes.Scrollable)}>
        {isLoading && (
          <div className={classes.Loading}>
            <CircularProgress
              variant={'indeterminate'}
              disableShrink
              size={30}
              thickness={4}
              color={'secondary'}
            />
          </div>
        )}
        {selectedOptions.map(o => (
          <div key={o.value} onClick={e => onOptionClicked(o)} className={classes.Option}>
            {multi && <Checkbox multi={true} checked={true} className={classes.Checkbox} />}
            <div title={o.label} className={classes.Label}>
              {o.label}
            </div>
          </div>
        ))}
        {enumOptions.map(option => (
          <div key={option.value} onClick={e => onOptionClicked(option)} className={classes.Option}>
            {multi && <Checkbox multi={true} checked={false} className={classes.Checkbox} />}
            <div className={classes.Label}>{option.label}</div>
          </div>
        ))}
        {enumOptions.length === 0 && selectedOptions.length === 0 && !isLoading && (
          <div className={classes.NoResults}>No results</div>
        )}
      </div>
      {onSubmit_ && multi && mode === 'onSubmit' && (
        <Button className={classes.ApplyBtn} onClick={() => onSubmit()}>
          Apply
        </Button>
      )}
    </DialogLayout>
  );
};

APICallDialog.defaultProps = {
  mode: 'onSubmit',
};
