import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './journey-drivers-scope-events-table.module.scss';
import {
  FlexibleTable,
  FlexibleTableClasses,
} from '../../../../../../shared/general/flexible-table/flexible-table.component';
import {UpliftDisplay} from '../../../../../../shared/display-columns/uplift-display.component';
import {VersusDisplay} from '../../../../../../shared/display-columns/versus-display.component';
import {getNextSort, sortData, Sorting, number2k} from 'front-core';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../../hooks/use-document-translation.hook';
import {JourneyDriversScopeEvent} from '../../../../../../../types/figures.types';
import {TooltipIfOverflow} from '../../../../../../../../../simple/generic/tooltips/tooltips.component';
import {ExtendedJourneyDriversScopeEvent} from '../../journey-drivers-scope.consts';

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.JOURNEY_DRIVERS_SCOPE_FIGURE.TABLE;

interface OwnProps {
  events: JourneyDriversScopeEvent[];
  isPercentageValue: boolean;
  entity: string;
  totalEntities: number;
}

type AllProps = OwnProps;

const DEFAULT_SORT: Sorting = {
  orderBy: 'adoption',
  order: 'desc',
};

export const JourneyDriversScopeEventsTable: React.FC<AllProps> = (props: AllProps) => {
  const {events, entity, totalEntities, isPercentageValue} = props;
  const {t} = useDocumentTranslation();
  const [sorting, setSorting] = useState<Sorting>(DEFAULT_SORT);
  const tableData = useMemo(() => sortData(events, sorting), [events, sorting]);

  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: t(TRANS_KEYS_PREFIX.HEADERS.EVENT_NAME.LABEL),
        helperText: t(TRANS_KEYS_PREFIX.HEADERS.EVENT_NAME.HELPER_TEXT),
        weight: 1,
        sortable: true,
        render: (item: ExtendedJourneyDriversScopeEvent) => (
          <TooltipIfOverflow title={item.name}>
            <div className={classes.Name}>{item.name}</div>
          </TooltipIfOverflow>
        ),
      },
      {
        key: 'adoption',
        title: t(TRANS_KEYS_PREFIX.HEADERS.ADOPTION.LABEL),
        helperText: t(TRANS_KEYS_PREFIX.HEADERS.ADOPTION.HELPER_TEXT),
        weight: 1,
        sortable: true,
        render: (event: ExtendedJourneyDriversScopeEvent) => (
          <div className={classes.Adoption}>
            {`${number2k(event.adoption)}%`}
            <span className={classNames(classes.Secondary, FlexibleTableClasses.ShowOnHover)}>
              ({event.adoptionAbs} {entity})
            </span>
          </div>
        ),
      },
      {
        key: 'goalInAdopters',
        title: t(TRANS_KEYS_PREFIX.HEADERS.GOAL_IN_ADOPTERS.LABEL),
        subTitle: `vs ${t(TRANS_KEYS_PREFIX.HEADERS.GOAL_IN_NON_ADOPTERS.LABEL)}`,
        weight: 1,
        sortable: true,
        helperText: t(TRANS_KEYS_PREFIX.HEADERS.GOAL_IN_ADOPTERS.HELPER_TEXT),
        render: (s: ExtendedJourneyDriversScopeEvent) => (
          <VersusDisplay
            value={s.goalInAdopters}
            vsValue={s.goalInNonAdopters}
            isPercentageValue={isPercentageValue}
            vsLabel={t(TRANS_KEYS_PREFIX.HEADERS.GOAL_IN_NON_ADOPTERS.LABEL)}
          />
        ),
      },
      {
        key: 'uplift',
        title: t(TRANS_KEYS_PREFIX.HEADERS.UPLIFT.LABEL),
        weight: 0.8,
        sortable: true,
        helperText: t(TRANS_KEYS_PREFIX.HEADERS.UPLIFT.HELPER_TEXT),
        render: (s: ExtendedJourneyDriversScopeEvent) => (
          <div className={classes.GeneralColumn}>
            <UpliftDisplay uplift={s.uplift} higherIsBetter={true} />
          </div>
        ),
      },
    ],
    [t, totalEntities]
  );

  const onSort = useCallback(
    (columnKey: string) => setSorting(getNextSort(columnKey, sorting)),
    [sorting, setSorting]
  );

  return (
    <FlexibleTable
      className={classes.Table}
      columns={columns}
      data={tableData}
      sorting={sorting}
      onSort={onSort}
      dynamicHeight
    />
  );
};

JourneyDriversScopeEventsTable.defaultProps = {};
