import * as React from 'react';
import classes from '../smart-table.module.scss';
import {useCallback, useContext, useMemo, useState} from 'react';
import {SmartTableContext} from '../smart-table.context';
import {TableRow} from './table-row.component';
import classNames from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {isEmpty} from 'lodash';
import {TablePartitionOptions, DocumentCommand} from '../../../../types';
import {ColorUtil} from '../../../../document-viewer.utils';
import {Link} from '../../../../../../simple/navigation/link/link.component';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';

interface OwnProps {
  name: string;
  items: any[];
  commands?: Array<DocumentCommand<any>>;
  options?: TablePartitionOptions;
}

type AllProps = OwnProps;

export const TablePartition: React.FC<AllProps> = (props: AllProps) => {
  const {name, items, commands, options} = props;
  const {columns, options: tableOptions, filters, sorting} = useContext(SmartTableContext);
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {partitionShowDataKey} = tableOptions;

  const [isOpen, setIsOpen] = useState(!options.startClosed);
  const [showMore, setShowMore] = useState(false);

  const titleColor = useMemo(() => ColorUtil.getColor(options.color), [options]);
  const disabled = useMemo(() => items.length === 0, [items]);
  const hasFiltersOrSorting = useMemo(
    () => !isEmpty(filters) || !isEmpty(sorting),
    [filters, sorting]
  );
  const partitionShowedData = useMemo(
    () => (partitionShowDataKey ? items.filter(i => i[partitionShowDataKey]) : items),
    [items, partitionShowDataKey]
  );
  const shouldDisplayShowMore = useMemo(
    () => partitionShowedData.length < items.length,
    [partitionShowedData, items]
  );

  const displayedItems = useMemo(() => {
    if (hasFiltersOrSorting) {
      return items;
    }
    if (!partitionShowDataKey) {
      return items;
    }
    if (showMore) {
      return items.sort(
        (a, b) =>
          Number(b[partitionShowDataKey] || false) - Number(a[partitionShowDataKey] || false)
      );
    }
    return partitionShowedData;
  }, [items, partitionShowDataKey, hasFiltersOrSorting, showMore, partitionShowedData]);
  const hidePartition = useMemo(
    () => hasFiltersOrSorting && displayedItems.length === 0,
    [displayedItems, hasFiltersOrSorting]
  );
  const onCommandClicked = useCallback((c: DocumentCommand<any>) => emitEvent(c), [emitEvent]);

  if (hidePartition) {
    return null;
  }

  return (
    <React.Fragment>
      <tr>
        <td
          className={classNames(classes.TablePartition, disabled && classes.Disabled)}
          colSpan={columns.length + 1}
        >
          <div className={classes.PartitionHeader}>
            <div
              style={{color: titleColor}}
              onClick={() => setIsOpen(!isOpen)}
              className={classes.Title}
            >
              {name} ({items.length})
            </div>
            <div className={classNames(classes.Arrow, isOpen && classes.Open)}>
              <ArrowDropDownIcon className={classes.Icon} />
            </div>
            {commands.length > 0 && (
              <div className={classes.Commands}>
                {commands.map((c, idx) => (
                  <Link onClick={() => onCommandClicked(c)}>{c.payload.text}</Link>
                ))}
              </div>
            )}
          </div>
        </td>
      </tr>
      {isOpen &&
        displayedItems.map((d, idx) => <TableRow key={idx} columns={columns} data={d} indent />)}
      {isOpen && shouldDisplayShowMore && !hasFiltersOrSorting && (
        <tr>
          <td className={classes.TablePartitionShowMore} colSpan={columns.length + 1}>
            <div className={classes.ShowMore}>
              <span onClick={() => setShowMore(!showMore)} className={classes.ShowMoreButton}>
                {showMore ? 'Show less' : `Show ${items.length - partitionShowedData.length} more`}
              </span>
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

TablePartition.defaultProps = {
  options: {},
  commands: [],
};
