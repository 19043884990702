import {CompositeSegments} from '../../../types';
import {ExtendedCompositeSegments} from './composite-segments-viewer.component';
import {safeDivision} from 'front-core';

export const extendCompositeSegments = (
  composites: CompositeSegments[],
  totalEntities: number
): ExtendedCompositeSegments[] => {
  return composites.map(c => ({
    ...c,
    key: `${c.segments.map(s => `${s.name}_${s.signalId}`).join('_')}`,
    share: safeDivision(c.count, totalEntities),
    uplift: safeDivision(c.goalInComposite - c.goalInNonComposite, c.goalInNonComposite),
    segments: c.segments.map(s => ({
      ...s,
      share: safeDivision(s.count, totalEntities),
      uplift: safeDivision(s.goalInSegment - s.goalInNonSegment, s.goalInNonSegment),
    })),
  }));
};
