import * as React from 'react';
import classNames from 'classnames';
import classes from './funnel-chart.module.scss';
import {
  BaseChartOptions,
  ChartWithLabelsOptions,
  FunnelDataset,
  LabelBasedChart,
} from '../../charts/chart-data.types';
import {useCallback, useMemo, useState} from 'react';
import {
  extendFunnelDataset,
  fixFunnelDataset,
  generateClipPath,
  generateStepTitle,
  getModeSettings,
} from './funnel-chart.utils';
import {max, range} from 'lodash';
import Rainbow from 'rainbowvis.js';
import {colorAlphaTransformer} from '../../../../utils/colors';
import {
  ArrowRightIcon,
  ArrowUpRightRegularIcon,
} from '../../../simple/controls/icons/icons.component';
import {FunnelChartTooltip} from './components/funnel-chart-tooltip/funnel-chart-tooltip.component';
import {HtmlTooltip, TooltipIfOverflow} from '../../../simple/generic/tooltips/tooltips.component';
import {ErrorFallback} from '../../../simple/errors/generic.error';
import {ErrorBoundary} from 'react-error-boundary';
import {useDisplayedDatasets} from '../hooks/use-displayed-datasets.hook';
import {useChartLegend} from '../hooks/use-chart-legend.hook';
import {useTranslation} from '../../../../core/translations/use-translation';
import TransKeys from 'translations';
import {FunnelChip} from './components/funnel-chip/funnel-chip.component';
import {Select} from '../../../forms/inputs/select/select.component';

export enum FunnelChartMode {
  CONVERSION_FROM_INITIAL_STEP = 'conversion_from_initial_step',
  CONVERSION_FROM_PREVIOUS_STEP = 'conversion_from_previous_step',
  TOTALS = 'totals',
  TRENDS = 'trends',
}

export interface FunnelChartOptions extends BaseChartOptions, ChartWithLabelsOptions {
  mode?: FunnelChartMode;
  sortDatasets?: boolean;
  stepMaxHeight?: number;
  emptyStateText?: string;
  // set the step label as the primary label
  singleTitle?: boolean;
  showChangeMode?: boolean;
  variant?: 'default' | 'ghost';
}

interface AllProps extends LabelBasedChart<FunnelDataset, FunnelChartOptions> {
  className?: string;
  // external
  onExplore?: (startStepIndex: number) => void;
  mode?: FunnelChartMode;
  onChangeMode?: (mode: FunnelChartMode) => void;
}

export interface ExtendedFunnelDataset extends FunnelDataset {
  conversionFromPrev: number[];
  conversionFromStart: number[];
  color?: string;
}

interface HoveredItem {
  funnelIndex: number;
  stepIndex: number;
}

const MAX_STEP_HEIGHT = 150;

export const FunnelChartV2Controller: React.FC<AllProps> = (props: AllProps) => {
  const {
    labels,
    className,
    datasets: datasetsFromProps,
    options,
    onExplore,
    mode: modeFromProps,
    onChangeMode: onChangeModeFromProps,
  } = props;
  const {t} = useTranslation();
  const [datasets, maxDatasetLength] = useMemo(
    () => fixFunnelDataset(datasetsFromProps, options.sortDatasets),
    [datasetsFromProps, options.sortDatasets]
  );
  const allFunnels: ExtendedFunnelDataset[] = useMemo(
    () => datasets.map((ds, idx) => extendFunnelDataset(ds, idx)),
    [datasets]
  );
  const stepMaxHeight = options.stepMaxHeight || MAX_STEP_HEIGHT;
  const {
    displayedDatasets: funnels,
    setDisplayedDatasetIds,
    displayedDatasetIds,
  } = useDisplayedDatasets<ExtendedFunnelDataset>({
    datasets: allFunnels,
    initialDisplayedDatasetIds: props.displayedDatasetIds,
  });
  const {defaultMode, modeOptions} = useMemo(
    () => getModeSettings(datasetsFromProps),
    [datasetsFromProps]
  );
  const [modeFromState, onChangeModeFromState] = useState<FunnelChartMode>(
    options.mode || defaultMode
  );
  const mode = modeFromProps || modeFromState;
  const setMode = useCallback(
    (m: FunnelChartMode) => {
      onChangeModeFromState(m);
      onChangeModeFromProps?.(m);
    },
    [onChangeModeFromProps, onChangeModeFromState]
  );
  const [hoveredStep, setHoveredStep] = useState<HoveredItem>(null);
  const {onLegendClick, datasetLabels} = useChartLegend({
    datasets: allFunnels,
    displayedDatasetIds,
    setDisplayedDatasetIds,
  });
  const selectModeOptions = useMemo(
    () =>
      modeOptions.map(m => ({
        label: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.MODES[m.toUpperCase()]),
        value: m,
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.MODES[`${m.toUpperCase()}_HELPER`]
        ),
      })),
    [modeOptions]
  );
  const headers = useMemo(() => {
    if (options.singleTitle) {
      return labels.map((l, idx) => ({
        title: l,
        subTitle: undefined,
      }));
    }
    return labels.map((l, idx) => ({
      title: generateStepTitle(idx, labels.length),
      subTitle: l,
    }));
  }, [labels, options.singleTitle]);
  const maxStartFunnel = useMemo(() => max(funnels.map(f => f.data.fromPrevious[0])), [funnels]);
  const funnelGradients = useMemo(
    () =>
      range(0, funnels.length).map(i => {
        const r = new Rainbow();
        r.setSpectrum(
          colorAlphaTransformer(funnels[i].color, 0.4, true, undefined, true),
          funnels[i].color,
          funnels[i].color
        );
        return r;
      }),
    [funnels]
  );
  const ticksRange = useMemo(() => range(0, maxDatasetLength + 1), [maxDatasetLength]);
  const {stepIndex: hoveredStepIndex, funnelIndex: hoveredFunnelIndex} = hoveredStep || {};

  const renderTickContent = (funnelIndex: number, stepIndex: number) => {
    const renderItems = [];
    const funnel = funnels[funnelIndex];
    const data = funnel.data.fromPrevious;

    if (stepIndex > 0 && stepIndex < maxDatasetLength) {
      const clipPath = generateClipPath(data[0], data[stepIndex - 1], data[stepIndex]);
      const gradient = funnelGradients[funnelIndex];
      const backgroundColor = funnel.color;
      const backgroundImage = `linear-gradient(90deg, #${gradient.colorAt(
        ((stepIndex - 1) / (maxDatasetLength - 1)) * 100
      )}, #${gradient.colorAt((stepIndex / (maxDatasetLength - 1)) * 100)})`;

      renderItems.push(
        <div className={classes.AreaWrapper}>
          <div
            className={classes.Area}
            style={{
              clipPath,
              backgroundImage: hoveredStepIndex === stepIndex ? undefined : backgroundImage,
              backgroundColor: hoveredStepIndex === stepIndex ? backgroundColor : undefined,
            }}
          />
        </div>
      );
    }
    if (stepIndex < maxDatasetLength) {
      const tooltipData = (
        <div className={classes.ChipTooltip}>
          {stepIndex === 0 && <span className={classes.LabelText}>{funnel.label}</span>}
          <span className={classes.ChipText}>{data[stepIndex].toLocaleString()}</span>
        </div>
      );
      renderItems.push(
        <FunnelChip
          className={classNames(
            classes.Chip,
            stepIndex === 0 && datasets.length > 1 && classes.First
          )}
          label={stepIndex === 0 && datasets.length > 1 ? funnel.label : ''}
          mode={stepIndex === 0 ? FunnelChartMode.TOTALS : mode}
          total={data[stepIndex]}
          conversionFromStart={funnel.conversionFromStart[stepIndex - 1]}
          conversionFromPrev={funnel.conversionFromPrev[stepIndex - 1]}
          trend={funnel.data.trends?.[stepIndex - 1]}
          helperText={stepIndex === 0 ? tooltipData : ''}
        />
      );
    }
    let tooltipData: any = '';
    if (hoveredStepIndex === stepIndex && funnelIndex === hoveredFunnelIndex) {
      tooltipData = (
        <FunnelChartTooltip
          startStep={labels[stepIndex - 1]}
          endStep={labels[stepIndex]}
          total={data[stepIndex]}
          conversionFromStart={funnel.conversionFromStart[stepIndex - 1]}
          conversionFromPrev={
            stepIndex === 1 ? undefined : funnel.conversionFromPrev[stepIndex - 1]
          }
          trend={funnel.data.trends?.[stepIndex - 1]}
        />
      );
    }

    const isActualStep = stepIndex > 0 && stepIndex < maxDatasetLength;
    return (
      <HtmlTooltip
        key={`${funnelIndex}_${stepIndex}`}
        title={tooltipData}
        placement={'right'}
        interactive={false}
      >
        <div
          className={classNames(
            classes.Tick,
            hoveredStepIndex === stepIndex && classes.Selected,
            hoveredStepIndex - 1 === stepIndex && classes.Selected,
            hoveredStepIndex !== undefined && hoveredStepIndex !== stepIndex && classes.Disabled,
            hoveredFunnelIndex === funnelIndex && classes.SelectedFunnel,
            isActualStep && onExplore && classes.Clickable
          )}
          onClick={isActualStep && onExplore ? () => onExplore(stepIndex - 1) : undefined}
          onMouseEnter={isActualStep ? () => setHoveredStep({stepIndex, funnelIndex}) : undefined}
          onMouseLeave={() => setHoveredStep(null)}
        >
          {stepIndex > 0 && stepIndex < maxDatasetLength && onExplore && (
            <div className={classes.Explore}>
              {t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART.ACTIONS.CLICK_TO_EXPLORE)}
              <ArrowUpRightRegularIcon className={classes.Icon} />
            </div>
          )}
          {renderItems}
        </div>
      </HtmlTooltip>
    );
  };

  return (
    <div className={classNames(classes.FunnelChartWrapper, className)}>
      {options.showChangeMode !== false && (
        <div className={classes.ModeOptions}>
          <Select
            options={{options: selectModeOptions}}
            value={mode}
            prefix={t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.SELECT_MODE_PREFIX)}
            onChange={v => setMode(v as any)}
            dropdownButtonClassName={classes.ModeSelection}
            capitalize={false}
            clearable={false}
            searchable={false}
            sortValues={false}
          />
        </div>
      )}
      <div
        className={classNames(classes.FunnelChart, options.variant === 'ghost' && classes.Ghost)}
      >
        <div
          className={classNames(
            classes.HeaderWrapper,
            classes.Sticky,
            options.singleTitle && classes.SingleTitle
          )}
        >
          <div className={classes.Header}>
            {headers.map((data, headerIdx) => (
              <div
                key={headerIdx}
                className={classNames(
                  classes.StepHeader,
                  (hoveredStepIndex === headerIdx || hoveredStepIndex - 1 === headerIdx) &&
                    classes.Active
                )}
              >
                <TooltipIfOverflow title={data.title}>
                  <div className={classes.Title}>{data.title}</div>
                </TooltipIfOverflow>
                {data.subTitle && (
                  <TooltipIfOverflow title={data.subTitle}>
                    <div className={classes.SubTitle}>{data.subTitle}</div>
                  </TooltipIfOverflow>
                )}
                <ArrowRightIcon
                  className={classNames(
                    classes.ArrowRight,
                    hoveredStepIndex - 1 === headerIdx && classes.Show
                  )}
                />
              </div>
            ))}
          </div>
          {/*<FunnelChartInfoLine funnels={funnels} mode={mode} activeStepIndex={hoveredStepIndex}/>*/}
        </div>
        <div className={classes.Chart}>
          {funnels.map((funnel, funnelIndex) => (
            <div
              key={funnel.id}
              className={classes.Funnel}
              style={{
                height: `${Math.round(
                  (funnel.data.fromPrevious[0] / maxStartFunnel) * stepMaxHeight
                )}px`,
              }}
            >
              {ticksRange.map((tick, stepIndex) => renderTickContent(funnelIndex, stepIndex))}
            </div>
          ))}
        </div>
        {funnels.length === 0 && (
          <div className={classes.EmptyState}>
            {options.emptyStateText || t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.EMPTY_STATE)}
          </div>
        )}
        {datasets.length > 1 && (
          <div className={classes.Footer}>
            <div className={classes.Legend}>
              <div className={classes.LegendLabel}>{options.legendsLabel || 'Legend'}</div>
              {datasetLabels.map(dl => (
                <div
                  onClick={e => onLegendClick(e, dl.datasetId)}
                  key={dl.datasetId}
                  className={classNames(classes.LegendItem, dl.isActive && classes.Active)}
                >
                  <div className={classes.Color} style={{backgroundColor: dl.color}} />
                  {dl.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const FunnelChart: React.FC<AllProps> = (props: AllProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FunnelChartV2Controller {...props} />
    </ErrorBoundary>
  );
};

FunnelChart.defaultProps = {};
