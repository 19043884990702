import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {get} from 'lodash';
import {
  Condition,
  ConditionOperator,
  SqlElementModel,
  SqlElementType,
} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {QueryBuilderContext} from '../query-builder.context';
import {useQueryElement} from '../query-builder.hooks';
import {useRemoteSource} from '../../../../hooks/use-remote-source';
import {Select} from '../../../forms/inputs/select/select.component';
import {QueryBlock} from '../components/query-block/query-block.component';
import {
  AddIcon,
  concatPath,
  InlineLabel,
  InlineToken,
  MultiSelect,
  parentPath,
  QueryAction,
  QueryBuilderFactory,
  QueryElementBuilder,
  UsersIcon,
} from '../../../../index';
import {ConditionOperatorName, PAYLOAD_SPECIAL_KEY} from '../query-builder.config';
import {queryBuilderClasses} from '../query-builder.component';
import {withViewMode} from '../core/with-view-mode.hoc';
import {exists} from 'front-core';

interface OwnProps {
  data: Condition;
  inline?: boolean;
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

const SEGMENT_ID_KEY = 'segment_id';

const ALLOWED_OPS = [ConditionOperator.IN, ConditionOperator.NIN, ConditionOperator.NIN_OR_NULL];

export const SegmentConditionBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled, inline, box, wrapWith} = props;
  const {data: allQuery, config} = useContext(QueryBuilderContext);
  const selectOptions = config.modelConfig?.[SqlElementModel.SEGMENT];
  const {exec: getSource} = useRemoteSource({
    type: 'source',
    networkRequest: selectOptions?.networkRequest,
  });
  const {localErrors, actions: actions_} = useQueryElement(props);
  const [segment, setSegment] = useState(null);
  const parent = useMemo(() => {
    const conditionCandidatePath = parentPath(path, 2);
    return conditionCandidatePath ? get(allQuery, conditionCandidatePath) : allQuery;
  }, [allQuery, path]);
  const isParentMultiCondition = useMemo(() => {
    return (
      parent?.type === SqlElementType.AND_CONDITION || parent?.type === SqlElementType.OR_CONDITION
    );
  }, [parent?.type]);
  const segmentClassesOptions = useMemo(() => {
    if (!segment || !segment.classes) {
      return [];
    }
    return segment.classes.map(c => ({label: c.name, value: c.value}));
  }, [segment]);
  const segmentIdFromData = useMemo(() => data[PAYLOAD_SPECIAL_KEY]?.[SEGMENT_ID_KEY], [data]);
  const opOptions = useMemo(
    () =>
      ALLOWED_OPS.map(k => ({
        label: ConditionOperatorName[k].toUpperCase(),
        value: k,
      })),
    []
  );
  const onSegmentClassesSelected = useCallback(
    (values: string[] | number[]) => {
      onChange(concatPath(path, 'right.value'), values);
    },
    [onChange, path]
  );
  const onOpChange = useCallback(
    (op: ConditionOperator) => {
      const newData = {...data};
      newData.op = op;
      onChange(path, newData);
    },
    [data, onChange, path]
  );
  const getAndSetSegment = useCallback(
    async id => {
      const segments: any = await getSource(null, {id});
      const segment = segments[0];
      setSegment(segment);
      return segment;
    },
    [getSource]
  );
  const onSegmentSelected: any = useCallback(
    async (id: number) => {
      const newData = {
        ...data,
      };
      let left = QueryBuilderFactory.createSignalColumn();

      if (id !== null) {
        const segment = await getAndSetSegment(id);
        left.signal_id = segment.signalId;
        newData[PAYLOAD_SPECIAL_KEY] = {[SEGMENT_ID_KEY]: id};
      } else {
        delete newData[PAYLOAD_SPECIAL_KEY];
      }

      newData.left = left;

      if (id === null || (data as any).left?.signal_id !== left.signal_id) {
        newData.right = {
          ...data.right,
          value: [],
        } as any;
      }
      onChange(path, newData);
    },
    [data, getSource, onChange, path]
  );
  const actions: QueryAction[] = useMemo(
    () => [
      {
        label: 'And Condition',
        onClick: () => wrapWith(SqlElementType.AND_CONDITION),
        icon: AddIcon,
        hidden: isParentMultiCondition,
        extended: true,
      },
      {
        label: 'Or Condition',
        onClick: () => wrapWith(SqlElementType.OR_CONDITION),
        icon: AddIcon,
        hidden: isParentMultiCondition,
        extended: true,
      },
      ...actions_,
    ],
    [actions_, isParentMultiCondition, wrapWith]
  );
  useEffect(() => {
    if (exists(segmentIdFromData) && (segment?.id !== segmentIdFromData || !segment)) {
      getAndSetSegment(segmentIdFromData);
    }
  }, [getAndSetSegment, segmentIdFromData, segment]);

  if (!selectOptions) {
    return <span>UNSUPPORTED! Missing model config for SEGMENT</span>;
  }

  return (
    <QueryBlock
      disabled={disabled}
      className={className}
      label={'Segment'}
      actions={actions}
      inline={inline}
      box={box}
    >
      <Select
        className={queryBuilderClasses.Inline}
        placeholder={'Select Segment'}
        value={segmentIdFromData}
        error={Boolean(localErrors.left)}
        onChange={onSegmentSelected}
        options={selectOptions}
        disabled={disabled}
      />
      <Select
        className={queryBuilderClasses.Inline}
        dropdownButtonClassName={queryBuilderClasses.Button}
        placeholder={'Operator'}
        value={data.op}
        error={Boolean(localErrors.op)}
        onChange={v => onOpChange(v as ConditionOperator)}
        searchable={false}
        clearable={false}
        options={{options: opOptions}}
        disabled={disabled}
      />
      {segmentClassesOptions && (
        <MultiSelect
          key={segment?.id}
          style={{maxWidth: '80%'}}
          value={(data.right as any)?.value || []}
          placeholder={'Select'}
          options={{options: segmentClassesOptions}}
          onChange={onSegmentClassesSelected}
          error={Boolean(localErrors.right)}
          disabled={disabled || !Boolean(segmentIdFromData)}
        />
      )}
    </QueryBlock>
  );
};

export const SegmentConditionViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, className} = props;
  const {config} = useContext(QueryBuilderContext);
  const selectOptions = config.modelConfig?.[SqlElementModel.SEGMENT];
  const {exec: getSource} = useRemoteSource({
    type: 'source',
    networkRequest: selectOptions.networkRequest,
  });
  const [segment, setSegment] = useState(null);
  const onSegmentSelected: any = useCallback(
    async (id: number) => {
      const segments: any = await getSource(null, {id});
      setSegment(segments[0]);
    },
    [getSource]
  );
  const segmentIdFromData = useMemo(() => data[PAYLOAD_SPECIAL_KEY]?.[SEGMENT_ID_KEY], [data]);
  useEffect(() => {
    (!segment || segmentIdFromData !== segment.id) && onSegmentSelected(segmentIdFromData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentIdFromData]);

  if (!selectOptions) {
    return <span>UNSUPPORTED! Missing model config for SEGMENT</span>;
  }

  return (
    <QueryBlock disabled={true} className={className} inline={true} box={false} fitContent>
      <InlineToken>Segment</InlineToken>
      <InlineLabel icon={UsersIcon} label={segment?.name} />
      {data.op === ConditionOperator.IN && <InlineToken>IN</InlineToken>}
      {data.op === ConditionOperator.NIN && <InlineToken>NOT IN</InlineToken>}
      {data.op === ConditionOperator.NIN_OR_NULL && <InlineToken>NOT IN OR NULLS</InlineToken>}
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'right')}
        data={data.right as any}
        box={false}
        inline
      />
    </QueryBlock>
  );
};

export const SegmentConditionBuilder = withViewMode(
  SegmentConditionBuilderComponent,
  SegmentConditionViewerComponent
);
