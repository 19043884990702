import React, {useCallback, useMemo, useState} from 'react';
import {
  Button,
  ContentIcon,
  FancyHeader,
  LabelWrapper,
  LiteralValueType,
  ModalLayout,
} from 'ui-components';
import {CONTENT_ID_PATH_PARAM} from '../../../../constants/app-routes';
import classes from './content-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {ActionKey} from '../../../../constants/action-key';
import {composition, withMetadata} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import {contactSupport, preventSubmitOnEnter} from '../../../../utils/general.utils';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {TabHeader} from '../../../shared/components/general/tab-header/tab-header.component';
import {NoteText} from '../../../shared/components/general/note-text/note-text.component';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {Content} from '../../../../objects/models/content.model';
import {getContentNetworkRequest} from '../../../../http/contents.network-requests';
import {createContent, updateContent} from '../../../../store/content/content.actions';
import {contentDTOValidator} from '../../../../objects/dto/content.dto';
import {values} from 'lodash';
import {SOURCE_PROP_KEY} from '../../../../constants/shared-component-prop-key';
import {FunnelTabs} from '../../../shared/components/general/funnel-tabs/funnel-tabs.component';
import {TableEntity} from '../../../../objects/models/table.model';
import i18n from '../../../../config/i18n.config';
import {EntityPicker} from '../../components/entity-picker/entity-picker.component';
import {ContentQueryBuilder} from './components/content-query-builder.component';
import {LITERAL_TYPE_TO_SIGNAL_DATA_TYPE} from '../../../../constants/literal-type-to-signal-data-type';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {DMPSignalValidationError} from '../../components/dmp-signal-validation-error/dmp-signal-validation-error.component.tsx';

interface OwnProps {
  content?: Content;
  data: Partial<Content>;
  onSubmit?: (data: Partial<Content>) => void;
  onClose?: () => void;
  cloneMode?: boolean;
  disabled?: boolean;
  [CONTENT_ID_PATH_PARAM]?: number;
  [SOURCE_PROP_KEY]?: string;
}

type AllProps = OwnProps;

const SELECTED_CONTENT_KEY = SharedSelectionKeys.CONTENT_FORM__FEATURE;

export enum ContentTabOptions {
  exposure = 'exposure',
  intent = 'intent',
  usage = 'usage',
}
export const generateContentTabs = () => ({
  [ContentTabOptions.exposure]: {
    key: ContentTabOptions.exposure,
    title: 'Exposure',
    helperText: i18n.t(TransKeys.CONTENT_FORM.TABS.EXPOSURE.TEXT),
  },
  [ContentTabOptions.intent]: {
    key: ContentTabOptions.intent,
    title: 'Intent',
    helperText: i18n.t(TransKeys.CONTENT_FORM.TABS.INTENT.TEXT),
  },
  [ContentTabOptions.usage]: {
    key: ContentTabOptions.usage,
    title: 'Usage',
    helperText: i18n.t(TransKeys.CONTENT_FORM.TABS.USAGE.TEXT),
  },
});

const generateSignalKey = key => key + 'SignalDefinition';
const generateSignalDataTypeKey = key => key + 'SignalDataType';

export const getInitialActionTabForContent = (
  content?: Content,
  signalId?: number
): ContentTabOptions => {
  if (signalId) {
    if (content?.exposureSignalId === signalId) {
      return ContentTabOptions.exposure;
    }
    if (content?.intentSignalId === signalId) {
      return ContentTabOptions.intent;
    }
    if (content?.usageSignalId === signalId) {
      return ContentTabOptions.usage;
    }
  }

  if (content?.exposureSignalDefinition) {
    return ContentTabOptions.exposure;
  }
  if (content?.intentSignalDefinition) {
    return ContentTabOptions.intent;
  }
  if (content?.usageSignalDefinition) {
    return ContentTabOptions.usage;
  }
  return ContentTabOptions.usage;
};

const DEFAULT_DATA = {
  name: '',
  shortDescription: '',
};

const ContentFormPanelComponent = (props: AllProps) => {
  const {
    content = {} as any,
    data = DEFAULT_DATA,
    onClose,
    cloneMode,
    onSubmit: onSubmitFromProps,
    disabled,
    [SOURCE_PROP_KEY]: appSource,
  } = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notify = useAmplitude();
  const {defaultTableEntity} = useProductData();
  const {demoProductValidator} = useDemoProduct();
  const [selectedTab, setSelectedTab] = useState<string>(getInitialActionTabForContent(content));
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_CONTENT,
      ActionKey.UPDATE_CONTENT,
      ActionKey.DELETE_CONTENT
    )(state)
  );
  const user = useCurrentUser();
  const formInitialValues = useMemo(() => {
    const values = {
      entity: defaultTableEntity,
      ...data,
      ...content,
    };
    cloneMode && delete values.id;
    return values;
  }, [defaultTableEntity, cloneMode, data, content]);
  const formMethods = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: formInitialValues,
    resolver: yupResolver(demoProductValidator || contentDTOValidator.noUnknown()),
  });
  const {
    handleSubmit,
    formState: {errors, isSubmitting, isValidating},
    watch,
    control,
    setValue,
  } = formMethods;
  const entity = watch('entity');
  const clearSignalDefinition = useCallback(
    () => values(ContentTabOptions).forEach(key => setValue(generateSignalKey(key), null)),
    [setValue]
  );

  const onSwitchEntity = useCallback(
    (newEntity: TableEntity) => {
      notify(AmplitudeEvent.CONTENT_FORM_FIELD_CHANGED, {
        ...(content ? {id: content.id} : {}),
        field: 'entity',
        value: newEntity,
        previousValue: entity,
      });
      setValue('entity', newEntity);
      clearSignalDefinition();
    },
    [setValue, notify, content, entity, clearSignalDefinition]
  );
  const onColumnTypeChange = useCallback(
    (literalValueType: LiteralValueType) => {
      const signalDataType = LITERAL_TYPE_TO_SIGNAL_DATA_TYPE[literalValueType] || null;
      if (!signalDataType) {
        return;
      }
      setValue(generateSignalDataTypeKey(selectedTab), signalDataType);
    },
    [setValue, selectedTab]
  );
  const editMode = Boolean(watch('id'));
  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onSuccess = () => onClose();
      const action = editMode ? updateContent(data, onSuccess) : createContent(data, onSuccess);
      dispatch(withMetadata(action, {[SOURCE_PROP_KEY]: appSource}));
      notify(AmplitudeEvent.CONTENT_SAVE_CLICKED, {
        userId: user.id,
      });
    },
    [dispatch, editMode, onSubmitFromProps, onClose, user, notify, appSource]
  );

  const handleContactSupport = useCallback(() => {
    notify(AmplitudeEvent.CONTENT_FORM_CONTACT_SUPPORT_CLICKED);
    contactSupport();
  }, [notify]);

  const contentTabs = useMemo(() => generateContentTabs(), []);

  const renderContentTab = (tabKey: string) => {
    const dataKey = generateSignalKey(tabKey);
    return (
      <div className={classes.ContentTab} key={`${tabKey}_${content?.id || ''}`}>
        <TabHeader
          size={'small'}
          title={`${t(TransKeys.GENERAL.LABELS.DEFINE)} ${contentTabs[tabKey]?.title}`}
          subTitle={contentTabs[tabKey]?.helperText}
        />
        <DMPSignalValidationError error={errors[dataKey]} marginBottom />
        <Controller
          render={({field}) => (
            <ContentQueryBuilder
              entity={entity}
              signalDefinition={field.value}
              errors={errors[dataKey]}
              onChangeSignalDefinition={field.onChange}
              onColumnTypeChange={onColumnTypeChange}
            />
          )}
          name={dataKey}
          control={control}
        />
        <NoteText
          text={t(TransKeys.SUPPORT.MISSING_EVENT_OR_PROPERTY)}
          buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
          onButtonClicked={handleContactSupport}
        />
      </div>
    );
  };
  const funnelTabs = values(contentTabs).map(tab => ({
    ...tab,
    error: errors[generateSignalKey(tab.key)]?.message,
    render: () => renderContentTab(tab.key),
  }));
  const missingDefinitionError = useMemo(() => {
    // empty string because the validation defined at root object
    if (errors['']?.type === 'atLeastOneOf') {
      return errors[''].message;
    }
  }, [errors]);
  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || disabled,
    [isLoading, isSubmitting, isValidating, disabled]
  );

  return (
    <div className={classes.CreateContentContainer}>
      <ModalLayout
        footer={
          <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        {isLoading && <GenericLoading />}
        <div className={classes.CreateContent}>
          <FancyHeader
            icon={ContentIcon}
            title={t(TransKeys.CONTENT_FORM[editMode ? 'TITLE_EDIT' : 'TITLE_CREATE'])}
            subTitle={t(TransKeys.CONTENT_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.CreateContentHeader}
          />
          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit);
              }}
            >
              <FormHiddenInputs names={['id']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={t(TransKeys.CONTENT_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.CONTENT_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      required
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      placeholder={t(TransKeys.CONTENT_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                      label={t(TransKeys.CONTENT_FORM.INPUTS.DESCRIPTION.LABEL)}
                      name={'shortDescription'}
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <EntityPicker value={entity} editMode={!editMode} onChange={onSwitchEntity} />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper
                      error={missingDefinitionError !== undefined}
                      label={
                        missingDefinitionError
                          ? `${t(TransKeys.GENERAL.LABELS.DEFINE)} - ${missingDefinitionError}`
                          : t(TransKeys.GENERAL.LABELS.DEFINE)
                      }
                      fullWidth
                    >
                      <FunnelTabs
                        tabs={funnelTabs as any}
                        selectedKey={selectedTab}
                        onTabChange={setSelectedTab}
                      />
                    </LabelWrapper>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const ContentFormPanel = composition<AllProps>(
  ContentFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    content: {
      selectedKey: SELECTED_CONTENT_KEY,
      actionKey: SELECTED_CONTENT_KEY,
      request: getContentNetworkRequest,
      mapPayloadFromProps: props => props[CONTENT_ID_PATH_PARAM],
      shouldCall: props => props[CONTENT_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default ContentFormPanel;
