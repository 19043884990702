import * as React from 'react';
import classNames from 'classnames';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import classes from './data-columns.module.scss';
import {exists} from 'front-core';
import {useMemo} from 'react';
import {DataColumnProps} from '../../smart-table.types';
import {Tooltip} from '@material-ui/core';
import {secondsToDuration} from '../../../../../document-viewer.utils';

interface OwnProps extends DataColumnProps<void> {}

type AllProps = OwnProps;

const DurationDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;

  const value = useMemo(() => data[column.key], [column.key, data]);
  const renderDuration = useMemo(() => secondsToDuration(value), [value]);
  const tooltipText = useMemo(() => secondsToDuration(value, true), [value]);

  return (
    <div className={classNames(classes.DefaultDataColumn, className)}>
      <Tooltip title={tooltipText} placement={'top'} interactive={false}>
        <div>{renderDuration}</div>
      </Tooltip>
    </div>
  );
};

const protector: Protector = (props: OwnProps) => exists(props.data[props.column.key]);
export const DurationDataColumn = withDataColumnProtector(protector)(DurationDataColumnComponent);
