import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {EnhancedGroup, EnhancedLine, EnhancedRect} from '../../../../../core/konva/components';
import {ChartWithLinesOptions, DumbbellDataset} from '../../chart-data.types';
import {range as lodashRange, max} from 'lodash';
import {DumbbellChartLayoutInjectedProps} from '../../layouts/dumbbell-chart-layout.component';
import {ScrollableView} from '../../../../../core/konva/hoc/scrollable-view.hoc';
import {DumbbellItem} from './components/dumbbell-item.component';
import {DumbbellLabel} from '../../components/dumbbell-label.component';
import {chartClasses} from '../../chart.consts';
import {colorAlphaTransformer} from '../../../../../utils/colors';

interface OwnProps extends DumbbellChartLayoutInjectedProps, ChartWithLinesOptions {
  datasets: DumbbellDataset[];
  onDatasetClick?: (ds: DumbbellDataset) => void;
}

type AllProps = OwnProps;

const ROW_HEIGHT = 40;
const STROKE_COLOR = colorAlphaTransformer('#C3C6D8', 0.4);

export const DumbbellChartDatasetContainer: React.FC<AllProps> = (props: AllProps) => {
  const {width, height, x, y, datasets, xRange, xPixelRange, xPositions, onDatasetClick, lines} =
    props;
  const context = useContext(StageContext);
  const {controller, style} = context;
  /**
   * Data computed properties
   */
  const yPositions = useMemo(
    () => lodashRange(0, datasets.length).map(i => i * ROW_HEIGHT + ROW_HEIGHT / 2),
    [datasets]
  );
  const labels = useMemo(
    () =>
      datasets.map(ds => ({
        value: ds.label,
        width: controller.measureTextWidth(ds.label, style.fontName, 12) + 16,
      })),
    [controller, datasets, style.fontName]
  );
  const calcInRange = useCallback(
    v => {
      const xLength = xPixelRange[1] - xPixelRange[0];
      const totalRange = xRange[1] - xRange[0];
      return ((v - xRange[0]) / totalRange) * xLength;
    },
    [xPixelRange, xRange]
  );
  const items = useMemo(
    () =>
      datasets.map(ds => {
        return {
          ...ds.data,
          valueX: calcInRange(ds.data.value),
          upperLimitX: calcInRange(ds.data.upper),
          lowerLimitX: calcInRange(ds.data.lower),
          color: ds.color,
          datasetLabel: ds.label,
        };
      }),
    [datasets, calcInRange]
  );
  const vLines = useMemo(() => {
    return (lines || [])
      .filter(l => l.direction === 'vertical')
      .map(hl => {
        const x = calcInRange(hl.position) + xPixelRange[0]; // + labels offset
        return {
          points: [x, 0, x, datasets.length * ROW_HEIGHT],
          title: hl.title,
          description: hl.description,
          color: hl.color,
        };
      });
  }, [lines, calcInRange, xPixelRange, datasets.length]);
  const highlights = useMemo(
    () =>
      datasets
        .map((ds, idx) => (ds.highlight ? {y: yPositions[idx]} : undefined))
        .filter(h => Boolean(h)),
    [datasets, yPositions]
  );
  /**
   * Render
   */
  return (
    <ScrollableView
      x={x}
      y={y}
      width={width}
      height={height}
      childrenHeight={ROW_HEIGHT * datasets.length}
    >
      {offsetY => (
        <EnhancedGroup offsetY={offsetY}>
          {vLines &&
            vLines.map(({points, title, description, color}, i) => (
              <EnhancedLine
                key={i}
                points={points}
                strokeWidth={3}
                stroke={color || 'rgba(58, 113, 217, 1)'}
                tooltipEnabled
                tooltipData={{
                  value: (
                    <div className={chartClasses.AnnotationTooltip}>
                      <div className={chartClasses.Title}>{title}</div>
                      <div className={chartClasses.Description}>{description}</div>
                    </div>
                  ),
                }}
              />
            ))}
          {highlights.map(({y}, i) => (
            <EnhancedRect
              key={`highlight_${i}`}
              x={10}
              y={y}
              height={28}
              width={width - 16}
              fill={colorAlphaTransformer('#FCE695', 0.7)}
              listening={false}
              centerY
            />
          ))}
          {yPositions.map((y, i) => (
            <EnhancedLine
              key={`y_line_${i}`}
              points={[0, y, width, y]}
              strokeWidth={1}
              stroke={STROKE_COLOR}
              listening={false}
            />
          ))}
          {xPositions.map((x, idx) => (
            <EnhancedLine
              key={`x_line_${idx}`}
              offsetX={-1}
              points={[
                xPixelRange[0] + x,
                0,
                xPixelRange[0] + x,
                max([ROW_HEIGHT * datasets.length, height]),
              ]}
              stroke={STROKE_COLOR}
              strokeWidth={2}
              dash={[8, 8]}
              lineJoin={'round'}
              listening={false}
            />
          ))}
          {yPositions.map((y, idx) => (
            <DumbbellLabel
              onClick={onDatasetClick ? i => onDatasetClick(datasets[idx]) : undefined}
              key={`dataset_label_${idx}`}
              x={4}
              y={y}
              width={labels[idx].width}
              height={28}
              text={labels[idx].value}
            />
          ))}
          {items.map((i, idx) => (
            <DumbbellItem
              onClick={onDatasetClick ? i => onDatasetClick(datasets[idx]) : undefined}
              key={`item_${idx}`}
              x={xPixelRange[0]}
              y={yPositions[idx]}
              width={i.upperLimitX - i.lowerLimitX}
              rectX={i.lowerLimitX}
              circleX={i.valueX}
              color={i.color}
              value={i.value}
              upper={i.upper}
              lower={i.lower}
              datasetLabel={i.datasetLabel}
            />
          ))}
        </EnhancedGroup>
      )}
    </ScrollableView>
  );
};

DumbbellChartDatasetContainer.defaultProps = {};
