import {useCallback, useMemo, useState} from 'react';
import {exists, safeDivision} from 'front-core';

export enum SimulatorItemType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export interface SimulatorItem {
  key?: string;
  value: number;
  potentialLift?: number;
  potentialLiftUpper?: number;
  potentialLiftLower?: number;
  insignificant?: boolean;
  simulationType: SimulatorItemType;
}

export interface UseSimulatorResponse<T> {
  items: Array<T & SimulatorAddedProperties>;
  changedValuesMap: ChangedValuesMap;
  onItemChangeValue: (itemKey: string, value: number) => void;
  resetItemChange: () => void;
}

interface MaxPotentials {
  maxPotential: number;
  maxPotentialUpper: number;
  maxPotentialLower: number;
}

export interface SimulatorAddedProperties extends MaxPotentials {
  newValue: number;
  change: number;
  defaultChange: number;
  maxChange: number;
  potential: number;
  potentialUpper: number;
  potentialLower: number;
}

interface UseSimulatorProps<T extends SimulatorItem> {
  items: T[];
  goalValue: number;
  maxValue?: number; // default = 1
  minValue?: number; // default = 0
}

const DEFAULT_CHANGE = 0.05;

const fixChange = (v, maxValue = 1, minValue = 0) => {
  if (!exists(v)) {
    return v;
  }
  if (v > maxValue - 0.000001) {
    return maxValue;
  }
  if (v < minValue + 0.000001) {
    return minValue;
  }
  return v;
};

interface ChangedValuesMap {
  [key: string]: number;
}

export function calculateMaxPotential(goalValue: number, item: SimulatorItem): MaxPotentials {
  let maxPotential;
  let maxPotentialUpper;
  let maxPotentialLower;

  if (item.simulationType === SimulatorItemType.POSITIVE) {
    maxPotential = goalValue + (1 - item.value) * item.potentialLift;
    maxPotentialUpper = goalValue + (1 - item.value) * item.potentialLiftUpper;
    maxPotentialLower = goalValue + (1 - item.value) * item.potentialLiftLower;
  }
  if (item.simulationType === SimulatorItemType.NEGATIVE) {
    maxPotential = goalValue + -1 * item.value * item.potentialLift;
    maxPotentialUpper = goalValue + -1 * item.value * item.potentialLiftUpper;
    maxPotentialLower = goalValue + -1 * item.value * item.potentialLiftLower;
  }

  return {
    maxPotential,
    maxPotentialUpper,
    maxPotentialLower,
  };
}

export function simulateSingleItem<T extends SimulatorItem>(
  i: T,
  change: number,
  goalValue: number,
  minAllowedChangeValue = 0,
  maxAllowedChangeValue = 1
): T & SimulatorAddedProperties {
  let defaultChange;
  const maxChange = safeDivision(1 - i.value, i.value);

  // Calculate default change
  if (i.simulationType === SimulatorItemType.POSITIVE) {
    defaultChange = DEFAULT_CHANGE;
  }
  if (i.simulationType === SimulatorItemType.NEGATIVE) {
    defaultChange = -1 * DEFAULT_CHANGE;
  }
  if (i.insignificant) {
    defaultChange = maxChange;
  }
  if (maxChange <= defaultChange) {
    defaultChange = maxChange;
  }
  // if change not exists set as defaultChange
  if (!exists(change)) {
    change = defaultChange;
  }
  // calculate values
  const adoptionDiff = i.value * change;
  const potential = goalValue + adoptionDiff * i.potentialLift;
  const potentialUpper = exists(i.potentialLiftUpper)
    ? goalValue + adoptionDiff * i.potentialLiftUpper
    : null;
  const potentialLower = exists(i.potentialLiftLower)
    ? goalValue + adoptionDiff * i.potentialLiftLower
    : null;

  return {
    ...i,
    newValue: i.value + adoptionDiff,
    change: isNaN(change) ? null : fixChange(change, maxAllowedChangeValue, minAllowedChangeValue),
    defaultChange: fixChange(defaultChange, maxAllowedChangeValue, minAllowedChangeValue),
    maxChange,
    potential: isNaN(potential) ? null : potential,
    potentialUpper,
    potentialLower,
    ...calculateMaxPotential(goalValue, i),
  };
}

export const useSimulator = function <T extends SimulatorItem>(
  props: UseSimulatorProps<T>
): UseSimulatorResponse<T> {
  const {items, goalValue, maxValue = Infinity, minValue = 0} = props;
  // changed values by item.key
  const [changedValuesMap, setChangedValuesMap] = useState<ChangedValuesMap>({});

  const calculatedItems: Array<T & SimulatorAddedProperties> = useMemo(() => {
    return items.map(i =>
      simulateSingleItem(i, changedValuesMap[i.key], goalValue, minValue, maxValue)
    );
  }, [items, goalValue, maxValue, minValue, changedValuesMap]);

  const onItemChangeValue = useCallback(
    (itemKey: string, value: number) => {
      value = fixChange(value, maxValue, minValue);
      setChangedValuesMap(changedValuesMap => {
        const newChangedValuesMap = {...changedValuesMap};
        if (value === null) {
          delete newChangedValuesMap[itemKey];
        } else {
          newChangedValuesMap[itemKey] = value;
        }
        return newChangedValuesMap;
      });
    },
    [setChangedValuesMap, maxValue, minValue]
  );
  const resetItemChange = useCallback(() => setChangedValuesMap({}), [setChangedValuesMap]);

  return {
    items: calculatedItems,
    changedValuesMap,
    onItemChangeValue,
    resetItemChange,
  };
};
