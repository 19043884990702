import * as React from 'react';
import {
  AlertIcon,
  ClockIcon,
  FlaskIcon,
  KPIIcon,
  UsersIcon,
  FeatureIcon,
  MagicIcon,
  AnalysisFileIcon,
  DatabaseIcon,
  ChartUserIcon,
  GraphIcon,
  ImageIcon,
  TableIcon,
  BarChartIcon,
  FunnelIcon,
  ContentIcon,
  FolderIcon,
  RefreshIcon,
  CalendarIcon,
  RepeatIcon,
  FaceSmileHeartsIcon,
  StarIcon,
  LoopsIcon,
} from '../../../../simple/controls/icons/icons.component';
import {DocumentIconType} from '../../types';
import {CSSProperties} from 'react';

interface OwnProps {
  icon: DocumentIconType;
  style?: CSSProperties;
  className?: string;
}

type AllProps = OwnProps;

export const DOCUMENT_ICON_MAPPING = {
  [DocumentIconType.LOOPS]: LoopsIcon,
  [DocumentIconType.ERROR]: AlertIcon,
  [DocumentIconType.CLOCK]: ClockIcon,
  [DocumentIconType.EXPERIMENT]: FlaskIcon,
  [DocumentIconType.SEGMENT]: UsersIcon,
  [DocumentIconType.KPI]: KPIIcon,
  [DocumentIconType.FEATURE]: FeatureIcon,
  [DocumentIconType.MAGIC_NUMBER]: MagicIcon,
  [DocumentIconType.ANALYSIS]: AnalysisFileIcon,
  [DocumentIconType.DATABASE]: DatabaseIcon,
  [DocumentIconType.CHART_USER]: ChartUserIcon,
  [DocumentIconType.BAR_CHART]: BarChartIcon,
  [DocumentIconType.CHART]: GraphIcon,
  [DocumentIconType.TABLE]: TableIcon,
  [DocumentIconType.IMAGE]: ImageIcon,
  [DocumentIconType.FUNNEL]: FunnelIcon,
  [DocumentIconType.CONTENT]: ContentIcon,
  [DocumentIconType.EVENT_GROUP]: FolderIcon,
  [DocumentIconType.MILESTONE]: RefreshIcon,
  [DocumentIconType.CALENDAR]: CalendarIcon,
  [DocumentIconType.REPEAT]: RepeatIcon,
  [DocumentIconType.FACE_SMILE_HEARTS]: FaceSmileHeartsIcon,
  [DocumentIconType.STAR]: StarIcon,
};

export const DocumentIcon: React.FC<AllProps> = (props: AllProps) => {
  const Icon = DOCUMENT_ICON_MAPPING[props.icon];
  if (!Icon) {
    return null;
  }
  return <Icon {...props} />;
};
