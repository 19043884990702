import * as React from 'react';
import classNames from 'classnames';
import classes from './checkbox.module.scss';
import {CheckSolidIcon} from '../../../simple/controls/icons/icons.component';
import {exists, withStopPropagation} from 'front-core';
import {useCallback} from 'react';
import {Tooltip} from '@material-ui/core';

export interface CheckboxProps {
  className?: string;
  checked?: boolean;
  error?: boolean;
  onChange?: (newValue: boolean) => void;
  multi?: boolean;
  halfChecked?: boolean;
  disabled?: boolean;
  size?: 'large' | 'default';
  label?: any;
  disabledHelperText?: string;
  border?: boolean;
}

type AllProps = CheckboxProps;

export const Checkbox: React.FC<AllProps> = (props: AllProps) => {
  const {
    className,
    checked,
    onChange,
    multi,
    error,
    border,
    size,
    label,
    halfChecked,
    disabled,
    disabledHelperText,
  } = props;

  const onClick = useCallback(
    () => onChange && !disabled && onChange(!checked),
    [onChange, disabled, checked]
  );

  const renderCheckBox = (className = undefined) => (
    <div
      onClick={withStopPropagation(onClick)}
      className={classNames(
        classes.Checkbox,
        checked && classes.Checked,
        error && classes.Error,
        multi && classes.Multi,
        size && classes[size],
        !checked && halfChecked && classes.HalfCheck,
        disabled && classes.Disabled,
        className
      )}
    >
      {checked && <CheckSolidIcon className={classes.CheckIcon} />}
      {halfChecked && !checked && <div className={classes.Square} />}
    </div>
  );

  let render = null;
  if (!exists(label)) {
    render = renderCheckBox(className);
  } else {
    render = (
      <div
        onClick={withStopPropagation(onClick)}
        className={classNames(
          classes.CheckBoxLabel,
          disabled && classes.Disabled,
          border && classes.Border,
          className
        )}
      >
        {renderCheckBox()}
        <div className={classes.Label}>{label}</div>
      </div>
    );
  }

  return (
    <Tooltip
      title={disabled && disabledHelperText ? disabledHelperText : ''}
      placement={'top'}
      interactive={false}
    >
      {render}
    </Tooltip>
  );
};
