import * as React from 'react';
import Rainbow from 'rainbowvis.js';
import classNames from 'classnames';
import classes from './retention-table.module.scss';
import {RetentionTableProps} from './retention-table.types';
import {useCallback, useMemo, useState} from 'react';
import moment from 'moment';
import {DEFAULT_DATE_FORMAT, DEFAULT_INPUT_DATE_FORMAT} from '../../../../consts/ui';
import {exists, number2k} from 'front-core';
import {SwitchActions} from '../../../simple/controls/switch-actions/switch-actions.component';
import {HashtagIcon, PercentageIcon} from '../../../simple/controls/icons/icons.component';
import {Tooltip} from '@material-ui/core';
import {HoverHelperTip} from '../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {calculateRetentionBucketsAverage} from './retention-table.utils';
import {get} from 'lodash';

interface OwnProps extends RetentionTableProps {
  className?: string;
}

type AllProps = OwnProps;

const MAX_COLOR = '#08306B';
const MIN_COLOR = '#FFFFFF';

const FIRST_PERIOD_TOOLTIP_TEXT =
  "The first column represents the starting point, which could be a day, week, or month. The retention rate is not always 100% at time 0, since it's possible for the user who performs the starting event not to perform the returning event within the same time period.";
const ALL_VALUES_PARTIAL_TOOLTIP_HELPER =
  'The average is not calculated for partial data only buckets';

const fixNumber = (v: number) => Number(v.toFixed(2));

export const RetentionTable: React.FC<AllProps> = (props: AllProps) => {
  const {title, data, options, labels, className} = props;
  const [isPercentageMode, setIsPercentageMode] = useState<boolean>(true);
  const [activeColumnIdx, setActiveColumnIdx] = useState<number>(null);
  const [activeRowIdx, setActiveRowIdx] = useState<number>(null);
  const [scrollX, setScrollX] = useState(0);
  const modeOptions = useMemo(() => {
    return [
      {
        helperText: 'Percentage values',
        icon: PercentageIcon,
        onClick: () => setIsPercentageMode(true),
        isActive: isPercentageMode,
      },
      {
        helperText: 'Absolute values',
        icon: HashtagIcon,
        onClick: () => setIsPercentageMode(false),
        isActive: !isPercentageMode,
      },
    ];
  }, [isPercentageMode, setIsPercentageMode]);

  const yLabel = useMemo((): string => options.yLabel || 'Y', [options]);
  const totalLabel = useMemo((): string => options.totalLabel || 'Total', [options]);
  const fixColorRange = useCallback(
    (value: number, total: number): number => {
      if (total === 0) {
        return 0;
      }
      const x = value / total;
      const rangeMax = options.maxRangeValue || 1;
      const rangeMin = options.minRangeValue || 0;
      return (x - rangeMin) / (rangeMax - rangeMin);
    },
    [options.minRangeValue, options.maxRangeValue]
  );
  const transformedData = useMemo(() => {
    const colorGradient = new Rainbow();
    colorGradient.setSpectrum(options.minColor || MIN_COLOR, options.maxColor || MAX_COLOR);

    return data.map((d, rowIdx) => ({
      date: moment(d.date, DEFAULT_INPUT_DATE_FORMAT).format(
        options.dateFormat || DEFAULT_DATE_FORMAT
      ),
      total: d.total,
      values: d.values.map((v, columnIdx) => {
        const colorPos = fixColorRange(v.value, d.total);
        return {
          absoluteValue: v.value,
          percentageValue: d.total === 0 ? 0 : exists(v.value) ? v.value / d.total : v.value,
          isPartial: v.isPartial,
          colorPos,
          color:
            exists(v.value) && !isNaN(colorPos)
              ? `#${colorGradient.colourAt(colorPos * 100)}`
              : undefined,
          highlight: v.highlight,
          // not in use but let's keep in here
          bold:
            (exists(activeColumnIdx) && activeColumnIdx === columnIdx) ||
            (exists(activeRowIdx) && activeRowIdx === rowIdx),
        };
      }),
    }));
  }, [data, options, activeColumnIdx, activeRowIdx, fixColorRange]);
  const bucketAverages = useMemo(
    () =>
      calculateRetentionBucketsAverage(data).map(i => (exists(i) ? `${number2k(i * 100)}%` : '-')),
    [data]
  );

  const showFirstPeriodTooltip = useMemo(() => {
    if (get(options, 'showFirstPeriodTooltip', true) === false) {
      return false;
    }
    const sumOfTotal = transformedData.reduce((acc, curr) => (acc += curr.total), 0);
    // If the sum of totals if zero we don't want to show the tooltip
    if (sumOfTotal === 0) {
      return false;
    }
    // if there's at least one percentage value in the first period
    // which isn't 1 and isn't 0, we want to show the tooltip
    return Boolean(
      transformedData.find(
        d => d.values[0].percentageValue !== 1 && d.values[0].percentageValue !== 0
      )
    );
  }, [transformedData, options]);

  const transformedLabels = useMemo(
    () =>
      labels.map((label, columnIdx) => ({
        key: `${label}_${columnIdx}`,
        columnIdx,
        tooltipTitle: columnIdx === 0 && showFirstPeriodTooltip ? FIRST_PERIOD_TOOLTIP_TEXT : '',
        label: label,
      })),
    [showFirstPeriodTooltip, labels]
  );

  const onItemHover = useCallback(
    (rowIdx: number, columnIdx: number) => {
      setActiveRowIdx(rowIdx);
      setActiveColumnIdx(columnIdx);
    },
    [setActiveColumnIdx, setActiveRowIdx]
  );
  const clearActivePosition = useCallback(() => {
    setActiveColumnIdx(null);
    setActiveRowIdx(null);
  }, [setActiveColumnIdx, setActiveRowIdx]);
  const renderItemText = (v, opposite: boolean = false): string => {
    if (!exists(v.absoluteValue)) {
      return '';
    }
    const localMode = opposite ? !isPercentageMode : isPercentageMode;
    if (localMode) {
      return `${fixNumber(v.percentageValue * 100)}%`;
    }
    return number2k(v.absoluteValue);
  };

  return (
    <div className={classNames(classes.RetentionTable, className)}>
      <div className={classes.Controls}>
        {title && <div className={classes.Title}>{title}</div>}
        <div className={classes.Actions}>
          <span className={classes.Note}>* Partial data</span>
          <SwitchActions actions={modeOptions} />
        </div>
      </div>
      <div className={classes.Content}>
        <div className={classNames(classes.Descriptors, scrollX > 0 && classes.Shadow)}>
          <div className={classes.Headers}>
            <div className={classNames(classes.HeaderTitle, classes.Descriptor)}>{yLabel}</div>
            <div className={classNames(classes.HeaderTitle, classes.Descriptor)}>{totalLabel}</div>
          </div>
          <div className={classNames(classes.Row, classes.AVGRow)} key={'avg'}>
            <div className={classes.AVG}>avg.</div>
          </div>
          {transformedData.map((td, rowIdx) => (
            <div className={classes.Row} key={rowIdx}>
              <div className={classNames(classes.Date, rowIdx === activeRowIdx && classes.Active)}>
                {td.date}
              </div>
              <div className={classNames(classes.Total, rowIdx === activeRowIdx && classes.Active)}>
                <Tooltip
                  enterDelay={1000}
                  enterNextDelay={1000}
                  title={td.total.toLocaleString()}
                  placement={'top'}
                >
                  <div>{number2k(td.total)}</div>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.Values} onScroll={(e: any) => setScrollX(e.target.scrollLeft)}>
          <div className={classes.Headers}>
            {transformedLabels.map(({label, columnIdx, key, tooltipTitle}) => (
              <div
                key={key}
                className={classNames(
                  classes.HeaderTitle,
                  classes.XTick,
                  columnIdx === activeColumnIdx && classes.Active
                )}
              >
                {label}
                {tooltipTitle && (
                  <HoverHelperTip className={classes.InfoTooltip} title={tooltipTitle} small />
                )}
              </div>
            ))}
          </div>
          <div className={classNames(classes.Row, classes.AVGRow)} key={'avg'}>
            {bucketAverages.map((v, idx) => (
              <div
                key={idx}
                className={classNames(classes.AVGItem, idx === activeRowIdx && classes.Active)}
              >
                {v}
                {v === '-' && (
                  <HoverHelperTip
                    className={classes.AVGHelper}
                    title={ALL_VALUES_PARTIAL_TOOLTIP_HELPER}
                    small
                  />
                )}
              </div>
            ))}
          </div>
          {transformedData.map((td, rowIdx) => (
            <div key={rowIdx} className={classes.Row}>
              {td.values.map((v, columnIdx) => (
                <Tooltip
                  key={columnIdx}
                  enterDelay={1000}
                  enterNextDelay={1000}
                  title={renderItemText(v, true)}
                  placement={'top'}
                >
                  <div
                    onMouseEnter={
                      v.absoluteValue !== null ? () => onItemHover(rowIdx, columnIdx) : undefined
                    }
                    onMouseLeave={() => clearActivePosition()}
                    style={{backgroundColor: v.color}}
                    className={classNames(
                      classes.Item,
                      v.colorPos > 0.35 && classes.LightColor,
                      v.isPartial && classes.Partial,
                      v.highlight && classes.Highlight,
                      v.absoluteValue === null && classes.Disabled,
                      v.bold && classes.Bold
                    )}
                  >
                    {renderItemText(v)}
                    {v.isPartial && '*'}
                  </div>
                </Tooltip>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

RetentionTable.defaultProps = {};
