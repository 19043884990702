import * as React from 'react';
import {EnhancedGroup, EnhancedCircle} from '../../../../../core/konva/components';
import {ShapeProps} from '../../../../../core/konva/hoc/enhanced-shape.hoc';
import {useMemo} from 'react';
import {KPIElementDirection} from '../../../../../types/growth-map.types';
import {colorAlphaTransformer} from '../../../../../utils/colors';

interface OwnProps {
  radius?: number;
  color: string;
  isActive?: boolean;
  direction?: KPIElementDirection;
  hideInnerCircle?: boolean;
  onDblClick?: any;
}

type AllProps = OwnProps & ShapeProps;

const STROKE_WIDTH = 4;

export const AreaCircle: React.FC<AllProps> = (props: AllProps) => {
  const {x, y, radius, color, isActive, direction, hideInnerCircle, onDblClick} = props;

  const actualRadius = useMemo(() => radius - STROKE_WIDTH, [radius]);

  return (
    <EnhancedGroup opacity={props.opacity} onDblClick={onDblClick} x={x} y={y} center>
      <EnhancedCircle
        x={0}
        y={0}
        radius={actualRadius}
        stroke={color}
        strokeWidth={STROKE_WIDTH}
        fillLinearGradientStartPoint={{x: radius, y: 0}}
        fillLinearGradientEndPoint={{x: 0, y: radius * 1.6}}
        fillLinearGradientColorStops={[
          0,
          colorAlphaTransformer(color, 0.45, true, 'black'),
          1,
          color,
        ]}
        // shadowColor={color}
        // shadowBlur={20}
        // shadowOffset={{x: 0, y: 0}}
        // shadowEnabled={isActive}
      />
      {!hideInnerCircle && (
        <>
          <EnhancedCircle
            x={0}
            y={0}
            radius={actualRadius * 0.25}
            stroke={color}
            strokeWidth={4}
            fill={color}
            scaleX={isActive ? 0.85 : 1}
            scaleY={isActive ? 0.85 : 1}
            duration={0.15}
          />
          <EnhancedCircle
            x={0}
            y={isActive ? (radius + 10) * (direction === 'to-top' ? -1 : 1) : 0}
            height={6}
            centerY
            duration={0.1}
            radius={3}
            fill={color}
            opacity={isActive ? 1 : 0}
          />
        </>
      )}
    </EnhancedGroup>
  );
};

AreaCircle.defaultProps = {
  radius: 26,
  direction: 'to-bottom',
};
