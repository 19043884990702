import * as React from 'react';
import classNames from 'classnames';
import classes from './matrix-table.module.scss';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {exists, number2k} from 'front-core';
import {HashtagIcon, PercentageIcon} from '../../../../../../simple/controls/icons/icons.component';
import {SwitchActions} from '../../../../../../simple/controls/switch-actions/switch-actions.component';

export interface PotentialRetentionDataset {
  key: string;
  name: string;
  values: number[];
  absValues?: number[];
  mainValue: number;
  mainValueAbs?: number;
  highlight?: boolean;
  showDiffFrom?: string;
  icon?: any;
}

interface OwnProps {
  title?: string;
  datasets: PotentialRetentionDataset[];
  labels: string[];
  mainValueLabel?: string;
  allowSwitchAbs?: boolean;
  switchValuesLabel?: string;
  switchAbsLabel?: string;
  className?: string;
}

type AllProps = OwnProps;

interface ScrollStatus {
  left: boolean;
  right: boolean;
}

const getMainValue = (ds, isPercentageMode, asText = false) => {
  if (!isPercentageMode && ds.mainValueAbs) {
    return asText ? number2k(ds.mainValueAbs) : ds.mainValueAbs;
  }
  return asText ? `${number2k(ds.mainValue)}%` : ds.mainValue;
};

export const MatrixTable: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    datasets: datasetsFromProps,
    labels,
    mainValueLabel,
    allowSwitchAbs,
    switchValuesLabel,
    switchAbsLabel,
    className,
  } = props;
  const [isPercentageMode, setIsPercentageMode] = useState<boolean>(true);
  const scrolledAreaRef = useRef<HTMLDivElement>(null);
  const [scrollStatus, setScrollStatus] = useState<ScrollStatus>({
    left: false,
    right: false,
  });
  const datasets: any[] = useMemo(() => {
    return datasetsFromProps.map(ds => {
      if (ds.showDiffFrom) {
        const from = datasetsFromProps.find(other => other.key === ds.showDiffFrom);
        if (!from) {
          return ds;
        }
        const diffValues = (isPercentageMode ? ds.values : ds.absValues).map((v, idx) => {
          const fromValue = (isPercentageMode ? from.values : from.absValues)[idx];
          if (!exists(v) || !exists(fromValue)) {
            return undefined;
          }
          return v / fromValue - 1;
        });
        const mainValue = getMainValue(ds, isPercentageMode);
        const mainValueFrom = getMainValue(from, isPercentageMode);
        const mainValueDiff = mainValue / mainValueFrom - 1;

        return {
          ...ds,
          diffValues: diffValues,
          mainValueDiff,
        };
      }
      return ds;
    });
  }, [datasetsFromProps, isPercentageMode]);
  const modeOptions = useMemo(() => {
    return [
      {
        helperText: switchValuesLabel,
        icon: PercentageIcon,
        onClick: () => setIsPercentageMode(true),
        isActive: isPercentageMode,
      },
      {
        helperText: switchAbsLabel,
        icon: HashtagIcon,
        onClick: () => setIsPercentageMode(false),
        isActive: !isPercentageMode,
      },
    ];
  }, [isPercentageMode, setIsPercentageMode, switchAbsLabel, switchValuesLabel]);
  const onScroll = useCallback(target => {
    const scrollLeft = target.scrollLeft;
    const {width} = target.getBoundingClientRect();
    setScrollStatus({
      left: scrollLeft > 0,
      right: target.scrollWidth - scrollLeft > width,
    });
  }, []);

  useEffect(() => {
    const listener = () => {
      onScroll(scrolledAreaRef.current);
    };
    listener();
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [scrolledAreaRef]);

  return (
    <div className={classNames(classes.MatrixTable, className)}>
      <div className={classes.Title}>
        <div className={classes.Text}>{title}</div>
        <div className={classes.Actions}>
          {allowSwitchAbs && <SwitchActions actions={modeOptions} />}
        </div>
      </div>
      <div className={classes.Table}>
        <div className={classNames(classes.Names, scrollStatus.left && classes.Shadow)}>
          <div className={classes.Headers} />
          {datasets.map(ds => (
            <div
              key={ds.key}
              className={classNames(
                classes.ContentItem,
                classes.Name,
                ds.highlight && classes.Highlight
              )}
            >
              {ds.icon && <ds.icon className={classes.Icon} />}
              {ds.name}
            </div>
          ))}
        </div>
        <div ref={scrolledAreaRef} onScroll={e => onScroll(e.target)} className={classes.Buckets}>
          <div className={classes.Headers}>
            {labels.map((l, idx) => (
              <div className={classes.Item} key={idx}>
                {l}
              </div>
            ))}
          </div>
          {datasets.map(ds => (
            <div
              className={classNames(classes.Row, ds.highlight && classes.Highlight)}
              key={ds.key}
            >
              {(isPercentageMode ? ds.values : ds.absValues).map((v, idx) => (
                <div
                  className={classNames(classes.ContentItem, !exists(v) && classes.Non)}
                  key={idx}
                >
                  {exists(v) ? `${number2k(v)}${isPercentageMode ? '%' : ''}` : '-'}
                  {exists(ds.diffValues) && exists(ds.diffValues[idx]) && (
                    <span className={classes.Diff}>+{number2k(ds.diffValues[idx] * 100)}%</span>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={classNames(classes.StableValues, scrollStatus.right && classes.Shadow)}>
          <div className={classes.Headers}>
            <div className={classes.Item}>{mainValueLabel}</div>
          </div>
          {datasets.map(ds => (
            <div
              className={classNames(classes.Row, ds.highlight && classes.Highlight)}
              key={ds.key}
            >
              <div className={classes.ContentItem}>
                {getMainValue(ds, isPercentageMode, true)}
                {exists(ds.mainValueDiff) && (
                  <span className={classes.Diff}>+{number2k(ds.mainValueDiff * 100)}%</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

MatrixTable.defaultProps = {
  mainValueLabel: 'Stable',
  switchValuesLabel: 'Percentage values',
  switchAbsLabel: 'Absolute values',
};
