import * as React from 'react';
import InputRange, {InputRangeProps, Range} from 'react-input-range';
import classes from './number-range-slider-input.module.scss';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {max, min} from 'lodash';
import classNames from 'classnames';

interface OwnProps {
  className?: string;
}

const DEFAULT_CLASS_NAMES = {
  activeTrack: 'input-range__track input-range__track--active',
  disabledInputRange: 'input-range input-range--disabled',
  inputRange: 'input-range',
  labelContainer: 'input-range__label-container',
  maxLabel: 'input-range__label input-range__label--max',
  minLabel: 'input-range__label input-range__label--min',
  slider: 'input-range__slider',
  sliderContainer: 'input-range__slider-container',
  track: 'input-range__track input-range__track--background',
  valueLabel: 'input-range__label input-range__label--value',
};

export const NumberRangeSliderInput: React.FC<InputRangeProps> = (props: InputRangeProps) => {
  const [internalValue, setInternalValue] = useState<any>(
    // @ts-ignore
    props.value || {min: props.minValue, max: props.maxValue}
  );
  const showRange = useMemo(
    () => internalValue?.min !== props.minValue || internalValue.max !== props.maxValue,
    [props.maxValue, props.minValue, internalValue]
  );
  const fixValue = useCallback(
    (value: Range) => ({
      min: max([value.min, props.minValue]),
      max: min([value.max, props.maxValue]),
    }),
    [props.maxValue, props.minValue]
  );
  useEffect(() => {
    // @ts-ignore
    setInternalValue(props.value || {min: props.minValue, max: props.maxValue});
  }, [props.maxValue, props.minValue, props.value]);

  return (
    <div className={classNames(classes.NumberRangeSlider, !showRange && classes.HideRange)}>
      <InputRange
        {...props}
        draggableTrack
        value={internalValue}
        onChange={value => setInternalValue(fixValue(value as Range))}
        // @ts-ignore
        onChangeComplete={() => props.onChange(internalValue)}
        classNames={{
          ...DEFAULT_CLASS_NAMES,
          valueLabel: classes['input-range__value_label'],
          labelContainer: classes['input-range__label'],
          inputRange: classes['input-range__track'],
          sliderContainer: classes['input-range__slider-container'],
          slider: classes['input-range__slider-slider'],
          activeTrack: classes['input-range__track_active'],
          maxLabel: classes['input-range__max-label'],
          minLabel: classes['input-range__min-label'],
        }}
      />
    </div>
  );
};

NumberRangeSliderInput.defaultProps = {};
