import {MapElementType, ProductArea} from '../../../types/growth-map.types';
import {KPIGroupViewer} from './kpi-components/kpi-group-viewer/kpi-group-viewer.component';
import {ConversionRate} from './kpi-components/conversion-rate/conversion-rate.component';

export const MapComponents: any = {
  [MapElementType.PRIMARY_GROUP]: KPIGroupViewer,
  [MapElementType.SECONDARY_GROUP]: KPIGroupViewer,
  [MapElementType.NORTH_STAR_GROUP]: KPIGroupViewer,
  [MapElementType.CONVERSION_RATE]: ConversionRate,
};

export const MAP_AREA_COLOR = {
  [ProductArea.RETENTION]: 'rgba(249, 159, 132, 1)',
  [ProductArea.ONBOARDING]: 'rgba(130, 203, 182, 1)',
  [ProductArea.ACTIVATION]: 'rgba(126, 209, 234, 1)',
  [ProductArea.ACQUISITION]: 'rgba(177, 104, 255, 1)',
  [ProductArea.ENGAGEMENT]: 'rgba(203, 130, 130, 1)',
  [ProductArea.CHURN]: '#666666',
  SECONDARY: '#ffffff',
};

export const GROUP_CIRCLE_RADIUS = 30;
export const SECONDARY_GROUP_CIRCLE_RADIUS = 20;
