import classes from './arithmetic-formula-builder.module.scss';
import {
  ArithmeticFormulaBuilderVariable,
  ArithmeticFormulaOP,
} from './arithmetic-formula-builder.types';
import {isNaN, isNumber, values} from 'lodash';
import {evaluate} from 'mathjs';
import {random} from 'lodash';

export const VARIABLE_PREFIX = '__metric_';
export const VARIABLE_SUFFIX = '_';
// data attributes
export const DATA_LOOPS_METRIC_ID = 'data-loops-metric-id';
export const DATA_LOOPS_NUMBER_VARIABLE = 'data-loops-number-variable';
export const DATA_LOOPS_OP_VARIABLE = 'data-loops-op-variable';
export const DATA_LOOPS_BRACKET_VARIABLE = 'data-loops-bracket-variable';

const ARITHMETIC_FORMULA_OP_TO_ICON = {
  [ArithmeticFormulaOP.ADD]: 'plus',
  [ArithmeticFormulaOP.SUB]: 'minus',
  [ArithmeticFormulaOP.DIV]: 'divide',
  [ArithmeticFormulaOP.MUL]: 'xmark',
};
// Generators
export const generateVariableValue = (value: string | number, dataRegex = false) =>
  `${VARIABLE_PREFIX}${dataRegex ? `(${value})` : value}${VARIABLE_SUFFIX}`;
export const generateVariableElement = (value: string | number, name: string, dataRegex = false) =>
  `<span ${DATA_LOOPS_METRIC_ID}="${dataRegex ? `(${value})` : value}" class="${classes.TextVar}" contenteditable="false">${name}</span>`;
export const generateNumberElement = (num: string | number, dataRegex = false) =>
  `<span ${DATA_LOOPS_NUMBER_VARIABLE}="${dataRegex ? `(${num})` : num}" class="${classes.NumberVar}" contenteditable="false">${num}</span>`;
export const generateOPElement = (op: string, dataRegex = false) =>
  `<span ${DATA_LOOPS_OP_VARIABLE}="${dataRegex ? `(${op})` : op}" class="${classes.OPVar}" contenteditable="false"><i class="fa-regular fa-${dataRegex ? `[\\w]+` : ARITHMETIC_FORMULA_OP_TO_ICON[op]}" aria-hidden="true"></i></span>`;
export const generateBracketElement = (bracket: string, dataRegex = false) =>
  `<span ${DATA_LOOPS_BRACKET_VARIABLE}="${dataRegex ? `(${bracket})` : bracket}" class="${classes.Bracket}" contenteditable="false">${bracket}</span>`;

// input regex
const ARITHMETIC_OPTIONS = values(ArithmeticFormulaOP).join('');
const BRACKET_OPTIONS = '()';
const VARIABLE_REGEX_STRING = generateVariableValue(`[\\d]+`, true);
const NUMBER_REGEX_STRING = `-?[\\d]+\\.?[\\d]{0,10}`;
const ARITHMETIC_OP_REGEX_STRING = `[${ARITHMETIC_OPTIONS}]`;
const BRACKET_REGEX_STRING = `[${BRACKET_OPTIONS}]`;
export const INPUT_VARIABLE_REGEX = new RegExp(
  `${VARIABLE_REGEX_STRING}|(${NUMBER_REGEX_STRING})|(${ARITHMETIC_OP_REGEX_STRING})|(${BRACKET_REGEX_STRING})`,
  'gm'
);
// output regex
const METRIC_ID_REGEX = `[\\d]+`;
const METRIC_NAME_REGEX = `[\\w\\d_ ]+`;
export const METRIC_HTML_REGEX = generateVariableElement(METRIC_ID_REGEX, METRIC_NAME_REGEX, true);
export const NUMBER_HTML_REGEX = generateNumberElement(NUMBER_REGEX_STRING, true);
export const OP_HTML_REGEX = generateOPElement(ARITHMETIC_OP_REGEX_STRING, true);
export const BRACKET_HTML_REGEX = generateBracketElement(BRACKET_REGEX_STRING, true);
export const OUTPUT_VARIABLE_REGEX = new RegExp(
  `${METRIC_HTML_REGEX}|${NUMBER_HTML_REGEX}|${OP_HTML_REGEX}|${BRACKET_HTML_REGEX}`,
  'gm'
);

// Fix input variable for unwanted characters
const fixInputValue = (value: string) => {
  const matches = value.matchAll(INPUT_VARIABLE_REGEX);
  return Array.from(matches)
    .map(m => m[0])
    .join('');
};

// Transformers
export const transformValueToRender = (
  value: string,
  variables: ArithmeticFormulaBuilderVariable[]
) => {
  const matches = value.matchAll(INPUT_VARIABLE_REGEX);
  let res = '';
  for (const match of matches) {
    const [, metricIdCandidate, numberCandidate, opCandidate, bracketCandidate] = match;
    let replacement = '';
    const variable = variables.find(v => v.value === Number(metricIdCandidate));
    if (variable && metricIdCandidate) {
      replacement = generateVariableElement(variable.value, variable.name);
    }
    if (
      numberCandidate !== undefined &&
      isNumber(Number(numberCandidate)) &&
      !isNaN(Number(numberCandidate))
    ) {
      replacement = generateNumberElement(numberCandidate);
    }
    if (opCandidate && ARITHMETIC_OPTIONS.includes(opCandidate)) {
      replacement = generateOPElement(opCandidate);
    }
    if ((bracketCandidate && bracketCandidate === ')') || bracketCandidate === '(') {
      replacement = generateBracketElement(bracketCandidate);
    }
    if (replacement) {
      res += replacement;
    }
  }
  return res;
};

export const transformRenderToValue = (
  input: string,
  variables: ArithmeticFormulaBuilderVariable[]
) => {
  let newValue = input.replace('<br>', '');
  let match;
  while ((match = OUTPUT_VARIABLE_REGEX.exec(input)) !== null) {
    const [matchedString, metricIdCandidate, numberCandidate, opCandidate, bracketCandidate] =
      match;
    const variable = variables.find(v => v.value === Number(metricIdCandidate));
    if (variable) {
      newValue = newValue.replace(matchedString, generateVariableValue(variable.value));
    } else if (
      numberCandidate !== undefined &&
      isNumber(Number(numberCandidate)) &&
      !isNaN(Number(numberCandidate))
    ) {
      newValue = newValue.replace(matchedString, numberCandidate);
    } else if (opCandidate && ARITHMETIC_OPTIONS.includes(opCandidate)) {
      newValue = newValue.replace(matchedString, opCandidate);
    } else if (bracketCandidate && BRACKET_OPTIONS.includes(bracketCandidate)) {
      newValue = newValue.replace(matchedString, bracketCandidate);
    }
  }
  return fixInputValue(newValue);
};

export const extractVariableValuesFromFormula = (formula: string): number[] => {
  const matches = formula.matchAll(new RegExp(VARIABLE_REGEX_STRING, 'gi'));
  const res = [];
  for (const match of matches) {
    const [, metricIdCandidate] = match;
    if (metricIdCandidate) {
      res.push(Number(metricIdCandidate));
    }
  }
  return res;
};

export const isValidFormula = (value: string) => {
  const variables = extractVariableValuesFromFormula(value);
  const scope = variables.reduce(
    (acc, v) => ({...acc, [generateVariableValue(v)]: random(1, 100, true)}),
    {}
  );
  try {
    evaluate(value, scope);
    return true;
  } catch (e) {
    return false;
  }
};
