import {FormProvider, useForm} from 'react-hook-form';
import {HomepageSubscriptionListPanelEmptyState} from '../homepage-subscriptions-list-panel/components/homepage-subscription-list-panel-empty-state/homepage-subscription-list-panel-empty-state.component';
import {useCallback, useContext, useEffect, useState} from 'react';
import {PanelKey} from '../../../../constants/panels';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {createEmailAndSlackFormValidator} from '../../components/homepage-summary/components/homepage-quick-subscription-form/homepage-quick-subscription-form.component';
import {OnSuccessActionHook} from 'front-core';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {HOMEPAGE_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {Button, CampaignIcon, FancyHeader, ModalLayout, useRemoteSourceStated} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {
  HomepageSubscriptionInitiatedFrom,
  HomepageSubscriptionVIA,
} from '../../../../objects/models/homepage.model';
import {createDefaultSubscription} from '../homepage-subscription-panel/homepage-subscription-panel.utils';
import {createHomepageSubscription} from '../../../../store/homepage/homepage.actions';
import {getHomepageSettingsNetworkRequest} from '../../../../http/homepage.network-requests';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import classes from './homepage-quick-subscription-panel.module.scss';
import {GenericLoading} from 'ui-components/src/components/simple/utils/generic-loading/generic-loading.component';
import {HomepageSelector} from '../../components/homepage-summary/components/homepage-selector/homepage-selector.component';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';

const createValidator = (userEmail?: string) => {
  return yup
    .object()
    .shape(createEmailAndSlackFormValidator(userEmail))
    .test(
      'at-least-one-required',
      'Must fill either email or slackChannels',
      obj => obj.emails?.length > 0 || (obj.slackChannels && obj.slackChannels.length > 0)
    );
};

type HomepageQuickSubscriptionPanelProps = {
  [HOMEPAGE_ID_PATH_PARAM]: number;
  onClose?: VoidFunction;
  onSuccess?: OnSuccessActionHook;
};

export const HomepageQuickSubscriptionPanel = (props: HomepageQuickSubscriptionPanelProps) => {
  const {[HOMEPAGE_ID_PATH_PARAM]: homepageId, onClose, onSuccess} = props;
  const {t} = useTranslation();
  const currentUser = useCurrentUser();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const dispatch = useDispatch();
  const {defaultHomepageId} = useProductData();
  const [selectedHomepageId, setSelectedHomepageId] = useState(homepageId ?? defaultHomepageId);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createValidator(currentUser?.email)),
  });

  const {
    isLoading,
    source: homepageSettings,
    exec: getHomepageSettings,
  } = useRemoteSourceStated({
    networkRequest: getHomepageSettingsNetworkRequest,
  });

  useEffect(() => {
    getHomepageSettings(selectedHomepageId);
  }, [getHomepageSettings, selectedHomepageId]);

  const {handleSubmit} = formMethods;
  const onSubmit = useCallback(
    (data: {emails?: string[]; slackChannels?: string[]}) => {
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose();
        openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_SUCCESS_PANEL, {
          subscription: res,
          homepageId: selectedHomepageId,
          action: 'created',
        });
      };

      const via = [];

      if (data.emails?.length > 0) {
        via.push(HomepageSubscriptionVIA.MAIL);
      }

      if (data.slackChannels?.length > 0) {
        via.push(HomepageSubscriptionVIA.SLACK);
      }

      const defaults = createDefaultSubscription(homepageSettings);
      dispatch(
        createHomepageSubscription(
          {
            ...defaults,
            isActive: true,
            initiatedFrom: HomepageSubscriptionInitiatedFrom.EMPTY_STATE,
            via,
            ...data,
            homepageId: homepageSettings.id,
          },
          onActionSuccess
        )
      );
    },
    [dispatch, onSuccess, onClose, openSecondaryPanel, homepageSettings, selectedHomepageId]
  );

  const onAddHomepageSubscription = useCallback(() => {
    onClose?.();
    openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_PANEL, {
      [HOMEPAGE_ID_PATH_PARAM]: selectedHomepageId,
    });
  }, [onClose, openSecondaryPanel, selectedHomepageId]);

  return (
    <div className={classes.HomepageQuickSubscriptionPanelContainer}>
      <ModalLayout
        footer={
          <div className={classes.Footer}>
            <Button onClick={onClose} variant={'outlined'}>
              {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
            </Button>
            <Button onClick={handleSubmit(onSubmit)}>{t(TransKeys.GENERAL.ACTIONS.SAVE)}</Button>
          </div>
        }
      >
        <FancyHeader
          icon={CampaignIcon}
          title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.HEADER_TITLE)}
        />
        {isLoading && <GenericLoading />}
        <div className={classes.Content}>
          <div className={classes.ActionsHeader}>
            <HomepageSelector
              onChange={setSelectedHomepageId}
              selectedHomepageId={selectedHomepageId}
            />
          </div>
          <div className={classes.ListContainer}>
            {!isLoading && (
              <FormProvider {...formMethods}>
                <HomepageSubscriptionListPanelEmptyState
                  onOpenAdvancedOptions={onAddHomepageSubscription}
                />
              </FormProvider>
            )}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};
