import * as React from 'react';
import classNames from 'classnames';
import classes from './gray-uplift-chip.module.scss';
import {number2k} from 'front-core';

interface OwnProps {
  value: number;
  className?: string;
}

type AllProps = OwnProps;

export const GrayUpliftChip: React.FC<AllProps> = (props: AllProps) => {
  const {value, className} = props;

  return (
    <div className={classNames(classes.Uplift, className)}>
      {value > 0 ? '+' : '-'}
      {number2k(Math.abs(value * 100))}%
    </div>
  );
};

GrayUpliftChip.defaultProps = {};
