import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {startEndDatesValidator, TIME_UNIT_OPTIONS} from '../shared-validators';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

import {querySchemaValidator} from '../../../../../objects/dto/query-builder.dto.ts';

export const analysis104ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      error: yup.number().required(),
      group_strategy: yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
      top_errors_number: yup.number().nullable(),
      population_filter: querySchemaValidator(false),
      events_before_number: yup.number().nullable(),
      min_general_share_event_before: yup.number().nullable(),
    })
    .required(),
  runParameters: startEndDatesValidator.required(),
});

export const analysis104ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('top_errors_number' in schema) {
    schema['top_errors_number'].order = 1000;
  }
  if ('events_before_number' in schema) {
    schema['events_before_number'].order = 1001;
  }
  if ('min_general_share_event_before' in schema) {
    schema['min_general_share_event_before'].order = 1002;
  }

  return [newValues, schema];
};
