import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './flexible-table.module.scss';
import {Tooltip} from '@material-ui/core';
import {exists, getNextSort, sortData, Sorting, toArray} from 'front-core';
import {get, isArray} from 'lodash';
import VirtualScroll from 'react-dynamic-virtual-scroll';
import {SortIndicator} from '../../../../../../simple/data-display/sort-indicator/sort-indicator.component';
import {PendoAnchors, usePendoAnchor} from '../../../../../../../hooks/use-pendo-anchor.hook';

export interface FlexibleTableColumn<T> {
  key: string | string[];
  icon?: any;
  title?: string | string[];
  subTitle?: string;
  helperText?: string | string[];
  weight?: number;
  width?: number | string;
  sortable?: boolean;
  hidden?: boolean;
  align?: 'left' | 'right';
  contentClassName?: string; // color
  render: (item: T) => any;
}

export type AcceptedFlexibleTableColumn<T> =
  | FlexibleTableColumn<T>
  | [FlexibleTableColumn<T>, FlexibleTableColumn<T>];

interface OwnProps<T> {
  columns: Array<AcceptedFlexibleTableColumn<T>>;
  data: T[];
  onRowClicked?: (item: T) => void;
  onSort?: (columnKey: string) => void;
  sorting?: Sorting;
  afterHeadersRenderer?: () => any;
  footerRenderer?: () => any;
  className?: string;
  rowClassName?: string | ((item: T) => string);
  isMutedRow?: (item: T) => boolean;
  defaultSort?: Sorting;
  primarySort?: Sorting;
  emptyState?: string | (() => any);
  dynamicHeight?: boolean;
  variant?: 'default' | 'blue';
}

export interface BaseDataItem {
  key: string;
}

export const FlexibleTableClasses = classes;

type AllProps<T> = OwnProps<T>;

const toSortedItemKeys = (sortedItems: any[]): {[key: string]: number} => {
  const res = {};
  for (const [index, item] of sortedItems.entries()) {
    res[item.key] = index;
  }
  return res;
};

export function FlexibleTable<T extends BaseDataItem>(props: AllProps<T>) {
  const {
    columns: columnsFromProps,
    data: dataFromProps = [],
    onSort: onSortFromProps,
    sorting: sortingFromProps,
    onRowClicked,
    afterHeadersRenderer,
    footerRenderer,
    defaultSort,
    primarySort,
    rowClassName,
    isMutedRow,
    variant,
    emptyState,
    dynamicHeight,
    className,
  } = props;
  const [sorting_, setSorting_] = useState<Sorting>();
  const [sortedItemKeys, setSortedItemKeys] = useState(toSortedItemKeys(dataFromProps));
  const [switchColumns, setSwitchColumns] = useState<{
    [index: number]: number;
  }>({});
  const tableRef = usePendoAnchor(PendoAnchors.FLEXIBLE_TABLE);
  const itemsRef = usePendoAnchor(PendoAnchors.FLEXIBLE_TABLE_ITEMS);
  const sorting = sortingFromProps || sorting_;

  const columns = useMemo(
    () =>
      columnsFromProps.map((c, index) => {
        if (isArray(c)) {
          return c[get(switchColumns, index, 0)];
        }
        return c;
      }),
    [columnsFromProps, switchColumns]
  );
  const data = useMemo(() => {
    if (onSortFromProps) {
      return dataFromProps;
    }
    return dataFromProps.sort((a, b) => sortedItemKeys[a.key] - sortedItemKeys[b.key]);
  }, [dataFromProps, sortedItemKeys, onSortFromProps]);
  const onSort = useCallback(
    (columnKey: string) => {
      if (onSortFromProps) {
        onSortFromProps(columnKey);
        return;
      }
      const nextSort = getNextSort(columnKey, sorting);
      const toSort = [nextSort];
      if (primarySort) {
        toSort.unshift(primarySort);
      }

      const sortedData = sortData(dataFromProps, toSort[0], toSort[1]);
      setSorting_(nextSort);
      setSortedItemKeys(toSortedItemKeys(sortedData));
    },
    [setSorting_, setSortedItemKeys, onSortFromProps, dataFromProps, primarySort, sorting]
  );
  const getColumnSize = (column: FlexibleTableColumn<any>) => {
    if (exists(column.weight)) {
      return {flex: `${column.weight} 0 0`};
    }
    if (exists(column.width)) {
      return {width: column.width};
    }
    return {flex: '0.1 0 0'};
  };
  useEffect(() => {
    // dataFromProps is not included in effect dependencies because we want this to happen only once
    if (!defaultSort) {
      return;
    }
    const toSort = [defaultSort];
    if (primarySort) {
      toSort.unshift(primarySort);
    }
    const sortedData = sortData(dataFromProps, toSort[0], toSort[1]);
    setSorting_(defaultSort);
    setSortedItemKeys(toSortedItemKeys(sortedData));
  }, [defaultSort, primarySort, setSortedItemKeys, setSorting_]);

  const renderColumnHeader = (c: FlexibleTableColumn<any>, columnIndex: number) => {
    if (c.hidden) {
      return null;
    }
    const keys = toArray(c.key);
    const titles = toArray(c.title);
    const helperText = toArray(c.helperText);
    const actualColumnDef = columnsFromProps[columnIndex];
    // switchable columns
    const isSwitchable = isArray(actualColumnDef) && actualColumnDef.length > 1;
    let otherColumn: FlexibleTableColumn<T>;
    let otherIndex: number;
    if (isSwitchable) {
      otherIndex = get(switchColumns, columnIndex, 0) === 0 ? 1 : 0;
      otherColumn = actualColumnDef[otherIndex];
    }

    return (
      <div key={keys.join('_')} className={classes.Header} style={getColumnSize(c)}>
        <div className={classes.Title}>
          {titles.map((_, idx) => (
            <React.Fragment key={keys[idx]}>
              <Tooltip
                title={
                  helperText[idx] ? (
                    <div className={classes.HeaderHelper}>
                      {helperText[idx] && <div className={classes.Content}>{helperText[idx]}</div>}
                    </div>
                  ) : (
                    ''
                  )
                }
                interactive
                placement={'top'}
              >
                <div
                  className={classNames(
                    classes.HeaderValue,
                    c.sortable && classes.Sortable,
                    sorting?.orderBy === c.key && classes.Sorted
                  )}
                  onClick={c.sortable ? () => onSort(keys[idx] as string) : undefined}
                >
                  {titles[idx]}
                  {sorting?.orderBy === c.key && (
                    <SortIndicator className={classes.SortIndicator} order={sorting.order} />
                  )}
                </div>
              </Tooltip>
              {idx < titles.length - 1 && <span className={classes.Separator}>{'>'}</span>}
            </React.Fragment>
          ))}
          {otherColumn && otherColumn.icon && (
            <Tooltip title={otherColumn.title} placement={'top'}>
              <div
                className={classes.SwitchColumn}
                onClick={() =>
                  setSwitchColumns(v => ({
                    ...v,
                    [columnIndex]: otherIndex,
                  }))
                }
              >
                <otherColumn.icon />
              </div>
            </Tooltip>
          )}
        </div>
        {c.subTitle && <div className={classes.SubTitle}>{c.subTitle}</div>}
      </div>
    );
  };
  const afterHeaders = useMemo(
    () => (afterHeadersRenderer ? afterHeadersRenderer() : null),
    [afterHeadersRenderer]
  );

  const renderEmptyState = () => {
    if (typeof emptyState === 'function') {
      return emptyState();
    }
    return <span className={classes.Text}>{emptyState || 'No results were found'}</span>;
  };

  return (
    <div
      className={classNames(
        classes.FlexibleTable,
        dynamicHeight && classes.DynamicHeight,
        classes[variant],
        className
      )}
      ref={tableRef}
    >
      <div className={classes.HeaderWrapper}>
        <div className={classes.Headers}>
          {columns.map((c, idx) => renderColumnHeader(c, idx)).filter(c => c !== null)}
        </div>
      </div>
      <div className={classes.Body}>
        {afterHeaders && <div className={classes.AfterHeader}>{afterHeaders}</div>}
        {data.length === 0 && <div className={classes.EmptyState}>{renderEmptyState()}</div>}
        <VirtualScroll
          ref={itemsRef}
          className={classes.List}
          minItemHeight={54}
          totalLength={data.length}
          renderItem={index => (
            <div
              key={data[index].key}
              onClick={onRowClicked ? () => onRowClicked(data[index]) : undefined}
              className={classNames(
                classes.Row,
                onRowClicked && classes.Clickable,
                index === 0 && classes.First,
                data.length === 1 && classes.NoHover,
                typeof rowClassName === 'string' && rowClassName,
                typeof rowClassName === 'function' && rowClassName(data[index]),
                isMutedRow && isMutedRow(data[index]) && classes.Muted
              )}
            >
              {columns
                .filter(c => !c.hidden)
                .map(c => (
                  <div
                    style={getColumnSize(c)}
                    className={classes.Cell}
                    key={isArray(c.key) ? c.key[0] : c.key}
                  >
                    <div
                      className={classNames(
                        classes.Content,
                        c.align === 'right' && classes.RightAlign,
                        c.contentClassName
                      )}
                    >
                      {c.render(data[index])}
                    </div>
                  </div>
                ))}
            </div>
          )}
        />
      </div>
      {footerRenderer && <div className={classes.Footer}>{footerRenderer()}</div>}
    </div>
  );
}

FlexibleTable.defaultProps = {
  variant: 'default',
};
