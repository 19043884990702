import {useCallback, useMemo, useState, useRef} from 'react';
import classNames from 'classnames';
import classes from './base-treatment-adoption-simulation-viewer.module.scss';
import {
  AnalysisAlgoMode,
  DocumentElementType,
  TreatmentAdoptionSimulationFigure,
  TreatmentAdoptionSimulationItem,
  TreatmentAdoptionSimulationItemType,
} from '../../../../../types';
import {groupBy, some, values} from 'lodash';
import TransKeys from 'translations';
import {AppTabs} from '../../../../../../../simple/navigation/app-tabs/app-tabs.component';
import {useDocumentTracking} from '../../../../../hooks/use-document-tracking.hook';
import {exists} from 'front-core';
import {HoverHelperTip} from '../../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {
  extendTreatmentAdoptionSimulationItems,
  fixOptionTypes,
} from './base-treatment-adoption-simulation.utils';
import {
  TreatmentAdoptionDataModeTable,
  TreatmentAdoptionSimulatorModeTable,
} from '../components/treatment-adoption-table/treatment-adoption-table.component';
import {SimulationMode, useSimulatorMode} from '../../../../../hooks/use-simulator-mode.hook';
import {SimulatorItem} from '../../../../../hooks/use-simulator.hook';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {useDocQuery} from '../../../../../hooks/use-doc-query.hook';
import {Select} from '../../../../../../../forms/inputs/select/select.component';
import {
  BarsFiltersLightIcon,
  MagnifyingGlassRegularIcon,
} from '../../../../../../../simple/controls/icons/icons.component';
import pluralize from 'pluralize';
import {PendoAnchors, usePendoAnchor} from '../../../../../../../../hooks/use-pendo-anchor.hook';
import {SwitchAlgoMode} from '../../../../shared/general/switch-treatment-adoption-mode/switch-algo-mode.component';

interface OwnProps extends TreatmentAdoptionSimulationFigure {
  className?: string;
  transKeysPrefix?: any;
  // given by TreatmentAdoptionSimulationModeSwitchWrapperViewer
  algoMode?: AnalysisAlgoMode;
}

type AllProps = OwnProps;

export interface ExtendedTreatmentAdoptionSimulationItem
  extends Omit<
      TreatmentAdoptionSimulationItem,
      'potentialLift' | 'potentialLiftUpper' | 'potentialLiftLower'
    >,
    SimulatorItem {
  actualType: TreatmentAdoptionSimulationItemType;
  maxPotential: number;
  maxPotentialUpper: number;
  maxPotentialLower: number;
}

const MIN_GROUPS_FOR_SEARCH = 6;

interface Filters {
  name?: string[];
}

const DEFAULT_FILTERS: Filters = {
  name: null,
};

export const BaseTreatmentAdoptionSimulationViewer: React.FC<AllProps> = (props: AllProps) => {
  const {
    id,
    data: dataFromProps,
    options: optionsFromProps,
    algoMode,
    setAlgoMode,
    transKeysPrefix,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const tabActionsRef = useRef(null);
  const viewerRef = usePendoAnchor(PendoAnchors.TREATMENT_ADOPTION_SIMULATION_VIEWER);
  const [highlightModeSelection, setHighlightModeSelection] = useState(false);
  const {trackNavigation} = useDocumentTracking(
    id,
    DocumentElementType.TREATMENT_ADOPTION_SIMULATION_FIGURE
  );
  const {button: simulatorButton, mode} = useSimulatorMode({
    figureId: id,
    elementType: props.type,
    allowDataMode: optionsFromProps.dataMode,
    isCasual: dataFromProps.mode === AnalysisAlgoMode.CAUSATION,
    helperText: t(transKeysPrefix.MODES.SIMULATOR_MODE_HELPER),
  });
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(
    id,
    {
      ...DEFAULT_FILTERS,
    },
    'filters'
  );
  // fix data from props
  const data = useMemo(
    () => ({
      ...dataFromProps,
      items: extendTreatmentAdoptionSimulationItems(dataFromProps.items, dataFromProps.goalValue),
    }),
    [dataFromProps]
  );
  const filteredDataItems = useMemo(() => {
    return data.items.filter(i => {
      if (filters.name) {
        const match = filters.name.map(n => i.treatmentName.indexOf(n) > -1);
        return some(match);
      }
      return true;
    });
  }, [data.items, filters.name]);
  const itemsGroupByType = useMemo(() => groupBy(filteredDataItems, 'type'), [filteredDataItems]);
  const options = useMemo(
    () => ({
      ...optionsFromProps,
      types: fixOptionTypes(optionsFromProps.types || values(TreatmentAdoptionSimulationItemType)),
    }),
    [optionsFromProps]
  );
  const defaultSelectedType = useMemo(
    () =>
      values(TreatmentAdoptionSimulationItemType).find(
        v => itemsGroupByType[v] && itemsGroupByType[v].length > 0
      ),
    [itemsGroupByType]
  );
  const {query: selectedType, setQuery: setSelectedType_} =
    useDocQuery<TreatmentAdoptionSimulationItemType>(id, defaultSelectedType, 'type');
  const setSelectedType = useCallback(
    (item: TreatmentAdoptionSimulationItemType) => {
      trackNavigation(item);
      setSelectedType_(item);
    },
    [setSelectedType_, trackNavigation]
  );
  const tabs = useMemo(
    () =>
      options.types.map(type => ({
        key: type,
        label: `${t(transKeysPrefix.TABS[type.toUpperCase()])} (${
          itemsGroupByType[type]?.length || 0
        })`,
        disabled: !exists(itemsGroupByType[type]) || itemsGroupByType[type].length === 0,
      })),
    [itemsGroupByType, options.types]
  );
  const filterOptions = useMemo(() => {
    const set = new Set();
    for (const i of data.items) {
      i.treatmentName.forEach(name => set.add(name));
    }
    return Array.from(set).map(i => ({value: i, label: i}) as any);
  }, [data.items, selectedType]);
  const onSwitchAlgoMode = useCallback(
    (mode: AnalysisAlgoMode) => {
      setHighlightModeSelection(false);
      setAlgoMode(mode);
    },
    [setAlgoMode, setHighlightModeSelection]
  );

  const switchModeRender = useMemo(() => {
    if (!algoMode || !onSwitchAlgoMode) {
      return null;
    }
    return (
      <SwitchAlgoMode
        algoMode={algoMode}
        onSwitchAlgoMode={onSwitchAlgoMode}
        className={classNames(classes.SelectMode, highlightModeSelection && classes.Highlight)}
      />
    );
  }, [algoMode, onSwitchAlgoMode, highlightModeSelection]);
  const footerRenderer = () => {
    return (
      <div className={classes.SwitchToCorrelativeHelper}>
        <MagnifyingGlassRegularIcon className={classes.Icon} />
        <span className={classes.HelperText}>
          {t(
            TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE
              .SWITCH_TO_CORRELATION_HELPER
          )}
        </span>
        <span
          onClick={() => onSwitchAlgoMode(AnalysisAlgoMode.CORRELATION)}
          onMouseEnter={() => setHighlightModeSelection(true)}
          onMouseLeave={() => setHighlightModeSelection(false)}
          className={classes.Button}
        >
          {t(
            TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE
              .SWITCH_TO_CORRELATION_BUTTON_TEXT
          )}
        </span>
      </div>
    );
  };
  const tableProps = {
    key: id,
    figureId: id,
    options,
    selectedType,
    items: filteredDataItems,
    goalValue: data.goalValue,
    mode: data.mode || AnalysisAlgoMode.CORRELATION,
    transKeysPrefix,
    footerRenderer:
      algoMode === AnalysisAlgoMode.CAUSATION && onSwitchAlgoMode ? footerRenderer : null,
  };
  const titleText = t(transKeysPrefix.HEADER.TITLE, {
    model: options.treatmentModelName,
  });

  return (
    <div
      className={classNames(classes.TreatmentAdoptionSimulationViewer, className)}
      ref={viewerRef}
    >
      {exists(titleText) && (
        <div className={classes.Title}>
          {t(transKeysPrefix.HEADER.TITLE, {
            model: options.treatmentModelName,
          })}
          <HoverHelperTip
            className={classes.Helper}
            title={t(transKeysPrefix.HEADER.HELPER_TEXT, {
              model: options.treatmentModelName,
            })}
          />
        </div>
      )}
      <div className={classes.Filters}>
        {simulatorButton}
        {switchModeRender}
        <Select
          value={filters.name as any}
          onChange={(v: any) => setFilters({name: v})}
          dropdownButtonClassName={classes.Select}
          placeholder={t(
            TransKeys.DOCUMENT_VIEWER.SHARED_TREATMENT_ADOPTION_SIMULATION_FIGURE
              .ENTITY_FILTER_PLACEHOLDER,
            {model: pluralize(options.treatmentModelName)}
          )}
          multiSelectItemName={options.treatmentModelName}
          options={{options: filterOptions}}
          icon={BarsFiltersLightIcon}
          searchable={filterOptions.length > MIN_GROUPS_FOR_SEARCH}
          multi
        />
      </div>
      <div className={classes.TabsWrapper}>
        <AppTabs tabs={tabs} selected={selectedType} onChange={setSelectedType} fullWidth />
        <div ref={tabActionsRef} />
      </div>
      <div className={classes.Content}>
        {mode === SimulationMode.DATA && <TreatmentAdoptionDataModeTable {...tableProps} />}
        {mode === SimulationMode.SIMULATOR && (
          <TreatmentAdoptionSimulatorModeTable {...tableProps} tabActionsRef={tabActionsRef} />
        )}
      </div>
    </div>
  );
};

BaseTreatmentAdoptionSimulationViewer.defaultProps = {
  transKeysPrefix: TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE,
};
