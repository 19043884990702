import * as React from 'react';
import classNames from 'classnames';
import classes from './funnel-overview-viewer.module.scss';
import {DocumentElementType, FunnelOverviewFigure} from '../../../../types';
import {FunnelOverviewDataMode} from './components/funnel-overview-data-mode/funnel-overview-data-mode.component';
import TransKeys from 'translations';
import {SimulationMode, useSimulatorMode} from '../../../../hooks/use-simulator-mode.hook';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {FunnelOverviewSimulatorMode} from './components/funnel-overview-simulator-mode/funnel-overview-simulator-mode.component';
import {exists} from 'front-core';
import {useMemo} from 'react';

interface OwnProps extends FunnelOverviewFigure {
  className?: string;
}

type AllProps = OwnProps;

export const FunnelOverviewViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data, options, className} = props;
  const {t} = useDocumentTranslation();

  const {button: simulatorButton, mode} = useSimulatorMode({
    figureId: id,
    elementType: DocumentElementType.FUNNEL_OVERVIEW_FIGURE,
    allowDataMode: true,
    isCasual: true,
    queryPrefix: 'overview',
    label: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_MODE),
    helperText: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_MODE_HELPER),
  });
  const renderSimulator = useMemo(
    () => exists(data.potentialLifts) && data.potentialLifts.length > 0,
    [data.potentialLifts]
  );

  return (
    <div className={classNames(classes.FunnelOverviewFigureViewer, className)}>
      {renderSimulator && <div className={classes.Controls}>{simulatorButton}</div>}
      {mode === SimulationMode.DATA && (
        <FunnelOverviewDataMode figureId={id} data={data} options={options} />
      )}
      {mode === SimulationMode.SIMULATOR && (
        <FunnelOverviewSimulatorMode figureId={id} data={data} options={options} />
      )}
    </div>
  );
};

FunnelOverviewViewer.defaultProps = {};
