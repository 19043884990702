import * as React from 'react';
import classNames from 'classnames';
import {ProgressColumnOptions} from '../../../../../types';
import classes from './data-columns.module.scss';
import {useMemo} from 'react';
import {get} from 'lodash';
import {exists, number2k} from 'front-core';
import Rainbow from 'rainbowvis.js';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {getDataValueKey} from '../../smart-table.utils';
import {DataColumnProps} from '../../smart-table.types';
import {colorAlphaTransformer} from '../../../../../../../../utils/colors';

interface OwnProps extends DataColumnProps<ProgressColumnOptions> {}

type AllProps = OwnProps;

const ProgressDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;
  const {totalDataKey} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);
  const total = useMemo(() => get(data, totalDataKey), [totalDataKey, data]);
  const value = useMemo(() => get(data, valueDataKey), [valueDataKey, data]);
  const percentage = useMemo(
    () => get(data, column.key) || Number(((value / total) * 100).toFixed(2)),
    [column.key, data, total, value]
  );
  const backgroundColor = useMemo(() => {
    if (column.typeOptions.gradient) {
      const colorGradient = new Rainbow();
      colorGradient.setSpectrum('#FF6871', '#62E797');
      return colorAlphaTransformer(`#${colorGradient.colourAt(Math.round(percentage))}`, 0.7);
    }
  }, [column, percentage]);

  return (
    <div className={classNames(classes.ProgressDataColumn, className)}>
      <div className={classes.Progress}>
        <div style={{width: `${percentage}%`, backgroundColor}} className={classes.ProgressBar}>
          <div className={classes.PercentageValue}>{percentage}%</div>
        </div>
        <div className={classes.Info}>
          <span className={classes.Muted}>{number2k(value)} /</span> {number2k(total)}
        </div>
      </div>
    </div>
  );
};

const protector: Protector = (props: OwnProps) => {
  const {column, data} = props;
  const {totalDataKey} = column.typeOptions;
  const value = get(data, getDataValueKey(column, 'valueDataKey'));
  const total = get(data, totalDataKey);
  return exists(value) && exists(total);
};

export const ProgressDataColumn = withDataColumnProtector(protector)(ProgressDataColumnComponent);
