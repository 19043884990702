import {useMemo} from 'react';
import {Select, SwitchActions} from 'ui-components';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {getEntityIcon} from '../../../../constants/entity.consts';
import {TableEntity} from '../../../../objects/models/table.model';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import pluralize from 'pluralize';

interface OwnProps {
  value: string;
  editMode?: boolean;
  onChange?: (entity: TableEntity) => void;
  variant?: 'select' | 'switch';
  size?: 'small' | 'default';
  pluralizeEntityName?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const EntityPicker = (props: AllProps) => {
  const {value, editMode, onChange, variant, pluralizeEntityName, size, className} = props;
  const {productEntities, productEntitiesMap} = useProductData();

  const entityOptions = useMemo(
    () => ({
      options: productEntities.map(p => ({
        key: p.key,
        value: p.key,
        label: pluralizeEntityName ? pluralize(p.name) : p.name,
        onClick: () => onChange(p.key),
        isActive: value === p.key,
        icon: getEntityIcon(p.key),
      })),
    }),
    [productEntities, value, onChange, pluralizeEntityName]
  );

  if (variant === 'select') {
    return (
      <Select
        placeholder={'Select'}
        value={value}
        onChange={v => onChange(v as TableEntity)}
        searchable={false}
        sortValues={false}
        fullWidth
        clearable={false}
        options={entityOptions}
      />
    );
  }

  if (editMode && entityOptions.options.length > 1) {
    return (
      <SwitchActions
        className={className}
        actions={entityOptions.options}
        showActionsLabel
        size={size}
      />
    );
  }

  return (
    <TitleWithIcon
      className={className}
      text={productEntitiesMap[value]?.name}
      icon={getEntityIcon(value as any)}
      size={'medium'}
    />
  );
};
