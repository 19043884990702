import {SeriesDatetimeAttribute} from '../../../../../../../objects/models/model-sample-series.model.ts';
import {useContext, useMemo} from 'react';
import {KPITreeContext} from '../../../kpi-tree.context.tsx';
import {KPITreePopoverLayout} from '../../kpi-tree-popover-layout/kpi-tree-popover-layout.component.tsx';
import {
  MetricNode,
  MetricTreeRelationshipType,
} from '../../../../../../../objects/models/metric-tree.model.ts';
import {useTranslation} from 'react-i18next';
import {CorrelationChip} from '../../correlation-chip/correlation-chip.component.tsx';
import {HoverHelperTip} from 'ui-components';
import TransKeys from 'translations';
import classes from './kpi-edge-popover.module.scss';
import {ICON_FOR_RELATIONSHIP_TYPE} from '../../../kpi-tree.consts.ts';
import {EdgeChartViewer} from './edge-chart-viewer.component.tsx';

interface OwnProps {
  correlation: number;
  isSignificantCorrelation: boolean;
  relationshipType: MetricTreeRelationshipType;
  sourceMetric: MetricNode;
  targetMetric: MetricNode;
  datetimeAttribute?: SeriesDatetimeAttribute;
  children: any;
}

type AllProps = OwnProps;

export const KPIEdgePopover = (props: AllProps) => {
  const {
    correlation,
    isSignificantCorrelation,
    relationshipType,
    sourceMetric,
    targetMetric,
    children,
  } = props;
  const {t} = useTranslation();
  const {toDate, datetimeAttribute} = useContext(KPITreeContext);
  const RelationshipIcon = ICON_FOR_RELATIONSHIP_TYPE[relationshipType];
  const metricIds: [number, number] = useMemo(
    () => [sourceMetric.id, targetMetric.id],
    [sourceMetric, targetMetric]
  );

  return (
    <KPITreePopoverLayout
      title={`${sourceMetric.name} → ${targetMetric.name}`}
      subTitleItems={[
        relationshipType === MetricTreeRelationshipType.CORRELATION && {
          label: t(TransKeys.KPI_TREE.INPUT_KPI_POPOVER.LABEL.CORRELATION),
          content: (
            <div className={classes.Inline}>
              <CorrelationChip correlation={correlation} isSignificant={isSignificantCorrelation} />
              <HoverHelperTip
                small
                title={t(TransKeys.KPI_TREE.CORRELATION_EDGE.CORRELATION_HELPER_TEXT)}
              />
            </div>
          ),
        },
        relationshipType !== MetricTreeRelationshipType.CORRELATION && {
          label: t(TransKeys.KPI_TREE.INPUT_KPI_POPOVER.LABEL.RELATION_TYPE),
          content: (
            <div className={classes.Inline}>
              {RelationshipIcon && <RelationshipIcon />}
              <span className={classes.RelationName}>
                {t(
                  TransKeys.KPI_TREE.INPUT_METRIC_RELATIONSHIP_TYPE[relationshipType.toUpperCase()]
                    .LABEL
                )}
              </span>
            </div>
          ),
        },
      ]}
      content={
        <div className={classes.Chart}>
          <EdgeChartViewer
            metricIds={metricIds}
            toDate={toDate}
            datetimeAttribute={datetimeAttribute}
          />
        </div>
      }
    >
      {children}
    </KPITreePopoverLayout>
  );
};
