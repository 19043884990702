import {useCallback, useMemo, useState} from 'react';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {isEmpty, values} from 'lodash';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {hasErrors} from '../../../../../../utils/general.utils';
import {AnalysisSelectorProps} from '../analysis-selector.types';
import {SmartTreeSelector} from 'ui-components';
import {SmartSelectorLayout} from '../../../../../shared/core/smart-selector/smart-selector-layout.component';
import classes from '../../../../../shared/core/smart-selector/smart-selector.module.scss';
import {exists} from 'front-core';

export const ALL_EVENT_PROPERTIES_TAG = '__all_event_properties__';

interface SchemaKeysMapping {
  event_properties: string;
  included_event_properties_tag: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  event_properties: 'event_properties',
  included_event_properties_tag: 'included_event_properties_tag',
};

interface OwnProps extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  disabled?: boolean;
  className?: string;
  options: Array<{signalId: number; name: string}>;
}

type AllProps = OwnProps;

export const EventPropertiesSelector = (props: AllProps) => {
  const {
    value: data,
    onChange: onChangeFromProps,
    options,
    errors,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    title: titleFromProps,
    subTitle: subTitleFromProps,
    helperText: helperTextFromProps,
    disabled: disabledFromProps,
    className,
  } = props;
  const {t} = useTranslation();
  const [count, setCount] = useState(undefined);

  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );

  const value = useMemo(() => {
    const tags =
      data[schemaKeysMapping.included_event_properties_tag]?.split(',').filter(Boolean) || [];
    return [...tags, ...(data[schemaKeysMapping.event_properties] || [])];
  }, [data, schemaKeysMapping]);

  const groups = useMemo(() => {
    if (!options || options.length === 0) {
      return [];
    }
    return [
      {
        key: 'event-properties-root',
        name: '',
        startOpen: true,
        data: [
          {
            key: ALL_EVENT_PROPERTIES_TAG,
            value: ALL_EVENT_PROPERTIES_TAG,
            label: 'All Event Properties',
            children: options.map(p => ({
              key: p.signalId,
              value: p.signalId,
              label: p.name,
              disabled: false,
              disabledHelper: null,
            })),
          },
        ],
      },
    ];
  }, [options]);

  const disabled = useMemo(
    () => disabledFromProps || groups.length === 0,
    [disabledFromProps, groups]
  );

  const texts = useMemo(
    () => ({
      title: titleFromProps || t(TransKeys.ANALYSIS_FORMS.SHARED.MODEL_EVENT_PROPERTIES.TITLE),
      subTitle:
        subTitleFromProps || t(TransKeys.ANALYSIS_FORMS.SHARED.MODEL_EVENT_PROPERTIES.SUB_TITLE),
      selectLabel: !count
        ? ''
        : `${count} ${t(TransKeys.ANALYSIS_FORMS.SHARED.MODEL_EVENT_PROPERTIES.SELECT_LABEL)}`,
      selectPlaceholder: disabled
        ? t(TransKeys.ANALYSIS_FORMS.SHARED.MODEL_EVENT_PROPERTIES.NO_EVENT_PROPERTIES)
        : t(TransKeys.ANALYSIS_FORMS.SHARED.MODEL_EVENT_PROPERTIES.SELECT_PLACEHOLDER),
      helperText:
        groups.length === 0
          ? helperTextFromProps ||
            t(TransKeys.ANALYSIS_FORMS.SHARED.MODEL_EVENT_PROPERTIES.HELPER_TEXT)
          : '',
    }),
    [groups, t, count, disabled, titleFromProps, subTitleFromProps, helperTextFromProps]
  );

  const onChange = useCallback(
    v => {
      const includedEventPropertiesTag =
        exists(v) && !isEmpty(v) ? v.filter(t => typeof t === 'string').join(',') : undefined;
      const includedEventPropertiesSignals =
        exists(v) && !isEmpty(v) ? v.filter(t => typeof t === 'number') : undefined;

      onChangeFromProps({
        [schemaKeysMapping.included_event_properties_tag]: includedEventPropertiesTag,
        [schemaKeysMapping.event_properties]: includedEventPropertiesSignals,
      });
    },
    [onChangeFromProps, schemaKeysMapping]
  );

  return (
    <ParameterInputWrapper
      title={texts.title}
      subTitle={texts.subTitle}
      helperText={texts.helperText}
      error={hasError}
      className={className}
    >
      <SmartSelectorLayout
        id={'event-properties-selector'}
        label={texts.selectLabel}
        disabled={disabled}
        error={null}
        onClear={() => onChange(null)}
        placeholder={texts.selectPlaceholder}
        withPreview={false}
      >
        <SmartTreeSelector
          className={classes.Selector}
          value={value}
          groups={groups}
          onChange={(value, originalValue: any) => {
            onChange(value);
            setCount(originalValue.length);
          }}
          multi={true}
          startRootOpen={true}
          selectParentValue={true}
        />
      </SmartSelectorLayout>
    </ParameterInputWrapper>
  );
};
