import {createContext, useCallback, useMemo} from 'react';
import * as React from 'react';
import {SqlElement} from './query-builder.types';
import {set, del} from 'object-path-immutable';
import {QueryBuilderConfig} from './query-builder-ui.types';
import {queryBuilderDefaultComponents} from './query-builder.config';
import {isEqual} from 'lodash';

export interface IQueryBuilderContext {
  onChange: (path: string, value: any) => void;
  onDelete: (path: string) => void;
  data: SqlElement;
  errors: any;
  disabled?: boolean;
  viewMode?: boolean;
  config?: QueryBuilderConfig;
}

export const QueryBuilderContext = createContext<IQueryBuilderContext>({
  onChange: undefined,
  onDelete: undefined,
  data: undefined,
  errors: undefined,
  disabled: undefined,
  viewMode: undefined,
  config: undefined,
});

interface OwnProps {
  placeholder?: SqlElement;
  data?: SqlElement;
  config?: QueryBuilderConfig;
  onChange?: (query: SqlElement) => void;
  errors?: any;
  disabled?: boolean;
  viewMode?: boolean;
  children: any;
}

export const QueryBuilderContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    data,
    placeholder,
    config,
    onChange: onChange_,
    errors: errors_,
    disabled,
    viewMode,
    children,
  } = props;
  const onChange = useCallback(
    (path: string, value: any) => {
      if (!onChange_) {
        return;
      }
      const newData = path ? set(data, path, value) : value;
      if (placeholder && isEqual(placeholder, newData)) {
        return onChange_(null);
      }
      onChange_(newData);
    },
    [data, onChange_]
  );
  const onDelete = useCallback(
    (path: string) => onChange_ && onChange_(del(data, path)),
    [data, onChange_]
  );
  const errors = useMemo(() => errors_ || {}, [errors_]);
  const componentsMap = useMemo(
    () => ({
      ...queryBuilderDefaultComponents,
      ...(config.componentsMap || {}),
    }),
    [config]
  );

  const contextValue = useMemo(
    () => ({
      data,
      errors,
      onChange,
      onDelete,
      disabled,
      viewMode,
      config: {...(config || {}), componentsMap},
    }),
    [data, errors, onChange, onDelete, disabled, viewMode, config, componentsMap]
  );

  return (
    <QueryBuilderContext.Provider value={contextValue}>{children}</QueryBuilderContext.Provider>
  );
};
