import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './kpi-segmentation-table.module.scss';
import {ExtendedKPISegmentationItem} from '../../kpi-segmentation-viewer.component';
import {KPISegmentationFigureOptions} from '../../../../../../types';
import {every, groupBy, take} from 'lodash';
import {TooltipIfOverflow} from '../../../../../../../../simple/generic/tooltips/tooltips.component';
import {HoverHelperTip} from '../../../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {
  FlexibleTable,
  FlexibleTableClasses,
} from '../../../../../shared/general/flexible-table/flexible-table.component';
import {Button} from '../../../../../../../../simple/controls/button/button.component';
import {UpliftDisplay} from '../../../../../shared/display-columns/uplift-display.component';
import {VersusDisplay} from '../../../../../shared/display-columns/versus-display.component';
import pluralize from 'pluralize';
import {ShareOfUsers} from '../../../../../shared/display-columns/share-of-users.component';
import {getNextSort, number2k, sortData, Sorting, withStopPropagation} from 'front-core';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';

interface OwnProps {
  figureId: string;
  options: Omit<KPISegmentationFigureOptions, 'kpiName'>;
  items: ExtendedKPISegmentationItem[];
  totalEntities: number;
  onSeeAll: (segmentGroupName: string) => void;
  transKeysPrefix?: any;
  className?: string;
}

type AllProps = OwnProps;
const PRIMARY_SEGMENT_SORT: Sorting = {
  orderBy: 'isInformative',
  order: 'desc',
};
const DEFAULT_SEGMENTS_SORT: Sorting = {
  orderBy: 'share',
  order: 'desc',
};
const GROUP_SORTING = [
  {
    orderBy: 'isMuted',
    order: 'desc',
  },
  {
    orderBy: 'groupName',
    order: 'asc',
  },
];

const NUMBER_OF_ITEMS_PER_GROUP = 3;

export const KPISegmentationDataModeTable: React.FC<AllProps> = (props: AllProps) => {
  const {items, totalEntities, options, onSeeAll, transKeysPrefix, className} = props;
  const {t} = useDocumentTranslation();
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const [sorting, setSorting] = useState<Sorting>(DEFAULT_SEGMENTS_SORT);
  const itemsGroupedBySegmentGroup = useMemo(() => groupBy(items, 'segmentGroupName.0'), [items]);
  const tableData = useMemo(() => {
    let res = [];
    for (const key in itemsGroupedBySegmentGroup) {
      res.push({
        key,
        groupName: key,
        totalCount: itemsGroupedBySegmentGroup[key].length,
        signalId: itemsGroupedBySegmentGroup[key][0].signalId,
        segments: sortData(itemsGroupedBySegmentGroup[key], PRIMARY_SEGMENT_SORT, sorting),
        isMuted: !every(itemsGroupedBySegmentGroup[key].map(s => s.groupInsignificant)),
      });
    }
    // @ts-ignore
    return sortData(res, GROUP_SORTING[0], GROUP_SORTING[1]);
  }, [itemsGroupedBySegmentGroup, sorting]);

  const columns = useMemo(
    () => [
      {
        key: 'groupName',
        title: t(transKeysPrefix.GROUP_NAME.LABEL),
        weight: 1.2,
        helperText: t(transKeysPrefix.GROUP_NAME.HELPER_TEXT),
        render: item => (
          <div className={classes.SegmentGroupColumn}>
            <TooltipIfOverflow title={item.groupName}>
              <div
                onClick={
                  item.signalId[0]
                    ? withStopPropagation(() => onSignalClick(item.signalId[0]))
                    : undefined
                }
                className={classNames(classes.Name, item.signalId[0] && classes.Clickable)}
              >
                {item.groupName.trim()}({item.totalCount})
              </div>
            </TooltipIfOverflow>
          </div>
        ),
      },
      {
        key: 'segmentName',
        title: t(transKeysPrefix.SEGMENT.LABEL),
        weight: 1,
        sortable: true,
        helperText: t(transKeysPrefix.SEGMENT.HELPER_TEXT),
        render: item => (
          <div className={classes.SegmentColumn}>
            {take(item.segments, NUMBER_OF_ITEMS_PER_GROUP).map(
              (s: ExtendedKPISegmentationItem) => (
                <div key={s.key} className={classes.SegmentLine}>
                  <TooltipIfOverflow title={s.segmentName[0]}>
                    <div className={classes.Name}>{s.segmentName[0]}</div>
                  </TooltipIfOverflow>
                  {s.helperText && <HoverHelperTip className={classes.Info} title={s.helperText} />}
                </div>
              )
            )}
          </div>
        ),
      },
      {
        key: 'share',
        title: t(transKeysPrefix.SHARE_OF_X.LABEL, {
          entity: pluralize(options.entity),
        }),
        weight: 1.2,
        sortable: true,
        helperText: t(transKeysPrefix.SHARE_OF_X.HELPER_TEXT, {
          entity: pluralize(options.entity),
        }),
        render: item => (
          <div className={classes.ShareOfUsers}>
            {take(item.segments, NUMBER_OF_ITEMS_PER_GROUP).map(
              (s: ExtendedKPISegmentationItem) => (
                <ShareOfUsers
                  key={s.key}
                  className={classes.SegmentLine}
                  count={s.count}
                  entity={options.entity}
                  total={totalEntities}
                />
              )
            )}
          </div>
        ),
      },
      {
        key: 'goalInSegment',
        title: t(transKeysPrefix.GOAL_IN_SEGMENT.LABEL),
        subTitle: options.isSumMode
          ? undefined
          : `vs. ${t(transKeysPrefix.GOAL_IN_NON_SEGMENT.LABEL)}`,
        weight: 1,
        sortable: true,
        helperText: t(transKeysPrefix.GOAL_IN_SEGMENT.HELPER_TEXT),
        render: item => {
          if (options.isSumMode) {
            return (
              <div className={classes.GeneralColumn}>
                {take(item.segments, NUMBER_OF_ITEMS_PER_GROUP).map(
                  (s: ExtendedKPISegmentationItem) => (
                    <div className={classes.SegmentLine}>
                      {options.isPercentageValue
                        ? `${number2k(s.goalInSegment * 100)}%`
                        : number2k(s.goalInSegment)}
                    </div>
                  )
                )}
              </div>
            );
          }
          return (
            <div className={classes.GeneralColumn}>
              {take(item.segments, NUMBER_OF_ITEMS_PER_GROUP).map(
                (s: ExtendedKPISegmentationItem) => (
                  <VersusDisplay
                    key={s.key}
                    className={classes.SegmentLine}
                    value={s.goalInSegment}
                    vsValue={s.goalInNonSegment}
                    isPercentageValue={options.isPercentageValue}
                    vsLabel={t(transKeysPrefix.GOAL_IN_NON_SEGMENT.LABEL)}
                  />
                )
              )}
            </div>
          );
        },
      },
      {
        key: 'uplift',
        title: t(transKeysPrefix.UPLIFT.LABEL),
        weight: 1,
        sortable: true,
        helperText: t(transKeysPrefix.UPLIFT.HELPER_TEXT),
        hidden: options.isSumMode === true,
        render: item => (
          <div className={classes.GeneralColumn}>
            {take(item.segments, NUMBER_OF_ITEMS_PER_GROUP).map(
              (s: ExtendedKPISegmentationItem) => (
                <UpliftDisplay
                  key={s.key}
                  uplift={s.uplift}
                  className={classes.SegmentLine}
                  higherIsBetter={options.higherIsBetter}
                />
              )
            )}
          </div>
        ),
      },
      {
        key: 'see_all',
        title: '',
        weight: 0.2,
        render: item => (
          <div className={classes.SeeAll}>
            <Button
              className={FlexibleTableClasses.ShowOnHover}
              onClick={withStopPropagation(() => onSeeAll(item.groupName))}
              caps={false}
            >
              {t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEE_ALL, {
                count: item.segments.length > 1 ? item.segments.length : '',
              })}
            </Button>
          </div>
        ),
      },
    ],
    [onSignalClick, items, totalEntities, t, options]
  );

  const onSort = useCallback(
    (columnKey: string) => setSorting(getNextSort(columnKey, sorting)),
    [sorting, setSorting]
  );

  return (
    <FlexibleTable
      className={classNames(classes.KPISegmentationDataMode, className)}
      columns={columns}
      data={tableData}
      sorting={sorting}
      onSort={onSort}
      onRowClicked={item => onSeeAll(item.groupName)}
      isMutedRow={item => item.isMuted === false}
      rowClassName={i =>
        i.segments.length > NUMBER_OF_ITEMS_PER_GROUP
          ? FlexibleTableClasses.RowGradientOverlay
          : null
      }
    />
  );
};

KPISegmentationDataModeTable.defaultProps = {
  transKeysPrefix: TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS,
};
