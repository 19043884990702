import * as React from 'react';
import {useContext, useEffect, useMemo, useState} from 'react';
import {Layer} from 'react-konva';
import {EnhancedGroup, EnhancedLine, EnhancedText} from '../../../../core/konva/components';
import {CanvasElementPositions} from '../../../../core/konva/stage.types';
import {calculateBbox} from '../../../../core/konva/stage.utils';
import {StageContext} from '../../../../core/konva/stage-context.component';
import {max, min, range} from 'lodash';
import {ChartLegend, LegendLabel} from '../components/chart-legend.component';
import {
  calculateFractionDigitForYTicks,
  createRangeTicks,
  formatXDateLabelWithRangeTimeUnit,
} from '../chart.utils';
import {exists, number2k} from 'front-core';
import {useChartLabelsElements, useChartLegend, LEGEND_TOP_PADDING} from '../chart.hooks';
import {DEFAULT_DATE_FORMAT} from '../../../../consts/ui';
import {XTicksRangeTimeUnit} from '../chart-data.types';
import {AxisProperties, XAxisProperties} from '../chart-inner.types';
import {colorAlphaTransformer} from '../../../../utils/colors';

export interface HorizontalGridLayoutInjectedProps extends CanvasElementPositions {
  xRange: [number, number];
  yRange: [number, number];
  secondaryYRange?: [number, number];
  maxValue?: number;
  xTickSize?: number;
  labels?: string[];
  xRangeMS?: boolean;
  timeUnit?: XTicksRangeTimeUnit;
  showIncompleteMark?: boolean;
  incompleteMarkDescription?: string;
}

interface OwnProps extends CanvasElementPositions {
  children?: (injected: HorizontalGridLayoutInjectedProps) => any;
  labels?: string[];
  alwaysRenderLabels?: boolean;
  legendLabels: LegendLabel[];
  showLegend?: boolean;
  legendsTitle?: string;
  dateFormat?: string;
  onLabelClicked?: (label: LegendLabel, e: React.MouseEvent<HTMLElement>) => void;
  showAnnotations?: boolean;
  onChangeShowAnnotations?: (showAnnotations: boolean) => void;
  xAxis: XAxisProperties;
  yAxis: AxisProperties;
  secondaryYAxis?: AxisProperties;
  descriptionsLegends?: Array<{color: string; description: string}>;
  showYHorizontalLines?: boolean;
  legendVerticalPosition?: 'top' | 'bottom';
  chartBBoxPaddingTop?: number;
  enableYLabel?: boolean;
  enableSecondaryYLabel?: boolean;
  showIncompleteMark?: boolean;
  incompleteMarkDescription?: string;
  minimalXAxisTicks?: boolean;
  errorBar?: boolean;
  onChangeErrorBar?: (errorBar: boolean) => void;
  showHideCI?: boolean;
  showHideAnnotation?: boolean;
  disableLegend?: boolean;
}

type AllProps = OwnProps;

const X_LABEL_HEIGHT = 20;
const MAX_NUMBER_OF_ROWS = 8;
const Y_TITLE_HEIGHT = 20;
const gridColor = colorAlphaTransformer('#C3C6D8', 0.4);
const MIN_WIDTH_FOR_X_LABEL = 15;
const Y_AXIS_MAX_FRACTION_DIGITS = 6;

export const GridLayout: React.FC<AllProps> = (props: AllProps) => {
  const {
    children,
    labels,
    width,
    height,
    legendsTitle = 'Legend',
    legendLabels,
    showLegend = true,
    legendVerticalPosition = 'bottom',
    alwaysRenderLabels,
    x,
    y,
    dateFormat = DEFAULT_DATE_FORMAT,
    onLabelClicked,
    xAxis,
    yAxis,
    secondaryYAxis,
    descriptionsLegends,
    showYHorizontalLines = true,
    chartBBoxPaddingTop = 0,
    enableYLabel = true,
    enableSecondaryYLabel = true,
    showIncompleteMark = false,
    incompleteMarkDescription,
    minimalXAxisTicks,
    disableLegend,
  } = props;
  const context = useContext(StageContext);
  const {controller, style} = context;
  const [chartBBox, setChartBBox] = useState(calculateBbox(width, height));
  const [yAxisFractionDigit, setYAxisFractionDigit] = useState({
    primary: undefined,
    secondary: undefined,
  });
  const {legendHeight, legendHtmlProps} = useChartLegend(
    height,
    LEGEND_TOP_PADDING,
    chartBBox,
    legendVerticalPosition,
    width
  );
  const yTitleHeight = useMemo(() => (yAxis.label ? Y_TITLE_HEIGHT : 0), [yAxis.label]);
  /**
   * Computed properties
   */
  const maxYTicks = useMemo(
    /*
     * We decrement 2 when calculating the number of rows as they represent the
     * bounding rows (lower and upper)
     * */
    () => (yAxis.maxTicks && yAxis.maxTicks > 0 ? yAxis.maxTicks - 2 : MAX_NUMBER_OF_ROWS),
    [yAxis.maxTicks]
  );
  const maxNumberOfXTicks = useMemo(
    () => min([chartBBox.width / MIN_WIDTH_FOR_X_LABEL, xAxis.maxTicks]),
    [chartBBox.width, xAxis.maxTicks]
  );
  const yRange = useMemo(() => {
    const {range} = yAxis;
    if (range.length === 1) {
      const diff = Math.abs(range[0] * 0.1);
      return [range[0] - diff, range[0] + diff];
    }
    return range;
  }, [yAxis]);
  const yTicks: number[] = useMemo(
    () =>
      createRangeTicks({
        startRange: yRange[0],
        endRange: yRange[1],
        maxTicks: maxYTicks,
      }),
    [maxYTicks, yRange]
  );
  // Support for 2 Y-Axis - Only relevant for LineChart component
  const secondaryYRange = useMemo(() => {
    if (!secondaryYAxis?.range) {
      return null;
    }
    const {range} = secondaryYAxis;
    if (range.length === 1) {
      const diff = Math.abs(range[0] * 0.1);
      return [range[0] - diff, range[0] + diff];
    }
    return range;
  }, [secondaryYAxis]);
  // Support for 2 Y-Axis - Only relevant for LineChart component
  const secondaryYTicks: number[] = useMemo(
    () =>
      secondaryYRange
        ? createRangeTicks({
            startRange: secondaryYRange[0],
            endRange: secondaryYRange[1],
            maxTicks: maxYTicks,
          })
        : null,
    [maxYTicks, secondaryYRange]
  );
  const xTicks = useMemo(
    () =>
      xAxis?.range
        ? createRangeTicks({
            startRange: xAxis.range[0],
            endRange: xAxis.range[1],
            maxTicks: maxNumberOfXTicks,
            dateRange: xAxis?.isTimeRangeMS,
            timeUnit: xAxis?.timeUnit,
          })
        : null,
    [xAxis.range, xAxis?.isTimeRangeMS, xAxis?.timeUnit, maxNumberOfXTicks]
  );
  const xRange: [number, number] = useMemo(() => {
    if (xAxis?.range) {
      return [min([...xTicks, ...xAxis.range]), max([...xTicks, ...xAxis.range])];
    }
    if (labels) {
      return xAxis?.range as any;
    }
  }, [labels, xTicks, xAxis]);
  const xLabels: string[] = useMemo(() => {
    if (alwaysRenderLabels) {
      return labels;
    }
    if (xTicks && xAxis.isTimeRangeMS) {
      return xTicks.map(i =>
        formatXDateLabelWithRangeTimeUnit({
          value: i,
          timeUnit: xAxis?.timeUnit as XTicksRangeTimeUnit,
          dateFormat,
        })
      );
    }
    if (labels) {
      const labelWidth = chartBBox.width / labels.length;
      if (labelWidth > MIN_WIDTH_FOR_X_LABEL) {
        return labels;
      }
    }
    if (!xTicks && labels) {
      /*
       * We're calculating here an array of labels with equal distance between each other by
       * "skipping" some labels (according to the filter predicate)
       * and make sure we don't render the labels on top of each other
       * */
      const mod = range(1, 10).find(
        i => chartBBox.width / (labels.length / i) > MIN_WIDTH_FOR_X_LABEL
      );
      return labels.filter((_, idx) => idx % mod === 0);
    }
    return xTicks.map(i => i.toLocaleString());
  }, [alwaysRenderLabels, xTicks, xAxis.isTimeRangeMS, labels, dateFormat, chartBBox.width]);
  const primaryYFractionDigit = useMemo(
    () => calculateFractionDigitForYTicks(yTicks, Y_AXIS_MAX_FRACTION_DIGITS),
    [yTicks]
  );
  const yTickLabels = useMemo(
    () =>
      yTicks.reverse().map(label => {
        const labelText = number2k(label, primaryYFractionDigit) + yAxis.suffix;
        const width = controller.measureTextWidth(labelText, style.fontName, 10) + 8;
        return {
          label: labelText,
          width,
        };
      }),
    [controller, style.fontName, yAxis, yTicks, primaryYFractionDigit]
  );
  // Support for 2 Y-Axis - Only relevant for LineChart component
  const secondaryYFractionDigit = useMemo(
    () => calculateFractionDigitForYTicks(secondaryYTicks, Y_AXIS_MAX_FRACTION_DIGITS),
    [secondaryYTicks]
  );
  const secondaryYTickLabels = useMemo(
    () =>
      secondaryYAxis && secondaryYTicks?.length
        ? secondaryYTicks.reverse().map(label => {
            const labelText =
              number2k(label, secondaryYFractionDigit) + secondaryYAxis?.suffix || '';
            const width = controller.measureTextWidth(labelText, style.fontName, 10) + 8;
            return {
              label: labelText,
              width,
            };
          })
        : null,
    [secondaryYTicks, controller, style.fontName, secondaryYAxis, secondaryYFractionDigit]
  );

  useEffect(() => {
    if (
      primaryYFractionDigit !== yAxisFractionDigit.primary &&
      secondaryYFractionDigit !== yAxisFractionDigit.secondary
    ) {
      setYAxisFractionDigit({
        primary: primaryYFractionDigit,
        secondary: secondaryYFractionDigit,
      });
    }
    if (primaryYFractionDigit !== yAxisFractionDigit.primary) {
      setYAxisFractionDigit(prevState => ({
        ...prevState,
        primary: primaryYFractionDigit,
      }));
    }
    if (secondaryYFractionDigit !== yAxisFractionDigit.secondary) {
      setYAxisFractionDigit(prevState => ({
        ...prevState,
        secondary: secondaryYFractionDigit,
      }));
    }
  }, [primaryYFractionDigit, secondaryYFractionDigit, yAxisFractionDigit, setYAxisFractionDigit]);

  const yTickSize = useMemo(() => {
    const tickLabels = yTickLabels && yTickLabels.length ? yTickLabels : secondaryYTickLabels;
    return chartBBox.height / (tickLabels.length - 1);
  }, [chartBBox, yTickLabels, secondaryYTickLabels]);
  const yPositions = useMemo(() => {
    const tickLabels = yTickLabels && yTickLabels.length ? yTickLabels : secondaryYTickLabels;
    return tickLabels.map((_, idx) => idx * yTickSize);
  }, [secondaryYTickLabels, yTickLabels, yTickSize]);
  const horizontalLines = useMemo(
    () => (showYHorizontalLines ? yPositions : [yPositions[yPositions.length - 1]]),
    [showYHorizontalLines, yPositions]
  );
  // Support for 2 Y-Axis - Only relevant for LineChart component
  const secondaryYTickSize = useMemo(
    () =>
      secondaryYTickLabels && secondaryYTickLabels?.length
        ? chartBBox.height / (secondaryYTickLabels.length - 1)
        : null,
    [chartBBox?.height, secondaryYTickLabels]
  );
  // Support for 2 Y-Axis - Only relevant for LineChart component
  const secondaryYPositions = useMemo(
    () => (secondaryYTickLabels || []).map((_, idx) => idx * secondaryYTickSize),
    [secondaryYTickLabels, secondaryYTickSize]
  );
  const renderLabels = useChartLabelsElements(
    xLabels,
    chartBBox.width,
    controller,
    {
      fontName: style.fontName,
      fontSize: 10,
    },
    minimalXAxisTicks
  );
  const maxYLabelWidth = useMemo(() => {
    if (yTickLabels && yTickLabels.length) {
      return max(yTickLabels.map(y => y.width));
    }
    if (secondaryYTickLabels && secondaryYTickLabels.length) {
      return max(secondaryYTickLabels.map(y => y.width));
    }
  }, [yTickLabels, secondaryYTickLabels]);
  // Support for 2 Y-Axis - Only relevant for LineChart component
  const maxSecondaryYLabelWidth = useMemo(
    () => max((secondaryYTickLabels || []).map(y => y.width)),
    [secondaryYTickLabels]
  );

  useEffect(() => {
    setChartBBox(
      calculateBbox(width, height, [
        legendVerticalPosition === 'top'
          ? legendHeight + chartBBoxPaddingTop
          : yTitleHeight + (chartBBoxPaddingTop ? chartBBoxPaddingTop : 14),
        maxSecondaryYLabelWidth ? Math.ceil(maxSecondaryYLabelWidth) : 20,
        legendHeight + X_LABEL_HEIGHT + renderLabels.height,
        maxYLabelWidth,
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, maxYLabelWidth, legendHeight, maxSecondaryYLabelWidth, yTitleHeight]);

  // Support for 2 Y-Axis - Only relevant for LineChart component
  const secondaryYLabelTickXCoords = useMemo(
    () => secondaryYAxis && maxYLabelWidth + chartBBox.width + 5,
    [secondaryYAxis, maxYLabelWidth, chartBBox]
  );
  // Support for 2 Y-Axis - Only relevant for LineChart component
  const secondaryYLabelXCoords = useMemo(() => {
    if (!secondaryYAxis?.label) {
      return null;
    }

    const labelWidth = controller.measureTextWidth(secondaryYAxis.label, style.fontName, 12);
    return secondaryYLabelTickXCoords - labelWidth + maxSecondaryYLabelWidth - 10;
  }, [
    secondaryYAxis,
    secondaryYLabelTickXCoords,
    controller,
    style.fontName,
    maxSecondaryYLabelWidth,
  ]);

  const childrenProps: any = useMemo(
    () => ({
      x: chartBBox.x + renderLabels.width / 2,
      y: chartBBox.y,
      height: chartBBox.height,
      width: chartBBox.width - renderLabels.width,
      // Support for 2 Y-Axis - Only relevant for LineChart component
      maxValue: secondaryYAxis ? max([yTicks[0], secondaryYTicks[0]]) : yTicks[0],
      yRange: [yTicks[yTicks.length - 1], yTicks[0]],
      // Support for 2 Y-Axis - Only relevant for LineChart component
      secondaryYRange: secondaryYAxis
        ? [secondaryYTicks[secondaryYTicks.length - 1], secondaryYTicks[0]]
        : null,
      xRange,
      xRangeMS: xAxis?.isTimeRangeMS,
      xTickSize: renderLabels.width,
      timeUnit: xAxis?.timeUnit,
      labels,
      yAxisFractionDigit,
      showIncompleteMark,
      incompleteMarkDescription,
    }),
    [
      chartBBox,
      renderLabels.width,
      secondaryYAxis,
      yTicks,
      secondaryYTicks,
      xRange,
      xAxis,
      labels,
      yAxisFractionDigit,
      showIncompleteMark,
      incompleteMarkDescription,
    ]
  );

  /**
   * Render
   */
  return (
    <Layer>
      <EnhancedGroup width={width} height={height} x={x} y={y}>
        {/* Y LABEL */}
        {enableYLabel && exists(yAxis?.label) && yTickLabels && yTickLabels.length > 0 && (
          <EnhancedText
            key={`y_label`}
            fontSize={12}
            x={0}
            y={0}
            fill={'#898EA8'}
            text={yAxis.label}
            fontFamily={style.fontName}
          />
        )}
        {/* Secondary Y LABEL */}
        {enableSecondaryYLabel &&
          exists(secondaryYAxis?.label) &&
          secondaryYTickLabels &&
          secondaryYTickLabels.length > 0 && (
            <EnhancedText
              key={`2bd_y_label`}
              fontSize={12}
              x={secondaryYLabelXCoords}
              y={0}
              fill={'#898EA8'}
              text={secondaryYAxis.label}
              fontFamily={style.fontName}
            />
          )}
        {/* X LABEL */}
        <EnhancedText
          key={`x_label`}
          fontSize={12}
          height={X_LABEL_HEIGHT}
          x={0}
          y={height - (legendHeight || LEGEND_TOP_PADDING / 2)}
          width={chartBBox.width}
          padding={4}
          fill={'#41445a'}
          text={`${xAxis?.label?.toUpperCase() || ''} ${
            xAxis?.suffix ? `(${xAxis?.suffix.toUpperCase()})` : ''
          }`}
          align={'center'}
          fontFamily={style.fontName}
          fontStyle={'600'}
          centerY
        />
        {/* VERTICAL LINE - PRIMARY Y AXIS */}
        <EnhancedGroup {...chartBBox}>
          <EnhancedLine
            key={'y_line'}
            points={[0, 0, 0, chartBBox.height]}
            strokeWidth={1}
            stroke={gridColor}
          />
        </EnhancedGroup>
        {/* Y HORIZONTAL LINES */}
        <EnhancedGroup y={chartBBox.y} x={chartBBox.x}>
          {horizontalLines.map((y, idx) => (
            <EnhancedLine
              key={`y_line_${idx}`}
              points={[0, y, chartBBox.width, y]}
              strokeWidth={1}
              stroke={gridColor}
            />
          ))}
        </EnhancedGroup>
        {/* Y LABELS */}
        {yTickLabels && yTickLabels.length > 0 && (
          <EnhancedGroup y={chartBBox.y}>
            {yTickLabels.map((label, idx) => (
              <EnhancedText
                key={`y_label_${idx}`}
                fontSize={10}
                x={0}
                y={yPositions[idx]}
                width={label.width}
                height={10}
                align={'center'}
                fill={'#898EA8'}
                text={label.label.toString()}
                fontFamily={style.fontName}
                centerY
              />
            ))}
          </EnhancedGroup>
        )}
        {/* VERTICAL LINE - SECONDARY Y AXIS */}
        {secondaryYAxis && (
          <EnhancedGroup {...chartBBox}>
            <EnhancedLine
              key={'2nd_y_line'}
              points={[chartBBox.width, 0, chartBBox.width, chartBBox.height]}
              strokeWidth={1}
              stroke={gridColor}
            />
          </EnhancedGroup>
        )}
        {/* SECONDARY Y LABELS */}
        {secondaryYAxis && secondaryYTickLabels && (
          <EnhancedGroup y={chartBBox.y}>
            {secondaryYTickLabels.map((label, idx) => (
              <EnhancedText
                key={`2nd_y_label_${idx}`}
                fontSize={10}
                x={secondaryYLabelTickXCoords}
                y={secondaryYPositions[idx]}
                width={maxSecondaryYLabelWidth}
                height={10}
                align={'left'}
                fill={'#898EA8'}
                text={label.label.toString()}
                fontFamily={style.fontName}
                centerY
              />
            ))}
          </EnhancedGroup>
        )}
        {/* X LABELS */}
        <EnhancedGroup x={chartBBox.x}>
          {renderLabels.labels
            .filter((_, idx) =>
              minimalXAxisTicks ? idx === 0 || idx === renderLabels.labels.length - 1 : true
            )
            .map((label, i) => (
              <EnhancedText
                key={`x_label_${i}`}
                x={label.x + renderLabels.width / 2}
                y={height - legendHeight - X_LABEL_HEIGHT - label.height / 2}
                width={label.width}
                height={label.height}
                ellipsis
                align={'center'}
                fontSize={10}
                fill={'#898EA8'}
                text={label.text}
                fontFamily={style.fontName}
                rotation={label.rotation}
                centerX
              />
            ))}
        </EnhancedGroup>
        {/* CHILDREN */}
        {children(childrenProps)}
        {/* LEGEND */}
        {showLegend && (
          <ChartLegend
            id={legendHtmlProps.id}
            {...legendHtmlProps.groupProps}
            title={legendsTitle}
            labels={legendLabels}
            onLabelClicked={onLabelClicked}
            descriptionsLegends={descriptionsLegends}
            showAnnotations={props.showAnnotations}
            onChangeShowAnnotations={props.showHideAnnotation && props.onChangeShowAnnotations}
            errorBar={props.errorBar}
            onChangeErrorBar={props.showHideCI && props.onChangeErrorBar}
            disabled={disableLegend}
          />
        )}
      </EnhancedGroup>
    </Layer>
  );
};
