import * as React from 'react';
import {useMemo} from 'react';
import {DocumentElement} from '../../types';
import {isArray} from 'lodash';
import {DocumentComponentsMap} from '../../../../../index';
import {SingleChildRenderer} from './single-child-renderer.component';

export type AcceptedChildren = DocumentElement<any, any, any>;

interface ChildRendererProps {
  children_?: AcceptedChildren | AcceptedChildren[];
  componentsMap?: DocumentComponentsMap;
  className?: string;
  [other: string]: any;
}

export const ChildRenderer: React.FC<ChildRendererProps> = (props: ChildRendererProps) => {
  const {children_} = props;
  const children = useMemo(() => (!isArray(children_) ? [children_] : children_), [children_]);

  const renderChildren = () => {
    let index = 0;
    const res = [];
    for (const child of children) {
      res.push(
        <SingleChildRenderer
          {...props}
          key={`${child.type}_${index}`}
          children_={child}
          index={index}
        />
      );
      index++;
    }
    return res;
  };

  return <>{renderChildren()}</>;
};

ChildRenderer.defaultProps = {};
