import * as React from 'react';
import classNames from 'classnames';
import classes from './query-tree.module.scss';
import {useCallback, useMemo, useState} from 'react';
import {ActionItem, ActionsDropdown, AddIcon} from '../../../../../index';
import {QueryAction, QueryActions} from '../query-actions/query-actions.component';
import {debounce} from 'lodash';

interface OwnProps {
  label: string;
  small?: boolean;
  children: any;
  className?: string;
  addOptions?: ActionItem[];
  actions?: QueryAction[];
  headerNext?: any;
  disabled?: boolean;
}

type AllProps = OwnProps;

export const QueryTree: React.FC<AllProps> = (props: AllProps) => {
  const {
    label,
    children,
    addOptions,
    className,
    actions: actions_,
    disabled,
    headerNext,
    small,
  } = props;
  const childrenArray = useMemo(() => React.Children.toArray(children), [children]);
  const [showActions, setShowActions_] = useState(false);
  const setShowActions = useCallback(debounce(setShowActions_, 200, {leading: true}), [
    setShowActions_,
  ]);
  const actions: QueryAction[] = useMemo(() => {
    const res = [];
    if (addOptions) {
      res.push({
        render: () => (
          <ActionsDropdown
            className={classes.Action}
            label={'Add'}
            icon={AddIcon}
            iconDropdown
            actions={addOptions}
            popoverDirection={'left'}
          />
        ),
      });
    }
    res.push(...actions_);

    return res;
  }, [actions_, addOptions]);

  return (
    <div className={classNames(classes.QueryTree, small && classes.Small, className)}>
      <div
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
        className={classes.Head}
      >
        <div className={classes.Label}>{label}</div>
        <div className={classes.Actions}>
          {headerNext}
          {!disabled && <QueryActions iconButton={true} show={showActions} actions={actions} />}
        </div>
      </div>
      {childrenArray.length > 0 && (
        <div className={classes.Children}>
          {React.Children.map(childrenArray, (child, idx) => (
            <div key={idx} className={classes.ChildWrapper}>
              <div className={classes.Child}>{child}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

QueryTree.defaultProps = {
  actions: [],
  headerNext: null,
};
