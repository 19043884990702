import * as React from 'react';
import classNames from 'classnames';
import {
  ChartType,
  CommandType,
  DocumentElementType,
  FollowUpType,
  FunnelOverviewFigureData,
  FunnelOverviewFigureOptions,
} from '../../../../../../types';
import classes from './funnel-overview-simulator-mode.module.scss';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {exists, number2k, safeDivision, withStopPropagation} from 'front-core';
import {
  FlexibleTable,
  FlexibleTableColumn,
} from '../../../../../shared/general/flexible-table/flexible-table.component';
import TransKeys from 'translations';
import {Select} from '../../../../../../../../forms/inputs/select/select.component';
import {SimulatorSlider} from '../../../../../shared/inputs/simulator-slider/simulator-slider.component';
import {GradientLabelDisplay} from '../../../../../shared/display-columns/gradient-label-display.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {FunnelChartMode} from '../../../../../../../charts-v2/funnel-chart/funnel-chart.component';
import {last, range, sortBy, take, takeRight} from 'lodash';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {
  LoopsIcon,
  RocketLaunchLightIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {MatrixTable} from '../../../../../shared/general/matrix-table/matrix-table.component';
import pluralize from 'pluralize';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {Button} from '../../../../../../../../simple/controls/button/button.component';

interface OwnProps {
  figureId: string;
  data: FunnelOverviewFigureData;
  options: FunnelOverviewFigureOptions;
  className?: string;
}

type AllProps = OwnProps;

const DEFAULT_CHANGE = 0.05;

const translateStepConversionName = (step: number, totalStepsLength: number) => {
  if (step === 0) {
    return `Start → Step ${step + 2}`;
  }
  if (step + 1 === totalStepsLength - 1) {
    return `Step ${step + 1} → Completion`;
  }
  return `Step ${step + 1} → ${step + 2}`;
};

export const FunnelOverviewSimulatorMode: React.FC<AllProps> = (props: AllProps) => {
  const {figureId, data, options, className} = props;
  const {potentialLifts, funnelId} = data;
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(
    potentialLifts[0].fromStepIndex
  );
  const [change, setChange] = useState(DEFAULT_CHANGE);
  const {trackInput} = useDocumentTracking(figureId, DocumentElementType.FUNNEL_OVERVIEW_FIGURE);

  const selectedPotentialLift = useMemo(
    () => potentialLifts.find(p => p.fromStepIndex == selectedStepIndex),
    [potentialLifts, selectedStepIndex]
  );
  const steps = useMemo(() => sortBy(data.steps, 'order'), [data.steps]);
  const onDragComplete = useCallback(
    value => trackInput('conversion', {value, inputType: 'slider'}),
    [trackInput]
  );
  const model = useMemo(() => {
    if (!exists(selectedPotentialLift)) {
      return undefined;
    }
    const usersCount = data.funnelData.fromPrevious;
    const initialUsersCount = usersCount[0];

    // calculate conversion from start
    const conversionFromStart = [];
    for (const [idx, value] of usersCount.entries()) {
      if (idx === 0) {
        continue;
      }
      conversionFromStart.push(safeDivision(value, initialUsersCount));
    }

    // calculate conversion from prev
    const conversionFromPrev = [];
    for (const [idx, value] of usersCount.entries()) {
      if (idx === 0) {
        continue;
      }
      conversionFromPrev.push(safeDivision(value, usersCount[idx - 1]));
    }

    // calculate max change
    const maxChange = [];
    for (const [idx, _] of conversionFromPrev.entries()) {
      maxChange.push(safeDivision(1 - conversionFromPrev[idx], conversionFromPrev[idx]));
    }

    // calculate projected conversion
    const changePP = change * conversionFromPrev[selectedStepIndex];
    const projectedConversionFromStart = [];
    const projectedConversionFromStartUpper = [];
    const projectedConversionFromStartLower = [];
    for (const [idx, value] of conversionFromStart.entries()) {
      projectedConversionFromStart.push(
        value + selectedPotentialLift.stepPotentialLift[idx] * changePP
      );
      projectedConversionFromStartUpper.push(
        value + selectedPotentialLift.stepPotentialLiftUpper[idx] * changePP
      );
      projectedConversionFromStartLower.push(
        value + selectedPotentialLift.stepPotentialLiftLower[idx] * changePP
      );
    }

    // calculate projected users
    const projectedUsers = [initialUsersCount];
    for (const [idx, _] of projectedConversionFromStart.entries()) {
      projectedUsers.push(
        Math.min(initialUsersCount * projectedConversionFromStart[idx], projectedUsers[idx])
      );
    }

    // calculate max potential
    const maxChangePP =
      maxChange[selectedStepIndex] * conversionFromStart[projectedConversionFromStart.length - 1];
    const maxPotential =
      conversionFromStart[projectedConversionFromStart.length - 1] +
      selectedPotentialLift.stepPotentialLift[projectedConversionFromStart.length - 1] *
        maxChangePP;

    return {
      projectedConversionFromStart: projectedConversionFromStart,
      conversionFromStart,
      conversionFromPrev,
      funnelCompletion: safeDivision(last(usersCount), usersCount[0]),
      projectedFunnelCompletion: last(projectedConversionFromStart),
      projectedFunnelCompletionLower: last(projectedConversionFromStartLower),
      projectedFunnelCompletionUpper: last(projectedConversionFromStartUpper),
      projectedUsers,
      users: data.funnelData.fromPrevious,
      maxChange,
      maxPotential,
    };
  }, [data, change, selectedStepIndex, selectedPotentialLift]);

  const stepOptions = useMemo(() => {
    return potentialLifts.map(pl => ({
      label: translateStepConversionName(pl.fromStepIndex, steps.length),
      value: pl.fromStepIndex,
    }));
  }, [potentialLifts, steps]);

  const onChangeStepIndex = useCallback(
    (stepIndex: number) => setSelectedStepIndex(stepIndex),
    [setSelectedStepIndex]
  );
  const onFollowUp = useCallback(() => {
    emitEvent({
      type: CommandType.FOLLOW_UP,
      payload: {
        type: FollowUpType.FUNNEL_ANALYSIS_CONVERSION,
        parameters: {
          funnel: funnelId,
          goal: steps[selectedStepIndex + 1].signalId,
          refDate: steps[selectedStepIndex].signalId,
        },
      },
    });
  }, [emitEvent, steps, selectedStepIndex, funnelId]);
  const simulationTableData = useMemo(() => {
    const conversionFromPrev = model.conversionFromPrev[selectedStepIndex];
    return [
      {
        change,
        conversionRate: conversionFromPrev,
        newConversionRate: conversionFromPrev + change * conversionFromPrev,
        maxChange: model.maxChange[selectedStepIndex],
        projectedFunnelCompletion: model.projectedFunnelCompletion,
        projectedFunnelCompletionUpper: model.projectedFunnelCompletionUpper,
        projectedFunnelCompletionLower: model.projectedFunnelCompletionLower,
        funnelCompletion: model.funnelCompletion,
        potential: model.projectedFunnelCompletion,
        maxPotential: model.maxPotential,
      },
    ];
  }, [model, change, selectedStepIndex]);
  const simulationTableColumns: FlexibleTableColumn<any>[] = useMemo(
    () => [
      {
        key: 'step',
        title: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.STEP.LABEL
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.STEP.HELPER_TEXT
        ),
        weight: 1.5,
        sortable: false,
        render: i => (
          <Select
            value={selectedStepIndex}
            onChange={onChangeStepIndex}
            options={{options: stepOptions}}
            className={classes.Select}
            dropdownButtonClassName={classes.DropdownButton}
            capitalize={false}
            clearable={false}
            sortValues={false}
            searchable={stepOptions.length > 5}
          />
        ),
      },
      {
        key: 'conversionRate',
        title: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.CONVERSION_RATE
            .LABEL
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.CONVERSION_RATE
            .HELPER_TEXT
        ),
        weight: 0.7,
        sortable: false,
        render: item => <div>{number2k(item.conversionRate * 100)}%</div>,
      },
      {
        key: 'change',
        title: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.CHANGE.LABEL
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.CHANGE
            .HELPER_TEXT
        ),
        weight: 1.5,
        sortable: false,
        render: item => {
          return (
            <SimulatorSlider
              min={0}
              max={1000}
              displayValue={item.newConversionRate * 100}
              value={change * 100}
              defaultValue={DEFAULT_CHANGE * 100}
              allowedMax={item.maxChange * 100}
              onChange={v => setChange(v !== null ? v / 100 : null)}
              onChangeCommitted={v => onDragComplete(exists(v) ? v / 100 : null)}
              suffix={'%'}
              sign={'+'}
              disabled={item.maxChange === 0}
              allowedMaxHelper={t(
                TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SLIDER_MAX_HELPER
              )}
            />
          );
        },
      },
      {
        key: 'potential',
        title: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.POTENTIAL.LABEL
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.POTENTIAL
            .HELPER_TEXT
        ),
        width: '18rem',
        sortable: false,
        render: item => (
          <GradientLabelDisplay
            value={item.projectedFunnelCompletion * 100}
            valueLower={item.projectedFunnelCompletionLower * 100}
            valueUpper={item.projectedFunnelCompletionUpper * 100}
            min={item.funnelCompletion * 100}
            max={item.maxPotential * 100}
            tooltipLabel={t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CONFIDENCE_INTERVAL
            )}
            suffix={'%'}
            showDiff
          />
        ),
      },
      {
        key: 'actions',
        title: '',
        width: '20rem',
        align: 'right',
        hidden: !funnelId,
        render: () => (
          <Button
            className={classes.ImproveAdoptionButton}
            onClick={onFollowUp}
            size={'large'}
            icon={RocketLaunchLightIcon}
          >
            {t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_SIMULATION.ACTIONS.HOW_TO_IMPROVE)}
          </Button>
        ),
      },
    ],
    [stepOptions, selectedStepIndex, change, model, onChangeStepIndex, t]
  );
  const funnelFigure = useMemo(() => {
    const labels = steps.map(s => s.label);
    return {
      labels,
      type: DocumentElementType.CHART,
      chartType: ChartType.FUNNEL,
      data: [
        {
          id: 'average',
          label: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_FUNNEL_CHART.AVERAGE.LABEL
          ),
          data: data.funnelData,
        },
        {
          id: 'projected',
          label: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_FUNNEL_CHART.PROJECTED.LABEL
          ),
          data: model.projectedUsers,
        },
      ],
      options: {
        mode: FunnelChartMode.TOTALS,
      },
    };
  }, [steps, data.funnelData, model.projectedUsers]);
  const projectedConversionTableProps = useMemo(() => {
    const baseLabels = ['Start'];
    const stepsRange = range(2, steps.length);
    for (const i of stepsRange) {
      baseLabels.push(`Step ${i}`);
    }
    const labels = [];
    for (let i = 0; i < baseLabels.length - 1; i++) {
      labels.push(`${baseLabels[i]} → ${baseLabels[i + 1]}`);
    }

    const avgValues = take(model.conversionFromStart, model.conversionFromStart.length - 1).map(
      i => i * 100
    );
    const avgAbsValues = take(takeRight(model.users, model.users.length - 1), steps.length - 2);
    const simulatedValues = take(
      model.projectedConversionFromStart,
      model.projectedConversionFromStart.length - 1
    ).map((i, idx) => (idx >= selectedStepIndex ? i * 100 : null));
    const simulatedAbsValues = take(
      takeRight(model.projectedUsers, model.projectedUsers.length - 1),
      steps.length - 2
    );

    const datasets = [
      {
        key: 'avg',
        name: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_POTENTIAL_TABLE
            .AVG_VALUES_LABEL
        ),
        values: avgValues,
        absValues: avgAbsValues,
        mainValue: model.funnelCompletion * 100,
        mainValueAbs: last(model.users),
      },
      {
        key: 'simulated',
        name: t(
          TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_POTENTIAL_TABLE
            .PROJECTED_RETENTION_LABEL
        ),
        showDiffFrom: 'avg',
        values: simulatedValues,
        absValues: simulatedAbsValues,
        mainValue: model.projectedFunnelCompletion * 100,
        mainValueAbs: last(model.projectedUsers),
        highlight: true,
        icon: LoopsIcon,
      },
    ];
    return {
      labels,
      datasets,
      title: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_POTENTIAL_TABLE.TITLE),
      allowSwitchAbs: true,
      switchValuesLabel: t(
        TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_POTENTIAL_TABLE
          .SWITCH_VALUES_LABEL
      ),
      switchAbsLabel: t(
        TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_POTENTIAL_TABLE.SWITCH_ABS_LABEL,
        {entity: pluralize(options.entity)}
      ),
      mainValueLabel: t(
        TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.SIMULATOR_POTENTIAL_TABLE.COMPLETION_LABEL
      ),
    };
  }, [potentialLifts, model, t]);

  useEffect(() => {
    if (change > model.maxChange[selectedStepIndex]) {
      setChange(model.maxChange[selectedStepIndex]);
    }
  }, [change, selectedStepIndex, model.maxChange]);

  return (
    <div className={classNames(classes.FunnelOverviewSimulatorMode, className)}>
      <FlexibleTable
        className={classes.Table}
        columns={simulationTableColumns}
        data={simulationTableData}
      />
      <ChildRenderer children_={funnelFigure} key={'funnel-chart'} className={classes.Chart} />
      <MatrixTable {...projectedConversionTableProps} />
    </div>
  );
};

FunnelOverviewSimulatorMode.defaultProps = {};
