import {TableEntity} from '../models/table.model';
import {
  MetricSeriesSeriesType,
  MonitoredMetricType,
  SegmentMetricType,
} from '../models/health-monitor.model';
import yup from '../../config/yup.config';
import {values} from 'lodash';

import {querySchemaValidator} from './query-builder.dto.ts';

export interface HealthMonitorGroupDTO {
  id?: number;
  metricHealthMonitorId: number;
  name: string;
}

export interface RemoveHealthMonitorGroupDTO {
  metricHealthMonitorId: number;
  metricSeriesGroupId?: number;
}

export interface HealthMonitorInitializeDTO {
  metricHealthMonitorId: number;
  monitoredMetricIds: number[];
}

export interface MonitoredMetricDTO {
  id?: number;
  name: string;
  metricType: MonitoredMetricType;
  shortDescription: string;
  analysisTypeId: number;
  metricSeriesGroupId: number;
  metricHealthMonitorId: number;
  parameters: any;
  analysisParameters: any;
  isNorthStar: boolean;
  entity: TableEntity;
}

const PARAMETERS_VALIDATOR = {
  [MonitoredMetricType.KPI]: yup
    .object()
    .shape({
      goal: yup.number().required(),
      metric_id: yup.number().required(),
      analysis_mode: yup.string().nullable(),
      population_filter: querySchemaValidator(false),
    })
    .required(),
  [MonitoredMetricType.FUNNEL]: yup
    .object()
    .shape({
      series_type: yup.string().oneOf(values(MetricSeriesSeriesType)).required(),
      start_step: yup
        .number()
        .min(0)
        .when('series_type', {
          is: v =>
            [MetricSeriesSeriesType.RATE, MetricSeriesSeriesType.TIME_BETWEEN].indexOf(v) > -1,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable(),
        }),
      end_step: yup.number().when('series_type', {
        is: v => v === MetricSeriesSeriesType.NUMBER,
        then: schema => schema.min(0).required(),
        otherwise: schema => schema.min(1).required(),
      }),
      funnel: yup.number().required(),
      population_filter: querySchemaValidator(false),
    })
    .required(),
  [MonitoredMetricType.SEGMENT]: yup
    .object()
    .shape({
      signal_id: yup.number().required(),
      segment_id: yup.number().required(),
      class_value: yup.mixed().required(),
      type: yup.string().oneOf(values(SegmentMetricType)).required(),
      population_filter: querySchemaValidator(false),
    })
    .required(),
};

export const monitoredMetricDTOValidator = yup.lazy(obj =>
  yup.object().shape({
    entity: yup.string().oneOf(values(TableEntity)).required(),
    metricType: yup.string().oneOf(values(MonitoredMetricType)).required(),
    analysisTypeId: yup.number().required(),
    parameters: PARAMETERS_VALIDATOR[obj.metricType],
  })
);

export const healthMonitorGroupDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup.string().required(),
  metricHealthMonitorId: yup.number().required(),
  order: yup.number().nullable(),
});
