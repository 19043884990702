import * as React from 'react';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

interface OwnProps {
  trendingUp: boolean;
  higherIsBetter?: boolean;
  positiveColor?: string;
  negativeColor?: string;
  className?: string;
}

type AllProps = OwnProps;

export const TrendingIcon: React.FC<AllProps> = (props: AllProps) =>
  props.trendingUp ? (
    <TrendingUpIcon
      className={props.className}
      style={{
        color: props.higherIsBetter ? props.positiveColor : props.negativeColor,
      }}
    />
  ) : (
    <TrendingDownIcon
      className={props.className}
      style={{
        color: props.higherIsBetter ? props.negativeColor : props.positiveColor,
      }}
    />
  );

TrendingIcon.defaultProps = {
  positiveColor: '#62E797',
  negativeColor: '#FF6871',
  higherIsBetter: true,
};
