import classes from './power-users-calculation-modal.module.scss';
import {PowerUsersData} from '../../../../../../types';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {
  PowerUsersChart,
  PowerUsersDataMode,
} from '../power-users-chart/power-users-chart.component';

interface OwnProps {
  isOpen: boolean;
  toggleOpen: () => void;
  items: PowerUsersData[];
  powerN: number;
  powerPercentile: number;
  signalName: string;
  isValuesWeightsAveraged: boolean;
}

type AllProps = OwnProps;

export const PowerUsersCalculationModal: React.FC<AllProps> = (props: AllProps) => {
  const {items, isOpen, toggleOpen, powerN, powerPercentile, signalName, isValuesWeightsAveraged} =
    props;
  const {t} = useDocumentTranslation();

  return (
    <ModalWrapper isOpen={isOpen} onClose={toggleOpen} width={'100rem'} height={'52rem'}>
      <div className={classes.Header}>
        {t(TransKeys.DOCUMENT_VIEWER.POWER_USERS_OVERVIEW_FIGURE.CALCULATION_MODAL.TITLE)}
      </div>
      <div className={classes.Body}>
        <PowerUsersChart
          dataMode={PowerUsersDataMode.CUMULATIVE}
          items={items}
          signalName={signalName}
          powerN={powerN}
          powerPercentile={powerPercentile}
          isValuesWeightsAveraged={isValuesWeightsAveraged}
        />
      </div>
    </ModalWrapper>
  );
};
