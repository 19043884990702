import * as React from 'react';
import {NumericFunction, NumericFunctionType, SqlElement} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {queryBuilderClasses} from '../query-builder.component';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {useMemo} from 'react';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  CopyIcon,
} from '../../../simple/controls/icons/icons.component';
import {useQueryArray, useQueryElement} from '../query-builder.hooks';
import {Select} from '../../../forms/inputs/select/select.component';
import {values, get} from 'lodash';
import {NumericFunctionTypeName} from '../query-builder.config';

interface OwnProps {
  data: NumericFunction;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const NumericFunctionBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled} = props;
  const {localErrors, actions} = useQueryElement(props);
  const elements = useMemo(() => data.elements || [], [data]);
  const {addElement, cloneElement, moveElement, removeElement} = useQueryArray(elements, data =>
    onChange(concatPath(path, 'elements'), data)
  );
  const options = useMemo(
    () =>
      values(NumericFunctionType).map(k => ({
        label: NumericFunctionTypeName[k],
        value: k,
      })),
    []
  );

  const renderElement = (e: SqlElement, idx: number) => {
    const actions = [
      {
        icon: ArrowUpIcon,
        label: 'Up',
        onClick: () => moveElement(idx, idx - 1),
        hidden: idx === 0,
      },
      {
        icon: ArrowDownIcon,
        label: 'Down',
        onClick: () => moveElement(idx, idx + 1),
        hidden: idx === elements.length - 1,
      },
      {
        icon: CopyIcon,
        label: 'Duplicate',
        onClick: () => cloneElement(idx),
      },
    ];

    return (
      <QueryElementBuilder
        key={idx}
        error={Boolean(get(localErrors, `elements.${idx}`))}
        path={concatPath(path, `elements.${idx}`)}
        actions={[
          ...actions,
          {
            icon: CloseIcon,
            label: 'Remove',
            onClick: () => removeElement(idx),
          },
        ]}
        data={e as any}
      />
    );
  };

  return (
    <QueryTree
      label={'Numeric Function'}
      disabled={disabled}
      className={className}
      headerNext={
        <Select
          className={queryBuilderClasses.Inline}
          placeholder={'Function'}
          value={data.function}
          error={Boolean(localErrors.function)}
          onChange={v => onChange(concatPath(path, 'function'), v)}
          searchable={false}
          clearable={false}
          options={{options}}
          disabled={disabled}
        />
      }
      actions={[
        {
          icon: AddIcon,
          label: 'Add',
          onClick: addElement,
        },
        ...actions,
      ]}
    >
      {data.elements.map((e, idx) => renderElement(e, idx))}
    </QueryTree>
  );
};
