import {exists} from 'front-core';

const EXPIRY_MAP_LOCAL_STORAGE_KEY = 'expiry-map';

export class LocalStorageManager {
  static getItem(key: string, defaultValue = undefined) {
    try {
      if (this.isExpired(key)) {
        return this.removeItem(key);
      }

      const v = JSON.parse(localStorage.getItem(key));
      return exists(v) ? v : defaultValue;
    } catch (error) {
      console.error(error);
      return defaultValue;
    }
  }

  static setItem(key: string, value: any, expiryMilliseconds?: number) {
    try {
      if (expiryMilliseconds) {
        const expiryMap = this.getItem(EXPIRY_MAP_LOCAL_STORAGE_KEY, {});
        expiryMap[key] = new Date().getTime() + expiryMilliseconds;
        this.setItem(EXPIRY_MAP_LOCAL_STORAGE_KEY, expiryMap);
      }

      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  }

  static removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  }

  private static isExpired(key: string) {
    const expiryMap = JSON.parse(localStorage.getItem(key)) || {};
    const expiry = expiryMap[key];
    return expiry && new Date().getTime() > expiry;
  }
}
