import * as React from 'react';
import {OptionsPlacer, OptionsPlacerAlignment} from '../../../types';
import classes from './options-placer-viewer.module.scss';
import classNames from 'classnames';
import {ChildRenderer} from '../../core/child-renderer.component';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {get, groupBy, isArray, isObject, pick, sortBy, values} from 'lodash';

export interface OptionsPlacerItem {
  elementId: string;
  renderer: () => JSX.Element;
  align?: OptionsPlacerAlignment;
}

export interface IOptionsPlacerContext {
  addOptions: (options: OptionsPlacerItem[]) => void;
  removeOptions: (keys: string[]) => void;
}

const DEFAULT_CONTEXT_VALUE: IOptionsPlacerContext = {
  addOptions: undefined,
  removeOptions: undefined,
};
export const OptionsPlacerContext = createContext<IOptionsPlacerContext>(DEFAULT_CONTEXT_VALUE);

export interface OwnProps extends OptionsPlacer {
  className?: string;
}

interface OptionsStateItem extends OptionsPlacerItem {
  order: number;
}

interface OptionsState {
  [key: string]: OptionsStateItem;
}

export const useOptionsPlacer = (options: OptionsPlacerItem[]) => {
  const {addOptions, removeOptions} = useContext(OptionsPlacerContext);

  useEffect(() => {
    if (!addOptions) {
      return;
    }
    addOptions(options);
    return () => {
      removeOptions(options.map(o => o.elementId));
    };
  }, [options, addOptions, removeOptions]);

  return {isActive: Boolean(addOptions)};
};

const calcOrderForElement = (item: any, id: string, currentIdx = 0) => {
  if (get(item, 'id') === id) {
    return currentIdx;
  }

  if (isArray(item)) {
    for (const i of item) {
      const res = calcOrderForElement(i, id, currentIdx + 1);
      if (res !== undefined) {
        return res;
      }
    }
    return;
  }

  if (isObject(item)) {
    const itemChildren = pick(item, ['children', 'data']);
    return calcOrderForElement(values(itemChildren), id, currentIdx + 1);
  }
};

export const OptionsPlacerViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {children} = props;
  const [options, setOptions] = useState<OptionsState>({});
  const removeOptions = useCallback(
    (keys: string[]) => {
      setOptions(options => {
        const newOptions = {...options};
        for (const k of keys) {
          delete newOptions[k];
        }
        return newOptions;
      });
    },
    [setOptions]
  );
  const addOptions = useCallback(
    (options: OptionsPlacerItem[]) => {
      const addedOptionsMap = {};
      for (const o of options) {
        addedOptionsMap[o.elementId] = {
          ...o,
          order: calcOrderForElement(children, o.elementId),
          align: o.align || 'left',
        };
      }
      setOptions(options => ({...options, ...addedOptionsMap}));
    },
    [setOptions, children]
  );
  const contextValue = useMemo(() => ({addOptions, removeOptions}), [addOptions, removeOptions]);
  const orderedOptions = useMemo(
    () =>
      groupBy(
        sortBy(values(options), o => o.order),
        'align'
      ),
    [options]
  );

  return (
    <div className={classNames(classes.OptionsPlacer, props.className)}>
      <div className={classes.Options}>
        <div className={classes.Left}>
          {(orderedOptions['left'] || []).map(o => (
            <div className={classes.Option} key={o.elementId}>
              {o.renderer()}
            </div>
          ))}
        </div>
        <div className={classes.Right}>
          {(orderedOptions['right'] || []).map(o => (
            <div className={classes.Option} key={o.elementId}>
              {o.renderer()}
            </div>
          ))}
        </div>
      </div>
      <OptionsPlacerContext.Provider value={contextValue}>
        <ChildRenderer children_={children} />
      </OptionsPlacerContext.Provider>
    </div>
  );
};
