import {useCallback, useContext, useMemo} from 'react';
import TransKeys from 'translations';
import {ExperimentValidation, ExperimentValidationStatus} from '../../../../../types/figures.types';
import {
  CircleCheckSolidIcon,
  CircleXmarkSolidIcon,
  TriangleExclamationSolidIcon,
} from '../../../../../../../simple/controls/icons/icons.component';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';
import {groupBy} from 'lodash';
import {DocumentCommand} from '../../../../../types/commands.types';

export const ValidationsIconMap = {
  [ExperimentValidationStatus.ERROR]: CircleXmarkSolidIcon,
  [ExperimentValidationStatus.WARNING]: TriangleExclamationSolidIcon,
  [ExperimentValidationStatus.SUCCESS]: CircleCheckSolidIcon,
};

export const useExperimentValidations = (validations: ExperimentValidation[]) => {
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const onValidationDeepDive = useCallback(
    (command: DocumentCommand<any>) => emitEvent(command),
    [emitEvent]
  );
  const attrs = useMemo(() => {
    if (!validations || validations.length == 0) {
      return {};
    }
    const groupByStatus = groupBy(validations, 'status');
    const errorsCount = groupByStatus[ExperimentValidationStatus.ERROR]?.length || 0;
    const warningsCount = groupByStatus[ExperimentValidationStatus.WARNING]?.length || 0;
    const successCount = groupByStatus[ExperimentValidationStatus.SUCCESS]?.length || 0;
    let validationsStatus = ExperimentValidationStatus.SUCCESS;
    if (warningsCount > 0) {
      validationsStatus = ExperimentValidationStatus.WARNING;
    }
    if (errorsCount > 0) {
      validationsStatus = ExperimentValidationStatus.ERROR;
    }
    const validationsSummary = t(
      TransKeys.DOCUMENT_VIEWER.AB_TEST_CONCLUSION_FIGURE.VALIDATIONS.PASSED_TESTS[
        validationsStatus
      ],
      {
        errorsCount,
        warningsCount,
        successCount,
        overallCount: validations.length,
      }
    );
    const ValidationsIcon = ValidationsIconMap[validationsStatus];
    return {
      validationsStatus,
      validationsSummary,
      ValidationsIcon,
    };
  }, [t, validations]);

  return {
    onValidationDeepDive,
    ...attrs,
  };
};
