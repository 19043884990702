import classNames from 'classnames';
import classes from './correlation-chip.module.scss';
import {useMemo} from 'react';
import {
  getCorrelationBackgroundColor,
  getCorrelationTextColor,
  correlationToText,
} from '../../kpi-tree.utils.ts';

interface Props {
  correlation: number;
  isSignificant?: boolean;
  className?: string;
}

export const CorrelationChip = (props: Props) => {
  const {correlation, isSignificant, className} = props;

  const correlationText = useMemo(() => correlationToText(correlation), [correlation]);
  const bgColor = useMemo(
    () => getCorrelationBackgroundColor(correlation, isSignificant),
    [correlation, isSignificant]
  );
  const textColor = useMemo(
    () => getCorrelationTextColor(correlation, isSignificant),
    [isSignificant, correlation]
  );

  return (
    <div
      className={classNames(classes.CorrelationChip, className)}
      style={{backgroundColor: bgColor, color: textColor}}
    >
      {correlationText}
    </div>
  );
};
