import {useCallback, useMemo} from 'react';
import {SignalSmartSelectorKey} from '../../smart-selector/signal-smart-selector.component';
import {SelectorModelType} from '../../smart-selector/advanced-smart-selector.component';
import {TemplateItemQueryBuilder} from '../template-item-query-builder/template-item-query-builder.component';
import {
  TableEntity,
  TableEntityBinding,
  TableType,
} from '../../../../../objects/models/table.model';
import {LiteralValueType} from 'ui-components';
import {SignalType, SignalDataType} from '../../../../../objects/models/signal.model';
import {
  PARAMETERS_METADATA_KEY,
  METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import classes from './usage-query-builder.module.scss';
import {LabelWrapper} from 'ui-components';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface OwnProps {
  onChange: (value: any) => void;
  query: any;
  filters?: any;
  errors?: any;
  clearable?: boolean;
  disabled?: boolean;
  onSignalInfo?: (value: string | number) => void;
  allowTypes?: SelectorModelType[];
  entityContext?: TableEntity;
  columnFilters?: any;
  eventFilters?: any;
  className?: string;
}

type AllProps = OwnProps;

const INCLUDE_SELECTOR = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FUNNELS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.CONTENTS,
  SignalSmartSelectorKey.SIGNALS,
];

export const UsageQueryBuilder = (props: AllProps) => {
  const {
    errors,
    onChange: onChangeFromProps,
    onSignalInfo,
    filters,
    query,
    clearable,
    disabled,
    allowTypes,
    columnFilters,
    eventFilters,
    entityContext,
    className,
  } = props;
  const {t} = useTranslation();

  const onChange = useCallback(
    newQuery => {
      if (newQuery) {
        newQuery[PARAMETERS_METADATA_KEY][METADATA_KEY.BUILDER_COMPONENT_NAME_KEY] =
          'UsageQueryBuilder';
      }
      onChangeFromProps && onChangeFromProps(newQuery);
    },
    [onChangeFromProps]
  );

  const signalFilters_ = useMemo(
    () => ({
      entityContext,
      ...filters,
      type: [SignalType.MEASURE],
      dataType: [SignalDataType.INTEGER, SignalDataType.DECIMAL, SignalDataType.BOOLEAN],
    }),
    [filters, entityContext]
  );

  const columnFilters_ = useMemo(
    () => ({
      entityContext,
      tableType: [TableType.STATE, TableType.EVENTS], // Default values
      ...columnFilters,
      literalType: [LiteralValueType.INTEGER, LiteralValueType.FLOAT, LiteralValueType.BOOLEAN],
    }),
    [columnFilters, entityContext]
  );

  const eventFilters_ = useMemo(
    () => ({
      entityBinding: TableEntityBinding.TWO_WAY,
      entityContext,
      ...eventFilters,
    }),
    [eventFilters, entityContext]
  );
  return (
    <div className={classes.Container}>
      <LabelWrapper label={t(TransKeys.QUERY_BUILDERS.USAGE_QUERY_BUILDER.LABEL)}>
        <TemplateItemQueryBuilder
          className={className}
          query={query}
          onChange={onChange}
          errors={errors}
          signalFilters={signalFilters_}
          signalsInclude={INCLUDE_SELECTOR}
          disabled={disabled}
          clearable={clearable}
          allowTypes={allowTypes}
          columnFilters={columnFilters_}
          eventFilters={eventFilters_}
          onSignalInfo={onSignalInfo}
        />
      </LabelWrapper>
    </div>
  );
};
