import * as React from 'react';
import classNames from 'classnames';
import classes from './toggle-open-button.module.scss';
import {ChevronDownIcon} from '../icons/icons.component';

interface Props {
  closedLabel: string;
  openLabel?: string;
  onClick: () => void;
  isOpen?: boolean;
  className?: string;
}

export const ToggleOpenButton: React.FC<Props> = (props: Props) => {
  const {openLabel, closedLabel, onClick, isOpen, className} = props;

  return (
    <div
      onClick={onClick}
      className={classNames(classes.ToggleOpenButton, isOpen && classes.Open, className)}
    >
      <span>{isOpen ? openLabel : closedLabel}</span>
      <ChevronDownIcon className={classes.Icon} />
    </div>
  );
};
