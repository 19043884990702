// chroma.js
// https://stackoverflow.com/questions/2049230/convert-rgba-color-to-rgb
import chroma from 'chroma-js';
import Rainbow from 'rainbowvis.js';

export const colorAlphaTransformer = (
  color: string,
  alpha: number,
  asRealColor: boolean = false,
  backColor = '#ffffff',
  hex = false
) => {
  const color_ = chroma(color);
  const backColor_ = chroma(backColor);

  const rF = color_.get('rgb.r'),
    gF = color_.get('rgb.g'),
    bF = color_.get('rgb.b');

  let rB = backColor_.get('rgb.r'),
    gB = backColor_.get('rgb.g'),
    bB = backColor_.get('rgb.b');

  if (alpha && asRealColor) {
    rB = (1 - alpha) * rB + alpha * rF;
    gB = (1 - alpha) * gB + alpha * gF;
    bB = (1 - alpha) * bB + alpha * bF;
    return hex ? chroma([rB, gB, bB]).hex() : 'rgb(' + rB + ', ' + gB + ', ' + bB + ')';
  }
  if (alpha) {
    return hex
      ? chroma([rB, gB, bB, alpha]).hex()
      : 'rgba(' + rF + ', ' + gF + ', ' + bF + ', ' + alpha + ')';
  } else {
    return hex ? chroma([rB, gB, bB]).hex() : 'rgb(' + rF + ', ' + gF + ', ' + bF + ')';
  }
};

export const getColorGradientAt = (startColor: string, endColor: string, position: number) => {
  const colorGradient = new Rainbow();
  colorGradient.setSpectrum(startColor, endColor);
  return `#${colorGradient.colourAt(position)}`;
};
