import * as React from 'react';
import classNames from 'classnames';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists} from 'front-core';
import {useMemo} from 'react';
import {isNumber, max, min} from 'lodash';
import Rainbow from 'rainbowvis.js';
import classes from './data-columns.module.scss';
import {ColorUtil} from '../../../../../document-viewer.utils';
import {GradientColumnOptions} from '../../../../../types';
import {DataColumnProps} from '../../smart-table.types';

interface OwnProps extends DataColumnProps<GradientColumnOptions> {}

type AllProps = OwnProps;

const GOOD_COLOR = ColorUtil.getColor('good');
const BAD_COLOR = ColorUtil.getColor('bad');
const NEUTRAL_COLOR = ColorUtil.getColor('muted');

const GradientDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, dataColumnOptions, className} = props;

  const value = useMemo(() => data[column.key], [column.key, data]);
  const higherIsBetter = useMemo(
    () => (exists(column.typeOptions.higherIsBetter) ? column.typeOptions.higherIsBetter : true),
    [column]
  );
  const unit = useMemo(
    () => dataColumnOptions?.unit || column.options.unit,
    [column.options.unit, dataColumnOptions]
  );
  const minValue = useMemo(
    () =>
      exists(column.typeOptions.min) ? column.typeOptions.min : min([column.metadata.minValue, 0]),
    [column]
  );
  const maxValue = useMemo(
    () =>
      exists(column.typeOptions.max) ? column.typeOptions.max : max([column.metadata.maxValue, 0]),
    [column]
  );
  const zeroValue = useMemo(
    () => (exists(column.typeOptions.zero) ? column.typeOptions.zero : null),
    [column]
  );
  const color = useMemo(() => {
    const itemValue = data[column.key];
    if (!exists(itemValue)) {
      return NEUTRAL_COLOR;
    }
    const colorGradient = new Rainbow();
    let totalRange = Math.abs(minValue - maxValue);

    let localBadColor = BAD_COLOR;
    let localGoodColor = GOOD_COLOR;
    if (higherIsBetter === false) {
      localBadColor = GOOD_COLOR;
      localGoodColor = BAD_COLOR;
    }

    if (!exists(zeroValue)) {
      colorGradient.setSpectrum(localBadColor, localGoodColor);
    } else if (itemValue >= zeroValue) {
      colorGradient.setSpectrum(NEUTRAL_COLOR, localGoodColor);
      totalRange = Math.abs(zeroValue - maxValue);
    } else if (itemValue < zeroValue) {
      colorGradient.setSpectrum(localBadColor, NEUTRAL_COLOR);
      totalRange = Math.abs(zeroValue - minValue);
    }
    if (totalRange === 0) {
      return NEUTRAL_COLOR;
    }
    const position = (itemValue - minValue) / totalRange;
    if (!exists(position)) {
      return NEUTRAL_COLOR;
    }
    try {
      return `#${colorGradient.colourAt(position * 100)}`;
    } catch (e) {
      return NEUTRAL_COLOR;
    }
  }, [column.key, data, higherIsBetter, maxValue, minValue, zeroValue]);

  return (
    <div
      className={classNames(
        classes.GradientDataColumn,
        column.options.align === 'right' && classes.AlignRight,
        className
      )}
    >
      <div className={classes.Gradient} style={{backgroundColor: color}} />
      <div className={classes.Value}>
        {value.toLocaleString()}
        {unit ? unit : ''}
      </div>
    </div>
  );
};

const protector: Protector = (props: OwnProps) =>
  exists(props.data[props.column.key]) && isNumber(props.data[props.column.key]);
export const GradientDataColumn = withDataColumnProtector(protector)(GradientDataColumnComponent);
