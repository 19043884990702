import * as React from 'react';
import classNames from 'classnames';
import {NumericColumnOptions} from '../../../../../types';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists} from 'front-core';
import {useMemo} from 'react';
import classes from './data-columns.module.scss';
import tableClasses from '../../smart-table.module.scss';
import {get} from 'lodash';
import {getDataValueKey} from '../../smart-table.utils';
import {DataColumnProps} from '../../smart-table.types';
import {DocumentIcon} from '../../../../internal-viewers/document-icon.component';
import {useHelperTextDataKey} from '../../hooks/use-helper-text-data-key.hook';

interface OwnProps extends DataColumnProps<NumericColumnOptions> {}

type AllProps = OwnProps;

const NumericDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, dataColumnOptions, data, className} = props;
  const {upperDataKey, lowerDataKey, icon} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);
  const unit = useMemo(
    () => dataColumnOptions?.unit || column.options.unit,
    [column.options.unit, dataColumnOptions]
  );
  const value = useMemo(() => get(data, valueDataKey), [valueDataKey, data]);
  const upper = useMemo(() => get(data, upperDataKey), [data, upperDataKey]);
  const lower = useMemo(() => get(data, lowerDataKey), [data, lowerDataKey]);
  const hasCI = useMemo(() => exists(upper) && exists(lower), [lower, upper]);

  return (
    <div className={classNames(classes.NumericDataColumn, className)}>
      {icon && <DocumentIcon icon={icon} className={classes.Icon} />}
      <span className={classes.Value}>
        {value.toLocaleString()}
        {unit ? unit : ''}
      </span>
      {hasCI && (
        <span className={classNames(classes.CI, tableClasses.ShowOnHover)}>
          ({lower.toLocaleString()}
          {unit ? unit : ''}, {upper.toLocaleString()}
          {unit ? unit : ''})
        </span>
      )}
    </div>
  );
};

const protector: Protector = (props: OwnProps) =>
  exists(props.data[props.column.key]) || exists(props.data[props.column.typeOptions.valueDataKey]);

export const NumericDataColumn = withDataColumnProtector(protector)(NumericDataColumnComponent);
