import classes from '../rca-figure-viewer.module.scss';
import {RCAElements} from '../../../../../types';
import {OVERVIEW_ID, useNavbarElements} from './use-navbar-elements.hook';
import {SideMenu} from '../../../../../../../simple/controls/side-menu/side-menu.component';
import {scrollRCAElementIntoView} from '../rca-figure-viewer.utils';

export type RCAFigureNavbarProps = {
  rcaElements: RCAElements;
};

export const RCAFigureNavbar = (props: RCAFigureNavbarProps) => {
  const {rcaElements} = props;

  const navItems = useNavbarElements({rcaElements});

  return (
    <div className={classes.Nav} id={OVERVIEW_ID}>
      <SideMenu className={classes.SideMenu} items={navItems} onSelect={scrollRCAElementIntoView} />
    </div>
  );
};
