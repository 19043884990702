import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import moment from 'moment/moment';
import {
  confoundersValadator,
  startEndDatesValidator,
  TIME_UNIT_OPTIONS,
  treatmentValidator,
  validateSignalTimeframeBoundingFor,
} from '../shared-validators';
import {QUERY_GOAL_KEY} from '../analysis-101/analysis-101-form.component';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
  confoundersAnalysisParametersTransformer,
} from '../shared-transformers';
import {growthbook} from '../../../../../config/growthbook.config';
import {FeatureFlag} from '../../../../../constants/feature-flags';

import {querySchemaValidator} from '../../../../../objects/dto/query-builder.dto.ts';

export const analysis67ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal_query: querySchemaValidator(),
      max_window: yup.number().required(),
      minimum_adoption_significance: yup.number().required(),
      max_window_unit: yup
        .string()
        .oneOf(TIME_UNIT_OPTIONS)
        .required()
        .test(
          'is_less_then_goal_query',
          'Habit moment is the short-term behavior of the user that predicts the retention goal. Therefore, habit moment period shall ends before the retention goal bound.',
          function (max_window_unit: string) {
            const {goal_query, max_window} = this.parent;
            if (!goal_query) {
              return false;
            }
            const windowEndDate = moment().add(max_window, max_window_unit as any);
            const goalStartDate = moment().add(goal_query.parameters[1], goal_query.parameters[3]);
            return goalStartDate.isSameOrAfter(windowEndDate);
          }
        ),
      ...confoundersValadator,
      population_filter: querySchemaValidator(false),
      ...treatmentValidator('features_tag'),
    })
    .required(),
  runParameters: startEndDatesValidator.required().test(
    validateSignalTimeframeBoundingFor({
      signalQueryParameter: 'goal_query',
      parameterName: 'retention goal',
    })
  ),
});

export const analysis67ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  [values, schema] = confoundersAnalysisParametersTransformer(values, schema);
  const newValues = {...values};

  if (QUERY_GOAL_KEY in schema) {
    schema[QUERY_GOAL_KEY].name = 'Goal Definition';
    schema[QUERY_GOAL_KEY].order = 1;
  }

  delete schema['features_tag'];
  delete schema['treatments_signals'];
  schema['features_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'features_tag',
    order: 3,
  };
  newValues['features_tag'] = [
    ...(newValues['features_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  // todo: check if this still in use, if not - delete!
  if (!growthbook.isOn(FeatureFlag.HABIT_MOMENT_FILTER_USERS_WHO_DID_NOT_DO_TREATMENT)) {
    delete schema['filter_users_who_did_not_do_treatment'];
  }

  if ('max_window_unit' in schema) {
    schema['max_window_unit'] = {
      name: 'Habit moment period',
      key: 'max_window_unit',
      type: ParameterType.STRING,
      order: 4,
    };
    newValues['max_window_unit'] = `${newValues.max_window} ${newValues.max_window_unit}s`;
    delete schema['max_window'];
  }
  if ('minimum_adoption_significance' in schema) {
    schema['minimum_adoption_significance'] = {
      name: 'Minimum adoption rate',
      key: 'minimum_adoption_significance',
      type: ParameterType.NUMBER,
      order: 5,
    };
    newValues['minimum_adoption_significance'] = `${newValues.minimum_adoption_significance}%`;
  }

  delete schema['reference_date'];

  return [newValues, schema];
};
