import {exists, safeDivision} from 'front-core';
import {PowerUsersSegmentationItem} from '../../../../types';
import {ExtendedPowerUsersSegmentationItem} from './power-users-segmentation-viewer.component';

export const extendPowerUsersSegmentationItems = (
  items: PowerUsersSegmentationItem[],
  totalEntities: number,
  valueInNonSegment: number
): ExtendedPowerUsersSegmentationItem[] => {
  let newItems: ExtendedPowerUsersSegmentationItem[] = items as any;

  newItems = newItems.map(i => {
    const share = safeDivision(i.count, totalEntities);
    const uplift = safeDivision(i.valueInSegment - valueInNonSegment, Math.abs(valueInNonSegment));
    const isInformative = exists(i.isInformative) ? i.isInformative : true;
    const isSignificant = exists(i.isSignificant) ? i.isSignificant : true;
    const item = {
      ...i,
      uplift,
      share,
      groupInsignificant: !isSignificant || !isInformative,
      isSignificant,
      isInformative,
      valueInNonSegment,
    };

    return item;
  });

  return newItems;
};
