import {createContext, Dispatch, SetStateAction, useCallback, useMemo} from 'react';
import * as React from 'react';
import {removeUndefinedKeys} from 'front-core';

export interface IAnalysisFigureContext {
  parameters: any;
  setParameters: (parameters: any) => void;
}

export const AnalysisFigureContext = createContext<IAnalysisFigureContext>({
  parameters: undefined,
  setParameters: undefined,
});

interface OwnProps {
  parameters: any;
  setParameters: Dispatch<SetStateAction<any>>;
  children: any;
}

export const AnalysisFigureContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {parameters, setParameters: setParametersFromProps, children} = props;

  const setParameters = useCallback(
    (newParameters: any) => {
      setParametersFromProps(parameters => removeUndefinedKeys({...parameters, ...newParameters}));
    },
    [setParametersFromProps]
  );
  const contextValue = useMemo(
    () => ({
      parameters: parameters,
      setParameters,
    }),
    [parameters, setParameters]
  );

  return (
    <AnalysisFigureContext.Provider value={contextValue}>{children}</AnalysisFigureContext.Provider>
  );
};
