import {useMemo, useState} from 'react';
import {keyBy, some, values} from 'lodash';
import {PopoverWrapper} from '../../../../../simple/generic/popover-wrapper/popover-wrapper.component';
import {DropdownButton} from '../../../../../simple/controls/dropdown-button/dropdown-button.component';
import classNames from 'classnames';
import classes from './composite-segments-viewer.module.scss';
import TransKeys from 'translations';
import {CloseIcon, UserGroupLightIcon} from '../../../../../simple/controls/icons/icons.component';
import {InputButton} from '../../../../../forms/related/input-button/input-button.component';
import {withStopPropagation} from 'front-core';
import {SmartTreeSelector} from '../../../../smart-tree-selector/smart-tree-selector.component';
import * as React from 'react';
import {useDocumentTranslation} from '../../../hooks/use-document-translation.hook';
import {TreeData} from '../../../../smart-tree-selector/tree-selector.component';

interface CompositeSegments {
  segments: Array<{name: string; groupName: string}>;
}

export const SEGMENT_TREE_VALUE_SEPARATOR = '=>';

interface UseCompositeFilterConfig<T extends CompositeSegments> {
  composites: T[];
  filter: string[];
  setFilter: (newFilter: string[]) => void;
  disabled?: boolean;
}

const parseFilters = (filters: string[]) => {
  return filters.map(f => {
    const [groupName, segmentName] = f.split(SEGMENT_TREE_VALUE_SEPARATOR);
    return {
      groupName,
      segmentName,
    };
  });
};

const getCount = (filters: string[], treeData: TreeData[]) => {
  let count = 0;
  const parsedFilters = parseFilters(filters);
  const treeDataMap = keyBy(treeData, 'value');
  for (const p of parsedFilters) {
    if (p.segmentName) {
      count++;
    } else {
      const group = treeDataMap[p.groupName];
      if (group) {
        count += group.children.length;
      }
    }
  }
  return count;
};

export function filterCompositeSegments<T extends CompositeSegments>(
  composites: T[],
  filters: string[]
) {
  if (filters.length === 0) {
    return composites;
  }
  // parsing filters
  const parsedFilters = parseFilters(filters);
  // filtering composites
  return composites.filter(comp => {
    for (const segment of comp.segments) {
      const segmentIsMatching = some(
        parsedFilters.map(f => {
          return (
            segment.groupName === f.groupName &&
            (f.segmentName ? segment.name === f.segmentName : true)
          );
        })
      );
      if (segmentIsMatching) {
        return true;
      }
    }
    return false;
  });
}

export const useCompositeFilter = (config: UseCompositeFilterConfig<any>) => {
  const {composites, filter, setFilter, disabled} = config;
  const {t} = useDocumentTranslation();

  const treeData: TreeData[] = useMemo(() => {
    const data: any = {};
    for (const comp of composites) {
      for (const segment of comp.segments) {
        if (data[segment.groupName] === undefined) {
          data[segment.groupName] = {
            groupName: segment.groupName,
            segments: new Set(),
          };
        }
        data[segment.groupName].segments.add(segment.name);
      }
    }
    const tree = [];
    for (const group of values(data)) {
      tree.push({
        key: group.groupName,
        value: group.groupName,
        label: group.groupName,
        children: Array.from(group.segments)
          .map((segmentName: string) => ({
            key: segmentName,
            value: `${group.groupName}${SEGMENT_TREE_VALUE_SEPARATOR}${segmentName}`,
            label: segmentName,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
    }
    return tree.sort((a, b) => a.label.localeCompare(b.label));
  }, [composites]);

  const [filteredSegmentCount, setFilteredSegmentCount] = useState<number>(
    filter ? getCount(filter, treeData) : 0
  );

  return {
    compositeFilter: (
      <PopoverWrapper
        key={'segment-filter'}
        popoverDirection={'left'}
        buttonRenderer={props => (
          <DropdownButton
            {...props}
            className={classNames(classes.DropdownButton, classes.Filter)}
            placeholder={t(
              TransKeys.DOCUMENT_VIEWER.SHARED_COMPOSITE_SEGMENTS.SEGMENT_FILTER_PLACEHOLDER
            )}
            icon={UserGroupLightIcon}
            disabled={disabled}
            label={
              filteredSegmentCount
                ? t(TransKeys.DOCUMENT_VIEWER.SHARED_COMPOSITE_SEGMENTS.SEGMENT_VALUE_SELECTED, {
                    count: filteredSegmentCount,
                  })
                : undefined
            }
            actions={
              filter.length > 0 && (
                <InputButton
                  icon={CloseIcon}
                  onClick={withStopPropagation(e => {
                    setFilter([]);
                    setFilteredSegmentCount(0);
                  })}
                />
              )
            }
          />
        )}
      >
        <SmartTreeSelector
          className={classes.DialogLayout}
          groups={[{key: 'segments', name: 'segments', data: treeData}]}
          selectParentValue
          value={filter}
          onChange={(v, originalValue: any) => {
            setFilter(v as any);
            setFilteredSegmentCount(originalValue.length);
          }}
          multi
        />
      </PopoverWrapper>
    ),
  };
};
