import * as React from 'react';
import classNames from 'classnames';
import classes from './direction-arrow.module.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {CSSProperties} from 'react';

interface OwnProps {
  className?: string;
  style?: CSSProperties;
}

type AllProps = OwnProps;

export const DirectionArrow: React.FC<AllProps> = (props: AllProps) => {
  const {className, style} = props;

  return (
    <div style={style} className={classNames(classes.DirectionArrow, className)}>
      <ExpandMoreIcon className={classNames(classes.Arrow, classes.TopArrow)} />
      <ExpandMoreIcon className={classNames(classes.Arrow, classes.BottomArrow)} />
    </div>
  );
};

DirectionArrow.defaultProps = {};
