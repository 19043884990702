import {SeriesDatetimeAttribute} from '../../../../../../../objects/models/model-sample-series.model.ts';
import {LineChart, useRemoteSourceStated} from 'ui-components';
import {getHomepageMetricDataNetworkRequest} from '../../../../../../../http/homepage.network-requests.ts';
import {MetricValueType} from '../../../../../../../objects/models/metric.model.ts';
import {useEffect, useMemo} from 'react';
import {GenericLoading} from '../../../../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import {getDefaultSeries} from '../../../../../../homepage/components/homepage-summary/homepage-summary.utils.ts';
import {TIME_FORMATS} from '../../../../../../../constants/time-formats.ts';
import TransKeys from 'translations';
import {ANNOTATION_ICONS} from '../../../../../../../constants/ui.ts';
import {useTranslation} from 'react-i18next';
import moment from 'moment/moment';
import {useProductData} from '../../../../../../../core/hooks/use-product-data.hook.ts';
import {getMinimumDate} from '../../../../../../../utils/general.utils.ts';

interface Props {
  metricIds: [number, number];
  toDate?: string;
  datetimeAttribute?: SeriesDatetimeAttribute;
  className?: string;
}

export const EdgeChartViewer = (props: Props) => {
  const {metricIds, toDate, datetimeAttribute, className} = props;
  const {t} = useTranslation();
  const {defaultSource} = useProductData();
  const {
    source: data,
    exec: getMetric,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getHomepageMetricDataNetworkRequest,
  });
  const {metrics, annotations = []} = data || {};

  const chartProps = useMemo(() => {
    if (!metrics) {
      return;
    }

    const allDatasets = [];
    for (const [index, metric] of metrics.entries()) {
      const metricSeries = getDefaultSeries(metric);
      const isPercentage = metric.valueType === MetricValueType.PERCENTAGE;
      const values = metricSeries.samples.map(s => ({
        x: s.datetime,
        y: isPercentage ? s.value * 100 : s.value,
        dashed: s.isPartial,
      }));
      allDatasets.push({
        id: `metric_${metric.id}`,
        label: metric.name,
        data: values,
        yAxis: index === 0 ? 'primary' : 'secondary',
      });
    }
    const chartAnnotations = annotations.map(annotation => ({
      ...annotation,
      type: t(TransKeys.ANNOTATION.TYPE[annotation.type.toUpperCase()]),
      icon: ANNOTATION_ICONS[annotation.type],
      textColor: annotation.relatedExperimentId ? 'rgba(52, 131, 255, 1)' : undefined,
    }));

    return {
      datasets: allDatasets,
      options: {
        annotations: chartAnnotations,
        labels: {
          dateFormat: TIME_FORMATS.READABLE_DATE_NO_YEAR,
          type: 'date',
        },
        yLabelSuffix: metrics[0].valueType === MetricValueType.PERCENTAGE ? '%' : undefined,
        secondaryYSuffix: metrics[1].valueType === MetricValueType.PERCENTAGE ? '%' : undefined,
        yAxisMaxTicks: 5,
        minimalXAxisTicks: true,
        showQuarters: true,
        showHideAnnotation: false,
        xLabel: 'Date',
      },
    } as any;
  }, [metrics, annotations, t]);

  useEffect(() => {
    metricIds &&
      getMetric({
        metricIds,
        filters: {
          fromDate: moment
            .utc(
              getMinimumDate([toDate, defaultSource.lastValidDate]),
              TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT
            )
            .subtract(3, 'month')
            .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          toDate: moment
            .utc(toDate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
            .format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          datetimeAttribute: SeriesDatetimeAttribute.DATETIME,
        },
      });
  }, [getMetric, metricIds, toDate, datetimeAttribute, defaultSource.lastValidDate]);

  if (isLoading) {
    return <GenericLoading />;
  }

  if (chartProps) {
    return <LineChart {...chartProps} className={className} />;
  }

  return null;
};
