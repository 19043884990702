import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {
  CommandType,
  FollowUpType,
  LineChartWithFollowUpRCA,
  KPIAnalysisLineChartFollowUpCommandPayload,
} from '../../../../../types';
import {ChildRenderer} from '../../../../core/child-renderer.component';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';
import {OnDatasetPointClickParam} from '../../../../../../charts/charts/line-chart/line-chart-dataset-container.component';
import {useTranslation} from '../../../../../../../../core/translations/use-translation';
import TransKeys from 'translations';
import {isArray} from 'lodash';
import {ChartXYValue} from '../../../../../../charts/chart-data.types';

interface OwnProps extends LineChartWithFollowUpRCA {}

/* DEPRECATED */
type AllProps = OwnProps;
export const LineChartWithFollowUpRCAViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, children} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {t} = useTranslation();

  const onFollowUp = useCallback(
    (param: OnDatasetPointClickParam) => {
      const {point, dataset, additionalProperties} = param;
      if (!point.isDate || point.itemIndex === 0) {
        return null;
      }
      const previousPoint = dataset.data[point.itemIndex - 1] as ChartXYValue;
      const increasedFromPreviousPoint = point.y > previousPoint.y;
      const change = t(
        TransKeys.GENERAL.LABELS[increasedFromPreviousPoint ? 'INCREASED' : 'DECREASED']
      );
      const timeAggregation = additionalProperties.timeUnit;
      let parameters = {
        goal: data.goal,
        fromToLabel: point.label,
        startDateAnomaly: point.x,
        timeAggregation,
        title: t(TransKeys.DOCUMENT_VIEWER.LINE_CHART_WITH_FOLLOW_UP_RCA.PANEL.TITLE, {
          change,
          timeGranularity: t(
            TransKeys.GENERAL.LABELS.TIME_GRANULARITY[timeAggregation.toUpperCase()]
          ),
        }),
      };
      if (data && data.segment && data.segmentClass) {
        parameters['segment'] = data.segment;
        parameters['segmentClass'] = isArray(data.segmentClass)
          ? data.segmentClass
          : [data.segmentClass];
      }
      const payload: KPIAnalysisLineChartFollowUpCommandPayload = {
        type: FollowUpType.RCA_FROM_KPI_ANALYSIS_LINE_CHART,
        parameters,
      };
      emitEvent({
        type: CommandType.FOLLOW_UP,
        payload,
      });
    },
    [data, emitEvent, t]
  );

  const lineChartFigure = useMemo(() => {
    if (!children || children.length === 0) {
      return null;
    }
    const lineChartFigure = children[0];

    lineChartFigure.data.map(ds => {
      const data = ds.data as ChartXYValue[];
      for (const point of data) {
        if (point.dashed) {
          point.clickable = false;
        }
        if (point.markColor && point.markColor === 'green') {
          point.pointTooltipTitle = t(
            TransKeys.DOCUMENT_VIEWER.LINE_CHART_WITH_FOLLOW_UP_RCA.TOOLTIP.INVESTIGATE_RISE_TITLE
          );
        }
        if (point.markColor && point.markColor === 'red') {
          point.pointTooltipTitle = t(
            TransKeys.DOCUMENT_VIEWER.LINE_CHART_WITH_FOLLOW_UP_RCA.TOOLTIP.INVESTIGATE_DROP_TITLE
          );
        }
      }
    });

    return {
      ...lineChartFigure,
      onDatasetPointClick: onFollowUp,
      pointTooltipTitle: t(TransKeys.DOCUMENT_VIEWER.LINE_CHART_WITH_FOLLOW_UP_RCA.TOOLTIP.TITLE),
      pointTooltipCta: t(
        TransKeys.DOCUMENT_VIEWER.LINE_CHART_WITH_FOLLOW_UP_RCA.TOOLTIP.CTA.DEFAULT
      ),
    };
  }, [children, onFollowUp, t]);

  return <ChildRenderer key={'line-chart'} children_={lineChartFigure} />;
};
