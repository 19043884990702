import {useCallback, useContext} from 'react';
import {BaseTreatmentAdoptionSimulationViewer} from './base-treatment-adoption-simulation-viewer/base-treatment-adoption-simulation-viewer.component';
import TransKeys from 'translations';
import {JourneyDriversSimulationFigure} from '../../../../types/figures.types';
import {JourneyDriversScopeViewer} from './journey-drivers-scope/journey-drivers-scope.component';
import {
  FilterEventsMode,
  IEventsFilters,
  JourneyDriversScopeFilters,
} from './journey-drivers-scope/journey-drivers-scope.consts';
import classes from './treatment-adoption-simulation-viewer.module.scss';
import {GlobalDocumentDataContext} from '../../../../contexts/global-document-data/global-document-data.context';

interface OwnProps extends JourneyDriversSimulationFigure {
  className?: string;
  onParametersSubmit?: (filters: JourneyDriversScopeFilters) => void;
}

type AllProps = OwnProps;

export const JourneyDriversSimulatorViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data, options, onParametersSubmit} = props;
  const {analysisParameters: filters} = useContext(GlobalDocumentDataContext);

  const onApplyFilters = useCallback(
    (filters: IEventsFilters) => {
      onParametersSubmit({
        sequence_filter_event_min_adoption: filters.adoptionRange[0],
        sequence_filter_event_max_adoption: filters.adoptionRange[1],
        sequence_filter_included_events:
          filters.eventsFilterMode === FilterEventsMode.INCLUDED
            ? filters.filteredEvents
            : undefined,
        sequence_filter_excluded_events:
          filters.eventsFilterMode === FilterEventsMode.EXCLUDED
            ? filters.filteredEvents
            : undefined,
      });
    },
    [onParametersSubmit]
  );

  return (
    <>
      {data.scope && (
        <JourneyDriversScopeViewer
          figureId={id}
          data={data}
          options={options}
          filters={filters}
          onApplyFilters={onApplyFilters}
          className={classes.JourneyDriversScopeViewer}
        />
      )}
      <BaseTreatmentAdoptionSimulationViewer
        {...props}
        transKeysPrefix={TransKeys.DOCUMENT_VIEWER.JOURNEY_DRIVERS_SIMULATION}
      />
    </>
  );
};

JourneyDriversSimulatorViewer.defaultProps = {};
