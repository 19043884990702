import * as React from 'react';
import classNames from 'classnames';
import {CircleInfoRegularIcon} from '../../../../../../../simple/controls/icons/icons.component';
import classes from './data-columns.module.scss';
import {Tooltip} from '@material-ui/core';
import {useMemo} from 'react';
import {exists} from 'front-core';
import {DataColumnProps} from '../../smart-table.types';

interface OwnProps extends DataColumnProps<void> {}

type AllProps = OwnProps;

export const InfoDataColumn: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;

  const value = useMemo(() => data[column.key], [column.key, data]);
  if (!exists(value)) {
    return null;
  }

  return (
    <div className={classNames(classes.InfoDataColumn, className)}>
      {value && (
        <Tooltip title={value} placement={'top'}>
          <CircleInfoRegularIcon className={classes.Icon} />
        </Tooltip>
      )}
    </div>
  );
};
