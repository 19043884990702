import * as React from 'react';
import classNames from 'classnames';
import classes from './next-prev-navigator.module.scss';
import {ArrowRightThinIcon} from '../../../../../../simple/controls/icons/icons.component';

interface OwnProps {
  className?: string;
  onNextPrev: (isNext?: boolean) => void;
  currentIndex: number;
  maxIndex: number;
  nextLabel?: string;
  prevLabel?: string;
}

type AllProps = OwnProps;

export const NextPrevNavigator: React.FC<AllProps> = (props: AllProps) => {
  const {onNextPrev, currentIndex, maxIndex, nextLabel, prevLabel, className} = props;
  const nextDisabled = currentIndex === maxIndex;
  const prevDisabled = currentIndex === 0;

  return (
    <div className={classNames(classes.NextPrevNavigator, className)}>
      <div
        onClick={prevDisabled ? undefined : () => onNextPrev(false)}
        className={classNames(classes.Prev, prevDisabled && classes.Disabled)}
      >
        <ArrowRightThinIcon className={classes.Icon} />
        {prevLabel}
      </div>
      <div className={classes.Separator} />
      <div
        onClick={nextDisabled ? undefined : () => onNextPrev(true)}
        className={classNames(classes.Next, nextDisabled && classes.Disabled)}
      >
        {nextLabel}
        <ArrowRightThinIcon className={classes.Icon} />
      </div>
    </div>
  );
};

NextPrevNavigator.defaultProps = {
  nextLabel: 'Next',
  prevLabel: 'Previous',
};
