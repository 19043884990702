import {useMemo} from 'react';
import {get} from 'lodash';
import {SmartTableFigureColumn} from '../../../../types';
import {exists} from 'front-core';
import {HoverHelperTip} from '../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import classes from '../smart-table.module.scss';
import * as React from 'react';

export const useHelperTextDataKey = (data: any, column: SmartTableFigureColumn<any>) => {
  const {helperTextDataKey} = column.options;
  const helperText = useMemo(
    () => (helperTextDataKey ? get(data, helperTextDataKey) : undefined),
    [data, helperTextDataKey]
  );

  if (!exists(helperText)) {
    return null;
  }
  return <HoverHelperTip title={helperText} small className={classes.HelperTextDataKey} />;
};
