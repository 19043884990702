import * as React from 'react';
import {range} from 'lodash';
import {useMemo, useEffect, useState} from 'react';
import {LayoutLabel} from '../components/layout-label.component';
import {ChartLegend, LegendLabel} from '../components/chart-legend.component';
import {Layer} from 'react-konva';
import {EnhancedGroup, EnhancedLine} from '../../../../core/konva/components';
import {CanvasElementPositions} from '../../../../core/konva/stage.types';
import {calculateBbox} from '../../../../core/konva/stage.utils';
import {useChartLegend, LEGEND_TOP_PADDING} from '../chart.hooks';

export type StepsLayoutInjectedProps = CanvasElementPositions;

interface OwnProps extends CanvasElementPositions {
  tickLabels?: string[];
  legendLabels: LegendLabel[];
  legendsTitle?: string;
  children?: (injected: StepsLayoutInjectedProps) => any;
  renderFirstLine?: boolean;
  renderLastLine?: boolean;
  renderLabels?: boolean;
  showLegend?: boolean;
  strokeColor?: string;
  onLabelClicked?: (label: LegendLabel, e: React.MouseEvent<HTMLElement>) => void;
  x: number;
  y: number;
}

type AllProps = OwnProps;

const LEGEND_HEIGHT = 10;
const MARGIN_BOTTOM = 20;

export const StepsLayout: React.FC<AllProps> = (props: AllProps) => {
  const {
    children,
    legendLabels,
    legendsTitle,
    width,
    height,
    x,
    y,
    tickLabels,
    renderFirstLine,
    renderLastLine,
    renderLabels,
    strokeColor,
    showLegend,
    onLabelClicked,
  } = props;
  /**
   * UI computed properties - position and values calculations
   */
  const [childrenViewBox, setChildrenViewBox] = useState(
    calculateBbox(width, height, [32, 0, MARGIN_BOTTOM, 0])
  );
  const {legendHtmlProps, legendHeight} = useChartLegend(
    height,
    LEGEND_TOP_PADDING,
    childrenViewBox
  );
  const labelSize = useMemo(() => width / tickLabels.length || 10, [width, tickLabels]);
  const xPositions = useMemo(
    () => range(0, tickLabels.length + 1).map(i => i * labelSize),
    [labelSize, tickLabels]
  );

  useEffect(() => {
    setChildrenViewBox(calculateBbox(width, height, [32, 0, legendHeight || MARGIN_BOTTOM, 0]));
  }, [width, height, legendHeight]);

  /**
   * Render
   */
  return (
    <Layer>
      <EnhancedGroup width={width} height={height} x={x} y={y}>
        {xPositions
          .filter((_, idx) => {
            if (idx === 0) return renderFirstLine;
            if (idx === xPositions.length - 1) return renderLastLine;
            return true;
          })
          .map((x, idx) => (
            <EnhancedLine
              key={`line_${idx}`}
              id={`line_${idx}`}
              offsetX={-1}
              points={[
                x,
                0,
                x,
                height - MARGIN_BOTTOM + (showLegend ? -1 : 1) * (legendHeight || LEGEND_HEIGHT),
              ]}
              stroke={strokeColor}
              strokeWidth={2}
              dash={[8, 8]}
              lineJoin={'round'}
            />
          ))}
        {renderLabels &&
          tickLabels &&
          tickLabels.map((step, i) => (
            <LayoutLabel
              key={`label_${i}`}
              id={`label_${i}`}
              label={step}
              x={xPositions[i]}
              y={0}
              width={labelSize}
            />
          ))}

        <EnhancedGroup>{children(childrenViewBox)}</EnhancedGroup>

        {showLegend && (
          <ChartLegend
            id={legendHtmlProps.id}
            {...legendHtmlProps.groupProps}
            title={legendsTitle}
            labels={legendLabels}
            onLabelClicked={onLabelClicked}
          />
        )}
      </EnhancedGroup>
    </Layer>
  );
};

StepsLayout.defaultProps = {
  legendsTitle: 'Legend',
  renderFirstLine: true,
  renderLastLine: true,
  renderLabels: true,
  showLegend: true,
  strokeColor: '#C3C6D8',
};
