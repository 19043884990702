import * as React from 'react';
import classNames from 'classnames';
import {RCAExperimentExplainer, RCAExperimentExplainerFigureOptions} from '../../../../../../types';
import classes from './experiment-variants-table.module.scss';
import {useMemo} from 'react';
import {VARIANT_COLORS} from '../../../../ab-test-analysis/experiment-context-viewer/experiment-context-viewer.consts';
import {ExplainabilityLabelDisplay} from '../../../components/explainability-label-display/explainability-label-display.component';
import {VersusWithUpliftDisplay} from '../../../../../shared/display-columns/versus-with-uplift-display.component';
import {ImpactPPDisplay} from '../../../../../shared/display-columns/impact-pp-display.component';
import {TooltipIfOverflow} from '../../../../../../../../simple/generic/tooltips/tooltips.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import pluralize from 'pluralize';
import {sortData, Sorting} from 'front-core';

interface Props {
  experiment: RCAExperimentExplainer;
  options: RCAExperimentExplainerFigureOptions;
  className?: string;
}

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_EXPERIMENT_EXPLAINER_FIGURE;
const PRIMARY_SORT: Sorting = {
  orderBy: 'isControl',
  order: 'desc',
};
const SECONDARY_SORT: Sorting = {
  orderBy: 'explainabilityScore',
  order: 'desc',
};

export const ExperimentVariantsTable: React.FC<Props> = (props: Props) => {
  const {experiment, options, className} = props;
  const {t} = useDocumentTranslation();

  const variants = useMemo(
    () =>
      sortData(experiment.variants, PRIMARY_SORT, SECONDARY_SORT).map((v, idx) => {
        const share = v.count / experiment.totalPopulationCount;
        const expectedShare = v.expectedCount / experiment.expectedPopulationCount;

        return {
          ...v,
          color: VARIANT_COLORS[idx % VARIANT_COLORS.length],
          share,
          expectedShare,
        };
      }),
    [experiment]
  );
  return (
    <div className={classNames(classes.ExperimentVariantsTable, className)}>
      <div className={classes.HeadersWrapper}>
        <div className={classes.Headers}>
          <div className={classes.Title}>
            {t(TransKeysPrefix.VARIANTS_TABLE.HEADERS.VARIANT.TITLE)}
          </div>
          <div className={classes.Title}>
            {t(TransKeysPrefix.VARIANTS_TABLE.HEADERS.EXPLAINABILITY.TITLE)}
          </div>
          <div className={classes.Title}>
            {t(TransKeysPrefix.VARIANTS_TABLE.HEADERS.SHARE.TITLE, {
              entity: pluralize(options.entity),
            })}
          </div>
          <div className={classes.Title}>
            {t(TransKeysPrefix.VARIANTS_TABLE.HEADERS.KPI_VALUE.TITLE)}
          </div>
          <div className={classes.Title}>
            {t(TransKeysPrefix.VARIANTS_TABLE.HEADERS.IMPACT_IN_PP.TITLE)}
          </div>
        </div>
      </div>
      <div className={classes.Body}>
        {variants.map(v => (
          <div className={classes.Row} key={v.name}>
            <div className={classes.Cell}>
              <div className={classes.VariantNameWrapper} style={{backgroundColor: v.color}}>
                <TooltipIfOverflow title={v.name}>
                  <span className={classes.VariantName}>{v.name}</span>
                </TooltipIfOverflow>
              </div>
            </div>
            <div className={classes.Cell}>
              {!v.isControl && (
                <ExplainabilityLabelDisplay
                  label={v.explainabilityLabel}
                  isSignificant={v.isSignificant}
                  modelName={'this variant'}
                />
              )}
              {v.isControl && <span className={classes.Control}>Control</span>}
            </div>
            <div className={classes.Cell}>
              <VersusWithUpliftDisplay
                value={v.share}
                vsValue={v.expectedShare}
                valueLabel={'Share'}
                vsValueLabel={'Expected share'}
              />
            </div>
            <div className={classes.Cell}>
              <VersusWithUpliftDisplay
                value={v.value}
                vsValue={v.expectedValue}
                isPercentageValue={options.isPercentageValue}
              />
            </div>
            <div className={classes.Cell}>
              <ImpactPPDisplay value={v.ctcPp} outOfValue={experiment.populationKpiTrend} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
