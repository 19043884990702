import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import classes from './segment-filters.module.scss';
import {get, keys, values} from 'lodash';
import {GlobalDocumentDataContext} from '../../../../contexts/global-document-data/global-document-data.context';
import {calculateMinMaxShare} from '../../../viewers/utils/segmentation.utils';
import {Select} from '../../../../../../forms/inputs/select/select.component';
import TransKeys from 'translations';
import {
  SlidersIcon,
  UserGroupLightIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {ShareOfUsersFilter} from '../../filters/share-of-users-filter/share-of-users-filter.component';
import pluralize from 'pluralize';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {
  FavoriteSegment,
  Team,
} from '../../../../contexts/global-document-data/global-document-data.types';
import {Tooltip} from '@material-ui/core';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {CommandType} from '../../../../types';
import {ModelType} from '../../../../../../../consts/model-type';
import {TeamSelector} from '../../../../../../simple/controls/team-selector/team-selector.component';
import {exists} from 'front-core';

export interface SegmentFiltersValue {
  group?: number;
  shareOfUsers?: {min: number; max: number};
}

export interface ExtractSegmentData {
  signalIdDataKey?: string;
  segmentGroupNameDataKey?: string;
  segmentNameDataKey?: string;
  shareDataKey?: string;
  // when data is true - it means that we need to ignore the team filter
  ignoreTeamFilterDataKey?: string;
}

interface OwnProps {
  segments: any[];
  extract: ExtractSegmentData;
  filtersValue: SegmentFiltersValue;
  onFiltersChange: (filters: SegmentFiltersValue) => void;
  entity: string;
  groupFilter?: boolean;
  shareOfUsersFilter?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const MIN_GROUPS_FOR_SEARCH = 6;

function filterByFavorites<T>(
  segments: T[],
  teamId: number | null = null,
  extract: ExtractSegmentData,
  favoriteSegmentsMap: {[signalId: number]: FavoriteSegment}
): T[] {
  let res = [...segments];
  if (!exists(teamId)) {
    return res;
  }
  let relevantSignalIds = [];

  // Add favorite segments signal ids
  if (teamId) {
    relevantSignalIds = values(favoriteSegmentsMap).map(fs => fs.signalId);
  }
  const relevantSignalIdsSet = new Set(relevantSignalIds);
  res = res.filter(s => {
    if (extract.ignoreTeamFilterDataKey) {
      const ignore = get(s, extract.ignoreTeamFilterDataKey);
      if (ignore) {
        return true;
      }
    }
    const signalId = get(s, extract.signalIdDataKey);
    const isRelevantSegment = relevantSignalIdsSet.has(signalId);
    // check if that signal is favorite and has classes, if so we also need to filter relevant classes
    if (
      isRelevantSegment &&
      signalId in favoriteSegmentsMap &&
      favoriteSegmentsMap[signalId].classes
    ) {
      return favoriteSegmentsMap[signalId].classes.includes(get(s, extract.segmentNameDataKey));
    }
    return isRelevantSegment;
  });
  return res;
}

export function useFilterSegmentItems<T>(
  segments: T[],
  teamId: number | null = null,
  filtersValue: SegmentFiltersValue,
  extract: ExtractSegmentData
): T[] {
  const {selectedFavoriteSegmentsMap} = useContext(GlobalDocumentDataContext);

  return useMemo(() => {
    let res = filterByFavorites(segments, teamId, extract, selectedFavoriteSegmentsMap);
    // filter by group name
    if (filtersValue.group) {
      res = res.filter(s => get(s, extract.signalIdDataKey) === filtersValue.group);
    }
    // filter by share of users
    if (filtersValue.shareOfUsers) {
      res = res.filter(s => {
        const share = get(s, extract.shareDataKey);
        return (
          share >= filtersValue.shareOfUsers.min / 100 &&
          share <= filtersValue.shareOfUsers.max / 100
        );
      });
    }
    return res;
  }, [segments, extract, filtersValue, selectedFavoriteSegmentsMap]);
}

const calculateInitialTeamId = (
  teamId: number | null,
  segments: any[],
  selectedFavoriteSegmentsMap: {[signalId: number]: FavoriteSegment},
  extract: ExtractSegmentData
) => {
  if (keys(selectedFavoriteSegmentsMap).length === 0) {
    return null;
  }
  for (const s of segments) {
    const signalId = get(s, extract.signalIdDataKey);
    if (selectedFavoriteSegmentsMap[signalId] === undefined) {
      continue;
    }
    const segmentName = get(s, extract.segmentNameDataKey);
    if (
      selectedFavoriteSegmentsMap[signalId].classes &&
      selectedFavoriteSegmentsMap[signalId].classes.indexOf(segmentName) === -1
    ) {
      continue;
    }
    return teamId;
  }

  return null;
};

export const useTeamAutoSetterForSegments = (
  segments: any[],
  extract: ExtractSegmentData
): number | null => {
  const {teamId, selectedFavoriteSegmentsMap, setTeamId} = useContext(GlobalDocumentDataContext);
  // check if we have segments in team - if not returns null
  const initialTeamId = useRef<number | null>(
    calculateInitialTeamId(teamId, segments, selectedFavoriteSegmentsMap, extract)
  );
  // set the team on initial load - only happens once
  useEffect(() => {
    // There are components who consume this hook which aren't in the
    // context of the GlobalDocumentDataContextProvider, (i.e. analysis
    // results in the backoffice) so we need to check if setTeamId
    // exists to avoid the error
    setTeamId && setTeamId(initialTeamId.current);
  }, [initialTeamId, setTeamId]);

  return initialTeamId.current;
};

export const SegmentFilters: React.FC<AllProps> = (props: AllProps) => {
  const {
    segments: segmentsFromProps,
    extract,
    filtersValue,
    onFiltersChange,
    entity,
    groupFilter,
    shareOfUsersFilter,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {
    teams: teamsFromContext,
    selectedFavoriteSegmentsMap,
    setTeamId,
    teamId,
  } = useContext(GlobalDocumentDataContext);
  const teams: Team[] = useMemo(
    () => [
      {
        id: null,
        name: 'All',
        icon: UserGroupLightIcon,
      },
      ...teamsFromContext,
    ],
    [teamsFromContext]
  );
  const segments = useMemo(
    () => filterByFavorites(segmentsFromProps, teamId, extract, selectedFavoriteSegmentsMap),
    [segmentsFromProps, filtersValue, extract, selectedFavoriteSegmentsMap]
  );

  const shareOfUsersMinMaxValue = useMemo(
    () =>
      calculateMinMaxShare(
        segments.map(s => ({
          share: get(s, extract.shareDataKey),
        }))
      ),
    [segments, extract.shareDataKey]
  );
  const groupOptions = useMemo(() => {
    const groups = new Set();
    const options = [];
    for (const s of segments) {
      const signalId = get(s, extract.signalIdDataKey);
      if (groups.has(signalId)) {
        continue;
      }
      groups.add(signalId);
      options.push(s);
    }
    return options.map(s => ({
      value: get(s, extract.signalIdDataKey),
      label: get(s, extract.segmentGroupNameDataKey),
    }));
  }, [segments, extract]);
  const showCreateFavoriteSegments = useCallback(
    () =>
      emitEvent({
        type: CommandType.CREATION,
        payload: {
          type: ModelType.SEGMENT_PREFERENCES,
          parameters: {
            teamId,
          },
        },
      }),
    [emitEvent, teamId]
  );

  const onChangeTeam = useCallback(
    (teamId: number) => {
      setTeamId(teamId);
    },
    [setTeamId]
  );
  const hasTeams = teamsFromContext.length > 0;

  return (
    <div className={classNames(classes.SegmentFilters, className)}>
      {hasTeams && (
        <TeamSelector
          withPrefix
          teams={teams}
          value={teamId}
          onChange={onChangeTeam}
          clearable={false}
        />
      )}

      {(groupFilter || shareOfUsersFilter) && (
        <>
          <span className={classNames(classes.FiltersSeparator)} />
          <span className={classNames(classes.FiltersLabel)}>Filters:</span>
        </>
      )}

      {groupFilter && (
        <Select
          className={classes.Filter}
          dropdownButtonClassName={classes.Select}
          placeholder={t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.GROUP_NAME.LABEL
          )}
          value={filtersValue.group}
          onChange={group => onFiltersChange({group} as any)}
          clearable
          searchable={groupOptions.length > MIN_GROUPS_FOR_SEARCH}
          icon={UserGroupLightIcon}
          disabled={groupOptions.length === 0}
          options={{
            options: groupOptions,
          }}
        />
      )}
      {shareOfUsersFilter && (
        <ShareOfUsersFilter
          className={classes.Filter}
          {...shareOfUsersMinMaxValue}
          value={filtersValue.shareOfUsers}
          onChange={v => onFiltersChange({shareOfUsers: v})}
          placeholder={t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.SHARE_OF_X.LABEL,
            {
              entity: pluralize(entity),
            }
          )}
        />
      )}
      <div className={classes.Other}>
        {hasTeams && (
          <Tooltip
            title={t(TransKeys.DOCUMENT_VIEWER.SEGMENT_FILTERS.CHANGE_FAVORITES_HELPER_TEST)}
            interactive={false}
            placement={'top'}
          >
            <div onClick={showCreateFavoriteSegments} className={classes.EditFavSegments}>
              <SlidersIcon />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

SegmentFilters.defaultProps = {
  segments: [],
  extract: {},
  filtersValue: {},
  groupFilter: true,
  shareOfUsersFilter: true,
};
