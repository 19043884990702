import * as React from 'react';
import classNames from 'classnames';
import classes from './modal-layout.module.scss';
import {CSSProperties} from 'react';
import {CloseIcon} from '../../simple/controls/icons/icons.component';
import {GenericLoading} from '../../simple/utils/generic-loading/generic-loading.component';

interface OwnProps {
  onClose?: (e) => void;
  children: any;
  className?: string;
  bodyClassName?: string;
  style?: CSSProperties;
  footer?: any;
  isLoading?: boolean;
}

type AllProps = OwnProps;

export const CloseModalButton: React.FC<any> = ({
  onClick,
  className,
}: {
  onClick: any;
  className?: string;
}) => (
  <div onClick={onClick} className={classNames(classes.CloseIconButton, className)}>
    <CloseIcon className={classes.CloseIcon} />
  </div>
);

export const ModalLayout: React.FC<AllProps> = (props: AllProps) => {
  const {children, footer, isLoading, onClose, className, bodyClassName, style} = props;

  return (
    <div
      style={style}
      className={classNames(classes.Modal, footer && classes.HasFooter, className)}
    >
      {onClose && (
        <div className={classes.Actions}>
          <CloseModalButton onClick={onClose} />
        </div>
      )}
      {isLoading && <GenericLoading />}
      {!isLoading && <div className={classNames(classes.Body, bodyClassName)}>{children}</div>}
      {!isLoading && footer && <div className={classes.Footer}>{footer}</div>}
    </div>
  );
};
