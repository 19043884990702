import * as React from 'react';
import {TreatmentAdoptionSimulationFigure} from '../../../../types';
import {BaseTreatmentAdoptionSimulationViewer} from './base-treatment-adoption-simulation-viewer/base-treatment-adoption-simulation-viewer.component';
import TransKeys from 'translations';

// todo: change interface to GoalDriversSimulationFigure
interface OwnProps extends TreatmentAdoptionSimulationFigure {
  className?: string;
}

type AllProps = OwnProps;

export const GoalDriversSimulatorViewer: React.FC<AllProps> = (props: AllProps) => {
  return (
    <BaseTreatmentAdoptionSimulationViewer
      {...props}
      transKeysPrefix={TransKeys.DOCUMENT_VIEWER.GOAL_DRIVERS_SIMULATION}
    />
  );
};

GoalDriversSimulatorViewer.defaultProps = {};
