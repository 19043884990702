import * as React from 'react';
import classNames from 'classnames';
import classes from './progress.module.scss';
import {number2k} from 'front-core';

interface OwnProps {
  className?: string;
  value: number; // [0 - 100]
  label?: string;
  max?: number;
  unit?: string;
  preview?: boolean;
  fromColor?: string;
  toColor?: string;
}

type AllProps = OwnProps;

export const Progress: React.FC<AllProps> = (props: AllProps) => {
  const {label, max, unit, value, preview, fromColor, toColor, className} = props;

  return (
    <div className={classNames(classes.Progress, className)}>
      <div className={classes.ProgressBar}>
        <div
          style={{
            width: `${value}%`,
            backgroundImage: `linear-gradient(90deg, ${fromColor} 15%, ${toColor} 100%)`,
          }}
          className={classes.Bar}
        />
      </div>
      {!preview && label && <div className={classes.Label}>{label}</div>}
      {!preview && max && (
        <div className={classes.Max}>
          {number2k(max)}
          {unit}
        </div>
      )}
    </div>
  );
};

Progress.defaultProps = {
  fromColor: '#3483FF',
  toColor: '#3483FF',
};
