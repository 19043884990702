import {SvgIcon} from '@material-ui/core';
import classNames from 'classnames';
import {memo, forwardRef} from 'react';

export enum IconStyles {
  Regular = 'regular',
  Solid = 'solid',
  Light = 'light',
  Duotone = 'duotone',
  Thin = 'thin',
  Brands = 'brands',
}

export function createSvgIcon(path, viewBox = '0 0 20 20') {
  const Component = (props, ref) => (
    <SvgIcon ref={ref} {...props} viewBox={viewBox}>
      {path}
    </SvgIcon>
  );

  return memo(forwardRef(Component));
}

export function createFontAwesomeIcon(
  name: string,
  style: IconStyles = undefined,
  isSharp?: boolean,
  customClassNames?: string
) {
  const fontAwesomeIconName = name?.startsWith('fa-') ? name : `fa-${name}`;
  const fontAwesomeIconStyle = style ? `fa-${style}` : '';
  const sharp = isSharp ? 'fa-sharp' : '';
  const customClassNames_ = customClassNames ? customClassNames : '';

  const Component = (props, ref) => (
    <i
      ref={ref}
      {...props}
      className={classNames(
        `${fontAwesomeIconName}`,
        fontAwesomeIconStyle,
        sharp,
        customClassNames_,
        props.className
      )}
    />
  );

  return memo(forwardRef(Component));
}

export function createCustomFontAwesomeIcon(name: string) {
  const fontAwesomeIconName = name?.startsWith('fa-') ? name : `fa-${name}`;

  const Component = (props, ref) => (
    <i
      ref={ref}
      {...props}
      className={classNames(`${fontAwesomeIconName}`, 'fa-kit', props.className)}
    />
  );

  return memo(forwardRef(Component));
}

export function createFontAwesomeSvgIcon(path, viewBox = '0 0 576 512') {
  const Component = (props, ref) => (
    <SvgIcon ref={ref} {...props} viewBox={viewBox}>
      {path}
    </SvgIcon>
  );

  return memo(forwardRef(Component));
}
