import classNames from 'classnames';
import classes from './rca-figure-viewer.module.scss';
import {DocumentElementType, RCAElementType, RCAFigure, RCAMode} from '../../../../types';
import React, {useContext, useMemo, useRef} from 'react';
import {ChildRenderer} from '../../../core/child-renderer.component';
import {CircularProgress} from '@material-ui/core';
import {RCAHeader} from './components/rca-header/rca-header.component';
import {sharedViewerClasses} from '../../index';
import {RCAFigureViewerElements} from './rca-figure-viewer-elements.component';
import {RCAFigureNavbar} from './rca-figure-navbar/rca-figure-navbar.component';
import {useFixedRCAHeader} from './components/rca-header/use-should-show-sticky-header.hook';
import {RCAFigureViewerContext, RCAFigureViewerContextProvider} from './rca-figure-viewer.context';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';
import {useRCAElementTitles} from './use-rca-element-titles.hook';
import {RCASegmentationViewMode} from './rca-figure-viewer.types';

interface RCAFigureViewerProps extends RCAFigure {
  className?: string;
  renderSideMenu?: boolean;
  renderHeaderName?: boolean;
  renderKPITree?: boolean;
  initialMode?: RCAMode;
  spaceTop?: number; // pixels
  showExecutiveSummary?: boolean;
  showMetricSuggestion?: boolean;
}

export const RCAFigureViewerComponent = (props: RCAFigureViewerProps) => {
  const {
    data,
    options,
    renderSideMenu = true,
    renderHeaderName = true,
    renderKPITree = false,
    spaceTop = 0,
    showExecutiveSummary,
    showMetricSuggestion,
    className,
  } = props;
  const {renderMode, onChangeMode, showFakeLoader, shouldUseThreshold} =
    useContext(RCAFigureViewerContext);
  const elementItems = useRCAElementTitles();
  const {loopsAlgoElements, comparedElements, granularity} = data;
  const contentElementRef = useRef<HTMLDivElement>();
  const executiveSummaryFigure = useMemo(() => {
    if (!showExecutiveSummary) {
      return null;
    }
    return {
      type: DocumentElementType._EXECUTIVE_SUMMARY_FIGURE,
      rcaMode: renderMode,
    };
  }, [renderMode, showExecutiveSummary]);
  const kpiTreeFigure = useMemo(() => {
    if (renderKPITree) {
      return {
        type: DocumentElementType._KPI_TREE_FIGURE,
        analyzedDate: data.analyzedDate,
      };
    } else {
      return null;
    }
  }, [renderKPITree, data.analyzedDate]);

  const renderedRCAElements =
    renderMode === RCAMode.LOOPS_ALGO ? loopsAlgoElements : comparedElements;

  const otherSegmentsFigure = useMemo(
    () => ({
      ...renderedRCAElements[RCAElementType.SEGMENTATION],
      id: `${renderedRCAElements[RCAElementType.SEGMENTATION].id}2`,
    }),
    [renderedRCAElements]
  );

  const metricSuggestionFigure = useMemo(() => {
    if (showMetricSuggestion) {
      return {
        type: DocumentElementType._METRIC_SUGGESTIONS_FIGURE,
        granularity,
      };
    }
    return null;
  }, [showMetricSuggestion, granularity]);

  const shouldStick = useFixedRCAHeader(contentElementRef);
  const elementStyle = {
    scrollMargin: `${spaceTop ? spaceTop + 80 : 80}px`,
  };

  return (
    <div
      className={classNames(
        classes.RCAFigureViewer,
        sharedViewerClasses.FullViewer,
        !renderSideMenu && classes.HideSideMenu,
        className
      )}
    >
      <RCAFigureNavbar rcaElements={renderedRCAElements} />
      <div className={classes.Content} ref={contentElementRef}>
        <div
          style={{top: spaceTop}}
          className={classNames(classes.RCAHeaderWrapper, shouldStick && classes.Sticky)}
        >
          <RCAHeader
            data={data}
            options={options}
            compact={shouldStick}
            onChangeMode={onChangeMode}
            disabled={showFakeLoader}
            useThreshold={shouldUseThreshold}
            renderHeaderName={renderHeaderName}
          />
        </div>
        {shouldStick && <div className={classes.Placeholder} />}
        <div className={classes.Children}>
          {showFakeLoader && (
            <div className={classes.Loader}>
              <div className={classes.Indicator}>
                <CircularProgress
                  variant={'indeterminate'}
                  disableShrink
                  size={20}
                  thickness={4}
                  color={'inherit'}
                />
                <span>Loading data...</span>
              </div>
            </div>
          )}
          {executiveSummaryFigure && (
            <div>
              <ChildRenderer children_={executiveSummaryFigure} />
            </div>
          )}
          {/*
            This key is highly important - we need to remount this entire block
            One of the children is not listen to props changes, and we need to force it to remount
          */}
          <React.Fragment key={renderMode}>
            <div className={classes.Element}>
              <ChildRenderer children_={renderedRCAElements.overview} />
            </div>
            {kpiTreeFigure && (
              <div className={classes.KPITree}>
                <ChildRenderer children_={kpiTreeFigure} />
              </div>
            )}
            <RCAFigureViewerElements
              rcaFigureElements={renderedRCAElements}
              elementStyle={elementStyle}
            />
            <div className={classes.OtherSegments}>
              <DocumentTitleWrapper
                title={elementItems[RCAElementType._CHANGING_SEGMENTS].label}
                subtitle={elementItems[RCAElementType._CHANGING_SEGMENTS].subtitle}
                icon={elementItems[RCAElementType._CHANGING_SEGMENTS].icon}
                id={elementItems[RCAElementType._CHANGING_SEGMENTS].key}
                className={classes.Element}
                style={elementStyle}
              >
                <ChildRenderer
                  children_={otherSegmentsFigure}
                  className={classes.MarginBottom}
                  viewMode={RCASegmentationViewMode.CHANGE}
                />
                {metricSuggestionFigure && <ChildRenderer children_={metricSuggestionFigure} />}
              </DocumentTitleWrapper>
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
};

const RCAFigureViewer = (props: RCAFigureViewerProps) => {
  return (
    <RCAFigureViewerContextProvider {...props}>
      <RCAFigureViewerComponent {...props} />
    </RCAFigureViewerContextProvider>
  );
};

RCAFigureViewer.defaultProps = {
  showExecutiveSummary: true,
  showMetricSuggestion: false,
};

export {RCAFigureViewer};
