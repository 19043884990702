import classNames from 'classnames';
import sharedClasses from '../../insights.module.scss';
import {formatPercentageValueWithPrefix} from '../../insights.utils';
import {RCAInsightSentenceSegmentViewData} from '../../rca/rca-insights.model.ts';
import {VenDiagramIcon} from 'ui-components';
import {ReactElement, useMemo} from 'react';

interface SentencePartProps {
  text: string | ReactElement;
  bold?: boolean;
  italic?: boolean;
  positive?: boolean;
  negative?: boolean;
  className?: string;
}

export const SentencePart = (props: SentencePartProps) => {
  const {text, bold, italic, positive, negative, className} = props;

  return (
    <span
      className={classNames(
        sharedClasses.InsightSentencePart,
        bold && sharedClasses.Bold,
        italic && sharedClasses.Italic,
        positive && sharedClasses.Positive,
        negative && sharedClasses.Negative,
        className
      )}
    >
      {text}
    </span>
  );
};

interface SentenceContainerProps {
  children: ReactElement<typeof SentencePart>[] | ReactElement<typeof SentencePart>;
  className?: string;
}

export const SentenceContainer = (props: SentenceContainerProps) => {
  const {children, className} = props;
  return <div className={classNames(sharedClasses.SentenceContainer, className)}>{children}</div>;
};

export const ChangeInTotalEntitiesSentencePart = (props: {changeInTotalEntities: string}) => {
  const {changeInTotalEntities} = props;

  return (
    <SentenceContainer>
      <SentencePart bold text={changeInTotalEntities} />
    </SentenceContainer>
  );
};

export const ChangeInShareOfEntitiesSentencePart = (props: {changeInShareOfEntities: string}) => {
  const {changeInShareOfEntities} = props;

  return (
    <SentenceContainer>
      <SentencePart bold text={changeInShareOfEntities} />
    </SentenceContainer>
  );
};
export const ChangeInValueSentencePart = (props: {
  changeInValue: number;
  higherIsBetter: boolean;
}) => {
  const {changeInValue, higherIsBetter} = props;
  const sentenceProps = useMemo(() => {
    if (changeInValue > 0) {
      return higherIsBetter ? {positive: true} : {negative: true};
    } else if (changeInValue < 0) {
      return higherIsBetter ? {negative: true} : {positive: true};
    } else {
      return {};
    }
  }, [changeInValue, higherIsBetter]);

  return (
    <SentenceContainer>
      <SentencePart bold text={formatPercentageValueWithPrefix(changeInValue)} {...sentenceProps} />
    </SentenceContainer>
  );
};

export const SegmentNameSentencePart = (props: {data: RCAInsightSentenceSegmentViewData[]}) => {
  const {data} = props;
  if (data && data.length === 1) {
    const [{groupName: segmentGroupName, name: segmentName}] = data;
    return (
      <SentenceContainer>
        <SentencePart bold text={`${segmentGroupName} → ${segmentName}`} />
      </SentenceContainer>
    );
  }

  return (
    <SentenceContainer>
      {data.map(({groupName: segmentGroupName, name: segmentName}, index) => {
        const isFirst = index === 0;
        const isLast = index === data.length - 1;
        const key = `${segmentGroupName}_${segmentName}_${index}`;
        return (
          <SentenceContainer key={key}>
            {isFirst && <VenDiagramIcon />}
            <SentencePart bold text={`${segmentGroupName} → ${segmentName}`} />
            {!isLast && <SentencePart text=" AND" />}
          </SentenceContainer>
        );
      })}
    </SentenceContainer>
  );
};

export const SampledModelMeasuredItemCompoundNameSentencePart = (props: {
  compoundName: string[];
}) => {
  const {compoundName} = props;
  if (compoundName.length === 1) {
    return (
      <SentenceContainer>
        <SentencePart text={compoundName[0]} />
      </SentenceContainer>
    );
  }

  return (
    <SentenceContainer>
      {compoundName.map((name, index) => {
        const isLast = index === compoundName.length - 1;
        return (
          <SentenceContainer key={name}>
            <SentencePart bold text={name} />
            {!isLast && <SentencePart text=" →" />}
          </SentenceContainer>
        );
      })}
    </SentenceContainer>
  );
};
