import * as React from 'react';
import classNames from 'classnames';
import {UnifiedRCASegmentationItem} from '../../../../../../rca-segmentation-extended.types';
import classes from './explainers-table-item.module.scss';
import {
  RCASegmentationUsersMode,
  RCASegmentationViewMode,
} from '../../../../../../../rca-figure/rca-figure-viewer.types';
import {RCASegmentationFigureOptions} from '../../../../../../../../../../types';
import TransKeys from 'translations';
import {SimilarButton} from '../similar-button/similar-button.component';
import {ExplainabilityLabelDisplay} from '../../../../../../../components/explainability-label-display/explainability-label-display.component';
import {InterestChipDisplay} from '../interest-chip-display/interest-chip-display.component';
import {take} from 'lodash';
import {MAX_INTEREST_CHIP_TO_SHOW} from '../../../../../../rca-segmentation-viewer.component';
import {VersusWithUpliftDisplay} from '../../../../../../../../../shared/display-columns/versus-with-uplift-display.component';
import {useRCAVsLabels} from '../../../../../../../rca-figure/hooks/use-rca-vs-labels.hook';
import {useContext, useState} from 'react';
import {RCAFigureViewerContext} from '../../../../../../../rca-figure/rca-figure-viewer.context';
import {ImpactPPDisplay} from '../../../../../../../../../shared/display-columns/impact-pp-display.component';
import {IconButton} from '../../../../../../../../../../../../simple/controls/icon-button/icon-button.component';
import {withStopPropagation} from 'front-core';
import {GraphIcon} from '../../../../../../../../../../../../simple/controls/icons/icons.component';
import {Button} from '../../../../../../../../../../../../simple/controls/button/button.component';
import {useDocumentTranslation} from '../../../../../../../../../../hooks/use-document-translation.hook';
import {SegmentNameDisplay} from '../segment-group-name-display/segment-group-name-display.component';

export interface ExplainerTableItem {
  primary: UnifiedRCASegmentationItem;
  group: Array<UnifiedRCASegmentationItem>;
}

interface Props {
  item: ExplainerTableItem;
  viewMode: RCASegmentationViewMode;
  usersMode: RCASegmentationUsersMode;
  options: RCASegmentationFigureOptions;
  onSignalClicked: (signalId: number) => void;
  populationTrend: number;
  onViewGroupSegments: (signalId: number) => void;
  onViewGroupChart: (signalId: number, segmentKey: string) => void;
  onViewCompositeBreakdown: (compositeKey: string) => void;
  onViewCompositeChart: (compositeKey: string) => void;
  showOvertimeChart?: boolean;
  variant?: 'default' | 'blue';
  className?: string;
}

interface SingleItemRendererProps extends Omit<Props, 'item'> {
  item: UnifiedRCASegmentationItem;
  primary?: UnifiedRCASegmentationItem;
  isExpanded?: boolean;
  highlight?: boolean;
}

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

type VersusColumnMode = 'share' | 'count' | 'value';

const extractValueByMode = (item: any, mode: VersusColumnMode, isPercentageValue: boolean) => {
  const shared = {
    key: item.key,
  };

  if (mode === 'share') {
    return {
      ...shared,
      value: item.share,
      vsValue: item.expectedShare,
      isPercentageValue: true,
    };
  }
  if (mode === 'count') {
    return {
      ...shared,
      value: item.count,
      vsValue: item.expectedCount,
      isPercentageValue: false,
    };
  }

  return {
    ...shared,
    value: item.value,
    vsValue: item.expectedValue,
    isPercentageValue,
  };
};

const SingleItemRenderer: React.FC<SingleItemRendererProps> = (props: SingleItemRendererProps) => {
  const {
    item,
    primary,
    viewMode,
    usersMode,
    options,
    populationTrend,
    showOvertimeChart,
    onViewGroupChart,
    onViewCompositeBreakdown,
    onViewCompositeChart,
    onViewGroupSegments,
    onSignalClicked,
    isExpanded,
    highlight,
    className,
  } = props;
  const {mode: rcaMode} = useContext(RCAFigureViewerContext);
  const vsLabels = useRCAVsLabels(rcaMode, options.entity);
  const {t} = useDocumentTranslation();
  const isComposite = item.segmentKeys && item.segmentKeys.length > 1;

  const renderActions = (segment: UnifiedRCASegmentationItem) => {
    return (
      <>
        {showOvertimeChart && segment.samples && segment.samples.length > 0 && (
          <IconButton
            onClick={withStopPropagation(() => onViewGroupChart(segment.signalId, segment.key))}
            icon={GraphIcon}
            tooltipText={t(
              TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.VIEW_SEGMENT_OVER_TIME_BUTTON_HELPER
            )}
          />
        )}
        <Button
          className={classes.SeeAll}
          helperText={t(TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.SEE_ALL_BUTTON_HELPER)}
          onClick={withStopPropagation(() => onViewGroupSegments(segment.signalId))}
        >
          {t(TRANS_KEYS_PREFIX.SEE_ALL_BUTTON)}
        </Button>
      </>
    );
  };
  const renderCompositeActions = (item: UnifiedRCASegmentationItem) => {
    return (
      <>
        {showOvertimeChart && item.samples && item.samples.length > 0 && (
          <IconButton
            onClick={withStopPropagation(() => onViewCompositeChart(item.key))}
            icon={GraphIcon}
            tooltipText={t(
              TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.VIEW_SEGMENT_OVER_TIME_BUTTON_HELPER
            )}
          />
        )}
        <Button
          className={classes.SeeAll}
          onClick={withStopPropagation(() => onViewCompositeBreakdown(item.key))}
        >
          {t(TRANS_KEYS_PREFIX.COMPOSITE_BREAKDOWN)}
        </Button>
      </>
    );
  };

  return (
    <div className={classNames(classes.Row, highlight && classes.Highlight, className)}>
      <div className={classes.Cell}>
        <SegmentNameDisplay
          item={item}
          primary={primary}
          onSignalClicked={onSignalClicked}
          showSimilarity={isExpanded}
        />
      </div>
      <div className={classNames(classes.Cell, classes.Vertical)}>
        {viewMode === RCASegmentationViewMode.EXPLAIN && (
          <ExplainabilityLabelDisplay
            helperText={item.helperText}
            isSignificant={item.isSignificant}
            label={item.explainabilityLabel}
          />
        )}
        {viewMode === RCASegmentationViewMode.CHANGE &&
          take(item.interestTypes, MAX_INTEREST_CHIP_TO_SHOW).map((interestType, index) => (
            <InterestChipDisplay
              key={index}
              data={item}
              isPercentageValue={options.isPercentageValue}
              entity={options.entity}
              interestType={interestType}
            />
          ))}
      </div>
      <div className={classes.Cell}>
        <VersusWithUpliftDisplay
          {...extractValueByMode(item, usersMode, options.isPercentageValue)}
          {...vsLabels[usersMode]}
        />
      </div>
      <div className={classes.Cell}>
        <VersusWithUpliftDisplay
          {...extractValueByMode(item, 'value', options.isPercentageValue)}
          {...vsLabels.value}
        />
      </div>
      <div className={classes.Cell}>
        <ImpactPPDisplay value={item.ctcPp} outOfValue={populationTrend} />
      </div>
      <div className={classes.ActionsWrapper}>
        <div className={classes.Actions}>
          {isComposite ? renderCompositeActions(item) : renderActions(item)}
        </div>
      </div>
    </div>
  );
};

export const ExplainersTableItem: React.FC<Props> = (props: Props) => {
  const {item, variant, className} = props;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={classNames(
        classes.ExplainersTableItem,
        item.primary.isSignificant === false && classes.NotSignificant,
        isExpanded && classes.Expanded,
        classes[variant],
        className
      )}
    >
      {isExpanded && (
        <div className={classes.ItemWrapper}>
          {item.group.map(segment => (
            <SingleItemRenderer
              {...props}
              key={segment.key}
              item={segment}
              primary={item.primary}
              isExpanded={true}
              highlight={segment.isPrimaryInGroup}
            />
          ))}
        </div>
      )}
      {!isExpanded && (
        <div className={classes.ItemWrapper}>
          <SingleItemRenderer {...props} item={item.primary} />
        </div>
      )}
      {item.group.length > 1 && (
        <SimilarButton
          className={classNames(classes.SimilarButton, className)}
          item={item}
          isExpanded={isExpanded}
          onExpand={() => setIsExpanded(!isExpanded)}
        />
      )}
    </div>
  );
};
