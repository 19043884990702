import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import classes from './funnel-composite-segments-viewer.module.scss';
import {
  DocumentElementType,
  FunnelCompositeSegments,
  FunnelCompositeSegmentsFigure,
  FunnelCompositeSegmentsFigureData,
} from '../../../../types';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {CompositeSegmentsViewer} from '../../../base-viewers/composite-segments-viewer/composite-segments-viewer.component';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {Tooltip} from '@material-ui/core';
import {
  ArrowRightLongLightIcon,
  FilterLightIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {Select} from '../../../../../../forms/inputs/select/select.component';
import {groupBy, keys} from 'lodash';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';

interface OwnProps extends FunnelCompositeSegmentsFigure {
  className?: string;
}

interface ExtendedFunnelCompositeSegments extends FunnelCompositeSegments {
  startStepIndex: number;
  endStepIndex: number;
}

type AllProps = OwnProps;

interface Filters {
  selectedStepIndex: number | null;
}

function getInitialStepIndex(data: FunnelCompositeSegmentsFigureData) {
  const grouped = groupBy(data.composites, 'funnelStepIndex');
  if (grouped['null']?.length > 0) {
    return null;
  }
  for (const s in data.steps) {
    if (grouped[s]?.length > 0) {
      return Number(s);
    }
  }
  return null;
}

export const FunnelCompositeSegmentsViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data: dataFromProps} = props;
  const {t} = useDocumentTranslation();
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const {trackFilter} = useDocumentTracking(
    id,
    DocumentElementType.FUNNEL_COMPOSITE_SEGMENTS_FIGURE
  );
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(id, {
    selectedStepIndex: getInitialStepIndex(dataFromProps),
  });
  const {steps} = dataFromProps;
  const data = useMemo(() => {
    return {
      ...dataFromProps,
      composites: dataFromProps.composites
        .map(c => ({
          ...c,
          startStepIndex: c.funnelCompletion ? 0 : c.funnelStepIndex,
          endStepIndex: c.funnelCompletion ? steps.length - 1 : c.funnelStepIndex + 1,
        }))
        .filter(c => {
          if (filters.selectedStepIndex === null) {
            return c.funnelCompletion === true;
          }
          return c.funnelStepIndex === filters.selectedStepIndex;
        }),
    };
  }, [dataFromProps, steps, filters]);
  const onFilterChange = useCallback(
    (newFilters: Filters) => {
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      }));
      const filterKey = keys(newFilters)[0];
      trackFilter(filterKey, {
        filter: newFilters[filterKey],
      });
    },
    [setFilters, trackFilter]
  );
  const stepsOptions = useMemo(() => {
    const options = [
      {
        value: null,
        label: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.LABELS.FUNNEL_COMPLETION, {
          stepsLength: data.steps.length,
        }),
      },
    ];
    for (let i = 1; i < data.steps.length; i++) {
      options.push({
        value: i - 1,
        label: `${data.steps[i - 1].name} (${i}) → ${data.steps[i].name} (${i + 1})`,
      });
    }
    return options;
  }, [data.steps]);
  const extraColumns = useMemo(
    () => [
      {
        column: {
          key: 'funnel_part',
          title: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_COMPOSITE_SEGMENTS.TABLE.HEADERS.FUNNEL_PART.TITLE
          ),
          subTitle: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_COMPOSITE_SEGMENTS.TABLE.HEADERS.FUNNEL_PART.SUB_TITLE
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_COMPOSITE_SEGMENTS.TABLE.HEADERS.FUNNEL_PART
              .HELPER_TEXT
          ),
          weight: 0.5,
          render: (item: ExtendedFunnelCompositeSegments) => (
            <div className={classes.FunnelPart}>
              <Tooltip title={steps[item.startStepIndex].name} placement={'top'}>
                <span
                  onClick={() => onSignalClick(steps[item.startStepIndex].signalId)}
                  className={classes.Step}
                >
                  {item.startStepIndex + 1}
                </span>
              </Tooltip>
              <ArrowRightLongLightIcon className={classes.Arrow} />
              <Tooltip title={steps[item.endStepIndex].name} placement={'top'}>
                <span
                  onClick={() => onSignalClick(steps[item.endStepIndex].signalId)}
                  className={classes.Step}
                >
                  {item.endStepIndex + 1}
                </span>
              </Tooltip>
            </div>
          ),
        },
        index: 3,
      },
    ],
    [steps]
  );

  const options = useMemo(
    () => ({
      ...(props.options as any),
      isPercentageValue: true,
      higherIsBetter: true,
    }),
    [props.options]
  );

  return (
    <div className={classes.FunnelCompositeSegmentsViewer}>
      <CompositeSegmentsViewer
        {...props}
        extraFilters={[
          {
            key: 'funnel-step-filter',
            index: 0,
            filter: (
              <Select
                dropdownButtonClassName={classes.Select}
                value={filters.selectedStepIndex}
                onChange={(v: any) => onFilterChange({selectedStepIndex: v})}
                searchable={false}
                prefix={t(
                  TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.SELECT_FUNNEL_STEP_PREFIX
                )}
                icon={FilterLightIcon}
                options={{
                  options: stepsOptions,
                }}
              />
            ),
          },
        ]}
        options={options}
        data={data}
        extraColumns={extraColumns}
        type={DocumentElementType.COMPOSITE_SEGMENTS_FIGURE}
        transKeysPrefix={TransKeys.DOCUMENT_VIEWER.FUNNEL_COMPOSITE_SEGMENTS}
      />
    </div>
  );
};

FunnelCompositeSegmentsViewer.defaultProps = {};
