import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {TextInput} from 'ui-components';
import {Footer} from '../../footer/footer.component';
import classes from './amazon-redshift-form.module.scss';
import {isEmpty} from 'lodash';
import {SourceWizardFormLayout} from '../../source-wizard-form-layout/source-wizard-form-layout.component';
import {SourceWizardLabelWrapper} from '../../source-wizard-label-wrapper/source-wizard-label-wrapper.component';
import {redshiftFormSchema, RedshiftFormSchema} from '../../../../../../../objects/dto/source.dto';

export type AmazonRedshiftFormProps = {
  onSubmit?: (values: RedshiftFormSchema) => void;
};

export const AmazonRedshiftForm = (props: AmazonRedshiftFormProps) => {
  const {onSubmit} = props;
  const formMethods = useForm({
    resolver: yupResolver(redshiftFormSchema),
  });
  const {
    handleSubmit,
    formState: {errors, isValid},
    control,
  } = formMethods;

  return (
    <SourceWizardFormLayout
      footer={
        <Footer
          buttonText="Continue"
          onClickSubmit={handleSubmit(onSubmit)}
          disabledCTAButton={!isEmpty(errors) || !isValid}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.RedshiftForm}>
          <SourceWizardLabelWrapper label="Database">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'database'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="Host">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'host'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <div className={classes.InputGroup}>
            <SourceWizardLabelWrapper label="Username">
              <Controller
                render={({field, fieldState: {error}}) => (
                  <TextInput
                    size="large"
                    placeholder={'Type here'}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(error)}
                    required
                  />
                )}
                name={'user'}
                control={control}
              />
            </SourceWizardLabelWrapper>
            <SourceWizardLabelWrapper label="Password">
              <Controller
                render={({field, fieldState: {error}}) => (
                  <TextInput
                    size="large"
                    placeholder={'Type here'}
                    value={field.value}
                    onChange={field.onChange}
                    error={Boolean(error)}
                    required
                    type="password"
                  />
                )}
                name={'password'}
                control={control}
              />
            </SourceWizardLabelWrapper>
          </div>
          <SourceWizardLabelWrapper label="Port (optional)">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                />
              )}
              name={'port'}
              control={control}
            />
          </SourceWizardLabelWrapper>
        </form>
      </FormProvider>
    </SourceWizardFormLayout>
  );
};
