import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useRemoteSourceStated} from 'ui-components';
import {groupBy, isEmpty, keys, pickBy, values} from 'lodash';
import classes from './user-specific-kpis-step.module.scss';
import yup from '../../../../../../config/yup.config';
import {getMetricsNetworkRequest} from '../../../../../../http/metrics.network-requests';
import {Metric, MetricType} from '../../../../../../objects/models/metric.model';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component';
import {KPI_TYPES_OPTIONS, UserKPITypeOption} from '../consts';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import {SIGNAL_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import {useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../../../store/store.selectors';
import {ActionKey} from '../../../../../../constants/action-key';
import {SpecificKPIGroup, SpecificKpisGroups} from './components/specific-kpis-groups.component';
import {EmptySpecificKpisGroups} from './components/empty-specific-kpis-groups.component';

export interface UserSpecificKpis {
  [UserKPITypeOption.CONVERSION]?: number;
  [UserKPITypeOption.ENGAGEMENT]?: number;
  [UserKPITypeOption.MONETIZATION]?: number;
  [UserKPITypeOption.RETENTION]?: number;
  [UserKPITypeOption.ACTIVATION]?: number;
  [UserKPITypeOption.ACQUISITION]?: number;
  [UserKPITypeOption.CHURN]?: number;
}

interface FormData {
  userKPIS: string[];
  userSpecificKpis: UserSpecificKpis;
  selectSimpleAnalysisPickerPanelData: {
    [SIGNAL_ID_PATH_PARAM]: number;
    analysisTypeIds: AnalysisTypeId[];
  };
}

interface OwnProps {
  data: FormData;
  onChange: (value: any) => void;
  className?: string;
}

export const userSpecificKpisStepValidations = yup.object().shape({
  [UserKPITypeOption.CONVERSION]: yup.number().optional().nullable(),
  [UserKPITypeOption.ENGAGEMENT]: yup.number().optional().nullable(),
  [UserKPITypeOption.MONETIZATION]: yup.number().optional().nullable(),
  [UserKPITypeOption.RETENTION]: yup.number().optional().nullable(),
  [UserKPITypeOption.ACTIVATION]: yup.number().optional().nullable(),
  [UserKPITypeOption.ACQUISITION]: yup.number().optional().nullable(),
  [UserKPITypeOption.CHURN]: yup.number().optional().nullable(),
});

const METRIC_TYPE_TO_USER_KPI_TYPE_OPTION_MAP = {
  // Conversion
  [MetricType.CONVERSION]: UserKPITypeOption.CONVERSION,
  // Retention
  [MetricType.PAYMENT_RETENTION]: UserKPITypeOption.RETENTION,
  [MetricType.RETENTION]: UserKPITypeOption.RETENTION,
  // Monetization
  [MetricType.REVENUE]: UserKPITypeOption.MONETIZATION,
  [MetricType.BOUNDED_REVENUE]: UserKPITypeOption.MONETIZATION,
  // Churn
  [MetricType.BEHAVIORAL_CHURN]: UserKPITypeOption.CHURN,
  // Activation
  [MetricType.HABIT_MOMENT]: UserKPITypeOption.ACTIVATION,
  // Engagement
  [MetricType.USAGE]: UserKPITypeOption.ENGAGEMENT,
  [MetricType.L7]: UserKPITypeOption.ENGAGEMENT,
  [MetricType.L28]: UserKPITypeOption.ENGAGEMENT,
  [MetricType.DAU]: UserKPITypeOption.ENGAGEMENT,
  [MetricType.WAU]: UserKPITypeOption.ENGAGEMENT,
  [MetricType.MAU]: UserKPITypeOption.ENGAGEMENT,
};

const getMetricTypesByUserKPITypeOptions = (options: UserKPITypeOption[]): MetricType[] =>
  !isEmpty(options)
    ? (keys(
        pickBy(METRIC_TYPE_TO_USER_KPI_TYPE_OPTION_MAP, (o: UserKPITypeOption) =>
          options.includes(o)
        )
      ) as MetricType[])
    : [];

export const getAnalysisTypeIdsByMetricType = (metricType: MetricType): AnalysisTypeId[] => {
  switch (metricType) {
    case MetricType.RETENTION:
    case MetricType.CONVERSION:
    case MetricType.PAYMENT_RETENTION:
      return [
        AnalysisTypeId.GOAL_DRIVERS,
        AnalysisTypeId.KPI_ANALYSIS,
        AnalysisTypeId.USER_JOURNEY,
      ];
    case MetricType.BOUNDED_REVENUE:
    case MetricType.REVENUE:
      return [AnalysisTypeId.KPI_ANALYSIS, AnalysisTypeId.GOAL_DRIVERS];
    case MetricType.USAGE:
    case MetricType.L7:
    case MetricType.L28:
    case MetricType.DAU:
    case MetricType.WAU:
    case MetricType.MAU:
      return [AnalysisTypeId.KPI_ANALYSIS, AnalysisTypeId.ENGAGEMENT_DRIVERS];
  }
};

type AllProps = OwnProps;

const groupMetricsRequestTransformer = (metrics: Metric[]): SpecificKPIGroup[] => {
  if (isEmpty(metrics)) {
    return [];
  }
  const groupedMetricsObj = groupBy(
    metrics.map(m => ({
      ...m,
      type: METRIC_TYPE_TO_USER_KPI_TYPE_OPTION_MAP[m.type] || m.type,
    })),
    'type'
  );
  return values(UserKPITypeOption)
    .map(userKPIOption => {
      const metricsGroup: Metric[] = groupedMetricsObj[userKPIOption];
      if (!metricsGroup) {
        return null;
      }
      const kpiCategoryOption = KPI_TYPES_OPTIONS.find(o => o.value === userKPIOption);
      return {
        name: userKPIOption,
        icon: kpiCategoryOption?.icon,
        iconClassName: kpiCategoryOption?.className,
        options: {
          options: metricsGroup.map(m => ({value: m.id, label: m.name})),
        },
      };
    })
    .filter(Boolean);
};
const createDefaultUserSpecificKpis = () => ({
  [UserKPITypeOption.ENGAGEMENT]: null,
  [UserKPITypeOption.MONETIZATION]: null,
  [UserKPITypeOption.RETENTION]: null,
  [UserKPITypeOption.ACTIVATION]: null,
  [UserKPITypeOption.ACQUISITION]: null,
  [UserKPITypeOption.CHURN]: null,
});

// As the user can select multiple UserKPITypeOption, we would like to allow him select
// analysis based on the bellow order. i.e. if the user selected both RETENTION and MONETIZATION
// we would like to show the mapped analysis for RETENTION.
export const USER_KPI_TYPE_OPTION_ORDER_FOR_SIMPLE_ANALYSIS_PICKER_PANEL = [
  UserKPITypeOption.CONVERSION,
  UserKPITypeOption.RETENTION,
  UserKPITypeOption.MONETIZATION,
  UserKPITypeOption.ENGAGEMENT,
];

export const UserSpecificKpisStep = (props: AllProps) => {
  const {data, onChange, className} = props;
  const [showConfetti, setShowConfetti] = useState(false);
  const [groupsVisible, setGroupsVisible] = useState(true);
  const formMethods = useForm({
    defaultValues: {
      userSpecificKpis: createDefaultUserSpecificKpis(),
      createUserSpecificSubscription: false,
      ...data,
    },
    resolver: yupResolver(userSpecificKpisStepValidations.noUnknown()),
  });
  const {watch, setValue} = formMethods;
  const {
    source: metrics,
    exec: getMetrics,
    isLoading,
  } = useRemoteSourceStated({
    initialValue: null,
    type: 'source',
    networkRequest: getMetricsNetworkRequest,
    transformer: res => res.data,
  });
  const isSubmittingQuestionnaire = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.UPDATE_USER_ONBOARDING_QUESTIONNAIRE)(state)
  );
  const userSpecificKpis = watch('userSpecificKpis');
  const createUserSpecificSubscription = watch('createUserSpecificSubscription');
  const userSelectedKpisTypes = useMemo(
    () => (data.userKPIS as UserKPITypeOption[]) || [],
    [data.userKPIS]
  );
  const previousUserSelectedKpisTypes = useRef(userSelectedKpisTypes);
  const isUserSelectedKpisTypesChanged = useMemo(
    () =>
      previousUserSelectedKpisTypes.current.length !== userSelectedKpisTypes.length ||
      previousUserSelectedKpisTypes.current.some((o, idx) => o !== userSelectedKpisTypes[idx]),
    [userSelectedKpisTypes]
  );
  const metricsTypeFilter = useMemo(
    () => getMetricTypesByUserKPITypeOptions(userSelectedKpisTypes),
    [userSelectedKpisTypes]
  );
  const groupedMetrics = useMemo(() => groupMetricsRequestTransformer(metrics), [metrics]);
  const showLoading = useMemo(
    () => isLoading || isSubmittingQuestionnaire || !groupsVisible,
    [isLoading, isSubmittingQuestionnaire, groupsVisible]
  );
  const showEmptySpecificKpisGroups = useMemo(
    () => !showLoading && isEmpty(groupedMetrics),
    [showLoading, groupedMetrics]
  );
  const showSpecificKpisGroups = useMemo(
    () => !showLoading && !isEmpty(groupedMetrics) && groupsVisible,
    [showLoading, groupedMetrics, groupsVisible]
  );
  const onSubmit = useCallback(
    _ => {
      onChange({userSpecificKpis, createUserSpecificSubscription});
      setGroupsVisible(false);
    },
    [onChange, userSpecificKpis, createUserSpecificSubscription, setGroupsVisible]
  );
  const onSelectChange = useCallback(
    (metricId: number, groupName: UserKPITypeOption) => {
      const updatedValue = {...userSpecificKpis};
      updatedValue[groupName] = metricId;
      setValue('userSpecificKpis', updatedValue);
    },
    [userSpecificKpis, setValue]
  );

  const onChangeConfirmSubscription = (value: boolean) => {
    setValue('createUserSpecificSubscription', value);
  };
  const onSkipClick = useCallback(() => {
    if (isSubmittingQuestionnaire) {
      return;
    }
    onChange({userSpecificKpis: createDefaultUserSpecificKpis()});
    setGroupsVisible(false);
  }, [onChange, isSubmittingQuestionnaire, setGroupsVisible]);
  useEffect(() => {
    if (isUserSelectedKpisTypesChanged && !isEmpty(metricsTypeFilter)) {
      getMetrics({
        type: metricsTypeFilter,
        limit: 1000,
      });
    }
  }, [isUserSelectedKpisTypesChanged, metricsTypeFilter, getMetrics]);
  useEffect(() => {
    if (metrics === null && !isEmpty(metricsTypeFilter)) {
      getMetrics({
        type: metricsTypeFilter,
        limit: 1000,
      });
    }
  }, [metrics, metricsTypeFilter, isUserSelectedKpisTypesChanged, getMetrics]);
  useEffect(() => {
    if (showEmptySpecificKpisGroups) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 1500);
    }
  }, [showEmptySpecificKpisGroups, setShowConfetti]);

  const renderStep = () => {
    if (showLoading) {
      return (
        <div className={classNames(classes.UserKPIDeepDiveStep, className)}>
          <GenericLoading />
        </div>
      );
    }
    if (showEmptySpecificKpisGroups) {
      return (
        <EmptySpecificKpisGroups
          className={className}
          onSubmit={onSubmit}
          disabled={isSubmittingQuestionnaire}
          showConfetti={showConfetti}
        />
      );
    }
    if (showSpecificKpisGroups) {
      return (
        <SpecificKpisGroups
          groupedMetrics={groupedMetrics}
          className={className}
          isSubmittingQuestionnaire={isSubmittingQuestionnaire}
          onSubmit={onSubmit}
          onChangeConfirmSubscription={onChangeConfirmSubscription}
          onSelectChange={onSelectChange}
          onSkipClick={onSkipClick}
        />
      );
    }
  };

  return <FormProvider {...formMethods}>{renderStep()}</FormProvider>;
};
