import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {get} from 'lodash';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {calculateBbox} from '../../../../../core/konva/stage.utils';
import {
  BaseChartOptions,
  ChartAnnotation as ChartAnnotationModel,
  ChartWithAnnotationOptions,
  ChartWithLabelsOptions,
  ChartWithLabelsXYOptions,
  ChartWithLinesOptions,
  ChartWithMinMaxXYOptions,
  ChartWithIncompleteDataOptions,
  LabelBasedChart,
  NumberDataset,
  XYDataset,
  ChartWithMarkAreaOptions,
  ChartWithQuarterOptions,
} from '../../chart-data.types';
import {AxisProperties, XAxisProperties, AXIS_VARIANT} from '../../chart-inner.types';
import {GridLayout} from '../../layouts/grid-layout.component';
import {
  LineChartDatasetContainer,
  OnDatasetPointClickParam,
} from './line-chart-dataset-container.component';
import {useChartLabels, useRange} from '../../chart.hooks';
import {ChartContext, ChartContextProvider} from '../../chart.context';
import {AnnotationsContainer} from './components/annotations-container.component';
import moment from 'moment';
import {exists} from 'front-core';
import {ResizeRender} from '../../../../hoc/resize-render/resize-render.component';
import {DEFAULT_INPUT_DATE_FORMAT} from '../../../../../consts/ui';
import classes from '../../chart.module.scss';
import {calculateMaxTicks, getDatasetValues, transformXToNumeric} from '../../chart.utils';
import {LineChartTooltip} from './components/line-chart-tooltip.component';
import {QuarterMarksContainer} from './components/quarter-marks-container.component';

export interface LineChartDatasetOptions {
  [datasetId: string]: {
    dashed?: boolean;
  };
}

export interface LineChartOptions
  extends BaseChartOptions,
    ChartWithLabelsXYOptions,
    ChartWithMinMaxXYOptions,
    ChartWithLabelsOptions,
    ChartWithAnnotationOptions,
    ChartWithLinesOptions,
    ChartWithIncompleteDataOptions,
    ChartWithMarkAreaOptions,
    ChartWithQuarterOptions {
  showDots?: boolean;
  showTooltipPreviousValueDelta?: boolean;
  curve?: boolean;
  datasets?: LineChartDatasetOptions;
  highlightIds?: string[];
  area?: boolean;
  errorBar?: boolean;
  yAxisMaxTicks?: number;
  minimalXAxisTicks?: boolean;
  showHideCI?: boolean;
  showHideAnnotation?: boolean;
  disableLegend?: boolean;
}

interface OwnProps extends LabelBasedChart<NumberDataset | XYDataset, LineChartOptions> {
  onAnnotationClicked?: (annotation: ChartAnnotationModel[]) => void;
  onDatasetPointClick?: (param: OnDatasetPointClickParam) => void;
  pointTooltipTitle?: string;
  pointTooltipCta?: string;
}

type AllProps = OwnProps;

export const TREND_PADDING_TOP = 40;

const LineChartController: React.FC<AllProps> = (props: AllProps) => {
  const {controller} = useContext(StageContext);
  const {
    displayedDatasets: datasets_,
    datasets: allDatasets_,
    datasetLabels,
    onLegendClick,
  } = useContext(ChartContext);
  const {
    onDatasetClick,
    onDatasetPointClick: onDatasetPointClickFromProps,
    options,
    labels: labelsFromProps,
    onAnnotationClicked,
    pointTooltipTitle,
    pointTooltipCta,
  } = props;
  const {
    datasets: datasetOptions,
    labels: labelOptions,
    legendsLabel,
    showLegend,
    xLabel,
    yLabel,
    curve,
    showDots,
    showTooltipPreviousValueDelta,
    area,
    errorBar: errorBar_,
    annotations: annotations_,
    lines: lines_,
    yLabelSuffix: yLabelSuffix_,
    xLabelSuffix: xLabelSuffix_,
    yAxisMaxTicks,
    showIncompleteMark,
    incompleteMarkDescription,
    minimalXAxisTicks,
    markAreas: markAreas_,
    showQuarters,
    showHideCI = true,
    showHideAnnotation = true,
    disableLegend,
  } = options;
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [errorBar, setErrorBar] = useState(errorBar_);
  /**
   * Computed properties
   */
  const labels: string[] = useChartLabels(labelsFromProps, labelOptions);
  const isDateLabels: boolean = useMemo(() => get(labelOptions, 'type') === 'date', [labelOptions]);
  const xTickTransformer = useCallback(
    (value: string | number) =>
      transformXToNumeric(isDateLabels, labelOptions?.dateInputFormat, value),
    [isDateLabels, labelOptions]
  );
  const transformer = useCallback(
    (ds: NumberDataset | XYDataset) => {
      let newDs = {...ds};
      const firstItem = newDs.data[0];
      // make sure all values are numbers
      if (typeof firstItem === 'number') {
        newDs = {
          ...newDs,
          // @ts-ignore
          data: newDs.data.map((v, idx) => ({
            ...v,
            x: labelsFromProps[idx],
            y: v,
          })),
        };
      }

      let newData = newDs.data.map(v => ({
        ...v,
        y: exists(v.y) ? Number(v.y) : 0,
        x: xTickTransformer(v.x),
        actualX: v.actualX ? xTickTransformer(v.actualX) : undefined,
      }));
      // This enables us to 'enlarge' our chart drawing area
      // And it's being utilized to draw our incomplete rectangle mark
      if (isDateLabels && showIncompleteMark && newDs.data.length >= 1) {
        const lastItem: any = newDs.data[newDs.data.length - 1];
        const lastItemX = moment.utc(lastItem.x);
        // This enables us to not crush when we're given a ds with only one point
        const overrideSecondFromLastItemIndex = newDs.data.length === 1;
        const secondFromLastItem: any =
          newDs.data[newDs.data.length - (overrideSecondFromLastItemIndex ? 1 : 2)];
        const secondFromLastItemX = moment.utc(secondFromLastItem.x);
        const differenceInDays = lastItemX.diff(secondFromLastItemX, 'days');
        const daysInMonth = secondFromLastItemX.daysInMonth();
        // check if the difference is a multiple of the number of days in a month
        let newX;
        if (differenceInDays % daysInMonth === 0) {
          // The difference is monthly
          newX = lastItemX.add(1, 'months');
        } else if (labelOptions?.timeUnit === 'week') {
          newX = lastItemX.add(7, 'days');
        } else {
          // The difference is daily
          newX = lastItemX.add(differenceInDays, 'days');
        }
        newData = [
          ...newData,
          {
            ...lastItem,
            y: exists(lastItem.y) ? Number(lastItem.y) : 0,
            x: xTickTransformer(newX.format(DEFAULT_INPUT_DATE_FORMAT)),
          },
        ];
      }

      return {
        ...newDs,
        data: newData,
      };
    },
    [isDateLabels, labelOptions, labelsFromProps, showIncompleteMark]
  );
  const annotations = useMemo(
    () =>
      isDateLabels && annotations_
        ? annotations_.map(a => ({
            ...a,
            timestamp: moment
              .utc(a.timestamp, labelOptions?.dateInputFormat || DEFAULT_INPUT_DATE_FORMAT)
              .valueOf(),
          }))
        : [],
    [annotations_, isDateLabels, labelOptions]
  );
  const lines = useMemo(() => {
    return (
      lines_ &&
      lines_.map(line => ({
        ...line,
        position:
          isDateLabels && line.direction === 'vertical'
            ? moment
                .utc(line.position, labelOptions?.dateInputFormat || DEFAULT_INPUT_DATE_FORMAT)
                .valueOf()
            : line.position,
      }))
    );
  }, [lines_, isDateLabels, labelOptions]);
  const markAreas = useMemo(() => {
    return (
      markAreas_ &&
      markAreas_.map(markArea => ({
        ...markArea,
        from: isDateLabels
          ? moment
              .utc(markArea.from, labelOptions?.dateInputFormat || DEFAULT_INPUT_DATE_FORMAT)
              .valueOf()
          : markArea.from,
        to: isDateLabels
          ? moment
              .utc(markArea.to, labelOptions?.dateInputFormat || DEFAULT_INPUT_DATE_FORMAT)
              .valueOf()
          : markArea.from,
      }))
    );
  }, [markAreas_, isDateLabels, labelOptions]);
  const datasets: XYDataset[] = useMemo(
    () => datasets_.map(transformer).filter(ds => ds.data.length > 0),
    [datasets_, transformer]
  );
  const allDatasets: XYDataset[] = useMemo(
    () => allDatasets_.map(transformer),
    [allDatasets_, transformer]
  );
  const layoutBbox = useMemo(
    () => calculateBbox(controller?.getSize().width, controller?.getSize().height),
    [controller]
  );
  // We're supporting 2 Y-Axis on the chart and therefore the segregation to primary and secondary
  const showSecondaryYAxis = useMemo(
    () => allDatasets.some(d => d?.yAxis === AXIS_VARIANT.SECONDARY),
    [allDatasets]
  );
  const {xValues, primaryYValues, secondaryYValues} = useMemo(
    () => getDatasetValues(datasets, errorBar, lines, markAreas),
    [datasets, errorBar, lines, markAreas]
  );
  // Primary Y-Axis logic
  const primaryYRange = useRange(primaryYValues, options.yMin, options.yMax, true);
  const primaryYLabelSuffix = useMemo(() => (yLabelSuffix_ ? yLabelSuffix_ : ''), [yLabelSuffix_]);
  const primaryYAxisProp: AxisProperties = useMemo(
    () => ({
      label: yLabel,
      suffix: primaryYLabelSuffix,
      range: primaryYRange,
      maxTicks: yAxisMaxTicks,
    }),
    [yLabel, primaryYLabelSuffix, primaryYRange, yAxisMaxTicks]
  );
  // Secondary Y-Axis logic
  const secondaryYRange = useRange(
    secondaryYValues,
    options?.secondaryYMin,
    options?.secondaryYMax
  );
  const secondaryYAxisProp: AxisProperties = useMemo(
    () =>
      showSecondaryYAxis
        ? {
            label: options.secondaryYLabel || '',
            suffix: options.secondaryYSuffix || '',
            range: secondaryYRange,
          }
        : undefined,
    [options.secondaryYLabel, options.secondaryYSuffix, secondaryYRange, showSecondaryYAxis]
  );
  // X-Axis logic
  const [transformedOptionalXMax, transformedOptionalXMin] = useMemo(
    () =>
      [options.xMax, options.xMin].map(optionalXValue =>
        optionalXValue ? xTickTransformer(optionalXValue) : undefined
      ),
    [options.xMax, xTickTransformer, options.xMin]
  );
  const xRange = useRange(xValues, transformedOptionalXMin, transformedOptionalXMax, false);
  const xAxisProp: XAxisProperties = useMemo(
    () => ({
      label: xLabel,
      suffix: xLabelSuffix_ ? xLabelSuffix_ : '',
      range: xRange,
      isTimeRangeMS: isDateLabels,
      maxTicks: calculateMaxTicks(
        Math.max(...datasets.map(d => d.data.length)),
        xRange,
        labelOptions?.timeUnit
      ),
      timeUnit: labelOptions?.timeUnit,
    }),
    [xLabel, xLabelSuffix_, xRange, isDateLabels, datasets, labelOptions?.timeUnit]
  );
  const descriptionsLegends = useMemo(() => {
    let match = false;
    // Temporary - Ido Adiv asked for this ability quickly
    for (const ds of allDatasets) {
      if (ds.id.toLowerCase() === 'expected') {
        return null;
      }
    }

    let selectedDs;
    for (const ds of allDatasets) {
      match = Boolean(ds.data.find(d => d.dashed));
      if (match && ds.dashedDescription) {
        selectedDs = ds;
        break;
      }
    }
    if (selectedDs) {
      return [{color: selectedDs.color, description: selectedDs.dashedDescription}];
    }
  }, [allDatasets]);
  const chartBBoxPaddingTop = useMemo(() => {
    let _hasTrends = false;
    for (const ds of allDatasets) {
      if (_hasTrends) {
        continue;
      }
      _hasTrends = Boolean(ds.data.find(p => p?.isTrend));
    }
    return _hasTrends ? 20 : 0;
  }, [allDatasets]);

  const onDatasetPointClick = useCallback(
    (param: OnDatasetPointClickParam) => {
      if (!onDatasetPointClickFromProps) {
        return;
      }
      const enhancedParam = {
        ...param,
        additionalProperties: {
          ...(param.additionalProperties || {}),
          timeUnit: labelOptions?.timeUnit || 'day',
        },
      };
      onDatasetPointClickFromProps(enhancedParam);
    },
    [onDatasetPointClickFromProps, labelOptions?.timeUnit]
  );
  /**
   * Render
   */
  if (datasets.length === 0) {
    return null;
  }
  return (
    <GridLayout
      {...layoutBbox}
      labels={labels}
      xAxis={xAxisProp}
      yAxis={primaryYAxisProp}
      secondaryYAxis={secondaryYAxisProp}
      legendLabels={datasetLabels}
      legendsTitle={legendsLabel || undefined}
      showLegend={showLegend}
      dateFormat={labelOptions?.dateFormat}
      onLabelClicked={(label, e) => onLegendClick(label.datasetId, e)}
      descriptionsLegends={descriptionsLegends}
      chartBBoxPaddingTop={chartBBoxPaddingTop}
      showIncompleteMark={showIncompleteMark}
      incompleteMarkDescription={incompleteMarkDescription}
      minimalXAxisTicks={minimalXAxisTicks}
      showAnnotations={showAnnotations}
      onChangeShowAnnotations={annotations?.length > 0 ? v => setShowAnnotations(v) : undefined}
      errorBar={showHideCI ? errorBar : errorBar_}
      onChangeErrorBar={errorBar_ ? v => setErrorBar(v) : undefined}
      showHideCI={showHideCI}
      showHideAnnotation={showHideAnnotation}
      disableLegend={disableLegend}
    >
      {props => {
        return (
          <>
            {isDateLabels && showQuarters && (
              <QuarterMarksContainer {...props} markAreas={markAreas} />
            )}
            <LineChartDatasetContainer
              {...props}
              lines={lines}
              markAreas={markAreas}
              datasets={datasets}
              showDots={showDots}
              showTooltipPreviousValueDelta={showTooltipPreviousValueDelta}
              curve={curve}
              datasetOptions={datasetOptions}
              onDatasetClick={onDatasetClick}
              onDatasetPointClick={onDatasetPointClickFromProps ? onDatasetPointClick : undefined}
              dateFormat={labelOptions?.dateFormat}
              timeUnit={labelOptions?.timeUnit}
              area={area}
              errorBar={showHideCI ? errorBar : errorBar_}
              yLabelSuffix={primaryYAxisProp?.suffix}
              secondaryYLabelSuffix={secondaryYAxisProp?.suffix}
              xLabelSuffix={xAxisProp.suffix}
              xLabel={xAxisProp.label}
              pointTooltipTitle={pointTooltipTitle}
              pointTooltipCta={pointTooltipCta}
            />
            {isDateLabels && annotations?.length > 0 && showAnnotations && (
              <AnnotationsContainer
                {...props}
                dateFormat={labelOptions?.dateFormat}
                annotations={annotations}
                onAnnotationClicked={onAnnotationClicked}
              />
            )}
          </>
        );
      }}
    </GridLayout>
  );
};

export const LineChart: React.FC<AllProps> = (props: AllProps) => {
  return (
    <div className={classes.ChartContainer}>
      <ResizeRender>
        {/*
          💡 In the line chart we're controlling the tooltip.
          This ↓ tooltip is used for incomplete mark
        */}
        <ChartContextProvider {...props} TooltipComponent={LineChartTooltip}>
          <LineChartController {...props} />
        </ChartContextProvider>
      </ResizeRender>
    </div>
  );
};

LineChart.defaultProps = {
  options: {
    highlightIds: [],
    annotations: [],
    lines: [],
  },
};
