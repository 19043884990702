import * as React from 'react';
import {useMemo} from 'react';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {Cases, SqlElementType} from '../query-builder.types';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {concatPath, QueryBuilderFactory} from '../query-builder.utils';
import classNames from 'classnames';
import {queryBuilderClasses} from '../query-builder.component';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {get} from 'lodash';
import {QueryBlock} from '../components/query-block/query-block.component';
import {InlineToken} from '../components/parts';
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  CopyIcon,
} from '../../../simple/controls/icons/icons.component';
import {useQueryArray, useQueryElement} from '../query-builder.hooks';

interface OwnProps extends Omit<QueryBuilderComponent, 'data'> {
  data: Cases;
}

type AllProps = OwnProps;

export const CasesBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, disabled, className} = props;
  const {localErrors, onDelete} = useQueryElement(props);
  const cases = useMemo(() => data.cases || [], [data]);
  const {addElement, removeElement, cloneElement, moveElement} = useQueryArray(
    cases,
    data => onChange(concatPath(path, 'cases'), data),
    () => QueryBuilderFactory.createCase()
  );

  return (
    <QueryTree
      disabled={disabled}
      actions={[
        {
          icon: AddIcon,
          label: 'Add',
          onClick: addElement,
        },
        {
          icon: CloseIcon,
          label: 'Remove',
          onClick: onDelete,
        },
      ]}
      label={'Cases'}
      className={classNames(queryBuilderClasses.QueryTree, className)}
    >
      {cases.map((case_, idx) => (
        <QueryBlock
          key={idx}
          label={`Case ${idx + 1}`}
          disabled={disabled}
          collapsible
          actions={[
            {
              icon: ArrowUpIcon,
              label: 'Up',
              onClick: () => moveElement(idx, idx - 1),
              hidden: idx === 0,
            },
            {
              icon: ArrowDownIcon,
              label: 'Down',
              onClick: () => moveElement(idx, idx + 1),
              hidden: idx === cases.length - 1,
            },
            {
              icon: CopyIcon,
              label: 'Duplicate',
              onClick: () => cloneElement(idx),
            },
            {
              icon: CloseIcon,
              label: 'Remove',
              onClick: () => removeElement(idx),
            },
          ]}
        >
          <InlineToken>WHEN</InlineToken>
          <QueryElementBuilder
            data={case_[0]}
            accept={[
              SqlElementType.CONDITION,
              SqlElementType.AND_CONDITION,
              SqlElementType.OR_CONDITION,
            ]}
            error={Boolean(get(localErrors, `cases.${idx}.0`))}
            className={queryBuilderClasses.Inline}
            path={concatPath(path, `cases.${idx}.0`)}
            onDelete={() => onChange(concatPath(path, `cases.${idx}.0`), {})}
            inline
          />
          <InlineToken>THEN</InlineToken>
          <QueryElementBuilder
            data={case_[1]}
            error={Boolean(get(localErrors, `cases.${idx}.1`))}
            className={queryBuilderClasses.Inline}
            path={concatPath(path, `cases.${idx}.1`)}
            onDelete={() => onChange(concatPath(path, `cases.${idx}.1`), {})}
            box={false}
            inline
          />
        </QueryBlock>
      ))}
      <QueryBlock box={false} label={'Else'}>
        <QueryElementBuilder
          error={Boolean(localErrors.else_value)}
          className={queryBuilderClasses.Inline}
          path={concatPath(path, 'else_value')}
          data={data.else_value}
        />
      </QueryBlock>
    </QueryTree>
  );
};

CasesBuilder.defaultProps = {};
