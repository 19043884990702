import * as React from 'react';
import classNames from 'classnames';
import classes from './dropdown-button.module.scss';
import {TooltipIfOverflow} from '../../generic/tooltips/tooltips.component';
import {exists} from 'front-core';
import {BaseComponentTheme, useUITheme} from '../../../../core/ui-theme/ui-theme.context';
import {ChevronFullDownIcon} from '../icons/icons.component';
import {useMemo} from 'react';
import {HoverHelperTip} from '../../data-display/hover-helper-tip/hover-helper-tip.component';

export interface DropdownButtonTheme extends BaseComponentTheme {}

interface OwnProps {
  id?: string;
  label?: any;
  helperText?: string;
  prefix?: string;
  placeholder?: any;
  onClick: (e) => void;
  icon?: any;
  isOpen?: boolean;
  disabled?: boolean;
  actions?: any;
  capitalize?: boolean;
  className?: string;
  fullWidth?: boolean;
  error?: boolean;
  rounded?: boolean;
  border?: boolean;
  hideText?: boolean;
  hideArrow?: boolean;
  iconPosition?: 'start' | 'end';
}

type AllProps = OwnProps;

const DropdownButton: React.FC<AllProps> = (props: AllProps) => {
  const {
    id,
    label,
    prefix,
    placeholder,
    onClick,
    icon: Icon,
    isOpen,
    disabled,
    className,
    fullWidth,
    capitalize = true,
    actions,
    error,
    rounded,
    hideArrow,
    border = true,
    hideText,
    iconPosition = 'start',
    helperText,
  } = props;
  const localTheme = useUITheme<DropdownButtonTheme>('DropdownButton');
  const placeholderMode = !exists(label);
  const text = useMemo(() => {
    if (hideText) {
      return '';
    }
    return placeholderMode ? placeholder : label;
  }, [hideText, placeholderMode, placeholder, label]);

  const icon = Icon && (
    <div
      className={classNames(
        classes.IconWrapper,
        iconPosition === 'start' && classes.IconPositionStart,
        iconPosition === 'end' && classes.IconPositionEnd
      )}
    >
      <Icon />
    </div>
  );
  return (
    <>
      <div
        id={id}
        onClick={disabled ? undefined : onClick}
        className={classNames(
          classes.DropdownButton,
          !border && classes.NoBorder,
          isOpen && classes.Open,
          disabled && classes.Disabled,
          fullWidth && classes.FullWidth,
          capitalize && classes.Capitalize,
          error && classes.Error,
          placeholderMode && classes.Placeholder,
          rounded && classes.Rounded,
          localTheme.rootClassName,
          className
        )}
      >
        {iconPosition === 'start' && icon}
        <TooltipIfOverflow title={text} key={text} interactive={false}>
          <div className={classes.Label}>
            {prefix && <span className={classes.Prefix}>{prefix}</span>}
            {text}
          </div>
        </TooltipIfOverflow>
        {iconPosition === 'end' && icon}
        {actions && <div className={classes.AdditionalActions}>{actions}</div>}
        {!hideArrow && (
          <ChevronFullDownIcon className={classNames(classes.ActionIcon, classes.Arrow)} />
        )}
      </div>
      {helperText && <HoverHelperTip title={helperText} />}
    </>
  );
};

DropdownButton.defaultProps = {};
export {DropdownButton};
