import {createContext, useCallback, useMemo, useRef, useState} from 'react';
import {RCAFigure, RCAMode} from '../../../../types';
import {useRCAPeriods, UseRCAPeriodsReturnType} from './hooks/use-rca-periods.hook';
import {exists} from 'front-core';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {useMountState} from '../../../../../../../hooks/use-mount-state.hook';

interface IRCAFigureViewerContext {
  renderMode: RCAMode;
  mode: RCAMode;
  isComparedSignificantChange: boolean;
  isExpectedTrendSignificantChange: boolean;
  rcaPeriods: UseRCAPeriodsReturnType;
  shouldUseThreshold: boolean;
  uplift: number;
  onChangeMode: (mode: RCAMode) => void;
  showFakeLoader: boolean;
}

export const RCAFigureViewerContext = createContext<IRCAFigureViewerContext>({
  renderMode: undefined,
  mode: undefined,
  isComparedSignificantChange: undefined,
  isExpectedTrendSignificantChange: undefined,
  rcaPeriods: undefined,
  shouldUseThreshold: undefined,
  onChangeMode: undefined,
  showFakeLoader: undefined,
  uplift: undefined,
});

interface OwnProps extends RCAFigure {
  children: any;
}
type AllProps = OwnProps;

const FAKE_DELAY_CHANGE_MODE_MS = 1000;

export const RCAFigureViewerContextProvider: React.FC<AllProps> = (props: AllProps) => {
  const {children, initialMode, id, version = 1, data, options} = props;
  const defaultMode = initialMode || options.defaultMode || RCAMode.LOOPS_ALGO;
  const {query: mode, setQuery: setMode} = useDocQuery(id, defaultMode, 'mode');
  const [renderMode, setRenderMode] = useState<RCAMode>(mode);
  const [showFakeLoader, setShowFakeLoader] = useState<boolean>(false);
  const isMounted = useMountState();

  const onChangeMode = useCallback(
    newMode => {
      if (mode === newMode || showFakeLoader) {
        return;
      }
      setMode(newMode);
      setShowFakeLoader(true);
      setTimeout(() => {
        if (isMounted) {
          setShowFakeLoader(false);
          setRenderMode(newMode);
        }
      }, FAKE_DELAY_CHANGE_MODE_MS);
    },
    [setMode, mode, showFakeLoader, isMounted]
  );
  const shouldUseThreshold = useMemo(() => !exists(version) || version < 1.1, [version]);
  const rcaPeriods = useRCAPeriods({data, options, shouldUseThreshold});
  const uplift = useMemo(() => {
    if (mode === RCAMode.LOOPS_ALGO) {
      return rcaPeriods.expected.uplift;
    }
    return rcaPeriods.compared.uplift;
  }, [rcaPeriods, mode]);

  const contextValue = useMemo(
    () => ({
      renderMode,
      mode,
      rcaPeriods,
      isComparedSignificantChange: rcaPeriods.compared.isSignificant,
      isExpectedTrendSignificantChange: rcaPeriods.expected.isSignificant,
      onChangeMode,
      shouldUseThreshold,
      showFakeLoader,
      uplift,
    }),
    [renderMode, rcaPeriods, onChangeMode, shouldUseThreshold, showFakeLoader, uplift, mode]
  );

  return (
    <RCAFigureViewerContext.Provider value={contextValue}>
      {children}
    </RCAFigureViewerContext.Provider>
  );
};
