import {useCallback, useMemo, useState, useEffect, useRef, FC} from 'react';
import classNames from 'classnames';
import classes from './habit-moment-viewer.module.scss';
import {
  AnalysisAlgoMode,
  DocumentElement,
  DocumentElementType,
  HabitMomentFigure,
  HabitMomentFigureItem,
  HabitMomentFigureItemInstance,
  HabitMomentFigureItemInstanceType,
  SmartTableFigurePaginationMode,
  SPECIAL_SMART_TABLE_DATA_KEYS,
} from '../../../../types';
import {exists} from 'front-core';
import {every, get, values} from 'lodash';
import {NoticePopup} from '../../../shared/general/notice-popup/notice-popup.component';
import TransKeys from 'translations';
import {AppTabs} from '../../../../../../simple/navigation/app-tabs/app-tabs.component';
import {ChildRenderer} from '../../../core/child-renderer.component';
import {useHabitMomentColumnsHook} from './hooks/use-habit-moment-columns.hook';
import {
  countRelevantItems,
  extendHabitMomentFigureItems,
  filterInstanceByMode,
  getBestInstance,
  getDefaultViewMode,
  getHabitOrderByMode,
  instanceFilterByMode,
} from './habit-moment-viewer.utils';
import {SwitchHabitMode} from './components/switch-habit-mode/switch-habit-mode.component';
import {ViewHabitMomentVariantModal} from './components/view-habit-moment-variant-modal/view-habit-moment-variant-modal.component';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {DocumentMessage} from '../../../shared/general/document-message/document-message.component';
import pluralize from 'pluralize';
import {useNoCausationsNotice} from './hooks/use-no-causations-notice.hook';

interface OwnProps extends HabitMomentFigure {
  className?: string;
}

type AllProps = OwnProps;

export type InstancesCountByMode = {
  [value in HabitMomentFigureItemInstanceType]: {
    [value in HabitMomentViewMode]: number;
  };
};

export interface ExtendedHabitMomentFigureItemInstance extends HabitMomentFigureItemInstance {
  key: string;
  uplift: number;
  adoptionAbs: number;
  maxPotential?: number;
  maxPotentialUpper?: number;
  maxPotentialLower?: number;
}

export type ByType<T> = {
  [value in HabitMomentFigureItemInstanceType]?: T;
};
export type RangeByType = ByType<[number, number]>;

export interface ExtendedHabitMomentFigureItem extends Omit<HabitMomentFigureItem, 'instances'> {
  instances: ByType<ExtendedHabitMomentFigureItemInstance[]>;
  upliftRange: RangeByType;
  correlationRange: RangeByType;
  maxPotentialRange: RangeByType;
  instancesCountByMode: InstancesCountByMode;
}

export enum HabitMomentViewMode {
  UPLIFT = 'uplift',
  CORRELATION = 'correlation',
  CAUSATION = 'causation',
}

const INITIAL_TOP_COUNT = 5;

export const CORRELATION_MODES = [HabitMomentViewMode.UPLIFT, HabitMomentViewMode.CORRELATION];

export const HabitMomentViewer: FC<AllProps> = (props: AllProps) => {
  const {id, data, options, className} = props;
  const {items, totalUsers} = data;
  const {trackNavigation, trackModeChanged, trackItemClicked} = useDocumentTracking(
    id,
    DocumentElementType.HABIT_MOMENT_FIGURE
  );
  const {t} = useDocumentTranslation();
  const [highlightModeSelection, setHighlightModeSelection] = useState(false);
  const [selected, setSelected] = useState<ExtendedHabitMomentFigureItem>(null);

  const triedToSwitchToUpliftWhenNoCausation = useRef(false);

  const {query: selectedType, setQuery: setSelectedType_} =
    useDocQuery<HabitMomentFigureItemInstanceType>(
      id,
      HabitMomentFigureItemInstanceType.REPEATED_ACTIONS,
      'type'
    );
  const {query: selectedMode, setQuery: setSelectedMode_} = useDocQuery<HabitMomentViewMode>(
    id,
    getDefaultViewMode(data.mode),
    'mode'
  );
  // SelectedType & SelectedMode setter + getter
  const setSelectedType = useCallback(
    (item: HabitMomentFigureItemInstanceType) => {
      trackNavigation(item);
      setSelectedType_(item);
    },
    [setSelectedType_, trackNavigation]
  );
  const setSelectedMode = useCallback(
    (mode: HabitMomentViewMode) => {
      trackModeChanged(mode);
      setSelectedMode_(mode);
      setHighlightModeSelection(false);
    },
    [setSelectedMode_, trackModeChanged]
  );
  // Transform Data
  const extendedItems: ExtendedHabitMomentFigureItem[] = useMemo(
    () => extendHabitMomentFigureItems(items, totalUsers, data.goalValue, data.mode),
    [items, totalUsers, data.mode]
  );
  const itemOptions = useMemo(() => {
    return extendedItems
      .filter(i => i.instances[selectedType].length > 1)
      .map(i => {
        const modes = [];
        if (i.instancesCountByMode[selectedType][HabitMomentViewMode.CAUSATION] > 0) {
          modes.push(HabitMomentViewMode.CAUSATION);
        }
        if (i.instancesCountByMode[selectedType][HabitMomentViewMode.UPLIFT] > 0) {
          modes.push(HabitMomentViewMode.UPLIFT);
        }
        if (i.instancesCountByMode[selectedType][HabitMomentViewMode.CORRELATION] > 0) {
          modes.push(HabitMomentViewMode.CORRELATION);
        }
        return {
          label: i.habitName,
          value: i.key,
          modes,
        };
      });
  }, [extendedItems, selectedType]);

  // Listeners
  const onViewItem = useCallback(
    (key: string | null) => {
      if (key === null) {
        setSelected(null);
        return;
      }
      const item = extendedItems.find(ex => ex.key === key);
      setSelected(item);
      trackItemClicked(item.key, {
        name: item.habitName,
        type: options.habitModelName,
      });
    },
    [setSelected, extendedItems, trackItemClicked, options.habitModelName]
  );
  const tabs = useMemo(() => {
    return values(HabitMomentFigureItemInstanceType).map(type => ({
      key: type,
      label: `${t(
        TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABS[type.toUpperCase()]
      )} (${countRelevantItems(extendedItems, type, selectedMode, true)})`,
    }));
  }, [t, extendedItems, selectedMode]);
  // SmartTableFigure Props
  const tableData = useMemo(() => {
    return extendedItems
      .map(ei => {
        const isRelevantForMode = countRelevantItems([ei], selectedType, selectedMode, true) > 0;
        if (!isRelevantForMode) {
          return;
        }
        // Count relevant instances
        const instancesCount = ei.instances[selectedType].filter(i =>
          filterInstanceByMode(i, selectedMode)
        ).length;
        // Calculate best instance
        const bestInstanceOptions = ei.instances[selectedType].filter(i =>
          instanceFilterByMode(i, selectedMode)
        );
        const bestInstance = getBestInstance(
          bestInstanceOptions,
          selectedMode,
          options.higherIsBetter
        );

        return {
          key: ei.key,
          name: ei.habitName,
          command: ei.habitCommand,
          dataType: ei.dataType,
          info: get(
            ei,
            `${selectedType}Info${
              selectedMode === HabitMomentViewMode.CAUSATION ? 'Causation' : ''
            }`
          ),
          repeats: bestInstance ? bestInstance.repeats : null,
          timeframeDays: bestInstance ? bestInstance.timeframeDays : null,
          adoptionRate: bestInstance ? bestInstance.adoptionRate * 100 : null,
          goalInAdopters: bestInstance
            ? bestInstance.goalInAdopters * (options.isPercentageValue ? 100 : 1)
            : null,
          goalInNonAdopters: bestInstance
            ? bestInstance.goalInNonAdopters * (options.isPercentageValue ? 100 : 1)
            : null,
          adoptionAbs: bestInstance ? bestInstance.adoptionAbs : null,
          uplift: bestInstance ? bestInstance.uplift * 100 : null,
          maxPotential: bestInstance ? bestInstance.maxPotential * 100 : null,
          maxPotentialUpper: bestInstance ? bestInstance.maxPotentialUpper * 100 : null,
          maxPotentialLower: bestInstance ? bestInstance.maxPotentialLower * 100 : null,
          correlation: bestInstance ? bestInstance.correlation : null,
          instancesCount: instancesCount,
          upliftRange: ei.upliftRange,
          correlationRange: ei.correlationRange,
          maxPotentialRange: ei.maxPotentialRange,
          [SPECIAL_SMART_TABLE_DATA_KEYS.DISABLED]: instancesCount <= 1 || !exists(bestInstance),
        };
      })
      .filter(i => i);
  }, [
    selectedType,
    selectedMode,
    extendedItems,
    options.isPercentageValue,
    options.higherIsBetter,
  ]);

  const noCausationsNoticeContainerRef = useRef(null);

  const {setShowNoCausationsNotice, noCausationNotice} = useNoCausationsNotice(
    noCausationsNoticeContainerRef
  );

  // attempt to switch to uplift mode if no relevant data is available in causations
  useEffect(() => {
    if (triedToSwitchToUpliftWhenNoCausation.current) {
      return;
    }

    const countRepeatedDaysCausationItems = countRelevantItems(
      extendedItems,
      HabitMomentFigureItemInstanceType.REPEATED_DAYS,
      HabitMomentViewMode.CAUSATION,
      true
    );
    const countRepeatedActionsCausationItems = countRelevantItems(
      extendedItems,
      HabitMomentFigureItemInstanceType.REPEATED_ACTIONS,
      HabitMomentViewMode.CAUSATION,
      true
    );

    if (
      selectedMode === HabitMomentViewMode.CAUSATION &&
      countRepeatedDaysCausationItems === 0 &&
      countRepeatedActionsCausationItems === 0
    ) {
      setSelectedMode(HabitMomentViewMode.UPLIFT);
      setShowNoCausationsNotice(true);
    }

    triedToSwitchToUpliftWhenNoCausation.current = true;
  }, [extendedItems, triedToSwitchToUpliftWhenNoCausation]);

  const itemsHasTotalUsers = useMemo(() => every(items.map(i => exists(i.totalUsers))), [items]);

  const tableColumns = useHabitMomentColumnsHook({
    ...options,
    selectedMode,
    selectedType,
    onViewItem,
    itemsHasTotalUsers,
  });

  const getTableMessage = useCallback(
    (emptyState = false) => {
      const tPrefix = TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE;
      const parts = [
        emptyState ? tPrefix.CAUSAL_EMPTY_STATE_PART_1 : tPrefix.CAUSAL_HIDDEN_ITEMS_HELPER_PART_1,
        emptyState
          ? tPrefix.CAUSAL_EMPTY_STATE_PART_SWITCH_VIEW_BUTTON
          : tPrefix.CAUSAL_HIDDEN_ITEMS_HELPER_PART_SWITCH_VIEW_BUTTON,
        emptyState ? tPrefix.CAUSAL_EMPTY_STATE_PART_2 : tPrefix.CAUSAL_HIDDEN_ITEMS_HELPER_PART_2,
      ];

      const content = (
        <div>
          <span className={classes.Part}>
            {t(parts[0], {
              model: pluralize(options.habitModelName).toLowerCase(),
            })}
          </span>
          <span
            onClick={() => setSelectedMode(HabitMomentViewMode.UPLIFT)}
            className={classNames(classes.Part, classes.ClickablePart)}
            onMouseEnter={() => setHighlightModeSelection(true)}
            onMouseLeave={() => setHighlightModeSelection(false)}
          >
            {t(parts[1])}
          </span>
          <span className={classes.Part}>
            {t(parts[2], {
              model: pluralize(options.habitModelName).toLowerCase(),
            })}
          </span>
        </div>
      );

      if (emptyState) {
        return content;
      }

      return <DocumentMessage border={false} className={classes.Info} text={content} />;
    },
    [options.habitModelName, t, setHighlightModeSelection, setSelectedMode]
  );
  const tableFigure: DocumentElement[] = useMemo(
    () =>
      ({
        id: `${id}_root_table`,
        dataKey: 'key',
        type: DocumentElementType.SMART_TABLE,
        columns: tableColumns,
        data: tableData,
        onClick: item => onViewItem(item.key),
        options: {
          pagination: true,
          perPage: INITIAL_TOP_COUNT,
          paginationMode: SmartTableFigurePaginationMode.TOP_X,
          // significantDataKey: 'isSignificant',
          bottomMessage:
            selectedMode === HabitMomentViewMode.CAUSATION &&
            tableData.length < extendedItems.length &&
            tableData.length > 0
              ? getTableMessage()
              : undefined,
          emptyState:
            selectedMode === HabitMomentViewMode.CAUSATION ? getTableMessage(true) : undefined,
          defaultSort: {
            orderBy: getHabitOrderByMode(selectedMode),
            order: options.higherIsBetter ? 'desc' : 'asc',
          },
        },
      }) as any,
    [
      tableColumns,
      tableData,
      extendedItems.length,
      id,
      selectedMode,
      options.higherIsBetter,
      onViewItem,
      getTableMessage,
    ]
  );
  const selectedItemAcceptedModes = useMemo(() => {
    if (!selected) {
      return;
    }
    const modes: HabitMomentViewMode[] = [];
    const hasCausal =
      selected.instancesCountByMode[selectedType][HabitMomentViewMode.CAUSATION] > 0;
    const hasCorrelation =
      selected.instancesCountByMode[selectedType][HabitMomentViewMode.CORRELATION] > 0;
    if (hasCorrelation) {
      modes.push(...CORRELATION_MODES);
    }
    if (hasCausal && data.mode === AnalysisAlgoMode.CAUSATION) {
      modes.push(HabitMomentViewMode.CAUSATION);
    }
    return modes;
  }, [selected, selectedType, data.mode]);
  const explainerText = useMemo(
    () =>
      selectedType === HabitMomentFigureItemInstanceType.REPEATED_ACTIONS
        ? t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.BY_ACTIONS_TAB_EXPLAINER_TEXT)
        : t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.BY_DAYS_TAB_EXPLAINER_TEXT),
    [selectedType]
  );

  return (
    <div className={classNames(classes.HabitMomentFigure, className)}>
      <div className={classes.Tabs}>
        <AppTabs tabs={tabs} selected={selectedType} onChange={setSelectedType} fullWidth />
        <div ref={noCausationsNoticeContainerRef}>
          <SwitchHabitMode
            className={classNames(
              classes.SwitchHabitMode,
              highlightModeSelection && classes.Highlight
            )}
            setMode={setSelectedMode}
            mode={selectedMode}
            modes={data.mode === AnalysisAlgoMode.CAUSATION ? undefined : CORRELATION_MODES}
          />
          <NoticePopup
            {...noCausationNotice}
            placement={'bottom-end'}
            zIndex={900}
            show={noCausationNotice.show}
          />
        </div>
      </div>
      <div className={classes.Content}>
        {explainerText && <div className={classes.Explainer}>{explainerText}</div>}
        <ChildRenderer children_={tableFigure} key={selectedMode} />
      </div>
      <ViewHabitMomentVariantModal
        id={id}
        selected={selected}
        setSelected={onViewItem}
        mode={selectedMode}
        itemOptions={itemOptions}
        type={selectedType}
        options={options}
        modeOptions={selectedItemAcceptedModes}
        goalValue={data.goalValue}
      />
    </div>
  );
};

HabitMomentViewer.defaultProps = {};
