import {
  ExpandableLabel,
  ExpandableLabelProps,
} from '../../generic-components/expandable-label/expandable-label.component';
import classes from './kpi-info.module.scss';
import {KPIStatusLine} from '../kpi-status-line/kpi-status-line.component';
import {GrowthMapIcon} from '../../generic-components/growth-map-icon/growth-map-icon.component';
import TimelineIcon from '@material-ui/icons/Timeline';
import * as React from 'react';
import {KPIElement} from '../../../../../types/growth-map.types';

interface OwnProps extends Omit<ExpandableLabelProps, 'label'> {
  kpi: KPIElement;
  onKPIClicked?: () => void;
  isExpanded?: boolean;
  onExpand?: () => void;
  model?: string;
}

export const KPIInfo = (props: OwnProps) => {
  const {kpi, onKPIClicked, model} = props;

  return (
    <ExpandableLabel {...props} label={kpi.title || kpi.data?.name}>
      {kpi.data?.samples?.length > 0 && (
        <KPIStatusLine
          className={classes.InfoItem}
          samples={kpi.data?.samples || []}
          higherIsBetter={kpi.data?.higherIsBetter}
          round={kpi.options?.round}
          model={model}
          noWrap
        />
      )}
      {kpi.metricId && (
        <GrowthMapIcon variant={'dark'} onClick={e => onKPIClicked()} icon={TimelineIcon} />
      )}
    </ExpandableLabel>
  );
};
