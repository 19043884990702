import {BaseModel} from './base.model';
import {Annotation} from './annotation.model';
import {HistorifyModel} from './historify-model.model';
import {TableEntity} from './table.model';
import {AdHocModel} from './ad-hoc-model.model';
import {AggregationMode, ValidationStatus} from './signal.model';
import {Team} from './team.model';
import {EventPropertiesModel} from './event-property.model';
import {ModelSeriesGranularity} from './model-sample-series.model.ts';

export enum MetricValueType {
  NUMERIC = 'numeric',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}

export enum MetricCadence {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  FOUR_WEEKS = 'four_weeks',
}

// Only add metric types that are supported by the UI.
// Currently excluding monetization, engagement, and acquisition on purpose.
export enum MetricType {
  RETENTION = 'retention',
  DOD = 'dod',
  WOW = 'wow',
  MOM = 'mom',
  CONVERSION = 'conversion',
  REVENUE = 'revenue',
  BOUNDED_REVENUE = 'bounded_revenue',
  PAYMENT_RETENTION = 'payment_retention',
  REVENUE_CHURN = 'revenue_churn',
  BEHAVIORAL_CHURN = 'behavioral_churn',
  DAU = 'dau',
  WAU = 'wau',
  MAU = 'mau',
  L7 = 'l7',
  L28 = 'l28',
  USAGE = 'usage',
  RATE = 'rate',
  HABIT_MOMENT = 'habit_moment',
  CUSTOM_SQL = 'custom_sql',
}

export interface MetricGranularityOptions {
  defaultGranularities: ModelSeriesGranularity[];
  availableGranularities: ModelSeriesGranularity[];
}

export interface MetricCategory {
  id: number;
  name: string;
  displayOrder: number;
  metrics?: Metric[];
}

export interface Metric extends BaseModel, HistorifyModel, AdHocModel, EventPropertiesModel {
  name: string;
  productId: number;
  signalId: number;
  type: string;
  entity: TableEntity;
  valueType: MetricValueType;
  cadence: MetricCadence;
  sampledGranularities: ModelSeriesGranularity[];
  partialUnit: 'day' | 'week' | 'month';
  partialCount: number;
  shortDescription: string;
  signalDefinition: any;
  signalPopulationFilter: any;
  categoryId?: number;
  categoryName?: string;
  ownerUserId?: number;
  ownerUserName?: string;
  totalWarnsCount: number;
  higherIsBetter: boolean;
  teams: Team[];
  lastSample: {
    value: number;
    expectedValue: number;
    upper: number;
    lower: number;
    datetime: string;
    sampleDatetime: string;
  };
  quarterStartingSample: {
    value: number;
    expectedValue: number;
    upper: number;
    lower: number;
    datetime: string;
  };
  goal: {id?: number; value: number; startDate: string; endDate: string};
  opportunitiesCount: number;
  metricMaterial?: {
    id: number;
    kpiAnalysisId: number;
    kpiAnalysisResultId: number;
    goalDriversAnalysisId: number;
    goalDriversAnalysisResultId: number;
    engagementDriversAnalysisId: number;
    engagementDriversAnalysisResultId: number;
  };
  isNorthStar: boolean;
  isActive: boolean;
  notes: string;
  idleAnnotations: Partial<Annotation>[];
  signalValidationStatus?: ValidationStatus;
  aggregationMode?: AggregationMode;
}

export interface TeamMetric {
  signalId: number;
  metricId: number;
}

export interface MetricsByTeam {
  [teamId: number]: TeamMetric[];
}

export interface MetricsByCategory {
  categories: Array<MetricCategory>;
  total: number;
}

export interface MetricMaterial {
  metricId: number;
  metricName: number;
  kpiAnalysisId: number;
  goalDriversAnalysisId: number;
  engagementDriversAnalysisId: number;
  kpiAnalysisResultId: number;
  goalDriversAnalysisResultId: number;
  engagementDriversAnalysisResultId: number;
}
