import * as React from 'react';
import classes from './button.module.scss';
import classNames from 'classnames';
import {CSSProperties, forwardRef} from 'react';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  className?: string;
  style?: CSSProperties;
  children?: any;
  icon?: any;
  endIcon?: any;
  variant?: 'contained' | 'outlined';
  appearance?: 'primary' | 'critical';
  size?: 'medium' | 'large';
  onClick: (e) => void;
  disabled?: boolean;
  active?: boolean;
  type?: 'button' | 'submit' | 'reset';
  helperText?: string;
  caps?: boolean;
}

type AllProps = OwnProps;

export const Button: React.FC<AllProps> = forwardRef((props: AllProps, ref: any) => {
  const {
    className,
    style,
    children,
    onClick,
    icon: Icon,
    endIcon: EndIcon,
    size,
    variant,
    active,
    disabled,
    appearance,
    type,
    helperText,
    caps,
  } = props;

  return (
    <Tooltip title={helperText ? helperText : ''} placement={'top'} interactive={false}>
      <button
        type={type}
        ref={ref}
        style={style}
        className={classNames(
          classes.Button,
          classes[variant],
          classes[size],
          disabled && classes.Disabled,
          active && classes.Active,
          caps && classes.Caps,
          appearance === 'critical' && classes.Critical,
          className
        )}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
      >
        {Icon && <Icon className={classes.Icon} />}
        {children}
        {EndIcon && <EndIcon className={classes.EndIcon} />}
      </button>
    </Tooltip>
  );
});

Button.defaultProps = {
  variant: 'contained',
  size: 'medium',
  appearance: 'primary',
  active: false,
  caps: true,
};
