import {RefObject, useState} from 'react';
import TransKeys from 'translations';
import {WarningIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';

export const useNoCausationsNotice = (ref: RefObject<HTMLElement>) => {
  const {t} = useDocumentTranslation();

  const [showNoCausationsNotice, setShowNoCausationsNotice] = useState(false);

  const noCausationNotice = {
    anchorEl: ref.current,
    show: showNoCausationsNotice,
    message: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.NO_CAUSATIONS_NOTICE.MESSAGE),
    confirmLabel: t(
      TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.NO_CAUSATIONS_NOTICE.CONFIRM_LABEL
    ),
    icon: WarningIcon,
    onConfirm: () => setShowNoCausationsNotice(false),
  };
  return {
    setShowNoCausationsNotice,
    noCausationNotice,
  };
};
