import * as React from 'react';
import classNames from 'classnames';
import {VersusColumnOptions} from '../../../../../types';
import {useMemo} from 'react';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists, number2k, toFixedNumber} from 'front-core';
import classes from './data-columns.module.scss';
import tableClasses from '../../smart-table.module.scss';
import {getDataValueKey} from '../../smart-table.utils';
import {ColorUtil} from '../../../../../document-viewer.utils';
import {DataColumnProps} from '../../smart-table.types';
import {ArrowIconUp, MinusIcon} from '../../../../../../../simple/controls/icons/icons.component';

interface OwnProps extends DataColumnProps<VersusColumnOptions> {}

type AllProps = OwnProps;

const GOOD_COLOR = ColorUtil.getColor('good');
const BAD_COLOR = ColorUtil.getColor('bad');
const MUTED_COLOR = ColorUtil.getColor('muted');

const VersusDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, dataColumnOptions, className} = props;
  const {versusDataKey} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);

  const value = useMemo(() => data[valueDataKey], [valueDataKey, data]);
  const versus = useMemo(() => data[versusDataKey], [versusDataKey, data]);
  const unit = useMemo(
    () => dataColumnOptions?.unit || column.options.unit || '',
    [column.options, dataColumnOptions]
  );
  const chip = useMemo(() => {
    if (column.typeOptions.hideIcon) {
      return null;
    }
    const {mode: mode_, higherIsBetter} = column.typeOptions;
    let mode = mode_;
    if (!exists(versus)) {
      return null;
    }
    // TODO: this is incorrect, in case versus is 0 uplift should return null ("inf")
    if (versus === 0 || !exists(mode_)) {
      mode = 'difference';
    }

    let number;
    let color;
    let localUnit;
    switch (mode) {
      case 'change':
        number = toFixedNumber(((value - versus) / Math.abs(versus)) * 100, 2);
        localUnit = '%';
        break;
      case 'difference':
        number = toFixedNumber(value - versus, 2);
        localUnit = unit;
        break;
    }
    let localBadColor = BAD_COLOR;
    let localGoodColor = GOOD_COLOR;

    if (higherIsBetter === false) {
      localBadColor = GOOD_COLOR;
      localGoodColor = BAD_COLOR;
    }
    if (number > 0) {
      color = localGoodColor;
    }
    if (number < 0) {
      color = localBadColor;
    }
    if (number === 0) {
      color = MUTED_COLOR;
    }

    return {
      component: number === 0 ? MinusIcon : ArrowIconUp,
      number,
      color,
      unit: localUnit,
    };
  }, [column.typeOptions, value, versus, unit]);

  return (
    <div className={classNames(classes.VersusDataColumn, className)}>
      {chip && (
        <chip.component
          style={{color: chip.color}}
          className={classNames(classes.Arrow, chip.number < 0 && classes.Down)}
        />
      )}
      <span className={classes.Value}>{exists(value) ? `${number2k(value)}${unit}` : '--'}</span>
      {exists(versus) && (
        <span className={classNames(classes.VS, tableClasses.ShowOnHover)}>
          <span className={classes.VSStr}>vs</span>
          {exists(versus) ? `${number2k(versus)}${unit}` : '--'}
        </span>
      )}
    </div>
  );
};
const protector: Protector = (props: OwnProps) => true; // exists(props.data[getDataValueKey(props.column, 'valueDataKey')]);
export const VersusDataColumn = withDataColumnProtector(protector)(VersusDataColumnComponent);
