import i18n from '../config/i18n.config';
import TransKeys from '../constants/translation-keys';
import {ToastType} from '../objects/system/toast-type.enum';
import {flatten, get} from 'lodash';
import {PropertyPath, removeUndefinedKeys} from 'front-core';
import {store} from './app.store';
import {CORE_STORE_KEY} from './core/core.store';
import {AnyAction} from 'redux';
import {DISABLED_ACTIONS_FOR_DEMO_PRODUCT} from '../constants/demo-disabled-actions';
import {ErrorCode} from '../constants/error-code.ts';

export type ToastActionType =
  | 'CREATE_SUCCESS'
  | 'CREATE_ERROR'
  | 'UPDATE_SUCCESS'
  | 'UPDATE_ERROR'
  | 'DELETE_SUCCESS'
  | 'DELETE_ERROR'
  | 'GET_ERROR'
  | 'RERUN_SUCCESS'
  | 'RERUN_ERROR'
  | 'RESCAN_SUCCESS'
  | 'RESCAN_ERROR'
  | 'STOP_SUCCESS'
  | 'STOP_ERROR';
export type Model =
  | 'experiment'
  | 'opportunity'
  | 'analysis'
  | 'growth_map'
  | 'analysis_result'
  | 'goal'
  | 'annotation'
  | 'feature'
  | 'content'
  | 'metric'
  | 'metric_category'
  | 'milestone'
  | 'signal'
  | 'funnel'
  | 'segment'
  | 'segment_category'
  | 'event_group'
  | 'phenomena'
  | 'table'
  | 'user_setting'
  | 'analysis_folder'
  | 'table_event'
  | 'experiment_automation'
  | 'workflow'
  | 'member'
  | 'team'
  | 'source'
  | 'homepage_subscription'
  | 'share';

export const createToastMessage = (model: Model, type: ToastActionType) => {
  const modelName = i18n.t(TransKeys.MODELS[model.toUpperCase()]);
  return {
    message: i18n.t(TransKeys.TOASTS[type], {model: modelName}),
    type: type.includes('ERROR') ? ToastType.ERROR : ToastType.SUCCESS,
  };
};

export const errorExtractor = err => ({
  errorCode: get(err, 'data.error_code'),
  message: get(err, 'data.message'),
  status: get(err, 'data.status'),
  payload: get(err, 'data.payload'),
});

export const getter = (storeKey: string, path: PropertyPath) =>
  get(store.getState(), flatten([storeKey, path]).join('.'));

export const mergeQueryParams = (prevState: any, change: any) => {
  if (!change) {
    return prevState;
  }

  const newFilters = {
    ...prevState,
  };

  for (const key in change) {
    if (change[key] === undefined) {
      delete newFilters[key];
    } else {
      newFilters[key] = change[key];
    }
  }
  return removeUndefinedKeys(newFilters);
};

export const getDMPToastErrorMessage = (err: any, modelType: Model) => {
  let toastMessage = '';
  const modelName = i18n.t(TransKeys.MODELS[modelType.toUpperCase()]);
  if (err.errorCode === ErrorCode.DUPLICATED_NAME) {
    toastMessage = `${modelName} name already is taken`;
  } else if (err.errorCode === ErrorCode.SIGNAL_VALIDATION_ERROR) {
    toastMessage = `Error while creating ${modelName}`;
  }
  return toastMessage;
};

export const demoProductLimitedActionsFilter = (_: any, action: AnyAction) => {
  if (DISABLED_ACTIONS_FOR_DEMO_PRODUCT.includes(action.type as any) === false) {
    return true;
  }
  const productData = getter(CORE_STORE_KEY, 'productData');
  return productData?.isDemoProduct === false;
};
