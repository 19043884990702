import {ModalWrapper} from '../../../../modal-wrapper/modal-wrapper.component';
import {FancyHeader} from '../../../../../../../../simple/data-display/fancy-header/fancy-header.component';
import {
  ShieldCheckLightIcon,
  ShieldExclamationSolidIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {
  ReleaseImpactSummaryValidationConfidenceLevel,
  ReleaseImpactSummaryValidationResults,
  ReleaseImpactSummaryValidationType,
} from '../../../../../../types';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import classes from './validations-more-info-panel.module.scss';
import {useMemo} from 'react';

type ValidationsMoreInfoPanelProps = {
  onClose: VoidFunction;
  selectedValidationType: ReleaseImpactSummaryValidationType;
  validationConfidenceLevel: ReleaseImpactSummaryValidationConfidenceLevel;
  selectedValidation: ReleaseImpactSummaryValidationResults[ReleaseImpactSummaryValidationType];
};

export const ValidationsMoreInfoPanel = (props: ValidationsMoreInfoPanelProps) => {
  const {onClose, selectedValidation, validationConfidenceLevel, selectedValidationType} = props;
  const {t} = useDocumentTranslation();

  const bottomLineMessage = useMemo(() => {
    if (validationConfidenceLevel !== ReleaseImpactSummaryValidationConfidenceLevel.HIGH) {
      return t(
        TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.BOTTOM_LINE_MESSAGE
          .LOW_CONFIDENCE
      );
    }
    if (selectedValidation.conclusion) {
      return t(
        TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.BOTTOM_LINE_MESSAGE
          .TEST_PASSED_HIGH_CONFIDENCE
      );
    }
    return t(
      TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.BOTTOM_LINE_MESSAGE
        .TEST_FAILED_HIGH_CONFIDENCE
    );
  }, [t, validationConfidenceLevel, selectedValidation]);

  return (
    <ModalWrapper isOpen onClose={onClose} width={'50rem'} height={'40rem'}>
      <FancyHeader
        title={t(
          TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.MORE_INFO_PANEL_TITLE
        )}
        icon={ShieldCheckLightIcon}
      />
      <div className={classes.ValidationMoreInfoPanelContent}>
        <div className={classes.ValidationTitle}>
          {t(
            TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS
              .VALIDATION_TYPE_LABEL
          )}
          {t(
            TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.TYPES[
              selectedValidationType.toUpperCase()
            ].LABEL
          )}
        </div>
        <div className={classes.ValidationExplainerContainer}>
          <ShieldExclamationSolidIcon />
          <div>
            {t(
              TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.TYPES[
                selectedValidationType.toUpperCase()
              ].EXPLAINER,
              {
                ...selectedValidation.data,
              }
            )}
          </div>
        </div>
        <div className={classes.ValidationDetailsContainer}>
          <span className={classes.Title}>
            {t(TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.MORE_INFO_LABEL)}
          </span>
          {t(
            TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.TYPES[
              selectedValidationType.toUpperCase()
            ].MORE_INFO_MESSAGE
          )}
        </div>
        <div className={classes.ValidationDetailsContainer}>
          <span className={classes.Title}>
            {t(
              TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS
                .MITIGATION_STEPS_LABEL
            )}
          </span>
          {t(
            TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS.TYPES[
              selectedValidationType.toUpperCase()
            ].MITIGATION_STEPS_MESSAGE
          )}
        </div>
        <div className={classes.ValidationDetailsContainer}>{bottomLineMessage}</div>
      </div>
    </ModalWrapper>
  );
};
