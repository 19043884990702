import * as React from 'react';
import classNames from 'classnames';
import classes from './label-wrapper.module.scss';
import {exists} from 'front-core';
import {HoverHelperTip} from '../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';

export interface LabelWrapperProps {
  label?: any;
  children: any;
  size?: 'default' | 'small' | 'large';
  required?: boolean;
  error?: boolean;
  tooltipContent?: any;
  helperText?: any;
  className?: string;
  fullWidth?: boolean;
  maxLength?: number;
  renderRight?: JSX.Element;
  mutedLabel?: boolean;
  boldLabel?: boolean;
  currentLength?: number;
  marginBottom?: string;
}

type AllProps = LabelWrapperProps;

export const LabelWrapper: React.FC<AllProps> = (props: AllProps) => {
  const {
    label,
    children,
    size,
    className,
    error,
    helperText,
    tooltipContent,
    required,
    fullWidth,
    maxLength,
    renderRight,
    currentLength,
    mutedLabel,
    boldLabel,
    marginBottom = '0.4rem',
  } = props;

  return (
    <div
      className={classNames(
        classes.LabelWrapper,
        classes[size],
        error && classes.Error,
        fullWidth && classes.FullWidth,
        mutedLabel && classes.MutedLabel,
        boldLabel && classes.BoldLabel,
        className
      )}
    >
      {label && (
        <div className={classes.Label} style={{marginBottom}}>
          <div className={classes.LabelText}>
            {label} {required && <span>*</span>}
          </div>
          {tooltipContent && (
            <HoverHelperTip className={classes.HoverHelperTip} title={tooltipContent} />
          )}
          <div className={classes.Spacer} />
          {exists(maxLength) && (
            <div>
              {currentLength || 0} / {maxLength}
            </div>
          )}
          {renderRight && <div className={classes.Right}>{renderRight}</div>}
        </div>
      )}
      <div className={classNames(classes.Children)}>{children}</div>
      {helperText && <div className={classes.HelperText}>{helperText}</div>}
    </div>
  );
};

LabelWrapper.defaultProps = {
  size: 'default',
  fullWidth: true,
};
