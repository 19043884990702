import * as React from 'react';
import classes from './date-picker.module.scss';
import {default as ReactDatePicker, DatePickerProps} from 'react-datepicker';
import moment from 'moment';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import classNames from 'classnames';
import {convertDateToUTC} from 'front-core';
import {useMemo} from 'react';
import {isArray} from 'lodash';
import {registerLocale} from 'react-datepicker';
// register locale
import {enGB} from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB);

interface OwnProps {
  variant?: 'light' | 'dark';
  utc?: boolean;
  filterDate?: (date: Date) => boolean;
}

export const DatePicker: React.FC<DatePickerProps & OwnProps> = (
  props: DatePickerProps & OwnProps
) => {
  const {
    variant,
    utc,
    minDate: minDate_,
    maxDate: maxDate_,
    filterDate,
    showMonthYearPicker,
  } = props;

  const minDate = useMemo(
    () =>
      minDate_
        ? new Date(minDate_.getFullYear(), minDate_.getMonth(), minDate_.getDate())
        : undefined,
    [minDate_]
  );
  const maxDate = useMemo(
    () =>
      maxDate_
        ? new Date(maxDate_.getFullYear(), maxDate_.getMonth(), maxDate_.getDate())
        : undefined,
    [maxDate_]
  );

  const renderCustomHeader = props => {
    const {
      date,
      //changeYear,
      //changeMonth,
      decreaseMonth,
      increaseMonth,
      decreaseYear,
      increaseYear,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
      prevYearButtonDisabled,
      nextYearButtonDisabled,
    } = props;
    const momDate = moment(date);

    return (
      <div
        className={classNames(
          classes.DatePickerHeader,
          showMonthYearPicker && classes.MonthPicker,
          variant === 'dark' && classes.Dark
        )}
      >
        <div
          onClick={e => {
            if (showMonthYearPicker) {
              !prevYearButtonDisabled && decreaseYear();
            } else {
              !prevMonthButtonDisabled && decreaseMonth();
            }
          }}
          className={classNames(
            classes.Move,
            !showMonthYearPicker && prevMonthButtonDisabled && classes.Disabled,
            showMonthYearPicker && prevYearButtonDisabled && classes.Disabled
          )}
        >
          <ArrowLeftIcon className={classes.IconBtn} />
        </div>
        {!showMonthYearPicker && (
          <div className={classes.HeaderDate}>{momDate.format('MMM YY')}</div>
        )}
        {showMonthYearPicker && <div className={classes.HeaderDate}>{momDate.format('YYYY')}</div>}
        <div
          onClick={e => {
            if (showMonthYearPicker) {
              !nextYearButtonDisabled && increaseYear();
            } else {
              !nextMonthButtonDisabled && increaseMonth();
            }
          }}
          className={classNames(
            classes.Move,
            !showMonthYearPicker && nextMonthButtonDisabled && classes.Disabled,
            showMonthYearPicker && nextYearButtonDisabled && classes.Disabled
          )}
        >
          <ArrowRightIcon className={classes.IconBtn} />
        </div>
      </div>
    );
  };

  const selected = useMemo(
    () => (utc && props.selected ? convertDateToUTC(props.selected) : props.selected),
    [props.selected, utc]
  );
  const startDate = useMemo(
    () => (utc && props.startDate ? convertDateToUTC(props.startDate) : props.startDate),
    [props.startDate, utc]
  );
  const endDate = useMemo(
    () => (utc && props.endDate ? convertDateToUTC(props.endDate) : props.endDate),
    [props.endDate, utc]
  );
  const dayClassNameSetter = (date: Date) => {
    if (date < minDate || date > maxDate) {
      return 'react-datepicker__day--outside-month';
    }
    if (filterDate && !filterDate(date)) {
      return 'react-datepicker__day--disabled';
    }
    return undefined;
  };

  const onChange = (value, e) => {
    if (utc) {
      value = isArray(value)
        ? value.map(v => (v ? convertDateToUTC(v) : v))
        : convertDateToUTC(value);
    }
    props.onChange(value, e);
  };

  return (
    <ReactDatePicker
      {...props}
      selected={selected}
      minDate={minDate}
      maxDate={maxDate}
      startDate={startDate}
      endDate={endDate}
      calendarClassName={`react-datepicker__variant-${variant}`}
      renderCustomHeader={renderCustomHeader}
      dayClassName={dayClassNameSetter}
      disabledKeyboardNavigation
      onChange={onChange}
      filterDate={filterDate}
    />
  );
};

DatePicker.defaultProps = {
  variant: 'light',
  utc: true,
};
