import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './rca-segmentation-composite-modal.module.scss';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {RCASegmentationFigureOptions, SPECIAL_SMART_TABLE_DATA_KEYS} from '../../../../../../types';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';
import {useTopExplainersSmartTable} from '../../hooks/use-top-explainers-smart-table.hook';
import {RCASegmentationViewMode} from '../../../rca-figure/rca-figure-viewer.types';
import TransKeys from 'translations';
import {useTranslation} from '../../../../../../../../../core/translations/use-translation';

type Composite = {
  segments: UnifiedRCASegmentationItem[];
} & UnifiedRCASegmentationItem;

interface OwnProps {
  composite: Composite;
  options: RCASegmentationFigureOptions;
  populationTrend: number;
  viewMode: RCASegmentationViewMode;
  onClose: () => void;
  className?: string;
}

type AllProps = OwnProps;

const transPrefix = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.COMPOSITE_OVERTIME_MODAL;

export const RCASegmentationCompositeModal: React.FC<AllProps> = (props: AllProps) => {
  const {composite, populationTrend, onClose, options, viewMode, className} = props;
  const {t} = useTranslation();

  const segments = useMemo(() => {
    const res = composite.segments.map(s => ({
      ...s,
      [SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]: classes.SegmentRow,
    }));
    res.push({
      ...composite,
      segmentName: t(transPrefix.COMPOSITE_ITEM_TEXT), // hack to render name
      [SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]: classes.CompositeRow,
      order: -1,
    } as any);
    return res;
  }, [composite]);

  const tableFigure = useTopExplainersSmartTable({
    groupName: true,
    options,
    segments,
    populationTrend,
    viewMode,
    isCompositeMode: true,
    key: 'segment-composite-modal',
  });

  return (
    <ModalWrapper isOpen={composite} onClose={onClose} width={'100rem'} height={'auto'}>
      <div className={classNames(classes.RCACompositeModal, className)}>
        <div className={classes.Title}>{t(transPrefix.COMPOSITE_ITEM_TEXT)}</div>
        <ChildRenderer children_={tableFigure} />
      </div>
    </ModalWrapper>
  );
};
