import * as React from 'react';
import classNames from 'classnames';
import classes from '../smart-table.module.scss';

interface OwnProps {
  className?: string;
  text: string;
}

type AllProps = OwnProps;

export const TableMutedTest: React.FC<AllProps> = (props: AllProps) => {
  const {text, className} = props;

  return <div className={classNames(classes.TableMutedTest, className)}>{text}</div>;
};

TableMutedTest.defaultProps = {};
