import {useCallback, useEffect, useMemo, useState} from 'react';
import classes from './rca-funnel-figure-viewer.module.scss';
import {Select} from '../../../../../../forms/inputs/select/select.component';
import {LabelWrapper} from '../../../../../../forms/related/label-wrapper/label-wrapper.component';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {FunnelOverviewFigureStep, RCAFunnelFigure} from '../../../../types';
import {RCAFigureViewer} from '../rca-figure/rca-figure-viewer.component';
import {sortItemsBySteps} from './rca-funnel-figure.utils';
import {keyBy} from 'lodash';
import {exists} from 'front-core';

type RCAFunnelFigureViewerProps = RCAFunnelFigure & {
  targetSignalId?: number;
  onSelectRCATargetFunnelStep?: (targetSignalId: number) => void;
};

export const RCAFunnelFigureViewer = (props: RCAFunnelFigureViewerProps) => {
  const {
    data: {items: unsortedItems, steps: unsortedSteps},
    targetSignalId,
    onSelectRCATargetFunnelStep,
  } = props;
  const {t} = useDocumentTranslation();

  const stepLabelsMap: Record<string, FunnelOverviewFigureStep> = useMemo(
    () => keyBy(unsortedSteps, 'signalId'),
    [unsortedSteps]
  );
  const sortedItems = useMemo(
    () => sortItemsBySteps(unsortedItems, unsortedSteps),
    [unsortedItems, unsortedSteps]
  );

  const funnelCompletionItemIndex = sortedItems.length - 1;

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(funnelCompletionItemIndex);

  useEffect(() => {
    if (exists(targetSignalId)) {
      const newSelectedIndex = sortedItems.findIndex(
        item => item.toStepSignalId === targetSignalId
      );
      if (newSelectedIndex !== -1) {
        setSelectedItemIndex(newSelectedIndex);
      } else {
        // default to funnel completion if no index is provided
        setSelectedItemIndex(sortedItems.length - 1);
      }
    }
  }, [sortedItems, targetSignalId, setSelectedItemIndex]);

  const selectOptions = useMemo(
    () =>
      sortedItems.map((item, itemIndex) => {
        if (itemIndex === funnelCompletionItemIndex) {
          return {
            label: t('DOCUMENT_VIEWER.RCA_FUNNEL_FIGURE.FUNNEL_COMPLETION_LABEL'),
            value: itemIndex,
          };
        }

        const fromStepLabel = stepLabelsMap[item.fromStepSignalId]?.label;
        const toStepLabel = stepLabelsMap[item.toStepSignalId]?.label;
        return {
          label: `${fromStepLabel} -> ${toStepLabel}`,
          value: itemIndex,
        };
      }),
    [sortedItems, funnelCompletionItemIndex]
  );

  const onChange = useCallback(
    (itemIndex: number) => {
      if (onSelectRCATargetFunnelStep) {
        onSelectRCATargetFunnelStep(
          itemIndex !== funnelCompletionItemIndex
            ? sortedItems[itemIndex].toStepSignalId
            : undefined
        );
        return;
      }

      setSelectedItemIndex(itemIndex);
    },
    [onSelectRCATargetFunnelStep, setSelectedItemIndex]
  );

  return (
    sortedItems &&
    selectOptions && (
      <div>
        <div className={classes.SelectContainer}>
          <LabelWrapper
            size="small"
            mutedLabel={false}
            label={t(
              'DOCUMENT_VIEWER.RCA_FUNNEL_FIGURE.FUNNEL_STEP_RANGE_SELECT_LABEL'
            ).toUpperCase()}
          >
            <Select
              placeholder={'Select'}
              value={selectedItemIndex}
              onChange={onChange}
              searchable={false}
              clearable={false}
              sortValues={false}
              fullWidth
              options={{options: selectOptions}}
            />
          </LabelWrapper>
        </div>
        <RCAFigureViewer
          {...sortedItems[selectedItemIndex].rcaFigure}
          renderSideMenu={false}
          showExecutiveSummary={false}
        />
        ;
      </div>
    )
  );
};
