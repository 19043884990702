import * as React from 'react';
import {useCallback, useEffect, useMemo} from 'react';
import {values, isArray} from 'lodash';
import {Literal, LiteralValueType} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {Select} from '../../../forms/inputs/select/select.component';
import {BOOLEAN_OPTIONS, LiteralTypeName} from '../query-builder.config';
import {DatePickerInput} from '../../../forms/inputs/date-picker-input/date-picker-input.component';
import {TextInput} from '../../../forms/inputs/text-input/text-input.component';
import {queryBuilderClasses} from '../query-builder.component';
import {QueryBlock} from '../components/query-block/query-block.component';
import {concatPath} from '../query-builder.utils';
import {CloseIcon} from '../../../simple/controls/icons/icons.component';
import {useQueryElement} from '../query-builder.hooks';
import {InlineLabel} from '../components/inline-label/inline-label.component';
import {withViewMode} from '../core/with-view-mode.hoc';
import {InlineText} from '../components/parts';
import moment from 'moment';

interface OwnProps {
  data: Literal;
  acceptLiteralType: LiteralValueType[];
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

const NUMBERS_TYPE = [
  LiteralValueType.INTEGER,
  LiteralValueType.INTEGER_LIST,
  LiteralValueType.FLOAT,
  LiteralValueType.FLOAT_LIST,
];

const LIST_TYPE = [
  LiteralValueType.INTEGER_LIST,
  LiteralValueType.FLOAT_LIST,
  LiteralValueType.STRING_LIST,
];

export const LiteralBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {
    path,
    data,
    onDelete: onDelete_,
    onChange,
    acceptLiteralType,
    className,
    disabled,
    box,
  } = props;
  const {localErrors} = useQueryElement(props);
  const literalType = useMemo(() => data?.value_type || undefined, [data]);
  const acceptLiteralTypeSet = useMemo(
    () => new Set(acceptLiteralType || values(LiteralValueType)),
    [acceptLiteralType]
  );
  const onLiteralTypeChange = useCallback(
    v => {
      const update = {
        value_type: v || null,
        value: null,
      };
      if (data.value_type === LiteralValueType.BOOLEAN) {
        update.value = false;
      }
      onChange(path, {...data, ...update});
    },
    [data, onChange, path]
  );
  const onLiteralValueChange = useCallback(
    v => onChange(concatPath(path, `value`), v),
    [onChange, path]
  );
  const options = useMemo(() => {
    return values(LiteralValueType)
      .filter(t => acceptLiteralTypeSet.has(t))
      .map(o => ({
        value: o,
        label: LiteralTypeName[o],
      }));
  }, [acceptLiteralTypeSet]);
  const onDelete = useCallback(() => {
    if (data.value_type) {
      return onLiteralTypeChange(null);
    }
    return onDelete_(path);
  }, [data.value_type, onDelete_, path, onLiteralTypeChange]);

  useEffect(() => {
    const acceptedLiteralType =
      acceptLiteralType && acceptLiteralType.length === 1 ? acceptLiteralType[0] : undefined;
    if (acceptedLiteralType && acceptedLiteralType !== literalType) {
      onLiteralTypeChange(acceptedLiteralType);
    }
  }, [onLiteralTypeChange, acceptLiteralType, literalType]);

  const renderLiteralValue = () => {
    const sharedProps: any = {
      placeholder: 'Select',
      value: data.value === undefined ? '' : data.value,
      onChange: value => onLiteralValueChange(value),
      className: queryBuilderClasses.Inline,
      fullWidth: false,
      error: Boolean(localErrors.value),
      disabled,
    };
    const isNumeric = NUMBERS_TYPE.indexOf(data.value_type) > -1;
    const isList = LIST_TYPE.indexOf(data.value_type) > -1;

    switch (data.value_type) {
      case LiteralValueType.BOOLEAN:
        return (
          <Select
            {...sharedProps}
            options={{options: BOOLEAN_OPTIONS as any}}
            searchable={false}
            clearable={false}
          />
        );
      case LiteralValueType.DATE:
        return <DatePickerInput {...sharedProps} dateFormat={'DD/MM/YYYY'} utc />;
      case LiteralValueType.STRING:
      case LiteralValueType.FLOAT:
      case LiteralValueType.INTEGER:
      case LiteralValueType.STRING_LIST:
      case LiteralValueType.INTEGER_LIST:
      case LiteralValueType.FLOAT_LIST:
        return (
          <TextInput
            {...sharedProps}
            placeholder={`Type...`}
            multiple={isList}
            type={isNumeric ? 'number' : undefined}
            style={isList ? {minWidth: '16rem', maxWidth: disabled ? 'unset' : '80%'} : undefined}
          />
        );
      case LiteralValueType.NULL:
        return disabled ? <InlineLabel label={LiteralTypeName[LiteralValueType.NULL]} /> : null;
      default:
        return data.value !== undefined ? <TextInput {...sharedProps} /> : null;
    }
  };
  const renderLiteralSelector = () => {
    return (
      <Select
        key={`${path}__selector`}
        placeholder={'Select Type'}
        className={queryBuilderClasses.Inline}
        value={data.value_type}
        error={Boolean(localErrors.value_type)}
        options={{options}}
        onChange={onLiteralTypeChange}
        searchable={options.length > 5}
        disabled={disabled}
        clearable={false}
      />
    );
  };

  return (
    <QueryBlock
      box={box}
      disabled={disabled}
      className={className}
      label={data.value_type ? LiteralTypeName[data.value_type] : 'Literal'}
      inline
      fitContent
      actions={[
        {
          icon: CloseIcon,
          label: 'Remove',
          onClick: onDelete,
        },
      ]}
    >
      {data.value_type ? renderLiteralValue() : renderLiteralSelector()}
    </QueryBlock>
  );
};

export const LiteralViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {data, className} = props;

  const value = useMemo(() => {
    const value = data.value;

    switch (data.value_type) {
      case LiteralValueType.BOOLEAN:
        return value ? 'True' : 'False';
      case LiteralValueType.DATE:
        return moment(value as string).format('DD/MM/YYYY');
      case LiteralValueType.STRING:
      case LiteralValueType.FLOAT:
      case LiteralValueType.INTEGER:
      case LiteralValueType.STRING_LIST:
      case LiteralValueType.INTEGER_LIST:
      case LiteralValueType.FLOAT_LIST:
        return isArray(value) ? value.map(v => (v === null ? 'null' : v)) : value;
      case LiteralValueType.NULL:
        return 'Null';
      default:
        return value;
    }
  }, [data]);

  return (
    <QueryBlock className={className} disabled={true} inline={true} box={false} fitContent>
      <div className={queryBuilderClasses.FlexWrap}>
        {isArray(value) ? (
          value.map(v => <InlineLabel size={'small'} label={v.toString()} key={v.toString()} />)
        ) : (
          <InlineText>{value}</InlineText>
        )}
      </div>
    </QueryBlock>
  );
};

export const LiteralBuilder = withViewMode(LiteralBuilderComponent, LiteralViewerComponent);
