import classNames from 'classnames';
import classes from './rca-header.module.scss';

import {Checkbox} from '../../../../../../../../forms/inputs/checkbox/checkbox.component';
import {HoverHelperTip} from '../../../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {
  TrendChip,
  calcIsPositiveTrend,
} from '../../../../../../../../simple/data-display/trend-chip/trend-chip.component';
import {isNil} from 'lodash';
import {renderDates} from './rca-header.utils';
import {InfinityRegularIcon} from '../../../../../../../../simple/controls/icons/icons.component';

type RCATrendComponentProps = {
  shouldUseThreshold?: boolean;
  onSelect?: () => void;
  onDateClick: () => void;
  isDisabled?: boolean;
  isCompact?: boolean;
  label: string | React.ReactNode;
  helperText?: string;
  isSelected?: boolean;
  shouldShowCheckbox?: boolean;
  higherIsBetter?: boolean;
  uplift?: number;
  enableInfinityIconForUplift?: boolean;
  isSignificantTrend?: boolean;
  significantTrendText?: string;
  periodValueText: string;
  startDate: string;
  endDate: string;
  refDateName: string;
};

export const RCATrendInfo = (props: RCATrendComponentProps) => {
  const {
    label,
    helperText,
    isSelected,
    higherIsBetter,
    isSignificantTrend,
    shouldShowCheckbox,
    significantTrendText,
    periodValueText,
    startDate,
    endDate,
    refDateName,
    uplift,
    isCompact,
    isDisabled,
    onDateClick,
    onSelect,
  } = props;
  const isPositiveTrend = calcIsPositiveTrend(uplift, higherIsBetter);

  return (
    <div
      onClick={onSelect}
      className={classNames(
        classes.Period,
        isSelected && classes.Selected,
        isDisabled && classes.Disabled,
        isCompact && classes.Compact
      )}
    >
      <div className={classes.AAA}>
        {shouldShowCheckbox && (
          <div className={classes.Check}>
            <Checkbox checked={isSelected} onChange={onSelect} size={'large'} />
          </div>
        )}
        <div className={classes.Content}>
          <div className={classes.PeriodTitle}>
            <div className={classes.Label}>
              {label}
              {helperText && <HoverHelperTip title={helperText} small />}
            </div>
            {!isCompact && (
              <div
                className={classNames(
                  classes.Significant,
                  isSignificantTrend && isPositiveTrend && classes.Green,
                  isSignificantTrend && !isPositiveTrend && classes.Red
                )}
              >
                {significantTrendText}
              </div>
            )}
          </div>
          <div className={classes.PeriodValue}>
            {!isCompact && <div className={classes.Value}>{periodValueText}</div>}
            {!isNil(uplift) && (
              <TrendChip
                value={uplift * 100}
                higherIsBetter={higherIsBetter}
                isSignificant={isSignificantTrend}
                className={classes.TrendChip}
              />
            )}
            {uplift === null && (
              <TrendChip
                value={uplift}
                inf={true}
                higherIsBetter={higherIsBetter}
                isSignificant={isSignificantTrend}
                className={classes.TrendChip}
              />
            )}
          </div>
          {!isCompact && (
            <div className={classes.PeriodDates}>
              <span className={classes.Dates}>{renderDates({startDate, endDate})}</span>
              {refDateName && (
                <span onClick={onDateClick} className={classes.RefDate}>
                  ({refDateName})
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
