import {useEffect, useState} from 'react';
import {TableFilters} from '../smart-table.types';
import {deepDiff} from 'front-core';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {DocumentElementType} from '../../../../types';

export function useTableTracking(id, filters, sorting, pagination) {
  const {trackFilter, trackPagination, trackSort} = useDocumentTracking(
    id,
    DocumentElementType.SMART_TABLE
  );

  const [prevFilters, setPrevFilters] = useState<TableFilters>({});
  useEffect(() => {
    const diffKeys = deepDiff(prevFilters, filters);
    if (diffKeys.length === 1) {
      trackFilter(diffKeys[0], filters[diffKeys[0]]);
    }
    setPrevFilters(filters);
  }, [filters, trackFilter]);

  useEffect(() => {
    if (pagination.page !== 0) {
      trackPagination(pagination.page, {perPage: pagination.perPage});
    }
  }, [pagination.page, trackPagination]);

  useEffect(() => {
    if (sorting?.orderBy && sorting?.order) {
      trackSort(sorting.orderBy, {order: sorting.order});
    }
  }, [sorting, trackSort]);
}
