import * as React from 'react';
import classNames from 'classnames';
import classes from './query-block.module.scss';
import {QueryAction, QueryActions} from '../query-actions/query-actions.component';
import {AddIcon, SquareChevronDownIcon} from '../../../../simple/controls/icons/icons.component';
import {useCallback, useState} from 'react';
import {debounce} from 'lodash';
import {InlineLabel} from '../inline-label/inline-label.component';

interface OwnProps {
  children: any;
  label?: string;
  actions?: QueryAction[];
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  box?: boolean;
  inline?: boolean;
  collapsible?: boolean;
  fitContent?: boolean;
}

type AllProps = OwnProps;

export const QueryBlock: React.FC<AllProps> = (props: AllProps) => {
  const {
    label,
    children,
    actions,
    fullWidth,
    fitContent,
    disabled,
    box,
    className,
    inline,
    collapsible,
  } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [showActions, setShowActions_] = useState(false);
  const setShowActions = useCallback(debounce(setShowActions_, 200, {leading: true}), [
    setShowActions_,
  ]);

  return (
    <div className={classNames(classes.QueryBlock, fullWidth && classes.FullWidth, className)}>
      {!inline && !disabled && (
        <div
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
          className={classes.Head}
        >
          <div className={classes.Label}>{label}</div>
          <div className={classes.Actions}>
            {!disabled && actions?.length > 0 && (
              <QueryActions show={showActions} actions={actions} />
            )}
          </div>
        </div>
      )}
      <div
        className={classNames(
          classes.Children,
          box && classes.Box,
          inline && classes.BoxInline,
          collapsed && classes.Collapsed
        )}
      >
        <div className={classNames(classes.BodyWrapper, fitContent && classes.FitContent)}>
          {inline && !disabled && label && (
            <InlineLabel key={label} label={label} actions={actions} />
          )}
          {children}
        </div>
        {collapsible && (
          <div
            onClick={() => setCollapsed(v => !v)}
            className={classNames(classes.Collapsible, collapsed && classes.Active)}
          >
            {!collapsed && <SquareChevronDownIcon className={classes.Icon} />}
            {collapsed && <AddIcon className={classes.Icon} />}
          </div>
        )}
      </div>
    </div>
  );
};

QueryBlock.defaultProps = {
  box: true,
};
