import {safeDivision} from 'front-core';
import {FunnelSegmentationItem} from '../../../../types';
import {ExtendedFunnelSegmentationItem} from './funnel-segmentation-viewer.component';

export const extendFunnelSegmentationItems = (
  items: FunnelSegmentationItem[],
  totalEntities: number
): ExtendedFunnelSegmentationItem[] => {
  let newItems: ExtendedFunnelSegmentationItem[] = items as any;
  newItems = newItems.map(item => {
    const hasNonInformativeCount = item.countInInformativeNonSegment !== undefined;
    const size = item.countInSegment[0];
    const share = safeDivision(size, totalEntities);
    const allSegmentCount = item.countInSegment.map(
      (_, idx) => item.countInSegment[idx] + item.countInNonSegment[idx]
    );
    const segmentConversions = [];
    const nonSegmentConversions = [];
    const informativeNonSegmentConversions = [];
    const allSegmentConversions = [];

    const uplifts = [];
    const informativeUplifts = [];
    const allSegmentsUplifts = [];
    // calculate conversions
    for (let i = 1; i < item.countInSegment.length; i++) {
      segmentConversions.push(
        safeDivision(item.countInSegment[i], item.countInSegment[i - 1], true)
      );
      nonSegmentConversions.push(
        safeDivision(item.countInNonSegment[i], item.countInNonSegment[i - 1], true)
      );
      if (hasNonInformativeCount) {
        informativeNonSegmentConversions.push(
          safeDivision(
            item.countInInformativeNonSegment[i],
            item.countInInformativeNonSegment[i - 1],
            true
          )
        );
      }
      allSegmentConversions.push(safeDivision(allSegmentCount[i], allSegmentCount[i - 1], true));
    }
    // calculate uplift
    for (let i = 0; i < segmentConversions.length; i++) {
      uplifts.push(
        safeDivision(
          segmentConversions[i] - nonSegmentConversions[i],
          Math.abs(nonSegmentConversions[i]),
          true
        )
      );
      if (hasNonInformativeCount) {
        informativeUplifts.push(
          safeDivision(
            segmentConversions[i] - informativeNonSegmentConversions[i],
            Math.abs(informativeNonSegmentConversions[i]),
            true
          )
        );
      }
      allSegmentsUplifts.push(
        safeDivision(
          segmentConversions[i] - allSegmentConversions[i],
          Math.abs(allSegmentConversions[i]),
          true
        )
      );
    }

    // funnel completion conversions
    const segmentFunnelCompletionConversion = safeDivision(
      item.countInSegment[item.countInSegment.length - 1],
      item.countInSegment[0],
      true
    );
    const nonSegmentFunnelCompletionConversion = safeDivision(
      item.countInNonSegment[item.countInNonSegment.length - 1],
      item.countInNonSegment[0],
      true
    );
    let informativeNonSegmentFunnelCompletionConversion: number;
    if (hasNonInformativeCount) {
      informativeNonSegmentFunnelCompletionConversion = safeDivision(
        item.countInInformativeNonSegment[item.countInInformativeNonSegment.length - 1],
        item.countInInformativeNonSegment[0],
        true
      );
    }
    const allSegmentFunnelCompletionConversion = safeDivision(
      allSegmentCount[allSegmentCount.length - 1],
      allSegmentCount[0],
      true
    );
    // funnel completion uplift
    const funnelCompletionUplift = safeDivision(
      segmentFunnelCompletionConversion - nonSegmentFunnelCompletionConversion,
      Math.abs(nonSegmentFunnelCompletionConversion),
      true
    );
    let informativeFunnelCompletionUplift: number;
    if (hasNonInformativeCount) {
      informativeFunnelCompletionUplift = safeDivision(
        segmentFunnelCompletionConversion - informativeNonSegmentFunnelCompletionConversion,
        Math.abs(informativeNonSegmentFunnelCompletionConversion),
        true
      );
    }
    const allSegmentsFunnelCompletionUplift = safeDivision(
      segmentFunnelCompletionConversion - allSegmentFunnelCompletionConversion,
      Math.abs(allSegmentFunnelCompletionConversion),
      true
    );

    return {
      ...item,
      allSegmentCount,
      share: share,

      uplift: uplifts,
      informativeUplift: informativeUplifts,
      allSegmentsUplift: allSegmentsUplifts,

      segmentConversions,
      nonSegmentConversions,
      informativeNonSegmentConversions:
        informativeNonSegmentConversions.length > 0 ? informativeNonSegmentConversions : undefined,
      allSegmentConversions,

      segmentFunnelCompletionConversion,
      nonSegmentFunnelCompletionConversion,
      informativeNonSegmentFunnelCompletionConversion,
      allSegmentFunnelCompletionConversion,

      funnelCompletionUplift,
      informativeFunnelCompletionUplift,
      allSegmentsFunnelCompletionUplift,
      // useSimulator interface
      value: segmentConversions,
    };
  });

  return newItems;
};
