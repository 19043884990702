import * as React from 'react';
import {Transformer} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {useMemo} from 'react';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {QueryBlock} from '../components/query-block/query-block.component';
import {useQueryElement} from '../query-builder.hooks';
import {get} from 'lodash';
import {TextInput} from '../../../forms/inputs/text-input/text-input.component';
import {ParametersBuilder} from './parameters-builder.component';

interface OwnProps {
  data: Transformer;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const TransformerBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled} = props;
  const {localErrors, actions} = useQueryElement(props);
  const parameters = useMemo(() => data.parameters || [], [data]);

  return (
    <QueryTree label={'Transformer'} actions={actions} disabled={disabled} className={className}>
      <QueryBlock label={`Name`} box={false} disabled={disabled}>
        <TextInput
          placeholder={'Enter name'}
          value={data.transformer}
          onChange={v => onChange(concatPath(path, 'transformer'), v)}
          error={Boolean(localErrors.transformer)}
          disabled={disabled}
          fullWidth
        />
      </QueryBlock>
      <QueryBlock label={`Value`} box={false} disabled={disabled}>
        <QueryElementBuilder
          data={data.value}
          error={Boolean(get(localErrors, `value`))}
          path={concatPath(path, `value`)}
        />
      </QueryBlock>
      <ParametersBuilder
        data={parameters}
        onChange={v => onChange(concatPath(path, 'parameters'), v)}
        disabled={disabled}
      />
    </QueryTree>
  );
};
