import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {useCallback, useMemo} from 'react';
import {keys, isEmpty} from 'lodash';
import {useFeatureIsOn} from '@growthbook/growthbook-react';

import classes from '../../homepage-subscription-panel.module.scss';
import TransKeys from 'translations';
import {
  HomepageSubscriptionUnit,
  HomepageSettings,
  HomepageSubscriptionPushType,
  HomepageSampledModelType,
  HomepageSubscriptionSampledModelSettings,
} from '../../../../../../objects/models/homepage.model';
import {SampledModelsSettingsList} from '../sampled-model-settings-list/sampled-model-settings-list.component';
import {FeatureFlag} from '../../../../../../constants/feature-flags';

interface SampledModelsPushSettings {
  metrics: HomepageSubscriptionSampledModelSettings;
  funnels?: HomepageSubscriptionSampledModelSettings;
}

export interface SubscriptionConfigProps {
  homepageSettings: HomepageSettings;
  homepageSubscriptionUnit: HomepageSubscriptionUnit;
  sampledModelsPushSettings: SampledModelsPushSettings;
}

const SETTINGS_MAP = {
  [HomepageSubscriptionUnit.WEEKLY]: {
    [HomepageSampledModelType.METRIC]: 'weeklyMetricSettings',
    [HomepageSampledModelType.FUNNEL]: 'weeklyFunnelSettings',
  },
  [HomepageSubscriptionUnit.DAILY]: {
    [HomepageSampledModelType.METRIC]: 'dailyMetricSettings',
    [HomepageSampledModelType.FUNNEL]: 'dailyFunnelSettings',
  },
};

export const SubscriptionSampledModelsConfig = ({
  homepageSettings,
  homepageSubscriptionUnit,
  sampledModelsPushSettings,
}: SubscriptionConfigProps) => {
  const {t} = useTranslation();
  const {setValue, watch} = useFormContext();
  const isFunnelFeatureEnabled = useFeatureIsOn(FeatureFlag.FUNNEL_HOMEPAGE_SUBSCRIPTION as string);

  const translationPath = useMemo(
    () => TransKeys[`${homepageSubscriptionUnit.toUpperCase()}_SUBSCRIPTION_CONFIG`],
    [homepageSubscriptionUnit]
  );

  const metricsSettingsName =
    SETTINGS_MAP[homepageSubscriptionUnit][HomepageSampledModelType.METRIC];
  const funnelsSettingsName =
    SETTINGS_MAP[homepageSubscriptionUnit][HomepageSampledModelType.FUNNEL];
  const metricsSettings = watch(metricsSettingsName);
  const funnelsSettings = watch(funnelsSettingsName);
  const isActive = watch('isActive');

  const sampledModelSettings = useMemo(
    () => ({
      [HomepageSampledModelType.METRIC]: metricsSettings,
      [HomepageSampledModelType.FUNNEL]: funnelsSettings,
    }),
    [metricsSettings, funnelsSettings]
  );

  const sortModelsSettings = useCallback(
    (modelsSettings: HomepageSubscriptionSampledModelSettings, sampledModelsIds: number[]) => {
      if (isEmpty(modelsSettings)) {
        return [];
      }
      return Object.values(modelsSettings).sort(
        (a, b) => sampledModelsIds.indexOf(a.id) - sampledModelsIds.indexOf(b.id)
      );
    },
    []
  );

  const sampledModelDataForList = useMemo(
    () => ({
      [HomepageSampledModelType.METRIC]: sortModelsSettings(
        sampledModelsPushSettings.metrics,
        homepageSettings.metrics
      ),
      [HomepageSampledModelType.FUNNEL]: sortModelsSettings(
        sampledModelsPushSettings.funnels,
        homepageSettings.funnels
      ),
    }),
    [sampledModelsPushSettings, homepageSettings, sortModelsSettings]
  );

  const onChangeAllSampledModels = useCallback(
    (pushType: HomepageSubscriptionPushType) => {
      const updateAll = (settingsName, currentSettings) => {
        setValue(
          settingsName,
          keys(currentSettings).reduce((acc, id) => {
            acc[id] = pushType;
            return acc;
          }, {})
        );
      };
      updateAll(metricsSettingsName, metricsSettings);
      updateAll(funnelsSettingsName, funnelsSettings);
    },
    [metricsSettings, funnelsSettings, setValue, metricsSettingsName, funnelsSettingsName]
  );

  const selectedSampledModelsCounts = useMemo(() => {
    const calculateSelectedCount = settings => {
      const total = Object.keys(settings).length;
      const selected =
        total -
        Object.values(settings).filter(v => v === HomepageSubscriptionPushType.NEVER).length;
      return {total, selected};
    };

    const metricsCount = calculateSelectedCount(metricsSettings);
    if (!isFunnelFeatureEnabled || isEmpty(funnelsSettings)) {
      return metricsCount;
    }

    const funnelsCount = calculateSelectedCount(funnelsSettings);
    return {
      total: metricsCount.total + funnelsCount.total,
      selected: metricsCount.selected + funnelsCount.selected,
    };
  }, [metricsSettings, funnelsSettings, isFunnelFeatureEnabled]);

  const texts = useMemo(
    () => ({
      title: t(translationPath.TITLE),
      description: t(translationPath.DESCRIPTION),
      metricListTitle: t(translationPath.METRIC_LIST.TITLE),
      metricListSubTitle: t(translationPath.METRIC_LIST.SUB_TITLE),
      significantChangeDescription: t(translationPath.SIGNIFICANT_CHANGE_DESCRIPTION),
    }),
    [t, translationPath]
  );

  const onChange = useCallback(
    (modelId, type, pushType) => {
      const settingsName = SETTINGS_MAP[homepageSubscriptionUnit][type];
      setValue(settingsName, {
        ...sampledModelSettings[type],
        [modelId]: pushType,
      });
    },
    [homepageSubscriptionUnit, sampledModelSettings, setValue]
  );

  return (
    <div className={classes.Block}>
      <div className={classes.Header}>
        <div className={classes.Title}>
          {texts.metricListTitle}
          {` - ${selectedSampledModelsCounts.selected} out of ${selectedSampledModelsCounts.total}`}
        </div>
        {texts.metricListSubTitle && (
          <div className={classes.Description}>{texts.metricListSubTitle}</div>
        )}
      </div>
      <div className={classes.MetricSettings}>
        <SampledModelsSettingsList
          disabled={!isActive}
          sampledModelsData={sampledModelDataForList}
          settings={sampledModelSettings}
          onChange={onChange}
          onChangeAll={onChangeAllSampledModels}
          mode={homepageSubscriptionUnit}
        />
      </div>
    </div>
  );
};
