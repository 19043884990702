import {AnalysisSelectorProps} from '../analysis-selector.types';
import {TableEntity} from '../../../../../../objects/models/table.model';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import classes from './release-impact-measurement-timeline-selector.module.scss';
import {Checkbox} from 'ui-components';
import TransKeys from '../../../../../../constants/translation-keys';
import {useCallback, useMemo} from 'react';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {useTranslation} from 'react-i18next';
import pluralize from 'pluralize';
import {GradualReleaseSelector} from './gradual-release-selector/gradual-release-selector.component';
import classNames from 'classnames';
import {createUndefinedObject} from '../../../../../../utils/general.utils.ts';
import {PrePostPeriodSelector} from './pre-post-period-selector/pre-post-period-selector.component.tsx';

interface SchemaKeysMapping {
  gradual_release_query: string;
  gradual_versions: string;
  pre_release_date: string;
  post_release_date: string;
  days_before: string;
  max_days_from_release: string;
  measurement_timeline_mode: string;
  release_date: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  gradual_release_query: 'gradual_release_query',
  gradual_versions: 'gradual_versions',
  pre_release_date: 'pre_release_date',
  post_release_date: 'post_release_date',
  days_before: 'days_before',
  max_days_from_release: 'max_days_from_release',
  measurement_timeline_mode: 'measurement_timeline_mode',
  release_date: 'release_date',
};

interface Props extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  entityContext: TableEntity;
  className?: string;
}

enum MeasurementTimelineMode {
  PRE_POST = 'pre_post_period',
  GRADUAL_RELEASE = 'gradual_release',
}

export const ReleaseImpactMeasurementTimelineSelector = (props: Props) => {
  const {
    value,
    entityContext,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    onChange,
    className,
  } = props;
  const {t} = useTranslation();
  const {productEntitiesMap} = useProductData();
  const entityName = useMemo(
    () => pluralize(productEntitiesMap[entityContext]?.name || '').toLowerCase(),
    [productEntitiesMap, entityContext]
  );
  const selectedMode = useMemo(() => {
    return value[schemaKeysMapping.measurement_timeline_mode] || MeasurementTimelineMode.PRE_POST;
  }, [value, schemaKeysMapping]);

  const onModeChange = useCallback(
    (mode: MeasurementTimelineMode) => {
      const newValues = createUndefinedObject([
        schemaKeysMapping.gradual_release_query,
        schemaKeysMapping.gradual_versions,
        schemaKeysMapping.pre_release_date,
        schemaKeysMapping.post_release_date,
        schemaKeysMapping.days_before,
        schemaKeysMapping.max_days_from_release,
      ]);
      onChange({
        ...newValues,
        [schemaKeysMapping.measurement_timeline_mode]: mode,
      });
    },
    [onChange, schemaKeysMapping]
  );

  return (
    <ParameterInputWrapper
      title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.MEASUREMENT_TIMELINE.TITLE)}
      className={className}
    >
      <div className={classes.ReleaseImpactMeasurementTimelineSelector}>
        <div
          className={classNames(
            classes.Option,
            selectedMode === MeasurementTimelineMode.PRE_POST && classes.Selected
          )}
        >
          <div className={classes.CheckBox}>
            <Checkbox
              checked={selectedMode === MeasurementTimelineMode.PRE_POST}
              onChange={() => onModeChange(MeasurementTimelineMode.PRE_POST)}
            />
          </div>
          <div className={classes.Content}>
            <div
              className={classes.Title}
              onClick={() => onModeChange(MeasurementTimelineMode.PRE_POST)}
            >
              {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.MEASUREMENT_TIMELINE.PRE_POST.TITLE)}
            </div>
            <div className={classes.Subtitle}>
              {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.MEASUREMENT_TIMELINE.PRE_POST.SUB_TITLE)}
            </div>
            <div className={classes.OptionSelector}>
              <PrePostPeriodSelector {...props} schemaKeysMapping={schemaKeysMapping} />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            classes.Option,
            selectedMode === MeasurementTimelineMode.GRADUAL_RELEASE && classes.Selected
          )}
        >
          <div className={classes.CheckBox}>
            <Checkbox
              checked={selectedMode === MeasurementTimelineMode.GRADUAL_RELEASE}
              onChange={() => onModeChange(MeasurementTimelineMode.GRADUAL_RELEASE)}
            />
          </div>
          <div className={classes.Content}>
            <div
              className={classes.Title}
              onClick={() => onModeChange(MeasurementTimelineMode.GRADUAL_RELEASE)}
            >
              {t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.TITLE, {entity: entityName})}
            </div>
            <div className={classes.Subtitle}>
              {t(TransKeys.ANALYSIS_FORMS.SHARED.GRADUAL_RELEASE.SUB_TITLE, {entity: entityName})}
            </div>
            <div className={classes.OptionSelector}>
              <GradualReleaseSelector {...props} schemaKeysMapping={schemaKeysMapping} />
            </div>
          </div>
        </div>
      </div>
    </ParameterInputWrapper>
  );
};
