import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import classes from './kpi-segmentation-table.module.scss';
import {
  CommandType,
  DocumentElementType,
  KPISegmentationFigureOptions,
  KPISegmentationType,
  TreatmentAdoptionSimulationItemType,
} from '../../../../../../types';
import {ExtendedKPISegmentationItem} from '../../kpi-segmentation-viewer.component';
import {exists, Sorting} from 'front-core';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {useSimulator} from '../../../../../../hooks/use-simulator.hook';
import {groupBy, max} from 'lodash';
import {FlexibleTable} from '../../../../../shared/general/flexible-table/flexible-table.component';
import TransKeys from 'translations';
import {ModelType} from '../../../../../../../../../consts/model-type';
import {VersusDisplay} from '../../../../../shared/display-columns/versus-display.component';
import {GradientLabelDisplay} from '../../../../../shared/display-columns/gradient-label-display.component';
import pluralize from 'pluralize';
import {UpliftDisplay} from '../../../../../shared/display-columns/uplift-display.component';
import {SegmentDisplay} from '../../../../../shared/display-columns/segment-display.component';
import {PercentageAbsDisplay} from '../../../../../shared/display-columns/percentage-abs-display.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import classNames from 'classnames';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {SimulatorSlider} from '../../../../../shared/inputs/simulator-slider/simulator-slider.component';
import {getPercentageValueFormatter} from '../../../../../../../../../utils/formatters';
import {
  DocumentMessage,
  DocumentMessageType,
} from '../../../../../shared/general/document-message/document-message.component';
import {AppTabs} from '../../../../../../../../simple/navigation/app-tabs/app-tabs.component';
import {useDocQuery} from '../../../../../../hooks/use-doc-query.hook';

interface OwnProps {
  figureId: string;
  options: KPISegmentationFigureOptions;
  items: ExtendedKPISegmentationItem[];
  className?: string;
  goalValue: number;
}

type AllProps = OwnProps;

const INSIGNIFICANT_TYPES = [
  KPISegmentationType.INSIGNIFICANT,
  KPISegmentationType.NO_RECOMMENDATION,
];

export const KPISegmentationSimulatorMode: React.FC<AllProps> = React.memo((props: AllProps) => {
  const {figureId, goalValue, items: itemsFromProps, options, className} = props;
  const {t} = useDocumentTranslation();
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);

  const {trackInput, trackNavigation} = useDocumentTracking(
    figureId,
    DocumentElementType.KPI_SEGMENTATION_FIGURE
  );
  const {items, onItemChangeValue, changedValuesMap} = useSimulator({
    items: itemsFromProps,
    goalValue: goalValue,
    minValue: -1,
  });
  const itemsGroupByType = useMemo(() => groupBy(items, 'type'), [items]);
  const defaultSelectedType = useMemo(
    () =>
      [
        KPISegmentationType.POSITIVE,
        KPISegmentationType.NEGATIVE,
        KPISegmentationType.INSIGNIFICANT,
        KPISegmentationType.NO_RECOMMENDATION,
      ].find(v => itemsGroupByType[v] && itemsGroupByType[v].length > 0),
    [itemsGroupByType]
  );
  const {query: selectedType, setQuery: setSelectedType_} = useDocQuery<KPISegmentationType>(
    figureId,
    defaultSelectedType,
    'type'
  );
  const setSelectedType = useCallback(
    (item: KPISegmentationType) => {
      trackNavigation(item);
      setSelectedType_(item);
    },
    [setSelectedType_, trackNavigation]
  );
  const kpiMaxPotential = useMemo(() => max(items.map(i => i.maxPotential)), [items, goalValue]);
  const valueFormatter = useMemo(
    () => getPercentageValueFormatter(options.isPercentageValue),
    [options]
  );
  const defaultSort: Sorting = useMemo(
    () => ({
      order: options.higherIsBetter ? 'desc' : 'asc',
      orderBy: selectedType === KPISegmentationType.INSIGNIFICANT ? 'uplift' : 'maxPotential',
    }),
    [options.higherIsBetter, selectedType]
  );
  const onDragComplete = useCallback(
    (controlType: 'master' | 'user_share' | 'goal', value) =>
      trackInput(controlType, {value, inputType: 'slider'}),
    [trackInput]
  );
  const columns = useMemo(
    () =>
      [
        {
          key: ['segmentGroupName', 'segmentName'],
          title: [
            t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.GROUP_NAME.LABEL),
            t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.SEGMENT.LABEL),
          ],
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.SEGMENT.HELPER_TEXT
          ),
          sortable: true,
          weight: 2,
          render: item => (
            <SegmentDisplay
              onClick={() => onSignalClick(item.signalId[0])}
              group={item.segmentGroupName[0]}
              segment={item.segmentName[0]}
              helperText={item.helperText}
            />
          ),
        },
        {
          key: 'share',
          title:
            INSIGNIFICANT_TYPES.indexOf(selectedType) > -1
              ? t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.SHARE_OF_X.LABEL,
                  {
                    entity: pluralize(options.entity),
                  }
                )
              : t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.CURRENT_SHARE_OF_X
                    .LABEL,
                  {
                    entity: pluralize(options.entity),
                  }
                ),
          helperText:
            INSIGNIFICANT_TYPES.indexOf(selectedType) > -1
              ? t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.SHARE_OF_X
                    .HELPER_TEXT,
                  {
                    entity: pluralize(options.entity),
                  }
                )
              : t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.CURRENT_SHARE_OF_X
                    .HELPER_TEXT,
                  {entity: pluralize(options.entity)}
                ),
          weight: 1.2,
          sortable: true,
          render: item => (
            <PercentageAbsDisplay
              percentage={item.share}
              abs={item.count}
              unit={pluralize(options.entity)}
            />
          ),
        },
        {
          key: 'change',
          title: t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.TARGET_USER_SHARE.LABEL,
            {entity: options.entity}
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.TARGET_USER_SHARE
              .HELPER_TEXT,
            {entity: options.entity}
          ),
          weight: 2,
          types: [KPISegmentationType.POSITIVE],
          render: item => (
            <SimulatorSlider
              min={0}
              max={1000}
              displayValue={item.newValue * 100}
              value={item.change * 100}
              defaultValue={item.defaultChange * 100}
              onChange={v => onItemChangeValue(item.key, exists(v) ? v / 100 : null)}
              onChangeCommitted={v => onDragComplete('user_share', exists(v) ? v / 100 : null)}
              allowedMax={item.maxChange * 100}
              suffix={'%'}
              sign={'+'}
              allowedMaxHelper={t(
                TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SLIDER_MAX_HELPER
              )}
            />
          ),
        },
        {
          key: 'goalInSegment',
          title:
            INSIGNIFICANT_TYPES.indexOf(selectedType) > -1
              ? t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_SEGMENT
                    .LABEL
                )
              : t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.CURRENT_GROUP_KPI
                    .LABEL
                ),
          subTitle: `vs. ${t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_NON_SEGMENT
              .LABEL
          )}`,
          weight: 1,
          sortable: true,
          helperText:
            INSIGNIFICANT_TYPES.indexOf(selectedType) > -1
              ? t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_SEGMENT
                    .HELPER_TEXT
                )
              : t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.CURRENT_GROUP_KPI
                    .HELPER_TEXT
                ),
          render: item => (
            <VersusDisplay
              key={item.key}
              className={classes.SegmentLine}
              value={item.goalInSegment}
              vsValue={item.goalInNonSegment}
              isPercentageValue={options.isPercentageValue}
              vsLabel={t(
                TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_NON_SEGMENT
                  .LABEL
              )}
            />
          ),
        },
        {
          key: 'change_negative',
          title: options.higherIsBetter
            ? t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.TARGET_KPI.LABEL)
            : t(
                TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.TARGET_KPI_DECREASE
                  .LABEL
              ),
          weight: 2,
          sortable: true,
          helperText: options.higherIsBetter
            ? t(
                TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.TARGET_KPI
                  .HELPER_TEXT
              )
            : t(
                TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.TARGET_KPI_DECREASE
                  .HELPER_TEXT
              ),
          types: [KPISegmentationType.NEGATIVE],
          render: item => {
            let mul = 1;
            const inputProps = {
              allowedMax: item.maxChange * 100,
              max: 1000,
              min: 0,
            };
            if (options.higherIsBetter === false) {
              mul = -1;
              inputProps.allowedMax = 100;
              inputProps.max = 100;
            }
            return (
              <SimulatorSlider
                {...inputProps}
                displayValue={item.newValue * 100}
                value={item.change * 100 * mul}
                defaultValue={item.defaultChange * 100}
                onChange={v => onItemChangeValue(item.key, exists(v) ? (mul * v) / 100 : null)}
                onChangeCommitted={v => onDragComplete('goal', exists(v) ? (mul * v) / 100 : null)}
                suffix={'%'}
                sign={mul === 1 ? '+' : '-'}
                allowedMaxHelper={t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SLIDER_MAX_HELPER_NEGATIVE
                )}
              />
            );
          },
        },
        {
          key: 'maxPotential',
          title: t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.POTENTIAL_KPI.LABEL
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.POTENTIAL_KPI
              .HELPER_TEXT
          ),
          weight: 1.5,
          sortable: true,
          types: [KPISegmentationType.NEGATIVE, KPISegmentationType.POSITIVE],
          render: item => {
            // Swap upper & lower for negative effects
            let valueLower =
              options.isPercentageValue && exists(item.potentialLower)
                ? item.potentialLower * 100
                : item.potentialLower;
            let valueUpper =
              options.isPercentageValue && exists(item.potentialUpper)
                ? item.potentialUpper * 100
                : item.potentialUpper;
            if ([KPISegmentationType.NEGATIVE].indexOf(item.actualType) > -1) {
              const temp = valueLower;
              valueLower = valueUpper;
              valueUpper = temp;
            }
            return (
              <GradientLabelDisplay
                className={classes.ColumnValue}
                value={options.isPercentageValue ? item.potential * 100 : item.potential}
                valueLower={valueLower}
                valueUpper={valueUpper}
                min={options.isPercentageValue ? goalValue * 100 : goalValue}
                max={options.isPercentageValue ? kpiMaxPotential * 100 : kpiMaxPotential}
                mode={item.type === KPISegmentationType.INSIGNIFICANT ? 'range' : 'default'}
                tooltipLabel={t(
                  TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.LABELS
                    .CONFIDENCE_INTERVAL
                )}
                higherIsBetter={options.higherIsBetter}
                suffix={options.isPercentageValue ? '%' : ''}
                showDiff
              />
            );
          },
        },
        {
          key: 'uplift',
          title: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.UPLIFT.LABEL),
          weight: 1,
          sortable: true,
          types: [KPISegmentationType.INSIGNIFICANT],
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.UPLIFT.HELPER_TEXT
          ),
          render: item => (
            <UpliftDisplay
              key={item.key}
              uplift={item.uplift}
              higherIsBetter={options.higherIsBetter}
              className={classes.SegmentLine}
            />
          ),
        },
        {
          key: 'message',
          title: t(
            TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.HEADERS.INFO.LABEL
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.HEADERS.INFO.HELPER_TEXT
          ),
          weight: 3,
          types: [TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION],
          render: item => item.info,
        },
      ].filter((c: any) => (c.types ? c.types.indexOf(selectedType) > -1 : true)),
    [
      options,
      selectedType,
      valueFormatter,
      kpiMaxPotential,
      changedValuesMap,
      goalValue,
      onSignalClick,
    ]
  );

  const tabs = useMemo(
    () =>
      [
        KPISegmentationType.POSITIVE,
        KPISegmentationType.NEGATIVE,
        KPISegmentationType.INSIGNIFICANT,
        KPISegmentationType.NO_RECOMMENDATION,
      ].map(type => ({
        key: type,
        label: `${t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABS[type.toUpperCase()])} (${
          itemsGroupByType[type]?.length || 0
        })`,
        disabled: !exists(itemsGroupByType[type]) || itemsGroupByType[type].length === 0,
      })),
    [itemsGroupByType]
  );

  const instructions = t(
    TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.INSTRUCTIONS[selectedType?.toUpperCase()]
  );

  return (
    <>
      <AppTabs
        className={classes.Tabs}
        tabs={tabs}
        selected={selectedType}
        onChange={tab => setSelectedType(tab as any)}
        fullWidth
      />
      {instructions && <div className={classes.Instructions}>{instructions}</div>}
      <FlexibleTable
        key={selectedType}
        className={classNames(classes.KPISimulatorDataMode, className)}
        columns={columns}
        data={itemsGroupByType[selectedType] || []}
        rowClassName={classes.SimulatorRow}
        defaultSort={defaultSort}
        afterHeadersRenderer={
          selectedType === KPISegmentationType.INSIGNIFICANT
            ? () => (
                <DocumentMessage
                  type={DocumentMessageType.WARN}
                  text={t(
                    TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.LABELS
                      .INSIGNIFICANT,
                    {model: 'segment'}
                  )}
                />
              )
            : null
        }
      />
    </>
  );
});

KPISegmentationSimulatorMode.defaultProps = {};
