import yup from '../../config/yup.config';
import {Feature} from '../models/feature.model';
import httpClientService from '../../services/http-client.service';
import {
  validateFeatureDefinitionNetworkRequest,
  validateNameNetworkRequest,
} from '../../http/validations.network-requests';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';

import {apiQuerySchemaValidator} from './query-builder.dto.ts';

export type FeatureDTO = Partial<Feature>;

export const featureDTOValidator = yup
  .object()
  .shape({
    id: yup.number().optional().nullable(),
    parentId: yup.number().optional().nullable(),
    name: yup
      .string()
      .required()
      .test('unique_name', 'Name already exists', async function (name: string) {
        const {id} = this.parent;
        try {
          await httpClientService.exec(
            validateNameNetworkRequest({
              name,
              existingId: id,
              modelType: 'feature',
            })
          );
          return true;
        } catch (e) {
          return false;
        }
      }),
    shortDescription: yup.string().nullable(),
    entity: yup.string().oneOf(values(TableEntity)).required(),
    launchOn: yup.string().nullable(),
    // signals
    usageSignalId: yup.number().optional().nullable(),
    usageSignalDefinition: apiQuerySchemaValidator({
      required: false,
      networkRequest: validateFeatureDefinitionNetworkRequest,
      extractData: data => ({
        definition: data.usageSignalDefinition,
        entity: data.entity,
      }),
    }),
    exposureSignalId: yup.number().optional().nullable(),
    exposureSignalDefinition: apiQuerySchemaValidator({
      required: false,
      networkRequest: validateFeatureDefinitionNetworkRequest,
      extractData: data => ({
        definition: data.exposureSignalDefinition,
        entity: data.entity,
      }),
    }),
    intentSignalId: yup.number().optional().nullable(),
    intentSignalDefinition: apiQuerySchemaValidator({
      required: false,
      networkRequest: validateFeatureDefinitionNetworkRequest,
      extractData: data => ({
        definition: data.intentSignalDefinition,
        entity: data.entity,
      }),
    }),
    tags: yup.array().of(yup.string().required()),
  })
  // @ts-ignore
  .atLeastOneRequired(
    ['usageSignalDefinition', 'exposureSignalDefinition', 'intentSignalDefinition'],
    'Missing definition'
  );
