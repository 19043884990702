import * as React from 'react';
import classNames from 'classnames';
import classes from './command-buttons-viewer.module.scss';
import {CommandButtonsFigure} from '../../../types';
import {useContext} from 'react';
import {Button} from '../../../../../simple/controls/button/button.component';
import {get} from 'lodash';
import {DocumentCommandEmitterContext} from '../../../contexts/document-command-emitter.context';

interface OwnProps extends CommandButtonsFigure {
  className?: string;
}

type AllProps = OwnProps;

export const CommandButtonsViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, options, className} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {buttons} = data;

  return (
    <div className={classNames(classes.CommandButtonsViewer, classes[options.align], className)}>
      {buttons.map((b, idx) => (
        <Button
          className={classes.Button}
          key={idx}
          onClick={() => emitEvent(b.command)}
          variant={b.buttonVariant === 'secondary' ? 'outlined' : undefined}
          caps={false}
        >
          {b.buttonText || get(b.command.payload, 'text')}
        </Button>
      ))}
    </div>
  );
};

CommandButtonsViewer.defaultProps = {};
