import * as React from 'react';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {EnhancedGroup, EnhancedRect, EnhancedText} from '../../../../core/konva/components';
import {useContext, useMemo} from 'react';
import {StageContext} from '../../../../core/konva/stage-context.component';
import {KonvaEventObject} from 'konva/types/Node';
import {ChartContext} from '../chart.context';

interface OwnProps {
  text: string;
  onClick: (evt: KonvaEventObject<MouseEvent>) => void;
  bgColor?: string;
}

type AllProps = ShapeProps & OwnProps;

export const DumbbellLabel: React.FC<AllProps> = (props: AllProps) => {
  const {x, y, height, width: widthFromProps, text, bgColor, onClick} = props;
  const {style, controller} = useContext(StageContext);
  const {darkMode} = useContext(ChartContext);

  const shadowColor = useMemo(() => `rgba(218, 218, 218, ${darkMode ? 0.3 : 0.6})`, [darkMode]);
  const width = useMemo(() => {
    if (widthFromProps) {
      return widthFromProps;
    }
    return controller.measureTextWidth(text, style.fontName, 12) + 20;
  }, [controller, style, text, widthFromProps]);

  return (
    <EnhancedGroup
      {...props}
      x={x}
      y={y}
      height={height}
      width={width}
      listening={false}
      onClick={onClick}
    >
      <EnhancedRect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={bgColor || '#ffffff'}
        cornerRadius={20}
        shadowColor={shadowColor}
        shadowBlur={8}
        shadowOffset={{x: 0, y: 2}}
        centerY
      />
      <EnhancedText
        x={width / 2}
        height={10}
        width={width}
        fontSize={12}
        fontFamily={style.fontName}
        fill={'#293164'}
        text={text}
        align={'center'}
        center
      />
    </EnhancedGroup>
  );
};

DumbbellLabel.defaultProps = {
  height: 28,
};
