export const QUERY_BUILDER_TEMPLATES = {
  l7: {
    name: 'L7',
    parameters: [
      {
        name: 'Action Signal',
        type: 'query_list',
      },
      {
        name: 'Days',
        type: 'number',
        default: 7,
      },
    ],
  },
  l28: {
    name: 'L28',
    parameters: [
      {
        name: 'Action Signal',
        type: 'query_list',
      },
      {
        name: 'Days',
        type: 'number',
        default: 28,
      },
    ],
  },
  dau: {
    name: 'Daily Active Users',
    parameters: [
      {
        name: 'Action Signal',
        type: 'query_list',
      },
    ],
  },
  wau: {
    name: 'Weekly Active Users',
    parameters: [
      {
        name: 'Action Signal',
        type: 'query_list',
      },
    ],
  },
  mau: {
    name: 'Monthly Active Users',
    parameters: [
      {
        name: 'Action Signal',
        type: 'query_list',
      },
    ],
  },
  d30: {
    name: 'D30',
    parameters: [],
  },
  bounded_retention: {
    name: 'Bounded Retention',
    parameters: [
      {
        name: 'Action Signal',
        type: 'signal',
        index: 3,
      },
      {
        name: 'Reference Date Signal',
        type: 'signal',
        index: 4,
      },
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        index: 2,
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'From',
        type: 'number',
        default: 0,
        index: 0,
      },
      {
        name: 'To',
        type: 'number',
        default: 14,
        index: 1,
      },
    ],
  },
  unbounded_retention: {
    name: 'Unbounded Retention',
    parameters: [
      {
        name: 'Action Signal',
        type: 'signal',
        index: 2,
      },
      {
        name: 'Reference Date Signal',
        type: 'signal',
        index: 3,
      },
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        index: 1,
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'Amount of time',
        type: 'number',
        default: 1,
        index: 0,
      },
    ],
  },
  n_day_retention: {
    name: 'X Retention Days',
    parameters: [
      {
        name: 'Action Signal',
        type: 'signal',
        index: 1,
      },
      {
        name: 'Days',
        type: 'number',
        default: 7,
        index: 0,
      },
    ],
  },
  bounded_action: {
    name: 'Bounded Action',
    parameters: [
      {
        name: 'Action Signal',
        type: 'signal',
        index: 0,
      },
      {
        name: 'Reference Date Signal',
        type: 'signal',
        index: 4,
      },
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        index: 3,
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'From',
        type: 'number',
        default: 0,
        index: 1,
      },
      {
        name: 'To',
        type: 'number',
        default: 14,
        index: 2,
      },
    ],
  },
  bounded_action_nu: {
    name: 'Bounded Action Numeric',
    parameters: [
      {
        name: 'Action Signal',
        type: 'signal',
        index: 0,
      },
      {
        name: 'Reference Date Signal',
        type: 'signal',
        index: 4,
      },
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        index: 3,
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'From',
        type: 'number',
        default: 0,
        index: 1,
      },
      {
        name: 'To',
        type: 'number',
        default: 14,
        index: 2,
      },
      {
        name: 'Aggregator',
        type: 'string',
        default: 'avg',
        options: [
          {
            value: 'avg',
            label: 'AVG',
          },
          {
            value: 'sum',
            label: 'Sum',
          },
        ],
      },
    ],
  },
  bounded_actions_ts: {
    name: 'Bounded Actions TS',
    parameters: [
      {
        name: 'Actions Signal',
        type: 'query_list',
        index: 0,
      },
      {
        name: 'Reference Date Signal',
        type: 'query',
        index: 4,
      },
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        index: 3,
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'From',
        type: 'number',
        default: 0,
        index: 1,
      },
      {
        name: 'To',
        type: 'number',
        default: 14,
        index: 2,
      },
    ],
  },
  bounded_action_ts: {
    name: 'Bounded Action TS',
    parameters: [
      {
        name: 'Action Signal',
        type: 'query',
        index: 0,
      },
      {
        name: 'Reference Date Signal',
        type: 'query',
        index: 4,
      },
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        index: 3,
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'From',
        type: 'number',
        default: 0,
        index: 1,
      },
      {
        name: 'To',
        type: 'number',
        default: 14,
        index: 2,
      },
    ],
  },
  subscription_retention: {
    name: 'Subscription Retention',
    parameters: [
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'Amount',
        type: 'number',
        default: 1,
      },
      {
        name: 'Plan',
        type: 'string',
        default: '',
      },
    ],
  },
  any_of_tag: {
    name: 'Any of Tag',
    parameters: [
      {
        name: 'Tag',
        type: 'string',
      },
    ],
  },
  all_events: {
    name: 'All Events',
    parameters: [
      {
        name: 'Exclude Tables',
        type: 'table_list',
      },
    ],
  },
  events: {
    name: 'Events',
    parameters: [
      {
        name: 'Tables',
        type: 'table_list',
      },
    ],
  },
  start_date: {
    name: 'Start Date',
    index: 0,
    parameters: [],
  },
  end_date: {
    name: 'End Date',
    index: 0,
    parameters: [],
  },
  bounded_retention_ts: {
    name: 'Bounded Retention Timestamp',
    parameters: [
      {
        name: 'Action Signal',
        type: 'signal',
        index: 3,
      },
      {
        name: 'Reference Date Signal',
        type: 'signal',
        index: 4,
      },
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        index: 2,
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'From',
        type: 'number',
        default: 0,
        index: 0,
      },
      {
        name: 'To',
        type: 'number',
        default: 14,
        index: 1,
      },
    ],
  },
  unbounded_retention_ta: {
    name: 'Unbounded Retention Timestamp',
    parameters: [
      {
        name: 'Action Signal',
        type: 'signal',
        index: 2,
      },
      {
        name: 'Reference Date Signal',
        type: 'signal',
        index: 3,
      },
      {
        name: 'Unit',
        type: 'string',
        default: 'day',
        index: 1,
        options: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ],
      },
      {
        name: 'Amount of time',
        type: 'number',
        default: 1,
        index: 0,
      },
    ],
  },
  email_feature_engineering: {
    name: 'Email Feature Engineering',
    parameters: [
      {
        name: 'Signal',
        type: 'signal',
        index: 0,
      },
    ],
  },
  milestone: {
    name: 'Milestone',
    parameters: [
      {
        name: 'Signals',
        type: 'signal_list',
        index: 0,
      },
      {
        name: 'Reference Date Signal',
        type: 'signal',
        index: 1,
      },
      {
        name: 'Max Days From Reference',
        type: 'number',
        index: 2,
      },
      {
        name: 'Count Type',
        type: 'string',
        index: 3,
        options: [
          {
            value: 'days',
            label: 'Distinct Days',
          },
          {
            value: 'times',
            label: 'Times',
          },
        ],
      },
      {
        name: 'Count Amount',
        type: 'number',
        index: 4,
      },
    ],
  },
  rate: {
    name: 'Rate',
    parameters: [
      {
        name: 'Numerator',
        type: 'query',
        index: 0,
      },
      {
        name: 'Denominator',
        type: 'query',
        index: 1,
      },
    ],
  },
};
