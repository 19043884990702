import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {HomepageSubscription} from '../objects/models/homepage.model';
import {
  GetHomepageFunnelDTO,
  GetHomepageMetricDTO,
  HomepageSubscriptionTestDTO,
  ReorderHomepageDTO,
  UpdateHomepageModelDTO,
  DeleteHomepageSubscriptionDTO,
  GetHomepageSubscriptionDTO,
} from '../objects/dto/homepage.dto';

export const getHomepageDataNetworkRequest: NRC<void, any> = (homepageId: number, query) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${homepageId}`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res =>
    toCamelCase(res.data, ['metrics.*.signal_definition', 'metrics.*.signal_population_filter']),
});

export const getHomepageMetricDataNetworkRequest: NRC<void, any> = (
  data: GetHomepageMetricDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/metric`,
  query: {
    metricIds: data.metricIds,
    ...data.filters,
  },
  queryTransformer: toFiltersDTO,
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const getHomepageFunnelDataNetworkRequest: NRC<void, any> = (
  data: GetHomepageFunnelDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/funnel/${data.funnelId}`,
  query: data.filters,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getRCACompareDataNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/rca-compare`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const addModelToHomepageNetworkRequest: NRC<UpdateHomepageModelDTO, void> = (
  data: UpdateHomepageModelDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `app/homepage/${data.homepageId}/item`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const removeModelFromHomepageNetworkRequest: NRC<UpdateHomepageModelDTO, void> = (
  data: UpdateHomepageModelDTO
) => ({
  method: RequestType.DELETE,
  relativeUrl: `app/homepage/${data.homepageId}/item`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getHomepageSettingsNetworkRequest: NRC<void, void> = (homepageId: number) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${homepageId}/settings`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getHomepageSubscriptionNetworkRequest: NRC<void, void> = ({
  homepageId,
  subscriptionId,
}: GetHomepageSubscriptionDTO) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${homepageId}/subscriptions/${subscriptionId}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getHomepageSubscriptionsNetworkRequest: NRC<void, void> = (homepageId: number) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${homepageId}/subscriptions`,
  responseTransformer: res => toCamelCase(res),
});

export const updateHomepageSubscriptionNetworkRequest: NRC<Partial<HomepageSubscription>, void> = (
  data: Partial<HomepageSubscription>
) => ({
  method: RequestType.PUT,
  relativeUrl: `app/homepage/${data.homepageId}/subscriptions/${data.id}`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const createHomepageSubscriptionNetworkRequest: NRC<Partial<HomepageSubscription>, void> = (
  data: Partial<HomepageSubscription>
) => ({
  method: RequestType.POST,
  relativeUrl: `app/homepage/${data.homepageId}/subscriptions`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const deleteHomepageSubscriptionNetworkRequest: NRC<void, void> = ({
  subscriptionId,
  homepageId,
}: DeleteHomepageSubscriptionDTO) => ({
  method: RequestType.DELETE,
  relativeUrl: `app/homepage/${homepageId}/subscriptions/${subscriptionId}`,
});

export const reorderHomepageNetworkRequest: NRC<Partial<ReorderHomepageDTO>, void> = (
  data: ReorderHomepageDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `app/homepage/${data.homepageId}/reorder`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const sendHomepageSubscriptionTestNetworkRequest: NRC<HomepageSubscriptionTestDTO, any> = (
  data: HomepageSubscriptionTestDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `app/homepage/${data.homepageId}/subscription/test`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});
