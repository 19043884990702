import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';

interface OwnProps {
  prefix?: string;
  children: any;
  className?: string;
}

type AllProps = OwnProps;

export const CompositeDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {prefix, children, className} = props;

  return (
    <div className={classNames(classes.CompositeDisplay, className)}>
      <div className={classes.Prefix}>{prefix}</div>
      <div className={classes.List}>{children}</div>
    </div>
  );
};

CompositeDisplay.defaultProps = {
  prefix: 'and',
};
