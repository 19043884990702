import {useCallback, useContext, useEffect, useMemo} from 'react';
import {SqlElementType} from '../query-builder.types';
import {keys} from 'lodash';
import classNames from 'classnames';
import {QueryBuilderContext} from '../query-builder.context';
import {Select} from '../../../forms/inputs/select/select.component';
import {QueryBuilderFactory} from '../query-builder.utils';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {queryBuilderClasses} from '../query-builder.component';
import {QueryActions} from '../components/query-actions/query-actions.component';
import {MutedText} from '../components/parts';

interface OwnProps extends QueryBuilderComponent {
  error?: boolean;
  placeholder?: string;
  root?: boolean;
  [otherBuilderProp: string]: any;
}

export const QueryElementBuilder: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    data: dataFromProps,
    accept: acceptFromProps,
    onChange: onChangeFromProps,
    onDelete: onDeleteFromProps,
    actions,
    path,
    root,
    placeholder,
    className,
    error,
  } = props;
  const {
    data: dataFromContext,
    errors,
    config,
    onChange: onChangeFromContext,
    onDelete: onDeleteFromContext,
    disabled,
  } = useContext(QueryBuilderContext);
  const {accept: acceptFromConfig, componentsMap} = config || {};
  const data = useMemo(
    () => (root ? dataFromContext : dataFromProps) || {type: undefined},
    [dataFromProps, dataFromContext, root]
  );
  const onChange = useMemo(
    () => onChangeFromProps || onChangeFromContext,
    [onChangeFromProps, onChangeFromContext]
  );
  const onDelete = useMemo(
    () => onDeleteFromProps || onDeleteFromContext,
    [onDeleteFromProps, onDeleteFromContext]
  );
  const Component = useMemo(
    () => componentsMap[data.extended_type || data.type]?.component || null,
    [data, componentsMap]
  );
  const accept = useMemo(() => {
    let contextAccept = acceptFromConfig;
    if (!contextAccept) {
      contextAccept = keys(componentsMap);
    }
    if (!acceptFromProps) {
      return contextAccept;
    }
    const acceptSet = new Set(contextAccept);
    return acceptFromProps.filter(at => acceptSet.has(at));
  }, [acceptFromConfig, acceptFromProps, componentsMap]);
  const options = useMemo(() => {
    let arr = accept;
    if (root && config.entry && config.entry.length > 0) {
      arr = [...config.entry];
    }
    return arr.map(type => ({
      label: componentsMap[type].name,
      value: type,
    }));
  }, [accept, componentsMap, config, root]);
  const rootError = useMemo(() => errors['type'], [errors]);
  const onSelectChange = useCallback(
    option => onChange(path, QueryBuilderFactory.create(option)),
    [onChange, path]
  );
  const wrapWith = useCallback(
    (wrapper: SqlElementType) => {
      const newElem = QueryBuilderFactory.create(wrapper);
      switch (wrapper) {
        case SqlElementType.AND_CONDITION:
        case SqlElementType.OR_CONDITION:
          newElem.conditions = [data];
          break;
        case SqlElementType.COALESCE:
        case SqlElementType.FILL_NULL:
          newElem.elements = [data];
          break;
        case SqlElementType.CASES:
          newElem.cases[0][0] = data;
      }

      onChange(path, newElem);
    },
    [data, onChange, path]
  );

  useEffect(() => {
    if (!data.type && accept?.length === 1) {
      onSelectChange(accept[0]);
    }
  }, [data, accept, onSelectChange, root, config.entry]);

  if (!data.type && !disabled) {
    return (
      <div className={classNames(queryBuilderClasses.MainSelector, className)}>
        <Select
          className={queryBuilderClasses.Selector}
          dropdownButtonClassName={queryBuilderClasses.Button}
          error={root ? rootError : error}
          placeholder={placeholder}
          value={''}
          onChange={onSelectChange}
          searchable={options.length >= 5}
          options={{options}}
          clearable={false}
        />
        {actions?.length > 0 && (
          <QueryActions className={queryBuilderClasses.Actions} actions={actions} />
        )}
      </div>
    );
  }
  if (!data.type && disabled) {
    return <MutedText>No Query Defined</MutedText>;
  }

  if (!Component) {
    return (
      <div className={classNames(queryBuilderClasses.Inline, className)}>
        <MutedText>Unsupported</MutedText>
      </div>
    );
  }
  return (
    <Component
      {...props}
      actions={actions}
      disabled={disabled}
      className={className}
      path={path}
      data={data}
      config={config}
      onDelete={onDelete}
      onChange={onChange}
      wrapWith={wrapWith}
      errors={errors}
      accept={accept}
      box={!root}
    />
  );
};

QueryElementBuilder.defaultProps = {
  path: '',
  placeholder: 'Select',
  root: false,
};
