import classNames from 'classnames';
import classes from './extended-parameters.module.scss';
import {ChevronDownIcon, IconButton} from 'ui-components';

interface OwnProps {
  label: string;
  children: any;
  isOpen?: boolean;
  onOpenChanged: () => void;
  size?: 'small' | 'default';
  className?: string;
  headClassName?: string;
}

type AllProps = OwnProps;

export const ExtendedParameters = (props: AllProps) => {
  const {label, isOpen, onOpenChanged, size, children, className, headClassName} = props;

  return (
    <div
      className={classNames(
        classes.ExtendedParameters,
        isOpen && classes.Show,
        classes[size],
        className
      )}
    >
      <div className={classNames(classes.Head, headClassName)} onClick={_ => onOpenChanged()}>
        <IconButton
          className={classes.DropdownButton}
          icon={ChevronDownIcon}
          onClick={_ => onOpenChanged()}
          size={size === 'small' ? 'small' : undefined}
        />
        <div className={classes.Label}>{label}</div>
      </div>
      <div className={classes.Children}>{children}</div>
    </div>
  );
};
