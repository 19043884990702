import yup from '../../config/yup.config';
import {FavoriteSegment, Segment, SegmentCategory} from '../models/segment.model';
import {SignalDataType, SignalType} from '../models/signal.model';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import httpClientService from '../../services/http-client.service';
import {
  validateNameNetworkRequest,
  validateSegmentDefinitionNetworkRequest,
} from '../../http/validations.network-requests';

import {querySchemaValidator, apiQuerySchemaValidator} from './query-builder.dto.ts';

export type SegmentDTO = Partial<Segment>;
export type SegmentCategoryDTO = Partial<SegmentCategory>;
export interface UpdateFavoriteSegmentsDTO {
  teamId: number;
  favorites: FavoriteSegment[];
}
export interface AddSegmentToTeamDTO {
  teamId: number;
  segmentId: number;
}

export interface SwapCategoriesDTO {
  segmentCategoryId: number;
  index: number;
  newIndex: number;
}

export const segmentDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup
    .string()
    .required()
    .test('unique_name', 'Name already exists', async function (name: string) {
      const {id} = this.parent;
      try {
        await httpClientService.exec(
          validateNameNetworkRequest({
            name,
            existingId: id,
            modelType: 'segment',
          })
        );
        return true;
      } catch (e) {
        return false;
      }
    }),
  isConfounder: yup.boolean().nullable(),
  useAsExplainer: yup.boolean().nullable(),
  calculateBuckets: yup.boolean().nullable(),
  shortDescription: yup.string().nullable(),
  entity: yup.string().oneOf(values(TableEntity)).required(),
  signalId: yup.number().nullable(),
  signalDefinition: yup.object().when('isComputed', {
    is: false,
    then: apiQuerySchemaValidator({
      networkRequest: validateSegmentDefinitionNetworkRequest,
      extractData: data => ({
        definition: data.signalDefinition,
        entity: data.entity,
        signalType: data.signalType,
        signalDataType: data.signalDataType,
      }),
    }),
    otherwise: querySchemaValidator(true),
  }),
  categoryId: yup.number().nullable(),
  signalType: yup.string().required().oneOf(values(SignalType)),
  signalDataType: yup.string().required().oneOf(values(SignalDataType)),
  isComputed: yup.boolean().nullable(),
});

export const segmentCategoryValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup.string().required(),
});
