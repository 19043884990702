import * as React from 'react';
import classes from './dot.module.scss';
import classNames from 'classnames';
import {colorAlphaTransformer} from '../../../../../utils/colors';

interface OwnProps {
  color: string;
  stroke?: string;
  className?: string;
  radius?: number;
  alpha?: number;
}

type AllProps = OwnProps;

export const Dot: React.FC<AllProps> = (props: AllProps) => {
  const {color, stroke, className, radius, alpha} = props;

  return (
    <div
      style={{
        backgroundColor: colorAlphaTransformer(color, alpha),
        borderColor: stroke,
        height: `${radius * 2}px`,
        width: `${radius * 2}px`,
      }}
      className={classNames(classes.Dot, className)}
    />
  );
};

Dot.defaultProps = {
  color: 'rgba(0, 0, 0, 0.5)',
  stroke: 'rgba(255, 255, 255, 1)',
  radius: 4,
  alpha: 1,
};
