import * as React from 'react';
import {Stage} from 'react-konva';
import {Stage as KonvaStage} from 'konva/types/Stage';
import {createContext, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {StageController, StageControllerImpl} from './stage.controller';
import classes from './stage.module.scss';
import FontFaceObserver from 'fontfaceobserver';
import {TooltipParams} from './stage.types';
import {StageProps} from 'react-konva/lib/ReactKonvaCore';

export interface StageContextProps {
  onReady?: (controller: StageController) => void;
  style?: any;
  TooltipComponent?: any;
}

export interface StageContextData {
  controller: StageController | null;
  style?: any;
}

export const StageContext = createContext<StageContextData>({
  controller: null,
  style: {},
});

export const StageContextProvider: React.FC<StageContextProps & StageProps> = (
  props: StageContextProps & StageProps
) => {
  const {children, onReady, style, TooltipComponent} = props;
  // States
  const [context, setContext] = useState<StageContextData>({
    controller: null,
    style,
  });
  const [isStageReady, setIsStageReady] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({x: 0, y: 0});
  // Refs
  const stageRef = useRef<KonvaStage>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const controller = useRef<StageController>(null);
  // Computed
  const rootSize = useMemo(
    () => ({
      width: rootRef.current?.clientWidth,
      height: rootRef.current?.clientHeight,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [rootRef.current]
  );
  // Set & create controller effect
  useEffect(() => {
    let _temp: any = controller.current;
    if (!controller.current && stageRef.current) {
      _temp = new StageControllerImpl(stageRef.current, rootRef.current, tooltipHandler);
      controller.current = _temp;
    }
    setContext(context => ({...context, controller: _temp}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageRef.current, rootRef.current]);
  useEffect(() => {
    setContext(context => ({...context, style}));
  }, [style]);

  const notifyReady = useCallback(() => {
    setIsStageReady(true);
    onReady && onReady(controller.current);
  }, [onReady, setIsStageReady, controller]);

  // Font load effect
  useEffect(() => {
    // @ts-ignore
    const onDone = () => {
      stageRef.current?.batchDraw();
      notifyReady();
    };

    if (!style?.fontName) {
      return onDone();
    }
    const font = new FontFaceObserver(style.fontName);
    font.load('', 1000).then(onDone).catch(onDone);
  }, [notifyReady, style.fontName]);

  const tooltipHandler = (params: TooltipParams) => {
    if (params) {
      setTooltipPosition({x: params.x, y: params.y});
      setTooltipData(params.data);
    } else {
      setTooltipData(null);
    }
  };

  // https://github.com/konvajs/react-konva/issues/188#issuecomment-478302062
  return (
    <StageContext.Consumer>
      {value => (
        <div className={classes.StageWrapper} ref={rootRef}>
          {rootSize && (
            <Stage
              style={{
                flex: 1,
                height: '100%',
              }}
              width={rootSize.width}
              height={rootSize.height}
              ref={stageRef}
              {...props}
            >
              <StageContext.Provider value={context}>
                {isStageReady && context.controller && children}
              </StageContext.Provider>
            </Stage>
          )}
          {TooltipComponent && (
            <TooltipComponent
              {...tooltipPosition}
              rootSize={rootSize}
              show={Boolean(tooltipData)}
              style={style}
              data={tooltipData}
            />
          )}
        </div>
      )}
    </StageContext.Consumer>
  );
};
