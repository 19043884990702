import * as React from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {useCallback, useContext, useMemo} from 'react';
import {max, flatten} from 'lodash';
import {calculateBbox} from '../../../../../core/konva/stage.utils';
import {
  BaseChartOptions,
  ChartWithLabelsOptions,
  ChartWithLabelsXYOptions,
  ItemDataset,
  LabelBasedChart,
  NumberDataset,
} from '../../chart-data.types';
import {GridLayout} from '../../layouts/grid-layout.component';
import {BarChartDatasetContainer} from './bar-chart-dataset-container.component';
import {useChartLabels} from '../../chart.hooks';
import {ChartTooltip} from '../../components/chart-tooltip.component';
import {ChartContext, ChartContextProvider} from '../../chart.context';
import {ResizeRender} from '../../../../hoc/resize-render/resize-render.component';
import classes from '../../chart.module.scss';

export interface BarChartOptions
  extends BaseChartOptions,
    ChartWithLabelsXYOptions,
    ChartWithLabelsOptions {
  errorBar?: boolean;
  yMax?: number;
}

interface OwnProps extends LabelBasedChart<NumberDataset | ItemDataset, BarChartOptions> {
  labels: string[];
}

type AllProps = OwnProps;

const BarChartController: React.FC<AllProps> = (props: AllProps) => {
  const {controller, style} = useContext(StageContext);
  const {onLegendClick, displayedDatasets: datasets_, datasetLabels} = useContext(ChartContext);
  const {labels: labelsFromProps, options, onDatasetClick} = props;
  const {
    legendsLabel,
    xLabel,
    yLabel,
    showLegend,
    yLabelSuffix: yLabelSuffix_,
    labels: labelsOptions,
    errorBar,
    yMax = 0,
  } = options;
  const {layoutPadding} = style;
  const maxDatasetLength: number = useMemo(
    () => max(datasets_.map(ds => ds.data.length)),
    [datasets_]
  );
  const transformer = useCallback((ds: NumberDataset | ItemDataset): ItemDataset => {
    let newDs = {...ds};
    const firstItem = newDs.data[0];
    // make sure all values are numbers
    if (typeof firstItem === 'number') {
      newDs = {
        ...newDs,
        // @ts-ignore
        data: newDs.data.map(value => ({value})),
      };
      return newDs;
    }
    return ds as any;
  }, []);
  // Make sure that all datasets have the same length
  const datasets: ItemDataset[] = useMemo(
    () => datasets_.map(transformer),
    [datasets_, transformer]
  );
  // Same as for labels
  const labels: string[] = useChartLabels(labelsFromProps, labelsOptions, maxDatasetLength);
  const maxDatasetsValue: number = useMemo(
    () =>
      max([
        ...flatten(
          flatten(datasets.map(d => d.data)).map(d =>
            errorBar ? [d.value, d.lower, d.upper] : d.value
          )
        ),
        yMax,
        1, // fixing the issue where yMax is 0
      ]),
    [datasets, errorBar]
  );
  const layoutBbox = useMemo(
    () => calculateBbox(controller?.getSize().width, controller?.getSize().height, layoutPadding),
    [controller, layoutPadding]
  );
  const yLabelSuffix = useMemo(() => (yLabelSuffix_ ? yLabelSuffix_ : ''), [yLabelSuffix_]);

  const primaryYAxisProp = useMemo(
    () => ({
      label: yLabel,
      suffix: yLabelSuffix,
      range: [0, maxDatasetsValue],
    }),
    [yLabel, yLabelSuffix, maxDatasetsValue]
  );
  const xAxisProp = useMemo(() => ({label: xLabel}), [xLabel]);
  /**
   * Render
   */
  return (
    <GridLayout
      labels={labels}
      legendLabels={datasetLabels}
      legendsTitle={legendsLabel || undefined}
      showLegend={showLegend}
      onLabelClicked={(label, e) => onLegendClick(label.datasetId, e)}
      xAxis={xAxisProp}
      yAxis={primaryYAxisProp}
      alwaysRenderLabels={true}
      {...layoutBbox}
    >
      {props => (
        <BarChartDatasetContainer
          {...props}
          datasets={datasets}
          onDatasetClick={onDatasetClick}
          yLabelSuffix={yLabelSuffix}
          errorBar={errorBar}
        />
      )}
    </GridLayout>
  );
};

export const BarChart: React.FC<AllProps> = (props: AllProps) => {
  return (
    <div className={classes.ChartContainer}>
      <ResizeRender>
        <ChartContextProvider {...props} TooltipComponent={ChartTooltip}>
          <BarChartController {...props} />
        </ChartContextProvider>
      </ResizeRender>
    </div>
  );
};

BarChart.defaultProps = {
  options: {
    highlightIds: [],
    showLegend: true,
  },
};
