import * as React from 'react';
import {last, max} from 'lodash';
import {useContext, useMemo} from 'react';
import {Layer} from 'react-konva';
import {EnhancedGroup, EnhancedText} from '../../../../core/konva/components';
import {CanvasElementPositions} from '../../../../core/konva/stage.types';
import {calculateBbox} from '../../../../core/konva/stage.utils';
import {StageContext} from '../../../../core/konva/stage-context.component';
import {ChartContext} from '../chart.context';
import {useChartLabelsElements} from '../chart.hooks';
import {createRangeTicks} from '../chart.utils';

export interface DumbbellChartLayoutInjectedProps extends CanvasElementPositions {
  xPixelRange?: [number, number];
  xRange?: [number, number];
  yRange?: [number, number];
  labels?: string[];
  xPositions: number[];
}

interface OwnProps extends CanvasElementPositions {
  xLabel?: string;
  yLabel?: string;
  children?: (injected: DumbbellChartLayoutInjectedProps) => any;
  xRange?: [number, number];
  xRangeMS?: boolean;
  yRange?: [number, number];
  labels?: string[];
  yLabelWidth?: number;
  highlightLabelIndex?: number;
}

type AllProps = OwnProps;

const PADDING_TOP = 22;
const PADDING_RIGHT = 10;

export const DumbbellChartLayout: React.FC<AllProps> = (props: AllProps) => {
  const {
    xLabel,
    yLabel,
    xRange: xRange_,
    yRange,
    children,
    width,
    height,
    labels,
    yLabelWidth,
    highlightLabelIndex,
    xRangeMS,
    x,
    y,
  } = props;
  const {style, controller} = useContext(StageContext);
  const {darkMode} = useContext(ChartContext);
  /**
   * Computed properties
   */
  const xRange: [number, number] = useMemo(
    () =>
      xRange_
        ? [Number((xRange_[0] * 1.1).toFixed(2)), Number((xRange_[1] * 1.1).toFixed(2))]
        : [0, labels.length - 1],
    [xRange_, labels]
  );
  const xLabels: number[] | string[] = useMemo(
    () =>
      labels
        ? labels
        : createRangeTicks({
            startRange: xRange[0],
            endRange: xRange[1],
            maxTicks: labels ? labels.length : 10,
            dateRange: xRangeMS,
          }),
    [labels, xRange, xRangeMS]
  );
  const renderLabels = useChartLabelsElements(
    xLabels,
    width - yLabelWidth - PADDING_RIGHT,
    controller,
    {
      fontName: style.fontName,
      fontSize: 10,
    }
  );
  const xPositions = useMemo(() => renderLabels.labels.map(i => i.x), [renderLabels.labels]);
  const xPixelRange: [number, number] = useMemo(
    () => [yLabelWidth, yLabelWidth + last(renderLabels.labels).x],
    [renderLabels.labels, yLabelWidth]
  );
  const labelsHeight = useMemo(() => max([renderLabels.height, 30]), [renderLabels]);
  const childrenViewBox = useMemo(
    () => calculateBbox(width, height, [yLabel ? PADDING_TOP : 0, 0, labelsHeight, 0]),
    [width, height, yLabel, labelsHeight]
  );
  // @ts-ignore
  const fixedXRange: [number, number] = useMemo(
    () => (xRange_ ? [Number(xLabels[0]), Number(xLabels[xLabels.length - 1])] : xRange),
    [xLabels, xRange, xRange_]
  );
  /**
   * Render
   */
  return (
    <Layer>
      <EnhancedGroup width={width} height={height} x={x} y={y}>
        <EnhancedText
          x={0}
          y={0}
          text={yLabel}
          fontSize={12}
          fill={'#898EA8'}
          fontFamily={style.fontName}
        />
        <EnhancedText
          x={0}
          y={height - labelsHeight / 2}
          width={width}
          text={xLabel}
          fontSize={10}
          fill={'#898EA8'}
          fontFamily={style.fontName}
        />

        <EnhancedGroup clip={childrenViewBox}>
          {children({
            ...childrenViewBox,
            height: childrenViewBox.height - 8,
            xPixelRange,
            xPositions,
            xRange: fixedXRange,
            yRange,
          })}
        </EnhancedGroup>

        <EnhancedGroup y={height - labelsHeight / 2} x={yLabelWidth}>
          {renderLabels.labels.map((label, idx) => (
            <EnhancedText
              key={`x_label_${idx}`}
              x={label.x}
              y={0}
              width={label.width}
              height={label.height}
              ellipsis
              align={'center'}
              fontSize={10}
              text={label.text}
              fontFamily={style.fontName}
              rotation={label.rotation}
              fill={
                highlightLabelIndex === idx
                  ? darkMode
                    ? '#ffffff'
                    : 'rgba(41, 50, 101, 1)'
                  : '#898EA8'
              }
              centerX
            />
          ))}
        </EnhancedGroup>
      </EnhancedGroup>
    </Layer>
  );
};

DumbbellChartLayout.defaultProps = {
  yLabelWidth: 150,
};
