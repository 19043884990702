import * as React from 'react';
import {EnhancedGroup, EnhancedText, EnhancedWedge} from '../../../../../core/konva/components';
import {BaseChartProps, SingleNumberDataset} from '../../chart-data.types';
import {useContext, useMemo} from 'react';
import {sum, min} from 'lodash';
import {number2k, toRadians} from '../../../../../core/konva/stage.utils';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {CanvasElementPositions} from '../../../../../core/konva/stage.types';
import {PieChartOptions} from './pie-chart.component';
import {arrayToMap} from 'front-core';
import {colorAlphaTransformer} from '../../../../../utils/colors';

interface OwnProps
  extends BaseChartProps<SingleNumberDataset, PieChartOptions>,
    CanvasElementPositions {
  radiusSizeRatio?: number;
  showLegend?: boolean;
}

type AllProps = OwnProps;

export const PieChartDataContainer: React.FC<AllProps> = (props: AllProps) => {
  const context = useContext(StageContext);
  const {controller, style} = context;
  const {datasets, width, height, x, y, radiusSizeRatio, onDatasetClick} = props;

  const datasetsMap = useMemo(() => arrayToMap(datasets, 'id'), [datasets]);
  const center = useMemo(() => {
    const size = controller.getSize();
    return {
      x: size.width / 2,
      y: size.height / 2,
    };
  }, [controller]);
  const radius = useMemo(
    () => (min([width, height]) * radiusSizeRatio) / 2,
    [width, height, radiusSizeRatio]
  );
  const totalDataSum = useMemo(() => sum(datasets.map(d => d.data)), [datasets]);
  const wedges = useMemo(() => {
    if (datasets.length === 0) {
      return [];
    }
    let startingAngel = 0;
    const highlight = [];
    const others = [];

    datasets.forEach(d => {
      const angle = 360 * (d.data / totalDataSum);
      const addToArray = d.highlight ? highlight : others;
      const wedge = {
        id: d.id,
        x: center.x,
        y: center.y,
        radius,
        angle,
        rotation: startingAngel - 90,
        stroke: d.highlight ? style.highlightColor : 'rgba(255, 255, 255, 0.8)',
        strokeWidth: d.highlight ? 3 : 2,
        fillRadialGradientStartPoint: {x: 0, y: 0},
        fillRadialGradientStartRadius: 0,
        fillRadialGradientEndPoint: {x: 0, y: 0},
        fillRadialGradientEndRadius: radius,
        fillRadialGradientColorStops: [0, colorAlphaTransformer(d.color, 0.5), 1, d.color],
        lineJoin: 'round',
        lineCap: 'round',
      };
      addToArray.push(wedge);
      startingAngel += angle;
    });
    return [...others, ...highlight];
  }, [center.x, center.y, datasets, radius, style.highlightColor, totalDataSum]);
  const labels = useMemo(() => {
    let startingAngel = 0;
    return datasets.map((d, idx) => {
      const percentage = Number(((d.data / totalDataSum) * 100).toFixed(2));
      const value = number2k(d.data);
      const angle = 360 * (d.data / totalDataSum);
      const rotation = startingAngel - 90;
      const label = {
        label: d.label,
        value,
        percentage,
        x: center.x + Math.cos(toRadians(angle / 2 + rotation)) * (radius * 0.7),
        y: center.y + Math.sin(toRadians(angle / 2 + rotation)) * (radius * 0.7),
      };
      startingAngel += angle;
      return label;
    });
  }, [totalDataSum, datasets, center, radius]);

  return (
    <EnhancedGroup width={width} height={height} x={x} y={y}>
      {/*@ts-ignore*/}
      {wedges.map((w, idx) => (
        <EnhancedWedge
          key={idx}
          {...w}
          onClick={onDatasetClick ? e => onDatasetClick(datasetsMap[w.id]) : undefined}
          tooltipEnabled
          tooltipScale={1.05}
          tooltipData={{
            datasetName: datasetsMap[w.id].label,
            color: datasetsMap[w.id].color,
            value: (
              <div>
                <strong>{labels[idx].value}</strong> ({labels[idx].percentage}%)
              </div>
            ),
          }}
        />
      ))}
      {labels.map((l, idx) => (
        <EnhancedText
          key={idx}
          x={l.x}
          y={l.y}
          width={50}
          height={20}
          align={'center'}
          text={`${l.percentage}%`}
          fill={'#ffffff'}
          fontSize={12}
          center
        />
      ))}
    </EnhancedGroup>
  );
};

PieChartDataContainer.defaultProps = {
  radiusSizeRatio: 0.8,
};
