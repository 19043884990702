import {
  ExtendedContentFunnelOverviewItem,
  TableTab,
} from './content-funnel-overview-viewer.component';
import {
  ColumnType,
  ContentFunnelOverviewFigureStep,
  ContentFunnelOverviewItem,
} from '../../../../types';
import {safeDivision} from 'front-core';
import {range, sum} from 'lodash';

export const generateStepKey = (
  s: ContentFunnelOverviewFigureStep,
  suffix: string = 'conversion'
) => `${s.label}_${s.order}_${suffix}`.toLowerCase().replace(' ', '_');

export const getValueByTab = (
  tab: TableTab,
  item: ExtendedContentFunnelOverviewItem,
  stepIndex: number
) => {
  switch (tab) {
    case TableTab.CONVERSION_FROM_PREV:
      return item.conversionFromPrev[stepIndex] * 100;
    case TableTab.CONVERSION_FROM_START:
      return item.conversionFromStart[stepIndex] * 100;
    case TableTab.UPLIFT:
      return item.upliftPerStep[stepIndex] * 100;
    case TableTab.COUNT_ENTITIES:
      return item.fromPrevious[stepIndex];
  }
};

export const getColumnDataByTab = (tab: TableTab) => {
  switch (tab) {
    case TableTab.CONVERSION_FROM_PREV:
    case TableTab.CONVERSION_FROM_START:
      return {
        type: ColumnType.GRADIENT,
        options: {
          unit: '%',
          sortable: true,
          filterable: true,
        },
      };
    case TableTab.UPLIFT:
      return {
        type: ColumnType.CHANGE,
        options: {
          sortable: true,
          filterable: true,
        },
      };
    case TableTab.COUNT_ENTITIES:
      return {
        type: ColumnType.NUMERIC,
        options: {
          sortable: true,
          filterable: true,
        },
      };
  }
};

export const extendContentData = (
  items: ContentFunnelOverviewItem[],
  steps: ContentFunnelOverviewFigureStep[]
): ExtendedContentFunnelOverviewItem[] => {
  // calculate conversions
  const res = items.map(item => {
    const values = item.fromPrevious;
    const funnelStart = values[0];
    const conversionFromStart = [];
    const conversionFromPrev = [];
    for (const [idx, value] of values.entries()) {
      if (idx === 0) {
        continue;
      }
      conversionFromStart.push(safeDivision(value, funnelStart));
      conversionFromPrev.push(safeDivision(values[idx], values[idx - 1]));
    }

    return {
      ...item,
      conversionFromPrev,
      conversionFromStart,
    };
  });

  // calculate uplift per step
  const sumOfUsersForStep = range(steps.length).map(i =>
    sum(res.map(other => other.fromPrevious[i]))
  );
  const conversionWeightedAveragePerStep = range(0, steps.length - 1).map(i =>
    safeDivision(
      sum(res.map(other => other.fromPrevious[i] * other.conversionFromPrev[i])),
      sumOfUsersForStep[i]
    )
  );
  return res.map(item => {
    const upliftPerStep = item.conversionFromPrev.map((conversion, idx) =>
      safeDivision(
        conversion - conversionWeightedAveragePerStep[idx],
        conversionWeightedAveragePerStep[idx]
      )
    );

    return {
      ...item,
      upliftPerStep,
    };
  });
};
