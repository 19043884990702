import * as React from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {useContext, useMemo} from 'react';
import {calculateBbox} from '../../../../../core/konva/stage.utils';
import {
  BaseChartOptions,
  ChartWithLabelsOptions,
  ChartWithLabelsXYOptions,
  ChartWithMinMaxYOptions,
  LabelBasedChart,
  NumberDataset,
} from '../../chart-data.types';
import {ChartTooltip} from '../../components/chart-tooltip.component';
import {PercentageLineChartDatasetContainer} from './percentage-line-chart-dataset-container.component';
import {DumbbellChartLayout} from '../../layouts/dumbbell-chart-layout.component';
import {min, max, get} from 'lodash';
import {ChartContext, ChartContextProvider} from '../../chart.context';
import {useChartLabels, useRange} from '../../chart.hooks';
import {ResizeRender} from '../../../../hoc/resize-render/resize-render.component';
import classes from '../../chart.module.scss';

export interface PercentageChartOptions
  extends BaseChartOptions,
    ChartWithLabelsXYOptions,
    ChartWithLabelsOptions,
    ChartWithMinMaxYOptions {}

interface OwnProps extends LabelBasedChart<NumberDataset, PercentageChartOptions> {}

type AllProps = OwnProps;

const PercentageLineChartController: React.FC<AllProps> = (props: AllProps) => {
  const {style, controller} = useContext(StageContext);
  const {datasets} = useContext(ChartContext);
  const {labels: labelsFromProps, options, onDatasetClick} = props;
  const {xLabel, labels: labelsOptions} = options;
  /**
   * Computed properties
   */
  const labels: string[] = useChartLabels(labelsFromProps, labelsOptions);
  const highlightLabelIndex = useMemo(() => max(datasets.map(d => d.data.length)) - 1, [datasets]);
  const layoutBbox = useMemo(
    () => calculateBbox(controller?.getSize().width, controller?.getSize().height),
    [controller]
  );
  const isDateLabels: boolean = useMemo(
    () => get(labelsOptions, 'type') === 'date',
    [labelsOptions]
  );
  let yValues = [];
  datasets.forEach(ds => ds.data.forEach(v => yValues.push(v)));
  const yRange = useRange(yValues, options?.yMin || 0, options?.yMax || 1) as [number, number];
  const yLabelWidth = useMemo(
    () => max(datasets.map(ds => controller.measureTextWidth(ds.label, style.fontName, 12) + 40)),
    [datasets, controller]
  );
  /**
   * Render
   */
  return (
    <DumbbellChartLayout
      {...layoutBbox}
      yLabelWidth={yLabelWidth}
      xLabel={xLabel}
      labels={labels}
      highlightLabelIndex={highlightLabelIndex}
      xRangeMS={isDateLabels}
      yRange={yRange}
    >
      {props => (
        <PercentageLineChartDatasetContainer
          {...props}
          labels={labels}
          datasets={datasets}
          onDatasetClick={onDatasetClick}
        />
      )}
    </DumbbellChartLayout>
  );
};

export const PercentageLineChart: React.FC<AllProps> = (props: AllProps) => {
  return (
    <div className={classes.ChartContainer}>
      <ResizeRender>
        <ChartContextProvider
          {...props}
          transformDS={ds => ({
            ...ds,
            data: ds.data.map(i => min([max([i, 0]), 1])),
          })}
          TooltipComponent={ChartTooltip}
        >
          <PercentageLineChartController {...props} />
        </ChartContextProvider>
      </ResizeRender>
    </div>
  );
};

PercentageLineChart.defaultProps = {
  options: {
    highlightIds: [],
  },
};
