import classes from './journey-drivers-scope-modal.module.scss';
import {Button} from '../../../../../../../../../simple/controls/button/button.component';
import {useState, useCallback, useMemo} from 'react';
import {ModalWrapper} from '../../../../../../shared/modal-wrapper/modal-wrapper.component';
import {FancyHeader} from '../../../../../../../../../simple/data-display/fancy-header/fancy-header.component';
import {
  FunnelIcon,
  CircleInfoRegularIcon,
} from '../../../../../../../../../simple/controls/icons/icons.component';
import {JourneyDriversScopeEventsTable} from '../journey-drivers-scope-events-table/journey-drivers-scope-events-table.component';
import {
  JourneyDriversSimulationFigureData,
  JourneyDriversSimulationFigureOptions,
} from '../../../../../../../types/figures.types';
import {
  generateEventOptions,
  extendEventsData,
  filterEventsData,
  createInitialFilters,
} from '../../journey-drivers-scope.utils';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../../hooks/use-document-translation.hook';
import {PartyHornIcon} from '../../../../../../../../../simple/controls/icons/icons.component';
import {JourneyDriversScopeEventsFilters} from '../journey-drivers-scope-events-filters/journey-drivers-scope-events-filters.component';
import {ExtendedJourneyDriversScopeEvent, IEventsFilters} from '../../journey-drivers-scope.consts';
import {number2k} from 'front-core';

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.JOURNEY_DRIVERS_SCOPE_FIGURE;

interface OwnProps {
  data: JourneyDriversSimulationFigureData;
  options: JourneyDriversSimulationFigureOptions;
  isOpen: boolean;
  onClose: () => void;
  filters: any;
  onApplyFilters: (filters: IEventsFilters) => void;
}

type AllProps = OwnProps;

export const JourneyDriversScopeModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    data,
    options,
    isOpen,
    onClose: onCloseFromProps,
    filters: filtersFromProps,
    onApplyFilters,
  } = props;
  const {events} = data.scope;
  const {t} = useDocumentTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const filtersEventsOptions = useMemo(() => generateEventOptions(events), [events]);

  const extendedData: ExtendedJourneyDriversScopeEvent[] = useMemo(() => {
    return extendEventsData(events, data.totalEntities, options.isPercentageValue);
  }, [events, data.totalEntities, options.isPercentageValue]);

  const adoptionRangeMin = useMemo(() => {
    const value = Math.min(...extendedData.map(e => e.adoption));
    return Math.floor(isFinite(value) ? value : 0);
  }, [extendedData]);
  const adoptionRangeMax = useMemo(() => {
    const value = Math.max(...extendedData.map(e => e.adoption));
    return Math.ceil(isFinite(value) ? value : 100);
  }, [extendedData]);
  const [filters, setFilters] = useState<IEventsFilters>(
    createInitialFilters(filtersFromProps, adoptionRangeMin, adoptionRangeMax)
  );

  const filteredData: ExtendedJourneyDriversScopeEvent[] = useMemo(() => {
    return filterEventsData(extendedData, filters);
  }, [extendedData, filters]);

  const onSubmit = useCallback(() => {
    onApplyFilters(filters);
    setIsSubmitted(true);
  }, [filters, onApplyFilters, setIsSubmitted]);

  const onClose = useCallback(() => {
    if (isSubmitted) {
      window.location.reload();
    } else {
      setFilters(createInitialFilters(filtersFromProps, adoptionRangeMin, adoptionRangeMax));
      onCloseFromProps();
    }
  }, [
    onCloseFromProps,
    isSubmitted,
    setFilters,
    filtersFromProps,
    adoptionRangeMin,
    adoptionRangeMax,
  ]);

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} width={'80%'} height={'80%'}>
      <div className={classes.JourneyDriversScopeModal}>
        <FancyHeader
          title={t(TRANS_KEYS_PREFIX.MODAL.TITLE)}
          subTitle={t(TRANS_KEYS_PREFIX.MODAL.SUBTITLE)}
          icon={FunnelIcon}
        />
        <div className={classes.ModalBody}>
          {!isSubmitted && (
            <>
              <div className={classes.Filters}>
                <span className={classes.Header}>{t(TRANS_KEYS_PREFIX.FILTERS.TITLE)}</span>
                <JourneyDriversScopeEventsFilters
                  filters={filters}
                  onChange={setFilters}
                  eventOptions={filtersEventsOptions}
                  adoptionRangeMin={adoptionRangeMin}
                  adoptionRangeMax={adoptionRangeMax}
                />
              </div>
              <div className={classes.Preview}>
                <span className={classes.Header}>
                  {t(TRANS_KEYS_PREFIX.EVENTS.TITLE, {
                    count: number2k(filteredData.length),
                  })}
                </span>
                <JourneyDriversScopeEventsTable
                  events={filteredData}
                  entity={options.entity}
                  totalEntities={data.totalEntities}
                  isPercentageValue={options.isPercentageValue}
                />
              </div>
            </>
          )}
          {isSubmitted && (
            <div className={classes.SubmitSuccess}>
              <div className={classes.Container}>
                <PartyHornIcon className={classes.SuccessIcon} />
                <div className={classes.Title}>{t(TRANS_KEYS_PREFIX.MODAL.SUBMITTED_TITLE)}</div>
                <div className={classes.SubTitle}>
                  {t(TRANS_KEYS_PREFIX.MODAL.SUBMITTED_SUBTITLE)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.ModalFooter}>
          {!isSubmitted && (
            <>
              <span className={classes.FilteredEventsInfo}>
                <CircleInfoRegularIcon className={classes.Icon} />
                {t(TRANS_KEYS_PREFIX.MODAL.FILTERED_EVENTS_INFO)}
              </span>
              <Button className={classes.Button} onClick={onSubmit}>
                {t(TRANS_KEYS_PREFIX.MODAL.SUBMIT_BUTTON_LABEL)}
              </Button>
            </>
          )}
          {isSubmitted && (
            <Button className={classes.Button} onClick={onClose}>
              {t(TRANS_KEYS_PREFIX.MODAL.CLOSE_BUTTON_LABEL)}
            </Button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};
