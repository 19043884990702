import * as React from 'react';
import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import classnames from 'classnames';
import {RCAMetric, RCAMode} from '../../../../../../types';
import classes from './rca-overview-compare-view.module.scss';
import {TextInput} from '../../../../../../../../forms/inputs/text-input/text-input.component';
import {
  CircleInfoRegularIcon,
  SearchIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {calcMetricUplift, generateMainDSForChart} from '../../rca-overview-utils';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {LineChart} from '../../../../../../../charts/charts/line-chart/line-chart.component';
import {TrendChip} from '../../../../../../../../simple/data-display/trend-chip/trend-chip.component';
import {Checkbox} from '../../../../../../../../forms/inputs/checkbox/checkbox.component';
import {groupBy} from 'lodash';
import {TooltipIfOverflow} from '../../../../../../../../simple/generic/tooltips/tooltips.component';
import TransKeys from 'translations';

interface OwnProps {
  metric: RCAMetric;
  compareMetrics: RCAMetric[];
  mode: RCAMode;
  analyzedDate: string;
  comparedDate: string;
  hasPopulationFilter: boolean;
  onSignalClicked: (signalId: number) => void;
  className?: string;
}

type AllProps = OwnProps;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_OVERVIEW;

export const RCAOverviewCompareView: React.FC<AllProps> = (props: AllProps) => {
  const {
    metric,
    compareMetrics: compareMetricsFromProps,
    mode,
    analyzedDate,
    comparedDate,
    hasPopulationFilter,
    onSignalClicked,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectedMetricId, setSelectedMetricId] = React.useState<number>(null);
  const onMetricClicked = useCallback(id => setSelectedMetricId(id), [setSelectedMetricId]);
  const upliftDirection = useMemo(() => {
    const {uplift} = calcMetricUplift(metric, analyzedDate, comparedDate, mode);
    return uplift > 0 ? 'up' : 'down';
  }, [metric, mode, analyzedDate, comparedDate]);
  const compareMetrics = useMemo(() => {
    return compareMetricsFromProps
      .filter(m => m.name.toLowerCase().includes(searchValue.toLowerCase()))
      .map(metric => {
        const {uplift, isSignificant} = calcMetricUplift(metric, analyzedDate, comparedDate, mode);

        return {
          ...metric,
          isSignificant,
          uplift,
        };
      })
      .sort((a, b) => {
        if (upliftDirection === 'up') {
          return b.uplift - a.uplift;
        } else {
          return a.uplift - b.uplift;
        }
      });
  }, [compareMetricsFromProps, searchValue, mode, upliftDirection]);
  const otherMetric = useMemo(
    () => compareMetrics.find(m => m.signalId === selectedMetricId),
    [compareMetrics, selectedMetricId]
  );
  const groupedCompareMetrics = useMemo(
    () => groupBy(compareMetrics, 'isSignificant'),
    [compareMetrics]
  );
  const mainChart = useMemo(() => {
    if (metric.samples.length === 0) {
      return;
    }
    const mainDS = generateMainDSForChart({
      samples: metric.samples,
      name: metric.name,
      isPercentageValue: metric.isPercentageValue,
      higherIsBetter: metric.higherIsBetter,
      analyzedDate: analyzedDate,
      comparedDate: comparedDate,
      showMarkColors: mode === RCAMode.LOOPS_ALGO,
    });
    let otherDS;
    if (otherMetric) {
      otherDS = generateMainDSForChart({
        samples: otherMetric.samples,
        name: otherMetric.name,
        isPercentageValue: otherMetric.isPercentageValue,
        higherIsBetter: otherMetric.higherIsBetter,
        analyzedDate: null,
        comparedDate: null,
        showMarkColors: mode === RCAMode.LOOPS_ALGO,
      });
      otherDS.dashedDescription = undefined;
      otherDS.yAxis = 'secondary';
    }

    return {
      datasets: otherDS ? [mainDS, otherDS] : [mainDS],
      displayedDatasetIds: otherDS ? [mainDS.id, otherDS.id] : [mainDS.id],
      options: {
        xLabel: 'Date',
        // yLabel: metric.name,
        // secondaryYLabel: otherMetric?.name,
        yLabelSuffix: metric.isPercentageValue ? '%' : undefined,
        secondaryYSuffix: otherMetric?.isPercentageValue ? '%' : undefined,
        yAxisMaxTicks: 5,
        minimalXAxisTicks: true,
        labels: {
          timeUnit: metric.granularity,
          type: 'date',
          dateFormat: 'MMM D',
          capitalize: false,
          dateInputFormat: null,
        },
        showDots: true,
      },
    } as any;
  }, [metric, otherMetric, analyzedDate, comparedDate, mode]);

  const renderMetricListItem = metric => {
    return (
      <div
        onClick={() => onMetricClicked(metric.signalId)}
        key={metric.signalId}
        className={classnames(
          classes.MetricItemWrapper,
          metric.signalId === selectedMetricId && classes.Selected
        )}
      >
        <div className={classes.MetricItem}>
          <Checkbox
            checked={metric.signalId === selectedMetricId}
            onChange={() => onMetricClicked(metric.signalId)}
          />
          <TooltipIfOverflow title={metric.name}>
            <div className={classes.Name}>{metric.name}</div>
          </TooltipIfOverflow>
          <TrendChip
            className={classNames(mode !== RCAMode.LOOPS_ALGO && classes.Transparent)}
            value={metric.uplift * 100}
            isSignificant={metric.isSignificant}
            higherIsBetter={metric.higherIsBetter}
            size={'xsmall'}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(classes.RCAOverviewCompareView, className)}>
      <div className={classes.List}>
        <div className={classes.Search}>
          <TextInput
            value={searchValue}
            onChange={v => setSearchValue((v || '').toString())}
            className={classnames(classes.SearchBox, className)}
            iconClassName={classes.SearchIcon}
            inputClassName={classes.SearchInput}
            placeholder={'Search'}
            icon={SearchIcon}
            clearable
          />
        </div>
        <div className={classes.Items}>
          {hasPopulationFilter && (
            <div className={classes.Notice}>
              <CircleInfoRegularIcon className={classes.InfoIcon} />
              <div>{t(TRANS_KEYS_PREFIX.KPI_COMPARISON.POPULATION_FILTER_WARN)}</div>
            </div>
          )}
          {mode === RCAMode.COMPARE_TO_DATE && (
            <div className={classes.Notice}>
              <CircleInfoRegularIcon className={classes.InfoIcon} />
              <div>
                {t(TRANS_KEYS_PREFIX.KPI_COMPARISON.COMPARE_TO_DATE_TREND_WARN, {
                  granularity: metric.granularity,
                })}
              </div>
            </div>
          )}
          {groupedCompareMetrics['true'] && groupedCompareMetrics['true'].length > 0 && (
            <>
              {groupedCompareMetrics['false']?.length > 0 && (
                <div className={classes.GroupTitle}>Significant</div>
              )}
              {groupedCompareMetrics['true'].map(metric => renderMetricListItem(metric))}
            </>
          )}
          {groupedCompareMetrics['false'] && groupedCompareMetrics['false'].length > 0 && (
            <>
              {groupedCompareMetrics['true']?.length > 0 && (
                <div className={classes.GroupTitle}>Others</div>
              )}
              {groupedCompareMetrics['false'].map(metric => renderMetricListItem(metric))}
            </>
          )}
          {compareMetrics.length === 0 && searchValue && (
            <div className={classes.EmptyState}>
              {t(TRANS_KEYS_PREFIX.KPI_COMPARISON.SEARCH_EMPTY_STATE, {
                query: searchValue,
              })}
            </div>
          )}
        </div>
      </div>
      <div className={classes.Content}>
        {mainChart && (
          <div className={classes.Title}>
            {metric.name && (
              <div onClick={() => onSignalClicked(metric.signalId)} className={classes.MetricName}>
                {metric.name}
              </div>
            )}
            {otherMetric && (
              <div
                onClick={() => onSignalClicked(otherMetric.signalId)}
                className={classes.MetricName}
              >
                {otherMetric.name}
              </div>
            )}
          </div>
        )}
        {mainChart && (
          <div className={classes.ChartWrapper}>
            <LineChart {...mainChart} />
          </div>
        )}
      </div>
    </div>
  );
};

RCAOverviewCompareView.defaultProps = {};
