import {useCallback, useContext, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {createUndefinedObject, hasError} from '../../../../../utils/general.utils';
import {values} from 'lodash';
import {BaseSignalBuilder} from '../../../../shared/core/query-builders/signal-builder/base-signal-builder.component';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {StatisticalSignificanceSelector} from '../../components/ui-selectors/statistical-significance-selector/statistical-significance-selector.component';

const statisticalSignificanceSchemaMapping = {
  statistical_significance: 'power_percentile',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const POWER_SIGNAL_MAPPING = {
  signal_id: 'power_signal',
};

const createPowerSignalFilters = (entityContext = undefined) => [
  {
    type: SignalType.MEASURE,
    data_type: [SignalDataType.DECIMAL, SignalDataType.INTEGER, SignalDataType.BOOLEAN],
    entity_binding: TableEntityBinding.TWO_WAY,
    exclude_templates: '*',
    entityContext,
  },
  {
    type: SignalType.DIMENSION,
    data_type: SignalDataType.DECIMAL,
    entity_binding: TableEntityBinding.ONE_WAY,
    exclude_templates: '*',
    entityContext,
  },
  {
    templates: ['bounded_action_nu', 'l7', 'l28'],
    entity_binding: TableEntityBinding.ONE_WAY,
  },
];

export const Analysis59Form = (props: AnalysisFormProps) => {
  const {className, onSignalInfo} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const {productEntities, defaultTableEntity} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];
  const powerSignalFilters = useMemo(
    () => createPowerSignalFilters(entityContext),
    [entityContext]
  );

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_59', parameters => {
      const defaults = {};
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      defaults[statisticalSignificanceSchemaMapping.statistical_significance] = 0.9;
      return defaults;
    });
    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_59');
    };
  }, [registerDefaultHandler, removeDefaultHandler, entityContext, defaultTableEntity]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = Array.from(
        new Set([...values(POWER_SIGNAL_MAPPING), ...values(segmentFilterSchemaMapping)])
      );
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={'Power KPI'}
        subTitle={'Engagement KPI that the Power Users split is based on'}
        className={classes.Parameter}
        error={hasError(errors, values(POWER_SIGNAL_MAPPING))}
      >
        <BaseSignalBuilder
          value={parameters}
          onChange={changeParametersValue}
          onSignalInfo={onSignalInfo}
          schemaKeysMapping={POWER_SIGNAL_MAPPING}
          filters={powerSignalFilters}
          errors={errors}
          clearable
        />
      </ParameterInputWrapper>
      <StatisticalSignificanceSelector
        title={'Power Percentile'}
        subTitle={'Only users above this percentile will be marked as power users'}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={statisticalSignificanceSchemaMapping}
        errors={errors}
        className={classes.Parameter}
      />
      <TimeFrameSelector
        title={'Time frame'}
        subTitle={'Set the time frame for this analysis.'}
        helperText={`For stable results, we exclude users' behavior in their first 30 days since join date.`}
        minDays={30}
        className={classes.Parameter}
        errors={errors}
        value={parameters}
        onChange={changeParametersValue}
      />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
    </div>
  );
};
