import {Content} from '../models/content.model';
import yup from '../../config/yup.config';
import httpClientService from '../../services/http-client.service';
import {
  validateContentDefinitionNetworkRequest,
  validateNameNetworkRequest,
} from '../../http/validations.network-requests';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import {SignalDataType} from '../models/signal.model';
import {nullableValues} from '../../utils/yup.utils';

import {apiQuerySchemaValidator} from './query-builder.dto.ts';

export type ContentDTO = Partial<Content>;

export const contentDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup
    .string()
    .required()
    .test('unique_name', 'Name already exists', async function (name: string) {
      const {id} = this.parent;
      try {
        await httpClientService.exec(
          validateNameNetworkRequest({
            name,
            existingId: id,
            modelType: 'content',
          })
        );
        return true;
      } catch (e) {
        return false;
      }
    }),
  shortDescription: yup.string().nullable(),
  entity: yup.string().oneOf(values(TableEntity)).required(),
  // signals
  usageSignalId: yup.number().optional().nullable(),
  usageSignalDataType: yup.string().oneOf(Object.values(SignalDataType)).required(),
  usageSignalDefinition: apiQuerySchemaValidator({
    required: true,
    networkRequest: validateContentDefinitionNetworkRequest,
    extractData: data => ({
      definition: data.usageSignalDefinition,
      entity: data.entity,
      signalDataType: data.usageSignalDataType,
    }),
  }),
  exposureSignalId: yup.number().optional().nullable(),
  exposureSignalDataType: yup.mixed().oneOf(nullableValues(SignalDataType)),
  exposureSignalDefinition: apiQuerySchemaValidator({
    required: false,
    networkRequest: validateContentDefinitionNetworkRequest,
    extractData: data => ({
      definition: data.exposureSignalDefinition,
      entity: data.entity,
      signalDataType: data.exposureSignalDataType,
    }),
  }),
  intentSignalId: yup.number().optional().nullable(),
  intentSignalDataType: yup.mixed().oneOf(nullableValues(SignalDataType)),
  intentSignalDefinition: apiQuerySchemaValidator({
    required: false,
    networkRequest: validateContentDefinitionNetworkRequest,
    extractData: data => ({
      definition: data.intentSignalDefinition,
      entity: data.entity,
      signalDataType: data.intentSignalDataType,
    }),
  }),
});
