import * as React from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {useContext, useMemo} from 'react';
import {min, max} from 'lodash';
import {calculateBbox} from '../../../../../core/konva/stage.utils';
import {
  BaseChartOptions,
  BaseChartProps,
  ChartWithLinesOptions,
  ChartWithLabelsXYOptions,
  DumbbellDataset,
} from '../../chart-data.types';
import {DumbbellChartDatasetContainer} from './dumbbell-chart-dataset-container.component';
import {DumbbellChartLayout} from '../../layouts/dumbbell-chart-layout.component';
import {ChartTooltip} from '../../components/chart-tooltip.component';
import {ChartContext, ChartContextProvider} from '../../chart.context';
import {ResizeRender} from '../../../../hoc/resize-render/resize-render.component';
import classes from '../../chart.module.scss';

export interface DumbbellChartOptions
  extends BaseChartOptions,
    ChartWithLabelsXYOptions,
    ChartWithLinesOptions {}

interface OwnProps extends BaseChartProps<DumbbellDataset, DumbbellChartOptions> {}

type AllProps = OwnProps;

const DumbbellChartController: React.FC<AllProps> = (props: AllProps) => {
  const {controller, style} = useContext(StageContext);
  const {datasets: datasets_} = useContext(ChartContext);
  const {onDatasetClick} = props;
  const {xLabel, yLabel, lines} = props.options;
  /**
   * Computed properties
   */
  const datasets: DumbbellDataset[] = useMemo(
    () =>
      datasets_
        .map((ds, index) => ({
          ...ds,
          data: {
            value: ds.data.value,
            lower: Math.min(ds.data.lower, ds.data.value),
            upper: Math.max(ds.data.upper, ds.data.value),
          },
        }))
        .sort((a, b) => Number(b.highlight) - Number(a.highlight)),
    [datasets_]
  );
  const layoutBbox = useMemo(
    () => calculateBbox(controller?.getSize().width, controller?.getSize().height),
    [controller]
  );
  const yLabelMaxWidth: number = useMemo(
    () =>
      datasets.reduce(
        (maxWidth: number, curr: DumbbellDataset) =>
          max([maxWidth, controller?.measureTextWidth(curr.label, style.fontName, 12) + 40]),
        0
      ),
    [controller, datasets, style.fontName]
  );
  const xRange: [number, number] = useMemo(() => {
    let startRange = Infinity;
    let endRange = -Infinity;

    for (const ds of datasets) {
      startRange = min([startRange, ds.data.lower]);
      endRange = max([endRange, ds.data.upper]);
    }

    return [startRange, endRange];
  }, [datasets]);
  /**
   * Render
   */
  return (
    <DumbbellChartLayout
      xLabel={xLabel}
      yLabel={yLabel}
      xRange={xRange}
      yLabelWidth={yLabelMaxWidth}
      {...layoutBbox}
    >
      {props => (
        <>
          <DumbbellChartDatasetContainer
            {...props}
            datasets={datasets}
            onDatasetClick={onDatasetClick}
            lines={lines}
          />
        </>
      )}
    </DumbbellChartLayout>
  );
};

export const DumbbellChart: React.FC<AllProps> = (props: AllProps) => {
  return (
    <div className={classes.ChartContainer}>
      <ResizeRender>
        <ChartContextProvider {...props} TooltipComponent={ChartTooltip}>
          <DumbbellChartController {...props} />
        </ChartContextProvider>
      </ResizeRender>
    </div>
  );
};

DumbbellChart.defaultProps = {
  options: {
    highlightIds: [],
  },
};
