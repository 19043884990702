import {useCallback, useContext} from 'react';
import {DocumentViewerContext} from '../contexts/document-viewer.context';
import {useTranslation} from '../../../../core/translations/use-translation';

interface UseDocumentTranslationsReturnType {
  t: (key: string, parameters?: any) => string;
}

export const useDocumentTranslation = (): UseDocumentTranslationsReturnType => {
  const {globalParameters} = useContext(DocumentViewerContext);
  const {t} = useTranslation();
  const documentTranslationFunc = useCallback(
    (key: string, parameters?: any) => {
      const tParams = {...globalParameters, ...(parameters || {})};
      return t(key, tParams);
    },
    [globalParameters, t]
  );
  return {
    t: documentTranslationFunc,
  };
};
