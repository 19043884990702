import * as React from 'react';
import classNames from 'classnames';
import {ExplainabilityLabel} from '../../../../../types';
import classes from './explainability-label-display.module.scss';
import {useMemo} from 'react';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {Tooltip} from '@material-ui/core';
import {
  ChevronsUpRegularIcon,
  ChevronUpRegularIcon,
} from '../../../../../../../simple/controls/icons/icons.component';

export interface ExplainabilityLabelDisplayProps {
  label?: ExplainabilityLabel;
  helperText?: string;
  isSignificant?: boolean;
  modelName?: string;
  className?: string;
}

const ICONS = {
  [ExplainabilityLabel.HIGH]: ChevronsUpRegularIcon,
  [ExplainabilityLabel.MEDIUM]: ChevronUpRegularIcon,
};

export const ExplainabilityLabelDisplay: React.FC<ExplainabilityLabelDisplayProps> = (
  props: ExplainabilityLabelDisplayProps
) => {
  const {label, className, isSignificant, modelName = 'this segment', helperText} = props;
  const {t} = useDocumentTranslation();

  const texts = useMemo(() => {
    const injected = {model: modelName};
    return label && isSignificant
      ? {
          label: t(
            TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.EXPLAINABILITY_LABEL[
              label.toUpperCase()
            ].LABEL,
            injected
          ),
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.EXPLAINABILITY_LABEL[
              label.toUpperCase()
            ].HELPER_TEXT,
            injected
          ),
        }
      : !isSignificant
        ? {
            label: t(
              TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.EXPLAINABILITY_LABEL.INSIGNIFICANT
                .LABEL,
              injected
            ),
            helperText: t(
              TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.EXPLAINABILITY_LABEL.INSIGNIFICANT
                .HELPER_TEXT,
              injected
            ),
          }
        : null;
  }, [label, modelName, isSignificant, t]);
  const Icon = ICONS[label];

  return (
    texts && (
      <Tooltip title={helperText ?? texts.helperText} placement={'top'} interactive={false}>
        <div
          className={classNames(
            classes.ExplainabilityLabelDisplay,
            classes[label] ?? classes.low,
            !isSignificant && classes.insignificant,
            className
          )}
        >
          {Icon && <Icon className={classes.Icon} />}
          {texts.label}
        </div>
      </Tooltip>
    )
  );
};
