import {Epic} from 'redux-observable';
import {action, createRequestEpic, remove, resetApp, upsert} from 'front-core';
import {AuthActionType, updateToken} from './auth.actions';
import {ActionKey} from '../../constants/action-key';
import {AUTH_STORE_KEY} from './auth.store';
import HttpClient from '../../services/http-client.service';
import {
  getAuthUserNetworkRequest,
  loginNetworkRequest,
  logoutNetworkRequest,
  mfaNetworkRequest,
  resendMFANetworkRequest,
} from '../../http/auth.network-requests';
import {AuthStatus} from '../../objects/models/auth.model';
import {afterLoginEpicHandler} from './auth.utils';
import {replace} from 'redux-first-history';

export const getAuthUserEpic: Epic = createRequestEpic(
  {
    types: [AuthActionType.GET_USER_REQUEST],
    actionKey: ActionKey.GET_AUTH_USER,
    request: getAuthUserNetworkRequest,
    onSuccess: user => [...afterLoginEpicHandler(user), action(AuthActionType.GET_USER_SUCCESS)],
    onError: err => [remove(AUTH_STORE_KEY, 'user'), action(AuthActionType.GET_USER_ERROR)],
  },
  HttpClient
);

export const loginEpic: Epic = createRequestEpic(
  {
    types: [AuthActionType.LOGIN_REQUEST],
    actionKey: ActionKey.LOGIN,
    request: loginNetworkRequest,
    onSuccess: res => {
      const actions = [
        upsert(AUTH_STORE_KEY, 'status', res.data.status),
        upsert(AUTH_STORE_KEY, 'mfaTokenId', res.data.tokenId),
        ...afterLoginEpicHandler(res.data.user),
      ];
      if (res.data.redirectTo) {
        actions.push(replace(res.data.redirectTo));
      }
      return actions;
    },
  },
  HttpClient
);

export const mfaEpic: Epic = createRequestEpic(
  {
    types: [AuthActionType.MFA_REQUEST],
    actionKey: ActionKey.LOGIN,
    request: mfaNetworkRequest,
    onSuccess: res => {
      const actions = [upsert(AUTH_STORE_KEY, 'status', res.data.status)];
      if (res.data.status === AuthStatus.SUCCESS) {
        actions.push(updateToken());
      }
      actions.push(...afterLoginEpicHandler(res.data.user));
      return actions;
    },
  },
  HttpClient
);

export const resendMFAEpic: Epic = createRequestEpic(
  {
    types: [AuthActionType.RESEND_MFA_REQUEST],
    actionKey: ActionKey.LOGIN,
    request: resendMFANetworkRequest,
    onSuccess: res => [
      upsert(AUTH_STORE_KEY, 'status', res.data.status),
      upsert(AUTH_STORE_KEY, 'mfaTokenId', res.data.tokenId),
    ],
  },
  HttpClient
);

export const logoutEpic: Epic = createRequestEpic(
  {
    types: [AuthActionType.LOGOUT_REQUEST],
    actionKey: ActionKey.LOGOUT,
    request: logoutNetworkRequest,
    onSuccess: res => [resetApp(), remove(AUTH_STORE_KEY, 'token'), replace('/login')],
  },
  HttpClient
);

export const moveToResetPassword: Epic = createRequestEpic(
  {
    types: [AuthActionType.MOVE_TO_RESET_PASSWORD],
    actionKey: ActionKey.MOVE_TO_RESET_PASSWORD,
    request: logoutNetworkRequest,
    onSuccess: res => [resetApp(), remove(AUTH_STORE_KEY, 'token'), replace('/forgot-password')],
  },
  HttpClient
);
