import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import moment from 'moment/moment';
import {
  startEndDatesValidator,
  TIME_UNIT_OPTIONS,
  treatmentValidator,
  validateSignalTimeframeBoundingFor,
} from '../shared-validators';
import {QUERY_GOAL_KEY} from '../analysis-101/analysis-101-form.component';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

import {querySchemaValidator} from '../../../../../objects/dto/query-builder.dto.ts';

export const analysis142ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal_query: querySchemaValidator(),
      ...treatmentValidator('features_tag'),
      max_window: yup.number().required(),
      max_window_unit: yup
        .string()
        .oneOf(TIME_UNIT_OPTIONS)
        .required()
        .test(
          'is_less_then_goal_query',
          "Devil's moment is the short-term behavior of the user that predicts churn. Therefore, devil's moment period shall ends before the churn bound.",
          function (max_window_unit: string) {
            const {goal_query, max_window} = this.parent;
            if (!goal_query) {
              return false;
            }
            const windowEndDate = moment().add(max_window, max_window_unit as any);
            const goalStartDate = moment().add(goal_query.parameters[1], goal_query.parameters[3]);
            return goalStartDate.isSameOrAfter(windowEndDate);
          }
        ),
      population_filter: querySchemaValidator(false),
    })
    .required(),
  runParameters: startEndDatesValidator.required().test(
    validateSignalTimeframeBoundingFor({
      signalQueryParameter: 'goal_query',
      parameterName: 'retention goal',
    })
  ),
});

export const analysis142ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};
  if (QUERY_GOAL_KEY in schema) {
    schema[QUERY_GOAL_KEY].name = 'Churn Criteria';
    schema[QUERY_GOAL_KEY].order = 1;
  }

  if ('max_window_unit' in schema) {
    schema['max_window_unit'] = {
      name: `Devil's moment period`,
      key: 'max_window_unit',
      type: ParameterType.STRING,
      order: 4,
    };
    newValues['max_window_unit'] = `${newValues.max_window} ${newValues.max_window_unit}s`;
    delete schema['max_window'];
  }

  delete schema['features_tag'];
  delete schema['treatments_signals'];
  schema['features_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'features_tag',
    order: 4,
  };
  newValues['features_tag'] = [
    ...(newValues['treatment_signal_slug'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  delete schema['reference_date'];

  return [newValues, schema];
};
