import * as React from 'react';
import {DocumentElementType, TipBoxFigure} from '../../../types';
import {TipBox} from '../../../../../simple/data-display/see-once-tip-box/see-once-tip-box.component';
import {MarkdownViewer} from '../markdown-viewer/markdown-viewer.component';

export interface OwnProps extends TipBoxFigure {
  className?: string;
}

export const TipBoxViewer: React.FC<OwnProps> = (props: OwnProps) => (
  <div className={props.className}>
    <TipBox
      dismissKey={props.data.key}
      content={
        <div style={{maxWidth: '70%'}}>
          <MarkdownViewer type={DocumentElementType.MARKDOWN} data={props.data.content} />
        </div>
      }
    />
  </div>
);

TipBoxViewer.defaultProps = {
  data: {
    content: '',
    key: '',
  },
};
