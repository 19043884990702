import classNames from 'classnames';
import classes from './rca-header.module.scss';
import {RCAFigureData, RCAFigureOptions, RCAMode} from '../../../../../../types';
import {useContext, useMemo} from 'react';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {withStopPropagation} from 'front-core';
import {RCATrendInfo} from './rca-trend-info.component';
import {calculatePeriodValueText, renderDates} from './rca-header.utils';
import {LoopsIconPurple} from '../../../../../../../../simple/controls/icons/icons.component';
import {useTranslation} from '../../../../../../../../../core/translations/use-translation';
import TransKeys from 'translations';
import {RCAFigureViewerContext} from '../../rca-figure-viewer.context';

interface RCAHeaderProps {
  data: RCAFigureData;
  options: RCAFigureOptions;
  onChangeMode?: (mode: RCAMode) => void;
  disabled?: boolean;
  renderHeaderName?: boolean;
  className?: string;
  compact?: boolean;
  // soon will be deprecated
  useThreshold?: boolean;
}

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_FIGURE;

export const RCAHeader = (props: RCAHeaderProps) => {
  const {
    data,
    options,
    onChangeMode,
    renderHeaderName,
    disabled,
    useThreshold,
    className,
    compact,
  } = props;
  const {metric, refDate, group} = data;
  const {t} = useTranslation();
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const {mode, rcaPeriods} = useContext(RCAFigureViewerContext);

  const {
    comparedDates,
    current: currentTrendData,
    compared: comparedTrendData,
    expected: expectedTrendData,
  } = rcaPeriods;

  // withStopPropagation returns incorrect type
  const onDateClick = withStopPropagation(() => onSignalClick(refDate?.signalId)) as () => void;
  const refDateName = refDate?.name;

  const labels = useMemo(() => {
    if (!compact) {
      return {
        current: currentTrendData.label,
        expected: expectedTrendData.label,
        compared: comparedTrendData.label,
      };
    }
    return {
      current: currentTrendData.label,
      expected: (
        <>
          <span className={classes.Bold}>
            {t(TransKeysPrefix.HEADER.COMPACT.LABELS.COMPARE_TO)}
          </span>
          <LoopsIconPurple />
          <span>{expectedTrendData.label}</span>
        </>
      ),
      compared: (
        <>
          <span className={classes.Bold}>
            {t(TransKeysPrefix.HEADER.COMPACT.LABELS.COMPARE_TO)}{' '}
          </span>
          <span>
            {renderDates({
              startDate: comparedDates.startDate,
              endDate: comparedDates.endDate,
            })}
          </span>
        </>
      ),
    };
  }, [compact, comparedDates, currentTrendData, comparedTrendData, expectedTrendData, t]);

  return (
    <div className={classNames(classes.RCAHeader, compact && classes.Compact, className)}>
      {!compact && renderHeaderName && (
        <div className={classes.TitleWrapper}>
          <div className={classes.Title}>
            <span onClick={() => onSignalClick(metric.signalId)} className={classes.MetricName}>
              {metric.name}
            </span>
            {group && (
              <>
                <span className={classes.In}>in</span>
                <span onClick={() => onSignalClick(group.signalId)} className={classes.GroupName}>
                  {group.name}
                </span>
              </>
            )}
          </div>
          <div className={classes.Action}></div>
        </div>
      )}
      <div className={classes.Options}>
        {!compact && (
          <RCATrendInfo
            startDate={currentTrendData.startDate}
            endDate={currentTrendData.endDate}
            refDateName={refDateName}
            isSelected
            shouldUseThreshold={useThreshold}
            periodValueText={calculatePeriodValueText(
              currentTrendData.value,
              options.isPercentageValue
            )}
            higherIsBetter={options.higherIsBetter}
            isDisabled={disabled}
            isCompact={compact}
            label={labels.current}
            onDateClick={onDateClick}
          />
        )}
        <div className={classes.Merged}>
          <RCATrendInfo
            startDate={expectedTrendData.startDate}
            endDate={expectedTrendData.endDate}
            refDateName={refDateName}
            isSelected={mode === RCAMode.LOOPS_ALGO}
            onSelect={() => onChangeMode(RCAMode.LOOPS_ALGO)}
            shouldUseThreshold={useThreshold}
            uplift={expectedTrendData.uplift}
            significantTrendText={expectedTrendData.significantText}
            isSignificantTrend={expectedTrendData.isSignificant}
            shouldShowCheckbox
            periodValueText={calculatePeriodValueText(
              expectedTrendData.value,
              options.isPercentageValue
            )}
            higherIsBetter={options.higherIsBetter}
            isDisabled={disabled}
            isCompact={compact}
            label={labels.expected}
            helperText={expectedTrendData.helperText}
            // withStopPropagation returns incorrect type
            onDateClick={onDateClick}
          />
          <RCATrendInfo
            isSignificantTrend={comparedTrendData.isSignificant}
            refDateName={refDateName}
            startDate={comparedTrendData.startDate}
            endDate={comparedTrendData.endDate}
            shouldShowCheckbox
            significantTrendText={comparedTrendData.significantText}
            uplift={comparedTrendData.uplift}
            higherIsBetter={options.higherIsBetter}
            periodValueText={calculatePeriodValueText(
              comparedTrendData.value,
              options.isPercentageValue
            )}
            isSelected={mode === RCAMode.COMPARE_TO_DATE}
            onSelect={() => onChangeMode(RCAMode.COMPARE_TO_DATE)}
            shouldUseThreshold={useThreshold}
            isDisabled={disabled}
            isCompact={compact}
            label={labels.compared}
            // withStopPropagation returns incorrect type
            onDateClick={onDateClick}
          />
        </div>
      </div>
    </div>
  );
};
