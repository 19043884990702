import {TableColumn} from '../../../../objects/models/table-column.model.ts';
import {TableEvent} from '../../../../objects/models/table-event.model.ts';
import {Segment} from '../../../../objects/models/segment.model.ts';
import {exists} from 'front-core';
import {startCase, toLower} from 'lodash';
import {
  createUserPropertyColumnInitialQuery,
  USER_PROPERTY_COLUMN_QUERY_BUILDER_NAME,
} from '../../../shared/core/query-builders/user-property-column-query-builder/user-property-column-query-builder.component.tsx';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../constants/parameters-saved-keys.ts';
import {
  BEHAVIORAL_FIRST_OR_LAST_VALUE_QUERY_BUILDER_NAME,
  createInitialBehavioralFirstOrLastValueQuery,
} from '../../../shared/core/query-builders/behavioral-first-or-last-value-query-builder/behavioral-first-or-last-value-query-builder.component.tsx';
import {SignalType} from '../../../../objects/models/signal.model.ts';
import {getComponentForQuery} from '../../../shared/core/query-builders/query-builders.utils.ts';
import {USER_PROPERTY_CONDITIONS_QUERY_BUILDER_NAME} from '../../../shared/core/query-builders/user-property-conditions-query-builder/user-property-conditions-query-builder.component.tsx';
import {SIGNAL_QUERY_BUILDER_NAME} from '../../../shared/core/query-builders/signal-builder/signal-query-builder.component.tsx';

import {
  COLUMN_TYPE_TO_SIGNAL_DATA_TYPE_MAP,
  SegmentTabOption,
} from './segment-form-panel.consts.ts';

export const createInitialSegment = (params: {
  tableColumn?: TableColumn;
  tableEvent?: TableEvent;
}): Partial<Segment> => {
  const {tableColumn, tableEvent} = params;
  let name = '';
  let signalDefinition = null;
  let signalDataType = null;
  let entity = null;

  if (!exists(tableColumn) && !exists(tableEvent)) {
    return {};
  }

  if (exists(tableColumn)) {
    name = startCase(toLower(tableColumn.name));
    signalDefinition = createUserPropertyColumnInitialQuery(tableColumn);
    signalDataType =
      COLUMN_TYPE_TO_SIGNAL_DATA_TYPE_MAP[
        signalDefinition[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.TABLE_COLUMN_TYPE_KEY]
      ];
    entity = tableColumn.tableEntity;
  }

  if (exists(tableEvent)) {
    name = startCase(toLower(tableEvent.name));
    signalDefinition = createInitialBehavioralFirstOrLastValueQuery(tableEvent);
    entity = tableEvent.tableEntity;
  }

  return {
    name,
    signalDefinition,
    signalDataType,
    entity,
    shortDescription: '',
    signalType: SignalType.DIMENSION,
  };
};

export const isCustomQueryBuilder = query => {
  if (!query) {
    return false;
  }
  return getComponentForQuery(query) === undefined;
};

export const getInitialTabFromSignalDefinition = query => {
  const builder = query?.[PARAMETERS_METADATA_KEY]?.[METADATA_KEY.BUILDER_COMPONENT_NAME_KEY];
  if (builder === USER_PROPERTY_CONDITIONS_QUERY_BUILDER_NAME) {
    return SegmentTabOption.CASES_CONDITION;
  } else if (builder === BEHAVIORAL_FIRST_OR_LAST_VALUE_QUERY_BUILDER_NAME) {
    return SegmentTabOption.FIRST_OR_LAST_EVENT_VALUE;
  } else if (builder === USER_PROPERTY_COLUMN_QUERY_BUILDER_NAME) {
    return SegmentTabOption.COLUMN_VALUE;
  } else if (builder === SIGNAL_QUERY_BUILDER_NAME) {
    return SegmentTabOption.KPI_ACHIEVEMENT;
  }
  // default
  return SegmentTabOption.COLUMN_VALUE;
};
