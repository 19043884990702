import * as React from 'react';
import classNames from 'classnames';
import {useCallback, useMemo} from 'react';
import {SentencePart} from './sentence-part.component';
import classes from './sentence.module.scss';
import {DEFAULT_DELIMITER, parseSentence} from './sentence.utils';

export interface SentencePartParameters {
  name: string;
  type: string;
  parameters: Array<string | Array<string>>;
}

export interface SentenceProps {
  text: string;
  onClick?: (part: SentencePartParameters) => void;
  className?: string;
  partClassName?: string;
  delimiter?: string;
  size?: 'small' | 'medium' | 'large';
  textCenter?: boolean;
}

type AllProps = SentenceProps;

export const Sentence: React.FC<AllProps> = (props: AllProps) => {
  const {text, delimiter, size, textCenter, onClick, className, partClassName} = props;
  const parts = useMemo(() => parseSentence(text, delimiter), [text, delimiter]);
  const onCopy = useCallback(e => {
    const selection = document.getSelection();
    e.clipboardData.setData('text/plain', selection.toString().replace(/\n/g, ' '));
    e.preventDefault();
  }, []);

  return (
    <div
      onCopy={onCopy}
      className={classNames(classes.Sentence, textCenter && classes.TextCenter, className)}
    >
      {parts.map((p, idx) => (
        <SentencePart
          {...p}
          className={partClassName}
          key={idx}
          size={size}
          onClick={onClick ? () => onClick(p) : undefined}
        />
      ))}
    </div>
  );
};

Sentence.defaultProps = {
  delimiter: DEFAULT_DELIMITER,
  text: '',
};
