import * as React from 'react';
import classNames from 'classnames';
import {LabelValueColumnOptions} from '../../../../../types';
import classes from './data-columns.module.scss';
import {useContext, useMemo} from 'react';
import {get, isArray} from 'lodash';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists, withStopPropagation} from 'front-core';
import {getDataValueKey} from '../../smart-table.utils';
import {DataColumnProps} from '../../smart-table.types';
import {TooltipIfOverflow} from '../../../../../../../simple/generic/tooltips/tooltips.component';
import {SmartTableContext} from '../../smart-table.context';
import {ArrowRightLongLightIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';

interface OwnProps extends DataColumnProps<LabelValueColumnOptions> {}

type AllProps = OwnProps;

const LabelValueDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;
  const {dataKey} = useContext(SmartTableContext);
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {labelDataKey, commandDataKey} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);
  const label = useMemo(() => get(data, labelDataKey), [data, labelDataKey]);
  const value = useMemo(() => get(data, valueDataKey), [data, valueDataKey]);
  const command = useMemo(
    () => (commandDataKey ? get(data, commandDataKey) : undefined),
    [data, commandDataKey]
  );
  const isList = useMemo(
    () => isArray(label) && isArray(value) && label.length === value.length,
    [label, value]
  );

  return (
    <div className={classNames(classes.LabelValueDataColumn, className)} key={data[dataKey]}>
      {isList && (
        <>
          {value.map((v, idx) => (
            <React.Fragment key={idx}>
              <div
                onClick={
                  get(command, idx) ? withStopPropagation(() => emitEvent(command[idx])) : undefined
                }
                className={classNames(classes.LabelValue, get(command, idx) && classes.Clickable)}
              >
                {get(label, idx).toString() && (
                  <>
                    <TooltipIfOverflow title={get(label, idx).toString()}>
                      <div className={classes.Label}>{get(label, idx).toString()}</div>
                    </TooltipIfOverflow>
                    <div className={classes.Separator}>
                      <ArrowRightLongLightIcon className={classes.Icon} />
                    </div>
                  </>
                )}
                <TooltipIfOverflow title={`${get(value, idx).toString()}`}>
                  <div className={classes.Value}>{get(value, idx).toString()}</div>
                </TooltipIfOverflow>
              </div>
              <div className={classes.Plus}>and</div>
            </React.Fragment>
          ))}
        </>
      )}
      {!isList && (
        <div
          onClick={command ? withStopPropagation(() => emitEvent(command)) : undefined}
          className={classNames(classes.LabelValue, command && classes.Clickable)}
        >
          {label && (
            <>
              <TooltipIfOverflow title={label}>
                <div className={classes.Label}>{label}</div>
              </TooltipIfOverflow>
              <div className={classes.Separator}>
                <ArrowRightLongLightIcon className={classes.Icon} />
              </div>
            </>
          )}
          <TooltipIfOverflow title={value.toString() || column.options.placeholderText || '--'}>
            <div className={classes.Value}>
              {value.toString() || column.options.placeholderText || '--'}
            </div>
          </TooltipIfOverflow>
        </div>
      )}
    </div>
  );
};

const protector: Protector = (props: OwnProps) => {
  const {column, data} = props;
  const {labelDataKey} = column.typeOptions;
  const valueDataKey = getDataValueKey(column, 'valueDataKey');
  const value = get(data, valueDataKey);
  const label = get(data, labelDataKey);
  return exists(value) || exists(label);
};

export const LabelValueDataColumn = withDataColumnProtector(protector)(
  LabelValueDataColumnComponent
);
