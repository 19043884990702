import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {startEndDatesValidator} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

import {querySchemaValidator} from '../../../../../objects/dto/query-builder.dto.ts';

export const analysis59ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      power_signal: yup.number().required(),
      power_percentile: yup.number().min(0).max(1).required(),
      active_signal: yup.number().nullable(),
      population_filter: querySchemaValidator(false),
    })
    .required(),
  runParameters: startEndDatesValidator.required().test({
    name: 'min_running_days_30',
    test: function (obj: any) {
      const startDate = moment(obj.start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
      const endDate = moment(obj.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
      if (endDate.diff(startDate, 'd') < 30) {
        return this.createError({
          message: 'Time frame must be at least 30 days for stable results.',
          path: 'runParameters.start_date',
        });
      }
      return true;
    },
  }),
});

export const analysis59ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('power_percentile' in schema) {
    schema['power_percentile'].type = ParameterType.STRING;
    newValues['power_percentile'] = `${newValues['power_percentile'] * 100}%`;
  }
  if ('power_signal' in schema) {
    schema['power_signal'].name = 'Power KPI';
  }
  if ('active_signal' in schema) {
    schema['active_signal'].name = 'Active KPI';
  }

  return [newValues, schema];
};
