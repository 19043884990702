import {
  RCAInsightSegmentSentenceParts,
  RCAInsightSentence,
  SpikeDropOverUnderPerformingIncreasedDecreasedKPI,
} from '../rca-insights.model.ts';
import {
  ChangeInValueSentencePart,
  SegmentNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: SpikeDropOverUnderPerformingIncreasedDecreasedKPI;
  sentenceParts: RCAInsightSegmentSentenceParts;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {pastTensePerformanceVsXox} = formattedSentenceParts;
  const {segmentViewData, changeInValue, higherIsBetter} = sentenceParts;

  return (
    <SentenceContainer>
      <SegmentNameSentencePart data={segmentViewData} />
      <SentencePart text={`${pastTensePerformanceVsXox} vs the analyzed period by`} />
      <ChangeInValueSentencePart changeInValue={changeInValue} higherIsBetter={higherIsBetter} />
    </SentenceContainer>
  );
};
export {SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter};
