import {exists, safeDivision} from 'front-core';
import {
  AnalysisAlgoMode,
  ConfusionMatrix,
  ConfusionMatrixIndex,
  HabitMomentFigureItem,
  HabitMomentFigureItemCausationType,
  HabitMomentFigureItemInstance,
  HabitMomentFigureItemInstanceType,
} from '../../../../types';
import {max, maxBy, min, minBy, sum} from 'lodash';
import {
  ExtendedHabitMomentFigureItem,
  ExtendedHabitMomentFigureItemInstance,
  HabitMomentViewMode,
  InstancesCountByMode,
} from './habit-moment-viewer.component';
import {calculateMaxPotential, SimulatorItemType} from '../../../../hooks/use-simulator.hook';

export const calcPerformanceMetricsForConfusionMatrix = (confusionMatrix: ConfusionMatrix) => {
  const accuracy = safeDivision(
    confusionMatrix[ConfusionMatrixIndex.TRUE_POSITIVE] +
      confusionMatrix[ConfusionMatrixIndex.TRUE_NEGATIVE],
    sum(confusionMatrix)
  );
  const precision = safeDivision(
    confusionMatrix[ConfusionMatrixIndex.TRUE_POSITIVE],
    confusionMatrix[ConfusionMatrixIndex.TRUE_POSITIVE] +
      confusionMatrix[ConfusionMatrixIndex.FALSE_POSITIVE]
  );
  const recall = safeDivision(
    confusionMatrix[ConfusionMatrixIndex.TRUE_POSITIVE],
    confusionMatrix[ConfusionMatrixIndex.TRUE_POSITIVE] +
      confusionMatrix[ConfusionMatrixIndex.FALSE_NEGATIVE]
  );
  const f1Score = safeDivision(2 * precision * recall, precision + recall);

  return {
    accuracy,
    precision,
    recall,
    f1Score,
  };
};

export const filterInstanceByMode = (
  instance: HabitMomentFigureItemInstance,
  mode: HabitMomentViewMode
) => {
  return mode === HabitMomentViewMode.CAUSATION
    ? exists(instance.causationType)
    : exists(instance.correlation);
};

export const countRelevantInstances = (
  item: HabitMomentFigureItem,
  type: HabitMomentFigureItemInstanceType,
  mode: HabitMomentViewMode,
  requirePositiveCasual: boolean = false
) =>
  item.instances[type].filter(instance => {
    const isRelevant = filterInstanceByMode(instance, mode);
    if (requirePositiveCasual && mode === HabitMomentViewMode.CAUSATION) {
      return isRelevant && instance.causationType === HabitMomentFigureItemCausationType.POSITIVE;
    }
    return isRelevant;
  }).length;

export const countRelevantItems = (
  data: HabitMomentFigureItem[],
  type: HabitMomentFigureItemInstanceType,
  mode: HabitMomentViewMode,
  requirePositiveCasual: boolean = false
) => {
  return data.filter(ei => countRelevantInstances(ei, type, mode, requirePositiveCasual) > 0)
    .length;
};

export const extendFigureItemInstance = (
  items: HabitMomentFigureItemInstance[],
  goalValue: number,
  totalUsers: number,
  mode: AnalysisAlgoMode
): ExtendedHabitMomentFigureItemInstance[] => {
  return items.map((i, idx) => {
    const addition: any = {
      key: `v_${idx}_${i.repeats}_${i.timeframeDays}`.replace('.', '_'),
      uplift: safeDivision(i.goalInAdopters - i.goalInNonAdopters, Math.abs(i.goalInNonAdopters)),
      adoptionAbs: Math.round(i.adoptionRate * totalUsers),
    };

    let maxPotential = {};
    if (mode === AnalysisAlgoMode.CAUSATION) {
      maxPotential = exists(i.potentialLift)
        ? calculateMaxPotential(goalValue, {
            value: i.adoptionRate,
            potentialLift: i.potentialLift,
            potentialLiftUpper: i.potentialLiftUpper,
            potentialLiftLower: i.potentialLiftLower,
            simulationType: SimulatorItemType.POSITIVE,
          })
        : {};
    }
    if (i.causationType === HabitMomentFigureItemCausationType.INSIGNIFICANT_POSITIVE) {
      i.causationType = HabitMomentFigureItemCausationType.INSIGNIFICANT;
    }

    return {
      ...i,
      ...addition,
      ...maxPotential,
    };
  });
};

export const extendHabitMomentFigureItems = (
  items: HabitMomentFigureItem[],
  totalUsers: number,
  goalValue: number,
  mode: AnalysisAlgoMode
): ExtendedHabitMomentFigureItem[] => {
  return items.map(i => {
    const extendedInstances = {};
    const upliftRange = {};
    const correlationRange = {};
    const maxPotentialRange = {};
    for (const k in i.instances) {
      const actualTotalUsers = exists(i.totalUsers) ? i.totalUsers : totalUsers;
      extendedInstances[k] = extendFigureItemInstance(
        i.instances[k],
        goalValue,
        actualTotalUsers,
        mode
      );
      const uplifts = extendedInstances[k].filter(i => i.uplift).map(i => i.uplift);
      upliftRange[k] = [min(uplifts), max(uplifts)];
      const correlations = extendedInstances[k].filter(i => i.correlation).map(i => i.correlation);
      correlationRange[k] = [min(correlations), max(correlations)];
      const maxPotentials = extendedInstances[k]
        .filter(i => i.maxPotential)
        .map(i => i.maxPotential);
      maxPotentialRange[k] = [min(maxPotentials), max(maxPotentials)];
    }

    const instancesCountByMode: InstancesCountByMode = {
      [HabitMomentFigureItemInstanceType.REPEATED_ACTIONS]: {
        [HabitMomentViewMode.UPLIFT]: countRelevantInstances(
          i,
          HabitMomentFigureItemInstanceType.REPEATED_ACTIONS,
          HabitMomentViewMode.UPLIFT
        ),
        [HabitMomentViewMode.CORRELATION]: countRelevantInstances(
          i,
          HabitMomentFigureItemInstanceType.REPEATED_ACTIONS,
          HabitMomentViewMode.CORRELATION
        ),
        [HabitMomentViewMode.CAUSATION]: countRelevantInstances(
          i,
          HabitMomentFigureItemInstanceType.REPEATED_ACTIONS,
          HabitMomentViewMode.CAUSATION,
          true
        ),
      },
      [HabitMomentFigureItemInstanceType.REPEATED_DAYS]: {
        [HabitMomentViewMode.UPLIFT]: countRelevantInstances(
          i,
          HabitMomentFigureItemInstanceType.REPEATED_DAYS,
          HabitMomentViewMode.UPLIFT
        ),
        [HabitMomentViewMode.CORRELATION]: countRelevantInstances(
          i,
          HabitMomentFigureItemInstanceType.REPEATED_DAYS,
          HabitMomentViewMode.CORRELATION
        ),
        [HabitMomentViewMode.CAUSATION]: countRelevantInstances(
          i,
          HabitMomentFigureItemInstanceType.REPEATED_DAYS,
          HabitMomentViewMode.CAUSATION,
          true
        ),
      },
    };

    return {
      ...i,
      instances: extendedInstances,
      upliftRange,
      correlationRange,
      maxPotentialRange,
      instancesCountByMode,
    };
  });
};

export const instanceScoreFunction = (
  i: ExtendedHabitMomentFigureItemInstance,
  mode: HabitMomentViewMode
) => {
  switch (mode) {
    case HabitMomentViewMode.CAUSATION:
      return i.maxPotential;
    case HabitMomentViewMode.UPLIFT:
      return i.uplift;
    case HabitMomentViewMode.CORRELATION:
      return i.correlation;
  }
};

export function getDefaultViewMode(mode: AnalysisAlgoMode) {
  if (mode === AnalysisAlgoMode.CAUSATION) {
    return HabitMomentViewMode.CAUSATION;
  }
  return HabitMomentViewMode.UPLIFT;
}

export function getHabitOrderByMode(mode: HabitMomentViewMode) {
  switch (mode) {
    case HabitMomentViewMode.CAUSATION:
      return 'maxPotential';
    case HabitMomentViewMode.CORRELATION:
      return 'correlation';
    case HabitMomentViewMode.UPLIFT:
      return 'uplift';
  }
}

export const instanceFilterByMode = (
  item: HabitMomentFigureItemInstance,
  mode: HabitMomentViewMode
) => {
  return mode === HabitMomentViewMode.CAUSATION
    ? item.causationType === HabitMomentFigureItemCausationType.POSITIVE
    : item.isSignificant !== false && exists(item.correlation);
};

export const getBestInstance = (
  instances: ExtendedHabitMomentFigureItemInstance[],
  selectedMode: HabitMomentViewMode,
  higherIsBetter: boolean = true
): ExtendedHabitMomentFigureItemInstance => {
  const selectedBestInstance = instances.find(i =>
    selectedMode === HabitMomentViewMode.CAUSATION ? i.isBestInstanceCausation : i.isBestInstance
  );
  if (selectedBestInstance) {
    return selectedBestInstance;
  }
  const maxInstanceFunc = higherIsBetter ? maxBy : minBy;
  return maxInstanceFunc(instances, (i: ExtendedHabitMomentFigureItemInstance) =>
    instanceScoreFunction(i, selectedMode)
  );
};
