import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {get, values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {treatmentValidator} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';
import {exists} from 'front-core';

import {querySchemaValidator} from '../../../../../objects/dto/query-builder.dto.ts';

export const analysis109ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal: yup
        .number()
        .required()
        .test(
          'validate_kpis_not_exists_in_features',
          'KPI cannot be a released feature',
          function (value, context) {
            const {features} = context.parent;
            return (features || []).indexOf(value) === -1;
          }
        ),
      secondary_goal: yup
        .array()
        .of(
          yup
            .number()
            .required()
            .test(
              'validate_kpis_not_exists_in_features',
              'KPI cannot be a released feature',
              function (value, context) {
                const {features} = get(context, 'from.0.value') as any;
                return (features || []).indexOf(value) === -1;
              }
            )
        )
        .nullable(),
      population_filter: querySchemaValidator(false),
      release_date: yup.string().required(),
      ...treatmentValidator('feature_usage_tag'),
      features: yup.array().of(yup.number()).nullable(),
      additional_event_dates: yup.array().of(yup.string()).nullable(),
      // measurement timeline
      days_before: yup.number().min(1),
      max_days_from_release: yup.number().min(1).nullable(),
      pre_release_date: yup.string().nullable(),
      post_release_date: yup.string().nullable(),
      experiment_threshold: yup.number().min(0).max(1).required(),
      gradual_release_query: querySchemaValidator(false),
      gradual_versions: yup.array().when('gradual_release_query', {
        is: value => exists(value),
        then: yup
          .array()
          .of(
            yup
              .mixed()
              .test(
                'is-string-or-number',
                'The field must be either a string or a number',
                value => typeof value === 'string' || typeof value === 'number'
              )
          )
          .min(2)
          .max(2)
          .required(),
        otherwise: yup.array().nullable(),
      }),
    })
    .required(),
  runParameters: yup
    .object()
    .shape({
      end_date: yup.string().nullable(),
    })
    .test({
      name: 'end_date_after_release_date',
      test: function (obj: any, context: any) {
        const {release_date} = context.parent.parameters;
        if (!release_date) {
          return true;
        }
        if (!obj.end_date) {
          return true;
        }
        const startDate = moment(release_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        const endDate = moment(obj.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        if (startDate.isSameOrAfter(endDate)) {
          return this.createError({
            message: 'Release date must be before end date',
            path: 'parameters.release_date',
          });
        }
        return true;
      },
    }),
});

export const analysis109ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('release_date' in schema) {
    schema['release_date'].order = 1;
    schema['release_date'].name = 'Release Date';
  }
  if ('goal' in schema) {
    schema['goal'].order = 2;
    schema['goal'].name = 'Primary KPI';
  }
  if ('secondary_goal' in schema) {
    schema['secondary_goal'].order = 3;
    schema['secondary_goal'].name = 'Secondary KPIs';
  }
  delete schema['feature_usage_tag'];
  delete schema['treatments_signals'];
  schema['feature_usage_tag'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'feature_usage_tag',
    order: 4,
  };
  newValues['feature_usage_tag'] = [
    ...(newValues['feature_usage_tag'] || []),
    ...(newValues['treatments_signals'] || []),
  ];
  if ('experiment_threshold' in schema) {
    schema['experiment_threshold'].order = 5;
    schema['experiment_threshold'].name = 'Statistical significance';
    schema['experiment_threshold'].type = ParameterType.STRING;
    newValues['experiment_threshold'] = `${newValues['experiment_threshold'] * 100}%`;
  }
  if ('population_filter' in schema) {
    schema['population_filter'].name = 'Experiment population';
    schema['population_filter'].order = 6;
  }
  if ('feature_usage_tag' in schema) {
    schema['feature_usage_tag'].order = 7;
  }
  if ('days_before' in schema) {
    schema['days_before'].order = 10;
    schema['days_before'].name = 'Pre-release period';
    schema['days_before'].type = ParameterType.STRING;
    values['days_before'] = `${values['days_before']} days`;
  }
  if ('max_days_from_release' in schema) {
    schema['max_days_from_release'].order = 11;
    schema['max_days_from_release'].name = 'Post-release period';
    schema['max_days_from_release'].type = ParameterType.STRING;
    values['max_days_from_release'] = `${values['days_before']} days`;
  }
  if ('pre_release_date' in schema) {
    schema['pre_release_date'].order = 10;
    schema['pre_release_date'].name = 'Pre-release date';
    delete schema['days_before'];
  }
  if ('post_release_date' in schema) {
    schema['post_release_date'].order = 11;
    schema['post_release_date'].name = 'Post-release date';
    delete schema['max_days_from_release'];
  }
  if ('features' in schema) {
    schema['features'].order = 12;
    schema['features'].name = 'Released features';
  }
  if ('gradual_release_query' in newValues) {
    schema['gradual_release_query'].name = 'Gradual release';
    schema['gradual_release_query'].type = ParameterType.STRING;
    schema['gradual_release_query'].order = 8;
    const query = newValues['gradual_release_query'];
    // const eventName = get(query, `cases.0.0.conditions.0.${PARAMETERS_METADATA_KEY}.${METADATA_KEY.DISPLAY_NAME_KEY}`);
    const columnName = get(query, `cases.0.1.column`);
    newValues['gradual_release_query'] =
      `${newValues['gradual_versions'][0]} → ${newValues['gradual_versions'][1]} (${columnName})`;
    delete schema['gradual_versions'];
    // also delete pre-post release parameters
    delete schema['pre_release_date'];
    delete schema['post_release_date'];
    delete schema['days_before'];
    delete schema['max_days_from_release'];
  }
  if ('measurement_timeline_mode' in schema) {
    delete schema['measurement_timeline_mode'];
  }
  if ('additional_event_dates' in schema) {
    schema['additional_event_dates'].name = 'Other important dates';
  }

  return [newValues, schema];
};
