import {SIGNAL_ID_PATH_PARAM, WORKFLOW_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useContext, useState} from 'react';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {Workflow} from '../../../../objects/models/workflow.model';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {ActionKey} from '../../../../constants/action-key';
import {PanelKey} from '../../../../constants/panels';
import classes from './workflow-form-panel.module.scss';
import {
  ArrowProgressRegularIcon,
  FancyHeader,
  ModalLayout,
  PrimaryTabs,
  WizardStepper,
} from 'ui-components';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {getWorkflowNetworkRequest} from '../../../../http/workflows.network-requests';
import {WorkflowTypeSelectionTab} from './components/workflow-type-selection-tab/workflow-type-selection-tab.component';
import {WorkflowParametersTab} from './components/workflow-parameters-tab/workflow-parameters-tab.component';
import {createWorkflow, editWorkflow} from '../../../../store/workflows/workflows.actions';
import {WorkflowOutputsTab} from './components/workflow-outputs-tab/workflow-outputs-tab.component';
import {getSlackChannelsNetworkRequest} from '../../../../http/slack-channels.network-requests';
import {SlackChannel} from '../../../analyses/panels/share-resource-form-panel/components/share-direct-slack-form-fields/share-direct-slack-form-fields.component';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import TransKeys from '../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {WorkflowDTO} from '../../../../objects/dto/workflow.dto.ts';

interface OwnProps {
  workflow?: Workflow;
  slackChannels: SlackChannel[];
  onClose?: () => void;
  data?: WorkflowDTO;
  [WORKFLOW_ID_PATH_PARAM]?: number;
  cloneMode?: boolean;
}

type AllProps = OwnProps;

const WIZARD_LABELS = [
  {
    label: 'Select Workflow Type',
    value: 'SELECT_TYPE',
  },
  {
    label: 'Set Parameters',
    value: 'SET_PARAMETERS',
  },
  {
    label: 'Set Outputs',
    value: 'SET_OUTPUTS',
  },
];

const WORKFLOW_KEY = SharedSelectionKeys.WORKFLOW_FORM__WORKFLOW;
const SLACK_CHANNELS_KEY = SharedSelectionKeys.WORKFLOW_FORM__SLACK_CHANNELS;

export const WorkflowFormPanelComponent = (props: AllProps) => {
  const {workflow = {} as Workflow, data = {}, slackChannels, cloneMode, onClose} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {isDemoProduct} = useDemoProduct();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const [activeIndex, setActiveIndex] = useState(workflow.id || data.type ? 1 : 0);
  const [formData, setFormData] = useState<any>({
    ...data,
    ...workflow,
    id: cloneMode ? undefined : workflow.id,
    analysisId: undefined,
  });
  const editMode = formData.id;
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.CREATE_WORKFLOW)(state)
  );
  const onSignalInfo = useCallback(
    signalId =>
      openSecondaryPanel(PanelKey.SIGNAL_DEFINITION_PANEL, {
        [SIGNAL_ID_PATH_PARAM]: signalId,
      }),
    [openSecondaryPanel]
  );
  const onWizardStepClick = useCallback(
    (value: string) => {
      const labelIndex = WIZARD_LABELS.findIndex(l => l.value === value);
      setActiveIndex(labelIndex || 0);
    },
    [setActiveIndex]
  );
  const onSubmit = useCallback(
    data => {
      const action = editMode ? editWorkflow : createWorkflow;
      dispatch(action(data, () => onClose()));
    },
    [dispatch, onClose, editMode]
  );

  return (
    <div className={classes.WorkflowFormContainer}>
      <ModalLayout>
        {isLoading && <GenericLoading />}
        <div className={classes.WorkflowForm}>
          <FancyHeader
            title={t(
              editMode
                ? TransKeys.WORKFLOW_FORM_PANEL.EDIT_TITLE
                : TransKeys.WORKFLOW_FORM_PANEL.CREATE_TITLE
            )}
            icon={ArrowProgressRegularIcon}
            onClose={onClose}
            className={classes.Header}
          />
          <div className={classes.WizardSteps}>
            <WizardStepper
              className={classes.FullWidth}
              activeValue={WIZARD_LABELS[activeIndex]?.value}
              lastCompletedValue={
                activeIndex > 0 ? WIZARD_LABELS[activeIndex - 1]?.value : undefined
              }
              labels={WIZARD_LABELS}
              onClick={onWizardStepClick}
            />
          </div>
          {isLoading && <GenericLoading />}
          <PrimaryTabs
            className={classes.Tabs}
            selected={WIZARD_LABELS[activeIndex].value}
            hideTabsButtons
            tabs={[
              {
                key: WIZARD_LABELS[0].value,
                render: () => (
                  <WorkflowTypeSelectionTab
                    onSubmit={data => {
                      setFormData({type: data.type});
                      onWizardStepClick(WIZARD_LABELS[1].value);
                    }}
                    data={formData}
                  />
                ),
              },
              {
                key: WIZARD_LABELS[1].value,
                render: () => (
                  <WorkflowParametersTab
                    onBack={() => setActiveIndex(ai => ai - 1)}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onWizardStepClick(WIZARD_LABELS[2].value);
                    }}
                    data={formData}
                    onSignalInfo={onSignalInfo}
                  />
                ),
              },
              {
                key: WIZARD_LABELS[2].value,
                render: () => (
                  <WorkflowOutputsTab
                    onBack={() => setActiveIndex(ai => ai - 1)}
                    data={formData}
                    slackChannels={slackChannels}
                    disabled={isDemoProduct}
                    onSubmit={data => {
                      setFormData(formData => ({...formData, ...data}));
                      onSubmit({...formData, ...data});
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalLayout>
    </div>
  );
};

const WorkflowFormPanel = composition<AllProps>(
  WorkflowFormPanelComponent,
  withModalInactiveSourceHandler,
  withLoadBefore({
    workflow: {
      selectedKey: WORKFLOW_KEY,
      actionKey: WORKFLOW_KEY,
      request: getWorkflowNetworkRequest,
      mapPayloadFromProps: props => props[WORKFLOW_ID_PATH_PARAM],
      shouldCall: props => props[WORKFLOW_ID_PATH_PARAM] !== undefined,
    },
    slackChannels: {
      selectedKey: SLACK_CHANNELS_KEY,
      actionKey: SLACK_CHANNELS_KEY,
      request: getSlackChannelsNetworkRequest,
    },
  })
);

export default WorkflowFormPanel;
