import * as React from 'react';
import classes from './markdown-viewer.module.scss';
import {CSSProperties, useCallback, useContext, useLayoutEffect, useMemo, useRef} from 'react';
import {MarkdownFigure, DocumentCommand} from '../../../types';
import marked from '../../../../../../config/marked.config';
import {sanitize} from 'front-core';
import {ChildRendererInjectedProps} from '../../../document-viewer.types';
import classNames from 'classnames';
import {DocumentCommandEmitterContext} from '../../../contexts/document-command-emitter.context';
import {DocumentTitleWrapper} from '../title-section-viewer/title-section-viewer.component';

export interface OwnProps extends MarkdownFigure, Partial<ChildRendererInjectedProps> {
  style?: CSSProperties;
  className?: string;
}

export const MarkdownViewerClasses = classes;

export const MarkdownViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {className} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const rendered = useMemo(() => marked(props.data || ''), [props.data]);
  const mdRef = useRef<HTMLDivElement>(null);

  const onCommandClicked = useCallback(
    (command: DocumentCommand<any>) => emitEvent(command),
    [emitEvent]
  );

  useLayoutEffect(() => {
    const elements = mdRef.current.querySelectorAll('[data-loops-type="command"]');
    for (const elem of Array.from(elements)) {
      const commandData = elem.getAttribute('data-loops-data');
      let command;
      try {
        command = JSON.parse(commandData);
      } catch (e) {
        continue;
      }
      if (!elem.classList.contains(classes.Command)) {
        elem.classList.add(classes.Command);
      }
      elem.addEventListener('click', () => onCommandClicked(command));
    }

    return () => {
      // remove click listener
      for (const elem of Array.from(elements)) {
        const elemClone = elem.cloneNode(true);
        elem.parentNode.replaceChild(elemClone, elem);
      }
    };
  }, [mdRef, onCommandClicked]);

  return (
    <DocumentTitleWrapper
      title={props.title}
      className={classNames(classes.MarkdownViewer, className)}
    >
      <div
        ref={mdRef}
        className={classes.Markdown}
        dangerouslySetInnerHTML={{__html: sanitize(rendered)}}
      />
    </DocumentTitleWrapper>
  );
};
