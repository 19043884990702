import {RCAMode} from '../../../../../types';
import {useMemo} from 'react';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';

interface RCAVsLabels {
  valueLabel: string;
  vsValueLabel: string;
}

interface UseRCAVsLabelsResponse {
  share: RCAVsLabels;
  count: RCAVsLabels;
  value: RCAVsLabels;
}

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

export const useRCAVsLabels = (mode: RCAMode, entity: string): UseRCAVsLabelsResponse => {
  const {t} = useDocumentTranslation();
  const prefix = t(TRANS_KEYS_PREFIX.VS_MODE_PREFIX[mode.toUpperCase()]);

  return useMemo(
    () => ({
      share: {
        valueLabel: `Current ${t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE.TITLE, {entity})}`,
        vsValueLabel: `${prefix} ${t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE.TITLE, {entity})}`,
      },
      count: {
        valueLabel: `Current ${t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT.TITLE, {entity})}`,
        vsValueLabel: `${prefix} ${t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT.TITLE, {entity})}`,
      },
      value: {
        valueLabel: `Current ${t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.TITLE)}`,
        vsValueLabel: `${prefix} ${t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE.TITLE)}`,
      },
    }),
    [prefix, entity, t]
  );
};
