import {RCASegmentationViewMode} from '../../../rca-figure/rca-figure-viewer.types';
import {exists} from 'front-core';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';

export const fixSegmentsForExplainers = (
  segments: UnifiedRCASegmentationItem[],
  viewMode: RCASegmentationViewMode
): UnifiedRCASegmentationItem[] => {
  return segments.map((s, idx) => {
    const changes: Partial<UnifiedRCASegmentationItem> = {};
    if (viewMode === RCASegmentationViewMode.EXPLAIN) {
      changes.groupKey = s.groupKey || `${s.key}_${idx}`;
    }
    if (viewMode === RCASegmentationViewMode.CHANGE) {
      changes.helperText = s.interestHelperText;
      changes.isSignificant = s.isInterestSignificant;
      changes.groupKey = s.interestGroupKey || `${s.key}_${idx}`;
      changes.similarityType = s.interestSimilarityType;
      changes.isPrimaryInGroup = exists(s.isInterestPrimaryInGroup)
        ? s.isInterestPrimaryInGroup
        : s.isPrimaryInGroup;
    }
    return {
      ...s,
      ...changes,
      // ignore event property on team filter
      ignoreTeamFilter: s.isEventProperty,
    };
  });
};

export const generateSegmentName = (segment: UnifiedRCASegmentationItem) => {
  if (segment.segmentKeys && segment.segmentKeys.length > 1) {
    return segment.segments.map(s => `${s.segmentGroupName} → ${s.segmentName}`).join(' & ');
  }
  return `${segment.segmentGroupName} → ${segment.segmentName}`;
};
