import * as React from 'react';
import {Template} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {concatPath} from '../query-builder.utils';
import {useCallback, useContext, useMemo, useState} from 'react';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {QueryBlock} from '../components/query-block/query-block.component';
import {useQueryElement} from '../query-builder.hooks';
import {TextInput} from '../../../forms/inputs/text-input/text-input.component';
import {ParametersBuilder} from './parameters-builder.component';
import {Select} from '../../../forms/inputs/select/select.component';
import {QUERY_BUILDER_TEMPLATES} from '../consts/templates.consts';
import {keys} from 'lodash';
import {exists} from 'front-core';
import {InlineButton} from '../components/inline-button/inline-button.component';
import {QueryBuilderContext} from '../query-builder.context';

interface OwnProps {
  data: Template;
}

type AllProps = OwnProps & QueryBuilderComponent;

enum MODE {
  SELECT,
  FREE_TEXT,
}

export const TemplateBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled} = props;
  const {viewMode} = useContext(QueryBuilderContext);
  const {localErrors, actions} = useQueryElement(props);
  const [mode, setMode] = useState<number>(MODE.SELECT);
  const parameters = useMemo(() => data.parameters || [], [data]);
  const selectOptions = useMemo(
    () => ({
      options: keys(QUERY_BUILDER_TEMPLATES).map(k => ({
        label: QUERY_BUILDER_TEMPLATES[k].name,
        value: k,
      })),
    }),
    []
  );
  const parametersDescriptor = useMemo(
    () => QUERY_BUILDER_TEMPLATES[data.template]?.parameters,
    [data.template]
  );
  const onModeChanged = useCallback(() => {
    setMode(mode => (mode === MODE.SELECT ? MODE.FREE_TEXT : MODE.SELECT));
    onChange(path, {
      ...data,
      parameters: [],
      template: '',
    });
  }, [data, onChange, path]);
  const onTypeChanged = useCallback(
    template => {
      onChange(path, {
        ...data,
        template,
        parameters: [],
      });
    },
    [data]
  );

  return (
    <QueryTree label={'Template'} actions={actions} disabled={disabled} className={className}>
      <QueryBlock label={`Name`} box={false} disabled={disabled}>
        {mode === MODE.SELECT && (
          <Select
            onChange={onTypeChanged}
            value={data.template || null}
            placeholder={'Select template'}
            options={selectOptions}
            disabled={disabled}
          />
        )}
        {mode === MODE.FREE_TEXT && (
          <TextInput
            placeholder={'Enter name'}
            value={data.template}
            onChange={v => onChange(concatPath(path, 'template'), v)}
            error={Boolean(localErrors.template)}
            disabled={disabled}
            fullWidth={false}
          />
        )}
        {!disabled && !viewMode && (
          <InlineButton
            label={mode === MODE.SELECT ? 'Custom' : 'Select'}
            onClick={onModeChanged}
          />
        )}
      </QueryBlock>
      {(!parametersDescriptor || parametersDescriptor.length > 0) && (
        <ParametersBuilder
          data={parameters}
          onChange={v => onChange(concatPath(path, 'parameters'), v)}
          disabled={disabled || !exists(data.template)}
          parametersDescriptor={parametersDescriptor}
        />
      )}
    </QueryTree>
  );
};
