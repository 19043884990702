import * as React from 'react';
import classes from './generic-loading.module.scss';
import {CircularProgress} from '@material-ui/core';
import classNames from 'classnames';

interface OwnProps {
  color?: 'primary' | 'secondary';
  size?: number;
  className?: string;
}

export const GenericLoading: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <div className={classNames(classes.Loading, props.className)}>
      <CircularProgress
        variant={'indeterminate'}
        disableShrink
        size={props.size}
        thickness={4}
        color={'inherit'}
      />
    </div>
  );
};

GenericLoading.defaultProps = {
  color: 'secondary',
};
