import {useEffect, useMemo, useState} from 'react';
import {LabelOptions} from './chart-data.types';
import moment from 'moment';
import {BASE_STYLE, COLOR_NAMES} from './chart.consts';
import {range as lodashRange, pick, min, max, uniqueId} from 'lodash';
import {capitalize as capitalizeUtil, exists, removeUndefinedKeys} from 'front-core';
import {StageController} from '../../../core/konva/stage.controller';
import {DEFAULT_DATE_FORMAT} from '../../../consts/ui';
import {isArray} from 'lodash';
import {BoundaryBox} from './chart-inner.types';

export const useChartStyle = (
  defaultStyle: any = BASE_STYLE,
  options: any = {},
  keys = ['colors', 'fontName']
) => {
  return useMemo(() => {
    const override = removeUndefinedKeys(pick(options, keys));
    if (isArray(override.colors)) {
      override.colors = override.colors.map(c => COLOR_NAMES[c] || c);
    }
    return {
      ...defaultStyle,
      ...override,
    };
  }, [defaultStyle, options.colors, keys]);
};

export const useChartLabels = (labels: string[], options: LabelOptions = {}, range?: number) => {
  const safeOptions = useMemo(() => options || {}, [options]);
  const {dateFormat, dateInputFormat, capitalize, type} = safeOptions;
  const arr = useMemo(
    () => (range ? lodashRange(0, range).map(i => labels[i] || '') : labels),
    [labels, range]
  );
  return useMemo(() => {
    if (!exists(labels)) {
      return undefined;
    }
    return arr.map(label => {
      if (type === 'date') {
        return moment
          .utc(label, dateInputFormat)
          .format(dateFormat ? dateFormat : DEFAULT_DATE_FORMAT);
      }
      if (capitalize) {
        return capitalizeUtil(label);
      }
      return label;
    });
  }, [arr, dateFormat, dateInputFormat, labels, capitalize, type]);
};

export const useRange = (
  data: number[],
  minValue?: number,
  maxValue?: number,
  extend: boolean = true
) => {
  const range: number[] = useMemo(() => {
    if (data.length === 1) {
      return data;
    }
    const min_ = exists(minValue) ? minValue : min(data);
    const max_ = exists(maxValue) ? maxValue : max(data);

    if (min_ === max_ && extend) {
      return [min_ - 1, min_ + 1];
    }
    return [min_, max_];
  }, [minValue, maxValue, data]);
  return range;
};

export const useChartLabelsElements = (
  labels: string[] | number[],
  width: number,
  controller: StageController,
  options: {
    fontName: string;
    fontSize: number;
  },
  minimal: boolean = false
) => {
  const labelWidths = useMemo(
    () =>
      labels.map(
        l => controller.measureTextWidth(l, options.fontName, options.fontSize) + options.fontSize
      ),
    [controller, labels, options]
  );
  const availableLabelWidth = useMemo(() => width / labels.length, [labels.length, width]);
  const positions = useMemo(
    () => labels.map((_, i) => i * availableLabelWidth),
    [labels, availableLabelWidth]
  );
  const maxLabelWidth = useMemo(() => min([max(labelWidths), 100]), [labelWidths]);
  const labelsRotation = useMemo(() => {
    if (minimal) {
      return 0;
    }
    return availableLabelWidth < maxLabelWidth ? Math.acos(availableLabelWidth / maxLabelWidth) : 0;
  }, [maxLabelWidth, availableLabelWidth, minimal]);
  const height = useMemo(
    () => max([Math.sin(labelsRotation) * maxLabelWidth, 20]),
    [labelsRotation, maxLabelWidth]
  );
  const labelElements = useMemo(
    () =>
      labels.map((l, idx) => ({
        text: String(l).toUpperCase(),
        rotation: labelsRotation * (180 / Math.PI),
        width: maxLabelWidth,
        x: positions[idx],
        height,
      })),
    [height, labels, labelsRotation, maxLabelWidth, positions]
  );

  return {
    labels: labelElements,
    width: availableLabelWidth,
    height,
  };
};

export const LEGEND_TOP_PADDING = 12;

export const useChartLegend = (
  height: number,
  paddingTop: number = 0,
  boundaryBox: BoundaryBox,
  position: 'top' | 'bottom' = 'bottom',
  layoutWidth: number = 0
) => {
  const legendDivElementId = useMemo(() => uniqueId('legend_'), []);
  const [legendHeight, setLegendHeight] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const legendsDiv = document.getElementById(legendDivElementId);
      setLegendHeight(legendsDiv?.clientHeight + paddingTop || 0);
    }, 0);
  }, [legendDivElementId, paddingTop]);

  const legendHtmlGroupProps = useMemo(() => {
    let x = 0;
    let y = 0;
    if (position === 'bottom') {
      y = height + paddingTop - legendHeight;
    }
    let width = boundaryBox?.width;
    if (position === 'top' && layoutWidth) {
      width = layoutWidth - 22;
    }

    return {
      y,
      x,
      width,
    };
  }, [height, legendHeight]);

  return {
    legendHtmlProps: {
      id: legendDivElementId,
      groupProps: legendHtmlGroupProps,
    },
    legendHeight,
  };
};
