import * as React from 'react';
import classNames from 'classnames';
import {
  DocumentElementType,
  RetentionOverviewFigureItem,
  RetentionTimeUnit,
} from '../../../../../../types';
import {useMemo} from 'react';
import {flatten, max, min} from 'lodash';
import {generateRetentionLabels} from '../../retention-overview-viewer.utils';
import {exists, safeDivision} from 'front-core';
import TransKeys from 'translations';
import pluralize from 'pluralize';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import classes from './retention-overview-data-mode.module.scss';
import {useRetentionChart} from '../../hooks/use-retention-chart.hook';
import {calculateRetentionBucketsAverage} from '../../../../../../../html-charts/retention-table/retention-table.utils';

interface OwnProps {
  items: RetentionOverviewFigureItem[];
  timeUnit: RetentionTimeUnit;
  timeUnitN: number;
  entity: string;
  bucket0?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const RetentionOverviewDataMode: React.FC<AllProps> = (props: AllProps) => {
  const {items, timeUnit, timeUnitN, bucket0, entity, className} = props;
  const {t} = useDocumentTranslation();

  const bucketAverages = useMemo(
    () =>
      calculateRetentionBucketsAverage(items)
        .filter(i => exists(i))
        .map(i => i * 100),
    [items]
  );
  const labels = useMemo(() => {
    const maxDataLen = max(items.map(i => i.values.length));
    return generateRetentionLabels(timeUnit, timeUnitN, maxDataLen, bucket0);
  }, [items, timeUnit, timeUnitN, bucket0]);

  const retentionChart = useRetentionChart({
    mainDataset: bucketAverages,
    timeUnit,
    timeUnitN,
    entity,
    bucket0,
  });

  const retentionTableFigure = useMemo(() => {
    const formattedValues = flatten(
      items.map(i => i.values.map(v => safeDivision(v.value, i.total)))
    ).filter(i => i);
    return {
      id: 'retention-table-overview',
      type: DocumentElementType.RETENTION_TABLE_FIGURE,
      data: items,
      title: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.RETENTION_TABLE_FIGURE.TITLE),
      labels,
      options: {
        yLabel: 'Date',
        totalLabel: pluralize(entity),
        dateFormat: 'DD MMM YY',
        minRangeValue: bucket0 ? 0 : min(formattedValues),
        maxRangeValue: bucket0 ? 1 : max(formattedValues),
        showFirstPeriodTooltip: bucket0,
      },
    };
  }, [items, entity, labels, bucket0, t]);

  return (
    <div className={classNames(classes.RetentionOverviewDataMode, className)}>
      {bucketAverages.length > 0 && (
        <ChildRenderer children_={retentionChart} className={classes.Chart} />
      )}
      <ChildRenderer children_={retentionTableFigure} />
    </div>
  );
};

RetentionOverviewDataMode.defaultProps = {
  bucket0: true,
};
