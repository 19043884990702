import {useMemo, useState} from 'react';
import classes from './ab-test-summary-viewer.module.scss';
import {ABTestSummaryFigure} from '../../../../types';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {SwitchActions} from '../../../../../../simple/controls/switch-actions/switch-actions.component';
import {
  MergeLightIcon,
  ShuffleLightIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {
  ExperimentSummaryVariantItem,
  ExperimentVariantsTable,
} from '../../../shared/general/experiment-variants-table/experiment-variants-table.component';

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.AB_TEST_SUMMARY_FIGURE;

interface OwnProps extends ABTestSummaryFigure {
  className?: string;
}

type AllProps = OwnProps;

export const ABTestSummaryViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, options} = props;
  const {items} = data;
  const {showVsAll} = options;
  const {t} = useDocumentTranslation();
  const [isComparedAgainstControl, setIsComparedAgainstControl] = useState<boolean>(true);
  const compareModeOptions = useMemo(
    () => [
      {
        helperText: t(TRANS_KEYS_PREFIX.VS_TOGGLE.CONTROL.HELPER_TEXT),
        label: t(TRANS_KEYS_PREFIX.VS_TOGGLE.CONTROL.LABEL),
        icon: MergeLightIcon,
        onClick: () => setIsComparedAgainstControl(true),
        isActive: isComparedAgainstControl,
      },
      {
        helperText: t(TRANS_KEYS_PREFIX.VS_TOGGLE.ALL.HELPER_TEXT),
        label: t(TRANS_KEYS_PREFIX.VS_TOGGLE.ALL.LABEL),
        icon: ShuffleLightIcon,
        onClick: () => setIsComparedAgainstControl(false),
        isActive: !isComparedAgainstControl,
      },
    ],
    [isComparedAgainstControl, setIsComparedAgainstControl]
  );
  const tableData: ExperimentSummaryVariantItem[] = useMemo(
    () =>
      items.map(item => ({
        ...item,
        isWinner: isComparedAgainstControl ? item.isWinnerVsControl : item.isWinnerVsAll,
        probability: isComparedAgainstControl ? item.probabilityVsControl : item.probabilityVsAll,
        expectedLoss: isComparedAgainstControl
          ? item.expectedLossVsControl
          : item.expectedLossVsAll,
        improvement: isComparedAgainstControl ? item.improvementVsControl : item.improvementVsAll,
      })),
    [items, isComparedAgainstControl]
  );
  return (
    <div className={classes.ABTestSummaryFigure}>
      <div className={classes.Header}>
        <span className={classes.Title}>{t(TRANS_KEYS_PREFIX.TITLE)}</span>
        {showVsAll && <SwitchActions showActionsLabel actions={compareModeOptions} />}
      </div>
      <ExperimentVariantsTable
        data={tableData}
        transKeysPrefix={TRANS_KEYS_PREFIX}
        transKeysModifier={isComparedAgainstControl ? 'VS_CONTROL' : 'VS_ALL'}
        showActions={options.showActions}
      />
    </div>
  );
};

ABTestSummaryViewer.defaultProps = {};
