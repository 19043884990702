import * as React from 'react';
import classes from '../../../chart.module.scss';
import {useMemo, useRef} from 'react';
import {ChartAnnotation, ChartMarkArea, XTicksRangeTimeUnit} from '../../../chart-data.types';
import {chartClasses} from '../../../chart.consts';
import moment from 'moment/moment';
import {take} from 'lodash';
import ClickAnimation from '../../../../../../assets/images/click-on-point-animation.gif';

interface OwnProps {
  show: boolean;
  markArea: ChartMarkArea;
  dateFormat?: string;
  timeUnit?: XTicksRangeTimeUnit;
}

type AllProps = OwnProps;

export const ChartMarkAreaTooltip: React.FC<AllProps> = (props: AllProps) => {
  const {markArea, show, dateFormat, timeUnit} = props;
  const head = useMemo(() => {
    const fromMoment = moment.utc(markArea.from);
    const toMoment = moment.utc(markArea.to);
    if (timeUnit) {
      fromMoment.add(1, timeUnit);
      toMoment.add(1, timeUnit).subtract(1, 'd');
    }
    const to = toMoment.format(dateFormat).toUpperCase();
    const from = fromMoment.format(dateFormat).toUpperCase();
    return `${from} - ${to}`;
  }, [markArea, timeUnit]);

  return (
    <div
      className={chartClasses.ChartMarkAreaTooltip}
      style={{
        opacity: show ? 1 : 0,
      }}
    >
      <div className={classes.Head}>{head}</div>
      <div className={classes.Main}>
        <div className={classes.Title}>{markArea.title}</div>
        {markArea.description && <div className={classes.Description}>{markArea.description}</div>}
      </div>
    </div>
  );
};
