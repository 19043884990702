import classNames from 'classnames';
import classes from './explore-users-controller.module.scss';
import {MetricSmartSelector} from '../../../../../shared/core/smart-selector/metric-smart-selector/metric-smart-selector.component.tsx';
import {AttributesSelector} from '../../../../../analyses/analysis-forms/components/ui-selectors/attributes-selector/attributes-selector.component.tsx';
import {AnalysisSelectorVariant} from '../../../../../analyses/analysis-forms/components/ui-selectors/analysis-selector.types.ts';
import {TableEntity, TableEntityBinding} from '../../../../../../objects/models/table.model.ts';
import {Controller, useForm} from 'react-hook-form';
import {
  ExportWorkflowDTO,
  onDemandWorkflowValidator,
} from '../../../../../../objects/dto/workflow.dto.ts';
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useMemo, useState} from 'react';
import {AddRegularIcon, Button, CloseRegularIcon} from 'ui-components';
import {BuilderWrapper} from '../../../../../shared/components/general/builder-wrapper/builder-wrapper.component.tsx';
import {PopulationSegmentQueryBuilder} from '../../../../../shared/core/query-builders/population-segment-query-builder/population-segment-query-builder.component.tsx';
import {TimeFrameSelector} from '../../../../../analyses/analysis-forms/components/ui-selectors/time-frame-selector/time-frame-selector.component.tsx';
import {startEndDatesDefaultHandler} from '../../../../../shared/core/parameters-form/parameters-form-default-handlers.utils.ts';
import {EntityPicker} from '../../../../../dmp/components/entity-picker/entity-picker.component.tsx';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook.ts';
import pluralize from 'pluralize';

interface Props {
  data: ExportWorkflowDTO;
  onSubmit: (data: ExportWorkflowDTO) => void;
  disabled?: boolean;
  className?: string;
}

export const ExploreUsersController = (props: Props) => {
  const {data = {}, onSubmit: onSubmitFromProps, disabled, className} = props;
  const [showPopulationFilter, setShowPopulationFilter] = useState(false);
  const {defaultTableEntity, productEntitiesMap} = useProductData();
  const formMethods = useForm({
    defaultValues: {
      metric: null,
      attributes: [null],
      population_filter: null,
      entity: defaultTableEntity,
      ...startEndDatesDefaultHandler(),
      ...data,
    } as ExportWorkflowDTO,
    resolver: yupResolver(onDemandWorkflowValidator.noUnknown()) as any,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
    control,
  } = formMethods;
  const entity = watch('entity');
  const attributes = watch('attributes');
  const populationFilter = watch('population_filter');
  const startDate = watch('start_date');
  const endDate = watch('end_date');

  const entityNamePlural = useMemo(() => {
    const entityName = productEntitiesMap[entity].name.toLowerCase();
    return pluralize(entityName);
  }, [entity, productEntitiesMap]);
  const onMetricChange = useCallback(
    (metricId: number) => setValue('metric', metricId),
    [setValue]
  );
  const metricFilters = useMemo(
    () => ({
      entityContext: entity,
      entityBinding: TableEntityBinding.DEFAULT,
    }),
    [entity]
  );
  const onAttributesChange = useCallback(
    v => {
      let newValue = v.attributes || [null];
      if (newValue.length === 0) {
        newValue = [null];
      }
      setValue('attributes', newValue);
    },
    [setValue]
  );
  const onChangeEntity = useCallback(
    entity => {
      setValue('entity', entity);
      setValue('metric', null);
      setValue('attributes', [null]);
      setValue('population_filter', null);
      setShowPopulationFilter(false);
    },
    [setValue, setShowPopulationFilter]
  );
  const onChangeTimeFrame = useCallback(
    timeframe => {
      setValue('start_date', timeframe.start_date);
      setValue('end_date', timeframe.end_date);
    },
    [setValue]
  );
  const onRemoveFilter = useCallback(() => {
    setValue('population_filter', null);
    setShowPopulationFilter(false);
  }, [setValue, setShowPopulationFilter]);

  const onSubmit = useCallback(data => onSubmitFromProps(data), [onSubmitFromProps]);

  return (
    <div className={classNames(classes.ExploreUsersController, className)}>
      <div className={classNames(classes.Line, classes.EntityLine)}>
        <Controller
          render={({field}) => (
            <EntityPicker
              value={field.value}
              onChange={onChangeEntity}
              variant={'switch'}
              pluralizeEntityName
              editMode
            />
          )}
          name={'entity'}
          control={control}
        />
      </div>
      <div className={classes.Line}>
        <span className={classes.Label}>The {entityNamePlural} who reached</span>
        <Controller
          render={({field}) => (
            <MetricSmartSelector
              value={field.value}
              onChange={onMetricChange}
              placeholder={'Select KPI'}
              filters={metricFilters}
              // @ts-ignore
              error={Boolean(errors?.metric)}
            />
          )}
          name={'metric'}
          control={control}
        />
        <span className={classes.Label}>in the</span>
        <TimeFrameSelector
          value={{
            start_date: startDate,
            end_date: endDate,
          }}
          onChange={onChangeTimeFrame}
          errors={errors || {}}
          variant={AnalysisSelectorVariant.INLINE}
          showTimeframeText={false}
        />
      </div>
      <div className={classes.Line}>
        <span className={classes.Label}>Selecting the following attributes</span>
        <AttributesSelector
          value={{attributes}}
          onChange={onAttributesChange}
          errors={errors || {}}
          entityContext={entity as TableEntity}
          variant={AnalysisSelectorVariant.INLINE}
        />
      </div>
      {showPopulationFilter && entity && (
        <div className={classes.Line}>
          <BuilderWrapper>
            <div className={classes.PopulationFilterHead}>
              <div className={classes.FilterBy}>Filter by</div>
              <span
                onClick={() => onRemoveFilter()}
                className={classNames(classes.AddFilterButton, classes.Remove)}
              >
                <CloseRegularIcon /> Remove filter
              </span>
            </div>
            <PopulationSegmentQueryBuilder
              onChange={value => setValue('population_filter', value)}
              query={populationFilter}
              // @ts-ignore
              errors={errors?.population_filter}
              entityContext={entity as TableEntity}
            />
          </BuilderWrapper>
        </div>
      )}
      <div className={classes.Line}>
        {!showPopulationFilter && entity && (
          <span onClick={() => setShowPopulationFilter(true)} className={classes.AddFilterButton}>
            <AddRegularIcon /> Add filter
          </span>
        )}
        <Button onClick={handleSubmit(onSubmit)} disabled={disabled}>
          Submit
        </Button>
      </div>
    </div>
  );
};
