import * as React from 'react';
import classNames from 'classnames';
import classes from './growth-map-icon.module.scss';

interface OwnProps {
  icon: any;
  color?: string;
  label?: string;
  onClick?: (e) => void;
  className?: string;
  variant?: 'light' | 'dark';
}

type AllProps = OwnProps;

export const GrowthMapIcon: React.FC<AllProps> = (props: AllProps) => {
  const {icon: Icon, color, label, onClick, variant, className} = props;

  return (
    <div className={classes.GrowthMapIconWrapper}>
      <div
        onClick={onClick ? onClick : undefined}
        className={classNames(
          classes.GrowthMapIcon,
          onClick && classes.Pointer,
          classes[variant],
          className
        )}
      >
        <Icon className={classes.Icon} style={{color: color}} />
      </div>
      {label && <span>{label}</span>}
    </div>
  );
};

GrowthMapIcon.defaultProps = {
  variant: 'light',
};
