import * as React from 'react';
import classes from './badge.module.scss';
import classNames from 'classnames';
import {forwardRef, useMemo} from 'react';

interface OwnProps {
  badgeContent: string | number;
  invisible?: boolean;
  children: any;
  className?: string;
  badgeContentClassName?: string;
  alert?: boolean;
  onBadgeClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

type AllProps = OwnProps;

export const Badge: React.FC<AllProps> = forwardRef((props: AllProps, ref) => {
  const {
    badgeContent,
    children,
    invisible: invisible_,
    className,
    alert,
    onBadgeClick,
    badgeContentClassName,
  } = props;

  const invisible = useMemo(() => invisible_ || badgeContent === 0, [badgeContent, invisible_]);

  return (
    <span className={classNames(classes.Badge, className)} ref={ref as any}>
      {children}
      <span
        className={classNames(
          classes.BadgeAnchor,
          badgeContent && classes.HasContent,
          invisible && classes.Invisible,
          alert && classes.Alert,
          badgeContentClassName
        )}
        onClick={onBadgeClick}
      >
        {invisible ? null : badgeContent}
      </span>
    </span>
  );
});

Badge.defaultProps = {
  invisible: false,
};
