import * as React from 'react';
import classNames from 'classnames';
import {ShareOfTotalColumnOptions} from '../../../../../types';
import {useMemo} from 'react';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists, number2k} from 'front-core';
import classes from './data-columns.module.scss';
import tableClasses from '../../smart-table.module.scss';
import {DataColumnProps} from '../../smart-table.types';
import {Tooltip} from '@material-ui/core';

interface OwnProps extends DataColumnProps<ShareOfTotalColumnOptions> {}

type AllProps = OwnProps;

const ShareOfTotalColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, columnState, className} = props;
  const {total, suffix} = column.typeOptions;

  const value = useMemo(() => data[column.key], [column.key, data]);
  const percentage = useMemo(() => number2k((data[column.key] / total) * 100), [data, column]);
  const formattedValues = useMemo(
    () => ({
      primary: {
        text: `${percentage}%`,
        tooltip: '',
      },
      secondary: {
        text: `${number2k(value)}`,
        tooltip: `${value.toLocaleString()} ${suffix}`,
      },
    }),
    [columnState, value, percentage]
  );
  const renderValues = useMemo(
    () => ({
      primary:
        columnState.columnMode === 'primary' ? formattedValues.primary : formattedValues.secondary,
      secondary:
        columnState.columnMode === 'primary' ? formattedValues.secondary : formattedValues.primary,
    }),
    [formattedValues, columnState]
  );

  return (
    <div className={classNames(classes.ShareOfTotalColumn, className)}>
      <Tooltip title={renderValues.primary.tooltip} placement={'top'}>
        <span className={classes.Value}>{renderValues.primary.text}</span>
      </Tooltip>
      {exists(value) && (
        <Tooltip title={renderValues.secondary.tooltip} placement={'top'}>
          <span className={classNames(tableClasses.SecondaryText, tableClasses.ShowOnHover)}>
            ({renderValues.secondary.text} {suffix})
          </span>
        </Tooltip>
      )}
    </div>
  );
};
const protector: Protector = (props: OwnProps) => exists(props.data[props.column.key]);
export const ShareOfTotalColumn = withDataColumnProtector(protector)(ShareOfTotalColumnComponent);
