import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import classes from './view-habit-moment-variant-modal.module.scss';
import {
  ExtendedHabitMomentFigureItem,
  HabitMomentViewMode,
} from '../../habit-moment-viewer.component';
import {SwitchHabitMode} from '../switch-habit-mode/switch-habit-mode.component';
import {Select} from '../../../../../../../../forms/inputs/select/select.component';
import {
  DocumentElement,
  DocumentElementType,
  DocumentIconType,
  HabitMomentFigureItemCausationType,
  HabitMomentFigureItemInstanceType,
  HabitMomentFigureOptions,
  SPECIAL_SMART_TABLE_DATA_KEYS,
} from '../../../../../../types';
import {useHabitMomentColumnsHook} from '../../hooks/use-habit-moment-columns.hook';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {every, get, groupBy, keyBy, maxBy, minBy} from 'lodash';
import TransKeys from 'translations';
import {AppTabs} from '../../../../../../../../simple/navigation/app-tabs/app-tabs.component';
import {ConfusionMatrixViewer} from '../confusion-matrix-viewer/confusion-matrix-viewer.component';
import {NextPrevNavigator} from '../../../../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {exists, number2k} from 'front-core';
import {
  filterInstanceByMode,
  getBestInstance,
  getHabitOrderByMode,
  instanceFilterByMode,
  instanceScoreFunction,
} from '../../habit-moment-viewer.utils';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import classNames from 'classnames';
import {
  DocumentMessage,
  DocumentMessageType,
} from '../../../../../shared/general/document-message/document-message.component';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {Checkbox} from '../../../../../../../../forms/inputs/checkbox/checkbox.component';

interface OwnProps {
  id: string;
  selected: ExtendedHabitMomentFigureItem;
  goalValue: number;
  setSelected: (key: string | null) => void;
  itemOptions: {label: string; value: string; modes: HabitMomentViewMode[]}[];
  mode: HabitMomentViewMode;
  modeOptions: HabitMomentViewMode[];
  type: HabitMomentFigureItemInstanceType;
  options: HabitMomentFigureOptions;
}

type AllProps = OwnProps;

enum MatrixTabs {
  HEATMAP = 'heatmap',
  CONFUSION = 'confusion',
}

const MINIMUM_ITEMS_FOR_SEARCH = 6;

const CAUSAL_TABS = [
  HabitMomentFigureItemCausationType.POSITIVE,
  HabitMomentFigureItemCausationType.INSIGNIFICANT,
  HabitMomentFigureItemCausationType.NO_RECOMMENDATION,
];
const ONLY_TABLE_CAUSAL_TYPES = [
  HabitMomentFigureItemCausationType.NO_RECOMMENDATION,
  HabitMomentFigureItemCausationType.INSIGNIFICANT,
];

export const ViewHabitMomentVariantModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    id,
    selected,
    setSelected,
    type,
    mode: modeFromProps,
    goalValue,
    modeOptions,
    itemOptions: itemOptionsFromProps,
    options,
  } = props;
  const {t} = useDocumentTranslation();
  const {trackItemTabChanged, trackItemClicked} = useDocumentTracking(
    id,
    DocumentElementType.HABIT_MOMENT_FIGURE
  );
  const [mode, setMode] = useState<HabitMomentViewMode>(modeFromProps);
  const [selectedCausalType, setSelectedCausalType] = useState(
    HabitMomentFigureItemCausationType.POSITIVE
  );
  const [showInsignificantHeatmap, setShowInsignificantHeatmap] = useState(true);
  const [selectedMatrixTab, setSelectedMatrixTab] = useState<MatrixTabs>(MatrixTabs.HEATMAP);
  const [selectedVariantKey, setSelectedVariantKey] = useState<string>(null);
  const tableColumns = useHabitMomentColumnsHook({
    ...options,
    selectedMode: mode,
    selectedType: type,
    causationType: selectedCausalType,
    variantsMode: true,
    correlationRange: selected?.correlationRange,
    maxPotentialRange: selected?.maxPotentialRange,
    itemsHasTotalUsers: exists(selected?.totalUsers),
  });
  const data: any[] = useMemo(
    () =>
      get(selected, `instances.${type}`, [])
        .map(i => ({
          ...i,
          dataType: selected.dataType,
        }))
        .filter(i => filterInstanceByMode(i, mode)),
    [selected, type, mode]
  );
  const dataGroupedByCausalType = useMemo(() => groupBy(data, 'causationType'), [data]);
  const displayData = useMemo(() => {
    if (mode === HabitMomentViewMode.CAUSATION && selectedCausalType) {
      return dataGroupedByCausalType[selectedCausalType] || [];
    }
    return data;
  }, [data, dataGroupedByCausalType, mode, selectedCausalType]);
  const maxItemDataKey: string = useMemo(() => {
    const bestInstance = getBestInstance(
      data.filter(i => instanceFilterByMode(i, mode)),
      mode,
      options.higherIsBetter
    );
    return bestInstance?.key;
  }, [data, mode, options.higherIsBetter]);
  const dataByKey = useMemo(() => keyBy(displayData, 'key'), [displayData]);
  const tableData = useMemo(
    () =>
      displayData.map(i => {
        const isSignificant = mode === HabitMomentViewMode.CAUSATION ? true : i.isSignificant;
        return {
          ...i,
          adoptionRate: i.adoptionRate * 100,
          goalInAdopters: i.goalInAdopters * (options.isPercentageValue ? 100 : 1),
          goalInNonAdopters: i.goalInNonAdopters * (options.isPercentageValue ? 100 : 1),
          uplift: i.uplift * 100,
          maxPotential: exists(i.maxPotential) ? i.maxPotential * 100 : undefined,
          maxPotentialUpper: exists(i.maxPotentialUpper) ? i.maxPotentialUpper * 100 : undefined,
          maxPotentialLower: exists(i.maxPotentialLower) ? i.maxPotentialLower * 100 : undefined,
          star: i.key === maxItemDataKey,
          info: mode === HabitMomentViewMode.CAUSATION ? i.infoCausation : i.info,
          isSignificant,
          [SPECIAL_SMART_TABLE_DATA_KEYS.DISABLED]:
            (mode === HabitMomentViewMode.CAUSATION &&
              ONLY_TABLE_CAUSAL_TYPES.indexOf(selectedCausalType) > -1) ||
            (isSignificant === false && !showInsignificantHeatmap),
        };
      }),
    [
      displayData,
      selected,
      type,
      options,
      mode,
      maxItemDataKey,
      selectedCausalType,
      showInsignificantHeatmap,
    ]
  );
  const onSelectItem = useCallback(
    (key: string) => {
      setSelected(key);
      setSelectedVariantKey(null);
    },
    [setSelected, setSelectedVariantKey]
  );
  const onVariantClicked = useCallback(
    (itemKey: string) => {
      setSelectedVariantKey(itemKey);
      const item = dataByKey[itemKey];
      trackItemClicked(itemKey, {
        type: 'variant',
        name: `Uplift ${number2k(item.uplift * 100)}%`,
      });
    },
    [setSelectedVariantKey, trackItemClicked, dataByKey]
  );
  const onTabChanged = useCallback(
    (tab: MatrixTabs) => {
      trackItemTabChanged(tab);
      setSelectedMatrixTab(tab);
    },
    [setSelectedMatrixTab, trackItemTabChanged]
  );
  const onChangeMode = useCallback(
    mode => {
      setSelectedVariantKey(null);
      setMode(mode);
    },
    [setMode, setSelectedVariantKey]
  );
  const tableFigure: DocumentElement = useMemo(
    () =>
      ({
        id: `${selected?.key}_modal_table`,
        dataKey: 'key',
        type: DocumentElementType.SMART_TABLE,
        columns: tableColumns,
        data: tableData,
        onClick: item => onVariantClicked(item.key),
        selectedKey: selectedVariantKey,
        options: {
          defaultSort: {
            orderBy: getHabitOrderByMode(mode),
            order: options.higherIsBetter ? 'desc' : 'asc',
          },
          pagination: false,
          stickyFooter: true,
          significantDataKey: 'isSignificant',
          headMessage:
            selectedCausalType === HabitMomentFigureItemCausationType.INSIGNIFICANT ? (
              <DocumentMessage
                type={DocumentMessageType.WARN}
                text={t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.INSIGNIFICANT_WARN_MESSAGE)}
              />
            ) : undefined,
        },
      }) as any,
    [
      tableColumns,
      tableData,
      id,
      onVariantClicked,
      selectedVariantKey,
      mode,
      selectedCausalType,
      options.higherIsBetter,
      selected?.key,
    ]
  );
  const heatmapFigure: DocumentElement = useMemo(() => {
    const values = displayData
      .filter(i => (showInsignificantHeatmap ? true : i.isSignificant))
      .map(i => {
        const v = instanceScoreFunction(i, mode);
        if (!exists(v)) {
          return;
        }
        return {
          key: i.key,
          x: i.timeframeDays,
          y: i.repeats,
          value: v,
          icon: maxItemDataKey === i.key ? DocumentIconType.STAR : undefined,
        };
      })
      .filter(i => i);
    if (values.length === 0) {
      return null;
    }
    return {
      id: `${id}_heatmap`,
      type: DocumentElementType.HEATMAP,
      selectedKey: selectedVariantKey,
      onClick: onVariantClicked,
      data: {
        values,
        xTicks: Array.from(new Set(values.map(v => v.x))).sort((a, b) => a - b),
        yTicks: Array.from(new Set(values.map(v => v.y))).sort((a, b) => a - b),
      },
      options: {
        xLabel: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.HEATMAP.X_LABEL),
        yLabel:
          type === HabitMomentFigureItemInstanceType.REPEATED_ACTIONS
            ? t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.HEATMAP.Y_LABEL_BY_ACTIONS)
            : t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.HEATMAP.Y_LABEL_BY_DAYS),
        isPercentage:
          [HabitMomentViewMode.UPLIFT, HabitMomentViewMode.CAUSATION].indexOf(mode) > -1,
        rangeLabel: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VIEW_MODE[mode.toUpperCase()]),
        higherIsBetter: options.higherIsBetter,
      },
    } as any;
  }, [
    id,
    displayData,
    selectedVariantKey,
    onVariantClicked,
    showInsignificantHeatmap,
    t,
    type,
    mode,
    maxItemDataKey,
    options.higherIsBetter,
  ]);
  const tabs = useMemo(() => {
    const res = [];
    if (heatmapFigure) {
      res.push({
        key: MatrixTabs.HEATMAP,
        label: t(
          TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VARIANTS_MODAL.TABS[
            `${mode.toUpperCase()}_MATRIX`
          ]
        ),
      });
    }
    res.push({
      key: MatrixTabs.CONFUSION,
      label: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VARIANTS_MODAL.TABS.CONFUSION_MATRIX),
    });
    return res;
  }, [t, mode, heatmapFigure]);
  const causalTabsOptions = useMemo(() => {
    return CAUSAL_TABS.map(v => ({
      key: v,
      label: `${t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.CAUSAL_TABS[v.toUpperCase()])} (${
        dataGroupedByCausalType[v]?.length || 0
      })`,
      disabled: (dataGroupedByCausalType[v]?.length || 0) === 0,
    }));
  }, [dataGroupedByCausalType, t]);
  const hideRightPanel = useMemo(() => {
    if (mode !== HabitMomentViewMode.CAUSATION) {
      return false;
    }
    return ONLY_TABLE_CAUSAL_TYPES.indexOf(selectedCausalType) > -1;
  }, [selectedCausalType, mode]);
  const itemOptions = useMemo(
    () => itemOptionsFromProps.filter(i => i.modes.indexOf(mode) > -1),
    [itemOptionsFromProps, mode]
  );
  const navigatorProps = useMemo(() => {
    const currentIndex = itemOptions.findIndex(i => i.value === selected?.key);
    const maxIndex = itemOptions.length - 1;
    return {
      currentIndex,
      maxIndex,
      onNextPrev: (isNext: boolean) =>
        setSelected(itemOptions[currentIndex + (isNext ? 1 : -1)].value),
      nextLabel: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VARIANTS_MODAL.NEXT_LABEL, {
        model: options.habitModelName,
      }),
      prevLabel: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VARIANTS_MODAL.PREV_LABEL, {
        model: options.habitModelName,
      }),
    };
  }, [itemOptions, selected?.key, setSelected, t, options]);
  const allInsignificant = useMemo(
    () => every(displayData.map(i => i.isSignificant === false)),
    [displayData]
  );
  useEffect(() => {
    if (selectedCausalType && ONLY_TABLE_CAUSAL_TYPES.indexOf(selectedCausalType) > -1) {
      setSelectedVariantKey(undefined);
      return;
    }
    let starItem = tableData.find(i => i.star === true)?.key;
    if (!starItem) {
      starItem = tableData[0]?.key;
    }
    setSelectedVariantKey(starItem);
  }, [tableData, selectedCausalType]);
  useEffect(() => {
    if (!heatmapFigure) {
      setSelectedMatrixTab(MatrixTabs.CONFUSION);
    } else {
      setSelectedMatrixTab(MatrixTabs.HEATMAP);
    }
  }, [heatmapFigure, setSelectedMatrixTab]);
  useEffect(() => {
    selected?.key && setSelectedCausalType(HabitMomentFigureItemCausationType.POSITIVE);
  }, [selected?.key, setSelectedCausalType]);
  useEffect(() => {
    selected?.key && setMode(modeFromProps);
  }, [modeFromProps, setMode, selected]);

  return (
    <ModalWrapper
      isOpen={selected !== null}
      onClose={() => setSelected(null)}
      width={'85vw'}
      height={'85vh'}
    >
      <div className={classes.ViewHabitMomentVariantModal}>
        <div className={classes.Title}>
          <div className={classes.TitleText}>
            {t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VARIANTS_MODAL.TITLE_PREFIX)}
          </div>
          <Select
            onChange={onSelectItem}
            options={{options: itemOptions}}
            value={selected?.key}
            clearable={false}
            className={classes.SelectItem}
            dropdownButtonClassName={classes.SelectItemButton}
            searchable={itemOptions.length > MINIMUM_ITEMS_FOR_SEARCH}
            capitalize={false}
          />
          <div className={classes.Separator} />
          <SwitchHabitMode mode={mode} setMode={onChangeMode} modes={modeOptions} />
          <div className={classes.Spacer} />
          <div className={classes.GoalValue}>
            <span className={classes.Label}>
              {t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.GOAL_VALUE_LABEL)}
            </span>
            <span className={classes.Value}>{number2k(goalValue * 100)}%</span>
          </div>
        </div>
        {mode === HabitMomentViewMode.CAUSATION && (
          <div className={classes.CausalTabs}>
            <AppTabs
              tabs={causalTabsOptions}
              selected={selectedCausalType}
              onChange={setSelectedCausalType as any}
              className={classes.Tabs}
              fullWidth
            />
          </div>
        )}
        <div className={classNames(classes.Content, hideRightPanel && classes.HideMatrix)}>
          <div className={classes.Table}>
            <ChildRenderer children_={tableFigure} key={`${selected?.key}_${mode}`} />
          </div>
          <div className={classes.Matrix}>
            <AppTabs
              tabs={tabs}
              selected={selectedMatrixTab}
              onChange={onTabChanged as any}
              fullWidth
              className={classes.Tabs}
            />
            {selectedMatrixTab === MatrixTabs.HEATMAP && selectedVariantKey && heatmapFigure && (
              <div className={classes.HeatmapWrapper}>
                <ChildRenderer children_={heatmapFigure} key={`${selected?.key}_${mode}`} />
                {!allInsignificant && (
                  <Checkbox
                    checked={showInsignificantHeatmap}
                    onChange={setShowInsignificantHeatmap}
                    label={t(
                      TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VARIANTS_MODAL
                        .SHOW_INSIGNIFICANT_VALUES_HEATMAP_LABEL
                    )}
                    className={classes.ShowInsignificantCheckbox}
                    border
                  />
                )}
              </div>
            )}
            {selectedMatrixTab === MatrixTabs.CONFUSION &&
              selectedVariantKey &&
              dataByKey[selectedVariantKey] && (
                <ConfusionMatrixViewer
                  matrix={get(dataByKey[selectedVariantKey], `confusionMatrix`)}
                />
              )}
          </div>
        </div>
        <div className={classes.Navigation}>
          <NextPrevNavigator {...navigatorProps} />
        </div>
      </div>
    </ModalWrapper>
  );
};
