import {
  TableDateFilter,
  TableNumberRangeFilter,
  TableListFilter,
  TableFilters,
  TableFilterType,
  TableExactMatchFilter,
  TableTreeFilter,
} from '../smart-table.types';
import {values, isArray, difference, pick, get, intersection, some} from 'lodash';
import moment from 'moment';
import {TREE_FILTER_ALL_CHILDREN} from '../components/filters/tree-filter.component';

const filterByExactMatch = (data: any, filter: TableExactMatchFilter) => {
  const value = data[filter.properties.dataKey];
  if (isArray(value)) {
    return new Set(value).has(filter.value);
  }
  return data[filter.properties.dataKey] === filter.value;
};
const filterByRange = (data: any, filter: TableNumberRangeFilter) =>
  filter.value.min <= data[filter.properties.dataKey] &&
  data[filter.properties.dataKey] <= filter.value.max;
const filterByInSet = (data: any, filter: TableListFilter) => {
  const value = data[filter.properties.dataKey];
  const set = new Set(filter.value);
  if (isArray(value)) {
    return difference(Array.from(set), value).length === 0;
  }
  return set.has(data[filter.properties.dataKey]);
};
const filterByDate = (data: any, filter: TableDateFilter) => {
  return moment(data[filter.properties.dataKey]).isBetween(
    filter.value[0],
    filter.value[1] || filter.value[0],
    'day',
    '[]'
  );
};
const filterByContains = (data: any, filter: TableTreeFilter) => {
  const item = pick(data, values(filter.properties));
  const selected: any = get(filter.value, item[filter.properties.parentDataKey]);
  // if item[filter.properties.parentDataKey] is an array we need to recursively call filterByContains for each individual item
  if (isArray(item[filter.properties.parentDataKey])) {
    return some(
      item[filter.properties.parentDataKey].map((_, idx) =>
        filterByContains(
          {
            [filter.properties.parentDataKey]: item[filter.properties.parentDataKey][idx],
            [filter.properties.dataKey]: item[filter.properties.dataKey][idx],
          },
          filter
        )
      )
    );
  }
  if (!selected) {
    return false;
  }
  if (selected === TREE_FILTER_ALL_CHILDREN) {
    const parentValue = item[filter.properties.parentDataKey];
    return (filter.value[parentValue] as any) === TREE_FILTER_ALL_CHILDREN;
  }
  const set = new Set(selected);
  return set.has(item[filter.properties.dataKey]);
};

const FILTER_BY_TYPE = {
  [TableFilterType.EXACT_MATCH]: filterByExactMatch,
  [TableFilterType.NUMBER_RANGE]: filterByRange,
  [TableFilterType.DURATION]: filterByRange,
  [TableFilterType.LIST]: filterByInSet,
  [TableFilterType.DATE]: filterByDate,
  [TableFilterType.TREE]: filterByContains,
};

export function filterData(
  data: any[],
  tableFilters: TableFilters,
  excludeFilterKeys: string[] = []
) {
  const fixedFilters = {...tableFilters};

  for (const excludeKey of excludeFilterKeys) {
    delete fixedFilters[excludeKey];
  }

  let newData = [...data];
  const filters = values(fixedFilters);

  for (const filter of filters) {
    // @ts-ignore
    newData = newData.filter(item => FILTER_BY_TYPE[filter.type](item, filter as any));
  }

  return newData;
}
