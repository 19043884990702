import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {DocumentViewerMode, ViewerComponentMode} from '../../../../document-viewer.types';
import {ViewerOptions} from './viewer-options.component';
import {DocumentElement} from '../../../../types';
import {ViewerComment} from '../comment/comment.component';
import classes from './viewer-options.module.scss';
import {EditorDocumentModes, MarkerableComponents} from '../../../../document-viewer.config';
import {EyeIcon, EyeSlashIcon} from '../../../../../../simple/controls/icons/icons.component';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ChatIcon from '@material-ui/icons/Chat';
import CodeIcon from '@material-ui/icons/Code';
import {Popover} from '@material-ui/core';
import {exists} from 'front-core';
import BuildIcon from '@material-ui/icons/Build';
import {JsonEditor as Editor} from 'jsoneditor-react';

interface OwnProps {
  element: DocumentElement;
  elementParameters: any;
  isMutated?: boolean;
  documentMode: DocumentViewerMode;
  componentMode: ViewerComponentMode;
  onChangeParameters: (change: any) => void;
  onChangeViewerMode: (mode: ViewerComponentMode) => void;
  onDataChanged?: (data: any) => void;
  onReset?: () => void;
  className?: string;
}

enum PopoverType {
  COMMENT,
  DEBUG_DATA,
  DEBUG_PARAMETERS,
}

type AllProps = OwnProps;

export const DocumentViewerOptions: React.FC<AllProps> = (props: AllProps) => {
  const {
    element,
    isMutated,
    documentMode,
    componentMode,
    elementParameters,
    onChangeParameters,
    onChangeViewerMode,
    onReset,
    onDataChanged,
    className,
  } = props;
  const [tempData, setTempData] = useState(null);
  const [popoverRef, setPopoverRef] = useState(null);
  const [notes, setNotes] = useState(elementParameters.notes || '');
  const [popoverType, setPopoverType] = useState<PopoverType>(null);

  const onClosePopover = useCallback(() => {
    setPopoverRef(null);
    if (popoverType === PopoverType.DEBUG_DATA && tempData !== null) {
      onDataChanged(tempData);
    }
    setPopoverType(null);
  }, [onDataChanged, popoverType, tempData]);
  const onChangeMode = useCallback(
    (newMode: ViewerComponentMode) => {
      if (componentMode === newMode) {
        onChangeViewerMode(ViewerComponentMode.DEFAULT_MODE);
        return;
      }
      onChangeViewerMode(newMode);
    },
    [componentMode, onChangeViewerMode]
  );
  const options: any = useMemo(
    () => [
      {
        icon: elementParameters.isHidden ? EyeSlashIcon : EyeIcon,
        onClick: () =>
          onChangeParameters({
            isHidden: !Boolean(elementParameters.isHidden),
          }),
        text: elementParameters.isHidden ? 'Hidden' : undefined,
        hidden: elementParameters.isPreview,
        helperText: elementParameters.isHidden ? 'Show' : 'Hide',
        modes: [DocumentViewerMode.OPPORTUNITY_MODE],
      },
      {
        icon: elementParameters.isPreview ? RemoveFromQueueIcon : AddToQueueIcon,
        onClick: () =>
          onChangeParameters({
            isPreview: !Boolean(elementParameters.isPreview),
          }),
        text: elementParameters.isPreview ? 'Preview' : undefined,
        hidden: elementParameters.isHidden,
        helperText: elementParameters.isPreview ? 'Remove from preview' : 'Add to preview',
        modes: [DocumentViewerMode.OPPORTUNITY_MODE],
      },
      {
        onClick: () => onChangeMode(ViewerComponentMode.HIGHLIGHT_MODE),
        icon: BorderColorIcon,
        hidden: MarkerableComponents.indexOf(element.type) === -1,
        active: componentMode === ViewerComponentMode.HIGHLIGHT_MODE,
        helperText:
          componentMode === ViewerComponentMode.HIGHLIGHT_MODE
            ? 'Exit highlight mode'
            : 'Enter highlight mode',
        modes: [DocumentViewerMode.OPPORTUNITY_MODE],
      },
      {
        icon: ChatIcon,
        onClick: e => {
          setPopoverRef(e.target);
          setPopoverType(PopoverType.COMMENT);
        },
        active: exists(elementParameters.notes),
        text: elementParameters.notes,
        helperText: exists(elementParameters.notes) ? 'Edit notes' : 'Add notes',
        modes: [DocumentViewerMode.OPPORTUNITY_MODE],
      },
      {
        icon: CodeIcon,
        onClick: e => {
          setPopoverRef(e.target);
          setPopoverType(PopoverType.DEBUG_DATA);
        },
        helperText: 'View source',
        modes: [DocumentViewerMode.DEBUG],
      },
      {
        icon: BuildIcon,
        onClick: e => {
          setPopoverRef(e.target);
          setPopoverType(PopoverType.DEBUG_PARAMETERS);
        },
        helperText: 'View parameters',
        modes: [DocumentViewerMode.DEBUG],
      },
      {
        // icon: BuildIcon,
        onClick: e => onReset(),
        text: 'Reset Changes',
        modes: [DocumentViewerMode.DEBUG],
        hidden: !isMutated,
      },
    ],
    [
      elementParameters,
      element,
      componentMode,
      isMutated,
      onChangeParameters,
      onChangeMode,
      onReset,
    ]
  );

  const renderPopoverContent = () => {
    switch (popoverType) {
      case PopoverType.COMMENT:
        return (
          <textarea
            value={notes || ''}
            onChange={e => setNotes(e.target.value)}
            onBlur={_ => onChangeParameters({notes})}
            className={classes.NoteInput}
            placeholder={'Add your note here...'}
          />
        );
      case PopoverType.DEBUG_PARAMETERS:
        return (
          <div className={classes.EditorWrapper}>
            <Editor
              value={elementParameters}
              mode={'view'}
              htmlElementProps={{style: {height: '100%'}}}
            />
          </div>
        );
      case PopoverType.DEBUG_DATA:
        return (
          <div className={classes.EditorWrapper}>
            <Editor
              value={tempData || element}
              onChange={setTempData}
              allowedModes={['code', 'tree']}
              htmlElementProps={{style: {height: '100%'}}}
            />
          </div>
        );
    }
  };
  const editorMode = useMemo(() => EditorDocumentModes.indexOf(documentMode) > -1, [documentMode]);
  const renderOptions = useMemo(
    () => options.filter(o => (o.modes ? o.modes.indexOf(documentMode) > -1 : true)),
    [documentMode, options]
  );

  if (editorMode) {
    return (
      <>
        <ViewerOptions className={className} options={renderOptions} title={element.type} />
        <Popover
          id={`popover_${element.id}`}
          open={Boolean(popoverRef)}
          anchorEl={popoverRef}
          onClose={onClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {renderPopoverContent()}
        </Popover>
      </>
    );
  }

  if (documentMode === DocumentViewerMode.VIEW_MODE && elementParameters.notes) {
    return <ViewerComment className={classes.NotesPosition} comment={elementParameters.notes} />;
  }
  return null;
};

DocumentViewerOptions.defaultProps = {
  elementParameters: {},
};
