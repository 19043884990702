import moment from 'moment/moment';
import humanizeDuration from 'humanize-duration';

export const ColorUtil = {
  COLORS: {
    highlight: '#FDE695',
    good: '#62E797',
    bad: '#F66B6B',
    muted: '#C3C6D8',
  },

  getColor: (color: string) => {
    return ColorUtil.COLORS[color] || color;
  },
};

export const ChartColorUtil = {
  COLORS: {
    green: '#21C476',
    red: '#f66b6b',
  },

  getColor: (color: string) => {
    return ChartColorUtil.COLORS[color] || color;
  },
};

const DURATION_OPTIONS = {
  conjunction: ' and ',
  largest: 3,
  units: ['y', 'mo', 'w', 'd', 'h', 'm', 's'],
  round: true,
  spacer: '',
};

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
    },
  },
});
export const secondsToDuration = (seconds: number, full = false) => {
  if (full) {
    return shortEnglishHumanizer(seconds * 1000, {conjunction: ' and '});
  }
  const options = {...DURATION_OPTIONS};
  const ms = seconds * 1000;
  if (ms >= moment.duration(2, 'week').asMilliseconds()) {
    options.units = ['mo', 'w', 'd'];
  } else if (ms >= moment.duration(7, 'day').asMilliseconds()) {
    options.units = ['d', 'h'];
  }
  return shortEnglishHumanizer(seconds * 1000, options);
};
