import * as React from 'react';
import classNames from 'classnames';
import classes from './release-impact-segmentation-viewer.module.scss';
import {ReleaseImpactSegmentationFigure, SegmentOfReleaseSegmentation} from '../../../../types';
import {
  FlexibleTable,
  FlexibleTableClasses,
  FlexibleTableColumn,
} from '../../../shared/general/flexible-table/flexible-table.component';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {useCallback, useContext, useMemo} from 'react';
import {extendSegments} from './release-impact-segmentation-viewer.utils';
import {every, groupBy, keys, take, values} from 'lodash';
import {TooltipIfOverflow} from '../../../../../../simple/generic/tooltips/tooltips.component';
import {sortData, withStopPropagation} from 'front-core';
import {Button} from '../../../../../../simple/controls/button/button.component';
import TransKeys from 'translations';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {ReleaseImpactSegmentationModal} from './release-impact-segmentation-modal/release-impact-segmentation-modal.component';
import {VersusDisplay} from '../../../shared/display-columns/versus-display.component';
import {UpliftDisplay} from '../../../shared/display-columns/uplift-display.component';
import {HoverHelperTip} from '../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {
  ExtractSegmentData,
  SegmentFilters,
  SegmentFiltersValue,
  useFilterSegmentItems,
  useTeamAutoSetterForSegments,
} from '../../../shared/segmentation/segment-filters/segment-filters.component';
import {GlobalDocumentDataContext} from '../../../../contexts/global-document-data/global-document-data.context';

interface OwnProps extends ReleaseImpactSegmentationFigure {
  className?: string;
}

type AllProps = OwnProps;

export interface ExtendedSegmentOfReleaseSegmentation extends SegmentOfReleaseSegmentation {
  postShare: number;
  preShare: number;
  shareUplift: number;
  valueUplift: number;
  groupInsignificant: boolean;
}

interface SegmentGroup {
  key: string;
  segmentGroupName: string;
  totalCount: number;
  signalId: number;
  isMuted: boolean;
  segments: ExtendedSegmentOfReleaseSegmentation[];
}

const NUMBER_OF_ITEMS_PER_GROUP = 3;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RELEASE_SEGMENTATION_FIGURE;

const EXTRACT_SEGMENT_DATA: ExtractSegmentData = {
  signalIdDataKey: 'signalId',
  segmentGroupNameDataKey: 'segmentGroupName',
  segmentNameDataKey: 'segmentName',
  shareDataKey: 'postShare',
};

const GROUP_SORTING = [
  {
    orderBy: 'isMuted',
    order: 'asc',
  },
  {
    orderBy: 'segmentGroupName',
    order: 'asc',
  },
];
const DEFAULT_FILTERS = {};

export const ReleaseImpactSegmentationViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data, options, className} = props;
  const {t} = useDocumentTranslation();
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const {teamId} = useContext(GlobalDocumentDataContext);

  const [selectedGroupSignalId, setSelectedGroupSignalId] = React.useState<number | null>(null);
  const segments = useMemo(
    () => extendSegments(data.segments, data.postTotalEntities, data.preTotalEntities),
    [data]
  );
  useTeamAutoSetterForSegments(segments, EXTRACT_SEGMENT_DATA);
  const {query: filters, setQuery: setFilters} = useDocQuery<SegmentFiltersValue>(
    id,
    DEFAULT_FILTERS,
    'filters'
  );
  const filteredSegments = useFilterSegmentItems(segments, teamId, filters, EXTRACT_SEGMENT_DATA);
  const segmentGroups = useMemo(() => {
    const groups = groupBy(filteredSegments, 'signalId');
    for (const signalId of keys(groups)) {
      groups[signalId] = groups[signalId].sort((a, b) => b.postShare - a.postShare);
    }
    return groups;
  }, [filteredSegments, data.releaseImpactValue]);
  const tableData: SegmentGroup[] = useMemo(() => {
    let res = [];
    for (const signalId of keys(segmentGroups)) {
      res.push({
        key: signalId,
        segmentGroupName: segmentGroups[signalId][0].segmentGroupName,
        totalCount: segmentGroups[signalId].length,
        signalId: segmentGroups[signalId][0].signalId,
        segments: segmentGroups[signalId],
        isMuted: every(segmentGroups[signalId].map(s => s.groupInsignificant)),
      });
    }
    // @ts-ignore
    return sortData(res, GROUP_SORTING[0], GROUP_SORTING[1]);
  }, [segmentGroups]);
  const tableColumns: FlexibleTableColumn<SegmentGroup>[] = useMemo(
    () => [
      {
        key: 'segmentGroupName',
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_GROUP_NAME.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_GROUP_NAME.SUB_TITLE),
        helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_GROUP_NAME.HELPER_TEXT),
        weight: 1,
        sortable: true,
        render: group => (
          <TooltipIfOverflow title={group.segmentGroupName}>
            <div
              onClick={withStopPropagation(() => onSignalClick(group.signalId))}
              className={classes.GroupName}
            >
              {group.segmentGroupName} ({group.totalCount})
            </div>
          </TooltipIfOverflow>
        ),
      },
      {
        key: 'segmentName',
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.SUB_TITLE),
        helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.HELPER_TEXT),
        weight: 1,
        sortable: false,
        render: group => (
          <div className={classes.List}>
            {take(group.segments, NUMBER_OF_ITEMS_PER_GROUP).map(segment => (
              <div className={classes.SegmentItem} key={segment.key}>
                <TooltipIfOverflow title={segment.segmentName}>
                  <div className={classes.Name}>{segment.segmentName || '--'}</div>
                </TooltipIfOverflow>
                {segment.helperText && (
                  <HoverHelperTip className={classes.Info} title={segment.helperText} />
                )}
              </div>
            ))}
          </div>
        ),
      },
      {
        key: 'postShare',
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE_POST.TITLE, {
          entity: options.entity,
        }),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE_POST.SUB_TITLE, {
          entity: options.entity,
        }),
        helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE_POST.HELPER_TEXT, {
          entity: options.entity,
        }),
        weight: 1,
        sortable: false,
        render: group => (
          <div className={classes.List}>
            {take(group.segments, NUMBER_OF_ITEMS_PER_GROUP).map(segment => (
              <div className={classes.SegmentItem} key={segment.key}>
                <VersusDisplay
                  value={segment.postShare}
                  vsValue={segment.preShare}
                  isPercentageValue={true}
                  vsLabel={t(TRANS_KEYS_PREFIX.LABELS.PRE_SHARE)}
                />
              </div>
            ))}
          </div>
        ),
      },
      {
        key: 'postValue',
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE_POST.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE_POST.SUB_TITLE),
        helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE_POST.HELPER_TEXT),
        weight: 1,
        sortable: false,
        render: group => (
          <div className={classes.List}>
            {take(group.segments, NUMBER_OF_ITEMS_PER_GROUP).map(segment => (
              <div className={classes.SegmentItem} key={segment.key}>
                <VersusDisplay
                  value={segment.postValue}
                  vsValue={segment.preValue}
                  isPercentageValue={options.isPercentageValue}
                  vsLabel={t(TRANS_KEYS_PREFIX.LABELS.PRE_KPI_VALUE)}
                />
              </div>
            ))}
          </div>
        ),
      },
      {
        key: 'uplift',
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_UPLIFT.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_UPLIFT.SUB_TITLE),
        weight: 1,
        sortable: true,
        helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_UPLIFT.HELPER_TEXT),
        render: group => (
          <div className={classes.List}>
            {take(group.segments, NUMBER_OF_ITEMS_PER_GROUP).map(segment => (
              <div className={classes.SegmentItem} key={segment.key}>
                <UpliftDisplay
                  uplift={segment.valueUplift}
                  className={classes.SegmentLine}
                  higherIsBetter={options.higherIsBetter}
                />
              </div>
            ))}
          </div>
        ),
      },
      // {
      //   key: 'ctcPp',
      //   title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP_POST.TITLE),
      //   subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP_POST.SUB_TITLE),
      //   helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP_POST.HELPER_TEXT),
      //   weight: 1,
      //   sortable: false,
      //   render: group => (
      //     <div className={classes.List}>
      //       {take(group.segments, NUMBER_OF_ITEMS_PER_GROUP).map(segment => (
      //         <div className={classes.SegmentItem} key={segment.key}>
      //           <ImpactPPDisplay value={segment.postCtcPp} outOfValue={data.postPopulationTrend} />
      //         </div>
      //       ))}
      //     </div>
      //   ),
      // },
      {
        key: 'actions',
        title: ``,
        subTitle: '',
        helperText: '',
        width: '8rem',
        align: 'right',
        render: segment => (
          <div className={FlexibleTableClasses.ShowOnHover}>
            <Button onClick={() => setSelectedGroupSignalId(segment.signalId)}>
              {t(TRANS_KEYS_PREFIX.SEE_ALL_BUTTON_HELPER, {
                count: segmentGroups[segment.signalId].length,
              })}
            </Button>
          </div>
        ),
      },
    ],
    [segmentGroups, onSignalClick, options, data.postPopulationTrend, t]
  );
  const selectedGroup = useMemo(
    () => segmentGroups[selectedGroupSignalId],
    [segmentGroups, selectedGroupSignalId]
  );
  const groupOptions = useMemo(
    () =>
      values(tableData).map(group => ({
        value: group.signalId,
        label: group.segmentGroupName,
      })),
    [tableData]
  );
  const onFilterChange = useCallback(
    (newFilters: SegmentFiltersValue) => {
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      }));
    },
    [setFilters]
  );

  return (
    <DocumentTitleWrapper
      title={t(TRANS_KEYS_PREFIX.TITLE)}
      description={t(TRANS_KEYS_PREFIX.DESCRIPTION)}
      className={classNames(classes.ReleaseImpactSegmentationViewer, className)}
    >
      <div className={classes.Filters}>
        <SegmentFilters
          segments={segments}
          extract={EXTRACT_SEGMENT_DATA}
          filtersValue={filters}
          onFiltersChange={onFilterChange}
          entity={options.entity}
        />
      </div>
      <FlexibleTable
        columns={tableColumns}
        data={tableData}
        isMutedRow={item => item.isMuted}
        onRowClicked={g => setSelectedGroupSignalId(g.signalId)}
        rowClassName={i =>
          i.segments.length > NUMBER_OF_ITEMS_PER_GROUP
            ? FlexibleTableClasses.RowGradientOverlay
            : null
        }
      />
      {selectedGroup && (
        <ReleaseImpactSegmentationModal
          segments={selectedGroup}
          selectedGroupSignalId={selectedGroupSignalId}
          postPopulationTrend={data.postPopulationTrend}
          releaseImpactValue={data.releaseImpactValue}
          groupOptions={groupOptions}
          onSelectGroup={setSelectedGroupSignalId}
          options={options}
        />
      )}
    </DocumentTitleWrapper>
  );
};

ReleaseImpactSegmentationViewer.defaultProps = {};
