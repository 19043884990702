import * as React from 'react';
import {useMemo} from 'react';
import {
  ColumnType,
  CommandType,
  DocumentElement,
  DocumentElementType,
  NavigationType,
  PhenomenasFigure,
  SmartTableFigureColumn,
  SmartTableFigureOptions,
} from '../../../types';
import {ChildRenderer} from '../../core/child-renderer.component';
import {exists} from 'front-core';
import {ChildRendererInjectedProps} from '../../../document-viewer.types';
import {ModelType} from '../../../../../../consts/model-type';

type PhenomenaNavigationType = 'ref' | 'page';

export interface OwnProps extends PhenomenasFigure, Partial<ChildRendererInjectedProps> {
  className?: string;
  additionalColumns?: Array<SmartTableFigureColumn<any>>;
  navigationType?: PhenomenaNavigationType;
  emptyStateRenderer?: () => any;
}

const createCommand = (docId: string, navigationType: PhenomenaNavigationType) => {
  if (navigationType === 'ref') {
    return {
      type: CommandType.REF,
      payload: {
        type: ModelType.PHENOMENA_DOC,
        refId: docId,
      },
    };
  }

  return {
    type: CommandType.NAVIGATION,
    payload: {
      documentId: docId,
      type: NavigationType.PAGE,
    },
  };
};

export const PhenomenasViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {data = [], options, additionalColumns, navigationType, emptyStateRenderer} = props;
  const tableData: any[] = useMemo(
    () =>
      data.map(p => ({
        ...p,
        id: p.id,
        navigation: p.documentId ? createCommand(p.documentId, navigationType) : null,
      })),
    [data, navigationType]
  );
  const tableOptions: SmartTableFigureOptions = useMemo(
    () => ({
      pagination: exists(options.pagination) ? options.pagination : true,
      perPage: exists(options.perPage) ? options.perPage : undefined,
      groupByDataKey: 'type',
      groupByLabel: 'Phenomena Type',
    }),
    [options]
  );
  const tableColumns: any[] = useMemo(
    () => [
      {
        key: 'typeName',
        title: 'Type',
        options: {
          sortable: true,
          filterable: true,
          width: '20%',
        },
      },
      {
        key: 'sentence',
        type: ColumnType.SENTENCE,
        title: 'Phenomena',
        options: {
          sortable: false,
          filterable: false,
        },
        typeOptions: {
          size: 'medium',
        },
      },
      ...(additionalColumns || []),
      {
        key: 'navigation',
        title: '',
        type: ColumnType.COMMAND,
      },
    ],
    [additionalColumns]
  );

  const tableFigure: DocumentElement[] = useMemo(
    () => [
      {
        id: `phenomenas_viewer`,
        type: DocumentElementType.SMART_TABLE,
        columns: tableColumns,
        data: tableData,
        options: tableOptions,
      } as any,
    ],
    [tableColumns, tableData, tableOptions]
  );

  if (tableData.length === 0 && emptyStateRenderer) {
    return emptyStateRenderer();
  }

  return <ChildRenderer children_={tableFigure} />;
};

PhenomenasViewer.defaultProps = {
  data: [],
  options: {},
  navigationType: 'ref',
};
