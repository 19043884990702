import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import classes from '../smart-table.module.scss';
import {ColumnType, SmartTableFigureColumn} from '../../../../types';
import {Tooltip} from '@material-ui/core';
import {SmartTableContext} from '../smart-table.context';
import classNames from 'classnames';
import {TableColumnOptions} from './table-column-options.component';
import {getIconForColumn} from '../smart-table.utils';
import {SortIndicator} from '../../../../../../simple/data-display/sort-indicator/sort-indicator.component';
import {removeUndefinedKeys, sanitize} from 'front-core';
import {useTableColumn} from '../hooks/use-table-column.hook';
import {getNextSort} from 'front-core';
import marked from '../../../../../../../config/marked.config';
import {isArray, keys, pick} from 'lodash';
import {TableFilter, TableFilterType} from '../smart-table.types';
import {
  ColumnStateAction,
  getColumnStateActions,
  getFilterOptions,
  getSortByOptions,
} from '../logic/options';
import {DocumentIcon} from '../../../internal-viewers/document-icon.component';

interface OwnProps {
  column: SmartTableFigureColumn<any>;
  index: number;
}

type AllProps = OwnProps;

const getDefaultWidth = (column: SmartTableFigureColumn<any>): any => {
  if (column.type === ColumnType.INFO) {
    return '6rem';
  }
  if (column.type === ColumnType.COMMAND) {
    return '6rem';
  }
};

export const TableColumn: React.FC<AllProps> = (props: AllProps) => {
  const {column: columnFromProps, index} = props;
  const {column} = useTableColumn(columnFromProps);
  const {icon: columnIcon} = column.options;
  const {data, columnsState, changeColumnState, filters, sorting, setSorting, setFilters} =
    useContext(SmartTableContext);
  const options = useMemo(() => column.options || {}, [column]);
  const sortByOptions = useMemo(
    () => getSortByOptions(column, columnsState[column.key]),
    [column, columnsState[column.key]]
  );
  const filterOptions = useMemo(
    () => getFilterOptions(column, data, filters, columnsState[column.key]),
    [column, data, filters, columnsState[column.key]]
  );
  const columnStateActions = useMemo(
    () => getColumnStateActions(column, columnsState[column.key]),
    [column, columnsState]
  );
  const columnKeys = useMemo(() => sortByOptions.map(s => s.value), [sortByOptions]);
  const isSorted = useMemo(() => columnKeys.indexOf(sorting.orderBy) > -1, [columnKeys, sorting]);
  const style = useMemo(
    () =>
      removeUndefinedKeys({
        width: options.width || getDefaultWidth(column),
      }),
    [column, options.width]
  );
  const switchColumnOptions = useMemo(() => {
    if (columnFromProps.type !== ColumnType.SWITCH_COLUMN) {
      return [];
    }
    const resetSortingKeys = columnFromProps.typeOptions.columns.map(c => c.key);
    const resetFilterKeys = filterOptions.map(f => f.props.filterKey);
    return (columnFromProps.typeOptions.columns || [])
      .map((c, index) => ({
        key: c.key,
        icon: getIconForColumn(c),
        label: c.title,
        onClick: _ =>
          changeColumnState(
            columnFromProps,
            {switchColumnIndex: index},
            resetFilterKeys,
            resetSortingKeys
          ),
      }))
      .filter(c => c.key !== column.key);
  }, [column.key, columnFromProps, changeColumnState, filterOptions]);
  const helperText = useMemo(() => {
    if (!options?.helperText) {
      return '';
    }
    const isText = typeof options.helperText === 'string';
    if (isText) {
      return (
        <div
          className={classes.InteractiveHoverHelperTip}
          dangerouslySetInnerHTML={{
            __html: sanitize(marked(options.helperText)),
          }}
        />
      );
    } else {
      return options.helperText;
    }
  }, [options?.helperText]);
  const titles = useMemo(
    () => sortByOptions.filter(o => o.primarySort).map(s => ({key: s.value, title: s.label})),
    [sortByOptions]
  );
  const subTitle = useMemo(() => {
    switch (column.asType || column.type) {
      case ColumnType.VERSUS:
        return {
          dataKey: column.typeOptions.versusDataKey,
          title: column.typeOptions.versusDataTitle,
          prefix: column.typeOptions.versusDataTitle?.includes('vs')
            ? ''
            : column.typeOptions.versusDataTitle
              ? 'vs. '
              : '',
        };
    }
    if (column.subTitle) {
      return {
        title: column.subTitle,
      };
    }
  }, [column]);
  const filtersCount = useMemo(
    () =>
      keys(pick(filters, columnKeys)).reduce((count, key) => {
        const filter = filters[key];
        let addition = 1;
        if (isArray(filter.value) && filter.type !== TableFilterType.DATE) {
          addition = filter.value.length;
        }
        return count + addition;
      }, 0),
    [filters, columnKeys]
  );
  const onSort = useCallback(
    (columnKey: string) => setSorting(getNextSort(columnKey, sorting) as any),
    [setSorting, sorting]
  );
  const onFilterChanged = useCallback(
    (filterKey: string, filter: TableFilter<any, any>) =>
      setFilters(filters => ({
        ...filters,
        [filterKey]: filter,
      })),
    [setFilters]
  );
  const onColumnStateAction = useCallback(
    (action: ColumnStateAction) =>
      changeColumnState(column, action.newState, action.resetFilterKeys, action.resetSortingKeys),
    [changeColumnState]
  );
  const onClearFilter = useCallback(
    (filterKey: string) =>
      setFilters(filters =>
        removeUndefinedKeys({
          ...filters,
          [filterKey]: undefined,
        })
      ),
    [setFilters]
  );

  return (
    <th
      className={classNames(classes.TableColumn, column.options.borderRight && classes.BorderRight)}
      style={style}
    >
      <div className={classes.Main}>
        <div className={classes.TableColumnContent}>
          <div className={classes.Titles}>
            {titles.map((t, idx) => (
              <React.Fragment key={t.key}>
                <Tooltip
                  title={helperText ? <div className={classes.ColumnHelper}>{helperText}</div> : ''}
                  interactive
                  placement={'top'}
                >
                  <div
                    onClick={column.options.sortable ? () => onSort(t.key) : undefined}
                    className={classNames(
                      classes.Title,
                      column.options.sortable && classes.Sortable,
                      t.key === sorting.orderBy && classes.Sorted
                    )}
                  >
                    <span className={classes.ColumnName}>
                      {columnIcon && (
                        <span className={classes.IconWrapper}>
                          <DocumentIcon icon={columnIcon} className={classes.TableColumnIcon} />
                        </span>
                      )}
                      {t.title}
                    </span>
                  </div>
                </Tooltip>
                {idx < titles.length - 1 && <div className={classes.Separator}>{'>'}</div>}
              </React.Fragment>
            ))}
          </div>
          {isSorted && subTitle?.dataKey !== sorting.orderBy && (
            <SortIndicator className={classes.ColumnSortIndicator} order={sorting.order} />
          )}
          <div className={classes.Actions}>
            {switchColumnOptions.map((sa, idx) => (
              <Tooltip key={idx} title={sa.label} placement={'top'}>
                <div onClick={sa.onClick} className={classes.Action}>
                  <sa.icon className={classes.Icon} />
                </div>
              </Tooltip>
            ))}
            {data.length > 1 && (
              <TableColumnOptions
                className={classNames(classes.Action, classes.Filters)}
                column={column}
                sortByOptions={sortByOptions}
                filterOptions={filterOptions}
                columnStateActions={columnStateActions}
                onSort={onSort}
                onFilter={onFilterChanged}
                onClearFilter={onClearFilter}
                onColumnStateAction={onColumnStateAction}
                sorting={sorting}
                hasFilters={filtersCount > 0}
                popoverLeft={index === 0}
              />
            )}
          </div>
        </div>
      </div>
      {subTitle && (
        <div className={classes.Secondary}>
          <div
            onClick={
              column.options.sortable && subTitle.dataKey
                ? () => onSort(subTitle.dataKey)
                : undefined
            }
            className={classNames(
              classes.SecondaryLabel,
              column.options.sortable && subTitle.dataKey && classes.Sortable,
              subTitle.dataKey === sorting.orderBy && classes.Sorted
            )}
          >
            {subTitle.prefix}
            {subTitle.title}
          </div>
          {subTitle.dataKey === sorting.orderBy && (
            <SortIndicator className={classes.SecondarySortIndicator} order={sorting.order} />
          )}
        </div>
      )}
    </th>
  );
};

TableColumn.defaultProps = {};
