import * as React from 'react';
import {
  EnhancedGroup,
  EnhancedLine,
  EnhancedRect,
  EnhancedText,
} from '../../../../core/konva/components';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {useContext, useMemo} from 'react';
import {ChartLine as ChartLineModel} from '../chart-data.types';
import {chartClasses} from '../chart.consts';
import classNames from 'classnames';
import {StageContext} from '../../../../core/konva/stage-context.component';

interface OwnProps extends ChartLineModel {}

type AllProps = ShapeProps & OwnProps;

export const ChartLine: React.FC<AllProps> = (props: AllProps) => {
  const {height, width, x, color, title, label, description, dashed, direction} = props;
  const {controller, style} = useContext(StageContext);
  const linePoint = useMemo(
    () => (direction === 'vertical' ? [0, 0, 0, height] : [0, 0, width, 0]),
    [direction, height, width]
  );
  const labelWidth = useMemo(
    () => controller.measureTextWidth(label || '', style.fontName, 12) + 12,
    []
  );
  return (
    <EnhancedGroup
      x={x}
      y={props.y}
      center={props.center}
      centerX={props.centerX}
      centerY={props.centerY}
      tooltipEnabled
      tooltipData={{
        value: (
          <div className={chartClasses.LineTooltip}>
            {title && <div className={classNames(description && chartClasses.Title)}>{title}</div>}
            {description && <div className={chartClasses.Description}>{description}</div>}
          </div>
        ),
      }}
    >
      {label && (
        <EnhancedRect
          y={-16}
          x={width - labelWidth - 20}
          fill={color}
          width={labelWidth}
          height={16}
        />
      )}
      {label && (
        <EnhancedText
          y={-14}
          x={width - labelWidth - 20}
          fontSize={12}
          text={label}
          fill={'#ffffff'}
          width={labelWidth}
          height={16}
          ellipsis
          align={'center'}
          fontFamily={style.fontName}
        />
      )}
      <EnhancedLine
        points={linePoint}
        strokeWidth={2}
        stroke={color}
        dash={dashed ? [5, 3] : undefined}
      />
    </EnhancedGroup>
  );
};

ChartLine.defaultProps = {
  color: 'rgba(141, 148, 174, 0.6)',
};
