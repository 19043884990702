import * as React from 'react';
import {CSSProperties, useCallback, useEffect, useMemo} from 'react';
import {DocumentElementType, ReleaseImpactStepsFigure} from '../../../../types';
import classNames from 'classnames';
import classes from './release-impact-steps-viewer.module.scss';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {ChildRenderer} from '../../../core/child-renderer.component';
import {WizardStep} from '../../../../../../simple/controls/wizard-stepper/wizard-step.component';
import {
  ChevronRightRegularIcon,
  LoopsIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {NextPrevNavigator} from '../../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import TransKeys from 'translations';
import {useTranslation} from '../../../../../../../core/translations/use-translation';
import {GrayUpliftChip} from '../../../shared/display-columns/micro-components/gray-uplift-chip/gray-uplift-chip.component';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';
import {Button} from '../../../../../../simple/controls/button/button.component';
import {exists} from 'front-core';

export interface OwnProps extends ReleaseImpactStepsFigure {
  className?: string;
  style?: CSSProperties;
}

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_STEPS_FIGURE;

export const ReleaseImpactStepsViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {id, type, data = [], className, style} = props;
  const {trackItemTabChanged} = useDocumentTracking(id, DocumentElementType.STEPS_BLOCK);
  // @ts-ignore
  const backwardCompatibility = type === DocumentElementType.STEPS_BLOCK;
  const {query: selectedKey, setQuery: setSelectedKey_} = useDocQuery<string>(
    id,
    // @ts-ignore (type check is used for backward compatibility)
    backwardCompatibility ? data[0].key : undefined
  );
  const [isOpen, setIsOpen] = React.useState(
    // @ts-ignore (type check is used for backward compatibility)
    selectedKey !== undefined || backwardCompatibility
  );
  const {t} = useTranslation();
  const selectedIndex = useMemo(
    () => data.findIndex(i => i.key === selectedKey),
    [selectedKey, data]
  );
  const wizardSteps = useMemo(
    () =>
      data.map((d, idx) => ({
        value: d.key,
        label: d.label,
        description: d.description,
        chip: d.chip,
        idx,
      })),
    [data]
  );
  const setSelectedKey = useCallback(
    (key: string) => {
      setSelectedKey_(key);
      setIsOpen(true);
    },
    [setSelectedKey_]
  );
  const onNextPrev = useCallback(
    (next?: boolean) => {
      const addition = next ? 1 : -1;
      const newIndex = selectedIndex + addition;
      if (newIndex > data.length - 1 || newIndex < 0) {
        return;
      }
      setSelectedKey(data[newIndex].key);
    },
    [selectedIndex, data, setSelectedKey]
  );
  const onToggle = useCallback(
    (isOpen: boolean) => {
      setIsOpen(isOpen);
      if (selectedKey === undefined) {
        setSelectedKey(data[0].key);
      }
    },
    [setIsOpen, data, selectedKey]
  );

  useEffect(() => {
    trackItemTabChanged(selectedKey);
  }, [trackItemTabChanged, selectedKey]);

  return (
    <DocumentTitleWrapper
      title={t(TRANS_KEYS_PREFIX.SECTION_TITLE)}
      icon={LoopsIcon}
      className={className}
      style={style}
    >
      <div className={classNames(classes.ReleaseImpactStepsViewer, isOpen && classes.Open)}>
        <div className={classes.Header}>
          <div className={classes.Title}>{t(TRANS_KEYS_PREFIX.MAIN_TITLE)}</div>
          <div className={classes.Actions}>
            {isOpen && (
              <NextPrevNavigator
                maxIndex={data.length - 1}
                currentIndex={selectedIndex}
                onNextPrev={onNextPrev}
                nextLabel={'Next step'}
                prevLabel={'Previous step'}
              />
            )}
            {!backwardCompatibility && (
              <Button
                onClick={() => onToggle(!isOpen)}
                className={classNames(isOpen && classes.CloseState)}
                endIcon={({className}) => (
                  <ChevronRightRegularIcon
                    className={classNames(className, isOpen && classes.Rotate)}
                  />
                )}
              >
                {isOpen
                  ? t(TRANS_KEYS_PREFIX.ACTIONS.CLOSE_DETAILS)
                  : t(TRANS_KEYS_PREFIX.ACTIONS.SHOW_DETAILS)}
              </Button>
            )}
          </div>
        </div>
        <div className={classes.Wizard}>
          {wizardSteps.map(ws => (
            <React.Fragment key={ws.value}>
              <WizardStep
                className={classes.Step}
                stepNumber={ws.idx + 1}
                label={ws.label}
                description={
                  <div className={classes.StepDescription}>
                    {ws.description && <span>{ws.description}</span>}
                    {exists(ws.chip) && <div className={classes.Chip}>{ws.chip}</div>}
                  </div>
                }
                isCompleted={ws.idx < selectedIndex}
                onClick={() => setSelectedKey(ws.value)}
                isActive={ws.value === selectedKey}
              />
              <ChevronRightRegularIcon className={classes.ChevronRight} />
            </React.Fragment>
          ))}
        </div>
        <div className={classes.StepContent}>
          {data[selectedIndex] && (
            <ChildRenderer children_={data[selectedIndex].data} className={classes.MarginBottom} />
          )}
        </div>
      </div>
    </DocumentTitleWrapper>
  );
};

ReleaseImpactStepsViewer.defaultProps = {
  options: {},
  data: [],
};
