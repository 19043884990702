import * as React from 'react';
import {Tooltip, withStyles, TooltipProps} from '@material-ui/core';
import {useEffect, useRef, useState} from 'react';

interface OwnProps {
  title: any;
  children: any;
  interactive?: boolean;
  forceShow?: boolean;
}

type AllProps = OwnProps;

export const TooltipIfOverflow: React.FC<AllProps> = (props: AllProps) => {
  const {forceShow, title, interactive = false} = props;
  const child = React.Children.only(props.children);
  const childRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState<boolean>();

  useEffect(() => {
    setHasOverflow(
      childRef.current?.scrollWidth > childRef.current?.clientWidth ||
        childRef.current?.scrollHeight - 1 > childRef.current?.clientHeight
    );
  }, [childRef.current]);

  return (
    <Tooltip
      title={hasOverflow || forceShow ? title : ''}
      placement={'top'}
      interactive={interactive}
    >
      {React.cloneElement(child, {ref: childRef})}
    </Tooltip>
  );
};

export const HtmlTooltip: any = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'transparent !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    maxWidth: 'unset !important',
    border: '0 !important',
    padding: '0 !important',
    borderRadius: 'unset !important',
    fontSize: 'unset !important',
    boxShadow: 'unset !important',
  },
}))(Tooltip);
