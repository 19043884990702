import {ColumnType, SmartTableFigureColumn} from '../../../../types';
import {TableFilters, TableFilterType} from '../smart-table.types';
import {filterData} from './filter';
import {getDataValueKey} from '../smart-table.utils';
import {ListFilter} from '../components/filters/list-filter.component';
import {NumberRangeFilter} from '../components/filters/number-range-filter.component';
import {DurationRangeFilter} from '../components/filters/duration-range-filter.component';
import {DateFilter} from '../components/filters/date-filter.component';
import {TreeFilter} from '../components/filters/tree-filter.component';
import {HashtagIcon, PercentageIcon} from '../../../../../../simple/controls/icons/icons.component';
import {number2k} from 'front-core';

export interface LocalFilter {
  component: any;
  props: any;
}

export interface SortOption {
  value: string;
  label: string;
  primarySort?: boolean;
}

export interface ColumnStateAction {
  title: string;
  icon?: any;
  newState?: any;
  resetFilterKeys?: string[];
  resetSortingKeys?: string[];
}

export interface FilterInfo {
  type: TableFilterType;
  filterKey: string;
  properties: {dataKey: string; parentDataKey?: string};
}

export const getSortByOptions = (
  column: SmartTableFigureColumn<any>,
  columnState: any
): SortOption[] => {
  /**
   * value: the key in data
   * label: the label to render
   * primarySort: show on column header
   */
  switch (column.type) {
    case ColumnType.COMMAND:
      return [];
    case ColumnType.CONFIDENCE_INTERVAL: {
      const valueKey = getDataValueKey(column, 'valueDataKey');
      return [
        {
          value: valueKey,
          label: column.typeOptions.valueDataTitle || column.title || valueKey,
          primarySort: true,
        },
      ];
    }
    case ColumnType.LABEL_VALUE: {
      const valueKey = getDataValueKey(column, 'valueDataKey');
      return [
        {
          value: column.typeOptions.labelDataKey,
          label: column.typeOptions.labelDataTitle || column.typeOptions.labelDataKey,
          primarySort: true,
        },
        {
          value: valueKey,
          label: column.typeOptions.valueDataTitle || valueKey,
          primarySort: true,
        },
      ];
    }
    case ColumnType.PROGRESS:
      return [
        {
          value: column.key,
          label: 'Percentage',
          primarySort: true,
        },
        {
          value: column.typeOptions.valueDataKey,
          label: column.typeOptions.valueDataTitle || column.typeOptions.valueDataKey,
        },
        {
          value: column.typeOptions.totalDataKey,
          label: column.typeOptions.totalDataTitle || column.typeOptions.totalDataKey,
        },
      ];
    case ColumnType.VERSUS: {
      const valueKey = getDataValueKey(column, 'valueDataKey');
      return [
        {
          value: valueKey,
          label: column.typeOptions.valueDataTitle || valueKey,
          primarySort: true,
        },
        {
          value: column.typeOptions.versusDataKey,
          label: column.typeOptions.versusDataTitle || column.typeOptions.versusDataKey,
        },
      ];
    }
    case ColumnType.SHARE_OF_TOTAL: {
      return [
        {
          value: column.key,
          label: column.title,
          primarySort: true,
        },
      ];
    }
    case ColumnType.OUT_OF: {
      const valueKey = getDataValueKey(column, 'valueDataKey');
      return [
        {
          value: valueKey,
          label: column.typeOptions.valueDataTitle || valueKey,
          primarySort: true,
        },
        {
          value: column.typeOptions.outOfDataKey,
          label: column.typeOptions.outOfDataTitle || column.typeOptions.outOfDataKey,
        },
      ];
    }
    case ColumnType.INFO:
      return [
        {
          value: column.key,
          label: column.title || 'Value',
        },
      ];
    case ColumnType._RENDER_COLUMN:
      return column.title
        ? [
            {
              value: column.key,
              label: column.title || 'Value',
              primarySort: true,
            },
          ]
        : [];
    default:
      return [
        {
          value: column.key,
          label: column.title || 'Value',
          primarySort: true,
        },
      ];
  }
};

export const generateFilterInfo = (column: SmartTableFigureColumn<any>): FilterInfo => {
  let type = column.type;
  if (type === ColumnType._RENDER_COLUMN && column.asType !== undefined) {
    type = column.asType;
  }
  switch (type) {
    case ColumnType.COMMAND:
    case ColumnType.SWITCH_COLUMN:
      return undefined;
    case ColumnType.LABEL_VALUE: {
      let {valueDataKey, labelDataKey} = column.typeOptions;
      valueDataKey = valueDataKey || column.key;
      return {
        type: TableFilterType.TREE,
        filterKey: valueDataKey,
        properties: {
          dataKey: valueDataKey,
          parentDataKey: labelDataKey,
        },
      };
    }
    case ColumnType.DATE: {
      let {valueDataKey} = column.typeOptions;
      valueDataKey = valueDataKey || column.key;
      return {
        type: TableFilterType.DATE,
        filterKey: valueDataKey,
        properties: {dataKey: valueDataKey},
      };
    }
    case ColumnType.DURATION: {
      let {valueDataKey} = column.typeOptions;
      valueDataKey = valueDataKey || column.key;
      return {
        type: TableFilterType.DURATION,
        filterKey: valueDataKey,
        properties: {dataKey: valueDataKey},
      };
    }
    case ColumnType.CHANGE:
    case ColumnType.PROGRESS:
    case ColumnType.ADOPTION_RATE:
    case ColumnType.CONFIDENCE_INTERVAL:
    case ColumnType.GRADIENT:
    case ColumnType.NUMERIC:
    case ColumnType.VERSUS:
    case ColumnType.OUT_OF:
    case ColumnType.SHARE_OF_TOTAL: {
      let {valueDataKey} = column.typeOptions;
      valueDataKey = valueDataKey || column.key;
      return {
        type: TableFilterType.NUMBER_RANGE,
        filterKey: valueDataKey,
        properties: {dataKey: valueDataKey},
      };
    }
    default:
      return {
        type: TableFilterType.LIST,
        filterKey: column.key,
        properties: {dataKey: column.key},
      };
  }
};

export const getFilterOptions = (
  column: SmartTableFigureColumn<any>,
  data: any[],
  filters: TableFilters,
  columnState: any
): LocalFilter[] => {
  const filterComponents: LocalFilter[] = [];
  let filteredData;
  let type = column.type;
  if (type === ColumnType._RENDER_COLUMN && column.asType !== undefined) {
    type = column.asType;
  }

  const filterInfo = generateFilterInfo(column);
  switch (type) {
    case ColumnType.COMMAND:
    case ColumnType.SWITCH_COLUMN:
      return [];
    case ColumnType.LABEL_VALUE:
      {
        let {valueDataTitle} = column.typeOptions;
        filteredData = filterData(data, filters, [
          filterInfo.properties.parentDataKey,
          filterInfo.properties.dataKey,
        ]);
        filterComponents.push({
          component: TreeFilter,
          props: {
            label: `filter ${valueDataTitle || filterInfo.properties.dataKey}`,
            filteredData,
            filter: filters[filterInfo.properties.dataKey],
            filterKey: filterInfo.filterKey,
            dataKey: filterInfo.properties.dataKey,
            parentDataKey: filterInfo.properties.parentDataKey,
            placeholderText: column.options.placeholderText,
          },
        });
      }
      break;
    case ColumnType.CHANGE:
    case ColumnType.PROGRESS:
    case ColumnType.ADOPTION_RATE:
      {
        filteredData = filterData(data, filters, [filterInfo.properties.dataKey]);
        let {valueDataTitle} = column.typeOptions;
        valueDataTitle = valueDataTitle || column.title;
        filterComponents.push({
          component: NumberRangeFilter,
          props: {
            label: `filter ${valueDataTitle}`,
            filteredData,
            filter: filters[filterInfo.properties.dataKey],
            filterKey: filterInfo.filterKey,
            dataKey: filterInfo.properties.dataKey,
            unit: '%',
          },
        });
      }
      break;
    case ColumnType.CONFIDENCE_INTERVAL:
    case ColumnType.GRADIENT:
    case ColumnType.NUMERIC:
    case ColumnType.DURATION:
      {
        let {valueDataTitle} = column.typeOptions;
        valueDataTitle = valueDataTitle || column.title;
        filteredData = filterData(data, filters, [filterInfo.properties.dataKey]);
        filterComponents.push({
          component: column.type === ColumnType.DURATION ? DurationRangeFilter : NumberRangeFilter,
          props: {
            label: `filter ${valueDataTitle}`,
            filteredData,
            filter: filters[filterInfo.properties.dataKey],
            filterKey: filterInfo.properties.dataKey,
            dataKey: filterInfo.properties.dataKey,
            unit: column.options.unit,
          },
        });
      }
      break;
    case ColumnType.VERSUS:
    case ColumnType.OUT_OF:
      {
        let {valueDataTitle} = column.typeOptions;
        valueDataTitle = valueDataTitle || column.title;
        filteredData = filterData(data, filters, [filterInfo.properties.dataKey]);
        filterComponents.push({
          component: NumberRangeFilter,
          props: {
            label: `filter ${valueDataTitle}`,
            filteredData,
            filter: filters[filterInfo.properties.dataKey],
            filterKey: filterInfo.properties.dataKey,
            dataKey: filterInfo.properties.dataKey,
            unit: column.options.unit,
          },
        });
      }
      break;
    case ColumnType.SHARE_OF_TOTAL:
      {
        const {total} = column.typeOptions;
        let unit = '%';
        let formatLabel = (v: number) => `${number2k((v / total) * 100)}%`;

        if (columnState.columnMode === 'secondary') {
          formatLabel = undefined;
          unit = undefined;
        }

        filteredData = filterData(data, filters, [filterInfo.properties.dataKey]);
        filterComponents.push({
          component: NumberRangeFilter,
          props: {
            label: `filter ${column.title}`,
            filteredData,
            filter: filters[filterInfo.properties.dataKey],
            filterKey: filterInfo.properties.dataKey,
            dataKey: filterInfo.properties.dataKey,
            unit,
            formatLabel,
          },
        });
      }
      break;
    case ColumnType.DATE:
      {
        filteredData = filterData(data, filters, [filterInfo.properties.dataKey]);
        filterComponents.push({
          component: DateFilter,
          props: {
            label: `filter ${column.title}`,
            filteredData,
            filter: filters[filterInfo.properties.dataKey],
            filterKey: filterInfo.properties.dataKey,
            dataKey: filterInfo.properties.dataKey,
          },
        });
      }
      break;
    default:
      {
        filteredData = filterData(data, filters, [filterInfo.properties.dataKey]);
        filterComponents.push({
          component: ListFilter,
          props: {
            label: column.title ? `filter ${column.title}` : 'Filter',
            filteredData,
            filter: filters[filterInfo.properties.dataKey],
            filterKey: filterInfo.properties.dataKey,
            dataKey: filterInfo.properties.dataKey,
            placeholderText: column.options.placeholderText,
          },
        });
      }
      break;
  }
  return filterComponents;
};

export const getColumnStateActions = (
  column: SmartTableFigureColumn<any>,
  columnState: any
): ColumnStateAction[] => {
  switch (column.type) {
    case ColumnType.SHARE_OF_TOTAL: {
      if (columnState.columnMode === 'primary') {
        return [
          {
            icon: HashtagIcon,
            title: 'Count',
            newState: {columnMode: 'secondary'},
          },
        ];
      } else {
        return [
          {
            icon: PercentageIcon,
            title: 'Share',
            newState: {columnMode: 'primary'},
          },
        ];
      }
    }
  }
  return [];
};
