import {useCallback, useContext, useMemo, useRef} from 'react';
import {DocumentViewerContext} from '../contexts/document-viewer.context';
import {toArray} from 'front-core';

type SetDocQueryParameter<T> = T | ((prevQuery: T) => T);

interface UseDocQueryReturnType<T> {
  key: string;
  query: T;
  setQuery: (v: SetDocQueryParameter<T>) => void;
  clearQuery: () => void;
}

export function useDocQuery<T>(
  slug: string,
  defaultValue?: T,
  prefix?: string,
  transformInput?: (v: any) => T
): UseDocQueryReturnType<T> {
  const {docQuery, setDocQuery} = useContext(DocumentViewerContext);
  const queryRef = useRef<any>();

  const key = useMemo(() => {
    let k = '';
    // if uuid v4 - take last part
    if (slug.length === 36 && slug.includes('-')) {
      k = slug.split('-').pop();
    } else {
      k = slug;
    }
    if (prefix) {
      return `${prefix}${k}`;
    }
    return k;
  }, [slug, prefix]);

  const query: T = docQuery[key] || defaultValue;
  // use ref to avoid setQuery re-evaluate for every query change
  queryRef.current = query;
  const setQuery = useCallback(
    (v: SetDocQueryParameter<T>) => {
      if (typeof v === 'function') {
        // @ts-ignore
        setDocQuery({[key]: v(queryRef.current)});
        return;
      }
      setDocQuery({[key]: v});
    },
    [setDocQuery, key]
  );
  const clearQuery = useCallback(() => setDocQuery({[key]: undefined}), [setDocQuery, key]);

  return {
    key,
    query: transformInput ? transformInput(query) : query,
    setQuery,
    clearQuery,
  };
}
