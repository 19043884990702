import {
  RCAInsightSegmentSentenceParts,
  RCAInsightSentence,
  SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease,
} from '../rca-insights.model.ts';
import sharedClasses from '../../insights.module.scss';
import {
  ChangeInShareOfEntitiesSentencePart,
  ChangeInValueSentencePart,
  SegmentNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import {FC} from 'react';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease;
  sentenceParts: RCAInsightSegmentSentenceParts;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter: FC<
  AllProps
> = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {
    pastTensePerformanceVsPriorPeriod,
    entityChangeDescription,
    changeInShareOfEntities,
    entityChangeDescriptionSynonym,
    entity,
    presentTensePerformanceVsAvg,
    segmentChangeDescription,
    changeDescription,
  } = formattedSentenceParts;
  const {segmentViewData, changeInValue, higherIsBetter} = sentenceParts;

  return (
    <SentenceContainer className={sharedClasses.Vertical}>
      <SentenceContainer>
        <SegmentNameSentencePart data={segmentViewData} />
        <SentencePart text={`${pastTensePerformanceVsPriorPeriod} vs the analyzed period by `} />
        <ChangeInValueSentencePart changeInValue={changeInValue} higherIsBetter={higherIsBetter} />
        <SentencePart
          text={`In addition - the share of ${entity} from this segment ${entityChangeDescription}d by`}
        />
        <ChangeInShareOfEntitiesSentencePart changeInShareOfEntities={changeInShareOfEntities} />
        <SentencePart text={'.'} />
      </SentenceContainer>
      <SentenceContainer className={sharedClasses.SecondaryULContainer}>
        <SentencePart text={`This is a driver for the ${changeDescription} because:`} />
        <div className={sharedClasses.SecondaryUL}>
          <span className={sharedClasses.Bullet} />
          <SentencePart
            text={`You had ${entityChangeDescriptionSynonym} ${entity} from a segment that is ${presentTensePerformanceVsAvg} vs the average`}
          />
          <span className={sharedClasses.Bullet} />
          <SentencePart
            text={`The KPI value of this segment ${segmentChangeDescription}d vs the analyzed period`}
          />
        </div>
      </SentenceContainer>
    </SentenceContainer>
  );
};

export {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecreaseFormatter};
