import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './tree-selector.module.scss';
import {exists} from 'front-core';
import {CaretRightSolidIcon} from '../../simple/controls/icons/icons.component';
import {Checkbox} from '../../forms/inputs/checkbox/checkbox.component';
import {every, keys, values} from 'lodash';
import {BoldTerm} from '../../simple/utils/bold-term/bold-term.component';
import {createTreeNodesInfo, getSelectType} from './tree-selector.utils';
import {TooltipIfOverflow} from '../../simple/generic/tooltips/tooltips.component';
import {HoverHelperTip} from '../../simple/data-display/hover-helper-tip/hover-helper-tip.component';

export type TreeDataValue = string | number;
const IGNORE_PARENT_VALUE_CHAR = '!';

export interface TreeData {
  key: TreeDataValue;
  value: TreeDataValue;
  label: string;
  disabled?: boolean;
  disabledHelper?: string;
  children?: TreeData[];
}

export type TreeSelectorValue = null | TreeDataValue | TreeDataValue[];

export interface TreeSelectorProps {
  value?: TreeSelectorValue;
  data: TreeData[];
  onChange?: (value: TreeSelectorValue, originalValue?: TreeSelectorValue) => void;
  multi?: boolean;
  searchValue?: string;
  className?: string;
  selectParentValue?: boolean;
  startRootOpen?: boolean;
  // disable selection from other root parent for selected values
  oneRootAllowed?: boolean;
  oneRootAllowedHelper?: string;
}

export enum TreeSelectType {
  FULL,
  PARTIAL,
  NONE,
}

type AllProps = TreeSelectorProps;

const createInitialOpenMap = (data: TreeData[], startRootOpen: boolean) => {
  if (data.length === 1) {
    return {[data[0].value]: true};
  }
  const res = {};
  if (startRootOpen) {
    for (const t of data) {
      res[t.value] = true;
    }
  }
  return res;
};

export const TreeSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    value: valueFromProps,
    onChange: onChangeFromProps,
    data,
    searchValue,
    multi,
    selectParentValue,
    startRootOpen,
    oneRootAllowed,
    oneRootAllowedHelper,
    className,
  } = props;
  const [openState, setOpenState] = useState<any>(createInitialOpenMap(data, startRootOpen));
  // A map from a parent value to its leafs
  const nodesInfo: any = useMemo(() => createTreeNodesInfo(data), [data]);
  // values as set + fixing selectParentValue
  const valueSet = useMemo(() => {
    let newValue = [];
    if (!multi) {
      newValue = [valueFromProps];
    } else {
      for (const v of valueFromProps as any[]) {
        const push = nodesInfo[v]?.leafsValues || [v];
        newValue.push(...push);
      }
    }
    return new Set(newValue);
  }, [nodesInfo, valueFromProps, multi]);
  const selectedRootParent = useMemo(() => {
    if (valueSet.size === 0) {
      return;
    }
    return Array.from(valueSet)
      .map(v => nodesInfo[v]?.rootParentValue)
      .find(s => exists(s));
  }, [valueSet, nodesInfo]);
  // Holding info for each node
  const renderInfo = useMemo(() => {
    const res = {};
    const queue = [...data];
    for (const i of queue) {
      const isParent = i.children && i.children.length > 0;
      res[i.value] = {
        label: i.label,
        isHidden: false,
        selectType: getSelectType(valueSet, nodesInfo[i.value].leafsValues),
        isSelected: valueSet.has(i.value),
        isMatch: searchValue ? i.label.toLowerCase().includes(searchValue.toLowerCase()) : true,
        disabled:
          oneRootAllowed && exists(selectedRootParent)
            ? nodesInfo[i.value].rootParentValue !== selectedRootParent
            : i.disabled,
        disabledHelper: i.disabledHelper,
        isParent,
      };
      if (isParent) {
        queue.push(...i.children);
      }
    }
    if (!exists(searchValue)) {
      return res;
    }
    // Hiding results from search
    const list = keys(nodesInfo).sort((a, b) => nodesInfo[b].ord - nodesInfo[a].ord);
    for (const k of list) {
      // if not parent just take isMatch
      if (!res[k].isParent) {
        res[k].isHidden = res[k].isMatch === false;
        continue;
      }
      // Hide this node only if all children (direct & leafs) are not matching
      res[k].isHidden =
        every(nodesInfo[k].childrenValues.map(c => res[c].isMatch === false)) &&
        every(nodesInfo[k].leafsValues.map(c => res[c].isMatch === false)) &&
        res[k].isMatch === false;
    }
    // Show all subtree if it has a matching node
    for (const k of list) {
      if (res[k].isParent && res[k].isHidden === false && res[k].isMatch === true) {
        nodesInfo[k].childrenValues.forEach(c => (res[c].isHidden = false));
        nodesInfo[k].leafsValues.forEach(c => (res[c].isHidden = false));
      }
    }
    return res;
  }, [
    data,
    valueSet,
    searchValue,
    nodesInfo,
    selectedRootParent,
    oneRootAllowed,
    oneRootAllowedHelper,
  ]);
  const showEmptyState = useMemo(() => {
    if (!exists(searchValue)) {
      return false;
    }
    return every(data.map(d => renderInfo[d.value].isHidden === true));
  }, [searchValue, data, renderInfo]);
  const toggleParent = useCallback(
    (value: TreeDataValue) =>
      setOpenState(state => ({
        ...state,
        [value]: !state[value],
      })),
    [setOpenState]
  );
  const onChange = useCallback(
    value => {
      if (!multi || !selectParentValue) {
        onChangeFromProps(value, value);
        return;
      }
      const valueAsSet = new Set(value);
      // If all children as selected, take parent value and remove all children values
      for (const parent of values(nodesInfo)) {
        const allChildrenInValue = every(parent.leafsValues.map(c => valueAsSet.has(c)));
        if (allChildrenInValue && parent.value[0] !== IGNORE_PARENT_VALUE_CHAR) {
          parent.leafsValues.forEach(c => valueAsSet.delete(c));
          valueAsSet.add(parent.value);
        }
      }
      return onChangeFromProps(Array.from(valueAsSet) as any, value);
    },
    [onChangeFromProps, multi, nodesInfo, selectParentValue]
  );
  const onCheckItem = useCallback(
    (value: TreeDataValue) => {
      if (!multi) {
        onChange(value);
        return;
      }
      const meta = renderInfo[value];
      const cachedParent = nodesInfo[value];
      if (meta.isParent) {
        if (meta.selectType === TreeSelectType.NONE || meta.selectType === TreeSelectType.PARTIAL) {
          cachedParent.leafsValues.forEach(valueSet.add, valueSet);
        } else {
          cachedParent.leafsValues.forEach(valueSet.delete, valueSet);
        }
      } else {
        if (valueSet.has(value)) {
          valueSet.delete(value);
        } else {
          valueSet.add(value);
        }
      }
      onChange(Array.from(valueSet));
    },
    [multi, renderInfo, valueSet, nodesInfo]
  );
  const isOpenAll = exists(searchValue);
  const renderItem = (item: TreeData) => {
    const isOpen = isOpenAll || openState[item.value];
    if (renderInfo[item.value].isHidden) {
      return null;
    }
    const showDisabledText =
      oneRootAllowed && renderInfo[item.value].disabled && Boolean(oneRootAllowedHelper);
    let showCheckbox = multi;
    let clickable =
      (!renderInfo[item.value].isParent || selectParentValue || multi) &&
      !renderInfo[item.value].disabled;
    const isSelected = renderInfo[item.value].isSelected;

    return (
      <div
        key={item.value}
        className={classNames(
          classes.Item,
          renderInfo[item.value].isParent && classes.Parent,
          renderInfo[item.value].isParent && isOpen && classes.Open,
          clickable && classes.Clickable,
          renderInfo[item.value].disabled && classes.Disabled
        )}
      >
        <div className={classNames(classes.Head, isSelected && !multi && classes.Selected)}>
          <div
            onClick={renderInfo[item.value].isParent ? () => toggleParent(item.value) : undefined}
            className={classes.ToggleOpenButton}
          >
            {renderInfo[item.value].isParent && <CaretRightSolidIcon className={classes.Icon} />}
          </div>
          {showCheckbox && (
            <Checkbox
              multi
              className={classes.Checkbox}
              onChange={() => onCheckItem(item.value)}
              checked={renderInfo[item.value].selectType === TreeSelectType.FULL || isSelected}
              halfChecked={renderInfo[item.value].selectType === TreeSelectType.PARTIAL}
              disabled={renderInfo[item.value].disabled}
            />
          )}
          <TooltipIfOverflow
            title={showDisabledText ? oneRootAllowedHelper : item.label}
            forceShow={showDisabledText}
          >
            <div
              onClick={clickable ? () => onCheckItem(item.value) : undefined}
              className={classes.Label}
            >
              <BoldTerm term={searchValue}>{item.label}</BoldTerm>
              {item.disabledHelper && (
                <HoverHelperTip title={item.disabledHelper} small className={classes.HelperTip} />
              )}
            </div>
          </TooltipIfOverflow>
        </div>
        <div className={classes.Children}>
          {renderInfo[item.value].isParent && item.children.map(c => renderItem(c))}
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(classes.TreeSelector, className)}>
      {showEmptyState && <div className={classes.EmptyState}>No results</div>}
      {data.map(d => renderItem(d))}
    </div>
  );
};

TreeSelector.defaultProps = {};
