import classes from './metric-advanced-settings.module.scss';
import {BuilderWrapper} from '../../../../../shared/components/general/builder-wrapper/builder-wrapper.component.tsx';
import {
  ArrowTrendDownLightIcon,
  ArrowTrendUpLightIcon,
  Checkbox,
  HashtagLightIcon,
  LabelWrapper,
  PercentageLightIcon,
  Select,
  SwitchActions,
  TextInput,
} from 'ui-components';
import {Controller, useFormContext} from 'react-hook-form';
import {ExtendedParameters} from '../../../../../shared/form/form-layout/extended-parameters/extended-parameters.component.tsx';
import {exists} from 'front-core';
import {useCallback, useMemo} from 'react';
import {
  MetricGranularityOptions,
  MetricType,
  MetricValueType,
} from '../../../../../../objects/models/metric.model.ts';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model.ts';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface Props {
  type: MetricType;
  isOpen?: boolean;
  onOpenChanged: () => void;
  granularityOptions: MetricGranularityOptions;
  isLoadingGranularityOptions: boolean;
  hideGranularityOptions?: boolean;
  className?: string;
}

const PARTIAL_UNIT_OPTIONS = [
  {
    label: 'Days',
    value: 'day',
  },
  {
    label: 'Weeks',
    value: 'week',
  },
  // {
  //   label: 'Months',
  //   value: 'month',
  // },
];

const GRANULARITY_OPTIONS = [
  {
    label: 'Daily',
    value: 'day',
  },
  {
    label: 'Weekly',
    value: 'week',
  },
  {
    label: 'Monthly',
    value: 'month',
  },
];

export const MetricAdvancedSettings = (props: Props) => {
  const {
    type,
    isOpen,
    onOpenChanged,
    granularityOptions,
    isLoadingGranularityOptions,
    hideGranularityOptions,
    className,
  } = props;
  const {
    control,
    setValue,
    watch,
    formState: {errors},
  } = useFormContext();
  const {t} = useTranslation();
  const hasPartialData = exists(watch('partialCount')) || exists(watch('partialUnit'));
  const higherIsBetter = watch('higherIsBetter');
  const valueType = watch('valueType');
  const onClearPartialData = useCallback(() => {
    setValue('partialCount', null);
    setValue('partialUnit', null);
  }, [setValue]);
  const sampledGranularities: string[] = watch('sampledGranularities');
  const onChangeSampledGranularities = useCallback(
    (value: string) => {
      const set = new Set(sampledGranularities || []);
      if (set.has(value)) {
        set.delete(value);
      } else {
        set.add(value);
      }
      setValue('sampledGranularities', Array.from(set));
    },
    [sampledGranularities, setValue]
  );
  const higherIsBetterActions = useMemo(
    () => [
      {
        label: t(TransKeys.METRIC_FORM.INPUTS.HIGHER_IS_BETTER.OPTIONS.TRUE),
        icon: ArrowTrendUpLightIcon,
        isActive: higherIsBetter === true,
        onClick: () => setValue('higherIsBetter', true),
      },
      {
        label: t(TransKeys.METRIC_FORM.INPUTS.HIGHER_IS_BETTER.OPTIONS.FALSE),
        icon: ArrowTrendDownLightIcon,
        isActive: higherIsBetter === false,
        onClick: () => setValue('higherIsBetter', false),
      },
    ],
    [higherIsBetter, setValue, t]
  );
  const valueTypeActions = useMemo(
    () => [
      {
        label: 'Numeric',
        isActive: valueType === MetricValueType.NUMERIC || valueType === undefined,
        icon: HashtagLightIcon,
        onClick: () => setValue('valueType', MetricValueType.NUMERIC),
      },
      {
        label: 'Percentage',
        isActive: valueType === MetricValueType.PERCENTAGE,
        icon: PercentageLightIcon,
        onClick: () => setValue('valueType', MetricValueType.PERCENTAGE),
      },
    ],
    [valueType, setValue]
  );

  return (
    <ExtendedParameters
      label={t(TransKeys.METRIC_FORM.ADVANCED_SETTINGS_TITLE)}
      isOpen={isOpen}
      onOpenChanged={onOpenChanged}
      size={'small'}
      className={className}
    >
      <BuilderWrapper>
        <div className={classes.MetricAdvancedSettings}>
          <LabelWrapper
            label={t(TransKeys.METRIC_FORM.INPUTS.HIGHER_IS_BETTER.TITLE)}
            tooltipContent={t(TransKeys.METRIC_FORM.INPUTS.HIGHER_IS_BETTER.HELPER_TEXT)}
          >
            <SwitchActions actions={higherIsBetterActions} showActionsLabel />
          </LabelWrapper>
          {type === MetricType.RATE && (
            <LabelWrapper
              label={t(TransKeys.METRIC_FORM.INPUTS.VALUE_TYPE.TITLE)}
              helperText={errors?.valueType?.message}
              error={Boolean(errors?.valueType)}
            >
              <SwitchActions actions={valueTypeActions} showActionsLabel />
            </LabelWrapper>
          )}
          <LabelWrapper
            label={t(TransKeys.METRIC_FORM.INPUTS.PARTIAL_DATA.TITLE)}
            tooltipContent={t(TransKeys.METRIC_FORM.INPUTS.PARTIAL_DATA.HELPER_TEXT)}
          >
            <div className={classes.PartialData}>
              <Controller
                render={({field}) => (
                  <TextInput
                    placeholder={'Count'}
                    value={field.value}
                    onChange={field.onChange}
                    type={'number'}
                    min={1}
                    className={classes.CountInput}
                    error={Boolean(errors?.partialCount)}
                  />
                )}
                name={'partialCount'}
                control={control}
              />
              <Controller
                render={({field}) => (
                  <Select
                    placeholder={'Unit'}
                    options={{options: PARTIAL_UNIT_OPTIONS}}
                    value={field.value}
                    onChange={field.onChange}
                    sortValues={false}
                    searchable={false}
                    clearable={false}
                    error={Boolean(errors?.partialUnit)}
                  />
                )}
                name={'partialUnit'}
                control={control}
              />
              {hasPartialData && (
                <div
                  className={classes.ClearPartialDataButton}
                  onClick={() => onClearPartialData()}
                >
                  clear
                </div>
              )}
            </div>
          </LabelWrapper>
          {!hideGranularityOptions && (
            <LabelWrapper
              label={t(TransKeys.METRIC_FORM.INPUTS.SAMPLED_GRANULARITIES.TITLE)}
              tooltipContent={t(TransKeys.METRIC_FORM.INPUTS.SAMPLED_GRANULARITIES.HELPER_TEXT)}
              helperText={errors?.sampledGranularities?.message}
              error={Boolean(errors?.sampledGranularities)}
            >
              <div className={classes.GranularityOptions}>
                {GRANULARITY_OPTIONS.map(option => (
                  <Checkbox
                    multi
                    size={'large'}
                    key={option.value}
                    label={option.label}
                    checked={(sampledGranularities || []).indexOf(option.value) !== -1}
                    onChange={() => onChangeSampledGranularities(option.value)}
                    disabledHelperText={
                      !granularityOptions || isLoadingGranularityOptions
                        ? ''
                        : t(
                            TransKeys.METRIC_FORM.INPUTS.SAMPLED_GRANULARITIES
                              .INVALID_GRANULARITY_HELPER
                          )
                    }
                    disabled={
                      !granularityOptions ||
                      isLoadingGranularityOptions ||
                      (granularityOptions.availableGranularities || []).indexOf(
                        option.value as ModelSeriesGranularity
                      ) === -1
                    }
                  />
                ))}
              </div>
            </LabelWrapper>
          )}
        </div>
      </BuilderWrapper>
    </ExtendedParameters>
  );
};
