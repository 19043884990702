import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import classes from './metric-page.module.scss';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  AppRoutes,
  METRIC_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useParams} from 'react-router';
import PageLayout from '../../../shared/components/layout/page-layout/index';
import {PageHeader} from '../../../shared/components/layout/page-header/page-header.component';
import {useDispatch} from 'react-redux';
import {ModelKey} from '../../../../constants/model-key';
import {
  getMetricPageNetworkRequest,
  getRCAForMetricByDate,
} from '../../../../http/metrics.network-requests';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {MetricPageHeader} from './components/metric-page-header/metric-page-header.component';
import {setMetricOwner} from '../../../../store/metrics/metrics.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  DocumentElementType,
  useRemoteSourceStated,
  RCAFigureViewer,
  RootDocumentViewer,
  MoreIcon,
  EditIcon,
} from 'ui-components';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import {StringParam, useQueryParam} from 'use-query-params';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {exists, HttpClientContext} from 'front-core';
import {RCAPanelFormParameters} from '../../../shared/follow-ups/panels/rca-follow-up-panel/rca-follow-up-panel.component';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {MetricType} from '../../../../objects/models/metric.model';
import {AggregationMode} from '../../../../objects/models/signal.model';
import {AppRCAOverviewViewer} from '../../../shared/core/document-viewer/viewers/app-rca-overview-viewer.component';
import queryString from 'query-string';
import {AppKPITreeViewer} from '../../../shared/core/document-viewer/viewers/app-kpi-tree-viewer/app-kpi-tree-viewer.component';
import {RCADatePicker} from './components/rca-date-picker/rca-date-picker.component';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {MetricSuggestionViewer} from '../../../shared/core/document-viewer/viewers/metric-suggestion-viewer/metric-suggestion-viewer.component';
import {Action, Subject} from '../../../../constants/permissions';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component.tsx';

interface OwnProps {
  [METRIC_ID_PATH_PARAM]: number;
}

type AllProps = OwnProps;

export const METRIC_PAGE_DATE_QUERY_PARAM = 'date';

const getInitialDate = () => {
  const parsed = queryString.parse(window.location.search);
  return parsed[METRIC_PAGE_DATE_QUERY_PARAM];
};

export const MetricPageComponent = (props: AllProps) => {
  const {metricId} = props;
  const http = useContext(HttpClientContext);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {can} = usePermissions();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const initialDateRef = useRef(getInitialDate());
  const tabsRef = useRef<HTMLDivElement>(null);

  const [rcaDate, setRCADate] = useQueryParam<string>(METRIC_PAGE_DATE_QUERY_PARAM, StringParam);
  const {
    source: metric,
    exec: getMetric,
    isLoading: isLoadingMetric,
  } = useRemoteSourceStated({
    networkRequest: getMetricPageNetworkRequest,
  });

  const {
    source: analysisResult,
    exec: getRCA,
    isLoading: isLoadingResult,
  } = useRemoteSourceStated({
    networkRequest: getAnalysisResultNetworkRequest,
  });
  const onChangeResultId = useCallback(resultId => getRCA(resultId), [getRCA]);
  const onRequestAnalysis = useCallback(
    (analysisTypeId: number, parameters: any) => {
      openSecondaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeId,
        parameters,
      });
    },
    [openSecondaryPanel]
  );

  const onOwnerChange = useCallback(
    (userId: number) =>
      dispatch(
        setMetricOwner({
          metricId: metric.id,
          userId,
        })
      ),
    [dispatch, metric]
  );
  const granularity = useMemo(() => {
    const week = metric?.series.find(s => s.granularity === ModelSeriesGranularity.WEEK);
    if (week) {
      return ModelSeriesGranularity.WEEK;
    }
    return metric?.series[0].granularity;
  }, [metric]);
  const onChangeRCADate = useCallback(
    async (date: string) => {
      if (!granularity) {
        return;
      }
      const analysisResultId: any = await http.exec(
        getRCAForMetricByDate({
          metricId,
          date: moment.utc(date, 'YYYY-MM-DD').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          granularity,
        })
      );
      if (!analysisResultId) {
        let analysisMode = undefined;
        if (metric.type === MetricType.USAGE || metric.type === MetricType.REVENUE) {
          analysisMode = AggregationMode.SUM;
        }
        const rcaPanelFormParams: RCAPanelFormParameters = {
          goal: metric.signalId,
          timeAggregation: granularity,
          startDateAnomaly: date,
          useLoopsAnomalyDetectionAlgo: true,
          entity: metric.entity,
          higherIsBetter: metric.higherIsBetter,
          analysisMode: analysisMode,
        };
        openSecondaryPanel(PanelKey.RCA_FOLLOW_UP_PANEL, {
          fromToLabel: moment(date).format(TIME_FORMATS.DEFAULT_DATE_FORMAT),
          title: t(TransKeys.HOMEPAGE.LINE_CHART_WITH_FOLLOW_UP_RCA.PANEL.TITLE, {
            change: 'changed',
            timeGranularity: t(
              TransKeys.GENERAL.LABELS.TIME_GRANULARITY[granularity.toUpperCase()]
            ),
          }),
          notifyAmplitudeSubmitted:
            AmplitudeEvent.CREATE_RCA_FOLLOW_UP_ACTION_FROM_HOMEPAGE_LINE_CHART_CLICKED,
          analysisFormParameters: rcaPanelFormParams,
        });
        return;
      }
      onChangeResultId(analysisResultId);
    },
    [http, metricId, granularity, metric, openSecondaryPanel, onChangeResultId, t]
  );
  const onSampleClicked = useCallback(
    sample =>
      setRCADate(
        moment
          .utc(sample.datetime, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
          .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
        'replaceIn'
      ),
    [setRCADate]
  );
  const documentComponentsMap = useMemo(
    () => ({
      [DocumentElementType.RCA_FIGURE]: props => (
        <RCAFigureViewer
          {...props}
          renderSideMenu={false}
          renderHeaderName={false}
          renderKPITree={true}
          showMetricSuggestion
        />
      ),
      [DocumentElementType.RCA_OVERVIEW_FIGURE]: props => (
        <AppRCAOverviewViewer {...props} onSampleClicked={onSampleClicked} />
      ),
      [DocumentElementType.DOCUMENT]: props => (
        <RootDocumentViewer {...props} className={classes.Document} />
      ),
      [DocumentElementType._KPI_TREE_FIGURE]: props => (
        <AppKPITreeViewer {...props} metricId={metricId} />
      ),
      [DocumentElementType._METRIC_SUGGESTIONS_FIGURE]: props => (
        <MetricSuggestionViewer
          {...props}
          metric={metric}
          onRequestAnalysis={onRequestAnalysis}
          granularity={granularity}
        />
      ),
    }),
    [onSampleClicked, granularity, onRequestAnalysis, metric, metricId]
  );

  useEffect(() => {
    metricId && getMetric(metricId);
  }, [metricId, getMetric]);

  useEffect(() => {
    rcaDate && onChangeRCADate(rcaDate);
  }, [rcaDate, onChangeRCADate]);

  useEffect(() => {
    let requestedDate = rcaDate;
    if (rcaDate) {
      requestedDate = rcaDate;
    } else if (metric && metric.rcaAnalysisResultId) {
      requestedDate = metric.rcaDate;
    } else if (metric && metric.maxSampleDate) {
      requestedDate = metric.maxSampleDate;
    }

    if (requestedDate) {
      // initialLoad.current = true;
      const formattedDate = moment
        .utc(requestedDate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
        .format(TIME_FORMATS.PARAMETER_DATE_FORMAT);
      setRCADate(formattedDate, 'replaceIn');
    }
  }, [metric, setRCADate, rcaDate]);

  const onDocumentResolved = useCallback(() => {
    if (!exists(initialDateRef.current)) {
      return;
    }
    setTimeout(() => {
      tabsRef.current?.scrollIntoView({behavior: 'smooth'});
      initialDateRef.current = null;
    }, 0);
  }, [initialDateRef, tabsRef]);

  useEffect(() => {
    const listener = action => {
      const {modelKey, data} = action.payload;
      if (modelKey === ModelKey.METRIC && data.id === metricId) {
        getMetric(metricId);
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, metricId, getMetric]);

  const hasEditPermission = can(Subject.METRIC, Action.EDIT);
  const onEdit = useCallback(
    () =>
      hasEditPermission &&
      openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
        [METRIC_ID_PATH_PARAM]: metric?.id,
      }),
    [openSecondaryPanel, hasEditPermission, metric?.id]
  );

  const showMetric = useCallback(() => {
    metric &&
      openSecondaryPanel(PanelKey.VIEW_METRIC_PANEL, {
        [METRIC_ID_PATH_PARAM]: metric.id,
      });
  }, [metric, openSecondaryPanel]);

  if (isLoadingMetric || !metric) {
    return <GenericLoading />;
  }

  return (
    <PageLayout.Layout>
      <PageHeader
        title={metric.name}
        crumbs={[
          {
            name: 'KPIs',
            navigateTo: AppRoutes.homepage(),
          },
        ]}
        actions={[
          metric && (
            <RCADatePicker
              selected={rcaDate}
              onChange={date => setRCADate(date, 'replaceIn')}
              maxDate={metric.maxSampleDate}
              granularity={granularity as any}
              isLoading={isLoadingResult}
            />
          ),
          <ModelActionsDropdown
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
            iconSize={'large'}
            actions={[
              {
                key: 'edit',
                icon: EditIcon,
                title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                onClick: onEdit,
              },
            ]}
          />,
        ]}
      />
      <PageLayout.Body noPadding isLoading={false}>
        <div className={classes.MetricPage}>
          <div className={classes.Main}>
            <div className={classes.ContentSection}>
              <MetricPageHeader
                onViewDefinition={showMetric}
                metric={metric}
                onOwnerChange={onOwnerChange}
              />
            </div>
            <div className={classNames(classes.ContentSection, classes.RCAContent)}>
              {isLoadingResult && <GenericLoading />}
              {analysisResult && (
                <AnalysisResults
                  key={analysisResult?.id}
                  analysisResult={analysisResult}
                  isLoading={isLoadingResult}
                  componentsMap={documentComponentsMap}
                  onDocumentResolvedSuccess={onDocumentResolved}
                  scrollable={false}
                  navigationDisabled
                />
              )}
            </div>
          </div>
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

export const MetricPage = props => {
  const {[METRIC_ID_PATH_PARAM]: metricIdFromParams} = useParams<any>();

  return (
    <MetricPageComponent
      {...props}
      key={metricIdFromParams}
      metricId={Number(metricIdFromParams)}
    />
  );
};
