import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';
import {Tooltip} from '@material-ui/core';
import {useMemo} from 'react';
import {calculateUplift} from 'front-core';
import {getPercentageValueFormatter} from '../../../../../../utils/formatters';
import {GrayUpliftChip} from './micro-components/gray-uplift-chip/gray-uplift-chip.component';

export interface VersusWithUpliftDisplayProps {
  value: number;
  vsValue: number;
  valueLabel?: string;
  vsValueLabel?: string;
  isPercentageValue?: boolean;
  className?: string;
}

export const VersusWithUpliftDisplay: React.FC<VersusWithUpliftDisplayProps> = (
  props: VersusWithUpliftDisplayProps
) => {
  const {
    value,
    vsValue,
    valueLabel = 'Value',
    vsValueLabel = 'Expected Value',
    isPercentageValue,
    className,
  } = props;
  const valueFormatter = useMemo(
    () => getPercentageValueFormatter(isPercentageValue),
    [isPercentageValue]
  );
  const uplift = useMemo(() => calculateUplift(value, vsValue), [value, vsValue]);

  return (
    <div className={classNames(classes.VersusWithUpliftDisplay, className)}>
      {valueFormatter(value)}
      <Tooltip
        placement={'top'}
        interactive={false}
        title={
          <div className={classes.VSTooltip}>
            <div className={classes.Line}>
              <span className={classes.Label}>{valueLabel}:</span>
              <span className={classes.Bolder}>{valueFormatter(value)}</span>
            </div>
            <div className={classes.Line}>
              <span className={classes.Label}>{vsValueLabel}:</span>
              <span className={classes.Bolder}>{valueFormatter(vsValue)}</span>
            </div>
          </div>
        }
      >
        <div>
          <GrayUpliftChip value={uplift} />
        </div>
      </Tooltip>
    </div>
  );
};
