import * as React from 'react';
import classNames from 'classnames';
import {DropdownButton} from '../../../../../../simple/controls/dropdown-button/dropdown-button.component';
import {
  ArrowLeftRightToLineLightIcon,
  CloseIcon,
  PercentageLightIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {InputButton} from '../../../../../../forms/related/input-button/input-button.component';
import {exists, withStopPropagation} from 'front-core';
import {DialogLayout} from '../../../../../../simple/generic/dialog-layout/dialog-layout.component';
import {NumberRangeSliderInput} from '../../../../../../forms/inputs/number-range-slider-input/number-range-slider-input.component';
import {PopoverWrapper} from '../../../../../../simple/generic/popover-wrapper/popover-wrapper.component';
import classes from './share-of-users-filter.module.scss';

interface ShareOfUsersValue {
  min: number;
  max: number;
}

interface OwnProps {
  value?: ShareOfUsersValue;
  onChange: (v: ShareOfUsersValue | null) => void;
  minValue?: number;
  maxValue?: number;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const ShareOfUsersFilter: React.FC<AllProps> = (props: AllProps) => {
  const {
    value,
    onChange,
    minValue,
    maxValue,
    placeholder,
    disabled: disabledFromProps,
    className,
  } = props;
  const disabled = disabledFromProps || !exists(minValue) || !exists(maxValue);

  return (
    <PopoverWrapper
      buttonRenderer={props => (
        <DropdownButton
          className={classNames(classes.ShareOfUsersFilter, className)}
          isOpen={props.isOpen}
          onClick={props.onClick}
          placeholder={placeholder}
          capitalize={false}
          disabled={disabled}
          label={value ? ` ${value.min}% - ${value.max}%` : ''}
          icon={PercentageLightIcon}
          actions={
            value && (
              <InputButton icon={CloseIcon} onClick={withStopPropagation(e => onChange(null))} />
            )
          }
        />
      )}
    >
      <DialogLayout className={classes.ShareOfUsersDialog}>
        <NumberRangeSliderInput
          minValue={minValue}
          maxValue={maxValue}
          step={1}
          // @ts-ignore
          onChange={value => onChange(value)}
          // @ts-ignore
          value={value as any}
          // @ts-ignore
          formatLabel={v => `${v}%`}
        />
      </DialogLayout>
    </PopoverWrapper>
  );
};

ShareOfUsersFilter.defaultProps = {
  minValue: 0,
  maxValue: 100,
};
