import * as React from 'react';
import {createContext, useCallback, useContext, useMemo, useState, useEffect} from 'react';
import {
  StageContext,
  StageContextProps,
  StageContextProvider,
} from '../../../core/konva/stage-context.component';
import {
  BaseChartOptions,
  BaseChartProps,
  BaseDataset,
  ChartWithAnnotationOptions,
  ChartWithIncompleteDataOptions,
  ChartWithLabelsOptions,
  ChartWithLabelsXYOptions,
  ChartWithLinesOptions,
  ChartWithMinMaxXYOptions,
} from './chart-data.types';
import {useChartStyle} from './chart.hooks';
import {BASE_STYLE} from './chart.consts';
import {LegendLabel} from './components/chart-legend.component';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from '../../simple/errors/generic.error';
import {debounce, groupBy, values} from 'lodash';
import {getIconForDataset} from './chart.utils';

export interface IChartContext {
  datasets: any[];
  displayedDatasets: any[];
  datasetLabels?: LegendLabel[];
  darkMode?: boolean;
  onLegendClick: (datasetId: number | string, e?: React.MouseEvent<HTMLElement>) => void;
}

export const ChartContext = createContext<IChartContext>({
  datasets: undefined,
  displayedDatasets: undefined,
  datasetLabels: undefined,
  darkMode: undefined,
  onLegendClick: undefined,
});

type AllChartOptions = BaseChartOptions &
  ChartWithLabelsOptions &
  ChartWithLinesOptions &
  ChartWithAnnotationOptions &
  ChartWithMinMaxXYOptions &
  ChartWithLabelsXYOptions &
  ChartWithIncompleteDataOptions;

interface OwnProps extends StageContextProps, BaseChartProps<any, AllChartOptions> {
  datasets: any[];
  transformDS?: (dataset: BaseDataset) => BaseDataset;
  labels?: string[];
  children: any;
  darkMode?: boolean;
}

const DOUBLE_CLICK_EVENT = 2;

const ChartContextComponent: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    datasets: datasets_,
    transformDS,
    options,
    displayedDatasetIds: displayedDatasetIdsFromProps,
    darkMode,
    children,
  } = props;
  const {highlightIds} = options;
  const initialDisplayedDatasetIds = useMemo(
    () => displayedDatasetIdsFromProps || datasets_.map(d => d?.id),
    [displayedDatasetIdsFromProps, datasets_]
  );
  const [displayedDatasetIds, setDisplayedDatasetIds] = useState<string[]>(
    initialDisplayedDatasetIds
  );
  useEffect(() => {
    setDisplayedDatasetIds(initialDisplayedDatasetIds);
  }, [initialDisplayedDatasetIds]);
  const onLegendClick = useCallback(
    debounce((datasetId: number | string, e?: React.MouseEvent<HTMLElement>) => {
      const ds = datasets_.find(ds => ds.id === datasetId);
      let displayedDatasetsSetCandidate;
      if (e?.detail === DOUBLE_CLICK_EVENT) {
        displayedDatasetsSetCandidate = [];
      } else if (displayedDatasetIds.length) {
        displayedDatasetsSetCandidate = displayedDatasetIds;
      } else {
        displayedDatasetsSetCandidate = [];
      }

      const currentDisplayedDatasets = new Set(displayedDatasetsSetCandidate);
      if (currentDisplayedDatasets.has(ds.id)) {
        currentDisplayedDatasets.delete(ds.id);
      } else {
        currentDisplayedDatasets.add(ds.id);
      }
      setDisplayedDatasetIds(
        currentDisplayedDatasets.size === 0
          ? datasets_.map(d => d?.id)
          : Array.from(currentDisplayedDatasets)
      );
    }, 250),
    [setDisplayedDatasetIds, displayedDatasetIds, datasets_]
  );
  const context = useContext(StageContext);
  const {style} = context;
  // Transform datasets
  const datasets: BaseDataset[] = useMemo(() => {
    return datasets_.map((ds, index) => ({
      ...(transformDS ? transformDS(ds) : ds),
      color: ds.color || style.colors[index % style.colors.length],
      highlight: highlightIds?.indexOf(ds.id) > -1,
      index,
    }));
  }, [datasets_, highlightIds, style.colors, transformDS]);
  const displayedDatasets: BaseDataset[] = useMemo(
    () => datasets.filter(ds => displayedDatasetIds?.indexOf(ds.id) > -1),
    [datasets, displayedDatasetIds, displayedDatasetIdsFromProps]
  );

  // Dataset labels for legend
  const datasetLabels: LegendLabel[] = useMemo(
    () =>
      datasets.map((d: any) => ({
        datasetId: d.id,
        name: d.label,
        isActive: displayedDatasets.indexOf(d) > -1,
        color: d.color,
        icon: getIconForDataset(d),
      })),
    [displayedDatasets, datasets]
  );

  const chartContextValue = useMemo(
    () => ({
      displayedDatasets: displayedDatasets.length > 0 ? displayedDatasets : datasets,
      datasets,
      datasetLabels,
      darkMode,
      onLegendClick,
    }),
    [darkMode, datasetLabels, datasets, displayedDatasets]
  );

  return <ChartContext.Provider value={chartContextValue}>{children}</ChartContext.Provider>;
};

export const ChartContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const style = useChartStyle(BASE_STYLE, props.options);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <StageContextProvider TooltipComponent={props.TooltipComponent} style={style}>
        <ChartContextComponent {...props}>{props.children}</ChartContextComponent>
      </StageContextProvider>
    </ErrorBoundary>
  );
};
