import {PeriodAllTimeHighOrLowInsight} from './objects.ts';
import {FC, useMemo} from 'react';
import {ChangeDescription} from '../rca-insights.model.ts';
import {
  SampledModelMeasuredItemCompoundNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

export const PeriodAllTimeHighOrLowModelSampleInsightFormatter: FC<
  PeriodAllTimeHighOrLowInsight
> = (props: PeriodAllTimeHighOrLowInsight) => {
  const {
    sentenceParts,
    sampledModelMeasuredItemCompoundName: compoundName,
    granularityPlural,
  } = props;

  const trendDescription = useMemo(
    () =>
      sentenceParts.valueBasedChangeDescription === ChangeDescription.INCREASE
        ? 'highest'
        : 'lowest',
    [sentenceParts]
  );

  return (
    <SentenceContainer>
      <SentencePart text={'This is the'} />
      <SentencePart bold text={trendDescription} />
      <SampledModelMeasuredItemCompoundNameSentencePart compoundName={compoundName} />
      <SentencePart text={`value over the last 6 ${granularityPlural}.`} />
    </SentenceContainer>
  );
};
