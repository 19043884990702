import classes from './multiple-dates-selector.module.scss';
import {AnalysisSelectorProps} from '../analysis-selector.types.ts';
import {ParameterInputWrapper} from '../../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component.tsx';
import {ItemList} from '../../../../../shared/core/query-builders/components/item-list/item-list.component.tsx';
import {useCallback, useMemo} from 'react';
import {hasErrors} from '../../../../../../utils/general.utils.ts';
import {get, values} from 'lodash';
import {DateIcon, DatePickerInput, useQueryArray} from 'ui-components';
import {TIME_FORMATS} from '../../../../../../constants/time-formats.ts';

interface SchemaKeysMapping {
  parameter_key: string;
}

interface Props extends AnalysisSelectorProps {
  schemaKeysMapping?: SchemaKeysMapping;
  maxDateNow?: boolean;
  className?: string;
}

const maxDate = new Date();

export const MultipleDatesSelector = (props: Props) => {
  const {
    title,
    subTitle,
    helperText,
    value,
    onChange,
    maxDateNow = true,
    schemaKeysMapping,
    errors,
    className,
  } = props;

  const hasError = useMemo(
    () => hasErrors(errors, values(schemaKeysMapping)),
    [errors, schemaKeysMapping]
  );
  const {addElement, removeElement} = useQueryArray(
    get(value, schemaKeysMapping.parameter_key, []),
    data => onChange({[schemaKeysMapping.parameter_key]: data}),
    () => null
  );
  const onChangeDate = useCallback(
    (pValue: any, idx: number) => {
      const existing = get(value, schemaKeysMapping.parameter_key, []) || [];
      const dates = [...existing];
      dates[idx] = pValue;
      onChange({[schemaKeysMapping.parameter_key]: dates});
    },
    [onChange, schemaKeysMapping, value]
  );
  console.log('here', errors);
  return (
    <ParameterInputWrapper
      title={title}
      subTitle={subTitle}
      helperText={helperText}
      error={hasError}
      className={className}
    >
      <ItemList
        items={value[schemaKeysMapping.parameter_key]}
        renderItem={(item, idx) => (
          <DatePickerInput
            className={classes.DatePickerInput}
            placeholder={'Select Date'}
            value={item}
            onChange={value => onChangeDate(value, idx)}
            dateFormat={'DD/MM/YYYY'}
            dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
            maxDate={maxDateNow ? maxDate : undefined}
            error={Boolean(errors[schemaKeysMapping.parameter_key]?.[idx])}
            icon={DateIcon}
            utc
          />
        )}
        min1Item={false}
        withPlaceholder
        onRemoveItem={removeElement}
        onAddItem={addElement}
      />
    </ParameterInputWrapper>
  );
};
