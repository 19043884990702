import {flatten, isFinite, min, range} from 'lodash';
import moment from 'moment';
import {
  ChartMarkArea,
  ChartLine,
  XTicksRangeTimeUnit,
  XYDataset,
  XYValue,
} from './chart-data.types';
import {AXIS_VARIANT} from './chart-inner.types';
import {scaleLinear, scaleTime} from 'd3-scale';
import {calcLeadingZeros} from 'front-core';
import {DEFAULT_INPUT_DATE_FORMAT} from '../../../consts/ui';
import {LoopsIcon} from '../../simple/controls/icons/icons.component';

export const calculateMaxTicks = (
  maxDatasetLength: number,
  xRange: number[],
  timeUnit?: XTicksRangeTimeUnit
) => {
  const options = [maxDatasetLength, xRange.length];
  if (timeUnit) {
    options.push(moment.utc(xRange[1]).diff(moment.utc(xRange[0]), timeUnit) + 1);
  }
  return Math.max(...options);
};

/**
 * This function creates a list of values from startRange to endRange matching the maxTicks
 * startRange - the start of the range (date in MS)
 * endRange - the end of the range (date in MS)
 * maxTicks - the upper limit for the returned size list
 * dateRange - if true - the range is treated as time
 */
export const createRangeTicks = (options: {
  startRange: number;
  endRange: number;
  maxTicks: number;
  dateRange?: boolean;
  timeUnit?: 'week' | 'month';
}): number[] => {
  const {startRange, endRange, maxTicks, dateRange = false, timeUnit} = options;
  if (!isFinite(startRange) && !isFinite(endRange)) {
    return [];
  }
  if (!isFinite(endRange)) {
    return [startRange];
  }
  if (startRange === endRange) {
    return [startRange];
  }
  let actualMaxTicks = maxTicks;
  // Handling date range!
  if (dateRange) {
    const startRangeMoment = moment.utc(startRange);
    const endRangeMoment = moment.utc(endRange);
    const isSameDayInMonth = startRangeMoment.date() === endRangeMoment.date();
    // +1 to include the ending label
    const isMonthJumps = endRangeMoment.diff(startRangeMoment, 'month') + 1 === maxTicks;
    // If the start and the end date have the same date (day in the month) and the difference between
    // the two in months is equal to the maxTicks, we can assume that we should return labels that
    // jump in a full month
    if (isSameDayInMonth && isMonthJumps) {
      return range(0, maxTicks).map(i => startRangeMoment.clone().add(i, 'month').valueOf());
    }
    // Making sure that the dates are in utc global time
    const startRange_ = startRangeMoment.valueOf();
    const endRange_ = endRangeMoment.valueOf();
    const totalRangeMS = endRange_ - startRange_;
    // Scale function will return a value between [0-1] for a given date within start and end range
    const scale = scaleTime().domain([startRange_, endRange_]);
    // Creating ticks
    const arr = [];
    if (timeUnit === 'week') {
      actualMaxTicks = totalRangeMS / moment.duration(1, timeUnit).asMilliseconds() + 1;
    }
    if (actualMaxTicks === 1) {
      return [startRange];
    }
    const step = Math.floor(totalRangeMS / (actualMaxTicks - 1));
    for (let i = 0; i < actualMaxTicks; i++) {
      let percent = Math.min(scale(startRange_ + i * step), 1);
      if (percent > 0.9999999) {
        percent = 1;
      }
      arr.push(startRange_ + percent * totalRangeMS);
    }
    return arr;
  }
  // Generating simple linear ticks
  return scaleLinear().domain([startRange, endRange]).nice(actualMaxTicks).ticks(actualMaxTicks);
};

/*
 * This function calculates the best suited
 * fraction digit for the ticks of a Y Axis
 * It assumes linearity as it is consumes the
 * results of createRangeTicks
 * */
export const calculateFractionDigitForYTicks = (yTicks: number[], maxFractionDigits: number) => {
  let fractionDigits = 2;
  if (!yTicks || !yTicks.length) {
    return fractionDigits;
  }
  if (yTicks.length >= 2) {
    // Calculates the "jump" / difference between 2 ticks
    const diffBetween2Ticks = Math.abs(yTicks[1] - yTicks[0]);
    // if bigger than 1 we don't need high precision, we will use the default by returning undefined
    // since this function is used to pass parameter to number2k
    if (diffBetween2Ticks > 1) {
      return;
    }
    const numberOfZerosAfterDecimalPoint = calcLeadingZeros(diffBetween2Ticks);
    if (numberOfZerosAfterDecimalPoint + 1 >= 0) {
      fractionDigits = min([numberOfZerosAfterDecimalPoint + 1, maxFractionDigits]);
    }
  }

  return fractionDigits;
};

/**
 * Given array and a comparator returns the min or max value.
 * @param arr
 * @param comparator
 * @param min
 */
export const minMaxBy = <T>(arr: T[], comparator: (a: T, b: T) => number, min = true) => {
  let minItem = arr[0];
  for (const item of arr) {
    let isMinGreater = comparator(minItem, item) < 0;
    if (min === false) {
      isMinGreater = !isMinGreater;
    }
    if (!isMinGreater) {
      minItem = item;
    }
  }

  return minItem;
};

export const minBy = <T>(arr: T[], comparator: (a, b) => number) => minMaxBy(arr, comparator, true);
export const maxBy = <T>(arr: T[], comparator: (a, b) => number) =>
  minMaxBy(arr, comparator, false);

export const dateComparator = (a, b) => {
  if (a > b) return 1;
  else if (a < b) return -1;
  else return 0;
};

export const pixelInRangeFactory = (
  range: [number, number],
  pixelRange: number,
  opposite: boolean = false
) => {
  const totalRange = Math.abs(range[1] - range[0]);
  return (v: number) => {
    if (totalRange === 0) {
      return 0;
    }
    const pixel = ((v - range[0]) / totalRange) * pixelRange;
    return opposite ? pixelRange - pixel : pixel;
  };
};

export const toFixedNumber = (num: number, fractionDigits: number = 2) =>
  Number(num.toFixed(fractionDigits));

// Helper method to get all the dataset's values
export const getDatasetValues = (
  datasets: XYDataset[],
  errorBar: boolean,
  lines?: Array<ChartLine>,
  areas?: Array<ChartMarkArea>
): {
  xValues: number[];
  primaryYValues: number[];
  secondaryYValues: number[];
} => {
  const getYValue = (v: XYValue): number | [number, number, number] =>
    errorBar ? [v.y, v.upper, v.lower] : v.y;

  let xValues = [];
  let primaryYValues = [];
  let secondaryYValues = [];
  datasets.forEach(ds => {
    if (ds?.yAxis === AXIS_VARIANT.SECONDARY) {
      secondaryYValues = [...secondaryYValues, ...flatten(ds?.data.map(getYValue))];
    } else {
      primaryYValues = [...primaryYValues, ...flatten(ds?.data.map(getYValue))];
    }
    xValues = [
      ...xValues,
      ...flatten(ds?.data.map(v => v.x)),
      ...flatten((areas || []).map(a => [a.from, a.to])),
    ];
  });

  if (lines) {
    lines.forEach(line => {
      if (line?.direction === 'horizontal') {
        primaryYValues = [...primaryYValues, line?.position];
      }
    });
  }

  return {
    xValues,
    primaryYValues,
    secondaryYValues,
  };
};

/*
 * Helper method to format unix timestamp to date
 * string with an additional upper bound - used
 * for x labels and tooltip in the line chart
 * */
export const formatXDateLabelWithRangeTimeUnit = ({
  value,
  timeUnit,
  dateFormat = 'DD-MMM',
  inputDateFormat,
}: {
  value: number | string;
  timeUnit?: XTicksRangeTimeUnit;
  dateFormat?: string;
  inputDateFormat?: string;
}) => {
  if (timeUnit === 'week') {
    return `${moment.utc(value, inputDateFormat).format(dateFormat)} - ${moment
      .utc(value, inputDateFormat)
      .add(6, 'd')
      .format(dateFormat)}`;
  }
  if (timeUnit === 'month') {
    return `${moment.utc(value, inputDateFormat).format(dateFormat)} - ${moment
      .utc(value, inputDateFormat)
      .add(1, 'M')
      .subtract(1, 'd')
      .format(dateFormat)}`;
  }
  return moment.utc(value).format(dateFormat);
};

// ChartTooltip utils
export const getLeftPosition = (
  element: HTMLDivElement,
  x: number,
  totalWidth: number,
  offset: number = 0,
  defaultWidth = 0
) => {
  const rect = element?.getBoundingClientRect();
  const width = rect?.width || defaultWidth;
  const endingX = x + width;
  if (endingX >= totalWidth) {
    return x - width - offset;
  } else {
    return x + offset;
  }
};

export const getTopPosition = (
  element: HTMLDivElement,
  y: number,
  totalHeight: number,
  offset: number = 0,
  defaultHeight = 0
) => {
  const rect = element?.getBoundingClientRect();
  const height = rect?.height || defaultHeight;
  const endingY = y + height;
  if (endingY >= totalHeight) {
    return y - height - offset;
  } else {
    return y + offset;
  }
};

export const transformXToNumeric = (
  isDateLabels: boolean,
  dateInputFormat = DEFAULT_INPUT_DATE_FORMAT,
  value: string | number
) => (isDateLabels ? moment.utc(value, dateInputFormat).valueOf() : Number(value));

export const getIconForDataset = (dataset: any) => {
  if (dataset.isPredicted) {
    return LoopsIcon;
  }
  return null;
};

export const findClosestPoint = (cursorX: number, cursorY: number, data: any[]) => {
  let minDistance = Number.POSITIVE_INFINITY;
  let closestPointX = null;
  let closestPoint = null;
  const relevantPoints = [];
  const focusableData = data.map(points => points.filter(p => p.focusable !== false));

  // find relevant x
  for (const pointsArray of focusableData) {
    for (const point of pointsArray) {
      const xDistance = Math.abs(cursorX - point.x);
      if (xDistance < minDistance) {
        minDistance = xDistance;
        closestPointX = point.x;
      }
    }
  }

  // find all relevant points for x
  for (const pointsArray of focusableData) {
    for (const point of pointsArray) {
      if (point.x === closestPointX) {
        relevantPoints.push(point);
      }
    }
  }

  // find closest point to Y
  minDistance = Number.POSITIVE_INFINITY;
  for (const point of relevantPoints) {
    const yDistance = Math.abs(cursorY - point.y);
    if (yDistance < minDistance) {
      minDistance = yDistance;
      closestPoint = point;
    }
  }

  return closestPoint;
};
