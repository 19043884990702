import * as React from 'react';
import classNames from 'classnames';
import classes from './segmentation-modal-header.module.scss';
import {Tooltip} from '@material-ui/core';
import TransKeys from 'translations';
import {
  ArrowRightIcon,
  ArrowRightLongLightIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {Select} from '../../../../../../forms/inputs/select/select.component';
import {HoverHelperTip} from '../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';

export interface SegmentationModalOption {
  label: string;
  value: string | number;
}

export enum SegmentationModalMode {
  DEFAULT = 'DEFAULT',
  COMPARE = 'COMPARE',
  DEEP_DIVE = 'DEEP_DIVE',
}

interface OwnProps {
  title: string;
  subTitle?: string;
  onBack?: () => void;
  mode?: SegmentationModalMode;
  // groups
  selectedGroup: any;
  groupOptions: SegmentationModalOption[];
  onSelectGroup: (value: any) => void;
  // segment
  selectedSegment?: string | number;
  segmentOptions?: SegmentationModalOption[];
  onSelectSegment?: (value: string | number) => void;
  segmentHelperText?: string;
  // steps
  selectedStep?: string | number;
  stepsOptions?: SegmentationModalOption[];
  onSelectStep?: (value: string | number) => void;
  stepsPrefix?: string;
  // other
  compareSegmentsSuffix?: string;
  className?: string;
}

type AllProps = OwnProps;

const MINIMUM_ITEMS_FOR_SEARCH = 6;

export const SegmentationModalHeader: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    selectedGroup,
    groupOptions,
    onSelectGroup,
    selectedSegment,
    segmentOptions,
    onSelectSegment,
    segmentHelperText,
    selectedStep,
    stepsOptions,
    onSelectStep,
    stepsPrefix,
    onBack,
    mode,
    compareSegmentsSuffix,
    className,
  } = props;
  const {t} = useDocumentTranslation();

  return (
    <div className={classNames(classes.SegmentationModalHeader, className)}>
      <div className={classes.Title}>
        <div
          onClick={() => onBack()}
          className={classNames(
            classes.Back,
            mode !== SegmentationModalMode.DEFAULT && classes.Show
          )}
        >
          <Tooltip title={t(TransKeys.GENERAL.ACTIONS.BACK)} placement={'top'} interactive={false}>
            <ArrowRightIcon className={classes.Icon} />
          </Tooltip>
        </div>
        <div className={classes.TitleText}>{title}</div>
        <Select
          onChange={onSelectGroup}
          options={{options: groupOptions}}
          value={selectedGroup}
          clearable={false}
          className={classes.SelectItem}
          dropdownButtonClassName={classes.SelectItemButton}
          searchable={groupOptions.length > MINIMUM_ITEMS_FOR_SEARCH}
          capitalize={false}
          disabled={mode !== SegmentationModalMode.DEFAULT}
        />
        {mode === SegmentationModalMode.DEFAULT && stepsOptions && (
          <>
            {stepsPrefix && <div className={classes.TitleText}>{stepsPrefix}</div>}
            <Select
              dropdownButtonClassName={classes.SelectItemButton}
              value={selectedStep}
              onChange={onSelectStep as any}
              searchable={stepsOptions.length > MINIMUM_ITEMS_FOR_SEARCH}
              sortValues={false}
              clearable={false}
              capitalize={false}
              options={{
                options: stepsOptions,
              }}
            />
          </>
        )}
        {mode === SegmentationModalMode.DEEP_DIVE && segmentOptions && (
          <>
            <div className={classes.TitleText}>
              <ArrowRightLongLightIcon />
            </div>
            <Select
              dropdownButtonClassName={classes.SelectItemButton}
              value={selectedSegment}
              onChange={onSelectSegment}
              searchable={segmentOptions.length > MINIMUM_ITEMS_FOR_SEARCH}
              sortValues={false}
              clearable={false}
              capitalize={false}
              options={{
                options: segmentOptions,
              }}
            />
            {segmentHelperText && <HoverHelperTip title={segmentHelperText} />}
          </>
        )}
        {mode === SegmentationModalMode.COMPARE && compareSegmentsSuffix && (
          <div className={classes.TitleText}>{compareSegmentsSuffix}</div>
        )}
      </div>
      {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
    </div>
  );
};

SegmentationModalHeader.defaultProps = {
  mode: SegmentationModalMode.DEFAULT,
};
