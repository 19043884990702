import {
  RCAInsightSection,
  RCAInsightSentence,
  RCAInsightsSectionKeys,
} from '../../../rca-insights.model.ts';
import {RCA_INSIGHTS_SENTENCE_FORMATTERS_MAPPING} from '../../../rca-insights.config.ts';
import {
  ExtendedRCAInsightSection,
  ExtendedRCAInsightSentence,
} from './rca-explainers-sentences.component.tsx';
import {exists} from 'front-core';

export const RCASegmentsInsightSubtitleMapping = {
  [RCAInsightsSectionKeys.POPULATION_SIZE_EXPLAINER]: 'Population Size',
  [RCAInsightsSectionKeys.POTENTIAL_DRIVERS]: 'Segments',
  [RCAInsightsSectionKeys.INTERESTING_SEGMENTS_PERFORMANCE_VS_PRIOR_WEEK]: 'Segments',
};

export const extendSentences = (sentences: RCAInsightSentence[]): ExtendedRCAInsightSentence[] => {
  return sentences.reduce(
    (sentencesAcc: ExtendedRCAInsightSentence[], sentence: RCAInsightSentence) => {
      const Formatter = RCA_INSIGHTS_SENTENCE_FORMATTERS_MAPPING[sentence.key];
      if (Formatter) {
        sentencesAcc.push({...sentence, formatter: Formatter});
      }
      return sentencesAcc;
    },
    []
  );
};

export interface InsightSectionToExtend {
  section: RCAInsightSection;
  titleId: string;
}

export const extendSections = (
  sections: InsightSectionToExtend[],
  enableScrollToElements: boolean
): ExtendedRCAInsightSection[] =>
  (sections || []).reduce(
    (sectionsAcc: ExtendedRCAInsightSection[], sectionInsight: InsightSectionToExtend) => {
      const section = sectionInsight?.section;
      if (exists(section) && exists(section.sentences) && section.sentences.length > 0) {
        const sentences = extendSentences(section.sentences);
        if (sentences.length > 0) {
          sectionsAcc.push({
            ...section,
            sentences,
            titleId: enableScrollToElements ? sectionInsight.titleId : null,
          });
        }
      }
      return sectionsAcc;
    },
    []
  );
