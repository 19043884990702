import * as React from 'react';
import classNames from 'classnames';
import {useMemo} from 'react';
import classes from './team-icon-chip.module.scss';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  name: string;
  color: string;
  className?: string;
  showTooltip?: boolean;
  size?: 'small' | 'medium';
}

type AllProps = OwnProps;

export const TeamIconChip: React.FC<AllProps> = (props: AllProps) => {
  const {name, color, size, showTooltip, className} = props;

  const renderName = useMemo(() => name.charAt(0), [name]);

  return (
    <Tooltip title={showTooltip ? name : ''} interactive={false} placement={'top'}>
      <div
        className={classNames(classes.TeamIconChip, classes[size], className)}
        style={{backgroundColor: color}}
      >
        {renderName}
      </div>
    </Tooltip>
  );
};

TeamIconChip.defaultProps = {
  size: 'small',
  showTooltip: false,
};
