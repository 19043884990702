import * as React from 'react';
import classNames from 'classnames';
import {DataColumnProps} from '../../smart-table.types';

interface OwnProps extends DataColumnProps<void> {}

type AllProps = OwnProps;

export const RenderDataColumn: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;

  return <div className={classNames(className)}>{column.render(data)}</div>;
};
