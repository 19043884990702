import {BaseSegmentOfRCASegmentation, ExplainabilityLabel, InterestType} from '../../../../types';
import {calculateUplift, safeDivision} from 'front-core';
import {RCASegmentationViewMode} from '../rca-figure/rca-figure-viewer.types';
import {ExtendedSegmentData, UnifiedRCASegmentationItem} from './rca-segmentation-extended.types';

const RELEVANT_LABELS = [ExplainabilityLabel.MEDIUM, ExplainabilityLabel.HIGH];

export const extendSegments = <T extends BaseSegmentOfRCASegmentation>(
  segments: T[],
  totalEntities: number,
  expectedTotalEntities: number
): Array<T & ExtendedSegmentData> => {
  return segments.map(s => {
    const newSegment = {
      ...s,
      share: safeDivision(s.count, totalEntities),
      expectedShare: safeDivision(s.expectedCount, expectedTotalEntities),
      uplift: calculateUplift(s.value, s.expectedValue),
    };
    let interestTypes = s.interestTypes || [];
    const shareUplift = calculateUplift(newSegment.share, newSegment.expectedShare, true);
    const countUplift = calculateUplift(newSegment.count, newSegment.expectedCount, true);
    const isOppositeDirection = shareUplift * countUplift < 0;

    if (
      interestTypes.includes(InterestType.SHARE) &&
      interestTypes.includes(InterestType.COUNT) &&
      isOppositeDirection === false
    ) {
      if (shareUplift > countUplift) {
        interestTypes = interestTypes.filter(i => i !== InterestType.COUNT);
      } else {
        interestTypes = interestTypes.filter(i => i !== InterestType.SHARE);
      }
    }

    return {
      ...newSegment,
      interestTypes,
    };
  });
};

interface ExplainableSegments {
  [RCASegmentationViewMode.EXPLAIN]: UnifiedRCASegmentationItem[];
  [RCASegmentationViewMode.CHANGE]: UnifiedRCASegmentationItem[];
}

export const getExplainableSegments = (
  segments: UnifiedRCASegmentationItem[]
): ExplainableSegments => {
  return {
    [RCASegmentationViewMode.EXPLAIN]: segments.filter(
      s => s.isSignificant && RELEVANT_LABELS.includes(s.explainabilityLabel)
    ),
    [RCASegmentationViewMode.CHANGE]: segments.filter(
      s => s.isInterestSignificant && s.isInteresting
    ),
  };
};
