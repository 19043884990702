import * as React from 'react';
import {useCallback, useState} from 'react';
import classNames from 'classnames';
import classes from './fancy-block.module.scss';
import {ChevronDownRegularIcon} from '../../controls/icons/icons.component';

interface OwnProps {
  title?: string;
  subTitle?: string;
  children: any;
  addPadding?: boolean;
  icon?: any;
  collapsible?: boolean;
  collapseInitial?: boolean;
  renderRight?: JSX.Element | string;
  className?: string;
  variant?: 'default' | 'highlighted';
}

type AllProps = OwnProps;

export const FancyBlock: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    subTitle,
    icon: Icon,
    variant = 'default',
    renderRight,
    children,
    addPadding,
    collapsible,
    collapseInitial,
    className,
  } = props;
  const [collapse, setCollapse] = useState(collapseInitial);
  const handleCollapse = useCallback(
    () => collapsible && setCollapse(prev => !prev),
    [collapsible, setCollapse]
  );
  const hasHeader = title || subTitle || Icon || renderRight;

  return (
    <div
      className={classNames(
        classes.FancyBlock,
        addPadding && classes.WithPadding,
        collapsible && classes.Collapsible,
        collapsible && !collapse && classes.Expand,
        collapsible && collapse && classes.Collapse,
        variant === 'highlighted' && classes.Highlighted,
        className
      )}
    >
      {hasHeader && (
        <div
          className={classNames(classes.TitleWrapper, collapsible && classes.Collapsible)}
          onClick={handleCollapse}
        >
          <div className={classes.Left}>
            <div className={classes.Title}>
              {Icon && <Icon className={classes.TitleIcon} />}
              {title}
            </div>
            {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
          </div>
          <div className={classes.Right}>{renderRight}</div>
          {collapsible && (
            <div onClick={handleCollapse}>
              <ChevronDownRegularIcon
                className={classNames(
                  classes.Right,
                  classes.Chevron,
                  !collapse && classes.Expand,
                  collapse && classes.Collapse
                )}
                onClick={handleCollapse}
              />
            </div>
          )}
        </div>
      )}
      <div className={classNames(classes.Content, hasHeader && classes.WithHeader)}>{children}</div>
    </div>
  );
};
