import * as React from 'react';
import {FilterComponentProps} from './filter-component.types';
import humanizeDuration from 'humanize-duration';
import {NumberRangeFilter} from './number-range-filter.component';
import {TableFilterType} from '../../smart-table.types';

interface OwnProps extends FilterComponentProps {
  dataKey: string;
  unit?: string;
  className?: string;
}

type AllProps = OwnProps;

const DURATION_OPTIONS = {
  largest: 1,
};

export const DurationRangeFilter: React.FC<AllProps> = (props: AllProps) => {
  return (
    <NumberRangeFilter
      {...props}
      formatLabel={v => humanizeDuration(v * 1000, DURATION_OPTIONS)}
      filterType={TableFilterType.DURATION}
    />
  );
};

DurationRangeFilter.defaultProps = {};
