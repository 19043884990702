import classes from './rca-explainers-sentences.module.scss';
import {RCAInsightSection, RCAInsightSentence} from '../../../rca-insights.model.ts';
import * as React from 'react';
import {useCallback} from 'react';
import {exists} from 'front-core';
import classNames from 'classnames';
import {RCASegmentsInsightSubtitleMapping} from './rca-explainers-sentences.utils.ts';

export interface ExtendedRCAInsightSentence extends RCAInsightSentence {
  formatter?: any;
}

export interface ExtendedRCAInsightSection extends RCAInsightSection {
  titleId?: string;
  subtitle?: string;
  sentences: ExtendedRCAInsightSentence[];
}

interface RCAExplainersSentencesProps {
  sections: ExtendedRCAInsightSection[];
  messageElement?: React.ReactNode;
}

type AllProps = RCAExplainersSentencesProps;

export const RCAExplainersSentencesComponent = (props: AllProps) => {
  const {sections, messageElement = null} = props;
  const onViewOnPage = useCallback((titleId: string) => {
    const elem = document.getElementById(titleId);
    if (elem) {
      elem.scrollIntoView({behavior: 'smooth'});
    }
  }, []);

  if (!sections || sections.length === 0) {
    return null;
  }

  return (
    <>
      {messageElement}
      <div
        className={classNames(
          classes.SectionContainer,
          sections.length > 1 && classes.MultipleSections
        )}
      >
        {sections.map((insight, idx) => (
          <React.Fragment key={`${insight.key}_${idx}`}>
            {sections.length > 1 && exists(RCASegmentsInsightSubtitleMapping[insight.key]) && (
              <div className={classes.SectionSubtitle}>
                {RCASegmentsInsightSubtitleMapping[insight.key]}
              </div>
            )}
            <div className={classes.SentencesList}>
              {insight.sentences.map((sentence, idx) => (
                <React.Fragment key={`${insight.key.toLowerCase()}_${sentence.key}_${idx}`}>
                  <span className={classes.Bullet} />
                  <sentence.formatter
                    sentenceParts={sentence.sentenceParts}
                    formattedSentenceParts={sentence.formattedSentenceParts}
                  />
                </React.Fragment>
              ))}
            </div>
            {exists(insight.titleId) && (
              <span onClick={() => onViewOnPage(insight.titleId)} className={classes.ScrollTo}>
                View on page →
              </span>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
