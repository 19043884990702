import {ColumnType, SmartTableFigureColumn} from '../../../../types';
import {max, min} from 'lodash';
import {ColumnStates, PercentageAbsColumnState, SwitchColumnState} from '../smart-table.types';
import moment from 'moment';
import {DEFAULT_INPUT_DATE_FORMAT} from '../../../../../../../consts/ui';
import {exists} from 'front-core';
import {getDataValueKey} from '../smart-table.utils';

/**
 * This function is responsible for preparing the data for smart table viewer.
 * NOTE! This function mutates the data!!
 *
 * 1. Add computed data columns
 * 2. Add metadata for columns so they wont need to perform logic in components.
 *
 * @param data - all the data
 * @param columns - all columns
 */
export function prepareData(
  data: any[],
  columns: SmartTableFigureColumn<any>[]
): {data: any[]; columns: SmartTableFigureColumn<any>[]} {
  for (const [index, column] of columns.entries()) {
    if (!column.key) {
      column.key = `column_${index}`;
    }
    if (!column.options) {
      column.options = {};
    }
    if (data.length <= 1) {
      column.options.sortable = false;
    }
    if (!column.typeOptions) {
      column.typeOptions = {};
    }
    if (column.title === null || column.title === undefined) {
      column.title = column.key;
    }
    switch (column.type) {
      case ColumnType.PROGRESS: {
        const totalKey = column.typeOptions.totalDataKey;
        const valueKey = getDataValueKey(column, 'valueDataKey');
        // Handles the case where the value data keys is set as the column key
        column.typeOptions.valueDataKey = valueKey;
        column.key = `${valueKey}_progress`;

        for (const d of data) {
          d[column.key] = Number(((d[valueKey] / d[totalKey]) * 100).toFixed(2));
        }
        break;
      }
      case ColumnType.CONFIDENCE_INTERVAL: {
        const lowerKey = column.typeOptions.lowerDataKey || column.key;
        const upperKey = column.typeOptions.upperDataKey || column.key;
        const minValue = min(data.map(d => d[lowerKey]));
        const maxValue = max(data.map(d => d[upperKey]));
        column.metadata = {
          minValue,
          maxValue,
        };
        break;
      }
      case ColumnType.GRADIENT: {
        const gradientData = data.map(d => d[column.key]);
        const minValue = min(gradientData);
        const maxValue = max(gradientData);
        column.metadata = {
          minValue,
          maxValue,
        };
        break;
      }
      case ColumnType.DATE: {
        const inputDateFormat = column.typeOptions.dateInputFormat || DEFAULT_INPUT_DATE_FORMAT;
        const endDateDataKey = column.typeOptions.endDateDataKey;
        for (const d of data) {
          if (exists(d[column.key])) {
            d[column.key] = moment(d[column.key], inputDateFormat).utc().toDate();
          }
          if (endDateDataKey && d[endDateDataKey]) {
            d[endDateDataKey] = moment(d[endDateDataKey], inputDateFormat).utc().toDate();
          }
        }
        break;
      }
      case ColumnType.SWITCH_COLUMN: {
        prepareData(data, column.typeOptions.columns || []);
        break;
      }
    }
  }
  return {data, columns};
}

export const createInitialColumnsState = (columns: SmartTableFigureColumn<any>[]): ColumnStates => {
  const columnStates: ColumnStates = {};

  for (const column of columns) {
    const state: any = {};

    switch (column.type) {
      case ColumnType.SWITCH_COLUMN:
        (state as SwitchColumnState).switchColumnIndex = 0;
        break;
      case ColumnType.SHARE_OF_TOTAL:
        (state as PercentageAbsColumnState).columnMode = 'primary';
        break;
    }

    columnStates[column.key] = state;
  }

  return columnStates;
};
