import * as React from 'react';
import {useMemo} from 'react';
import {HorizontalGridLayoutInjectedProps} from '../../../layouts/grid-layout.component';
import {EnhancedGroup} from '../../../../../../core/konva/components';
import {ChartAnnotation} from '../../../components/chart-annotation.component';
import {DEFAULT_DATE_FORMAT} from '../../../../../../consts/ui';
import {
  ChartAnnotation as ChartAnnotationModel,
  ChartWithAnnotationOptions,
} from '../../../chart-data.types';
import {groupBy, values} from 'lodash';

interface OwnProps
  extends ChartWithAnnotationOptions,
    Omit<HorizontalGridLayoutInjectedProps, 'labels'> {
  dateFormat: string;
  onAnnotationClicked?: (annotation: ChartAnnotationModel[]) => void;
}

type AllProps = OwnProps;

export const AnnotationsContainer: React.FC<AllProps> = (props: AllProps) => {
  const {
    width,
    height,
    x,
    y,
    dateFormat,
    annotations: annotations_,
    xRange,
    onAnnotationClicked,
  } = props;
  const dateRangeMS = useMemo(() => Math.abs(xRange[1] - xRange[0]), [xRange]);
  const annotations = useMemo(() => {
    const annos = [];
    for (const a of annotations_) {
      if (typeof a.timestamp !== 'number') {
        continue;
      }
      const diffMS = a.timestamp - xRange[0];
      // If the annotation is bellow the range don't show it
      if (diffMS < 0) {
        continue;
      }
      // If the annotation is above the range don't show it
      if (a.timestamp - xRange[1] > 0) {
        continue;
      }

      // handles the case we have only one date and therefore dateRangeMS === 0.
      const x = dateRangeMS === 0 ? diffMS * width : (diffMS / dateRangeMS) * width;
      annos.push({
        ...a,
        x,
      });
    }
    const grouped = groupBy(annos, 'timestamp');
    return values(grouped).map(annos => ({
      timestamp: annos[0].timestamp,
      x: annos[0].x,
      annotations: annos,
    }));
  }, [annotations_, xRange, dateRangeMS, width]);
  /**
   * Render
   */
  return (
    <EnhancedGroup width={width} height={height} x={x} y={y}>
      {annotations.map((a, idx) => (
        <ChartAnnotation
          dateFormat={dateFormat}
          key={idx}
          height={height}
          onAnnotationClicked={onAnnotationClicked ? a => onAnnotationClicked(a) : undefined}
          annotations={a.annotations}
          timestamp={a.timestamp}
          x={a.x}
        />
      ))}
    </EnhancedGroup>
  );
};

AnnotationsContainer.defaultProps = {
  annotations: [],
  dateFormat: DEFAULT_DATE_FORMAT,
};
