import {RCAInsights} from '../../../../../../../../../insights/rca/rca-insights.model.ts';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import sharedClasses from '../../rca-executive-summery.module.scss';
import classes from '../../rca-executive-summery.module.scss';
import TransKeys from 'translations';
import {
  SentenceContainer,
  SentencePart,
} from '../../../../../../../../../insights/components/shared-sentence-parts/shared-sentence-parts.components';
import {NoExplainersMsg} from '../no-explainers-msg/no-explainers-msg.component';
import {RCAExplainersSentencesComponent} from '../../../../../../../../../insights/rca/segment-sentences/components/rca-explainers-sentences/rca-explainers-sentences.component.tsx';
import {ChangeDescriptionAlias} from '../rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component';
import {RCAElementType, RCAMode} from 'ui-components';
import classNames from 'classnames';
import * as React from 'react';
import {extendSections} from '../../../../../../../../../insights/rca/segment-sentences/components/rca-explainers-sentences/rca-explainers-sentences.utils.ts';

interface OwnProps extends Omit<RCAInsights, 'sampledModel'> {
  changeDescriptionAlias: ChangeDescriptionAlias;
  mode: RCAMode;
  enableScrollToElements: boolean;
  granularity: string;
}

type AllProps = OwnProps;

export const RCAInsightsSection = (props: AllProps) => {
  const {
    potentialDrivers,
    populationSizeExplainer,
    interestingSegmentsPerformanceVsPriorWeek,
    changeDescriptionAlias,
    mode,
    enableScrollToElements,
    granularity,
  } = props;
  const {t} = useTranslation();
  const populationExplainerSection = useMemo(
    () =>
      extendSections(
        [{section: populationSizeExplainer, titleId: RCAElementType.POPULATION_EXPLAINER}],
        enableScrollToElements
      ),
    [populationSizeExplainer, enableScrollToElements]
  );

  const explainableSegmentsSection = useMemo(
    () =>
      extendSections(
        [{section: potentialDrivers, titleId: RCAElementType.SEGMENTATION}],
        enableScrollToElements
      ),
    [potentialDrivers, enableScrollToElements]
  );

  const potentialDriversMergedSections = useMemo(() => {
    if (interestingSegmentsPerformanceVsPriorWeek.key) {
      return [];
    }
    return [...populationExplainerSection, ...explainableSegmentsSection];
  }, [
    interestingSegmentsPerformanceVsPriorWeek,
    populationExplainerSection,
    explainableSegmentsSection,
  ]);

  const interestingSegmentsSections = useMemo(
    () =>
      extendSections(
        [
          {
            section: interestingSegmentsPerformanceVsPriorWeek,
            titleId: RCAElementType._CHANGING_SEGMENTS,
          },
        ],
        enableScrollToElements
      ),
    [interestingSegmentsPerformanceVsPriorWeek, enableScrollToElements]
  );

  const rcaInsightsTitleTexts = useMemo(() => {
    const partOne =
      potentialDriversMergedSections.length > 0
        ? t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.EXPLAINERS_TITLE.PART_ONE, {
            changeDescription: changeDescriptionAlias.toUpperCase(),
          })
        : t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.HIGHLIGHTED_TITLE.PART_ONE);

    const partTwo =
      mode === RCAMode.LOOPS_ALGO
        ? t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.EXPLAINERS_TITLE.PART_TWO)
        : t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.HIGHLIGHTED_TITLE.PART_TWO_NEW, {
            granularity: granularity.toUpperCase(),
          });

    return {partOne, partTwo};
  }, [potentialDriversMergedSections, t, changeDescriptionAlias, mode, granularity]);

  const renderPopulationSizeExplainerAsStandAloneSection = () => {
    if (potentialDriversMergedSections.length > 0 || populationExplainerSection.length === 0) {
      return null;
    }
    return (
      <>
        <SentenceContainer>
          <SentencePart
            text={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.EXPLAINERS_TITLE.PART_ONE, {
              changeDescription: changeDescriptionAlias.toUpperCase(),
            })}
            className={classes.SubTitle}
          />
          <SentencePart
            text={rcaInsightsTitleTexts.partTwo}
            className={classNames(classes.SubTitle, classes.Dark)}
          />
        </SentenceContainer>
        <RCAExplainersSentencesComponent sections={populationExplainerSection} />
      </>
    );
  };

  return (
    <div className={sharedClasses.ExecutiveSummarySection}>
      {renderPopulationSizeExplainerAsStandAloneSection()}
      <SentenceContainer>
        <SentencePart text={rcaInsightsTitleTexts.partOne} className={classes.SubTitle} />
        <SentencePart
          text={rcaInsightsTitleTexts.partTwo}
          className={classNames(classes.SubTitle, classes.Dark)}
        />
      </SentenceContainer>
      {potentialDriversMergedSections.length > 0 && (
        <RCAExplainersSentencesComponent sections={potentialDriversMergedSections} />
      )}
      {interestingSegmentsSections.length > 0 && (
        <RCAExplainersSentencesComponent
          sections={interestingSegmentsSections}
          messageElement={<NoExplainersMsg />}
        />
      )}
    </div>
  );
};
