import * as React from 'react';
import classes from './image-viewer.module.scss';
import {IconButton, Tooltip} from '@material-ui/core';
import {ImageFigure, ImageFigureOptions} from '../../../types';
import {CSSProperties} from 'react';
import {ExpandIcon} from '../../../../../simple/controls/icons/icons.component';
import classNames from 'classnames';
import {DocumentTitleWrapper} from '../title-section-viewer/title-section-viewer.component';

export interface OwnProps extends ImageFigure {
  data: string;
  options?: ImageFigureOptions;
  style?: CSSProperties;
  className?: string;
}

export const ImageViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const onClick = (e: any) => {
    e.stopPropagation();
    const w = window.open('about:blank');
    const image = new Image();
    image.src = props.data;
    setTimeout(function () {
      w?.document.write(image.outerHTML);
    }, 0);
  };

  return (
    <DocumentTitleWrapper
      title={props.title}
      className={classNames(classes.ImageViewer, props.className)}
    >
      <div className={classes.ImageWrapper}>
        <img src={props.data} alt={props.options?.alt} />
        <div className={classes.Actions}>
          <Tooltip placement={'top'} title={'Open in new tab'}>
            <IconButton onClick={onClick}>
              <ExpandIcon className={classes.Icon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {props.options?.caption && <div className={classes.Caption}>{props.options?.caption}</div>}
    </DocumentTitleWrapper>
  );
};

ImageViewer.defaultProps = {
  options: {},
};
