import * as React from 'react';
import {useContext, useMemo} from 'react';
import {HorizontalGridLayoutInjectedProps} from '../../../layouts/grid-layout.component';
import {EnhancedGroup, EnhancedLine, EnhancedText} from '../../../../../../core/konva/components';
import moment from 'moment';
import {pixelInRangeFactory} from '../../../chart.utils';
import {StageContext} from '../../../../../../core/konva/stage-context.component';
import {ChartMarkArea} from '../../../chart-data.types';

interface OwnProps extends HorizontalGridLayoutInjectedProps {
  color?: string;
  markAreas?: ChartMarkArea[];
}

type AllProps = OwnProps;

export const QuarterMarksContainer: React.FC<AllProps> = (props: AllProps) => {
  const {width, height, x, y, xRange, color, markAreas} = props;
  const {style} = useContext(StageContext);

  const minMarkDateArea = useMemo(() => {
    if (!markAreas || markAreas.length === 0) {
      return null;
    }
    const arr = markAreas.map(ma => moment.utc(ma.from));
    return arr.length > 0 ? moment.min(arr) : null;
  }, [markAreas]);

  const quarters = useMemo(() => {
    const calcX = pixelInRangeFactory(xRange, width);
    const start = moment.utc(xRange[0]);
    let end = moment.utc(xRange[1]);
    if (minMarkDateArea) {
      end = moment.min([end, minMarkDateArea]);
    }
    const startOfYear = start.clone().startOf('year');
    const itr = startOfYear.clone();
    const res = [];
    while (itr.isSameOrBefore(end)) {
      if (itr.isSameOrAfter(start)) {
        res.push({
          label: `Q${itr.quarter()}`,
          x: calcX(itr.valueOf()),
          points: [0, 0, 0, height],
        });
      }
      itr.add(3, 'month');
    }
    return res;
  }, [xRange, width, height, minMarkDateArea]);

  /**
   * Render
   */
  return (
    <EnhancedGroup width={width} height={height} x={x} y={y}>
      {quarters.map(q => (
        <EnhancedGroup x={q.x} height={height} key={q.x}>
          <EnhancedText
            text={q.label}
            fontFamily={style.fontName}
            fill={'#8D94AE'}
            fontStyle={'500'}
            align={'center'}
            height={12}
            y={-12}
            x={-7}
          />
          <EnhancedLine points={q.points} strokeWidth={1} stroke={color} />
        </EnhancedGroup>
      ))}
    </EnhancedGroup>
  );
};

QuarterMarksContainer.defaultProps = {
  color: '#C5C9DB',
};
