import * as React from 'react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classes from './actions-dropdown.module.scss';
import sharedClasses from '../../../shared.module.scss';
import {isNumber} from 'lodash';
import {Popover} from '@material-ui/core';
import classNames from 'classnames';
import {SimpleSearch} from '../../custom-inputs/simple-search/simple-search.component';
import {DropdownButton} from '../dropdown-button/dropdown-button.component';
import {useListActiveIndex} from '../../../../hooks/use-list-active-index.hook';
import {ChevronDownIcon, DialogLayout, HoverHelperTip, IconButton} from '../../../../index';
import {withStopPropagation} from 'front-core';

export interface ActionItem {
  key?: string;
  title: string | JSX.Element;
  onClick?: (e) => void;
  hide?: boolean;
  disabled?: boolean;
  helperText?: string;
  icon?: any;
  children?: Array<ActionItem>;
  renderRight?: any;
}

export interface ActionsDropdownProps {
  id?: string;
  searchValue?: string;
  onSearchValueChange?: (q: string) => void;
  onActionClick?: (e, action) => void;
  enableSearch?: boolean;
  onFreeSearch?: (q: string) => void;
  label?: any;
  icon?: any;
  iconDropdown?: boolean;
  buttonComponent?: any;
  actions: Array<ActionItem>;
  className?: string;
  disabled?: boolean;
  popoverDirection?: 'left' | 'right';
  dropdownVariant?: 'light' | 'dark';
  dropdownClassName?: string;
  iconSize?: 'small' | 'normal' | 'large';
  rounded?: boolean;
  capitalize?: boolean;
  noResultsText?: string;
  border?: boolean;
}

type AllProps = ActionsDropdownProps;
type ActionsDropdownBodyProps = Omit<ActionsDropdownProps, 'label' | 'disabled'>;
const generateClassNameForOption = idx => `action_${idx}`;

export const ActionsDropdownBody: React.FC<ActionsDropdownBodyProps> = (
  props: ActionsDropdownProps
) => {
  const {
    actions: actionFromProps,
    enableSearch,
    searchValue: searchValueFromProps,
    onFreeSearch,
    onSearchValueChange,
    onActionClick: onActionClickFromProps,
    className,
    noResultsText,
  } = props;
  const [parentActionIdx, setParentActionIdx] = useState<number>();
  const [searchValue, setSearchValue] = useState(searchValueFromProps);
  const listRef = useRef<HTMLDivElement>(null);
  const parentAction = useMemo(
    () => (parentActionIdx !== undefined ? actionFromProps[parentActionIdx] : undefined),
    [parentActionIdx, actionFromProps]
  );
  const renderedActions = useMemo(
    () => (parentAction ? parentAction.children || [] : actionFromProps),
    [parentAction, actionFromProps]
  );
  const actions = useMemo(
    () =>
      renderedActions.filter(a =>
        searchValue && typeof a.title === 'string'
          ? a.title.toLowerCase().includes(searchValue.toLowerCase())
          : true
      ),
    [renderedActions, searchValue]
  );
  const {activeIndex, setActiveIndex, keyUpHandler} = useListActiveIndex({
    totalCount: actions.length,
    listParent: listRef.current,
    getClassNameForIndex: generateClassNameForOption,
    onEnter: (index, e) =>
      isNumber(index) && index >= 0
        ? actions[index]?.onClick(e)
        : onFreeSearch && onFreeSearch(searchValue),
  });
  const onChangeSearch = useCallback(
    value => {
      onSearchValueChange ? onSearchValueChange(value) : setSearchValue(value);
      setActiveIndex(null);
    },
    [onSearchValueChange, setSearchValue, setActiveIndex]
  );
  const onActionClick = useCallback(
    (e, action, idx) => {
      e.stopPropagation();
      e.preventDefault();
      if (action.disabled) {
        return;
      }
      if (action.children && action.children.length > 0) {
        setParentActionIdx(idx);
        setActiveIndex(null);
        return;
      }
      action.onClick(e);
      onActionClickFromProps && onActionClickFromProps(e, action);
    },
    [setActiveIndex, setParentActionIdx, onActionClickFromProps]
  );

  useEffect(() => {
    setSearchValue(searchValueFromProps);
  }, [searchValueFromProps]);

  return (
    <DialogLayout
      className={classNames(classes.Dropdown, parentAction && classes.HasParent, className)}
    >
      {parentAction && (
        <div
          onClick={withStopPropagation(() => setParentActionIdx(undefined))}
          className={classes.ParentHeader}
        >
          <ChevronDownIcon className={classes.BackIcon} />
          {parentAction.title}
        </div>
      )}
      {enableSearch && (
        <div className={classes.Search}>
          <SimpleSearch
            className={classes.SearchInput}
            value={searchValue}
            onChange={onChangeSearch}
            onKeyUp={keyUpHandler}
            placeholder={'Search'}
          />
        </div>
      )}
      <div ref={listRef} className={classes.ActionsWrapper}>
        {actions
          .filter(a => !a.hide)
          .map((action, idx) => (
            <div
              key={action.key || `${action.title}_${idx}`}
              className={classNames(
                classes.DropdownItem,
                action.disabled && classes.Disable,
                activeIndex === idx && !action.disabled && classes.Active,
                generateClassNameForOption(idx)
              )}
              onClick={e => onActionClick(e, action, idx)}
            >
              {action.icon && <action.icon className={classes.Icon} />}
              <div className={classes.Label}>
                {action.title}
                {action.helperText && (
                  <HoverHelperTip title={action.helperText} className={classes.Helper} />
                )}
              </div>
              {action.renderRight ? action.renderRight : null}
              {action.children && action.children.length > 0 && (
                <ChevronDownIcon className={classes.ChildrenIcon} />
              )}
            </div>
          ))}
        {actions.length === 0 && enableSearch && (
          <div className={classes.NoResults}>{noResultsText || 'No results'}</div>
        )}
      </div>
    </DialogLayout>
  );
};

export const ActionsDropdown: React.FC<AllProps> = (props: ActionsDropdownProps) => {
  const {
    id,
    label,
    actions,
    disabled: disabled_,
    className,
    enableSearch,
    iconDropdown,
    buttonComponent: ButtonComponent,
    icon,
    iconSize,
    popoverDirection,
    rounded,
    border,
    capitalize,
    dropdownClassName,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const disabled = useMemo(() => disabled_ || actions.length === 0, [disabled_, actions]);
  const hasActiveActions = useMemo(() => actions.filter(a => !a.hide).length > 0, [actions]);

  if (actions.length === 0 || !hasActiveActions) {
    return null;
  }

  const handleClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if (disabled || actions.length === 0) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event?.stopPropagation();
    event?.preventDefault();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'actions-popover' : undefined;

  const renderPrimaryButton = () => {
    if (ButtonComponent) {
      return (
        <ButtonComponent
          id={id}
          className={className}
          onClick={handleClick}
          label={label}
          isOpen={open}
          disabled={disabled}
          active={open}
        />
      );
    }
    if (!iconDropdown) {
      return (
        <DropdownButton
          id={id}
          className={className}
          onClick={handleClick}
          label={label}
          isOpen={open}
          icon={icon}
          disabled={disabled}
          rounded={rounded}
          border={border}
          capitalize={capitalize}
        />
      );
    }

    return (
      <IconButton
        tooltipText={label}
        className={className}
        onClick={handleClick}
        icon={icon}
        active={open}
        size={iconSize}
        border={border}
      />
    );
  };

  return (
    <React.Fragment>
      {renderPrimaryButton()}
      <Popover
        className={sharedClasses.BlankPaperAllowOverflow}
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: sharedClasses.BlankPaperAllowOverflow,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: popoverDirection,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: popoverDirection,
        }}
      >
        <ActionsDropdownBody
          className={dropdownClassName}
          actions={actions}
          enableSearch={enableSearch}
          onActionClick={handleClose as any}
        />
      </Popover>
    </React.Fragment>
  );
};

ActionsDropdown.defaultProps = {
  id: 'actions-dropdown',
  popoverDirection: 'right',
  actions: [],
  iconDropdown: false,
};
