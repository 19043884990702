import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import {InterestType} from '../../../../../../../../../../types';
import classes from './interest-chip-display.module.scss';
import {useDocumentTranslation} from '../../../../../../../../../../hooks/use-document-translation.hook';
import {Tooltip} from '@material-ui/core';
import {
  ArrowNarrowUpIcon,
  ChartPieIcon,
  HashtagRegularIcon,
  UserGroupSolidIcon,
} from '../../../../../../../../../../../../simple/controls/icons/icons.component';
import {getPercentageValueFormatter} from '../../../../../../../../../../../../../utils/formatters';
import TransKeys from 'translations';
import pluralize from 'pluralize';

export interface InterestChipDisplayData {
  value: number;
  expectedValue: number;
  share: number;
  expectedShare: number;
  count: number;
  expectedCount: number;
}

export interface InterestChipDisplayProps {
  data: InterestChipDisplayData;
  interestType: InterestType;
  isPercentageValue: boolean;
  entity: string;
  valueLabel?: string;
  prevLabel?: string;
  className?: string;
}

const CONTENT_FOR_INTEREST_TYPE = {
  [InterestType.KPI_CHANGE]: <span className={classes.Text}>KPI</span>,
  [InterestType.SHARE]: (
    <>
      <ChartPieIcon />
      <UserGroupSolidIcon />
    </>
  ),
  [InterestType.COUNT]: (
    <>
      <HashtagRegularIcon />
      <UserGroupSolidIcon />
    </>
  ),
};

export const InterestChipDisplay: React.FC<InterestChipDisplayProps> = (
  props: InterestChipDisplayProps
) => {
  const {
    data: dataFromProps,
    interestType,
    valueLabel,
    prevLabel,
    isPercentageValue,
    entity,
    className,
  } = props;
  const {t} = useDocumentTranslation();

  const valueFormatter = useMemo(() => {
    let value = false;
    if (interestType === InterestType.KPI_CHANGE) {
      value = isPercentageValue;
    }
    if (interestType === InterestType.SHARE) {
      value = true;
    }
    return getPercentageValueFormatter(value);
  }, [isPercentageValue, interestType]);
  const data = useMemo(() => {
    if (interestType === InterestType.KPI_CHANGE) {
      return {
        current: dataFromProps.value,
        prev: dataFromProps.expectedValue,
      };
    }
    if (interestType === InterestType.SHARE) {
      return {
        current: dataFromProps.share,
        prev: dataFromProps.expectedShare,
      };
    }
    if (interestType === InterestType.COUNT) {
      return {
        current: dataFromProps.count,
        prev: dataFromProps.expectedCount,
      };
    }
  }, [dataFromProps, interestType]);
  const chipContent = useMemo(() => {
    if (interestType in CONTENT_FOR_INTEREST_TYPE) {
      return CONTENT_FOR_INTEREST_TYPE[interestType];
    }
    return null;
  }, [interestType]);
  const isNegative = data.current < data.prev;
  const title = useMemo(() => {
    const prefix = isNegative ? 'Drop in' : 'Spike in';
    const subject = t(
      TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.INTEREST_TYPE[interestType?.toUpperCase()],
      {entity: pluralize(entity)}
    );
    return `${prefix} ${subject}:`;
  }, [isNegative, entity, interestType]);

  return (
    <Tooltip
      title={
        <div className={classes.Tooltip}>
          <div className={classes.Line}>
            <span className={classes.Title}>{title}</span>
          </div>
          <div className={classes.Line}>
            <span className={classes.Label}>{valueLabel}:</span>
            <span className={classes.Bolder}>{valueFormatter(data.current)}</span>
          </div>
          <div className={classes.Line}>
            <span className={classes.Label}>{prevLabel}:</span>
            <span className={classes.Bolder}>{valueFormatter(data.prev)}</span>
          </div>
        </div>
      }
      placement={'top'}
      interactive={false}
    >
      <div
        className={classNames(
          classes.InterestChipDisplay,
          isNegative && classes.Negative,
          className
        )}
      >
        <ArrowNarrowUpIcon className={classes.ArrowIcon} />
        <span className={classes.ChipContent}>{chipContent}</span>
      </div>
    </Tooltip>
  );
};

InterestChipDisplay.defaultProps = {
  valueLabel: 'Current',
  prevLabel: 'Previous',
};
