export const calculatePeriodValueText = (valueText: string, withPercentage?: boolean) => {
  return withPercentage ? `${valueText}%` : valueText;
};

export const renderDates = (i: {startDate: string; endDate: string}) => {
  if (!i.endDate) {
    return i.startDate;
  }
  return `${i.startDate} - ${i.endDate}`;
};
