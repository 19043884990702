import {createContext, useContext, useMemo} from 'react';
import * as React from 'react';
import {get} from 'lodash';
import {DropdownButtonTheme} from '../../components/simple/controls/dropdown-button/dropdown-button.component';

export interface BaseComponentTheme {
  rootClassName?: string;
  [other: string]: string;
}

export interface UITheme {
  DropdownButton?: DropdownButtonTheme;
}

type SupportedUIThemeName = keyof UITheme;

export interface IUIThemeContext {
  theme: UITheme;
}

export const UIThemeContext = createContext<IUIThemeContext>({
  theme: {},
});

interface OwnProps {
  theme: UITheme;
  children: any;
}

export const UIThemeProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {theme, children} = props;

  const contextValue: IUIThemeContext = useMemo(
    () => ({
      theme,
    }),
    [theme]
  );

  return <UIThemeContext.Provider value={contextValue}>{children}</UIThemeContext.Provider>;
};

export function useUITheme<T extends BaseComponentTheme>(
  name: SupportedUIThemeName
): UITheme[SupportedUIThemeName] {
  const {theme} = useContext(UIThemeContext);
  return get(theme, name, {});
}
