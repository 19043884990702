import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './content-funnel-overview-viewer.module.scss';
import {
  ChartType,
  ColumnType,
  CommandType,
  ContentFunnelOverviewFigure,
  ContentFunnelOverviewFigureStep,
  ContentFunnelOverviewItem,
  DocumentElement,
  DocumentElementType,
  SPECIAL_SMART_TABLE_DATA_KEYS,
} from '../../../../types';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {FunnelChartMode} from '../../../../../charts-v2/funnel-chart/funnel-chart.component';
import {ChildRenderer} from '../../../core/child-renderer.component';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {
  extendContentData,
  generateStepKey,
  getColumnDataByTab,
  getValueByTab,
} from './content-funnel-overview-viewer.utils';
import {capitalize, last, range, take, values} from 'lodash';
import {withStopPropagation} from 'front-core';
import {Checkbox} from '../../../../../../forms/inputs/checkbox/checkbox.component';
import {AppTabs} from '../../../../../../simple/navigation/app-tabs/app-tabs.component';
import TransKeys from 'translations';
import pluralize from 'pluralize';

interface OwnProps extends ContentFunnelOverviewFigure {
  className?: string;
}

export interface ExtendedContentFunnelOverviewItem extends ContentFunnelOverviewItem {
  conversionFromPrev: number[];
  conversionFromStart: number[];
  upliftPerStep: number[];
}

export enum TableTab {
  CONVERSION_FROM_START = 'conversion_from_start',
  CONVERSION_FROM_PREV = 'conversion_from_prev',
  UPLIFT = 'uplift',
  COUNT_ENTITIES = 'count_entities',
}

type AllProps = OwnProps;

export const ContentFunnelOverviewViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, options, className} = props;
  const {steps, contentsData} = data;
  const {t} = useDocumentTranslation();
  const tabOptions = useMemo(
    () =>
      values(TableTab).map(tab => ({
        key: tab,
        label: t(TransKeys.DOCUMENT_VIEWER.CONTENT_FUNNEL_OVERVIEW_FIGURE.TABS[tab.toUpperCase()], {
          entity: capitalize(pluralize(options.entity)),
        }),
      })),
    [t, options]
  );
  const [selectedTab, setSelectedTab] = useState(TableTab.CONVERSION_FROM_START);
  const extendedContentData: ExtendedContentFunnelOverviewItem[] = useMemo(
    () =>
      extendContentData(contentsData, steps).sort(
        (a, b) => last(b.conversionFromStart) - last(a.conversionFromStart)
      ),
    [contentsData, steps]
  );
  const initialSelection = useMemo(
    () => take(extendedContentData, 4).map(d => d.key),
    [extendedContentData]
  );
  const {query: selectedContent, setQuery: setSelectedContent} = useDocQuery(
    'selected-contents',
    initialSelection
  );
  const selectedContentSet = useMemo(() => new Set(selectedContent), [selectedContent]);
  const onChangeContentCompare = useCallback(
    (key: string) => {
      if (selectedContentSet.has(key)) {
        selectedContentSet.delete(key);
        setSelectedContent(Array.from(selectedContentSet));
        return;
      }
      setSelectedContent([...Array.from(selectedContentSet), key]);
    },
    [selectedContentSet, setSelectedContent]
  );

  const funnelFigure = useMemo(() => {
    const labels = steps.map(s => s.label);
    const funnelData = extendedContentData.filter(c => selectedContent.indexOf(c.key) > -1);
    return {
      labels,
      type: DocumentElementType.CHART,
      chartType: ChartType.FUNNEL,
      data: funnelData.map(d => ({
        id: d.key,
        label: d.name,
        data: d,
      })),
      options: {
        mode: FunnelChartMode.TOTALS,
        displayedDatasetIds: funnelData.map(f => f.key),
        stepMaxHeight: 60,
        singleTitle: true,
        emptyStateText: t(
          TransKeys.DOCUMENT_VIEWER.CONTENT_FUNNEL_OVERVIEW_FIGURE.FUNNEL_EMPTY_STATE
        ),
      },
    };
  }, [steps, extendedContentData, selectedContent, t]);
  const tableData = useMemo(() => {
    return extendedContentData.map((d, i) => {
      const cvr = take(steps, steps.length - 1).reduce(
        (acc, s, idx) => ({
          ...acc,
          [generateStepKey(s)]: getValueByTab(selectedTab, d, idx),
        }),
        {}
      );
      const count = range(steps.length).reduce(
        (acc, s, idx) => ({
          ...acc,
          [generateStepKey(steps[s], 'count')]: d.fromPrevious[idx],
        }),
        {}
      );
      return {
        ...d,
        ...cvr,
        ...count,
        command: {
          type: CommandType.ANALYSIS_FIGURE_SWITCH_VIEW,
          payload: {
            rootContentKey: data.overviewByContentContentKey,
            innerContentKey: d.key,
          },
        },
        commandHelperText: t(
          TransKeys.DOCUMENT_VIEWER.CONTENT_FUNNEL_OVERVIEW_FIGURE.TABLE.COMMAND.HELPER_TEXT,
          {
            name: d.name,
          }
        ),
        [SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]: selectedContentSet.has(d.key)
          ? classes.SelectedRow
          : '',
      };
    });
  }, [
    extendedContentData,
    steps,
    selectedTab,
    data.overviewByContentContentKey,
    selectedContentSet,
  ]);
  const tableColumns = useMemo(() => {
    const conversionColumns = [];
    for (let i = 1; i < steps.length; i++) {
      conversionColumns.push({
        title: `${steps[i - 1].label} → ${steps[i].label}`,
        key: generateStepKey(steps[i - 1]),
        ...getColumnDataByTab(selectedTab),
        hidden: selectedTab === TableTab.COUNT_ENTITIES,
      });
    }
    const countEntitiesColumns = [];
    for (let i = 0; i < steps.length; i++) {
      countEntitiesColumns.push({
        title: `${steps[i].label}`,
        key: generateStepKey(steps[i], 'count'),
        type: ColumnType.NUMERIC,
        options: {
          sortable: i > 0,
          filterable: i > 0,
        },
        hidden: selectedTab !== TableTab.COUNT_ENTITIES,
      });
    }

    return [
      {
        key: 'select',
        type: ColumnType._RENDER_COLUMN,
        title: '',
        options: {
          width: '4rem',
        },
        contentClassName: classes.CheckboxCell,
        render: item => (
          <div
            className={classes.CheckboxWrapper}
            onClick={withStopPropagation(() => onChangeContentCompare(item.key))}
          >
            <Checkbox
              multi
              checked={selectedContentSet.has(item.key)}
              onChange={() => onChangeContentCompare(item.key)}
            />
          </div>
        ),
      },
      {
        key: 'name',
        type: ColumnType.DEFAULT,
        title: t(TransKeys.DOCUMENT_VIEWER.CONTENT_FUNNEL_OVERVIEW_FIGURE.TABLE.CONTENT_ITEM),
        options: {
          sortable: true,
          filterable: true,
          bold: true,
          width: '30rem',
        },
      },
      ...conversionColumns,
      ...countEntitiesColumns,
      {
        key: 'command',
        type: ColumnType.COMMAND,
        title: '',
        options: {
          width: '8rem',
          align: 'right',
        },
        typeOptions: {
          helperTextDataKey: 'commandHelperText',
        },
      },
    ];
  }, [steps, onChangeContentCompare, selectedContentSet, selectedTab, t]);

  const tableFigure: DocumentElement = useMemo(
    () =>
      ({
        id: `content-table-${selectedTab}`,
        dataKey: 'key',
        type: DocumentElementType.SMART_TABLE,
        columns: tableColumns,
        data: tableData,
        options: {
          pagination: false,
        },
      }) as any,
    [tableColumns, tableData, selectedTab]
  );

  return (
    <div className={classNames(classes.ContentFunnelOverviewFigureViewer, className)}>
      <div className={classes.ChartWrapper}>
        <ChildRenderer children_={funnelFigure} key={'funnel-chart'} />
      </div>
      <div className={classes.TableWrapper}>
        <AppTabs
          tabs={tabOptions}
          onChange={setSelectedTab as any}
          selected={selectedTab}
          className={classes.Tabs}
        />
        <ChildRenderer
          children_={tableFigure}
          key={'content-table'}
          className={classes.MarginBottom}
        />
      </div>
    </div>
  );
};

ContentFunnelOverviewViewer.defaultProps = {};
