import * as React from 'react';
import {CSSProperties, useEffect, useMemo, useState} from 'react';
import {EnhancedGroup} from '../../../../../core/konva/components';
import {AreaCircle} from '../../generic-components/area-circle/area-circle.component';
import {KPIList} from '../kpi-list/kpi-list.component';
import {
  GroupMembersType,
  KPIElement,
  KPIGroup,
  MapElementType,
} from '../../../../../types/growth-map.types';
import {Group} from 'konva/types/Group';
import {Html} from '../../../../../core/konva/hoc/html.hoc';
import {Sources} from '../sources/sources.component';
import {GroupInfoBox} from '../group-info-box/group-info-box.component';
import {KPILoops} from '../kpi-loops/kpi-loops.component';
import {
  GROUP_CIRCLE_RADIUS,
  MAP_AREA_COLOR,
  SECONDARY_GROUP_CIRCLE_RADIUS,
} from '../../growth-map.config';
import {GroupSmallInfoBox} from '../group-small-info-box/group-small-info-box.component';
import {uniqueId} from 'lodash';
import {pick} from 'lodash';

interface OwnProps extends KPIGroup {
  x: number;
  y: number;
  model: string;
  zIndex?: number;
  onClick?: (e, target?: Group) => void;
  opacity?: number;
  isExpanded?: boolean;
  isFocused?: boolean;
  onExpand?: () => void;
  onFocus?: () => void;
  onOpportunitiesClicked?: (e) => void;
  onLoopsClicked?: (e) => void;
  onKPIClicked?: (kpi: KPIElement) => void;
}

type AllProps = OwnProps;

const INFOBOX_CLASS_NAME = '__info_box';
const GROUP_PROPS_NAMES = [
  'type',
  'mapElementType',
  'metricId',
  'data',
  'options',
  'title',
  'value',
  'position',
];

export const KPIGroupViewer: React.FC<AllProps> = (props: AllProps) => {
  const {
    x,
    y,
    area,
    title,
    value,
    members,
    mapElementType,
    membersType,
    zIndex,
    onClick,
    options,
    opacity,
    isExpanded,
    isFocused,
    onExpand,
    onFocus,
    data,
    model,
    metricId,
    onOpportunitiesClicked,
    onLoopsClicked,
    onKPIClicked,
  } = props;
  const [infoboxHeight, setInfoboxHeight] = useState(0);
  const isPrimary = useMemo(
    () =>
      [MapElementType.PRIMARY_GROUP, MapElementType.NORTH_STAR_GROUP].indexOf(mapElementType) > -1,
    [mapElementType]
  );
  const dirNum: number = useMemo(
    () => (options.direction === 'to-top' ? -1 : 1),
    [options.direction]
  );
  const style: CSSProperties = useMemo(
    () => ({
      pointerEvents: 'none',
      zIndex,
      opacity,
    }),
    [zIndex, opacity]
  );
  const membersStyle = useMemo(
    () => ({
      ...style,
      opacity: isExpanded ? 0.2 : style.opacity,
    }),
    [isExpanded, style]
  );
  const htmlId = useMemo(() => uniqueId('kpi-info-'), []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const wrapper = document.getElementById(htmlId);
      const groupInfoDiv: any = wrapper.querySelectorAll('.' + INFOBOX_CLASS_NAME)[0];
      setInfoboxHeight(groupInfoDiv.clientHeight || 50);
    }, 0);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMembers = () => {
    const offset = infoboxHeight + 30;

    switch (membersType) {
      case GroupMembersType.FUNNEL:
      case GroupMembersType.LIST:
        const isFunnel = membersType === GroupMembersType.FUNNEL;
        return (
          <EnhancedGroup centerX y={dirNum * (GROUP_CIRCLE_RADIUS + 10)}>
            <Html divProps={{style: membersStyle}}>
              <KPIList
                yOffset={offset}
                color={MAP_AREA_COLOR[area]}
                members={members as any}
                isFunnel={isFunnel}
                direction={options.direction}
                isExpanded={isFocused}
                model={model}
                onKPIClicked={kpi => onKPIClicked(kpi)}
              />
            </Html>
          </EnhancedGroup>
        );
      case GroupMembersType.SOURCES:
        return (
          <Sources
            htmlStyle={style}
            onClick={onClick}
            members={members as any}
            color={MAP_AREA_COLOR[area]}
            model={model}
            round={options.round}
            onKPIClicked={kpi => onKPIClicked(kpi)}
            isExpanded={isFocused}
          />
        );
      case GroupMembersType.LOOPS:
        return (
          <EnhancedGroup centerX y={dirNum * (GROUP_CIRCLE_RADIUS + 10)}>
            <Html divProps={{style: membersStyle}}>
              <KPILoops
                yOffset={offset}
                members={members as any}
                direction={options.direction}
                onKPIClicked={kpi => onKPIClicked(kpi)}
                isExpanded={isFocused}
                model={model}
              />
            </Html>
          </EnhancedGroup>
        );
    }
  };

  const renderInfo = () => {
    if (isPrimary) {
      return (
        <GroupInfoBox
          title={title || data.name}
          options={options}
          color={MAP_AREA_COLOR[area]}
          isExpanded={isExpanded}
          onExpand={() => onExpand && onExpand()}
          isNorthStar={mapElementType === MapElementType.NORTH_STAR_GROUP}
          data={data}
          model={model}
          metricId={metricId}
          infoboxClassName={INFOBOX_CLASS_NAME}
          onLoopsClicked={onLoopsClicked}
          onOpportunitiesClicked={onOpportunitiesClicked}
          onKPIClicked={e => onKPIClicked(pick(props, GROUP_PROPS_NAMES) as KPIElement)}
        />
      );
    }
    if (mapElementType === MapElementType.SECONDARY_GROUP) {
      return (
        <GroupSmallInfoBox
          title={title || data.name}
          options={options}
          color={MAP_AREA_COLOR[area]}
          isExpanded={isExpanded}
          onExpand={() => onExpand && onExpand()}
          value={value}
          data={data}
          model={model}
          metricId={metricId}
          infoboxClassName={INFOBOX_CLASS_NAME}
          onKPIClicked={e => onKPIClicked(pick(props, GROUP_PROPS_NAMES) as KPIElement)}
        />
      );
    }
  };

  return (
    <EnhancedGroup opacity={opacity} x={x} y={y} onClick={onClick} cursorPointer={false}>
      {renderMembers()}
      <AreaCircle
        color={isPrimary ? MAP_AREA_COLOR[area] : MAP_AREA_COLOR.SECONDARY}
        radius={isPrimary ? GROUP_CIRCLE_RADIUS : SECONDARY_GROUP_CIRCLE_RADIUS}
        isActive={isExpanded}
        direction={options.direction}
        onDblClick={() => onFocus && onFocus()}
      />
      <EnhancedGroup centerX y={0}>
        <Html
          divProps={{
            style,
            id: htmlId,
          }}
        >
          {renderInfo()}
        </Html>
      </EnhancedGroup>
    </EnhancedGroup>
  );
};

KPIGroupViewer.defaultProps = {
  membersType: GroupMembersType.LIST,
  options: {},
  zIndex: 10,
  opacity: 1,
};
