import classNames from 'classnames';
import {Tooltip} from '@material-ui/core';
import classes from './switch-actions.module.scss';
import {exists} from 'front-core';

export interface SwitchAction {
  label?: React.ReactNode;
  count?: number;
  icon?: any;
  onClick?: (e) => void;
  helperText?: string;
  isActive?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export interface OwnProps {
  label?: string;
  actions: SwitchAction[];
  showActionsLabel?: boolean;
  disabled?: boolean;
  className?: string;
  actionClassName?: string;
  size?: 'small' | 'default';
  rounded?: boolean;
}

export const SwitchActions: React.FC<OwnProps> = (props: OwnProps) => {
  const {actions, showActionsLabel, disabled, label, className, actionClassName, size, rounded} =
    props;
  return (
    <div
      className={classNames(
        classes.SwitchActions,
        rounded && classes.Rounded,
        disabled && classes.Disabled,
        classes[size],
        className
      )}
    >
      {label && <div className={classNames(classes.Action, classes.Label)}>{label}</div>}
      {actions
        .filter(a => !a.hidden)
        .map(
          (
            {label, helperText, icon: Icon, isActive, onClick, count, disabled: actionDisabled},
            idx
          ) => (
            <Tooltip key={`action_${idx}`} title={helperText || ''} placement={'top'}>
              <div
                onClick={e => (actionDisabled || disabled ? undefined : onClick(e))}
                className={classNames(
                  classes.Action,
                  isActive && classes.Active,
                  (actionDisabled || disabled) && classes.Disabled,
                  actionClassName
                )}
              >
                {Icon && <Icon className={classes.Icon} />}
                {showActionsLabel && <span className={classes.Label}>{label}</span>}
                {exists(count) && <span className={classes.Chip}>{count}</span>}
              </div>
            </Tooltip>
          )
        )}
    </div>
  );
};

SwitchActions.defaultProps = {
  actions: [],
};
