import * as React from 'react';
import {useMemo} from 'react';
import {ChildRenderer} from '../../core/child-renderer.component';
import {ContentWrapper, DocumentElementType} from '../../../types';
import {ChildRendererInjectedProps} from '../../../document-viewer.types';
import {FancyBlock} from '../../../../../simple/data-display/fancy-block/fancy-block.component';
import classes from './content-wrapper-viewer.module.scss';

export interface OwnProps extends ContentWrapper, Partial<ChildRendererInjectedProps> {
  className?: string;
}

export const ContentWrapperViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {children, title, subTitle, className} = props;

  const addPadding = useMemo(() => {
    if (children.length === 1 && children[0].type === DocumentElementType.RCA_TRENDS) {
      return false;
    }
    return true;
  }, [children]);

  return (
    <FancyBlock title={title} subTitle={subTitle} addPadding={addPadding} className={className}>
      <ChildRenderer children_={props.children} className={classes.MarginBottom} />
    </FancyBlock>
  );
};

ContentWrapperViewer.defaultProps = {};
