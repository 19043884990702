import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {EnhancedCircle, EnhancedGroup, EnhancedLine} from '../../../../../core/konva/components';
import {Rect} from 'react-konva';
import {
  ChartLine as ChartLineModel,
  ChartMarkArea as ChartMarkAreaModel,
  NumberDataset,
  XYDataset,
} from '../../chart-data.types';
import {AXIS_VARIANT} from '../../chart-inner.types';
import {HorizontalGridLayoutInjectedProps} from '../../layouts/grid-layout.component';
import {flatten, get, groupBy, initial} from 'lodash';
import {curveLine} from '../../../../../core/konva/stage.utils';
import {LineChartDatasetOptions} from './line-chart.component';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {Polygon} from '../../components/polygon.component';
import {chartClasses} from '../../chart.consts';
import {ChartContext} from '../../chart.context';
import {ChartColorUtil} from '../../../document-viewer/document-viewer.utils';
import {
  pixelInRangeFactory,
  formatXDateLabelWithRangeTimeUnit,
  findClosestPoint,
} from '../../chart.utils';
import {exists, number2k} from 'front-core';
import {colorAlphaTransformer} from '../../../../../utils/colors';
import {IncompleteMark} from './components/incomplete-mark.component';
import {TREND_DEFAULT_TEXT, TrendMark, TrendMarkProps} from './components/trend-mark.component';
import {Html} from '../../../../../core/konva/hoc/html.hoc';
import {LineChartTooltip} from './components/line-chart-tooltip.component';
import {ChartLine} from '../../components/chart-line.component';
import ClickAnimation from '../../../../../assets/images/click-on-point-animation.gif';
import {ChartMarkArea} from '../../components/chart-mark-area.component';

interface TransformedDatasetPoint {
  key: string;
  x: number;
  y: number;
  markedValue: boolean;
  color: string;
  highlight: boolean;
  datasetName: string;
  value: any;
  itemIndex: number;
  datasetIndex: number;
  isSelected: boolean;
  label: string;
  dashed: boolean;
  dsColor: string;
  showPoint: boolean;
  groupBy?: any;
  focusable?: boolean;
  clickable?: boolean;
  pointTooltipTitle?: string;
  pointTooltipCta?: string;
  metadata?: any;
}

interface ExtractedDataPoint {
  x: number;
  y: number;
  isDate: boolean;
  itemIndex: number;
  label: string;
  metadata?: any;
}

type DatasetPointsArray = Array<TransformedDatasetPoint>;

export interface OnDatasetPointClickParam {
  point: ExtractedDataPoint;
  dataset: NumberDataset | XYDataset;
  additionalProperties?: {
    [other: string]: any;
  };
}

interface OwnProps extends HorizontalGridLayoutInjectedProps {
  datasets: XYDataset[];
  lines?: ChartLineModel[];
  markAreas?: ChartMarkAreaModel[];
  curve?: boolean;
  showDots?: boolean;
  showTooltipPreviousValueDelta?: boolean;
  area?: boolean;
  errorBar?: boolean;
  datasetOptions?: LineChartDatasetOptions;
  onDatasetClick?: (ds: XYDataset) => void;
  onDatasetPointClick?: (param: OnDatasetPointClickParam) => void;
  dateFormat?: string;
  yLabelSuffix?: string;
  xLabelSuffix?: string;
  secondaryYLabelSuffix?: string;
  xLabel?: string;
  yAxisFractionDigit?: {primary: number; secondary: number};
  pointTooltipTitle?: string;
  pointTooltipCta?: string;
}

type AllProps = OwnProps;

const OPACITY_LOW = 0.4;
const FOCUS_COLOR = 'rgba(0, 0, 0, 0.4)';
const FOCUS_COLOR_DARK_MODE = 'rgba(255, 255, 255, 0.8)';
const DASHED = [8, 6];

export const LineChartDatasetContainer: React.FC<AllProps> = (props: AllProps) => {
  const {
    width,
    height,
    x,
    y,
    datasets: datasets_,
    lines: lines_ = [],
    markAreas: markAreas_ = [],
    yRange,
    secondaryYRange,
    xRange,
    showDots = true,
    showTooltipPreviousValueDelta,
    curve = false,
    area,
    errorBar,
    datasetOptions = {},
    labels,
    onDatasetClick,
    onDatasetPointClick,
    xRangeMS,
    dateFormat,
    yLabelSuffix = '',
    secondaryYLabelSuffix,
    timeUnit: ticksRangeTimeUnitFromProps,
    yAxisFractionDigit,
    xTickSize,
    xLabel,
    showIncompleteMark = false,
    incompleteMarkDescription,
    pointTooltipTitle,
    pointTooltipCta,
  } = props;
  const context = useContext(StageContext);
  const {darkMode} = useContext(ChartContext);
  const {style, controller} = context;
  const [tooltipData, setTooltipData] = useState<TransformedDatasetPoint>(null);
  /**
   * Data computed properties
   */
  const focusColor = useMemo(() => (darkMode ? FOCUS_COLOR_DARK_MODE : FOCUS_COLOR), [darkMode]);
  const datasets = useMemo(
    () => datasets_.sort((a, b) => Number(a.highlight) - Number(b.highlight)),
    [datasets_]
  );
  const getGroupByPropertyValue = useCallback(
    (datasetIndex: number, itemIndex: number) =>
      get(datasets, `${datasetIndex}.data.${itemIndex}.x`, null),
    [datasets]
  );
  // datasetPoints are array of arrays, each array represents a dataset
  const datasetPointsArrays: DatasetPointsArray[] = useMemo(
    () =>
      datasets.map((ds, dsIdx) => {
        // When showing the incomplete mark, remove the last point
        // (we've added it 'manually' for extending the grid)
        const dsData = showIncompleteMark ? [...initial(ds.data)] : ds.data;
        return dsData.map((item, idx) => {
          let label: any = get(labels, idx);
          if (!label) {
            if (xRangeMS) {
              label = formatXDateLabelWithRangeTimeUnit({
                value: item.x,
                timeUnit: ticksRangeTimeUnitFromProps,
                dateFormat,
              });
            } else {
              label = typeof item.x === 'number' ? number2k(item.x as number) : item.x;
            }
          }
          const range =
            ds?.yAxis === AXIS_VARIANT.SECONDARY && secondaryYRange ? secondaryYRange : yRange;
          const calcY = pixelInRangeFactory(range, height, true);
          const calcX = xRange ? pixelInRangeFactory(xRange, width) : undefined;
          return {
            key: `point_${ds.index}_${idx}`,
            x: calcX ? calcX(item.x as number) : (idx / (dsData.length - 1)) * width,
            y: calcY(item.y),
            color: item.markColor ? ChartColorUtil.getColor(item.markColor) : ds.color,
            markedValue: Boolean(item.markColor),
            highlight: ds.highlight,
            datasetName: ds.label,
            value: item,
            itemIndex: idx,
            datasetIndex: dsIdx,
            isSelected: tooltipData?.groupBy === getGroupByPropertyValue(dsIdx, idx), // refers to the line and not the point
            label: label.toUpperCase(),
            dashed: item?.dashed,
            dsColor: ds.color,
            showPoint: ds.showLineDots !== false, // For backward compatibility, if showLineDots is not explicitly set to false, we're showing the dots
            focusable: item.focusable,
            pointTooltipTitle: item.pointTooltipTitle,
            pointTooltipCta: item.pointTooltipCta,
            clickable:
              onDatasetPointClick === undefined
                ? false
                : !exists(item.clickable)
                  ? true
                  : item.clickable,
            metadata: item.metadata,
          };
        });
      }),
    [
      datasets,
      showIncompleteMark,
      labels,
      xRangeMS,
      ticksRangeTimeUnitFromProps,
      dateFormat,
      secondaryYRange,
      yRange,
      height,
      xRange,
      width,
      tooltipData?.groupBy,
      getGroupByPropertyValue,
      onDatasetPointClick,
    ]
  );
  const lastXPointOnChart = useMemo(
    () =>
      datasetPointsArrays && datasetPointsArrays.length > 0
        ? datasetPointsArrays[0][datasetPointsArrays[0].length - 1].x
        : 0,
    [datasetPointsArrays]
  );
  const incompleteMarkX = useMemo(
    () => (showIncompleteMark ? lastXPointOnChart + xTickSize * 0.5 + 1 : 0),
    [showIncompleteMark, lastXPointOnChart, xTickSize]
  );
  const datasetMarks: TrendMarkProps[] = useMemo(() => {
    const allChartMarks = datasetPointsArrays.map(dsPointsArr => {
      const toBound = xTickSize / 2;
      return dsPointsArr
        .filter((dsp: TransformedDatasetPoint) => dsp.value.isTrend)
        .map((dsp: TransformedDatasetPoint) => {
          const trendDescriptionText = dsp.value.trendDescription || TREND_DEFAULT_TEXT;
          const trendDescriptionTextWidth = controller.measureTextWidth(
            trendDescriptionText,
            style.fontName,
            12
          );

          return {
            lowerXBound: dsp.x - toBound,
            upperXBound: dsp.x + toBound,
            toBound,
            trendRectColor: dsp.value.trendColor,
            trendDescriptionTextColor: dsp.value.trendColor,
            height,
            trendDescriptionText,
            trendDescriptionTextWidth,
            fontName: style.fontName,
          };
        });
    });
    return flatten(allChartMarks.reduce((acc, curr) => (curr.length ? [...acc, curr] : acc), []));
  }, [datasetPointsArrays, xTickSize, controller, style.fontName, height]);

  const datasetLines = useMemo(() => {
    const lines = [];
    datasetPointsArrays.forEach((dsp, dspIdx) => {
      const sharedLineProperties = {
        color: datasets[dspIdx].color,
        highlight: datasets[dspIdx].highlight,
        isSelected: tooltipData?.datasetIndex === dspIdx,
      };

      if (dsp.length >= 2) {
        const initialIterationLinesProperties = {
          key: '',
          type: '',
          points: [],
          dashed: undefined,
        };
        let iterationLine = {
          ...initialIterationLinesProperties,
          ...sharedLineProperties,
        };
        for (let i = 0; i < dsp.length - 1; i++) {
          const iterationLinePoints = [dsp[i].x, dsp[i].y, dsp[i + 1].x, dsp[i + 1].y];
          /*
           * We're iterating the dataset point, checking if the next point isPredicted
           * is true. This way we know whether we want to draw dashed or a solid line
           * */
          if (dsp[i + 1]?.dashed) {
            if (iterationLine.type === 'regular') {
              lines.push(iterationLine);
              iterationLine = {
                ...initialIterationLinesProperties,
                ...sharedLineProperties,
              };
            }
            iterationLine.type = 'dashed';
            iterationLine.key = `line_${datasets[dspIdx].index}_${i}_dashed`;
            iterationLine.points = [...iterationLine.points, ...iterationLinePoints];
            iterationLine.dashed = true;
          } else {
            if (iterationLine.type === 'dashed') {
              lines.push(iterationLine);
              iterationLine = {
                ...initialIterationLinesProperties,
                ...sharedLineProperties,
              };
            }
            iterationLine.type = 'regular';
            iterationLine.key = `line_${datasets[dspIdx].index}_${i}_regular`;
            iterationLine.points = [...iterationLine.points, ...iterationLinePoints];
            iterationLine.dashed = datasetOptions[datasets[dspIdx].id]?.dashed;
          }
        }
        lines.push(iterationLine);
      } else if (dsp.length === 1) {
        // We're adding here a "line" with a single
        // which in practice is invisible as it being
        // rendered "behind" the dataset point (circle)
        // in order to support rendering a CI area (errorBar)
        // on the chart
        const singlePointLine = {
          ...sharedLineProperties,
          type: 'regular',
          key: `line_${datasets[dspIdx].index}_single_point_regular`,
          dashed: false,
          points: [dsp[0].x, dsp[0].y],
        };
        lines.push(singlePointLine);
      }
    });
    return lines;
  }, [datasetPointsArrays, datasets, datasetOptions, tooltipData]);
  const areas = useMemo(() => {
    if (!area) {
      return;
    }
    return datasetLines.map(dsl => ({
      color: dsl.color,
      points: [0, height, ...dsl.points, width, height],
      width,
    }));
  }, [area, datasetLines, width, height]);
  const lines = useMemo(() => {
    const lines = [];
    for (const line of lines_) {
      if (typeof line.position !== 'number') {
        continue;
      }
      const computed: any = {x: 0, y: 0, width, height};
      let range = Math.abs(xRange[1] - xRange[0]);
      let rangeStart = xRange[0];
      let prop = 'x';
      let totalRangePX = width;

      if (line.direction === 'horizontal') {
        rangeStart = yRange[0];
        range = Math.abs(yRange[1] - yRange[0]);
        prop = 'y';
        totalRangePX = height;
      }

      const diffMS = line.position - rangeStart;
      if (diffMS < 0) {
        continue;
      }
      computed[prop] = (diffMS / range) * totalRangePX;

      if (line.direction === 'horizontal') {
        computed[prop] = height - computed[prop];
        computed.x -= xTickSize / 2;
        computed.width += xTickSize;
      }

      lines.push({
        ...line,
        ...computed,
      });
    }
    return lines;
  }, [lines_, xRange, width, yRange, height, xTickSize]);
  const markAreas = useMemo(() => {
    const markAreas = [];
    const calcX = pixelInRangeFactory(xRange, width);
    for (const markArea of markAreas_) {
      if (typeof markArea.from !== 'number' || typeof markArea.to !== 'number') {
        continue;
      }
      markAreas.push({
        ...markArea,
        x: calcX(markArea.from),
        width:
          calcX(markArea.to) -
          calcX(markArea.from) +
          (markArea.to === xRange[1] ? xTickSize / 2 : 0),
      });
    }
    return markAreas;
  }, [markAreas_, xRange, width, xTickSize]);
  const cachedTooltipsByXLabel = useMemo(() => {
    return groupBy(
      flatten(
        datasets.map((ds, datasetIndex) => {
          return ds.data.map((item, itemIndex) => ({
            groupBy: getGroupByPropertyValue(datasetIndex, itemIndex),
            datasetIndex,
            datasetId: ds.id,
            itemIndex,
          }));
        })
      ),
      'groupBy'
    );
  }, [datasets, getGroupByPropertyValue]);
  const errorBars = useMemo(() => {
    if (!errorBar) {
      return;
    }
    return datasets.map(ds => {
      const range =
        ds?.yAxis === AXIS_VARIANT.SECONDARY && secondaryYRange ? secondaryYRange : yRange;
      const calcY = pixelInRangeFactory(range, height, true);
      const calcX = pixelInRangeFactory(xRange, width);
      const upper = ds.data
        .filter(item => exists(item.upper))
        .map(item => [calcX(item.x as number), calcY(item.upper)]);
      const lower = ds.data
        .filter(item => exists(item.lower))
        .map(item => [calcX(item.x as number), calcY(item.lower)])
        .reverse();
      return {
        points: flatten([...upper, ...lower]),
        fill: colorAlphaTransformer(ds.color, 0.2),
      };
    });
  }, [datasets, errorBar, height, width, xRange, yRange, secondaryYRange]);
  const renderTooltipValue = (datasetIndex: number, itemIndex: number) => {
    const renderDs = (dsIdx, pIdx) => {
      const ds = datasets[dsIdx];
      const item = ds.data[pIdx];
      let suffix;
      let fractionDigit;
      if (ds?.yAxis === AXIS_VARIANT.SECONDARY) {
        suffix = secondaryYLabelSuffix || '';
        fractionDigit = yAxisFractionDigit.secondary;
      } else {
        suffix = yLabelSuffix;
        fractionDigit = yAxisFractionDigit.primary;
      }
      // We're adding 1 to the fraction digit, in order to avoid
      // rounding to the closest tick and show the value
      // in the tooltip with higher accuracy
      const itemValue = number2k(item.y, fractionDigit ? fractionDigit + 1 : undefined);

      const showDelta = showTooltipPreviousValueDelta && pIdx > 0;
      const deltaSign = showDelta ? item.y - ds.data[pIdx - 1]?.y > 0 : null;
      const deltaSignChar = deltaSign ? '+' : '-';
      const fractionDigitParam = fractionDigit ? fractionDigit + 1 : 0;
      const delta = showDelta ? number2k(item.y - ds.data[pIdx - 1]?.y, fractionDigitParam) : null;
      const secondaryXLabel = item.actualX
        ? formatXDateLabelWithRangeTimeUnit({
            value: item.actualX,
            timeUnit: ticksRangeTimeUnitFromProps,
            dateFormat,
          }).toUpperCase()
        : undefined;
      return (
        <div key={dsIdx} className={chartClasses.DatasetInfo}>
          <div className={chartClasses.LabelWrapper}>
            <div className={chartClasses.DatasetLabel}>
              <div className={chartClasses.Dash} style={{backgroundColor: ds.color}} />
              <div className={chartClasses.Label}>{ds.label}</div>
            </div>
            {secondaryXLabel && secondaryXLabel !== tooltipData.label && (
              <div className={chartClasses.ActualX}>{secondaryXLabel}</div>
            )}
          </div>
          <div className={chartClasses.ValueWrapper}>
            <span className={chartClasses.Value}>
              {itemValue}
              {suffix}
              {delta && (
                <span className={chartClasses.Delta}>{`${deltaSignChar}${delta}${suffix}`}</span>
              )}
              {exists(item.lower) && exists(item.upper) && (
                <span className={chartClasses.CI}>
                  ({number2k(item.lower, fractionDigitParam)}
                  {suffix}, {number2k(item.upper, fractionDigitParam)}
                  {suffix})
                </span>
              )}
            </span>
          </div>
        </div>
      );
    };

    const dataset = datasets[datasetIndex];
    const item = dataset.data[itemIndex];
    const related = item ? cachedTooltipsByXLabel[item.x] || [] : [];

    return (
      <div className={chartClasses.DatasetInfoContainer}>
        {renderDs(datasetIndex, itemIndex)}
        {related
          .filter(rel => rel.datasetId !== dataset.id)
          .map(rel => renderDs(rel.datasetIndex, rel.itemIndex))}
      </div>
    );
  };
  const renderTooltipLabel = () => {
    return (
      <div className={chartClasses.TooltipLabelContainer}>
        <span className={chartClasses.Label}>{tooltipData.label}</span>
        {tooltipData.markedValue && (
          <div
            style={{
              color: tooltipData.markedValue ? tooltipData.color : undefined,
            }}
            className={chartClasses.MarkedValue}
          >
            {tooltipData.pointTooltipTitle || pointTooltipTitle}
          </div>
        )}
      </div>
    );
  };
  const renderTooltipFooter = () => {
    if ((tooltipData.pointTooltipCta || pointTooltipCta) && tooltipData.clickable) {
      return (
        <>
          <img src={ClickAnimation} alt={''} />
          <span className={chartClasses.Text}>
            {tooltipData.pointTooltipCta || pointTooltipCta}
          </span>
        </>
      );
    }
  };
  const onMouseMove = useCallback(
    e => {
      const {
        evt,
        target: {parent},
      } = e;
      const x = evt.offsetX - parent.attrs.x;
      // If the mouse is on the incomplete mark (or 2 pixels next to),
      // don't show the controlled tooltip. We will show the tooltip
      // that's managed by the chart context
      if (Boolean(incompleteMarkX) && Math.abs(incompleteMarkX - x) <= 2) {
        return setTooltipData(null);
      }
      const y = evt.offsetY - parent.attrs.y;
      const point = findClosestPoint(x, y, datasetPointsArrays);
      setTooltipData(point);
    },
    [datasetPointsArrays, setTooltipData, incompleteMarkX]
  );
  const extractDataPointValue = useCallback(
    (p: TransformedDatasetPoint): ExtractedDataPoint => {
      const {value} = p;
      return {
        x: value.x,
        y: value.y,
        itemIndex: p.itemIndex,
        label: p.label,
        isDate: exists(xRangeMS),
        metadata: p.metadata,
      };
    },
    [xRangeMS]
  );

  /**
   * Render
   */
  return (
    <EnhancedGroup
      onMouseLeave={() => setTooltipData(null)}
      width={width}
      height={height}
      x={x}
      y={y}
    >
      {markAreas.map((markArea, idx) => (
        <ChartMarkArea
          key={idx}
          height={height}
          dateFormat={dateFormat}
          timeUnit={ticksRangeTimeUnitFromProps}
          {...markArea}
        />
      ))}
      {datasetLines.map((dsl, datasetIndex) => (
        <React.Fragment key={dsl.key}>
          {area && (
            <Polygon
              color={areas[datasetIndex].color}
              points={areas[datasetIndex].points}
              width={areas[datasetIndex].width}
              fill={colorAlphaTransformer(areas[datasetIndex].color, 0.3)}
              opacity={tooltipData && !dsl.isSelected ? 0 : 1}
            />
          )}
          {/* Confidence intervals */}
          {errorBars && errorBars[datasetIndex] && (
            <EnhancedLine
              points={errorBars[datasetIndex].points}
              fill={errorBars[datasetIndex].fill}
              opacity={tooltipData && !dsl.isSelected ? OPACITY_LOW : 1}
              closed
            />
          )}
          {/* Highlight */}
          <EnhancedLine
            onClick={onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined}
            points={curve ? curveLine(dsl.points, 0.5) : dsl.points}
            lineJoin={'round'}
            strokeWidth={6}
            stroke={dsl.highlight ? style.highlightColor : dsl.color}
            visible={dsl.highlight}
            opacity={tooltipData && !dsl.isSelected ? OPACITY_LOW : 1}
          />
          {/* Regular line */}
          <EnhancedLine
            onClick={onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined}
            points={curve ? curveLine(dsl.points, 0.5) : dsl.points}
            stroke={dsl.color}
            strokeWidth={2.5}
            lineJoin={'round'}
            dash={dsl.dashed ? DASHED : undefined}
            opacity={tooltipData && !dsl.isSelected ? OPACITY_LOW : 1}
            shadowBlur={3}
            shadowColor={'rgba(66, 68, 80, 0.2)'}
            shadowOffsetY={1}
            shadowOffsetX={0}
          />
        </React.Fragment>
      ))}
      {/* Trend Marks */}
      {datasetMarks.length > 0 &&
        datasetMarks.map((trMark, idx) => <TrendMark key={`trend_mark_${idx}`} {...trMark} />)}
      {/* Incomplete Mark */}
      {showIncompleteMark && (
        <EnhancedGroup onMouseEnter={() => setTooltipData(null)}>
          <IncompleteMark
            height={height}
            x={incompleteMarkX}
            xLabel={xLabel}
            incompleteMarkDescription={incompleteMarkDescription}
            incompleteMarkWidth={width - lastXPointOnChart}
          />
        </EnhancedGroup>
      )}
      {/* Tooltip dashed vertical line */}
      {tooltipData && (
        <>
          <EnhancedLine
            points={[tooltipData.x, 0, tooltipData.x, height]}
            stroke={focusColor}
            opacity={0.5}
            strokeWidth={1}
            dash={DASHED}
          />
          <Html>
            <LineChartTooltip
              x={tooltipData.x}
              y={tooltipData.y}
              style={{
                fontName: style.fontName,
              }}
              rootSize={{
                width,
                height,
              }}
              yOffset={5}
              xOffset={10}
              show
              data={{
                label: renderTooltipLabel(),
                value: renderTooltipValue(tooltipData.datasetIndex, tooltipData.itemIndex),
                footer: renderTooltipFooter(),
              }}
            />
          </Html>
        </>
      )}
      {/*
        This transparent rectangle ↓ provides the layer that
        exposes the mouse position relative to the group.
        We're using react-konva Rect instead of EnhancedRect
        so that other (enhanced) elements will have the ability
        to handle mouse events properly (without collisions).
      */}
      <Rect
        width={incompleteMarkX || width}
        height={height}
        stroke={'transparent'}
        strokeWidth={0}
        onMouseMove={onMouseMove}
      />
      {/* Render lines */}
      <EnhancedGroup onMouseEnter={() => setTooltipData(null)}>
        {lines.map((line, idx) => (
          <ChartLine key={idx} height={height} width={width} {...line} />
        ))}
      </EnhancedGroup>
      {/* Render Mark Areas */}
      {showDots &&
        datasetPointsArrays.map(dsp =>
          dsp.map(p => {
            let showPoint;
            if (p.markedValue) {
              // Always show on the line chart marked values
              showPoint = true;
            } else if (dsp.length === 1) {
              // Always show if there's only one point
              showPoint = true;
            } else {
              // Only show if there's a tooltip data and it's the same point
              showPoint = tooltipData && tooltipData.x === p.x && tooltipData.y === p.y;
            }
            return (
              <EnhancedCircle
                onClick={
                  onDatasetPointClick && p.clickable
                    ? e =>
                        onDatasetPointClick({
                          point: extractDataPointValue(p),
                          dataset: datasets[p.datasetIndex],
                        })
                    : undefined
                }
                cursorPointer={Boolean(onDatasetPointClick) && p.clickable}
                key={p.key}
                x={p.x}
                y={p.y}
                radius={5}
                fill={p.color}
                strokeEnabled={p.highlight || p.isSelected}
                stroke={p.isSelected ? focusColor : style.highlightColor}
                strokeWidth={0.7}
                center
                opacity={showPoint ? 1 : 0}
              />
            );
          })
        )}
    </EnhancedGroup>
  );
};
