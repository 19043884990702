import {createContext, useMemo} from 'react';
import {ExperimentMetric, ExperimentStatus, ExperimentVariant} from '../types';

export interface IExperimentContext {
  status: ExperimentStatus;
  metrics: {[key: string]: ExperimentMetric};
  variants: {[key: string]: ExperimentVariant};
  winnerVariantKey?: string;
}

export const ExperimentContext = createContext<IExperimentContext>({
  status: undefined,
  metrics: undefined,
  variants: undefined,
  winnerVariantKey: undefined,
});

interface ExperimentContextProps {
  status: ExperimentStatus;
  metrics: {[key: string]: ExperimentMetric};
  variants: {[key: string]: ExperimentVariant};
  winnerVariantKey?: string;
  children: any;
}

export const ExperimentContextProvider: React.FC<ExperimentContextProps> = (
  props: ExperimentContextProps
) => {
  const {status, metrics, variants, winnerVariantKey, children} = props;

  const contextValue = useMemo(
    () => ({
      status,
      metrics,
      variants,
      winnerVariantKey,
    }),
    [metrics, variants, winnerVariantKey, status]
  );

  return <ExperimentContext.Provider value={contextValue}>{children}</ExperimentContext.Provider>;
};
