import {
  ColumnType,
  DocumentIconType,
  HabitMomentDataType,
  HabitMomentFigureItemCausationType,
  HabitMomentFigureItemInstanceType,
  SmartTableFigureColumn,
} from '../../../../../types';
import * as React from 'react';
import {useMemo} from 'react';
import {capitalize, get} from 'lodash';
import classes from '../habit-moment-viewer.module.scss';
import {Button} from '../../../../../../../simple/controls/button/button.component';
import {HabitMomentViewMode, RangeByType} from '../habit-moment-viewer.component';
import pluralize from 'pluralize';
import {exists} from 'front-core';
import TransKeys from 'translations';
import {StarIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {GradientLabelDisplay} from '../../../../shared/display-columns/gradient-label-display.component';
import {TableMutedTest} from '../../../../base-viewers/smart-table-viewer/components/table-muted-text.component';
import {DocumentIcon} from '../../../../internal-viewers/document-icon.component';

interface Config {
  habitModelName: string;
  entity: string;
  selectedMode: HabitMomentViewMode;
  causationType?: HabitMomentFigureItemCausationType;
  higherIsBetter: boolean;
  isPercentageValue: boolean;
  selectedType: HabitMomentFigureItemInstanceType;
  onViewItem?: (key: string) => void;
  variantsMode?: boolean;
  correlationRange?: RangeByType;
  maxPotentialRange?: RangeByType;
  itemsHasTotalUsers?: boolean;
}

export const useHabitMomentColumnsHook = (config: Config) => {
  const {
    habitModelName,
    entity,
    selectedMode,
    causationType,
    higherIsBetter,
    isPercentageValue,
    onViewItem,
    selectedType,
    variantsMode = false,
    correlationRange,
    maxPotentialRange,
    itemsHasTotalUsers = false,
  } = config;
  const {t} = useDocumentTranslation();
  const tableColumns: SmartTableFigureColumn<any>[] = useMemo(() => {
    const columns: SmartTableFigureColumn<any>[] = [];
    variantsMode &&
      columns.push({
        key: 'star',
        title: '',
        type: ColumnType._RENDER_COLUMN,
        options: {
          sortable: false,
          filterable: false,
          width: '5.2rem',
          noPadding: true,
          suffixRight: item => (!item.star && exists(item.repeats) ? 'at least' : null),
        },
        render: i =>
          i.star ? (
            <div className={classes.StarWrapper}>
              <StarIcon className={classes.Star} />
            </div>
          ) : null,
      });
    !variantsMode &&
      columns.push({
        key: 'name',
        type: ColumnType.DEFAULT,
        title: capitalize(habitModelName),
        options: {
          sortable: true,
          filterable: true,
          width: '25%',
          bold: true,
          suffixRight: item => (exists(item.repeats) ? 'at least' : null),
          helperTextDataKey: 'info',
        },
        typeOptions: {
          commandDataKey: 'command',
        },
      });

    let repeatsTitle = t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.REPEATS.LABEL);
    let repeatsHelper = t(
      TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.REPEATS.HELPER_TEXT
    );
    let repeatsUnit = t(
      TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.REPEATS.UNIT_TIMES
    );
    if (selectedType === HabitMomentFigureItemInstanceType.REPEATED_DAYS) {
      repeatsTitle = t(
        TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.REPEATS_BY_DAYS.LABEL
      );
      repeatsUnit = t(
        TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.TIMEFRAME_DAYS.UNIT
      );
    }

    columns.push(
      {
        key: 'repeats',
        title: repeatsTitle,
        type: ColumnType._RENDER_COLUMN,
        asType: ColumnType.NUMERIC,
        options: {
          sortable: true,
          filterable: true,
          bold: true,
          width: '13.2rem',
          suffixRight: item => (exists(item.timeframeDays) ? 'within' : null),
          unit: repeatsUnit,
          helperText: repeatsHelper,
        },
        render: i => {
          let localRepeatsUnit = repeatsUnit;
          if (
            i.dataType === HabitMomentDataType.NUMERIC &&
            selectedType === HabitMomentFigureItemInstanceType.REPEATED_ACTIONS
          ) {
            localRepeatsUnit = t(
              TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.REPEATS.UNIT_TIMES_NUMERIC
            );
          }
          if (!i.repeats) {
            return <TableMutedTest text={'--'} />;
          }
          return (
            <div>
              <DocumentIcon icon={DocumentIconType.REPEAT} className={classes.RepeatIcon} />
              {i.repeats} {localRepeatsUnit}
            </div>
          );
        },
      },
      {
        key: 'timeframeDays',
        title: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.TIMEFRAME_DAYS.LABEL),
        type: ColumnType.NUMERIC,
        options: {
          sortable: true,
          filterable: true,
          unit: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.TIMEFRAME_DAYS.UNIT),
          bold: true,
          width: '12rem',
          borderRight: true,
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.TIMEFRAME_DAYS.HELPER_TEXT
          ),
        },
        typeOptions: {
          icon: DocumentIconType.CALENDAR,
        },
      },
      {
        key: 'adoptionRate',
        title: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.ADOPTION_RATE.LABEL),
        type: ColumnType.ADOPTION_RATE,
        options: {
          sortable: true,
          filterable: true,
          helperText: itemsHasTotalUsers
            ? t(
                TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.ADOPTION_RATE
                  .HELPER_TEXT_FEATURE_TREATMENT_REQUIRED
              )
            : t(
                TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.ADOPTION_RATE
                  .HELPER_TEXT
              ),
        },
        typeOptions: {
          valueDataKey: 'adoptionRate',
          totalDataKey: 'adoptionAbs',
          totalSuffix: capitalize(pluralize(entity)),
        },
      },
      {
        key: 'goalInAdopters',
        title: t(
          TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.GOAL_IN_ADOPTERS.LABEL
        ),
        type: ColumnType.VERSUS,
        options: {
          sortable: true,
          filterable: true,
          unit: isPercentageValue ? '%' : undefined,
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.GOAL_IN_ADOPTERS.HELPER_TEXT
          ),
        },
        typeOptions: {
          valueDataKey: 'goalInAdopters',
          valueDataTitle: t(
            TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.GOAL_IN_ADOPTERS.LABEL
          ),
          versusDataKey: 'goalInNonAdopters',
          versusDataTitle: t(
            TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.GOAL_IN_NON_ADOPTERS.LABEL
          ),
          hideIcon: true,
        },
      }
    );

    if (selectedMode === HabitMomentViewMode.UPLIFT) {
      columns.push({
        key: 'uplift',
        title: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.UPLIFT.LABEL),
        type: ColumnType.CHANGE,
        options: {
          sortable: true,
          filterable: true,
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.UPLIFT.HELPER_TEXT
          ),
        },
        typeOptions: {
          higherIsBetter: higherIsBetter,
        },
      });
    } else if (selectedMode === HabitMomentViewMode.CORRELATION) {
      columns.push({
        key: 'correlation',
        title: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.CORRELATION.LABEL),
        type: ColumnType._RENDER_COLUMN,
        asType: ColumnType.NUMERIC,
        options: {
          sortable: true,
          filterable: true,
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.CORRELATION.HELPER_TEXT
          ),
        },
        render: i => {
          const cr = get(i, 'correlationRange', correlationRange);
          const relevantCorrelationRange: [number, number] = cr[selectedType];
          if (!exists(i.correlation)) {
            return <TableMutedTest text={'--'} />;
          }
          return (
            <GradientLabelDisplay
              min={relevantCorrelationRange[0]}
              max={relevantCorrelationRange[1]}
              value={i.correlation}
              maxColor={'#01C366'}
              minColor={'#B8F8D9'}
              square
            />
          );
        },
      });
    } else if (
      selectedMode === HabitMomentViewMode.CAUSATION &&
      causationType !== HabitMomentFigureItemCausationType.NO_RECOMMENDATION
    ) {
      columns.push({
        key: 'maxPotential',
        title: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.MAX_POTENTIAL.LABEL),
        type: ColumnType._RENDER_COLUMN,
        asType: ColumnType.NUMERIC,
        options: {
          sortable: true,
          filterable: true,
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.TABLE.HEADERS.MAX_POTENTIAL.HELPER_TEXT
          ),
        },
        render: i => {
          const cr = get(i, 'maxPotentialRange', maxPotentialRange);
          const relevantMaxPotentialRangeRange: [number, number] = cr[selectedType];
          if (!exists(i.maxPotential)) {
            return <TableMutedTest text={'--'} />;
          }
          return (
            <GradientLabelDisplay
              min={relevantMaxPotentialRangeRange[0] * 100}
              max={relevantMaxPotentialRangeRange[1] * 100}
              value={i.maxPotential}
              valueUpper={i.maxPotentialUpper}
              valueLower={i.maxPotentialLower}
              maxColor={'#01C366'}
              minColor={'#B8F8D9'}
              mode={
                causationType === HabitMomentFigureItemCausationType.INSIGNIFICANT
                  ? 'range'
                  : 'default'
              }
              suffix={'%'}
              tooltipLabel={t(
                TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.LABELS
                  .CONFIDENCE_INTERVAL
              )}
            />
          );
        },
      });
    } else if (
      selectedMode === HabitMomentViewMode.CAUSATION &&
      causationType === HabitMomentFigureItemCausationType.NO_RECOMMENDATION
    ) {
      columns.push({
        key: 'info',
        title: 'Info',
        type: ColumnType.DEFAULT,
        options: {
          width: '40%',
        },
      });
    }
    !variantsMode &&
      columns.push({
        key: 'actions',
        title: '',
        type: ColumnType._RENDER_COLUMN,
        options: {
          showOnHover: true,
          width: '2rem',
        },
        render: i => {
          let content;
          if (i.instancesCount <= 1) {
            return null;
          } else {
            content = (
              <Button
                className={classes.Button}
                onClick={() => onViewItem(i.key)}
                variant={'outlined'}
              >
                {t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VIEW_ALL_VARIATIONS)}
              </Button>
            );
          }

          return <div className={classes.Action}>{content}</div>;
        },
      });

    variantsMode &&
      causationType !== HabitMomentFigureItemCausationType.NO_RECOMMENDATION &&
      columns.push({
        key: 'info',
        title: '',
        type: ColumnType.INFO,
        options: {width: '4rem'},
      });

    return columns;
  }, [
    habitModelName,
    higherIsBetter,
    isPercentageValue,
    selectedMode,
    selectedType,
    onViewItem,
    correlationRange,
    variantsMode,
    causationType,
    entity,
    t,
  ]);

  return tableColumns;
};
