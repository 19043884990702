import {every, flatten, some} from 'lodash';
import {TreeData, TreeDataValue, TreeSelectType} from './tree-selector.component';
import {toArray} from 'front-core';

export function getTreeChildrenValues(tree: TreeData, onlyLeaf = true, ord = 0) {
  if (!tree.children || tree.children.length === 0) {
    return tree.value;
  }
  const arr = [];
  if (onlyLeaf === false && ord > 0) {
    arr.push(tree.value);
  }
  return flatten([...arr, ...tree.children.map(c => getTreeChildrenValues(c, onlyLeaf, ord + 1))]);
}

export function getSelectType(values: Set<any>, children: any[]): TreeSelectType {
  const res = children.map(i => values.has(i));
  if (every(res)) {
    return TreeSelectType.FULL;
  }
  if (some(res)) {
    return TreeSelectType.PARTIAL;
  }
  return TreeSelectType.NONE;
}

export function createTreeNodesInfo(
  tree: TreeData[],
  ord: number = 0,
  rootParentValue_: TreeDataValue = undefined
) {
  let res = {};
  for (const i of tree) {
    const isParent = i.children && i.children.length > 0;
    const rootParentValue = ord === 0 ? i.value : rootParentValue_;
    res[i.value] = {
      value: i.value, // value might be a number, which is not allowed as a key of dict
      leafsValues: toArray(getTreeChildrenValues(i)),
      childrenValues: toArray(getTreeChildrenValues(i, false)),
      rootParentValue,
      ord,
    };
    if (isParent) {
      res = {
        ...res,
        ...createTreeNodesInfo(i.children, ord + +1, rootParentValue),
      };
    }
  }
  return res;
}
