import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './kpi-segment-modal.module.scss';
import {KPISegmentGroup} from '../../kpi-segmentation-viewer.component';
import {
  CommandType,
  KPISegmentationType,
  KPISegmentationUpliftMode,
  MetricSampleData,
} from '../../../../../../types';
import {NextPrevNavigator} from '../../../../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {ModelType} from '../../../../../../../../../consts/model-type';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {KPISegmentGroupTable} from '../kpi-segment-group-table/kpi-segment-group-table.component';
import {
  SegmentationModalHeader,
  SegmentationModalMode,
} from '../../../../../shared/segmentation/segmentation-modal-header/segmentation-modal-header.component';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {keyBy} from 'lodash';
import {CompareSegmentsButton} from '../../../../../shared/segmentation/compare-segments-button/compare-segments-button.component';
import {LineChart} from '../../../../../../../charts/charts/line-chart/line-chart.component';
import {FancyBlock} from '../../../../../../../../simple/data-display/fancy-block/fancy-block.component';

interface OwnProps {
  group: KPISegmentGroup;
  allPopulation: MetricSampleData[];
  granularity: string;
  kpiName: string;
  goalValue: number;
  groupOptions: string[];
  onSelectGroup: (group: string) => void;
  entity: string;
  totalEntities: number;
  isPercentageValue?: boolean;
  higherIsBetter?: boolean;
  showOvertime?: boolean;
  isSumMode?: boolean;
  className?: string;
}

type AllProps = OwnProps;

function getHelperText(isInformative: boolean, isSignificant: boolean, helperText: string) {
  if (isInformative === false) {
    return {
      nameHelperText: helperText,
    };
  }
  if (!isSignificant) {
    return {
      upliftHelperText: helperText,
    };
  }
  return {};
}

export const KPISegmentationModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    group,
    goalValue,
    allPopulation,
    groupOptions: groupOptionsFromProps,
    granularity,
    kpiName,
    onSelectGroup,
    entity,
    totalEntities,
    showOvertime,
    isPercentageValue,
    higherIsBetter,
    isSumMode,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const [upliftMode, setUpliftMode] = useState<KPISegmentationUpliftMode>(
    KPISegmentationUpliftMode.VS_OTHERS
  );
  const [mode, setMode] = useState(SegmentationModalMode.DEFAULT);
  const [comparedSegmentKeys, setComparedSegmentKeys] = useState<string[]>([]);
  const segmentsByKey = useMemo(() => keyBy(group.segments, 'key'), [group.segments]);

  const groupOptions = useMemo(
    () =>
      groupOptionsFromProps.map(g => ({
        value: g,
        label: g,
      })),
    [groupOptionsFromProps]
  );
  const onCompareClicked = useCallback(() => {
    setMode(SegmentationModalMode.COMPARE);
  }, [setMode]);
  const onSegmentClicked = useCallback((key: string) => {
    setComparedSegmentKeys([key]);
    setMode(SegmentationModalMode.COMPARE);
  }, []);
  const navigatorProps = useMemo(() => {
    if (groupOptions.length === 0) {
      return;
    }
    const currentIndex = groupOptions.findIndex(i => i.value === group?.name);
    const maxIndex = groupOptions.length - 1;
    return {
      currentIndex,
      maxIndex,
      onNextPrev: (isNext: boolean) => {
        setComparedSegmentKeys([]);
        setMode(SegmentationModalMode.DEFAULT);
        onSelectGroup(groupOptions[currentIndex + (isNext ? 1 : -1)].value);
      },
      nextLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.NEXT_LABEL),
      prevLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.PREV_LABEL),
    };
  }, [groupOptions, group.name, onSelectGroup, t]);
  const tableData = useMemo(
    () =>
      group.segments.map(s => {
        const isSignificant = s.type !== KPISegmentationType.INSIGNIFICANT;
        return {
          key: s.key,
          name: s.segmentName[0],
          signalId: s.signalId[0],
          count: s.count,
          share: s.share * 100,
          goalInSegment: isPercentageValue ? s.goalInSegment * 100 : s.goalInSegment,
          goalInNonSegment: isPercentageValue ? s.goalInNonSegment * 100 : s.goalInNonSegment,
          goalInInformativeNonSegment: s.goalInInformativeNonSegment
            ? isPercentageValue
              ? s.goalInInformativeNonSegment * 100
              : s.goalInInformativeNonSegment
            : undefined,
          goalValue: isPercentageValue ? goalValue * 100 : goalValue,
          uplift: s.uplift * 100,
          informativeUplift: s.informativeUplift ? s.informativeUplift * 100 : undefined,
          avgUplift: s.avgUplift ? s.avgUplift * 100 : undefined,
          isSignificant: isSignificant,
          isInformative: s.isInformative,
          command: {
            type: CommandType.REF,
            payload: {
              type: ModelType.SIGNAL,
              refId: s.signalId[0],
            },
          },
          ...getHelperText(s.isInformative, isSignificant, s.helperText),
        };
      }),
    [group.segments, isPercentageValue, goalValue]
  );
  const chartData = useMemo(() => {
    const segments = comparedSegmentKeys.map(k => segmentsByKey[k]);
    const kpiDataset = allPopulation
      ? {
          id: 'kpi',
          label: kpiName,
          data: allPopulation.map(s => ({
            x: s.datetime,
            y: isPercentageValue ? s.value * 100 : s.value,
          })),
        }
      : undefined;
    const segmentsDatasets = segments.map(segment => {
      const data = segment.samples.map(s => ({
        x: s.datetime,
        y: isPercentageValue ? s.value * 100 : s.value,
      }));
      return {
        id: segment.key,
        label: segment.segmentName,
        data,
      };
    });
    const datasets = kpiDataset ? [kpiDataset, ...segmentsDatasets] : segmentsDatasets;

    return {
      datasets: datasets,
      displayedDatasetIds: datasets.map(d => d.id),
      options: {
        labels: {
          dateFormat: 'DD MMM',
          type: 'date',
          timeUnit: granularity,
        },
        yLabelSuffix: isPercentageValue ? '%' : undefined,
        yAxisMaxTicks: 5,
        xLabel: 'date',
      },
    } as any;
  }, [comparedSegmentKeys, segmentsByKey, isPercentageValue, kpiName, allPopulation]);

  return (
    <ModalWrapper
      isOpen={group}
      onClose={() => onSelectGroup(null)}
      width={'100rem'}
      height={'60rem'}
    >
      <div className={classNames(classes.KPISegmentationModal, className)}>
        <SegmentationModalHeader
          title={'KPI for segment'}
          selectedGroup={group?.name}
          groupOptions={groupOptions}
          onSelectGroup={onSelectGroup}
          mode={mode}
          onBack={() => setMode(SegmentationModalMode.DEFAULT)}
          compareSegmentsSuffix={t(
            TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.MODAL.TITLE.COMPARE_SEGMENTS_PREFIX
          )}
        />
        {mode === SegmentationModalMode.DEFAULT && (
          <>
            <KPISegmentGroupTable
              tableData={tableData}
              higherIsBetter={higherIsBetter}
              isPercentageValue={isPercentageValue}
              entity={entity}
              totalEntities={totalEntities}
              upliftMode={upliftMode}
              isSumMode={isSumMode}
              onChangeUpliftMode={setUpliftMode}
              selectedKeys={comparedSegmentKeys}
              onChangedSelectedKeys={setComparedSegmentKeys}
              multiSelection={showOvertime}
              onClick={showOvertime ? onSegmentClicked : undefined}
              buttonText={
                showOvertime
                  ? t(
                      TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.MODAL.ACTIONS.VIEW_OVER_TIME
                    )
                  : undefined
              }
            />
            {showOvertime && (
              <div className={classes.CompareLine}>
                <CompareSegmentsButton
                  onClick={() => onCompareClicked()}
                  count={comparedSegmentKeys.length}
                />
              </div>
            )}
          </>
        )}
        {mode === SegmentationModalMode.COMPARE && (
          <FancyBlock title={'KPI Overtime'} addPadding>
            <div className={classes.Chart}>
              <LineChart {...chartData} />
            </div>
          </FancyBlock>
        )}
        <div className={classes.Spacer} />
        {navigatorProps && (
          <div className={classes.Navigation}>
            <NextPrevNavigator {...navigatorProps} />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

KPISegmentationModal.defaultProps = {
  showOvertime: false,
};
