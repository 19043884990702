import classes from './journey-drivers-scope.module.scss';
import {Button} from '../../../../../../../simple/controls/button/button.component';
import {useState, useCallback, useMemo} from 'react';
import {
  JourneyDriversSimulationFigureData,
  JourneyDriversSimulationFigureOptions,
} from '../../../../../types/figures.types';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {JourneyDriversScopeModal} from './components/journey-drivers-scope-modal/journey-drivers-scope-modal.component';
import {IEventsFilters, JourneyDriversScopeFilters} from './journey-drivers-scope.consts';
import {number2k} from 'front-core';
import {useDocumentTracking} from '../../../../../hooks/use-document-tracking.hook';
import {DocumentElementType} from '../../../../../types';
import classNames from 'classnames';

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.JOURNEY_DRIVERS_SCOPE_FIGURE;

interface OwnProps {
  figureId: string;
  data: JourneyDriversSimulationFigureData;
  options: JourneyDriversSimulationFigureOptions;
  filters?: JourneyDriversScopeFilters;
  onApplyFilters: (filters: IEventsFilters) => void;
  className?: string;
}

type AllProps = OwnProps;

export const JourneyDriversScopeViewer: React.FC<AllProps> = (props: AllProps) => {
  const {
    figureId,
    data,
    options,
    filters,
    onApplyFilters: onApplyFiltersFromProps,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {trackItemClicked} = useDocumentTracking(
    figureId,
    DocumentElementType.JOURNEY_DRIVERS_SIMULATION_FIGURE
  );
  const {calculatedSequencesCount, displayedSequencesCount} = data.scope;

  const noticeTransKeys = useMemo(() => {
    const hasFiltersApplied =
      filters?.sequence_filter_event_min_adoption !== undefined ||
      filters?.sequence_filter_event_max_adoption !== undefined ||
      filters?.sequence_filter_included_events !== undefined ||
      filters?.sequence_filter_excluded_events !== undefined;

    return hasFiltersApplied ? TRANS_KEYS_PREFIX.FILTERS_APPLIED : TRANS_KEYS_PREFIX.NO_FILTERS;
  }, [filters]);

  const onEditClick = useCallback(() => {
    setIsModalOpen(true);
    trackItemClicked('edit_scope_open_modal_clicked', {});
  }, [setIsModalOpen, trackItemClicked]);

  const onApplyFilters = useCallback(
    (filters: IEventsFilters) => {
      onApplyFiltersFromProps(filters);
      trackItemClicked('edit_scope_submit_clicked', {});
    },
    [onApplyFiltersFromProps, trackItemClicked, setIsModalOpen]
  );

  return (
    <div className={classNames(classes.JourneyScopeDrivers, className)}>
      <span className={classes.Notice}>
        {t(noticeTransKeys.NOTICE_MESSAGE, {
          calculatedSequencesCount: number2k(calculatedSequencesCount),
          displayedSequencesCount: number2k(displayedSequencesCount),
        })}
      </span>
      <Button className={classes.Button} onClick={onEditClick}>
        {t(noticeTransKeys.NOTICE_BUTTON_LABEL)}
      </Button>
      <JourneyDriversScopeModal
        data={data}
        options={options}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        filters={filters}
        onApplyFilters={onApplyFilters}
      />
    </div>
  );
};

JourneyDriversScopeViewer.defaultProps = {};
