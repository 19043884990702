import classNames from 'classnames';
import classes from './analyses-views.module.scss';
import {AnalysisFolder} from '../../../../../../objects/models/analysis-folder.model';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {AnalysisSubFolders} from '../analysis-sub-folders/analysis-sub-folders.component';
import {AnalysisResultList} from '../../../../components/analysis-result-list/analysis-result-list.component';
import {useAnalysisResultActions} from '../../../../hooks/use-analysis-result-actions.hook';
import {useAnalysisResultList} from '../../../../hooks/use-analysis-result-list.hook';
import {useAnalysisFolderActions} from '../../../../hooks/use-analysis-folder-actions.hook';
import {exists} from 'front-core';
import {AnalysesEmptyState} from '../../../../components/analyses-empty-state/analyses-empty-state.component';
import {useAnalysisTypesCatalog} from '../../../../hooks/use-analysis-types-catalog.hook';
import {ANALYSIS_TYPE_ID_PATH_PARAM} from '../../../../../../constants/app-routes';
import {Checkbox, Filters, SkeletonLoading} from 'ui-components';
import {NRC} from '../../../../../../http';
import {AnalysisResultFiltersDTO} from '../../../../../../objects/dto/analysis-result-filters.dto';
import {AnalysesListPlaceholder} from '../general/analyses-list-placeholder/analyses-list-placeholder.component';
import {useMemo} from 'react';
import {
  analysisResultsSingleAnalysisTypeFilter,
  queryFilter,
} from '../../../../../../constants/filters';
import {CircularProgress} from '@material-ui/core';
import {useCurrentUser} from '../../../../../../core/hooks/use-user.hook';
import {EmptyState} from '../../../../../shared/components/general/override';

interface OwnProps {
  key_?: string | number;
  header: any;
  children?: Partial<AnalysisFolder>[];
  extraFilters?: any;
  analysisFolder?: AnalysisFolder;
  onFolderClicked?: (analysisFolderId: number | string) => void;
  onCreateAnalysis?: (parameters?: any) => void;
  networkRequest?: NRC<void, AnalysisResultFiltersDTO>;
  isLoading?: boolean;
  customEmptyState?: any;
  showParticipants?: boolean;
  showFilters?: boolean;
  isPrivateFolder?: boolean;
  disableEditing?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const BaseAnalysesView = (props: AllProps) => {
  const {
    key_,
    header,
    children,
    extraFilters,
    showParticipants,
    onFolderClicked,
    onCreateAnalysis,
    networkRequest,
    isLoading,
    customEmptyState,
    showFilters,
    disableEditing,
    isPrivateFolder,
    className,
  } = props;
  const {t} = useTranslation();
  const {analysisTypes} = useAnalysisTypesCatalog({limit: 3});
  const analysisResultActions = useAnalysisResultActions();
  const analysisFolderActions = useAnalysisFolderActions();
  const user = useCurrentUser();
  const filtersDef = useMemo(() => [queryFilter(), analysisResultsSingleAnalysisTypeFilter()], []);
  const {
    listsData,
    onSortTable,
    filters,
    onPageChange,
    onFiltersChange,
    isLoading: isLoadingResults,
  } = useAnalysisResultList({
    extraDefaultFilters: extraFilters,
    key: key_,
    filtersDef,
    networkRequest,
  });
  const filterByRequestedByMe = filters['requestedByUserId'] === user.id;
  const showFilterByMe = useMemo(() => {
    if (isPrivateFolder) {
      return false;
    }
    if (filterByRequestedByMe) {
      return true;
    }
    return !isLoading && listsData && listsData.meta.total > 0;
  }, [filterByRequestedByMe, isPrivateFolder, isLoading, listsData]);

  return (
    <div className={classNames(classes.BaseAnalysesView, className)}>
      <div className={classes.Header}>
        {isLoading ? <SkeletonLoading className={classes.HeaderPlaceholder} /> : header}
      </div>
      {(isLoading || children?.length > 0) && (
        <>
          <div className={classes.Title}>
            <div className={classes.Label}>
              {t(TransKeys.ANALYSES_MAIN.VIEW_FOLDER.SECTIONS.FOLDERS.TITLE, {
                count: isLoading ? ('Loading...' as any) : children?.length,
              })}
            </div>
          </div>
          <AnalysisSubFolders
            analysisFolders={children || []}
            onFolderClicked={onFolderClicked}
            className={classes.Folders}
            showParticipants={showParticipants}
            isLoading={isLoading}
            {...analysisFolderActions}
          />
        </>
      )}
      <div className={classes.Title}>
        <div className={classes.Label}>
          {t(TransKeys.ANALYSES_MAIN.VIEW_FOLDER.SECTIONS.ANALYSES.TITLE, {
            count:
              isLoading || (isLoadingResults && !exists(listsData?.meta?.total))
                ? 'Loading...'
                : listsData?.meta?.total,
          })}
          {isLoadingResults && (
            <div className={classes.Loading}>
              <CircularProgress
                variant={'indeterminate'}
                size={20}
                thickness={4}
                color={'inherit'}
              />
            </div>
          )}
        </div>
        <div className={classes.Actions}>
          {showFilters && (
            <Filters
              selected={filters}
              onChange={onFiltersChange}
              onClearAll={() => onFiltersChange(undefined)}
              freeSearchFilterKey={'q'}
              filters={filtersDef}
            />
          )}
          {showFilterByMe && (
            <Checkbox
              label={t(TransKeys.ANALYSES_MAIN.ACTIONS.SHOW_ONLY_USER_CREATED_ANALYSES_FILTER)}
              checked={filterByRequestedByMe}
              className={classes.FilterByMeCheckbox}
              onChange={() =>
                onFiltersChange({
                  requestedByUserId: filterByRequestedByMe ? undefined : user.id,
                })
              }
              disabled={isLoadingResults}
              border
            />
          )}
        </div>
      </div>
      {(isLoading || (isLoadingResults && !exists(listsData?.meta?.total))) && (
        <AnalysesListPlaceholder />
      )}
      {!isLoading && listsData && listsData.meta.total > 0 && (
        <div className={classes.TableWrapper}>
          <AnalysisResultList
            className={classes.Table}
            {...analysisResultActions}
            disableEditing={disableEditing}
            showFolderColumn={!exists(children) || children?.length > 0}
            onFolderClicked={onFolderClicked}
            data={listsData.list}
            total={listsData.meta.total}
            page={listsData.meta.page}
            perPage={listsData.meta.numPerPage}
            onSort={onSortTable}
            order={filters?.order?.[0]}
            orderBy={filters?.orderBy?.[0]}
            onPageChange={onPageChange}
            isLoading={isLoadingResults}
            emptyStateProps={{
              title: t(TransKeys.ANALYSES.EMPTY_STATE.TITLE),
              subTitle: t(TransKeys.ANALYSES.EMPTY_STATE.SUB_TITLE),
              buttonText: t(TransKeys.GENERAL.ACTIONS.REQUEST_ANALYSIS),
              background: false,
            }}
          />
        </div>
      )}
      {!isLoading && listsData && listsData.meta?.total === 0 && !showFilterByMe && (
        <div className={classes.EmptyStateWrapper}>
          {!customEmptyState && (
            <AnalysesEmptyState
              className={classes.SelectAnalysisState}
              analysisTypes={(analysisTypes as any) || []}
              onSelect={(analysisTypeId: number) =>
                onCreateAnalysis({
                  [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeId,
                })
              }
            />
          )}
          {exists(customEmptyState) && customEmptyState}
        </div>
      )}
      {!isLoading && listsData && listsData.meta?.total === 0 && showFilterByMe && (
        <EmptyState
          title={t(TransKeys.ANALYSES.FILTER_MY_ANALYSES_EMPTY_STATE.TITLE)}
          subTitle={t(TransKeys.ANALYSES.FILTER_MY_ANALYSES_EMPTY_STATE.SUB_TITLE)}
        />
      )}
    </div>
  );
};
