import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';
import {exists, number2k} from 'front-core';
import {FlexibleTableClasses} from '../general/flexible-table/flexible-table.component';
import {ArrowIconUp} from '../../../../../simple/controls/icons/icons.component';

interface OwnProps {
  value: number;
  uplift: number;
  higherIsBetter: boolean;
  isPercentageValue?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const ValueWithUpliftDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {value, uplift, higherIsBetter, isPercentageValue, className} = props;
  const isNegative = higherIsBetter ? uplift < 0 : uplift > 0;

  return (
    <div className={classNames(classes.ValueWithUpliftDisplay, className)}>
      <span>
        {number2k(isPercentageValue ? value * 100 : value)}
        {isPercentageValue ? '%' : ''}
      </span>
      {exists(uplift) && (
        <span className={classNames(FlexibleTableClasses.ShowOnHover, classes.Secondary)}>
          <span className={classNames(classes.Uplift, isNegative && classes.Negative)}>
            <ArrowIconUp className={classes.Icon} />
            {`${uplift > 0 ? '+' : ''}${number2k(uplift * 100)}%`}
          </span>
        </span>
      )}
    </div>
  );
};

ValueWithUpliftDisplay.defaultProps = {
  higherIsBetter: true,
};
