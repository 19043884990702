import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {RCASegmentationFigure} from '../../../../types';
import classes from './rca-segmentation-viewer.module.scss';
import {AppTabs} from '../../../../../../simple/navigation/app-tabs/app-tabs.component';
import {SegmentExplainersTable} from './components/segment-explainers-table/segment-explainers-table.component';
import {RCACompositeSegmentsViewer} from '../rca-composite-segments-viewer/rca-composite-segments-viewer.component';
import {extendSegments} from './rca-segmentation-viewer.utils';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {groupBy, keyBy, values} from 'lodash';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {SegmentExplainersModal} from './components/segment-explainers-modal/segment-explainers-modal.component';
import {SegmentChartOvertimeModal} from './components/segment-chart-overtime-modal/segment-chart-overtime-modal.component';
import {NoticePopup} from '../../../shared/general/notice-popup/notice-popup.component';
import {useRCASegmentationViewerNotice} from './hooks/use-rca-segmentation-viewer-notice.hook';
import {RCASegmentationViewMode} from '../rca-figure/rca-figure-viewer.types';
import {RCASegmentationCompositeModal} from './components/rca-segmentation-composite-modal/rca-segmentation-composite-modal.component';
import {CompositeChartOvertimeModal} from './components/composite-chart-overtime-modal/composite-chart-overtime-modal.components';

interface OwnProps extends RCASegmentationFigure {
  viewMode: RCASegmentationViewMode;
  className?: string;
}

type AllProps = OwnProps;

// this is deprecated in version 1.3, but we need to keep it for backward compatibility
export enum Tab {
  ALL_SEGMENTS_EXPLAINER = 'all_segments_explainer',
  COMPOSITE_TREND = 'composite_trend',
}

enum ModalType {
  SEGMENT_EXPLAINER = 'segment_explainer',
  SEGMENT_CHART_OVERTIME = 'segment_chart_overtime',
  COMPOSITE_CHART_OVERTIME = 'composite_chart_overtime',
  COMPOSITE_BREAKDOWN = 'composite_breakdown',
}

export const MAX_INTEREST_CHIP_TO_SHOW = 2;

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

export const RCASegmentationViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, version, data, viewMode = RCASegmentationViewMode.EXPLAIN, options} = props;
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const {t} = useDocumentTranslation();
  const {segments, composites = [], totalEntities, expectedTotalEntities, populationTrend} = data;
  const {compositeSegmentsFigure} = data;
  const [selectedGroupSignalId, setSelectedGroupSignalId] = useState<number | null>(null);
  const [selectedCompositeKey, setSelectedCompositeKey] = useState<string | null>(null);
  const [reviewedSegmentKey, setReviewedSegmentKey] = useState<string | null>(null);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const {noExplainersRef, noExplainersNotice} = useRCASegmentationViewerNotice({segments} as any);
  // this is deprecated in version 1.3, but we need to keep it for backward compatibility
  const {query: selectedTab, setQuery: setSelectedTab} = useDocQuery<Tab>(
    id,
    Tab.ALL_SEGMENTS_EXPLAINER,
    'tab'
  );

  const extendedSegments = useMemo(
    () => extendSegments(segments, totalEntities, expectedTotalEntities),
    [segments, totalEntities, expectedTotalEntities]
  );
  const extendedComposites = useMemo(
    () => extendSegments(composites, totalEntities, expectedTotalEntities),
    [composites, totalEntities, expectedTotalEntities]
  );
  const segmentGroups = useMemo(() => groupBy(extendedSegments, 'signalId'), [extendedSegments]);
  const compositeGroups = useMemo(() => keyBy(extendedComposites, 'key'), [extendedComposites]);
  const selectedGroup = useMemo(
    () => segmentGroups[selectedGroupSignalId],
    [segmentGroups, selectedGroupSignalId]
  );
  const segmentByKeys = useMemo(() => keyBy(extendedSegments, 'key'), [extendedSegments]);
  const selectedComposite = useMemo(() => {
    if (!selectedCompositeKey) {
      return null;
    }
    const selected = compositeGroups[selectedCompositeKey];
    const segments = selected.segmentKeys.map(k => segmentByKeys[k]);
    return {
      ...selected,
      segments,
    };
  }, [compositeGroups, selectedCompositeKey, segmentByKeys]);
  const groupOptions = useMemo(() => {
    return values(segmentGroups).map(g => ({
      label: g[0].segmentGroupName,
      value: g[0].signalId,
    }));
  }, [segmentGroups]);
  const onSelectGroupOvertime = useCallback(
    (signalId: number | null) => {
      setSelectedGroupSignalId(signalId);
      setReviewedSegmentKey(null);
    },
    [setSelectedGroupSignalId, setReviewedSegmentKey]
  );
  const tabs = useMemo(() => {
    const tabOptions = values(Tab);
    return tabOptions.map(tab => {
      if (tab === Tab.ALL_SEGMENTS_EXPLAINER) {
        return {
          key: tab,
          label: t(TransKeysPrefix.TABS.SEGMENT_EXPLAINERS),
          tabRef: noExplainersRef,
        };
      }
      return {
        key: tab,
        label: t(TransKeysPrefix.TABS[tab.toUpperCase()]),
      };
    });
  }, [t, noExplainersRef]);

  const renderContent = () => {
    switch (selectedTab) {
      case Tab.ALL_SEGMENTS_EXPLAINER:
        return (
          <SegmentExplainersTable
            segments={extendedSegments}
            composites={extendedComposites}
            populationTrend={populationTrend}
            viewMode={viewMode}
            options={options}
            isValidForExplainers={data.isValidForExplainers}
            showOvertimeChart={version >= 1.2}
            onSignalClicked={onSignalClick}
            onViewGroupChart={(signalId, segmentKey) => {
              setSelectedCompositeKey(null);
              setSelectedGroupSignalId(signalId);
              setReviewedSegmentKey(segmentKey);
              setModalType(ModalType.SEGMENT_CHART_OVERTIME);
            }}
            onViewGroupSegments={signalId => {
              setSelectedGroupSignalId(signalId);
              setModalType(ModalType.SEGMENT_EXPLAINER);
            }}
            onViewCompositeChart={(compositeKey: string) => {
              setSelectedCompositeKey(compositeKey);
              setModalType(ModalType.COMPOSITE_CHART_OVERTIME);
            }}
            onViewCompositeBreakdown={(compositeKey: string) => {
              setSelectedCompositeKey(compositeKey);
              setModalType(ModalType.COMPOSITE_BREAKDOWN);
            }}
          />
        );
      case Tab.COMPOSITE_TREND:
        // todo: remove - this figure is deprecated since 1.3
        return <RCACompositeSegmentsViewer {...compositeSegmentsFigure} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.RCASegmentationFigureViewer}>
      {selectedTab === Tab.ALL_SEGMENTS_EXPLAINER && (
        <NoticePopup
          {...noExplainersNotice}
          placement={'bottom-start'}
          zIndex={900}
          show={noExplainersNotice.show}
        />
      )}
      {viewMode === RCASegmentationViewMode.EXPLAIN && version < 1.3 && (
        <AppTabs
          onChange={setSelectedTab as any}
          tabs={tabs}
          selected={selectedTab}
          className={classes.Tabs}
        />
      )}
      {renderContent()}
      {selectedGroup && modalType === ModalType.SEGMENT_EXPLAINER && (
        <SegmentExplainersModal
          segments={selectedGroup}
          populationTrend={populationTrend}
          selectedGroupSignalId={selectedGroupSignalId}
          viewMode={viewMode}
          groupOptions={groupOptions}
          onSelectGroup={setSelectedGroupSignalId}
          options={options}
        />
      )}
      {selectedGroup && modalType === ModalType.SEGMENT_CHART_OVERTIME && (
        <SegmentChartOvertimeModal
          selectedGroupSignalId={selectedGroupSignalId}
          initialSegmentKey={reviewedSegmentKey}
          metricSamples={data.allPopulation}
          granularity={data.granularity}
          segments={selectedGroup}
          groupOptions={groupOptions}
          viewMode={viewMode}
          onSelectGroup={onSelectGroupOvertime}
          options={options}
        />
      )}
      {selectedComposite && modalType === ModalType.COMPOSITE_BREAKDOWN && (
        <RCASegmentationCompositeModal
          composite={selectedComposite}
          populationTrend={populationTrend}
          onClose={() => setModalType(null)}
          viewMode={viewMode}
          options={options}
        />
      )}
      {selectedComposite && modalType === ModalType.COMPOSITE_CHART_OVERTIME && (
        <CompositeChartOvertimeModal
          metricSamples={data.allPopulation}
          composite={selectedComposite}
          granularity={data.granularity}
          viewMode={viewMode}
          options={options}
          onClose={() => setModalType(null)}
        />
      )}
    </div>
  );
};
