import {createMuiTheme, ThemeOptions, Tooltip} from '@material-ui/core';

export const rootMuiTheme: ThemeOptions = {
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: 'rgba(36, 38, 69, 1)',
    },
    secondary: {
      main: 'rgba(52, 131, 255, 1)',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1.3rem !important',
        backgroundColor: '#f8f9fd !important',
        boxShadow: '0 0 6px 0 rgba(33, 33, 35, 0.2) !important',
        padding: '0.8rem 1.2rem !important',
        borderRadius: '6px !important',
        color: '#41445a !important',
        // @ts-ignore
        fontWeight: '400 !important',
      },
      arrow: {
        color: '#f8f9fd !important',
      },
    },
  },
};

// @ts-ignore
Tooltip.defaultProps = {
  // @ts-ignore
  ...(Tooltip.defaultProps || {}),
  interactive: true,
};

export const theme = createMuiTheme(rootMuiTheme);
