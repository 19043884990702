import {
  fixedMaxValue,
  fixedMinValue,
} from '../../base-viewers/smart-table-viewer/smart-table.utils';

export const calculateMinMaxShare = (items: Array<{share: number}>) => {
  const shareValues = items.map(i => i.share * 100);
  const minValue = fixedMinValue(shareValues);
  const maxValue = fixedMaxValue(shareValues);
  return {
    minValue: minValue,
    maxValue: maxValue,
  };
};
