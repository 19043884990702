import * as React from 'react';
import TransKeys from 'translations';
import {Button} from '../../../../../../simple/controls/button/button.component';
import {useTranslation} from '../../../../../../../core/translations/use-translation';

interface OwnProps {
  onClick?: () => void;
  count: number;
  className?: string;
}

type AllProps = OwnProps;

export const CompareSegmentsButton: React.FC<AllProps> = (props: AllProps) => {
  const {onClick, count, className} = props;
  const {t} = useTranslation();

  return (
    <Button
      className={className}
      onClick={onClick}
      disabled={count < 2}
      helperText={
        count < 2
          ? t(TransKeys.DOCUMENT_VIEWER.GENERAL.ACTIONS.COMPARE_SEGMENTS_DISABLED_HELPER)
          : ''
      }
      caps={false}
    >
      {count < 2
        ? t(TransKeys.DOCUMENT_VIEWER.GENERAL.ACTIONS.COMPARE_SEGMENTS_DISABLED)
        : t(TransKeys.DOCUMENT_VIEWER.GENERAL.ACTIONS.COMPARE_SEGMENTS, {
            count: count,
          })}
    </Button>
  );
};

CompareSegmentsButton.defaultProps = {};
