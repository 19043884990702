import {useCallback, useContext, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {AnalysisFormProps} from '../../analysis-forms.types';
import {createUndefinedObject} from '../../../../../utils/general.utils';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';
import {
  SignalSmartSelector,
  SignalSmartSelectorKey,
} from '../../../../shared/core/smart-selector/signal-smart-selector.component';
import {TreatmentSelector} from '../../components/ui-selectors/treatment-selector/treatment-selector.component';
import {values} from 'lodash';

export const GOAL_KEY = 'goal';
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const entitySchemaMapping = {
  entity: 'entity',
};
const treatmentsSelectorMap = {
  treatments_tag: 'treatment_signal_slug',
  treatments_signals: 'treatments_signals',
};
const GOAL_SELECTOR_INCLUDE = [
  SignalSmartSelectorKey.METRICS,
  SignalSmartSelectorKey.FEATURES,
  SignalSmartSelectorKey.SIGNALS,
];

const createGoalSignalFilters = (entityContext = undefined) => [
  {
    with_population_filter: true,
    type: SignalType.MEASURE,
    data_type: [SignalDataType.BOOLEAN, SignalDataType.INTEGER, SignalDataType.DECIMAL],
    entity_binding: TableEntityBinding.TWO_WAY,
    exclude_templates: '*',
    entityContext,
  },
  {
    with_population_filter: true,
    templates: ['dau', 'wau', 'mau'],
    entity_binding: TableEntityBinding.TWO_WAY,
    entityContext,
  },
];

const MIN_DAYS_TIME_FRAME = 42;

export const Analysis122Form = (props: AnalysisFormProps) => {
  const {onSignalInfo, className} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const entityContext = parameters[entitySchemaMapping.entity];
  const goalSignalFilters = useMemo(() => createGoalSignalFilters(entityContext), [entityContext]);
  const {productEntities, defaultTableEntity} = useProductData();

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_122', parameters => {
      const defaults = {};
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      return defaults;
    });

    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_122');
    };
  }, [registerDefaultHandler, removeDefaultHandler, defaultTableEntity]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetKeys = [
        GOAL_KEY,
        segmentFilterSchemaMapping.population_filter,
        ...values(treatmentsSelectorMap),
      ];
      const resetParameters = createUndefinedObject(resetKeys);
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        value={parameters}
        productEntities={productEntities}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        className={classes.Parameter}
      />
      <ParameterInputWrapper
        title={'Goal'}
        subTitle={'Set the analysis engagement goal'}
        className={classes.Parameter}
        error={Boolean(errors?.[GOAL_KEY])}
      >
        <SignalSmartSelector
          placeholder={'Select'}
          value={parameters[GOAL_KEY]}
          error={Boolean(errors?.[GOAL_KEY])}
          onSignalInfo={onSignalInfo}
          filters={goalSignalFilters}
          onChange={v => changeParametersValue({[GOAL_KEY]: v})}
          include={GOAL_SELECTOR_INCLUDE}
          clearable={false}
        />
      </ParameterInputWrapper>
      <TreatmentSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        schemaKeysMapping={treatmentsSelectorMap}
        errors={errors}
        entityContext={entityContext}
      />
      <TimeFrameSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        minDays={MIN_DAYS_TIME_FRAME}
        errors={errors}
      />
      {entityContext && (
        <SegmentFilterSelector
          onChange={changeParametersValue}
          value={parameters}
          className={classes.Parameter}
          schemaKeysMapping={segmentFilterSchemaMapping}
          entityContext={entityContext}
          errors={errors}
        />
      )}
    </div>
  );
};
