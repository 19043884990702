import * as React from 'react';
import {
  PopulationSizeExplainerFormattedSentenceParts,
  RCAInsightPopulationSizeExplainerSentenceParts,
  RCAInsightSentence,
} from '../rca-insights.model.ts';
import sharedClasses from '../../insights.module.scss';
import {
  ChangeInValueSentencePart,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import {ProductEntities} from '../../../../objects/models/product-data.model.ts';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: PopulationSizeExplainerFormattedSentenceParts;
  sentenceParts: RCAInsightPopulationSizeExplainerSentenceParts;
  productEntities?: ProductEntities;
}

type AllProps = OwnProps;

const SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter: React.FC<AllProps> = (
  props: AllProps
) => {
  const {formattedSentenceParts: fsp, sentenceParts} = props;
  const {trendChangeValue} = sentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SentencePart text={'The number of'} />
      <SentencePart bold text={fsp.sampledModelEntity} />
      <SentencePart text={`${fsp.populationChangeDescription} by`} />
      <ChangeInValueSentencePart higherIsBetter={true} changeInValue={trendChangeValue} />
      <SentencePart
        text={`making it a ${fsp.explainabilityLabelDescription} explanation for the ${fsp.sampledModelType} ${fsp.sampledModelChangeDescription}`}
      />
    </div>
  );
};

export {SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter};
