import * as React from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {useContext, useMemo} from 'react';
import {
  BaseChartOptions,
  BaseChartProps,
  ChartWithLabelsOptions,
  SingleNumberDataset,
} from '../../chart-data.types';
import {ChartTooltip} from '../../components/chart-tooltip.component';
import {Layer} from 'react-konva';
import {PieChartDataContainer} from './pie-chart-data-container.component';
import {calculateBbox} from '../../../../../core/konva/stage.utils';
import {ChartContext, ChartContextProvider} from '../../chart.context';
import {ResizeRender} from '../../../../hoc/resize-render/resize-render.component';
import classes from '../../chart.module.scss';

export interface PieChartOptions extends BaseChartOptions, ChartWithLabelsOptions {}

interface OwnProps extends BaseChartProps<SingleNumberDataset, PieChartOptions> {}

type AllProps = OwnProps;

const PieChartController: React.FC<AllProps> = (props: AllProps) => {
  const {controller} = useContext(StageContext);
  const {datasets} = useContext(ChartContext);
  const {options, onDatasetClick} = props;
  const layoutBbox = useMemo(
    () => calculateBbox(controller?.getSize().width, controller?.getSize().height),
    [controller]
  );
  /**
   * Render
   */
  return (
    <Layer>
      <PieChartDataContainer
        {...layoutBbox}
        options={options}
        datasets={datasets}
        onDatasetClick={onDatasetClick}
      />
    </Layer>
  );
};

export const PieChart: React.FC<AllProps> = (props: AllProps) => {
  return (
    <div className={classes.ChartContainer}>
      <ResizeRender>
        <ChartContextProvider {...props} TooltipComponent={ChartTooltip}>
          <PieChartController {...props} />
        </ChartContextProvider>
      </ResizeRender>
    </div>
  );
};

PieChart.defaultProps = {
  options: {
    highlightIds: [],
  },
};
