import {TestConfig} from 'yup/lib/util/createValidation';
import {get} from 'lodash';
import {exists} from 'front-core';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import httpClientService from '../../../../services/http-client.service';
import {
  validateAnalysisTimeframeRequest,
  validateSignalBoundingDaysRequest,
} from '../../../../http/validations.network-requests';
import TransKeys from 'translations';
import i18n from 'i18next';
import yup from '../../../../config/yup.config';

export const TIME_UNIT_OPTIONS = ['day', 'week', 'month', 'year'];

export const startDateBeforeEndDateTest = (
  optionalEndDate: boolean = false
): TestConfig<any, any> => ({
  name: 'end_after_start',
  test: function (dates, context) {
    const startDate = moment(dates.start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
    if (optionalEndDate && !exists(dates.end_date)) {
      return true;
    }
    const endDate = moment(dates.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
    if (endDate.isBefore(startDate)) {
      return this.createError({
        message: 'Invalid time frame, start date must be before end date',
        path: `${context.path}.start_date`,
      });
    }
    return true;
  },
});

export const startEndDatesValidator = yup
  .object()
  .shape({
    start_date: yup.string().required(),
    end_date: yup.string().required(),
  })
  .test(startDateBeforeEndDateTest());

export const validateSignalTimeframeBoundingFor = (props: {
  signalIdParameter?: string;
  signalQueryParameter?: string;
  parameterName?: string;
  startDateParameterName?: string;
  endDateParameterName?: string;
  parametersPrefix?: string;
  timeAggregationParameterName?: string;
  timeAggregationDefaultValue?: string;
}): TestConfig<any, any> => ({
  name: 'validate_timeframe_bounding_for',
  test: async function (runParameters: any, context) {
    const {
      signalIdParameter,
      signalQueryParameter,
      parameterName,
      startDateParameterName = 'start_date',
      endDateParameterName = 'end_date',
      parametersPrefix = 'parameters',
      timeAggregationParameterName = 'time_granularity',
      timeAggregationDefaultValue = null,
    } = props;
    let parameters = context.parent;
    if (parametersPrefix) {
      parameters = get(context.parent, parametersPrefix);
    }
    const timeAggregation = timeAggregationParameterName
      ? get(parameters, timeAggregationParameterName)
      : timeAggregationDefaultValue;
    const signalId = signalIdParameter ? get(parameters, signalIdParameter) : null;
    const signalQuery = signalQueryParameter ? get(parameters, signalQueryParameter) : null;
    if (!exists(signalId) && !exists(signalQueryParameter)) {
      return true;
    }

    const startDate = moment(
      runParameters[startDateParameterName],
      TIME_FORMATS.PARAMETER_DATE_FORMAT
    );
    const endDate = moment(runParameters[endDateParameterName], TIME_FORMATS.PARAMETER_DATE_FORMAT);
    try {
      await httpClientService.exec(
        validateAnalysisTimeframeRequest({
          timeAggregation,
          signalId,
          signalQuery,
          startDate: startDate.format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          endDate: endDate.format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        })
      );
      return true;
    } catch (e: any) {
      const errorParameters = e.data.parameters || {};
      const {minimum_date, signal_name, template_name, count, unit} = errorParameters;
      let message = '';
      const transMessage = template_name
        ? TransKeys.ANALYSIS_VALIDATIONS.TIMEFRAME_BOUNDING[template_name.toUpperCase()]
        : null;
      if (transMessage) {
        message = i18n.t(transMessage, {
          count,
          unit: `${unit}s`,
          signal_name: signal_name ? ` (${signal_name})` : '',
          parameter_name: parameterName,
          minimum_date: minimum_date ? moment(minimum_date).format(TIME_FORMATS.READABLE_DATE) : '',
        });
      } else {
        message = e.data.message;
      }
      return this.createError({
        message,
        path: `${context.path}.${startDateParameterName}`,
      });
    }
  },
});

export const eventPropertiesSignalsValidator = (
  includedEventPropertiesTagName: string = 'included_event_properties_tag',
  eventPropertiesName: string = 'event_properties'
) => ({
  [includedEventPropertiesTagName]: yup.string().nullable(),
  [eventPropertiesName]: yup.array().of(yup.number()).nullable(),
});

export const segmentsGroupsValidator = (
  includedSegmentsTagName: string = 'included_segments_tag',
  includedSegmentsSignalsName: string = 'included_segments_signals'
) => ({
  [includedSegmentsTagName]: yup.string().test({
    name: 'required_if_included_segment_signals_not_exists',
    test: function (v, context) {
      const segments_signals = context.parent[includedSegmentsSignalsName];
      if (exists(v)) {
        return true;
      }
      if (!exists(segments_signals) || segments_signals.length === 0) {
        return this.createError({
          message: 'Required',
        });
      }
      return true;
    },
  }),
  [includedSegmentsSignalsName]: yup
    .array()
    .of(yup.number())
    .when(includedSegmentsTagName, {
      is: s => !exists(s),
      then: schema => schema.required().min(1),
      otherwise: schema => schema.nullable(),
    }),
});

export const treatmentValidator = (
  treatmentsTagName: string = 'treatments_tag',
  treatmentsSignalsName: string = 'treatments_signals'
) => ({
  [treatmentsTagName]: yup
    .string()
    .nullable()
    .test({
      name: 'required_if_treatments_signals_not_exists',
      test: function (v, context) {
        const treatment_signals = context.parent[treatmentsSignalsName];
        if (exists(v)) {
          return true;
        }
        if (!exists(treatment_signals) || treatment_signals.length === 0) {
          return this.createError({
            message: 'Required',
          });
        }
        return true;
      },
    }),
  [treatmentsSignalsName]: yup
    .array()
    .of(yup.number())
    .when(treatmentsTagName, {
      is: s => !exists(s),
      then: schema => schema.required().min(1),
      otherwise: schema => schema.nullable(),
    }),
});

export const confoundersValadator = {
  confounders_tag: yup.string().nullable(),
  confounders_signals: yup.array().of(yup.number()).nullable(),
};

export const timeRangeValidator = (
  countParameter: string,
  unitParameter: string, // default "day"
  message: string,
  canBeEquals: boolean = false
): TestConfig<any, any> => {
  return {
    name: 'time_range_validation',
    test: function (runParameters: any, context: any) {
      const {parameters} = context.parent;
      const startDate = moment(runParameters.start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
      const endDate = moment(runParameters.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
      const totalRunDuration = Math.floor(endDate.diff(startDate, 'day'));
      const goalDuration = Math.floor(
        moment
          .duration(get(parameters, countParameter), get(parameters, unitParameter, 'day'))
          .asDays()
      );
      const hasError = canBeEquals
        ? totalRunDuration < goalDuration
        : totalRunDuration <= goalDuration;
      if (hasError) {
        return this.createError({
          message: message,
          path: 'runParameters.start_date',
        });
      }
      return true;
    },
  };
};

export const validateSignalBoundingDays = (props: {
  signalIdParameter?: string;
  signalQueryParameter?: string;
  optional?: boolean;
}): TestConfig<any, any> => ({
  name: 'validate_goal_query_bounding_days',
  test: async function (days: any, context: any) {
    const {signalIdParameter, signalQueryParameter, optional = false} = props;
    const signalId = signalIdParameter ? get(context.parent, signalIdParameter) : null;
    const signalQuery = signalQueryParameter ? get(context.parent, signalQueryParameter) : null;

    if (!exists(days) && optional) {
      return true;
    }

    try {
      await httpClientService.exec(
        validateSignalBoundingDaysRequest({
          numberOfDays: days,
          signalId,
          signalQuery,
        })
      );
      return true;
    } catch (e: any) {
      return this.createError({
        message:
          e.data?.message ||
          `The preliminary period needs to be shorter than time-bound you defined`,
        path: context.path,
      });
    }
  },
});
