import {useMemo, useState} from 'react';
import {
  HashtagIcon,
  PercentageIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {SwitchActions} from '../../../../../../../../simple/controls/switch-actions/switch-actions.component';
import {PowerUsersData} from '../../../../../../types/figures.types';
import TransKeys from 'translations';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {DocumentElementType} from '../../../../../../types/document.types';
import {ChartType} from '../../../../../../types/chart.types';
import {FancyBlock} from '../../../../../../../../simple/data-display/fancy-block/fancy-block.component';
import classes from './power-users-chart.module.scss';
import {
  PowerUsersChartParams,
  generateBarChart,
  generateLineChart,
} from '../../power-users-overview-viewer.utils';

export enum PowerUsersChartMode {
  COUNT = 'count',
  PERCENTAGE = 'percentage',
}

export enum PowerUsersDataMode {
  CUMULATIVE = 'cumulative',
  DISTRIBUTION = 'distribution',
}

interface OwnProps {
  dataMode: PowerUsersDataMode;
  items: PowerUsersData[];
  powerN: number;
  powerPercentile: number;
  signalName: string;
  isValuesWeightsAveraged: boolean;
}

type AllProps = OwnProps;

export const PowerUsersChart: React.FC<AllProps> = (props: AllProps) => {
  const {dataMode, items, powerN, powerPercentile, signalName, isValuesWeightsAveraged} = props;
  const {t} = useDocumentTranslation();
  const [chartMode, setChartMode] = useState<PowerUsersChartMode>(PowerUsersChartMode.PERCENTAGE);
  const modeOptions = useMemo(
    () => [
      {
        helperText: 'Percentage values',
        icon: PercentageIcon,
        onClick: () => setChartMode(PowerUsersChartMode.PERCENTAGE),
        isActive: chartMode == PowerUsersChartMode.PERCENTAGE,
      },
      {
        helperText: 'Absolute values',
        icon: HashtagIcon,
        onClick: () => setChartMode(PowerUsersChartMode.COUNT),
        isActive: chartMode == PowerUsersChartMode.COUNT,
      },
    ],
    [chartMode, setChartMode]
  );

  let label = t(
    chartMode == PowerUsersChartMode.PERCENTAGE
      ? TransKeys.DOCUMENT_VIEWER.POWER_USERS_OVERVIEW_FIGURE.CHART.PERCENT_DS_LABEL
      : TransKeys.DOCUMENT_VIEWER.POWER_USERS_OVERVIEW_FIGURE.CHART.COUNT_DS_LABEL
  );
  label = dataMode == PowerUsersDataMode.CUMULATIVE ? `Cumulative ${label}` : label;
  label += isValuesWeightsAveraged ? ' (averaged)' : '';
  const powerLineTitle = t(
    TransKeys.DOCUMENT_VIEWER.POWER_USERS_OVERVIEW_FIGURE.CHART.POWER_INDICATION_TITLE,
    {powerPercentile: powerPercentile * 100}
  );
  const powerLineDescription = t(
    TransKeys.DOCUMENT_VIEWER.POWER_USERS_OVERVIEW_FIGURE.CHART.POWER_INDICATION_DESCRIPTION,
    {powerPercentile: powerPercentile * 100}
  );

  const chart = useMemo(() => {
    const params: PowerUsersChartParams = {
      label,
      items,
      isPercentage: chartMode == PowerUsersChartMode.PERCENTAGE,
      signalName,
      powerN,
      powerLineTitle,
      powerLineDescription,
    };

    return dataMode == PowerUsersDataMode.DISTRIBUTION
      ? generateBarChart(params)
      : generateLineChart(params);
  }, [dataMode, items, chartMode, signalName, powerN, powerLineTitle, powerLineDescription]);

  return (
    <FancyBlock
      title={t(
        dataMode == PowerUsersDataMode.CUMULATIVE
          ? TransKeys.DOCUMENT_VIEWER.POWER_USERS_OVERVIEW_FIGURE.CHART.CUMULATIVE_CHART_TITLE
          : TransKeys.DOCUMENT_VIEWER.POWER_USERS_OVERVIEW_FIGURE.CHART.CHART_TITLE,
        {signalName}
      )}
    >
      <div className={classes.BlockContent}>
        <div className={classes.Header}>
          <span className={classes.Label}></span>
          <SwitchActions actions={modeOptions} />
        </div>
        <div className={classes.Content}>
          <ChildRenderer children_={chart} />
        </div>
      </div>
    </FancyBlock>
  );
};

PowerUsersChart.defaultProps = {};
