import * as React from 'react';
import {CSSProperties, useCallback, useMemo, useState} from 'react';
import classes from './kpi-list.module.scss';
import classNames from 'classnames';
import {Dot} from '../../generic-components/dot/dot.component';
import {KPIElement, KPIElementDirection} from '../../../../../types/growth-map.types';
import {isArray, isNumber} from 'lodash';
import {DirectionArrow} from '../../generic-components/direction-arrow/direction-arrow.component';
import {TrendingIcon} from '../../generic-components/trending-icon/trending-icon.component';
import {IndexSwitch} from '../../../../simple/controls/index-switch/index-switch.component';
import {KPIInfo} from '../kpi-info/kpi-info.component';

interface OwnProps {
  members: KPIElement[];
  model: string;
  className?: string;
  yOffset?: number;
  color?: string;
  isFunnel?: boolean;
  direction?: KPIElementDirection;
  style?: CSSProperties;
  perPage?: number;
  isExpanded?: boolean;
  onKPIClicked?: (kpi: KPIElement) => void;
}

type AllProps = OwnProps;
const PER_PAGE = 5;

export const KPIList: React.FC<AllProps> = (props: AllProps) => {
  const {
    members,
    model,
    yOffset,
    className,
    color,
    isFunnel,
    direction,
    style,
    perPage,
    isExpanded,
    onKPIClicked,
  } = props;
  const [openStates, setOpenStates] = useState(members.map(l => false));
  const [page, setPage] = useState(0);
  const funnelValues = useMemo(() => {
    if (!isFunnel) {
      return null;
    }
    const values = [];
    for (let i = 2; i <= members.length; i++) {
      const currentMember = members[i - 1];
      const prevMember = members[i - 2];
      const currentMemberPrevSample = currentMember?.data.samples?.[1];
      const prevMemberPrevSample = prevMember?.data.samples?.[1];

      const percentage = currentMember?.value / prevMember?.value;
      const prevPercentage = currentMemberPrevSample?.value / prevMemberPrevSample?.value;

      values.push({
        valid: isNumber(percentage) && isNumber(prevPercentage),
        percentage: percentage ? Number((percentage * 100).toFixed(2)) : '----',
        isBetter: prevPercentage && percentage ? prevPercentage < percentage : undefined,
        prevPercentage: prevPercentage ? Number((prevPercentage * 100).toFixed(2)) : '----',
      });
    }
    return values;
  }, [members, isFunnel]);
  const toTop = useMemo(() => direction === 'to-top', [direction]);
  const visibleMembers = useMemo(
    () => (isFunnel ? members : members.slice(page * perPage, page * perPage + perPage)),
    [isFunnel, members, page, perPage]
  );
  const totalPages = useMemo(() => Math.ceil(members.length / perPage), [members, perPage]);
  const hasPagination = useMemo(() => isFunnel === false && totalPages > 1, [isFunnel, totalPages]);

  const onExpand = useCallback(
    (idx: number) => {
      const newState = [...openStates];
      newState[idx] = !newState[idx];
      setOpenStates(newState);
    },
    [openStates, setOpenStates]
  );

  if (members.length === 0) {
    return null;
  }

  return (
    <div
      style={style}
      className={classNames(
        classes.KPIList,
        isFunnel ? classes.Funnel : classes.List,
        toTop && classes.ToTop,
        className
      )}
    >
      <div className={classes.Content}>
        <div className={classes.Shape} />
        {isFunnel && (
          <DirectionArrow
            style={{
              top: toTop ? 'unset' : `${yOffset}px`,
              bottom: toTop ? `${yOffset}px` : 'unset',
            }}
            className={classNames(classes.FrontArrow, toTop && classes.ToTop)}
          />
        )}
        <div
          className={classNames(classes.LabelsListItems, hasPagination && classes.HasPagination)}
        >
          {!toTop && <div style={{height: yOffset}} />}
          {visibleMembers.map((kpi, idx) => (
            <div key={idx} className={classes.ListItem}>
              {/* Label */}
              <div className={classes.ExpandableLabelWrapper}>
                <Dot className={classes.Dot} color={color} />
                <KPIInfo
                  kpi={kpi}
                  isExpanded={openStates[idx] || isExpanded}
                  onExpand={() => onExpand(idx)}
                  onKPIClicked={() => onKPIClicked(kpi)}
                  model={model}
                />
              </div>
              {/* Funnel conversion rate */}
              {isFunnel && isArray(funnelValues) && funnelValues[idx] && (
                <div className={classes.PostPercentage}>
                  {funnelValues[idx].valid && (
                    <>
                      <div className={classes.Percentage}>{funnelValues[idx].percentage}%</div>
                      <TrendingIcon
                        trendingUp={funnelValues[idx].isBetter}
                        className={classes.Icon}
                      />
                      <div className={classes.PrevPercentage}>
                        Was {funnelValues[idx].prevPercentage}%
                      </div>
                    </>
                  )}
                  {!funnelValues[idx].valid && (
                    <div className={classNames(classes.PostPercentage, classes.Invalid)}>
                      <div className={classes.Percentage}>----%</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
          {toTop && <div style={{height: yOffset}} />}
        </div>
        {isFunnel && <DirectionArrow className={classes.BackArrow} />}
      </div>
      <div
        style={{
          bottom: toTop ? `${yOffset - 10}px` : 'unset',
          top: toTop ? `unset` : `${yOffset - 10}px`,
        }}
        className={classes.Pagination}
      >
        {isFunnel === false && totalPages > 1 && (
          <IndexSwitch
            current={page}
            count={totalPages}
            onChange={p => setPage(p)}
            color={'rgba(58, 113, 217, 1)'}
          />
        )}
      </div>
    </div>
  );
};

KPIList.defaultProps = {
  members: [],
  yOffset: 0,
  color: 'rgba(255, 255, 255, 0.5)',
  isFunnel: false,
  direction: 'to-bottom',
  perPage: PER_PAGE,
};
