import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {QueryBlock} from '../components/query-block/query-block.component';
import {SignalColumn, SqlElementModel} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {concatPath} from '../query-builder.utils';
import {useQueryElement} from '../query-builder.hooks';
import {withViewMode} from '../core/with-view-mode.hoc';
import {InlineLabel} from '../components/inline-label/inline-label.component';
import {SignalIcon} from '../../../simple/controls/icons/icons.component';
import {HttpClientContext} from 'front-core';
import {SelectedOption} from '../../../forms/inputs.types';
import {extractValue} from '../../../../hooks/use-remote-source';
import {ModelSelector} from '../components/model-selector/model-selector.component';

interface OwnProps {
  data: SignalColumn;
  inline?: boolean;
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const SignalColumnBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled, inline, box} = props;
  const {localErrors, actions} = useQueryElement(props);

  return (
    <QueryBlock
      disabled={disabled}
      className={className}
      label={'Signal Column'}
      actions={actions}
      inline={inline}
      box={box}
      fitContent
    >
      <ModelSelector
        value={data.signal_id}
        onChange={v => onChange(concatPath(path, 'signal_id'), v)}
        error={Boolean(localErrors.signal_id)}
        model={SqlElementModel.SIGNAL}
        disabled={disabled}
      />
    </QueryBlock>
  );
};

SignalColumnBuilderComponent.defaultProps = {};

export const SignalColumnViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {data, className, config} = props;
  const httpClient = useContext(HttpClientContext);
  const options = config.modelConfig?.[SqlElementModel.SIGNAL];
  const [selected, setSelected] = useState(null);
  const selectionKey = useMemo(() => (options as any).selectionKey || 'id', [options]);
  const getOption = useCallback(
    async (value: SelectedOption) => {
      if (!value || !('networkRequest' in options)) {
        return;
      }
      const nr = options.networkRequest(undefined, {
        [selectionKey]: [value],
      });
      const res: any = await httpClient.exec(nr);
      setSelected({
        label: extractValue(res[0], options.labelAttributePath),
        value: extractValue(res[0], options.valueAttributePath),
      });
    },
    [httpClient, options, selectionKey]
  );
  useEffect(() => {
    if (data.signal_id === selected?.value) {
      return;
    }
    if (!selected) {
      getOption(data.signal_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.signal_id, getOption]);

  if (!options) {
    return <span>UNSUPPORTED! Missing model config for SIGNAL</span>;
  }

  return (
    <QueryBlock disabled={true} className={className} inline={true} box={false} fitContent>
      <InlineLabel icon={SignalIcon} label={selected?.label} />
    </QueryBlock>
  );
};

export const SignalColumnBuilder = withViewMode(
  SignalColumnBuilderComponent,
  SignalColumnViewerComponent
);
