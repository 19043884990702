import yup from '../../config/yup.config';

import {querySchemaValidator} from './query-builder.dto.ts';

export const ModelEventPropertiesValidator = yup.object().shape({
  id: yup.number().nullable(),
  definition: querySchemaValidator(true),
});

export const modelEventPropertiesDataSchema = yup
  .array()
  .of(ModelEventPropertiesValidator)
  .nullable();
