import * as React from 'react';
import classNames from 'classnames';
import {RCAExperimentExplainerFigure} from '../../../../types';
import classes from './rca-experiment-explainer-viewer.module.scss';
import {ExperimentExplainerItem} from './components/experiment-explainer-item/experiment-explainer-item.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';

interface Props extends RCAExperimentExplainerFigure {
  className?: string;
}

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_EXPERIMENT_EXPLAINER_FIGURE;

export const RCAExperimentExplainerViewer: React.FC<Props> = (props: Props) => {
  const {data, options, className} = props;
  const {t} = useDocumentTranslation();
  const {experiments} = data;

  return (
    <div className={classNames(classes.RCAExperimentExplainerViewer, className)}>
      <div className={classes.Table}>
        <div className={classes.HeaderWrapper}>
          <div className={classes.Headers}>
            <div className={classes.Title}>
              {t(TransKeysPrefix.MAIN_TABLE.HEADERS.EXPERIMENT_NAME.TITLE)}
            </div>
            <div className={classes.Title}>
              {t(TransKeysPrefix.MAIN_TABLE.HEADERS.EXPLAINABILITY.TITLE)}
            </div>
            <div className={classes.Title}>
              {t(TransKeysPrefix.MAIN_TABLE.HEADERS.DESCRIPTION.TITLE)}
            </div>
          </div>
        </div>
        <div className={classes.TableBody}>
          {experiments.map(experiment => (
            <ExperimentExplainerItem
              key={experiment.experimentId}
              experiment={experiment}
              options={options}
              className={classes.Item}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
