import * as React from 'react';
import {useMemo, useState} from 'react';
import classes from './time-to-event-viewer.module.scss';
import classNames from 'classnames';
import {ChartType, DocumentElementType, TimeToEventData, TimeToEventFigure} from '../../../types';
import {capitalize, keys, range, sum, values, min, max, every, some, isEmpty} from 'lodash';
import {ItemValue} from '../../../../charts/chart-data.types';
import {SwitchActions} from '../../../../../simple/controls/switch-actions/switch-actions.component';
import {
  HashtagIcon,
  WarningIcon,
  PercentageIcon,
} from '../../../../../simple/controls/icons/icons.component';
import {safeDivision, toFixedNumber, weightedMedian} from 'front-core';
import {ChartViewer} from '../chart-viewer/chart-viewer.component';
import {useEffect} from 'react';
import {Tooltip} from '@material-ui/core';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../hooks/use-document-translation.hook';

export interface OwnProps extends TimeToEventFigure {
  className?: string;
}

const MAX_DS_LEN = 8760; // 1 year in hours

enum TTE_RESOLUTION {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
}

enum TTE_MODE {
  PERCENTAGE = 'PERCENTAGE',
  ABS = 'ABS',
}

const fixData = (
  data: {[key: number]: number},
  tteWindow: TTE_RESOLUTION,
  maxBars: number,
  total: number = 0,
  mode: TTE_MODE
): ItemValue[] => {
  let winSizeHour = null;
  const returnData = new Array(MAX_DS_LEN).fill(0);
  const dataKeys = keys(data);

  switch (tteWindow) {
    case TTE_RESOLUTION.MINUTE:
      winSizeHour = 1;
      break;
    case TTE_RESOLUTION.HOUR:
      winSizeHour = 60;
      break;
    case TTE_RESOLUTION.DAY:
      winSizeHour = 60 * 24;
      break;
    case TTE_RESOLUTION.WEEK:
      winSizeHour = 60 * 24 * 7;
      break;
  }
  for (const key of dataKeys) {
    // @ts-ignore
    const idx = Math.floor(key / winSizeHour);
    returnData[idx] = (returnData[idx] || 0) + data[key];
  }

  if (returnData.length > maxBars) {
    returnData.splice(maxBars);
    returnData[returnData.length - 1] = max([total - sum(returnData), 0]);
  }

  return returnData.map(r =>
    mode === TTE_MODE.ABS ? {value: r} : {value: toFixedNumber(safeDivision(r, total, true) * 100)}
  );
};

const consolidateData = (data: TimeToEventData[], totals: number[]) => {
  const format = (dataMap: {[key: number]: number}, total: number, resolution: TTE_RESOLUTION) =>
    keys(dataMap)
      .filter(k => Number(k) >= 0)
      .reduce((acc, key) => {
        acc[Number(key) * (resolution === TTE_RESOLUTION.HOUR ? 60 : 1)] = dataMap[key];
        return acc;
      }, {});

  return data.map((d, dIndex) => ({
    ...d,
    hours: format(d.hours, totals[dIndex], TTE_RESOLUTION.HOUR),
    minutes: format(d.minutes || {}, totals[dIndex], TTE_RESOLUTION.MINUTE),
  }));
};

const clearNegativeKeys = a =>
  keys(a).reduce((obj, key) => {
    if (Number(key) < 0) {
      return obj;
    }
    obj[key] = a[key];
    return obj;
  }, {});

function calculateTotalMissing(data) {
  let negCount = 0;
  let totalCount = 0;
  for (const ds of data) {
    for (const k of keys(ds.hours)) {
      if (Number(k) < 0) {
        negCount += Number(ds.hours[k]);
      }
      totalCount += Number(ds.hours[k]);
    }
  }
  return negCount / totalCount;
}

export const TimeToEventViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {data: dataFromProps, options, title} = props;
  const {t} = useDocumentTranslation();
  const [windowSize, setWindowSize] = useState<TTE_RESOLUTION>(TTE_RESOLUTION.HOUR);
  const [mode, setMode] = useState<TTE_MODE>(TTE_MODE.PERCENTAGE);
  const cleanedData = useMemo(() => dataFromProps.filter(d => !isEmpty(d.hours)), [dataFromProps]);
  const removedData = useMemo(
    () => dataFromProps.filter(d => isEmpty(d.hours) || isEmpty(clearNegativeKeys(d.hours))),
    [dataFromProps]
  );
  const cleanedTotals = useMemo(
    () => cleanedData.map(d => sum(values(clearNegativeKeys(d.hours))) || 0).filter(d => d > 0),
    [cleanedData]
  );
  const dataFixed = useMemo(
    () => consolidateData(cleanedData, cleanedTotals),
    [cleanedData, cleanedTotals]
  );
  const medianMinutes = useMemo(
    () =>
      min(
        dataFixed.map(ds =>
          weightedMedian(
            keys(ds.hours).map(k => ({
              weight: ds.hours[k],
              value: Number(k),
            }))
          )
        )
      ),
    [dataFixed]
  );
  const barChartProps: any = useMemo(() => {
    const maxBars = windowSize === TTE_RESOLUTION.MINUTE ? 60 : 30;
    const data = dataFixed.map((ds, dsIdx: number) => {
      return {
        id: ds.id,
        label: ds.label,
        data: fixData(
          windowSize === TTE_RESOLUTION.MINUTE ? ds.minutes : ds.hours,
          windowSize,
          maxBars,
          cleanedTotals[dsIdx],
          mode
        ),
      };
    });
    // cleaning trailing 0's
    if (windowSize === TTE_RESOLUTION.WEEK || windowSize === TTE_RESOLUTION.DAY) {
      for (const i of range(0, maxBars).reverse()) {
        if (every(data.map(d => d.data[i].value === 0))) {
          data.forEach(d => d.data.pop());
        } else {
          break;
        }
      }
    }

    const labels = range(0, maxBars).map(l => l.toString());
    labels[labels.length - 1] = `${labels[labels.length - 1]}+`;
    const yLabel = options.entityName ? `of ${options.entityName}` : '';
    return {
      datasets: data,
      labels: labels,
      options: {
        yLabel: mode === TTE_MODE.PERCENTAGE ? `Percentage ${yLabel}` : `Amount ${yLabel}`,
        xLabel: capitalize(windowSize.toLowerCase() + 's'),
        yLabelSuffix: mode === TTE_MODE.PERCENTAGE ? '%' : '',
      },
    };
  }, [windowSize, dataFixed, mode, cleanedTotals]);
  const hasMinutes = useMemo(() => some(dataFixed, d => values(d.minutes || {}).length > 0), []);
  const totalMissingPercent = useMemo(
    () => toFixedNumber(calculateTotalMissing(dataFromProps) * 100, 2),
    [dataFromProps]
  );
  const windowSizeOptions = useMemo(
    () => [
      {
        label: 'Minutes',
        onClick: () => setWindowSize(TTE_RESOLUTION.MINUTE),
        isActive: windowSize === TTE_RESOLUTION.MINUTE,
        hidden: !hasMinutes,
      },
      {
        label: 'Hours',
        onClick: () => setWindowSize(TTE_RESOLUTION.HOUR),
        isActive: windowSize === TTE_RESOLUTION.HOUR,
      },
      {
        label: 'Days',
        onClick: () => setWindowSize(TTE_RESOLUTION.DAY),
        isActive: windowSize === TTE_RESOLUTION.DAY,
      },
      {
        label: 'Weeks',
        onClick: () => setWindowSize(TTE_RESOLUTION.WEEK),
        isActive: windowSize === TTE_RESOLUTION.WEEK,
      },
    ],
    [windowSize, hasMinutes]
  );
  const modeActions = useMemo(
    () => [
      {
        icon: HashtagIcon,
        helperText: 'Absolute',
        onClick: () => setMode(TTE_MODE.ABS),
        isActive: mode === TTE_MODE.ABS,
      },
      {
        icon: PercentageIcon,
        helperText: 'Percentage',
        onClick: () => setMode(TTE_MODE.PERCENTAGE),
        isActive: mode === TTE_MODE.PERCENTAGE,
      },
    ],
    [mode]
  );
  const {totalMissingPercentTooltipTitle, removedDataTooltipTitle} = useMemo(() => {
    const totalMissingPercentTooltipTitle =
      totalMissingPercent > 0
        ? t(TransKeys.TIME_TO_EVENT.MESSAGES.HAD_NEGATIVE_TIME, {
            label: `${totalMissingPercent}% of ${options.entityName || 'users'}`,
          })
        : '';
    const removedDataTooltipTitle =
      removedData.length > 0 && totalMissingPercent !== 100
        ? t(TransKeys.TIME_TO_EVENT.MESSAGES.HAD_NO_DATA, {
            label: `${removedData.map(r => `"${r.label}"`).join(', ')}`,
          })
        : '';
    return {
      totalMissingPercentTooltipTitle,
      removedDataTooltipTitle,
    };
  }, [totalMissingPercent, t, removedData]);

  // Show this only when there's no data at all!
  const showNoDataMessage = useMemo(() => {
    let show = true;
    for (const ds of dataFixed) {
      const hasData = [...values(ds.hours), ...values(ds.minutes)].length > 0;
      if (hasData) {
        show = false;
        break;
      }
    }
    return show;
  }, [dataFixed]);

  useEffect(() => {
    // initial
    let winSize = TTE_RESOLUTION.MINUTE;
    // more than 1 hour
    if (medianMinutes >= 60) {
      winSize = TTE_RESOLUTION.HOUR;
    }
    // more than 1 day
    if (medianMinutes >= 1440) {
      winSize = TTE_RESOLUTION.DAY;
    }
    // more than 1 week
    if (medianMinutes >= 10080) {
      winSize = TTE_RESOLUTION.WEEK;
    }
    setWindowSize(winSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classNames(classes.TimeToEvent, props.className)}>
      <div className={classes.Header}>
        <div className={classes.Title}>{title}</div>
        <div className={classes.Actions}>
          {Boolean(totalMissingPercentTooltipTitle) && (
            <Tooltip placement={'top'} title={totalMissingPercentTooltipTitle}>
              <WarningIcon className={classes.Icon} />
            </Tooltip>
          )}
          {Boolean(removedDataTooltipTitle) && (
            <Tooltip placement={'top'} title={removedDataTooltipTitle}>
              <WarningIcon className={classes.Icon} />
            </Tooltip>
          )}
          <SwitchActions className={classes.ActionSet} actions={modeActions} />
          <SwitchActions
            className={classes.ActionSet}
            actions={windowSizeOptions}
            showActionsLabel
          />
        </div>
      </div>
      <div className={classes.ChartWrapper}>
        {showNoDataMessage && (
          <div className={classes.NoDataMessageContainer}>
            <WarningIcon className={classes.Icon} />
            <div className={classes.Message}>
              {removedDataTooltipTitle || totalMissingPercentTooltipTitle}
            </div>
          </div>
        )}
        <ChartViewer
          type={DocumentElementType.CHART}
          chartType={ChartType.BAR}
          data={barChartProps.datasets}
          labels={barChartProps.labels}
          options={barChartProps.options}
          componentMode={props.componentMode}
        />
      </div>
    </div>
  );
};

TimeToEventViewer.defaultProps = {
  options: {},
};
