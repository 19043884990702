import {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {DocumentPhenomena} from '../types';
import {flatten} from 'lodash';

export interface IDocumentPhenomenasContext {
  getPhenomenas: (filters?: PhenomenaFilter[]) => DocumentPhenomena[];
  refreshPhenomenas: () => void;
  isLoading: boolean;
  total: number;
}

export interface PhenomenaFilter {
  type: string[];
}

const DEFAULT_VALUE: IDocumentPhenomenasContext = {
  getPhenomenas: () => [],
  refreshPhenomenas: () => undefined,
  isLoading: false,
  total: undefined,
};

export const DocumentPhenomenasContext = createContext<IDocumentPhenomenasContext>(DEFAULT_VALUE);

interface OwnProps {
  phenomenasResolver: () => Promise<DocumentPhenomena[]>;
  children: any;
}

export const DocumentPhenomenasProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {phenomenasResolver, children} = props;
  const [phenomenas, setPhenomenas] = useState<DocumentPhenomena[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPhenomenas = useCallback(
    (filters?: PhenomenaFilter[]) => {
      if (!filters || filters.length === 0) {
        return phenomenas;
      }
      // this is simple implementation because we only support filter by type right now
      const types = flatten(filters.map(f => f.type)).filter(t => t !== null);
      return phenomenas.filter(p => types.indexOf(p.type) > -1);
    },
    [phenomenas]
  );

  const resolvePhenomenas = useCallback(async () => {
    setIsLoading(true);
    const data = await phenomenasResolver();
    setIsLoading(false);
    setPhenomenas(data);
  }, [setPhenomenas, phenomenasResolver, setIsLoading]);

  useEffect(() => {
    resolvePhenomenas();
  }, [resolvePhenomenas]);

  const contextValue = useMemo(
    () => ({
      getPhenomenas,
      isLoading,
      refreshPhenomenas: resolvePhenomenas,
      total: phenomenas?.length || 0,
    }),
    [getPhenomenas, isLoading, phenomenas, resolvePhenomenas]
  );

  return (
    <DocumentPhenomenasContext.Provider value={contextValue}>
      {children}
    </DocumentPhenomenasContext.Provider>
  );
};
