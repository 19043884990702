import * as React from 'react';
import {Window, WindowFunctionType} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {concatPath} from '../query-builder.utils';
import {useMemo} from 'react';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {QueryBlock} from '../components/query-block/query-block.component';
import {useQueryElement} from '../query-builder.hooks';
import {ParametersBuilder} from './parameters-builder.component';
import {get, values} from 'lodash';
import {WindowFunctionTypeName} from '../query-builder.config';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {queryBuilderClasses} from '../query-builder.component';
import {Select} from '../../../forms/inputs/select/select.component';

interface OwnProps {
  data: Window;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const WindowBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled} = props;
  const {localErrors, actions} = useQueryElement(props);
  const parameters = useMemo(() => data.parameters || [], [data]);
  const functionOptions = useMemo(
    () =>
      values(WindowFunctionType).map(o => ({
        value: o,
        label: WindowFunctionTypeName[o],
      })),
    []
  );
  const orderOptions = useMemo(
    () => [
      {value: true, label: 'ASC'},
      {value: false, label: 'DESC'},
    ],
    []
  );

  return (
    <QueryTree
      label={'Window Function'}
      headerNext={
        <Select
          className={queryBuilderClasses.Inline}
          placeholder={'Function'}
          value={data.function}
          error={Boolean(localErrors.function)}
          onChange={v => onChange(concatPath(path, 'function'), v)}
          searchable={false}
          options={{options: functionOptions}}
          disabled={disabled}
          clearable={false}
        />
      }
      actions={actions}
      disabled={disabled}
      className={className}
    >
      <QueryBlock label={`Partition By`} box={false} disabled={disabled}>
        <QueryElementBuilder
          data={data.partition_by}
          error={Boolean(get(localErrors, `partition_by`))}
          className={queryBuilderClasses.Inline}
          path={concatPath(path, `partition_by`)}
        />
      </QueryBlock>
      <QueryBlock label={`Order By`} box={false} disabled={disabled}>
        <QueryElementBuilder
          data={data.order_by[0]}
          error={Boolean(get(localErrors, `order_by.0`))}
          className={queryBuilderClasses.Inline}
          path={concatPath(path, `order_by.0`)}
        />
      </QueryBlock>
      <QueryBlock label={`Order`} box={false} disabled={disabled}>
        <Select
          value={data.order_by[1] as any}
          options={{options: orderOptions as any}}
          error={Boolean(get(localErrors, `order_by.1`))}
          onChange={v => onChange(concatPath(path, 'order_by.1'), v)}
          className={queryBuilderClasses.Inline}
          clearable={false}
          disabled={disabled}
        />
      </QueryBlock>
      <ParametersBuilder
        data={parameters}
        onChange={v => onChange(concatPath(path, 'parameters'), v)}
        disabled={disabled}
      />
    </QueryTree>
  );
};
