import * as React from 'react';
import classNames from 'classnames';
import {SentenceFigureOptions} from '../../../../../types';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import classes from './data-columns.module.scss';
import {SentenceViewer} from '../../../sentence-viewer/sentence-viewer.component';
import {exists} from 'front-core';
import {DataColumnProps} from '../../smart-table.types';

interface OwnProps extends DataColumnProps<SentenceFigureOptions> {}

type AllProps = OwnProps;

const SentenceDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;

  return (
    <div className={classNames(classes.DefaultDataColumn, className)}>
      <SentenceViewer data={data[column.key]} options={column.typeOptions as any} />
    </div>
  );
};

const protector: Protector = (props: OwnProps) => exists(props.data[props.column.key]);
export const SentenceDataColumn = withDataColumnProtector(protector)(SentenceDataColumnComponent);
