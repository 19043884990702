import {useEffect, useMemo, useState} from 'react';
import {useDocumentTracking} from './use-document-tracking.hook';

const TRACKED_ELEMENT_TYPES_BLACKLIST = [
  'Block',
  '_WINDOW_BLOCK',
  'GridLayout',
  'Document',
  'ContentWrapper',
  'OptionsPlacer',
];

export function useScreenTracking(id, ref, children) {
  const {trackScroll, trackItemViewed} = useDocumentTracking(id, children.type);
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [wasEverOnScreen, setWasEverOnScreen] = useState(false);
  const [isFirstTimeOnScreen, setIsFirstTimeOnScreen] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
  }, [ref]);

  useEffect(() => {
    isOnScreen && setWasEverOnScreen(true);
    setIsFirstTimeOnScreen(isOnScreen && !wasEverOnScreen);
  }, [isOnScreen, wasEverOnScreen]);

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref?.current]);

  useEffect(() => {
    if (isFirstTimeOnScreen) {
      // offsetTop is the distance between the top of the element and the top of the parent node
      // we intend to track distance to the top of the page, so anything above 100px is probably at the root of the page
      if (ref.current.offsetTop > 100) {
        trackScroll(ref.current.offsetTop);
      }
      if (!TRACKED_ELEMENT_TYPES_BLACKLIST.includes(children.type)) {
        trackItemViewed(children.title);
      }
    }
  }, [isFirstTimeOnScreen]);
}
