import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';
import {number2k, safeDivision} from 'front-core';
import {Tooltip} from '@material-ui/core';
import pluralize from 'pluralize';
import {useMemo} from 'react';
import {capitalize} from 'lodash';

interface OwnProps {
  count: number;
  total: number;
  entity: string;
  className?: string;
}

type AllProps = OwnProps;

export const ShareOfUsers: React.FC<AllProps> = (props: AllProps) => {
  const {count, total, entity, className} = props;
  const percentage = safeDivision(count, total);
  const tooltipText = useMemo(() => {
    const pluralEntity = capitalize(pluralize(entity));
    return `${count.toLocaleString()} ${pluralEntity} / ${total.toLocaleString()} ${pluralEntity}`;
  }, [entity, count, total]);

  return (
    <Tooltip title={tooltipText} placement={'top'} interactive={false}>
      <div className={classNames(classes.ShareOfUsers, className)}>
        <div className={classes.Percentage}>{number2k(percentage * 100)}%</div>
        <div className={classes.Progress}>
          <div className={classes.Bar} style={{width: `${percentage * 100}%`}} />
        </div>
      </div>
    </Tooltip>
  );
};

ShareOfUsers.defaultProps = {};
