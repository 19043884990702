import {Range} from 'react-input-range';
import {DataColumnOptions, SmartTableFigureColumn} from '../../../types';

/**
 * Pagination
 */

export interface Pagination {
  page: number;
  perPage: number;
}

/**
 * Filters
 */

export enum TableFilterType {
  LIST = 'LIST',
  NUMBER_RANGE = 'NUMBER_RANGE',
  EXACT_MATCH = 'EXACT_MATCH',
  DURATION = 'DURATION',
  DATE = 'DATE',
  TREE = 'TREE',
}

export interface TableFilter<V, P = void> {
  type: TableFilterType;
  value: V;
  properties?: P;
}

export interface TableListFilter extends TableFilter<string[], {dataKey: string}> {
  type: TableFilterType.LIST;
}

export interface TableNumberRangeFilter extends TableFilter<Range, {dataKey: string}> {
  type: TableFilterType.NUMBER_RANGE;
}

export interface TableExactMatchFilter extends TableFilter<any, {dataKey: string}> {
  type: TableFilterType.EXACT_MATCH;
}

export interface TableDateFilter extends TableFilter<[Date, Date], {dataKey: string}> {
  type: TableFilterType.DATE;
}

export interface TableTreeFilter
  extends TableFilter<
    {[parentDataValue: string]: string[]},
    {dataKey: string; parentDataKey: string}
  > {
  type: TableFilterType.TREE;
}

export interface TableFilters {
  [filterKey: string]: TableFilter<any, any>;
}

/**
 * Columns State
 */

export interface SwitchColumnState {
  switchColumnIndex: number;
}

export interface PercentageAbsColumnState {
  columnMode: 'primary' | 'secondary';
}

export interface ColumnStates {
  [columnKey: string]: any;
}

export interface DataColumnProps<T> {
  column: SmartTableFigureColumn<T>;
  data: any;
  dataColumnOptions?: DataColumnOptions;
  columnState: any;
  className?: string;
}
