export enum AmplitudeEvent {
  PAGEVIEW = 'pageview',
  GROWTH_MAP_OPENED = 'growth_map_opened',
  OPPORTUNITY_DEEP_DIVE = 'opportunity_deep_dive',
  ANALYSIS_DEEP_DIVE = 'analysis_deep_dive',
  EXPERIMENT_DEEP_DIVE = 'experiment_deep_dive',
  CONFIRMATION_DIALOG_APPROVED = 'confirmation_dialog_approved',
  CONFIRMATION_DIALOG_REJECTED = 'confirmation_dialog_rejected',
  EXPERIMENT_CREATED = 'experiment_created',
  EXPERIMENT_PATCHED = 'experiment_patched',
  EXPERIMENT_RERAN_LAST_COMPLETED_ANALYSIS_RESULT = 'experiment_reran_last_completed_analysis_result',
  EXPERIMENT_UPDATED = 'experiment_updated',
  EXPERIMENT_DELETED = 'experiment_deleted',
  UPDATE_EXPERIMENT_TEAM = 'UPDATE_EXPERIMENT_TEAM',
  SET_EXPERIMENT_OWNER = 'SET_EXPERIMENT_OWNER',
  EXPERIMENT_SUBSCRIPTION_CREATED = 'experiment_subscription_created',
  EXPERIMENT_SUBSCRIPTION_UPDATED = 'experiment_subscription_updated',
  EXPERIMENT_SUBSCRIPTION_DELETED = 'experiment_subscription_deleted',
  ANALYSIS_REQUESTED = 'analysis_requested',
  ANNOTATION_CREATED = 'annotation_created',
  ANNOTATION_UPDATED = 'annotation_updated',
  ANNOTATION_DELETED = 'annotation_deleted',
  GOAL_CREATED = 'goal_created',
  GOAL_UPDATED = 'goal_updated',
  GOAL_DELETED = 'goal_deleted',
  AI_CHATBOT_USER_CLICKED_SUGGESTION = 'ai_chatbot_user_clicked_suggestion',
  AI_CHATBOT_USER_CLICKED_RUN_ANALYSIS = 'ai_chatbot_user_clicked_run_analysis',
  WORKFLOW_CREATED = 'workflow_created',
  WORKFLOW_EXPORTED = 'workflow_exported',
  WORKFLOW_UPDATED = 'workflow_updated',
  WORKFLOW_DELETED = 'workflow_deleted',
  SET_WORKFLOW_OWNER = 'set_workflow_owner',
  UPDATE_WORKFLOW_TEAM = 'update_workflow_team',
  UPDATE_WORKFLOW_STATUS = 'update_workflow_status',
  RENAME_WORKFLOW = 'rename_workflow',
  FUNNEL_CREATED = 'funnel_created',
  FUNNEL_UPDATED = 'funnel_updated',
  FUNNEL_DELETED = 'funnel_deleted',
  FUNNEL_PUBLISHED = 'funnel_published',
  RYOA_API_ERROR = 'ryoa_api_error',
  RESOURCE_SHARED = 'resource_shared',
  RESOURCE_SHARE_CLICKED = 'resource_share_clicked',
  RESOURCE_SHARE_TAB_CLICKED = 'resource_share_tab_clicked',
  RESOURCE_SHARE_INPUT_CHANGED = 'resource_share_input_changed',
  CREATE_SCHEDULE_ANALYSIS = 'create_schedule_analysis',
  EDIT_SCHEDULE_ANALYSIS = 'edit_schedule_analysis',
  DELETE_SCHEDULE_ANALYSIS = 'delete_schedule_analysis',
  USER_ANALYSIS_CREATION_STEP_VIEW = 'user_analysis_creation_step_view',
  USER_ANALYSIS_CREATION_SWITCH_FORM_MODE = 'user_analysis_creation_switch_form_mode',
  MODEL_DISMISSED = 'model_archived',
  MODEL_EXPLORED = 'model_explored',
  MODEL_VIEWED = 'model_viewed',
  MODEL_SNOOZED = 'model_snoozed',
  MODEL_MARKED_USEFUL = 'model_marked_useful',
  ANALYSIS_DUPLICATE_CLICKED = 'analysis_duplicate_clicked',
  CHANGE_EXPERIMENT_PARAMETERS_CLICKED = 'change_experiment_parameters_clicked',
  CREATE_ANALYSIS_CLICKED = 'create_analysis_clicked',
  EDIT_ANALYSIS_CLICKED = 'edit_analysis_clicked',
  EDIT_ANALYSIS_CONFIRMED_CLICKED = 'edit_analysis_confirmed_clicked',
  CREATE_ANALYSIS_CATEGORY_SELECTED = 'create_analysis_category_selected',
  CREATE_ANALYSIS_PREVIEW_CLICKED = 'create_analysis_preview_clicked',
  CREATE_ANALYSIS_SEARCH = 'select_analysis_tab_search',
  ANALYSIS_WIZARD_ACTIVATED = 'analysis_wizard_activated',
  FEATURE_CREATED = 'feature_created',
  FEATURE_UPDATED = 'feature_updated',
  FEATURE_DELETED = 'feature_deleted',
  CONTENT_CREATED = 'content_created',
  CONTENT_UPDATED = 'content_updated',
  CONTENT_DELETED = 'content_deleted',
  CONTENT_SAVE_CLICKED = 'content_save_clicked',
  CONTENT_FORM_FIELD_CHANGED = 'content_form_field_changed',
  CONTENT_FORM_CONTACT_SUPPORT_CLICKED = 'content_form_contact_support_clicked',
  CONTENT_CREATE_CLICKED = 'content_create_clicked',
  CONTENT_DUPLICATE_CLICKED = 'content_duplicate_clicked',
  CONTENT_EDIT_CLICKED = 'content_edit_clicked',
  CONTENT_RESCANNED = 'content_rescanned',
  SEGMENT_CREATED = 'segment_created',
  SEGMENT_UPDATED = 'segment_updated',
  SEGMENT_RESCANNED = 'segment_rescanned',
  SEGMENT_DELETED = 'segment_deleted',
  SEGMENT_VALIDATED = 'segment_validated',
  FAVORITE_SEGMENTS_UPDATED = 'favorite_segments_updated',
  SEGMENT_CATEGORY_CREATED = 'segment_category_created',
  SEGMENT_CATEGORY_UPDATED = 'segment_category_updated',
  SEGMENT_CATEGORY_DELETED = 'segment_category_deleted',
  TABLE_CREATED = 'table_created',
  TABLE_UPDATED = 'table_updated',
  TABLE_CREATE_CLICKED = 'table_create_clicked',
  TABLE_EDIT_CLICKED = 'table_edit_clicked',
  TABLE_DUPLICATE_CLICKED = 'table_duplicate_clicked',
  TABLE_SAVE_CLICKED = 'table_save_clicked',
  CREATE_SEGMENT_ERROR = 'create_segment_error',
  UPDATE_SEGMENT_ERROR = 'update_segment_error',
  CREATE_FEATURE_ERROR = 'create_feature_error',
  UPDATE_FEATURE_ERROR = 'update_feature_error',
  CREATE_CONTENT_ERROR = 'create_content_error',
  UPDATE_CONTENT_ERROR = 'update_content_error',
  CREATE_FUNNEL_ERROR = 'create_funnel_error',
  UPDATE_FUNNEL_ERROR = 'update_funnel_error',
  CREATE_EVENT_GROUP_ERROR = 'create_event_group_error',
  UPDATE_EVENT_GROUP_ERROR = 'update_event_group_error',
  METRIC_CREATE_CLICKED = 'metric_create_clicked',
  METRIC_DUPLICATE_CLICKED = 'metric_duplicate_clicked',
  METRIC_EDIT_CLICKED = 'metric_edit_clicked',
  METRIC_FORM_FIELD_CHANGED = 'metric_form_field_changed',
  METRIC_FORM_CONTACT_SUPPORT_CLICKED = 'metric_form_contact_support_clicked',
  METRIC_CREATED = 'metric_created',
  METRIC_UPDATED = 'metric_updated',
  METRIC_DELETED = 'metric_deleted',
  SET_METRIC_OWNER = 'set_metric_owner',
  SET_METRIC_NORTH_STAR = 'set_metric_north_star',
  METRIC_PUBLISHED = 'metric_published',
  METRIC_VALIDATED = 'metric_validated',
  UPDATE_TEAM_METRICS = 'update_team_metrics',
  ADD_METRIC_TO_TEAM = 'add_metric_to_team',
  INPUT_METRIC_ADDED = 'input_metric_added',
  INPUT_METRIC_REMOVED = 'input_metric_removed',
  INPUT_METRIC_RELATIONSHIP_TYPE_CHANGED = 'input_metric_relationship_type_changed',
  METRIC_TREE_POSITIONS_CHANGED = 'metric_tree_positions_changed',
  METRIC_ADDED_TO_TREE = 'metric_added_to_tree',
  METRIC_REMOVED_FROM_TREE = 'metric_removed_from_tree',
  SET_KPI_TREE_METRIC_FORMULA = 'set_kpi_tree_metric_formula',
  METRIC_CATEGORY_CREATED = 'metric_category_created',
  METRIC_CATEGORY_UPDATED = 'metric_category_updated',
  METRIC_CATEGORY_DELETED = 'metric_category_deleted',
  CREATE_METRIC_CATEGORY_ERROR = 'create_metric_category_error',
  UPDATE_METRIC_CATEGORY_ERROR = 'update_metric_category_error',
  METRIC_CATEGORY_CREATE_CLICKED = 'metric_category_create_clicked',
  METRIC_CATEGORY_SAVE_CLICKED = 'metric_category_save_clicked',
  CREATE_MILESTONE_ERROR = 'create_milestone_error',
  UPDATE_MILESTONE_ERROR = 'update_milestone_error',
  MILESTONE_CREATE_CLICKED = 'milestone_create_clicked',
  MILESTONE_EDIT_CLICKED = 'milestone_edit_clicked',
  MILESTONE_CREATED = 'milestone_created',
  MILESTONE_UPDATED = 'milestone_updated',
  MILESTONE_DELETED = 'milestone_deleted',
  MILESTONE_VALIDATED = 'milestone_validated',
  EVENT_GROUP_CREATED = 'event_group_created',
  EVENT_GROUP_UPDATED = 'event_group_updated',
  EVENT_GROUP_DELETED = 'event_group_deleted',
  EVENT_GROUP_VALIDATED = 'event_group_validated',
  EVENT_GROUP_CREATE_CLICKED = 'event_group_create_clicked',
  EVENT_GROUP_DUPLICATE_CLICKED = 'event_group_duplicate_clicked',
  EVENT_GROUP_EDIT_CLICKED = 'event_group_edit_clicked',
  EVENT_GROUP_SAVE_CLICKED = 'event_group_save_clicked',
  CREATE_METRIC_ERROR = 'create_metric_error',
  UPDATE_METRIC_ERROR = 'update_metric_error',
  ANALYSIS_RESULT_USER_PRESSED_PARAMETERS = 'analysis_result_user_pressed_parameters',
  SEGMENT_CREATE_CLICKED = 'segment_create_clicked',
  SEGMENT_DUPLICATE_CLICKED = 'segment_duplicate_clicked',
  SEGMENT_EDIT_CLICKED = 'segment_edit_clicked',
  SEGMENT_CATEGORY_CREATE_CLICKED = 'segment_category_create_clicked',
  UPDATE_FAVORITES_SEGMENTS_CLICKED = 'update_favorites_segments_clicked',
  SEGMENT_CATEGORY_SAVE_CLICKED = 'segment_category_save_clicked',
  SEGMENT_SAVE_CLICKED = 'segment_save_clicked',
  SEGMENT_RESCAN_VALIDATION_ERROR = 'segment_rescan_validation_error',
  SOURCE_CREATED = 'source_created ',
  TEAM_SAVE_CLICKED = 'team_save_clicked',
  TEAM_CREATED = 'team_created',
  TEAM_UPDATED = 'team_updated',
  TEAM_DELETED = 'team_deleted',
  ADD_MEMBER_CLICKED = 'add_member_clicked',
  CHANGED_TEAM_FOR_MEMBER = 'changed_team_for_member',
  CHANGED_PERMISSION_ROLE_FOR_MEMBER = 'changed_permission_role_for_member',
  MEMBER_DELETED = 'member_deleted',
  ANALYSIS_FOLDER_SAVE_CLICKED = 'analysis_folder_save_clicked',
  FEATURE_CREATE_CLICKED = 'feature_create_clicked',
  FEATURE_DUPLICATE_CLICKED = 'feature_duplicate_clicked',
  FEATURE_EDIT_CLICKED = 'feature_edit_clicked',
  FEATURE_SAVE_CLICKED = 'feature_save_clicked',
  FEATURE_FORM_FIELD_CHANGED = 'feature_form_field_changed',
  FUNNEL_CREATE_CLICKED = 'funnel_create_clicked',
  FUNNEL_DUPLICATE_CLICKED = 'funnel_duplicate_clicked',
  FUNNEL_EDIT_CLICKED = 'funnel_edit_clicked',
  HEALTH_MONITOR_DATE_CHANGED = 'health_monitor_date_changed',
  MONITORED_METRIC_REMOVED = 'monitored_metric_removed',
  MONITORED_METRIC_CREATED = 'monitored_metric_created',
  MONITORED_METRIC_UPDATED = 'monitored_metric_updated',
  MONITORED_METRIC_VIEWED = 'monitored_metric_viewed',
  MONITORED_METRIC_ALERT_CLICKED = 'monitored_metric_alert_clicked',
  HEALTH_MONITOR_GROUP_CREATED = 'health_monitor_group_created',
  HEALTH_MONITOR_GROUP_UPDATED = 'health_monitor_group_updated',
  HEALTH_MONITOR_GROUP_REMOVED = 'health_monitor_group_removed',
  HEALTH_MONITOR_GROUP_OPENED = 'health_monitor_group_opened',
  HEALTH_MONITOR_GROUP_SAVE_CLICKED = 'health_monitor_group_save_clicked',
  CREATE_SEGMENT_CATEGORY_ERROR = 'create_segment_category_error',
  UPDATE_SEGMENT_CATEGORY_ERROR = 'update_segment_category_error',
  HEALTH_MONITOR_SUBSCRIPTION_CREATED = 'health_monitor_subscription_created',
  HEALTH_MONITOR_SUBSCRIPTION_UPDATED = 'health_monitor_subscription_updated',
  HEALTH_MONITOR_SUBSCRIPTION_DELETED = 'health_monitor_subscription_deleted',
  HEALTH_MONITOR_INITIALIZED = 'health_monitor_initialized',
  ANALYSIS_PARAMETERS_CHANGED = 'analysis_parameters_changed',
  ANALYSIS_RESULT_RERUN = 'analysis_result_rerun',
  ANALYSIS_RESULT_STOP = 'analysis_result_stop',
  DELETE_ANALYSIS_RESULT = 'delete_analysis_result',
  RESTORE_ANALYSIS_RESULT = 'restore_analysis_result',
  ANALYSIS_EDIT = 'analysis_edit',
  ERROR_2003_CHANGE_CONTROL_GROUP_CLICKED = 'error_2003_change_control_group_clicked',
  MONITORED_METRIC_DEEP_DIVE_CLICK = 'monitored_metric_deep_dive_click',
  USER_SETTINGS_EMAIL_NOTIFICATIONS_ON = 'email_notifications_on',
  USER_SETTINGS_EMAIL_NOTIFICATIONS_OFF = 'email_notifications_off',
  SOURCE_TABLE_DISCOVERY = 'source_table_discovery',
  TABLE_DISCOVERY = 'table_discovery',
  ALL_TABLES_DISCOVERY = 'all_tables_discovery',
  ALL_TABLE_TYPES_SCAN = 'all_table_types_scan',
  ARTICLE_VIEWED = 'article_viewed',
  ANALYSIS_FOLDER_CREATED = 'analysis_folder_created',
  ANALYSIS_FOLDER_UPDATED = 'analysis_folder_updated',
  ANALYSIS_FOLDER_ICON_UPDATED = 'analysis_folder_icon_updated',
  ANALYSIS_FOLDER_DELETED = 'analysis_folder_deleted',
  ANALYSIS_MOVED_TO_FOLDER = 'analysis_moved_to_folder',
  INVITE_USERS_SUCCESS = 'invite_users_success',
  INVITE_USERS_ERROR = 'invite_users_error',
  INVITE_USERS_CLICKED = 'invite_users_clicked',
  FEEDBACK_CREATED_SUCCESS = 'feedback_created_success',
  FEEDBACK_BUTTON_CLICKED = 'feedback_button_clicked',
  UPDATE_USER_ONBOARDING_QUESTIONNAIRE_SUCCESS = 'update_user_questionnaire_success',
  UPDATE_USER_ONBOARDING_QUESTIONNAIRE_ERROR = 'update_user_questionnaire_error',
  USER_STARTED_QUESTIONNAIRE = 'user_started_questionnaire',
  USER_COMPLETED_QUESTIONNAIRE_STEP = 'user_completed_questionnaire_step',
  USER_COMPLETED_QUESTIONNAIRE = 'user_completed_questionnaire',
  USER_SELECTED_ANALYSIS_FROM_SIMPLE_ANALYSIS_PICKER = 'user_selected_analysis_from_simple_analysis_picker',
  FOLLOW_UP_INTERACTION_CLICKED = 'follow_up_interaction_clicked',
  CREATE_RCA_FOLLOW_UP_ACTION_FROM_FUNNEL_ANALYSIS_LINE_CHART_CLICKED = 'create_rca_follow_up_action_from_funnel_analysis_line_chart_clicked',
  CREATE_RCA_FOLLOW_UP_ACTION_FROM_KPI_ANALYSIS_LINE_CHART_CLICKED = 'create_rca_follow_up_action_from_kpi_analysis_line_chart_clicked',
  RCA_TRIGGERED_FROM_CHART_HOMEPAGE = 'rca_triggered_from_chart_homepage',
  RCA_INSIGHT_PANEL_LOADED = 'rca_insight_panel_loaded',
  CREATE_RCA_FOLLOW_UP_ACTION_FROM_HOMEPAGE_LINE_CHART_CLICKED = 'create_rca_follow_up_action_from_homepage_line_chart_clicked',
  FUNNEL_ANALYSIS_CONVERSION_FOLLOW_UP_ACTIONS_CLICKED = 'funnel_analysis_conversion_follow_up_actions_clicked',
  GOAL_DRIVERS_ANALYSIS_IMPROVE_FEATURE_ADOPTION_FOLLOW_UP_ACTIONS_CLICKED = 'goal_drivers_analysis_improve_feature_adoption_follow_up_actions_clicked',
  RETENTION_ANALYSIS_INVESTIGATE_BUCKET_FOLLOW_UP_ACTIONS_CLICKED = 'retention_analysis_investigate_bucket_follow_up_actions_clicked',
  GOAL_MODAL_TRIGGERED_FROM_HOMEPAGE = 'goal_modal_triggered_from_homepage',
  ANALYSIS_SUGGESTION_TRIGGERED_HOMEPAGE = 'analysis_suggestion_triggered_homepage',
  RCA_MODAL_TRIGGERED_FROM_CHART_HOMEPAGE = 'rca_modal_triggered_from_chart_homepage',
  HOMEPAGE_QUICK_ACTION_CLICKED = 'homepage_quick_action_clicked',
  FOLLOW_UPS_CREATED_SUCCESS = 'follow_ups_created_success',
  FOLLOW_UPS_CREATED_ERROR = 'follow_ups_created_error',
  EXECUTED_SQL_TAB_CLICKED = 'executed_sql_tab_clicked',
  ONBOARDING_DISMISSED = 'onboarding_dismissed',
  ONBOARDING_RESUMED = 'onboarding_resumed',
  ONBOARDING_RESET = 'onboarding_reset',
  ONBOARDING_DRAWER_OPENED = 'onboarding_drawer_opened',
  ONBOARDING_DRAWER_CLOSED = 'onboarding_drawer_closed',
  ONBOARDING_TASK_FOCUSED = 'onboarding_task_focused',
  ONBOARDING_TASK_ACTION_CLICKED = 'onboarding_task_action_clicked',
  ANALYSIS_FOLDER_MOVED = 'analysis_folder_moved',
  POWER_USERS_SEGMENT_CREATION_REQUESTED = 'power_users_segment_creation_requested',
  ADD_MODEL_TO_HOMEPAGE = 'add_model_to_homepage',
  REMOVE_MODEL_FROM_HOMEPAGE = 'remove_model_from_homepage',
  UPDATE_HOMEPAGE_SUBSCRIPTION = 'update_homepage_subscription',
  CREATE_HOMEPAGE_SUBSCRIPTION = 'create_homepage_subscription',
  DELETE_HOMEPAGE_SUBSCRIPTION = 'delete_homepage_subscription',
  REORDER_HOMEPAGE = 'reorder_homepage',
  WEEKLY_INTEREST_FORM_OPENED = 'weekly_interest_form_opened',
  WEEKLY_INTEREST_FORM_SUBMIT = 'weekly_interest_form_submit',
  SIMILAR_ANALYSIS_CLICKED = 'similar_analysis_clicked',
  SIMILAR_ANALYSIS_LOADED = 'similar_analysis_loaded',
  EXPERIMENT_AUTOMATION_CREATED = 'experiment_automation_created',
  EXPERIMENT_AUTOMATION_UPDATED = 'experiment_automation_updated',
  EXPERIMENT_AUTOMATION_DELETED = 'experiment_automation_deleted',
  SEGMENTATION_MODAL_TRIGGERED_FROM_HOMEPAGE = 'segmentation_modal_triggered_from_homepage',
  HOMEPAGE_FORECAST_CLICKED = 'homepage_forecast_clicked',
  ATTEMPT_CREATE_SUBSCRIPTION_WITH_OTHER_EMAIL = 'attempt_create_subscription_with_other_email',
  CREATE_QUICK_SUBSCRIPTION = 'create_quick_subscription',
  USER_DISMISSED_QUICK_SUBSCRIPTION_NOTICE = 'user_dismissed_quick_subscription_notice',
  USER_OPENED_HOMEPAGE_SUBSCRIPTIONS = 'USER_OPENED_HOMEPAGE_SUBSCRIPTIONS',
}
