import * as React from 'react';
import classNames from 'classnames';
import classes from './document-message.module.scss';
import {
  CircleInfoRegularIcon,
  WarningIcon,
} from '../../../../../../simple/controls/icons/icons.component';

export enum DocumentMessageType {
  WARN = 'warn',
  ERROR = 'error',
  INFO = 'info',
}

interface OwnProps {
  text: any;
  type?: DocumentMessageType;
  border?: boolean;
  className?: string;
}

const ICON_FOR_TYPE = {
  [DocumentMessageType.WARN]: WarningIcon,
  [DocumentMessageType.ERROR]: WarningIcon,
  [DocumentMessageType.INFO]: CircleInfoRegularIcon,
};

type AllProps = OwnProps;

export const DocumentMessage: React.FC<AllProps> = (props: AllProps) => {
  const {text, type, border, className} = props;
  const Icon = ICON_FOR_TYPE[type];

  return (
    <div
      className={classNames(
        classes.DocumentMessage,
        classes[type],
        border === false && classes.NoBorder,
        className
      )}
    >
      <Icon className={classes.Icon} />
      {text}
    </div>
  );
};

DocumentMessage.defaultProps = {
  type: DocumentMessageType.INFO,
  border: true,
};
