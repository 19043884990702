import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {EnhancedCircle, EnhancedGroup} from '../../../../../core/konva/components';
import {ScatterDataset} from '../../chart-data.types';
import {HorizontalGridLayoutInjectedProps} from '../../layouts/grid-layout.component';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {flatten, max, min, some} from 'lodash';
import {pixelInRangeFactory} from '../../chart.utils';
import {ScatterChartLabel} from '../../components/scatter-chart-label.component';

interface OwnProps extends HorizontalGridLayoutInjectedProps {
  datasets: ScatterDataset[];
  onDatasetClick?: (ds: ScatterDataset) => void;
  xLabel: string;
  yLabel: string;
  rLabel: string;
  yLabelSuffix?: string;
  xLabelSuffix?: string;
  showItemsLabels: boolean;
}

type AllProps = OwnProps;
const MIN_RADIUS = 5;
const MAX_RADIUS = 12;

export const ScatterChartDatasetContainer: React.FC<AllProps> = (props: AllProps) => {
  const {
    xLabel,
    yLabel,
    rLabel,
    width,
    height,
    x,
    y,
    datasets,
    yRange,
    xRange,
    xLabelSuffix,
    yLabelSuffix,
    onDatasetClick,
    showItemsLabels,
  } = props;
  const {style, controller} = useContext(StageContext);
  const [hoveredLabel, setHoveredLabel] = useState(null);
  /**
   * Data computed properties
   */
  const radiusRange = useMemo(() => {
    const allItemsRadius = flatten(datasets.map(ds => ds.data)).map(i => i.radius);
    const minRadius = min(allItemsRadius);
    const maxRadius = max(allItemsRadius);
    return [minRadius, maxRadius];
  }, [datasets]);
  const calcRadius = useCallback(
    (r: number): number => {
      const totalRadiusRange = radiusRange[1] - radiusRange[0]; // 99
      const totalToRadiusRange = MAX_RADIUS - MIN_RADIUS; // 7 (10 - 3)
      const v = (r - radiusRange[0]) / totalRadiusRange;
      return max([v * totalToRadiusRange + MIN_RADIUS, 1]);
    },
    [radiusRange]
  );
  const datasetOrder = useMemo(
    () => datasets.sort((a, b) => Number(a.highlight) - Number(b.highlight)),
    [datasets]
  );
  const datasetPoints = useMemo(
    () =>
      flatten(
        datasetOrder.map((ds, dsIdx) =>
          ds.data.map((item, idx) => {
            const calcX = pixelInRangeFactory(xRange, width);
            const calcY = pixelInRangeFactory(yRange, height, true);
            const key = `${ds.index}_${ds.id}_${idx}`;
            const isActive = hoveredLabel?.key === key;
            return {
              key,
              isActive,
              datasetIndex: dsIdx,
              x: calcX(item.x as any),
              y: calcY(item.y),
              color: ds.color,
              highlight: ds.highlight,
              datasetName: ds.label,
              value: item,
              radius: item.radius ? calcRadius(item.radius) : MIN_RADIUS,
              actualWidth: controller.measureTextWidth(ds.label, style.fontName, 12) + 8,
            };
          })
        )
        // @ts-ignore
      ).sort((a, b) => a.isActive - b.isActive),
    [datasetOrder, yRange, xRange, calcRadius, height, width, hoveredLabel]
  );
  const hasActive = useMemo(() => some(datasetPoints.map(p => p.isActive)), [datasetPoints]);
  const generateTooltip = useCallback((p, includeDatasetName: boolean = true) => {
    return {
      value: (
        <>
          <div>
            {xLabel}:{' '}
            <strong>
              {Number(p.value.x.toFixed(2))}
              {xLabelSuffix}
            </strong>
          </div>
          <div>
            {yLabel}:{' '}
            <strong>
              {Number(p.value.y.toFixed(2))}
              {yLabelSuffix}
            </strong>
          </div>
          {p.value.radius && (
            <div>
              {rLabel || 'R'}: <strong>{Number(p.value.radius.toFixed(2))}</strong>
            </div>
          )}
        </>
      ),
      datasetName: includeDatasetName ? p.datasetName : undefined,
      color: p.color,
    };
  }, []);
  /**
   * Render
   */
  return (
    <EnhancedGroup width={width} height={height} x={x} y={y}>
      {datasetPoints.map(p => (
        <EnhancedGroup key={`label_${p.key}`}>
          {showItemsLabels && (
            <ScatterChartLabel
              opacity={hasActive && !p.isActive ? 0.5 : 1}
              width={p.actualWidth}
              x={p.x + 20 > width ? p.x - p.actualWidth - 8 : p.x + 8}
              y={p.y}
              onMouseEnter={e => setHoveredLabel(p)}
              onMouseLeave={e => setHoveredLabel(null)}
              onClick={onDatasetClick ? e => onDatasetClick(datasets[p.datasetIndex]) : undefined}
              text={p.datasetName}
              isActive={p.isActive}
              tooltipEnabled
              tooltipData={generateTooltip(p, false)}
            />
          )}
          <EnhancedCircle
            opacity={hasActive && !p.isActive ? 0.5 : 1}
            key={`point_${p.key}`}
            onClick={onDatasetClick ? e => onDatasetClick(datasets[p.datasetIndex]) : undefined}
            x={p.x}
            y={p.y}
            radius={p.highlight ? p.radius + 2 : p.radius}
            fill={p.color}
            strokeEnabled={p.highlight}
            stroke={style.highlightColor}
            strokeWidth={2}
            tooltipScale={2}
            tooltipEnabled
            tooltipData={generateTooltip(p, true)}
            center
          />
        </EnhancedGroup>
      ))}
    </EnhancedGroup>
  );
};

ScatterChartDatasetContainer.defaultProps = {
  xLabelSuffix: '',
  yLabelSuffix: '',
};
