import * as React from 'react';
import {EnhancedGroup, EnhancedRect, EnhancedText} from '../../../../core/konva/components';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {ChartMarkArea as ChartAreaModel, XTicksRangeTimeUnit} from '../chart-data.types';
import {useContext, useMemo, useState} from 'react';
import {StageContext} from '../../../../core/konva/stage-context.component';
import {Html} from '../../../../core/konva/hoc/html.hoc';
import {ChartMarkAreaTooltip} from '../charts/line-chart/components/chart-mark-area-tooltip.component';

interface OwnProps extends ChartAreaModel {
  dateFormat: string;
  timeUnit?: XTicksRangeTimeUnit;
}

type AllProps = ShapeProps & OwnProps;

export const ChartMarkArea: React.FC<AllProps> = (props: AllProps) => {
  const {height, width, x, color, textColor, title} = props;
  const {style} = useContext(StageContext);
  const [isHover, setIsHover] = useState(false);
  const tooltipHTMLProps = useMemo(() => ({style: {zIndex: 1000}}), []);

  return (
    <EnhancedGroup x={x} y={props.y}>
      <EnhancedRect x={0} width={width} height={height} fill={color} />
      {title && (
        <EnhancedText
          text={title.toUpperCase()}
          fontFamily={style.fontName}
          fill={textColor}
          width={width}
          fontStyle={'500'}
          align={'center'}
          height={12}
          y={-12}
          ellipsis
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        />
      )}
      <Html groupProps={{y: 6, x: width / 2}} divProps={tooltipHTMLProps}>
        <ChartMarkAreaTooltip
          show={isHover}
          markArea={props}
          dateFormat={props.dateFormat}
          timeUnit={props.timeUnit}
        />
      </Html>
    </EnhancedGroup>
  );
};

ChartMarkArea.defaultProps = {
  color: 'rgba(231, 234, 243, 0.4)',
  textColor: '#757C94',
};
