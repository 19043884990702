import {RefObject, useLayoutEffect, useState} from 'react';

const POINT_FROM_TOP_TO_MAKE_FIXED = 80;

// spaceTop should be in px
export const useFixedRCAHeader = (containerRef: RefObject<HTMLElement> | null) => {
  const [shouldStick, setShouldStick] = useState(false);

  const containerElement = containerRef?.current;

  useLayoutEffect(() => {
    if (!containerElement) {
      return;
    }

    const updateShouldShowStickyHeader = () => {
      const topPosition = containerElement.getBoundingClientRect().top;
      if (topPosition < -POINT_FROM_TOP_TO_MAKE_FIXED) {
        setShouldStick(true);
      } else {
        setShouldStick(false);
      }
    };

    window.addEventListener('scroll', updateShouldShowStickyHeader, true);

    return () => {
      window.removeEventListener('scroll', updateShouldShowStickyHeader);
    };
  }, [containerElement, setShouldStick]);

  return shouldStick;
};
