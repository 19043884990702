import {useMemo, useState} from 'react';
import {TableColumn} from '../../../../../shared/components/general/grid-table/grid-table.types.ts';
import {GridTable} from '../../../../../shared/components/general/grid-table/grid-table.component.tsx';
import classNames from 'classnames';
import classes from './explore-users-list.module.scss';
import {Button, FileExportLightIcon, TooltipIfOverflow} from 'ui-components';

interface ListData {
  primaryKey: string;
  columns: Array<{key: string; name: string}>;
  data: any[];
  total: number;
}

interface Props {
  data: ListData;
  onSaveWorkflow: () => void;
  onExport: () => void;
  isLoadingExport?: boolean;
  className?: string;
}

const PER_PAGE = 20;

export const ExploreUsersList = (props: Props) => {
  const {data, onSaveWorkflow, isLoadingExport, onExport, className} = props;
  const [page, setPage] = useState(0);

  const columns: TableColumn[] = useMemo(
    () =>
      data.columns.map(column => ({
        key: column.key,
        title: column.name,
        render: v => (
          <TooltipIfOverflow title={v[column.key]}>
            <span className={classes.Value}>{v[column.key]}</span>
          </TooltipIfOverflow>
        ),
        width: column.key === data.primaryKey ? '20rem' : undefined,
      })),
    [data.columns, data.primaryKey]
  );
  const items = useMemo(() => {
    return data.data.slice(page * PER_PAGE, (page + 1) * PER_PAGE);
  }, [data.data, page]);
  const hasMoreResults = data.total === 500;

  return (
    <div className={classNames(classes.ExploreUsersList, className)}>
      <div className={classes.Header}>
        <div className={classes.Title}>
          <span>{hasMoreResults ? 'Showing' : 'Found'}</span>
          {hasMoreResults && <span>a sample of</span>}
          <span className={classes.Count}>{data.total.toLocaleString()} users</span>
        </div>
        <div className={classes.Actions}>
          <Button
            onClick={onExport}
            variant={'outlined'}
            icon={FileExportLightIcon}
            disabled={isLoadingExport}
          >
            Export
          </Button>
          <Button onClick={onSaveWorkflow}>Save as new workflow</Button>
        </div>
      </div>
      <GridTable
        className={className}
        columns={columns}
        data={items}
        dataKey={data.primaryKey}
        pagination={true}
        paginationMode={'pages'}
        total={data.data.length}
        page={page}
        perPage={PER_PAGE}
        onPageChange={(_, page) => setPage(page)}
      />
    </div>
  );
};
