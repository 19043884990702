import {RetentionTimeUnit} from '../../../../types';
import {range} from 'lodash';

export const generateRetentionLabels = (
  timeUnit: RetentionTimeUnit,
  n: number,
  count: number,
  include0 = true
): string[] => {
  const start = include0 ? 0 : 1;
  const end = include0 ? count : count + 1;
  return range(start, end).map(i => {
    const label = `${timeUnit} `;
    if (n === 1) {
      return label + i;
    }
    return label + `${i * n}-${(i + 1) * n}`;
  });
};
