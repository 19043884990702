import {exists} from 'front-core';
import {TrendChip} from 'ui-components';
import {HomepageMetricViewer} from '../../../../../homepage/components/homepage-summary/components/homepage-model-samples-viewer/homepage-metric-viewer/homepage-metric-viewer.component.tsx';
import {
  ModelSample,
  ModelSeriesGranularity,
} from '../../../../../../objects/models/model-sample-series.model.ts';
import {useContext, useMemo} from 'react';
import {getSampleWoWChange} from '../../../../../homepage/components/homepage-summary/homepage-summary.utils.ts';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook.ts';
import {formatMetricValue} from '../../kpi-tree.utils.ts';
import {KPITreeContext} from '../../kpi-tree.context.tsx';
import {KPITreePopoverLayout} from '../kpi-tree-popover-layout/kpi-tree-popover-layout.component.tsx';

interface OwnProps {
  name: string;
  metricId: number;
  isPercentageValue: boolean;
  granularity: ModelSeriesGranularity;
  lastSample?: ModelSample;
  previousSample?: ModelSample;
  children: any;
}

type AllProps = OwnProps;

export const KPINodePopover = (props: AllProps) => {
  const {metricId, name, granularity, lastSample, previousSample, isPercentageValue, children} =
    props;
  const {toDate, datetimeAttribute} = useContext(KPITreeContext);
  const {userSettings} = useProductData();
  const trend = useMemo(
    () => getSampleWoWChange(lastSample, previousSample),
    [lastSample, previousSample]
  );

  return (
    <KPITreePopoverLayout
      title={name}
      subTitleItems={[
        {
          label: `Last ${granularity}`,
          content: exists(lastSample?.value)
            ? formatMetricValue(lastSample.value, isPercentageValue)
            : '-',
        },
        {
          label: `Previous${granularity === 'day' ? ' week  ' : ' '} ${granularity}`,
          content: exists(previousSample?.value)
            ? formatMetricValue(previousSample.value, isPercentageValue)
            : '-',
        },
        trend && {
          label: 'Change',
          content: (
            <TrendChip value={trend.value} size={'xsmall'} isSignificant={trend.isSignificant} />
          ),
        },
      ]}
      content={
        <HomepageMetricViewer
          metricId={metricId}
          configuration={userSettings}
          showHeader={false}
          showOptions={false}
          showLegend={false}
          toDate={toDate}
          datetimeAttribute={datetimeAttribute}
        />
      }
    >
      {children}
    </KPITreePopoverLayout>
  );
};
