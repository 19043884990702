import * as React from 'react';
import classNames from 'classnames';
import classes from './query-builder.module.scss';
import {QueryElementBuilder} from './core/query-element-builder.component';
import {SqlElement} from './query-builder.types';
import {QueryBuilderContextProvider} from './query-builder.context';
import {QueryBuilderConfig} from './query-builder-ui.types';
import {useMemo} from 'react';

export const queryBuilderClasses = classes;

interface OwnProps {
  placeholder?: SqlElement;
  query?: SqlElement;
  config?: QueryBuilderConfig;
  onChange?: (query: SqlElement) => void;
  disabled?: boolean;
  viewMode?: boolean;
  errors?: any;
  className?: string;
}

type AllProps = OwnProps;

export const QueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {
    query: queryFromProps,
    config,
    errors,
    onChange,
    disabled,
    viewMode,
    placeholder,
    className,
  } = props;

  const query = useMemo(() => queryFromProps || placeholder, [placeholder, queryFromProps]);

  return (
    <div className={classNames(classes.QueryBuilder, className)}>
      <QueryBuilderContextProvider
        errors={errors}
        data={query}
        onChange={onChange}
        disabled={disabled || viewMode}
        viewMode={viewMode}
        config={config}
        placeholder={placeholder}
      >
        <QueryElementBuilder root />
      </QueryBuilderContextProvider>
    </div>
  );
};

QueryBuilder.defaultProps = {
  config: {},
  placeholder: {} as any,
};
