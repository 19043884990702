// @ts-ignore
import marked from 'marked';
import katex from 'katex';

const renderer = new marked.Renderer();

function mathsExpression(expr) {
  if (expr.match(/^\$\$[\s\S]*\$\$$/)) {
    expr = expr.substr(2, expr.length - 4);
    return katex.renderToString(expr, {displayMode: true});
  } else if (expr.match(/^\$[\s\S]*\$$/)) {
    expr = expr.substr(1, expr.length - 2);
    return katex.renderToString(expr, {displayMode: false});
  }
}

marked.use({
  renderer: {
    code: (code: string, lang: string, escaped: boolean) => {
      if (!lang) {
        const math = mathsExpression(code);
        if (math) {
          return math;
        }
      }

      return renderer.code(code, lang, escaped);
    },
    codespan: text => {
      const math = mathsExpression(text);

      if (math) {
        return math;
      }

      return renderer.codespan(text);
    },
  },
});

export default marked;
