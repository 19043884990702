import classes from './rca-figure-viewer.module.scss';
import {RCAElements, RCAElementType} from '../../../../types';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';
import {ChildRenderer} from '../../../core/child-renderer.component';
import {useRCAElementTitles} from './use-rca-element-titles.hook';
import {CSSProperties} from 'react';
import {RCASegmentationViewMode} from './rca-figure-viewer.types';

type RCAFigureViewerElementsProps = {
  rcaFigureElements: RCAElements;
  elementStyle?: CSSProperties;
};

const EXTRA_PROPS = {
  [RCAElementType.SEGMENTATION]: {
    viewMode: RCASegmentationViewMode.EXPLAIN,
  },
};

export const RCAFigureViewerElements = (props: RCAFigureViewerElementsProps) => {
  const {rcaFigureElements, elementStyle} = props;

  const elementItems = useRCAElementTitles();

  return (
    <>
      {Object.keys(elementItems).map(elementKey => {
        const elementItem = elementItems[elementKey];
        const element = rcaFigureElements[elementKey];
        const extraProps = EXTRA_PROPS[elementKey] || {};

        return (
          element && (
            <DocumentTitleWrapper
              key={elementItem.key}
              title={elementItem.label}
              subtitle={elementItem.subtitle}
              icon={elementItem.icon}
              id={elementItem.key}
              className={classes.Element}
              style={elementStyle}
            >
              <ChildRenderer children_={element} className={classes.MarginBottom} {...extraProps} />
            </DocumentTitleWrapper>
          )
        );
      })}
    </>
  );
};
