import classes from './explore-users.module.scss';
import {ExploreUsersController} from './components/explore-users-controller/explore-users-controller.component.tsx';
import {useCallback, useContext, useState} from 'react';
import {ExportWorkflowDTO} from '../../../../objects/dto/workflow.dto.ts';
import {useRemoteSourceStated} from 'ui-components';
import {getWorkflowOnDemandNetworkRequest} from '../../../../http/workflows.network-requests.ts';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import {ExploreUsersList} from './components/explore-users-list/explore-users-list.component.tsx';
import {PanelsContext} from '../../../../core/contexts/panels.context.tsx';
import {PanelKey} from '../../../../constants/panels.ts';
import {WorkflowType} from '../../../../objects/models/workflow.model.ts';
import PageLayout from '../../../shared/components/layout/page-layout';
import TransKeys from '../../../../constants/translation-keys.ts';
import {useTranslation} from 'react-i18next';
import {PageHeader} from '../../../shared/components/layout/page-header/page-header.component.tsx';
import {AppRoutes} from '../../../../constants/app-routes.ts';
import {useDispatch, useSelector} from 'react-redux';
import {exportWorkflow} from '../../../../store/workflows/workflows.actions.ts';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors.ts';
import {ActionKey} from '../../../../constants/action-key.ts';

interface Props {}

export const ExploreWorkflows = (props: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [parameters, setParameters] = useState(undefined);
  const {openSecondaryPanel} = useContext(PanelsContext);
  const isLoadingExport = useSelector(state =>
    getReducedLoadingStateSelector(ActionKey.EXPORT_WORKFLOW)(state)
  );
  const {
    source: list,
    exec: getList,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getWorkflowOnDemandNetworkRequest,
  });
  const onDataChange = useCallback(
    (data: ExportWorkflowDTO) => {
      setParameters(data);
      getList(data);
    },
    [getList]
  );
  const onSaveWorkflow = useCallback(() => {
    openSecondaryPanel(PanelKey.WORKFLOW_FORM_PANEL, {
      data: {
        type: WorkflowType.KPI_REACH,
        entity: parameters.entity,
        parameters: {
          metric: parameters.metric,
          attributes: parameters.attributes,
          population_filter: parameters.population_filter,
        },
      },
    });
  }, [parameters, openSecondaryPanel]);
  const onExport = useCallback(() => dispatch(exportWorkflow(parameters)), [parameters, dispatch]);

  return (
    <PageLayout.Layout>
      <PageHeader
        title={'Explore'}
        crumbs={[
          {
            name: t(TransKeys.WORKFLOWS.HEADER.TITLE),
            navigateTo: AppRoutes.workflows(),
          },
        ]}
      />
      <PageLayout.Body noPadding className={classes.PageBody}>
        <div className={classes.ExploreUsersMain}>
          <div className={classes.ExploreUsers}>
            <div className={classes.TitleWrapper}>
              <div className={classes.Title}>Explore your users</div>
            </div>
            <div className={classes.Content}>
              <ExploreUsersController
                data={parameters}
                onSubmit={onDataChange}
                className={classes.Controller}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className={classes.Main}>
            {isLoading && <GenericLoading />}
            {list && (
              <ExploreUsersList
                data={list}
                onSaveWorkflow={onSaveWorkflow}
                isLoadingExport={isLoadingExport}
                onExport={onExport}
              />
            )}
            {!list && <div className={classes.EmptyState}>Click "submit" to explore</div>}
          </div>
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};
