import {MetricValueType} from '../../../../objects/models/metric.model.ts';
import {TableEntity} from '../../../../objects/models/table.model.ts';
import {ChangeDescription, ChangeSentiment} from '../rca-insights.model.ts';
import {ModelSampleSeriesModel} from '../../../../objects/models/model-sample-series.model.ts';

export enum ModelSampleSentenceKeys {
  USERS_ONGOING_TREND = 'USERS_ONGOING_TREND',
  USERS_SIGNIFICANT_CHANGE = 'USERS_SIGNIFICANT_CHANGE',
  USERS_ALL_TIME_HIGH_OR_LOW = 'USERS_ALL_TIME_HIGH_OR_LOW',
  LOOPS_KPI_TREND = 'LOOPS_KPI_TREND',
  PERIOD_METRIC_ALL_TIME_HIGH_OR_LOW = 'PERIOD_METRIC_ALL_TIME_HIGH_OR_LOW',
}

interface SharedModelSampleInsight {
  key: ModelSampleSentenceKeys;
  sampledModelType: Omit<ModelSampleSeriesModel, 'feature'>;
  sampledModelName: string;
  sampledModelMeasuredItemCompoundName: string[];
  sampledModelValueType: MetricValueType;
  isSignificant: boolean;
  isSignificantByLoops: boolean;
  allowInsightNumberOfUsersSentence: boolean;
  entity: TableEntity;
  xoxGranularity: string;
  granularityPlural: string;
  granularity: string;
  valueBasedChangeSentiment: ChangeSentiment;
  denominatorBasedChangeSentiment: ChangeSentiment;
  valueBasedChangeDescription: ChangeDescription;
  denominatorBasedChangeDescription: ChangeDescription;
  valueBasedPercentageChangeBetweenLastAndPrevious: number;
  denominatorBasedPercentageChangeBetweenLastAndPrevious: number;
  lastSampleValue: number;
  lastSampleDenominator: number;
  lastSampleDate: string;
  lastSampleRcaResultId: number;
  previousSampleValue: number;
  previousSampleDate: string;
  previousSampleDenominator: number;
  // Need to remove metricName and metricValueType from here doesn't look like they are used
  metricName: string;
  metricValueType: MetricValueType;
  // TODO! check
}

interface SharedSentenceParts {
  value: number;
}

// Trend insight sentence
interface TrendSentenceParts extends SharedSentenceParts {
  trendDescription: ChangeDescription;
  trendNumberOfPeriods: number;
  trendStartDate: string;
  changeDescription: ChangeDescription;
}

export interface TrendModelSampleInsight extends SharedModelSampleInsight {
  key: ModelSampleSentenceKeys;
  sentenceParts: TrendSentenceParts;
}

// Period All-Time High or Low sentence
interface PeriodAllTimeHighOrLowInsightSentenceParts extends SharedSentenceParts {
  valueBasedChangeDescription: ChangeDescription;
}

export interface PeriodAllTimeHighOrLowInsight extends SharedModelSampleInsight {
  key: ModelSampleSentenceKeys;
  sentenceParts: PeriodAllTimeHighOrLowInsightSentenceParts;
}

// Number of users insight sentence
export interface UsersAllTimeHighOrLowSentenceParts extends SharedSentenceParts {
  changeDescription: ChangeDescription;
  isMaximumInPeriod: boolean;
}

export interface UsersSignificantChangeSentenceParts extends SharedSentenceParts {
  changeDescription: ChangeDescription;
  lastSampleUserCount: number;
  previousSampleUserCount: number;
}

export interface UsersOngoingTrendSentenceParts extends SharedSentenceParts {
  changeDescription: ChangeDescription;
  lastSampleUserCount: number;
  previousSampleUserCount: number;
  trendNumberOfPeriods: number;
}

type NumberOfUsersSentenceParts =
  | UsersAllTimeHighOrLowSentenceParts
  | UsersSignificantChangeSentenceParts
  | UsersOngoingTrendSentenceParts;

export interface NumberOfUsersMetricSampleInsight extends SharedModelSampleInsight {
  sentenceKey: ModelSampleSentenceKeys;
  sentenceParts: NumberOfUsersSentenceParts;
}

export interface SampledModelSentences {
  trendInsight?: TrendModelSampleInsight;
  periodAllTimeHighOrLowInsight?: PeriodAllTimeHighOrLowInsight;
  numberOfUsersInsight?: NumberOfUsersMetricSampleInsight;
}

export interface SampledModelXoXOverview {
  sampledModelType: Omit<ModelSampleSeriesModel, 'feature'>;
  sampledModelName: string;
  sampledModelValueType: MetricValueType;
  sampledModelMeasuredItemCompoundName: string[];
  higherIsBetter: boolean;
  lastSampleValue: number;
  lastSampleExpectedValue: number;
  lastValueAndLastExpectedValueByLoopsChange: number;
  xoxChangeValue: number;
  xoxChangeDescription: ChangeDescription;
  xoxGranularity: string;
  granularity: string;
  isSignificantXoX: boolean;
  isSignificantLoopsAlgo: boolean;
}
