import {NoExplainers, RCAInsightSentence} from '../rca-insights.model.ts';
import {
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NoExplainers;
}

type AllProps = OwnProps;

const NoExplainersFormatter = (props: AllProps) => {
  const {formattedSentenceParts} = props;
  const {changeDescription} = formattedSentenceParts;
  return (
    <SentenceContainer>
      <SentencePart
        text={`We didn’t find any segments that can exclusively and strongly explain the ${changeDescription} 💤`}
      />
    </SentenceContainer>
  );
};

export {NoExplainersFormatter};
