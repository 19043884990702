import * as React from 'react';
import {CommandType, DataValidationFigure} from '../../../types';
import classes from './data-validation-viewer.module.scss';
import {useCallback, useContext, useMemo, useState} from 'react';
import {get, groupBy} from 'lodash';
import {Tooltip} from '@material-ui/core';
import {
  AlertIcon,
  AlertSolidIcon,
  BadgeCheckSolidIcon,
  CheckIcon,
  ChevronDownIcon,
  CircleInfoRegularIcon,
} from '../../../../../simple/controls/icons/icons.component';
import classNames from 'classnames';
import {DocumentCommandEmitterContext} from '../../../contexts/document-command-emitter.context';

export interface OwnProps extends DataValidationFigure {
  className?: string;
}

export const DataValidationViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {data} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const groupByStatus = useMemo(() => groupBy(data, 'status'), [data]);
  const [isOpen, setIsOpen] = useState(false);
  const titleData = useMemo(() => {
    if (groupByStatus['error']?.length > 0) {
      return {
        hasErrors: true,
        text: 'Issues with data validation',
        secondaryText: `${groupByStatus['error']?.length}/${data.length} tests failed`,
        helperText: `When there is an issue with data validation, it may indicate the analysis is corrupted and should be re-run. it’s also possible this is just low activity. It is recommended to check with the data engineers to confirm the cause and ensure proper data collection.`,
      };
    }
    return {
      hasErrors: false,
      text: 'All data validated:',
      secondaryText: `${groupByStatus['success']?.length} tests passed successfully`,
      helperText: `Data validation makes sure there were no data outages and there was continuous data flow to Loops.`,
    };
  }, [groupByStatus, data.length]);
  const onLearnMore = useCallback(
    (learnMoreArticleId: string) =>
      emitEvent({
        type: CommandType.VIEW_ARTICLE,
        payload: {
          articleId: learnMoreArticleId,
        },
      }),
    [emitEvent]
  );

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={classes.DataValidationViewer}>
      <div
        className={classNames(
          classes.DataValidationWrapper,
          titleData.hasErrors && classes.HasErrors,
          isOpen && classes.Open
        )}
      >
        <div className={classes.TitleWrapper} onClick={isOpen ? () => setIsOpen(false) : undefined}>
          <div
            className={classes.Title}
            onClick={e => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
          >
            {titleData.hasErrors && <AlertSolidIcon className={classes.TitleIcon} />}
            {!titleData.hasErrors && <BadgeCheckSolidIcon className={classes.TitleIcon} />}
            <div className={classes.Primary}>{titleData.text}</div>
            <div className={classes.Secondary}>{titleData.secondaryText}</div>
            {titleData.helperText && (
              <Tooltip title={titleData.helperText} placement={'top'}>
                <CircleInfoRegularIcon className={classes.HelperTextIcon} />
              </Tooltip>
            )}
            <ChevronDownIcon className={classes.DDIcon} />
          </div>
        </div>
        <div className={classes.Content}>
          {data.map((validationData, idx) => (
            <div
              key={idx}
              className={classNames(
                classes.ValidationItem,
                validationData.status === 'error' && classes.HasError
              )}
            >
              <div className={classes.ItemContent}>
                {validationData.status === 'error' && (
                  <AlertIcon className={classes.ValidationIcon} />
                )}
                {validationData.status === 'success' && (
                  <CheckIcon className={classes.ValidationIcon} />
                )}
                <div className={classes.Primary}>{validationData.text}</div>
                <div className={classes.Secondary}>{validationData.secondaryText}</div>
                {validationData.learnMoreArticleId && (
                  <div
                    onClick={() => onLearnMore(validationData.learnMoreArticleId)}
                    className={classes.LearnMore}
                  >
                    Learn more
                  </div>
                )}
              </div>
              {validationData.command && (
                <div className={classes.Command}>
                  <span
                    onClick={e =>
                      emitEvent({
                        ...validationData.command,
                        element: e.target,
                      })
                    }
                    className={classes.CommandButton}
                  >
                    {get(validationData.command, 'payload.text')}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
