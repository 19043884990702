import * as React from 'react';
import {DialogLayout} from '../../simple/generic/dialog-layout/dialog-layout.component';
import classNames from 'classnames';
import classes from './dialog.module.scss';
import {DatePicker} from '../inputs/date-picker/date-picker.component';

interface OwnProps {
  className?: string;
  value?: Date;
  label?: string;
  onSubmit?: (value: Date) => void;
  utc?: boolean;
  minDate?: Date;
  maxDate?: Date;
  clearable?: boolean;
  filterDate?: (date: Date) => boolean;
  showMonthYearPicker?: boolean;
  showWeekPicker?: boolean;
  locale?: string;
}

type AllProps = OwnProps;

export const DateDialog: React.FC<AllProps> = (props: AllProps) => {
  const {value, label, onSubmit, className, utc, minDate, maxDate, clearable, filterDate} = props;
  return (
    <DialogLayout className={classNames(classes.DateFilter, className)}>
      <DatePicker
        selected={value}
        onChange={(date: Date) => onSubmit(date)}
        inline
        utc={utc}
        minDate={minDate}
        maxDate={maxDate}
        isClearable={clearable}
        filterDate={filterDate}
        showMonthYearPicker={props.showMonthYearPicker}
        locale={props.locale}
        // @ts-ignore
        showWeekPicker={props.showWeekPicker}
      />
    </DialogLayout>
  );
};

DateDialog.defaultProps = {};
