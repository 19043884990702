import * as React from 'react';
import {SmartTableFigureColumn, SPECIAL_SMART_TABLE_DATA_KEYS} from '../../../../types';
import classes from '../smart-table.module.scss';
import {TableCell} from './table-cell.component';
import {CSSProperties, useContext, useMemo} from 'react';
import {SmartTableContext} from '../smart-table.context';
import classNames from 'classnames';
import {ColorUtil} from '../../../../document-viewer.utils';
import {colorAlphaTransformer} from '../../../../../../../utils/colors';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';

interface OwnProps {
  columns: SmartTableFigureColumn<any>[];
  data: any;
  indent?: boolean;
}

type AllProps = OwnProps;
export const generateRowId = (key: string) => `row_${key}`;

export const TableRow: React.FC<AllProps> = (props: AllProps) => {
  const {columns, data, indent} = props;
  const {
    options,
    dataKey,
    columnsState,
    onClick: onClickFromProps,
    selectedKey,
  } = useContext(SmartTableContext);
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const dataKeyValue = useMemo(() => data[dataKey], [data, dataKey]);
  const significant = useMemo(
    () => (options.significantDataKey ? data[options.significantDataKey] : undefined),
    [data, options.significantDataKey]
  );
  const dataOptions = useMemo(
    () => (options.data || {})[dataKeyValue],
    [options.data, dataKeyValue]
  );
  const style: CSSProperties = useMemo(() => {
    const color = dataOptions?.color
      ? colorAlphaTransformer(ColorUtil.getColor(dataOptions?.color), 0.25)
      : undefined;
    return {
      backgroundColor: color,
    };
  }, [dataOptions]);
  const onClick = useMemo(() => {
    if (data[SPECIAL_SMART_TABLE_DATA_KEYS.DISABLED]) {
      return undefined;
    }
    if (onClickFromProps) {
      return () => onClickFromProps(data);
    }
    if (options.clickCommandDataKey) {
      const command = data[options.clickCommandDataKey];
      if (!command) {
        return undefined;
      }
      return () => emitEvent(command);
    }
  }, [onClickFromProps, options.clickCommandDataKey, emitEvent, data]);

  return (
    <tr
      id={generateRowId(data[dataKey])}
      style={style}
      className={classNames(
        classes.TableRow,
        indent && classes.Indent,
        significant === false && classes.Insignificant,
        onClick && classes.Clickable,
        selectedKey && data[dataKey] === selectedKey && classes.Selected,
        data[SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]
      )}
      onClick={onClick}
    >
      {columns.map((c, idx) => (
        <React.Fragment key={c.key}>
          {idx === 1 && <td className={classes.FakeTableCell} />}
          <TableCell
            column={c}
            data={data}
            dataOptions={dataOptions}
            columnState={columnsState[c.key]}
          />
        </React.Fragment>
      ))}
    </tr>
  );
};

TableRow.defaultProps = {};
