import classNames from 'classnames';
import classes from './empty-state-full-mode.module.scss';
import {PlusRegularIcon, PopoverWrapper} from 'ui-components';
import {MetricNodeSelector} from '../metric-node-selector/metric-node-selector.component.tsx';
import {Metric} from '../../../../../../objects/models/metric.model';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  onSelectMetric: (metric: Metric) => void;
  className?: string;
}

type AllProps = OwnProps;

export const EmptyStateFullMode = (props: AllProps) => {
  const {onSelectMetric, className} = props;
  const {t} = useTranslation();

  return (
    <PopoverWrapper
      buttonRenderer={({onClick, isOpen}) => (
        <div
          onClick={onClick}
          className={classNames(classes.EmptyNode, isOpen && classes.Active, className)}
        >
          <PlusRegularIcon className={classes.AddIcon} />
          <div className={classes.Text}>{t(TransKeys.KPI_TREE.EMPTY_STATE_FULL_MODE.TEXT)}</div>
        </div>
      )}
    >
      <MetricNodeSelector onChange={(metricId, metric) => onSelectMetric(metric)} />
    </PopoverWrapper>
  );
};
