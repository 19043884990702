export enum AIConversationRole {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant',
}

export enum AICMessageFeedback {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
}

export enum AIConversationMessageType {
  TEXT = 'text',
  KPI_GRAPH = 'kpi_graph',
  USER_CHOOSE_KPI = 'user_choose_kpi',
  RUN_ANALYSES_RECOMMENDATION = 'run_analyses_recommendation',
  SHOW_SUGGESTIONS = 'show_suggestions',
}

export interface AIConversationMessage<T> {
  id: number;
  role: AIConversationRole;
  type: AIConversationMessageType;
  content: T;
  feedback?: AICMessageFeedback;
  createdOn: string;
}

export interface UserChooseKPIMessageData {
  metricIds: number[];
}

export interface KPIGraphMessageData {
  metricId: number;
  metricName: string;
  segmentId: number;
  segmentName: string;
  segmentValues: string[];
  startDate: string;
  endDate: string;
  isRcaQuestion: boolean;
}

export interface RunAnalysesRecommendationMessageData {
  metricId: number;
  metricName: string;
  segmentId: number;
  segmentName: string;
  segmentValues: string[];
  startDate: string;
  endDate: string;
  analysisType: AIChatBotAnalysisType;
  payloadType: AIConversationMessageType;
}

export interface AIConversationThread {
  id: number;
  title?: string;
  messages?: AIConversationMessage<any>[];
  completedOn: string;
  createdOn?: string;
  isActive?: boolean;
}

export enum AIChatBotAnalysisType {
  // These "magic strings" are validated by our growthAIService
  RELEASE_IMPACT = 'Release Impact',
  GOAL_DRIVERS = 'Goal Drivers',
  HABIT_MOMENTS = 'Habit Moment',
  USER_JOURNEY = 'Journey',
  EVENTS_DISCOVERY = 'Journey Before Churn',
  POWER_USERS = 'Power Users',
}
