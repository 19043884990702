import * as React from 'react';
import classes from '../../../chart.module.scss';
import {useMemo, useRef} from 'react';
import {ChartAnnotation} from '../../../chart-data.types';
import {chartClasses} from '../../../chart.consts';
import moment from 'moment/moment';
import {take} from 'lodash';
import ClickAnimation from '../../../../../../assets/images/click-on-point-animation.gif';

interface OwnProps {
  show: boolean;
  annotations: ChartAnnotation[];
  dateFormat?: string;
  showFooter?: boolean;
}

type AllProps = OwnProps;

const MAX_ANNOTATIONS = 4;

export const AnnotationTooltip: React.FC<AllProps> = (props: AllProps) => {
  const {annotations, show, dateFormat, showFooter} = props;
  const head = useMemo(() => {
    return moment.utc(annotations[0].timestamp).format(dateFormat).toUpperCase();
  }, [annotations]);
  const tooltipRef = useRef<HTMLDivElement>();
  const renderAnnotations = useMemo(() => take(annotations, MAX_ANNOTATIONS), [annotations]);

  return (
    <div
      ref={tooltipRef}
      className={chartClasses.AnnotationTooltip}
      style={{
        opacity: show ? 1 : 0,
      }}
    >
      <div className={classes.Head}>{head}</div>
      <div className={classes.Main}>
        {renderAnnotations.map((a, i) => (
          <div
            className={chartClasses.Title}
            key={i}
            style={{
              color: a.textColor,
            }}
          >
            {a.icon && (
              <div className={classes.IconWrapper}>
                <a.icon className={chartClasses.Icon} />
              </div>
            )}
            {a.type && <span className={chartClasses.Type}>{a.type}</span>}
            <span className={chartClasses.Name}>{a.title}</span>
          </div>
        ))}
        {annotations.length > MAX_ANNOTATIONS && (
          <div className={classes.More}>
            {annotations.length - MAX_ANNOTATIONS} more annotation(s)...
          </div>
        )}
      </div>
      {showFooter && (
        <div className={chartClasses.Footer}>
          <img src={ClickAnimation} alt={''} className={classes.PointerIcon} />
          <span className={classes.Text}>Click on line to see annotations</span>
        </div>
      )}
    </div>
  );
};
