import {
  ActionsDropdown,
  EnvelopeLightIcon,
  MoreIcon,
  NoticeMessage,
  SlackIcon,
  useLocalStorage,
} from 'ui-components';
import classes from './homepage-list-no-subscriptions-message.module.scss';
import TransKeys from '../../../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';
import {useCallback, useContext, useMemo} from 'react';
import {PanelsContext} from '../../../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../../../constants/panels';
import {HOMEPAGE_ID_PATH_PARAM} from '../../../../../../../../constants/app-routes';
import {uniq} from 'lodash';
import {AttachedHomepageModel} from '../../../../../../../../objects/models/homepage.model';
import {useAmplitude} from '../../../../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../../../constants/amplitude-event';
type HomepageListNoSubscriptionsMessageProps = {
  isUserSubscribed?: boolean;
  homepageItems: AttachedHomepageModel[];
  homepageId: number;
};
export const HomepageListNoSubscriptionsMessage = (
  props: HomepageListNoSubscriptionsMessageProps
) => {
  const {t} = useTranslation();
  const notify = useAmplitude();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const [dismissedMessageHomepageIds, setDismissedMessageHomepageIds] = useLocalStorage(
    `dismissed-no-subscriptions-notice-message`,
    []
  );
  const {isUserSubscribed, homepageItems, homepageId} = props;
  const onDismissCreateSubscriptionMessage = useCallback(() => {
    notify(AmplitudeEvent.USER_DISMISSED_QUICK_SUBSCRIPTION_NOTICE, {homepageId});
    setDismissedMessageHomepageIds(dismissedHompageIds =>
      dismissedHompageIds ? uniq([...dismissedHompageIds, homepageId]) : [homepageId]
    );
  }, [setDismissedMessageHomepageIds, homepageId, notify]);

  const onCreateQuickSubscription = useCallback(() => {
    notify(AmplitudeEvent.CREATE_QUICK_SUBSCRIPTION, {homepageId});
    openSecondaryPanel(PanelKey.HOMEPAGE_QUICK_SUBSCRIPTION_PANEL, {
      [HOMEPAGE_ID_PATH_PARAM]: homepageId,
    });
  }, [openSecondaryPanel, homepageId, notify]);

  const isNoSubscriptionMessageDismissed = useMemo(
    () => dismissedMessageHomepageIds?.includes(homepageId),
    [dismissedMessageHomepageIds, homepageId]
  );

  const createSubscriptionMessage = useMemo(() => {
    return (
      <div className={classes.CreateSubscriptionMessage}>
        <SlackIcon />
        <EnvelopeLightIcon />
        <div>
          {t(
            TransKeys.HOMEPAGE.NO_SUBSCRIPTIONS_WARNING_MESSAGE.OPEN_SUBSRIPTION_LIST_BUTTON.LABEL
          )}
        </div>
      </div>
    );
  }, [t]);

  if (!isUserSubscribed && homepageItems.length > 0 && !isNoSubscriptionMessageDismissed) {
    return (
      <div className={classes.MissingSubscriptionMessageContainer}>
        <NoticeMessage
          fullWidth
          variant="warn"
          message={
            <div className={classes.MissingSubscriptionMessage}>
              <div className={classes.Title}>
                {t(TransKeys.HOMEPAGE.NO_SUBSCRIPTIONS_WARNING_MESSAGE.TITLE)}
              </div>
              <div className={classes.Content}>
                {t(TransKeys.HOMEPAGE.NO_SUBSCRIPTIONS_WARNING_MESSAGE.CONTENT)}
              </div>
              <div className={classes.Footer}>
                <div
                  className={classes.CreateSubsciptionMessageCTA}
                  onClick={onCreateQuickSubscription}
                >
                  {createSubscriptionMessage}
                </div>
                <ActionsDropdown
                  border={false}
                  iconDropdown
                  className={classes.MoreIcon}
                  buttonComponent={props => <MoreIcon {...props} />}
                  actions={[{title: 'Close', onClick: onDismissCreateSubscriptionMessage}]}
                  icon={MoreIcon}
                  iconSize="small"
                />
              </div>
            </div>
          }
        />
      </div>
    );
  }

  if (!isUserSubscribed && homepageItems.length > 0 && isNoSubscriptionMessageDismissed) {
    return (
      <div
        className={classes.CreateSubscriptionMessageContainer}
        onClick={onCreateQuickSubscription}
      >
        {createSubscriptionMessage}
      </div>
    );
  }

  return null;
};
