import {SignalDataType} from '../../../../objects/models/signal.model.ts';

export const COLUMN_TYPE_TO_SIGNAL_DATA_TYPE_MAP = {
  string: SignalDataType.STRING,
  integer: SignalDataType.INTEGER,
  boolean: SignalDataType.BOOLEAN,
  float: SignalDataType.DECIMAL,
  date: SignalDataType.TIMESTAMP,
};

export enum SegmentTabOption {
  COLUMN_VALUE = 'column_value',
  CASES_CONDITION = 'cases_condition',
  FIRST_OR_LAST_EVENT_VALUE = 'first_or_last_event_aggregation',
  KPI_ACHIEVEMENT = 'kpi_achievement',
}

export const TABS = {
  [SegmentTabOption.COLUMN_VALUE]: {
    key: SegmentTabOption.COLUMN_VALUE,
    title: 'Property value based',
    helperText:
      'Classify users to segments based on the value in a specific column. E.g. country. <a href="#" data-beacon-article-sidebar="37">Learn more</a>',
  },
  [SegmentTabOption.CASES_CONDITION]: {
    key: SegmentTabOption.CASES_CONDITION,
    title: 'Condition based',
    helperText:
      'Classify users to segments based on a condition. E.g. if a source is like %google% then add the user to Google segment. <a href="#" data-beacon-article-sidebar="37">Learn more</a>',
  },
  [SegmentTabOption.KPI_ACHIEVEMENT]: {
    key: SegmentTabOption.KPI_ACHIEVEMENT,
    title: 'KPI Achievement',
    helperText:
      'Classify users to segments based on the achievement of a KPI. E.g. users who have made a purchase in the last 30 days. <a href="#" data-beacon-article-sidebar="37">Learn more</a>',
  },
  [SegmentTabOption.FIRST_OR_LAST_EVENT_VALUE]: {
    key: SegmentTabOption.FIRST_OR_LAST_EVENT_VALUE,
    title: 'Event Property',
    helperText:
      'Classify users to segments based on the first or last value of an event property. E.g. the first value of the event property "purchase type" <a href="#" data-beacon-article-sidebar="37">Learn more</a>',
  },
};
