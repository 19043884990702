import * as React from 'react';
import {useContext} from 'react';
import {QueryBuilderContext} from '../query-builder.context';

export function withViewMode<P>(
  WrappedComponent: React.ComponentType<P>,
  ViewModeComponent?: React.ComponentType<P>
): React.ComponentType<P> {
  return props => {
    const {viewMode} = useContext(QueryBuilderContext);

    if (viewMode) {
      return <ViewModeComponent {...props} />;
    }

    return <WrappedComponent {...props} />;
  };
}
