import {createContext, useCallback, useMemo, useRef, useState} from 'react';
import {SmartDocument} from '../types';
import {
  DocumentComponentsMap,
  DocumentViewerMode,
  DocumentViewerParams,
} from '../document-viewer.types';
import * as React from 'react';
import {values} from 'lodash';
import {CommandType, DocumentCommand} from '../types';
import {DocumentViewerComponents} from '../document-viewer.config';
import {removeUndefinedKeys} from 'front-core';
import {DocumentCommandEmitterContextProvider} from './document-command-emitter.context';

export interface IDocumentViewerContext {
  viewerMode: DocumentViewerMode;
  parameters: DocumentViewerParams;
  globalParameters: any;
  onChangeParameters: (id: string, change: any) => void;
  docQuery?: any;
  setDocQuery?: (query: any) => void;
  componentsMap?: DocumentComponentsMap;
  darkMode?: boolean;
  showHidden?: boolean;
}

const DEFAULT_CONTEXT_VALUE: IDocumentViewerContext = {
  viewerMode: DocumentViewerMode.VIEW_MODE,
  parameters: {},
  globalParameters: undefined,
  onChangeParameters: undefined,
  componentsMap: {},
  docQuery: undefined,
  setDocQuery: undefined,
  darkMode: undefined,
  showHidden: undefined,
};

interface OwnProps {
  document: SmartDocument;
  parameters?: DocumentViewerParams;
  componentsMap?: DocumentComponentsMap;
  onParametersChange?: (params: DocumentViewerParams) => void;
  onViewerCommand?: (command: DocumentCommand<any>) => void;
  viewerMode?: DocumentViewerMode;
  docQuery?: any;
  setDocQuery?: (query: any) => void;
  darkMode?: boolean;
  showHidden?: boolean;
  children: any;
}

export const DocumentViewerContext = createContext<IDocumentViewerContext>(DEFAULT_CONTEXT_VALUE);

export const DocumentViewerContextProvider: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    document,
    viewerMode,
    parameters,
    onParametersChange,
    onViewerCommand,
    componentsMap,
    docQuery: docQueryFromProps,
    setDocQuery: setDocQueryFromProps,
    darkMode,
    showHidden,
    children,
  } = props;
  const docQueryRef = useRef<any>();
  const [localDocQuery, setLocalDocQuery] = useState({});
  const docQuery = docQueryFromProps ? docQueryFromProps : localDocQuery;
  // use ref to avoid setDocQuery re-evaluate for every query change
  docQueryRef.current = docQuery;
  const setDocQuery = useCallback(
    (query: any = {}) => {
      const newQuery = removeUndefinedKeys({
        ...docQueryRef.current,
        ...query,
      });
      // update docQueryRef (when called multiple times)
      docQueryRef.current = newQuery;
      if (setDocQueryFromProps) {
        return setDocQueryFromProps(newQuery);
      }
      setLocalDocQuery(newQuery);
    },
    [docQueryRef, setLocalDocQuery, setDocQueryFromProps]
  );
  const onChangeParameters = useCallback(
    (id: string, change: any) => {
      onParametersChange &&
        onParametersChange({
          ...parameters,
          [id]: {
            ...parameters[id],
            ...change,
          },
        });
    },
    [onParametersChange, parameters]
  );

  const contextValue: IDocumentViewerContext = useMemo(
    () => ({
      viewerMode,
      parameters,
      onChangeParameters,
      docQuery,
      setDocQuery,
      componentsMap: {...DocumentViewerComponents, ...(componentsMap || {})},
      globalParameters: document.globalParameters || {},
      darkMode,
      showHidden,
    }),
    [
      componentsMap,
      darkMode,
      showHidden,
      viewerMode,
      onChangeParameters,
      parameters,
      docQuery,
      setDocQuery,
      document.globalParameters,
    ]
  );

  return (
    <DocumentViewerContext.Provider value={contextValue}>
      <DocumentCommandEmitterContextProvider onEvent={onViewerCommand}>
        {children}
      </DocumentCommandEmitterContextProvider>
    </DocumentViewerContext.Provider>
  );
};

DocumentViewerContextProvider.defaultProps = DEFAULT_CONTEXT_VALUE;
