import {BaseImage} from './base-image.component';
import {ZoomListener} from './zoom-listener.component';
import {enhancedShape} from '../hoc/enhanced-shape.hoc';
import {
  Group,
  Line,
  Rect,
  Text,
  Shape,
  Circle,
  Layer,
  Ellipse,
  Wedge,
  RegularPolygon,
} from 'react-konva';

const EnhancedLayer = enhancedShape(Layer);
const EnhancedShape = enhancedShape(Shape);
const EnhancedLine = enhancedShape(Line);
const EnhancedGroup = enhancedShape(Group);
const EnhancedRect = enhancedShape(Rect);
const EnhancedText = enhancedShape(Text);
const EnhancedCircle = enhancedShape(Circle);
const EnhancedEllipse = enhancedShape(Ellipse);
const EnhancedWedge = enhancedShape(Wedge);
const EnhancedRegularPolygon = enhancedShape(RegularPolygon);

export {
  BaseImage,
  ZoomListener,
  EnhancedLayer,
  EnhancedShape,
  EnhancedLine,
  EnhancedGroup,
  EnhancedRect,
  EnhancedText,
  EnhancedCircle,
  EnhancedEllipse,
  EnhancedWedge,
  EnhancedRegularPolygon,
};
