import {RetentionTableData} from './retention-table.types';
import {every, get, range, sum} from 'lodash';
import {exists, safeDivision} from 'front-core';

export function calculateRetentionBucketsAverage<T extends RetentionTableData>(
  data: T[]
): number[] {
  const arr = [];
  const length = get(data, '0.values.length');
  if (!length) {
    return [];
  }
  const actualData = data.map(d => ({
    ...d,
    values: d.values.filter(v => v.isPartial === false && exists(v.value)),
  }));
  for (const i of range(length)) {
    if (every(actualData.map(d => d.values[i] === undefined))) {
      arr.push(null);
      continue;
    }
    const bucketSum = sum(actualData.map(d => d.values[i]?.value || 0));
    const total = sum(actualData.map(d => (exists(d.values[i]?.value) ? d.total : 0)));
    arr.push(safeDivision(bucketSum, total, true));
  }

  return arr;
}
