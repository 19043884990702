import {Backdrop, Modal} from '@material-ui/core';
import classes from './modal-wrapper.module.scss';
import {ModalLayout} from '../../../../../layouts/modal-layout/modal-layout.component';

export const ModalWrapper = ({isOpen, onClose, children, width, height}) => (
  <Modal
    className={classes.ModalStage}
    open={isOpen}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 250,
    }}
  >
    <ModalLayout style={{maxWidth: width, height}}>{children}</ModalLayout>
  </Modal>
);
