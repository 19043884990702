import * as React from 'react';
import {EnhancedGroup, EnhancedRect, EnhancedText} from '../../../../core/konva/components';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {useContext, useMemo} from 'react';
import {StageContext} from '../../../../core/konva/stage-context.component';
import {exists} from 'front-core';

interface OwnProps {
  percentage?: number;
  value?: number | string;
  onClick?: (e) => void;
}

type AllProps = ShapeProps & OwnProps;

export const ChartLabel: React.FC<AllProps> = (props: AllProps) => {
  const {percentage, value, onClick} = props;
  const context = useContext(StageContext);
  const {controller, style} = context;

  const percentageWidth = useMemo(
    () => (percentage ? controller.measureTextWidth(`${percentage}%`, style.fontName, 12) + 8 : 0),
    [controller, percentage, style.fontName]
  );

  return (
    <EnhancedGroup
      onClick={onClick}
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      center={props.center}
      centerX={props.centerX}
      centerY={props.centerY}
    >
      <EnhancedRect
        x={0}
        y={0}
        width={props.width}
        height={props.height}
        fill={'#ffffff'}
        cornerRadius={12}
        shadowColor={'rgba(218, 218, 218, 0.6)'}
        shadowBlur={8}
        shadowOffset={{x: 0, y: 2}}
      />
      {exists(percentage) && (
        <EnhancedText
          x={4}
          y={1}
          height={props.height}
          text={`${props.percentage}%`}
          fontFamily={style.fontName}
          padding={4}
          fill={'#03AC9A'}
          fontSize={12}
        />
      )}
      {exists(value) && (
        <EnhancedText
          x={percentageWidth + 4}
          y={1}
          width={percentageWidth === 0 ? props.width : undefined}
          height={props.height}
          text={`${value}`}
          fontFamily={style.fontName}
          padding={4}
          fill={'#293164'}
          fontSize={12}
          align={'center'}
        />
      )}
    </EnhancedGroup>
  );
};
