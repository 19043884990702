import {useMemo} from 'react';
import {
  KPISegmentationType,
  PowerUsersSegmentationFigureData,
  PowerUsersSegmentationFigureOptions,
} from '../../../../../../types';
import {KPISegmentationDataModeTable} from '../../../../kpi-analysis/kpi-segmentation-viewer/components/kpi-segmentation-tables/kpi-segmentation-data-mode-table.component';
import TransKeys from 'translations';
import {ExtendedPowerUsersSegmentationItem} from '../../power-users-segmentation-viewer.component';

interface OwnProps {
  figureId: string;
  data: PowerUsersSegmentationFigureData;
  options: PowerUsersSegmentationFigureOptions;
  items: ExtendedPowerUsersSegmentationItem[];
  totalEntities: number;
  onSeeAll: (segmentGroupName: string) => void;
  className?: string;
}

type AllProps = OwnProps;

export const PowerUsersSegmentationDataModeTable: React.FC<AllProps> = (props: AllProps) => {
  const {items, data, options, totalEntities, figureId, onSeeAll, className} = props;
  const tableData: any[] = useMemo(
    () =>
      items.map(i => ({
        ...i,
        groupInsignificant: i.isSignificant === false || i.isInformative === false,
        goalInSegment: i.valueInSegment / 100,
        goalInNonSegment: i.valueInNonSegment / 100,
        type: i.isSignificant ? KPISegmentationType.POSITIVE : KPISegmentationType.INSIGNIFICANT,
      })),
    [items]
  );

  return (
    <KPISegmentationDataModeTable
      className={className}
      figureId={figureId}
      options={{
        ...options,
        higherIsBetter: true,
        isPercentageValue: true,
      }}
      items={tableData}
      totalEntities={totalEntities}
      onSeeAll={onSeeAll}
      transKeysPrefix={TransKeys.DOCUMENT_VIEWER.POWER_USERS_SEGMENTATION_FIGURE.TABLE.HEADERS}
    />
  );
};

PowerUsersSegmentationDataModeTable.defaultProps = {};
