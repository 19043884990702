import * as React from 'react';
import {CSSProperties, useEffect, useMemo} from 'react';
import {ChartType, DocumentElementType, FigureElement, SwitchBlock} from '../../../types';
import classes from './switch-block-viewer.module.scss';
import {ChildRenderer} from '../../core/child-renderer.component';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {get, isArray, keyBy} from 'lodash';
import {
  BarChartIcon,
  ClockIcon,
  GraphIcon,
  ImageIcon,
  TableIcon,
  TextIcon,
} from '../../../../../simple/controls/icons/icons.component';
import classNames from 'classnames';
import {DOCUMENT_ICON_MAPPING} from '../../internal-viewers/document-icon.component';
import {useOptionsPlacer} from '../options-placer-viewer/options-placer-viewer.component';
import {SwitchActions} from '../../../../../simple/controls/switch-actions/switch-actions.component';
import {useDocumentTracking} from '../../../hooks/use-document-tracking.hook';
import {useDocQuery} from '../../../hooks/use-doc-query.hook';

export interface OwnProps extends SwitchBlock {
  style?: CSSProperties;
  className?: string;
}

const FALLBACK_ICON = CheckBoxOutlineBlankIcon;

const getIcon = (data: FigureElement) => {
  if (
    data.type === DocumentElementType.SELECT_BLOCK ||
    data.type === DocumentElementType.SWITCH_BLOCK
  ) {
    return getIcon(get(data, 'data.0.data.0'));
  }
  switch (data.type) {
    case DocumentElementType.CHART:
      if (data.chartType === ChartType.BAR) {
        return BarChartIcon;
      } else {
        return GraphIcon;
      }
    case DocumentElementType.IMAGE:
      return ImageIcon;
    case DocumentElementType.SMART_TABLE:
      return TableIcon;
    case DocumentElementType.MARKDOWN:
      return TextIcon;
    case DocumentElementType.TIME_TO_EVENT:
      return ClockIcon;
  }
  return FALLBACK_ICON;
};

const calcInitialSelectedViewKey = (data, options) =>
  data.find(d => d.key === options.default)?.key || data[0].key;

export const SwitchBlockViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {id, data = [], options, optionsPlacerOptions, title, style, className} = props;
  const {trackItemTabChanged} = useDocumentTracking(id, DocumentElementType.SWITCH_BLOCK);
  const defaultViewKey = useMemo(() => calcInitialSelectedViewKey(data, options), [data, options]);
  const {query: selectedKey, setQuery: setSelectedKey} = useDocQuery<string>(id, defaultViewKey);

  useEffect(() => {
    trackItemTabChanged(selectedKey);
  }, [trackItemTabChanged, selectedKey]);

  const dataMap = useMemo(() => keyBy(data, 'key'), [data]);
  const actions = useMemo(
    () =>
      data.map(d => {
        let icon;
        if (d.icon) {
          icon = DOCUMENT_ICON_MAPPING[d.icon];
        } else {
          icon = getIcon(isArray(d.data) ? d.data[0] : d.data);
        }
        if (d.hideIcon) {
          icon = null;
        }
        if (icon === FALLBACK_ICON && options.showLabels) {
          icon = null;
        }
        return {
          icon,
          label: d.label,
          onClick: () => setSelectedKey(d.key),
          isActive: d.key === selectedKey,
          helperText: d.helperText || '',
        };
      }),
    [data, selectedKey, options, setSelectedKey]
  );
  const optionPlacer = useMemo(
    () => [
      {
        elementId: props.id,
        renderer: () => <SwitchActions actions={actions} showActionsLabel={options.showLabels} />,
        align: optionsPlacerOptions?.align,
      },
    ],
    [props.id, actions, options.showLabels, optionsPlacerOptions]
  );
  const {isActive: isActiveOptionsPlacer} = useOptionsPlacer(optionPlacer);
  const selectedView = dataMap[selectedKey];
  // When props are changed we need to re-evaluate the selected key
  useEffect(() => {
    if (selectedKey === null || selectedView === undefined) {
      setSelectedKey(calcInitialSelectedViewKey(data, options));
    }
  }, [data, options, setSelectedKey, selectedKey, selectedView]);

  return (
    <div style={style} className={classNames(classes.SwitchBlockViewer, className)}>
      {(title || !isActiveOptionsPlacer) && (
        <div className={classes.TitleWrapper}>
          {title && <div className={classes.Title}>{title}</div>}
          {!isActiveOptionsPlacer && (
            <div className={classes.Actions}>
              <SwitchActions actions={actions} showActionsLabel={options.showLabels} />
            </div>
          )}
        </div>
      )}
      {selectedView?.data && <ChildRenderer children_={selectedView?.data} />}
    </div>
  );
};

SwitchBlockViewer.defaultProps = {
  children: [],
  options: {},
  data: [],
  title: '',
};
