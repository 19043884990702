import * as React from 'react';
import {RetentionTableFigure} from '../../../types';
import {RetentionTable} from '../../../../html-charts/retention-table/retention-table.component';

interface OwnProps extends RetentionTableFigure {}

type AllProps = OwnProps;

export const RetentionTableViewer: React.FC<AllProps> = (props: AllProps) => {
  const {title, data, options, className} = props;

  return (
    <RetentionTable
      title={title}
      data={data}
      labels={props.labels}
      options={options}
      className={className}
    />
  );
};

RetentionTableViewer.defaultProps = {
  options: {},
};
