import classNames from 'classnames';
import {AnalysisAlgoMode} from '../../../../types';
import classes from './switch-algo-mode.module.scss';
import {useMemo} from 'react';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {EyeIcon} from '../../../../../../simple/controls/icons/icons.component';
import {ActionsDropdown} from '../../../../../../simple/controls/actions-dropdown/actions-dropdown.component';
import {NO_CAUSAL_RESULTS_NOTICE_ELEMENT_ID} from '../../../viewers/drivers-analysis/treatment-adoption-simulation-viewer/treatment-adoption-simulation-mode-switch-wrapper-viewer/treatment-adoption-simulation-mode-switch-wrapper-viewer.component';

interface OwnProps {
  algoMode: AnalysisAlgoMode;
  onSwitchAlgoMode: (mode: AnalysisAlgoMode) => void;
  className?: string;
}

type AllProps = OwnProps;

export const SwitchAlgoMode: React.FC<AllProps> = (props: AllProps) => {
  const {algoMode, onSwitchAlgoMode, className} = props;
  const {t} = useDocumentTranslation();

  const modeTexts = useMemo(() => {
    return {
      [AnalysisAlgoMode.CAUSATION]: t(
        TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.SWITCH_MODE.CAUSAL
      ),
      [AnalysisAlgoMode.CORRELATION]: t(
        TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.SWITCH_MODE.CORREALTIVE
      ),
    };
  }, []);

  const modeOptions = useMemo(() => {
    return [
      {
        title: modeTexts[AnalysisAlgoMode.CAUSATION],
        onClick: () => onSwitchAlgoMode(AnalysisAlgoMode.CAUSATION),
      },
      {
        title: modeTexts[AnalysisAlgoMode.CORRELATION],
        onClick: () => onSwitchAlgoMode(AnalysisAlgoMode.CORRELATION),
      },
    ];
  }, [modeTexts, onSwitchAlgoMode]);

  return (
    <ActionsDropdown
      id={NO_CAUSAL_RESULTS_NOTICE_ELEMENT_ID}
      className={classNames(classes.SwitchAlgoMode, className)}
      actions={modeOptions}
      popoverDirection={'left'}
      label={
        <div className={classes.ViewModeText}>
          <EyeIcon className={classes.Icon} />
          <span className={classes.Label}>
            {t(TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.SWITCH_MODE_LABEL)}
          </span>
          <span className={classes.Value}>{modeTexts[algoMode]}</span>
        </div>
      }
    />
  );
};

SwitchAlgoMode.defaultProps = {};
