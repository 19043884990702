import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import classes from './retention-overview-simulator-mode.module.scss';
import {
  BucketPotentialLift,
  CommandType,
  DocumentElementType,
  FollowUpType,
  RetentionOverviewFigureItem,
  RetentionTimeUnit,
} from '../../../../../../types';
import {calculateRetentionBucketsAverage} from '../../../../../../../html-charts/retention-table/retention-table.utils';
import {generateRetentionLabels} from '../../retention-overview-viewer.utils';
import {useRetentionChart} from '../../hooks/use-retention-chart.hook';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {
  FlexibleTable,
  FlexibleTableColumn,
} from '../../../../../shared/general/flexible-table/flexible-table.component';
import {Select} from '../../../../../../../../forms/inputs/select/select.component';
import {exists, number2k, withStopPropagation} from 'front-core';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {SimulatorSlider} from '../../../../../shared/inputs/simulator-slider/simulator-slider.component';
import {GradientLabelDisplay} from '../../../../../shared/display-columns/gradient-label-display.component';
import {
  calculateMaxPotential,
  simulateSingleItem,
  SimulatorItem,
  SimulatorItemType,
} from '../../../../../../hooks/use-simulator.hook';
import {capitalize} from 'lodash';
import {
  PotentialRetentionDataset,
  MatrixTable,
} from '../../../../../shared/general/matrix-table/matrix-table.component';
import TransKeys from 'translations';
import {
  LoopsIcon,
  RocketLaunchLightIcon,
} from '../../../../../../../../simple/controls/icons/icons.component';
import {IconButton} from '../../../../../../../../simple/controls/icon-button/icon-button.component';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';

interface OwnProps {
  figureId: string;
  items: RetentionOverviewFigureItem[];
  potentialLifts: BucketPotentialLift[];
  stableValue: number;
  timeUnit: RetentionTimeUnit;
  timeUnitN: number;
  entity: string;
  bucket0?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const DEFAULT_CHANGE = 0.05;
const MIN_ALLOWED_CHANGE = 0;

const simulateSingleItem_ = (i: any, change: number, goalValue: number) =>
  simulateSingleItem(i, change, goalValue, MIN_ALLOWED_CHANGE, Infinity);

export const RetentionOverviewSimulatorMode: React.FC<AllProps> = (props: AllProps) => {
  const {items, potentialLifts, stableValue, timeUnit, timeUnitN, entity, bucket0, className} =
    props;
  const {t} = useDocumentTranslation();
  const {trackInput} = useDocumentTracking(
    props.figureId,
    DocumentElementType.RETENTION_TABLE_FIGURE
  );
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const prevBucket0 = useRef<boolean>(bucket0);
  // global slider picker value
  const [change, setChange] = useState(DEFAULT_CHANGE);
  // calculate bucket averages (by index)
  const bucketAverages = useMemo(
    () => calculateRetentionBucketsAverage(items).filter(i => exists(i)),
    [items]
  );
  // calculate each bucket max potential (by index)
  const bucketsStableMaxPotential = useMemo(() => {
    return potentialLifts.map(
      (bpl, index) =>
        calculateMaxPotential(stableValue, {
          key: index.toString(),
          value: bucketAverages[index],
          potentialLift: bpl.stablePotentialLift,
          potentialLiftUpper: bpl.stablePotentialLiftUpper,
          potentialLiftLower: bpl.stablePotentialLiftLower,
          insignificant: false,
          simulationType: SimulatorItemType.POSITIVE,
        }).maxPotential
    );
  }, [items, stableValue]);
  // calculate initial bucket index - the bucket index which has the max potential
  const initialBucketIndex = useMemo(() => {
    const options = bucketsStableMaxPotential
      .map((p, index) => ({p, index}))
      .sort((a, b) => b.p - a.p);
    return options[0].index;
  }, [bucketsStableMaxPotential]);
  // state holding the selected bucket index
  const [selectedBucketIndex, setSelectedBucketIndex] = useState(initialBucketIndex);
  // SimulatorItem for main table
  const mainSimulatedItem: SimulatorItem = useMemo(() => {
    const bucketPotentialLift = potentialLifts[selectedBucketIndex];
    return {
      key: selectedBucketIndex.toString(),
      value: bucketAverages[selectedBucketIndex],
      potentialLift: bucketPotentialLift.stablePotentialLift,
      potentialLiftUpper: bucketPotentialLift.stablePotentialLiftUpper,
      potentialLiftLower: bucketPotentialLift.stablePotentialLiftLower,
      insignificant: false,
      simulationType: SimulatorItemType.POSITIVE,
    };
  }, [bucketAverages, selectedBucketIndex]);
  // Create SimulatorItem for each bucket
  const simulatorBuckets: SimulatorItem[] = useMemo(() => {
    const selectedBucketPotentialLifts = potentialLifts[selectedBucketIndex];
    const selectedBucketValue = bucketAverages[selectedBucketIndex];
    return bucketAverages.map((bucketValue, bucketIndex) => ({
      key: bucketIndex.toString(),
      value: selectedBucketValue,
      potentialLift: selectedBucketPotentialLifts.bucketPotentialLift[bucketIndex],
      potentialLiftUpper: selectedBucketPotentialLifts.bucketPotentialLiftUpper[bucketIndex],
      potentialLiftLower: selectedBucketPotentialLifts.bucketPotentialLiftLower[bucketIndex],
      insignificant: false,
      simulationType: SimulatorItemType.POSITIVE,
    }));
  }, [bucketAverages, potentialLifts, selectedBucketIndex]);
  // Simulate main table item
  const simulationTableData = useMemo(
    () => [simulateSingleItem_(mainSimulatedItem, change, stableValue)],
    [mainSimulatedItem, change, stableValue]
  );
  // Simulate simulatorBuckets
  const simulatedBuckets = useMemo(() => {
    return simulatorBuckets.map((item, idx) =>
      simulateSingleItem(item, change, bucketAverages[idx])
    );
  }, [simulatorBuckets, change]);
  // Generate labels for bucket options
  const labels = useMemo(
    () => generateRetentionLabels(timeUnit, timeUnitN, bucketAverages.length, bucket0),
    [items, timeUnit, timeUnitN, bucket0, bucketAverages]
  );
  // Selection bucket items for main table, sort by most potential
  const bucketOptions = useMemo(() => {
    return labels
      .map((label, idx) => ({
        label: capitalize(label),
        value: idx,
      }))
      .sort((a, b) => bucketsStableMaxPotential[b.value] - bucketsStableMaxPotential[a.value])
      .map((o, idx) => ({
        ...o,
        label: idx === 0 ? `${o.label} (recommended)` : o.label,
      }));
  }, [labels, bucketsStableMaxPotential]);
  const onDragComplete = useCallback(
    value => trackInput('retention', {value, inputType: 'slider'}),
    [trackInput]
  );
  const onChangeBucketIndex = useCallback(
    (bucketIndex: number) => {
      if (change > simulatedBuckets[bucketIndex].maxChange) {
        setChange(simulatedBuckets[bucketIndex].maxChange);
      }
      setSelectedBucketIndex(bucketIndex);
    },
    [setSelectedBucketIndex, change, simulatedBuckets, setChange]
  );
  const selectedBucketName = useMemo(
    () => labels[selectedBucketIndex].toLowerCase(),
    [bucketOptions, selectedBucketIndex]
  );
  const disabledFollowUp = useMemo(
    () => bucket0 && selectedBucketIndex === 0,
    [bucket0, selectedBucketIndex]
  );
  const onFollowUp = useCallback(() => {
    emitEvent({
      type: CommandType.FOLLOW_UP,
      payload: {
        type: FollowUpType.RETENTION_ANALYSIS_BUCKET_INVESTIGATION,
        parameters: {
          bucket: bucket0 ? selectedBucketIndex : selectedBucketIndex + 1,
          numberOfBuckets: bucket0 ? bucketAverages.length - 1 : bucketAverages.length,
        },
      },
    });
  }, [emitEvent, selectedBucketIndex, bucket0, bucketAverages]);
  const simulationTableColumns: FlexibleTableColumn<any>[] = useMemo(
    () => [
      {
        key: 'bucket',
        title: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.BUCKET.LABEL,
          {bucket: selectedBucketName}
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.BUCKET
            .HELPER_TEXT,
          {bucket: selectedBucketName}
        ),
        weight: 2,
        sortable: false,
        render: i => (
          <Select
            key={bucket0.toString()}
            value={selectedBucketIndex}
            onChange={onChangeBucketIndex}
            options={{options: bucketOptions}}
            className={classes.Select}
            dropdownButtonClassName={classes.DropdownButton}
            selectionHelper={t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.SELECTION_HELPER
            )}
            capitalize={false}
            clearable={false}
            sortValues={false}
          />
        ),
      },
      {
        key: 'retention_rate',
        title: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.RETENTION_RATE
            .LABEL,
          {bucket: selectedBucketName}
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.RETENTION_RATE
            .HELPER_TEXT,
          {bucket: selectedBucketName}
        ),
        weight: 1,
        sortable: false,
        render: item => <div>{number2k(item.value * 100)}%</div>,
      },
      {
        key: 'change',
        title: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.CHANGE.LABEL,
          {bucket: selectedBucketName}
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.CHANGE
            .HELPER_TEXT,
          {bucket: selectedBucketName}
        ),
        weight: 2,
        sortable: false,
        render: item => {
          let allowedMax = item.maxChange;
          // if (selectedBucketIndex > 0) {
          //   allowedMax =
          //     (bucketAverages[selectedBucketIndex - 1] - bucketAverages[selectedBucketIndex]) /
          //     bucketAverages[selectedBucketIndex];
          // }
          return (
            <SimulatorSlider
              min={0}
              max={1000}
              displayValue={item.newValue * 100}
              value={item.change * 100}
              defaultValue={DEFAULT_CHANGE * 100}
              allowedMax={allowedMax * 100}
              onChange={v => setChange(v !== null ? v / 100 : null)}
              onChangeCommitted={v => onDragComplete(exists(v) ? v / 100 : null)}
              suffix={'%'}
              sign={'+'}
              disabled={item.maxChange === 0}
              allowedMaxHelper={t(
                TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SLIDER_MAX_HELPER
              )}
            />
          );
        },
      },
      {
        key: 'potential',
        title: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.POTENTIAL
            .LABEL,
          {bucket: selectedBucketName}
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_TABLE.HEADERS.POTENTIAL
            .HELPER_TEXT,
          {bucket: selectedBucketName}
        ),
        weight: 2,
        sortable: false,
        render: item => (
          <GradientLabelDisplay
            value={item.potential * 100}
            valueLower={item.potentialLower * 100}
            valueUpper={item.potentialUpper * 100}
            min={stableValue * 100}
            max={item.maxPotential * 100}
            tooltipLabel={t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CONFIDENCE_INTERVAL
            )}
            suffix={'%'}
            showDiff
          />
        ),
      },
      {
        key: 'actions',
        title: '',
        width: '6.6rem',
        render: item => (
          <IconButton
            tooltipText={t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_SIMULATION.ACTIONS[
                disabledFollowUp ? 'HOW_TO_IMPROVE_DISABLED' : 'HOW_TO_IMPROVE'
              ]
            )}
            disabled={disabledFollowUp}
            size={'large'}
            onClick={withStopPropagation(() => onFollowUp())}
            icon={RocketLaunchLightIcon}
          />
        ),
      },
    ],
    [
      selectedBucketIndex,
      bucketOptions,
      bucketAverages,
      onChangeBucketIndex,
      selectedBucketName,
      selectedBucketIndex,
      bucket0,
      t,
      disabledFollowUp,
      onFollowUp,
    ]
  );
  const chartBucketAverages = useMemo(() => bucketAverages.map(i => i * 100), [bucketAverages]);
  const simulatedBucketAverages = useMemo(
    () =>
      simulatedBuckets.map((i, idx) => {
        if (idx === selectedBucketIndex) {
          return i.newValue * 100;
        }
        return i.potential * 100;
      }),
    [simulatedBuckets, selectedBucketIndex]
  );
  const retentionChart = useRetentionChart({
    mainDataset: chartBucketAverages,
    projectedDataset: simulatedBucketAverages,
    timeUnit,
    timeUnitN,
    entity,
    bucket0,
  });
  const potentialRetentionDatasets: PotentialRetentionDataset[] = useMemo(
    () => [
      {
        key: 'avg',
        name: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.POTENTIAL_RETENTION_TABLE
            .AVG_VALUES_LABEL
        ),
        values: chartBucketAverages,
        mainValue: stableValue,
      },
      {
        key: 'simulated',
        name: t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.POTENTIAL_RETENTION_TABLE
            .PROJECTED_RETENTION_LABEL
        ),
        values: simulatedBucketAverages.map((i, idx) => (idx >= selectedBucketIndex ? i : null)),
        mainValue: simulationTableData[0].potential,
        highlight: true,
        icon: LoopsIcon,
      },
    ],
    [
      chartBucketAverages,
      simulatedBucketAverages,
      stableValue,
      simulationTableData[0].potential,
      selectedBucketIndex,
    ]
  );

  // Sync selected bucket index on filter bucket 0
  useEffect(() => {
    if (bucket0 === prevBucket0.current) {
      return;
    }
    if (prevBucket0.current === true) {
      setSelectedBucketIndex(b => Math.max(b - 1, 0));
    } else {
      setSelectedBucketIndex(b => b + 1);
    }
    prevBucket0.current = bucket0;
  }, [bucket0, prevBucket0, setSelectedBucketIndex]);
  useEffect(() => {
    if (change > simulatedBuckets[0].maxChange) {
      setChange(simulatedBuckets[0].maxChange);
    }
  }, [simulationTableData[0]]);

  return (
    <div className={classNames(classes.RetentionOverviewSimulatorMode, className)}>
      <FlexibleTable
        className={classes.Table}
        columns={simulationTableColumns}
        data={simulationTableData}
      />
      <ChildRenderer children_={retentionChart} className={classes.Chart} />
      <MatrixTable
        title={t(
          TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.POTENTIAL_RETENTION_TABLE.TITLE
        )}
        datasets={potentialRetentionDatasets}
        labels={labels}
      />
    </div>
  );
};

RetentionOverviewSimulatorMode.defaultProps = {
  bucket0: true,
};
