export enum DocumentIconType {
  LOOPS = 'LOOPS',
  SEGMENT = 'SEGMENT',
  ERROR = 'ERROR',
  CLOCK = 'CLOCK',
  EXPERIMENT = 'EXPERIMENT',
  KPI = 'KPI',
  FEATURE = 'FEATURE',
  FUNNEL = 'FUNNEL',
  CONTENT = 'CONTENT',
  EVENT_GROUP = 'EVENT_GROUP',
  MILESTONE = 'MILESTONE',
  EVENT = 'EVENT',
  MAGIC_NUMBER = 'MAGIC_NUMBER',
  ANALYSIS = 'ANALYSIS',
  DATABASE = 'DATABASE',
  CHART_USER = 'CHART_USER',
  BAR_CHART = 'BAR_CHART',
  CHART = 'CHART',
  TABLE = 'TABLE',
  IMAGE = 'IMAGE',
  CALENDAR = 'CALENDAR',
  REPEAT = 'REPEAT',
  STAR = 'STAR',
  FACE_SMILE_HEARTS = 'FACE_SMILE_HEARTS',
}
