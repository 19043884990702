import {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {debounce, keyBy, takeRight} from 'lodash';
import {DocumentElementType, TopLevelSelectBlock} from '../../../types';
import {ChildRenderer} from '../../core/child-renderer.component';
import classNames from 'classnames';
import classes from './top-level-select-block-viewer.module.scss';
import {useDocumentTracking} from '../../../hooks/use-document-tracking.hook';
import {useDocQuery} from '../../../hooks/use-doc-query.hook';
import {sharedViewerClasses} from '../../viewers';
import {DocumentIcon} from '../../internal-viewers/document-icon.component';
import {Select} from '../../../../../forms/inputs/select/select.component';

export interface OwnProps extends TopLevelSelectBlock {
  className?: string;
  style?: CSSProperties;
}

const MIN_COUNT_FOR_SELECT_SEARCH = 6;

const calcInitialSelectedViewKey = (data, options) =>
  data.find(d => d.key === options.default)?.key || data[0]?.key;

const handleResize = debounce(
  (tabsElem, cb) => {
    if (tabsElem) {
      const buttons = tabsElem.getElementsByClassName(classes.TabButton);
      let visibleItems = 0;
      let visibleWidth = 0;
      for (let b of buttons) {
        visibleWidth += b.offsetWidth;
        if (visibleWidth <= tabsElem.offsetWidth) {
          visibleItems++;
        }
      }
      cb(visibleItems);
    }
  },
  50,
  {leading: true}
);

export const TopLevelSelectBlockViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {id, data = [], options, className} = props;
  const tabsRef = useRef(null);
  const [visibleTabsCount, setVisibleTabsCount] = useState(data.length);
  const {trackItemTabChanged} = useDocumentTracking(id, DocumentElementType.TOP_LEVEL_SELECT_BLOCK);
  const defaultViewKey = useMemo(() => calcInitialSelectedViewKey(data, options), [data, options]);
  const {query: selectedKey, setQuery: setSelectedKey_} = useDocQuery<string>(id, defaultViewKey);

  const tabs = useMemo(
    () =>
      data.map(d => ({
        label: d.label,
        key: d.key,
        render: () => <ChildRenderer children_={d.data} />,
      })),
    [data]
  );

  const selectedItem = useMemo(() => keyBy(tabs, 'key')[selectedKey], [tabs, selectedKey]);

  const moreTabs = useMemo(
    () =>
      takeRight(tabs, tabs.length - visibleTabsCount).map(s => ({
        value: s.key,
        label: s.label as string,
      })),
    [tabs, visibleTabsCount]
  );

  const moreSelected = useMemo(
    () => moreTabs.findIndex(v => v.value === selectedKey) > -1,
    [moreTabs, selectedKey]
  );

  const setSelectedKey = useCallback(
    v => {
      setSelectedKey_(v);
      trackItemTabChanged(v);
      handleResize(tabsRef?.current, setVisibleTabsCount);
    },
    [setSelectedKey_, handleResize, trackItemTabChanged, tabsRef, setVisibleTabsCount]
  );

  useEffect(() => {
    const resizeListener = () => handleResize(tabsRef?.current, setVisibleTabsCount);
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [setVisibleTabsCount]);

  return (
    <div
      className={classNames(
        classes.TopLevelSelectBlockViewer,
        sharedViewerClasses.FullViewer,
        className
      )}
    >
      <div className={classes.TabsSelector}>
        <div className={classes.SelectOptions}>
          <div className={classes.SelectLabel}>
            {options.icon && <DocumentIcon className={classes.Icon} icon={options.icon} />}
            <span className={classes.Label}>{options.selectLabel}</span>
          </div>
          <div className={classes.Tabs} ref={tabsRef}>
            {tabs.map((t, idx) => (
              <div
                key={t.key}
                onClick={() => setSelectedKey(t.key)}
                className={classNames(
                  classes.TabButton,
                  t.key === selectedKey && classes.Selected,
                  idx > visibleTabsCount - 1 && classes.Hidden
                )}
              >
                {t.label}
              </div>
            ))}
          </div>
          {moreTabs.length > 0 && (
            <div className={classNames(classes.TabButton, moreSelected && classes.Selected)}>
              <Select
                key={`${selectedKey}${moreSelected}`}
                value={selectedKey}
                placeholder={`${tabs.length - visibleTabsCount} More`}
                onChange={v => setSelectedKey(v as string)}
                options={{
                  options: moreTabs,
                }}
                className={classNames(classes.SelectItem)}
                clearable={false}
                searchable={moreTabs.length > MIN_COUNT_FOR_SELECT_SEARCH}
                dropdownButtonClassName={classNames(classes.More, moreSelected && classes.Selected)}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.TabContent}>{selectedItem && selectedItem.render()}</div>
    </div>
  );
};

TopLevelSelectBlockViewer.defaultProps = {
  options: {},
  data: [],
};
