export enum DocumentTrackingType {
  NAVIGATION = 'navigation',
  SCROLL = 'scroll',
  ITEM_VIEWED = 'item_viewed',
  ITEM_TOGGLED = 'item_toggled',
  ITEM_TAB_CHANGED = 'item_tab_changed',
  SORT = 'sort',
  FILTER = 'filter',
  EXPORT = 'export',
  PAGINATION = 'pagination',
  INPUT = 'input',
  MODE_CHANGED = 'mode_changed',
  ITEM_CLICKED = 'item_clicked',
  MODAL_OPENED = 'modal_opened',
}

// Navigation tracking
export type TrackingNavigationValue = string; // target

// Scroll tracking
export type TrackingScrollValue = number; // scroll top

// Item viewed tracking
export type TrackingItemViewedValue = number; // title

// Item toggled tracking
export type TrackingItemToggledValue = boolean; // isOpen
export interface TrackingItemToggledInfo {
  title?: string;
}

// Item tab changed tracking
export type TrackingItemTabChangedValue = string; // target

// Sort tracking
export type TrackingSortValue = string; // column name
export interface TrackingSortInfo {
  order?: 'asc' | 'desc';
}

// Filter tracking
export type TrackingFilterValue = string; // data key
export interface TrackingFilterInfo {
  filter?: any;
}

// Export tracking - no parameters

// Pagination tracking
export type TrackingPaginationValue = number; // page
export interface TrackingPaginationInfo {
  perPage?: number;
}

// Mode changed tracking
export type TrackingModeChangedValue = string; // the mode

// Input tacking
export type TrackingInputValue = string; // input name
export interface TrackingInputInfo {
  inputType?: string;
  value?: any;
}

export type TrackingItemClickedValue = string; // target
export interface TrackingItemClickedInfo {
  name?: string;
  type?: string;
}

export type TrackingModalOpenedValue = string; // modal name
export interface TrackingModalOpenedInfo {
  name?: string;
}

export type DocumentTracker<V, I = undefined> = (value: V, info?: I) => void;
