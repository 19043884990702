import React, {useState, useCallback, ReactNode} from 'react';
import classes from './fancy-collapsible-container.module.scss';
import {Button} from '../../../../../../simple/controls/button/button.component';
import classNames from 'classnames';
import {
  ChevronRightRegularIcon,
  ChevronUpRegularIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';

interface FancyCollapsibleContainerProps {
  children: React.ReactNode;
  title: ReactNode;
  defaultOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

export const FancyCollapsibleContainer: React.FC<FancyCollapsibleContainerProps> = props => {
  const {children, defaultOpen = false, title, onToggle} = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const {t} = useDocumentTranslation();

  const handleToggle = useCallback(() => {
    setIsOpen(state => {
      onToggle?.(!state);
      return !state;
    });
  }, [onToggle]);

  return (
    <div className={classNames(classes.Container, isOpen && classes.IsOpen)}>
      <div className={classes.Header}>
        <div className={classes.Title}>{title}</div>
        <Button
          onClick={handleToggle}
          endIcon={isOpen ? ChevronUpRegularIcon : ChevronRightRegularIcon}
          className={classNames(classes.ToggleButton, isOpen && classes.IsOpen)}
        >
          {isOpen ? t(TransKeys.GENERAL.ACTIONS.CLOSE) : t(TransKeys.GENERAL.ACTIONS.OPEN_DETAILS)}
        </Button>
      </div>
      {<div className={classNames(classes.Content)}>{children}</div>}
    </div>
  );
};

export default FancyCollapsibleContainer;
