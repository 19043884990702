import * as React from 'react';
import classNames from 'classnames';
import {DefaultColumnOptions} from '../../../../../types';
import {useContext, useMemo} from 'react';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists, withStopPropagation} from 'front-core';
import classes from './data-columns.module.scss';
import {getDataValueKey} from '../../smart-table.utils';
import {get} from 'lodash';
import {DataColumnProps} from '../../smart-table.types';
import {TooltipIfOverflow} from '../../../../../../../simple/generic/tooltips/tooltips.component';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';

interface OwnProps extends DataColumnProps<DefaultColumnOptions> {}

type AllProps = OwnProps;

const DefaultDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, dataColumnOptions, className} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {commandDataKey} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);

  const value = useMemo(() => data[valueDataKey], [valueDataKey, data]);
  const unit = useMemo(
    () => dataColumnOptions?.unit || column.options.unit,
    [column.options.unit, dataColumnOptions]
  );
  const command = useMemo(
    () => (commandDataKey ? get(data, commandDataKey) : undefined),
    [data, commandDataKey]
  );
  const valueText = `${value?.toString()}${unit ? unit : ''}`;

  return (
    <div className={classNames(classes.DefaultDataColumn, className)}>
      <div className={classNames(classes.Value, command && classes.Clickable)}>
        <TooltipIfOverflow title={valueText}>
          <span onClick={command ? withStopPropagation(() => emitEvent(command)) : undefined}>
            {valueText}
          </span>
        </TooltipIfOverflow>
      </div>
    </div>
  );
};

const protector: Protector = (props: OwnProps) =>
  exists(props.data[getDataValueKey(props.column, 'valueDataKey')]);
export const DefaultDataColumn = withDataColumnProtector(protector)(DefaultDataColumnComponent);
