import * as React from 'react';
import classes from '../viewers/viewer.module.scss';
import {ChildRenderer} from '../core/child-renderer.component';
import {DocumentElementType, SmartDocument} from '../../types';
import classNames from 'classnames';
import {ChildRendererInjectedProps} from '../../document-viewer.types';
import {
  TableOfContent,
  TableOfContentItem,
} from '../shared/general/table-of-content/table-of-content.component';
import {useContext, useMemo} from 'react';
import {DocumentViewerContext} from '../../contexts/document-viewer.context';

export interface OwnProps extends SmartDocument, Partial<ChildRendererInjectedProps> {
  className?: string;
}

export const RootDocumentViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {options, children, className} = props;
  const {darkMode} = useContext(DocumentViewerContext);

  // DEPRECATED
  const showTableOfContent = options?.showTableOfContent || false;
  const tableOfContent: TableOfContentItem[] = useMemo(
    () =>
      (children || [])
        .filter(element => element.type === DocumentElementType.SECTION)
        .map((s: any) => ({
          title: s.options?.tableOfContentTitle || s.title,
          slug: s.slug,
          status: s.options?.status,
          icon: s.options?.icon,
        })),
    [children]
  );

  return (
    <div className={classNames(classes.DocumentViewer, darkMode && classes.DarkMode, className)}>
      <div className={classes.Body}>
        {showTableOfContent && (
          <div className={classes.SideColumn}>
            <TableOfContent title={options.tableOfContentTitle} items={tableOfContent} />
          </div>
        )}
        <div
          className={classNames(classes.MainColumn, showTableOfContent && classes.HasSideColumn)}
        >
          <div className={classes.DocumentChildrenWrapper}>
            <ChildRenderer key={'body'} children_={children} />
          </div>
        </div>
      </div>
    </div>
  );
};

RootDocumentViewer.defaultProps = {};
