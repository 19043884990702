import * as React from 'react';
import classNames from 'classnames';
import {TrendColumnOptions} from '../../../../../types';
import {useMemo} from 'react';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {exists, toFixedNumber} from 'front-core';
import classes from './data-columns.module.scss';
import {getDataValueKey} from '../../smart-table.utils';
import {ColorUtil} from '../../../../../document-viewer.utils';
import {ArrowRightIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {DataColumnProps} from '../../smart-table.types';
import {colorAlphaTransformer} from '../../../../../../../../utils/colors';

interface OwnProps extends DataColumnProps<TrendColumnOptions> {}

type AllProps = OwnProps;

const GOOD_COLOR = colorAlphaTransformer(ColorUtil.getColor('good'), 0.4);
const BAD_COLOR = colorAlphaTransformer(ColorUtil.getColor('bad'), 0.4);

// TODO: some duplicate code around chip generation from versus-data-column
const TrendDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, dataColumnOptions, className} = props;
  const {previousDataKey} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);

  const value = useMemo(() => data[valueDataKey], [valueDataKey, data]);
  const previous = useMemo(() => data[previousDataKey], [previousDataKey, data]);
  const unit = useMemo(
    () => dataColumnOptions?.unit || column.options.unit || '',
    [column.options, dataColumnOptions]
  );
  const chip = useMemo(() => {
    const {mode: mode_, higherIsBetter} = column.typeOptions;
    let mode = mode_;
    if (!exists(previous) || !exists(mode)) {
      return null;
    }

    let number;
    let background;
    let unit;
    switch (mode) {
      case 'change':
        if (previous === 0) {
          number = null;
        } else {
          number = toFixedNumber(((value - previous) / Math.abs(previous)) * 100, 2);
        }
        unit = '%';
        break;
      case 'difference':
        number = toFixedNumber(value - previous, 2);
        unit = column.options.unit;
        break;
    }
    if (exists(higherIsBetter)) {
      let localBadColor = BAD_COLOR;
      let localGoodColor = GOOD_COLOR;

      if (higherIsBetter === false) {
        localBadColor = GOOD_COLOR;
        localGoodColor = BAD_COLOR;
      }
      if (number > 0) {
        background = localGoodColor;
      }
      if (number < 0) {
        background = localBadColor;
      }
    }
    return {
      number: Math.abs(number),
      background,
      unit,
      sign: number >= 0 ? '+' : '-',
    };
  }, [column.typeOptions, value, previous, column.options]);

  return (
    <div className={classNames(classes.TrendDataColumn, className)}>
      {exists(previous) && (
        <span className={classes.Previous}>
          {previous.toLocaleString()}
          {unit}
        </span>
      )}
      {exists(previous) && (
        <div className={classNames(classes.Separator, classes.Arrow)}>
          <ArrowRightIcon className={classes.Icon} />
        </div>
      )}
      <span className={classes.Value}>
        {value?.toString()}
        {unit}
      </span>
      <span className={classes.Spacer} />
      {chip && (
        <div style={{backgroundColor: chip.background}} className={classes.Chip}>
          {chip.sign}
          {chip.number}
          {chip.unit}
        </div>
      )}
    </div>
  );
};
const protector: Protector = (props: OwnProps) =>
  exists(props.data[getDataValueKey(props.column, 'valueDataKey')]);
export const TrendDataColumn = withDataColumnProtector(protector)(TrendDataColumnComponent);
