import {useTranslation} from 'react-i18next';
import {ImagesResource} from '../../../../../../assets/images';
import {
  HomepageSubscription,
  HomepageSubscriptionVIA,
} from '../../../../../../objects/models/homepage.model';
import TransKeys from '../../../../../../constants/translation-keys';
import {getHomepageSubscriptionCounts} from '../../homepage-summary.utils';
import {useEffect, useMemo} from 'react';
import {EnvelopeLightIcon, SlackIcon, useRemoteSourceStated} from 'ui-components';
import {useCurrentUser} from '../../../../../../core/hooks/use-user.hook';
import classes from './homepage-subscription-created-success-screen.module.scss';
import {getSlackChannelsNetworkRequest} from '../../../../../../http/slack-channels.network-requests';
import {intersection} from 'lodash';

type HomepageSubscriptionCreatedSuccessScreenProps = {
  subscription: HomepageSubscription;
  onEditSubscription: VoidFunction;
  action: 'created' | 'edited';
};
export const HomepageSubscriptionCreatedSuccessScreen = (
  props: HomepageSubscriptionCreatedSuccessScreenProps
) => {
  const {subscription, onEditSubscription, action} = props;
  const {t} = useTranslation();
  const currentUser = useCurrentUser();

  const {exec: getSlackChannels, source: channels} = useRemoteSourceStated({
    networkRequest: getSlackChannelsNetworkRequest,
  });

  useEffect(() => {
    getSlackChannels();
  }, [getSlackChannels]);

  const {dailyCounts, weeklyCounts} = useMemo(() => {
    const {weeklyMetricsCount, weeklyFunnelsCount, dailyFunnelsCount, dailyMetricsCount} =
      getHomepageSubscriptionCounts(subscription);
    return {
      dailyCounts: dailyMetricsCount + dailyFunnelsCount,
      weeklyCounts: weeklyMetricsCount + weeklyFunnelsCount,
    };
  }, [subscription]);

  const description = useMemo(() => {
    const textsArr = [];
    if (weeklyCounts > 0) {
      textsArr.push(`weekly updates`);
    }

    if (dailyCounts) {
      textsArr.push(`drop alerts`);
    }

    const updatesText = textsArr.join(' and ');
    const homepageItemCount = Math.max(weeklyCounts, dailyCounts);
    return t(TransKeys.HOMEPAGE_SUBSCRIPTION_SUCCESS_SCREEN.DESCRIPTION, {
      updatesText,
      kpiCount: homepageItemCount,
    });
  }, [t, weeklyCounts, dailyCounts]);

  const slackChannels = useMemo(() => {
    if (!channels) {
      return '';
    }
    return intersection(
      channels.map(c => c.id),
      subscription.slackChannels
    )
      .map(id => channels.find(c => c.id === id).name)
      .map(c => `#${c}`)
      .join(', ');
  }, [channels, subscription]);

  return (
    <div className={classes.HomepageSubscriptionCreatedSuccessScreen}>
      <div className={classes.Content}>
        <div className={classes.Header}>
          <div>
            <img src={ImagesResource.app.subscriptionSuccess} />
          </div>
          <div className={classes.Title}>
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_SUCCESS_SCREEN.TITLE, {action})}
          </div>
        </div>
        <div className={classes.Description}>{description}</div>
        <div className={classes.Via}>
          {subscription.via.includes(HomepageSubscriptionVIA.SLACK) && (
            <div>
              <SlackIcon /> {slackChannels}
            </div>
          )}
          {subscription.via.includes(HomepageSubscriptionVIA.MAIL) && (
            <div>
              <EnvelopeLightIcon /> {currentUser?.email}
            </div>
          )}
        </div>
      </div>

      <div className={classes.Footer}>
        <div onClick={onEditSubscription} className={classes.EditButton}>
          {t(TransKeys.GENERAL.ACTIONS.EDIT)}
        </div>
        <div className={classes.Message}>
          {t(TransKeys.HOMEPAGE_SUBSCRIPTION_SUCCESS_SCREEN.FOOTER)}
        </div>
      </div>
    </div>
  );
};
