import * as React from 'react';
import classes from './kpi-loops.module.scss';
import classNames from 'classnames';
import {KPIElement, KPIElementDirection} from '../../../../../types/growth-map.types';
import {DirectionArrow} from '../../generic-components/direction-arrow/direction-arrow.component';
import {useCallback, useMemo, useState} from 'react';
import {flatten} from 'lodash';
import {KPIInfo} from '../kpi-info/kpi-info.component';

interface OwnProps {
  members: KPIElement[][];
  className?: string;
  model: string;
  isExpanded: boolean;
  yOffset?: number;
  direction?: KPIElementDirection;
  onKPIClicked?: (kpi: KPIElement) => void;
}

type AllProps = OwnProps;

export const KPILoops: React.FC<AllProps> = (props: AllProps) => {
  const {members, yOffset, className, direction, onKPIClicked, model, isExpanded} = props;
  const toTop = useMemo(() => direction === 'to-top', [direction]);
  const [openStates, setOpenStates] = useState(flatten(members).map(l => false));

  const onExpand = useCallback(
    (idx: number) => {
      const newState = [...openStates];
      newState[idx] = !newState[idx];
      setOpenStates(newState);
    },
    [openStates, setOpenStates]
  );

  const renderKpis = (kpis: KPIElement[], idx: number) => {
    if (kpis.length > 1) {
      return (
        <div key={idx} className={classes.Loop}>
          <DirectionArrow className={classes.LoopArrow} />
          <KPIInfo
            withArrow={false}
            className={classNames(classes.LabelWrapper, classes.Upper)}
            kpi={kpis[0]}
            isExpanded={openStates[idx * 2] || isExpanded}
            onExpand={() => onExpand(idx * 2)}
            onKPIClicked={() => onKPIClicked(kpis[0])}
            model={model}
          />
          <KPIInfo
            withArrow={false}
            className={classNames(classes.LabelWrapper, classes.Lower)}
            kpi={kpis[1]}
            isExpanded={openStates[idx * 2 + 1] || isExpanded}
            onExpand={() => onExpand(idx * 2 + 1)}
            onKPIClicked={() => onKPIClicked(kpis[1])}
            model={model}
          />
        </div>
      );
    }
    return (
      <div key={idx} className={classes.Solo}>
        <KPIInfo
          withArrow={false}
          className={classes.LabelWrapper}
          kpi={kpis[0]}
          isExpanded={openStates[idx * 2] || isExpanded}
          onExpand={() => onExpand(idx * 2)}
          onKPIClicked={() => onKPIClicked(kpis[0])}
          model={model}
        />
      </div>
    );
  };

  if (members.length === 0) {
    return null;
  }

  return (
    <div className={classNames(classes.KPILoops, toTop && classes.ToTop, className)}>
      <div className={classes.Shape} />
      <DirectionArrow
        style={{
          top: toTop ? 'unset' : `${yOffset}px`,
          bottom: toTop ? `${yOffset}px` : 'unset',
        }}
        className={classes.FrontArrow}
      />
      <div className={classes.Loops}>
        {direction === 'to-bottom' && <div style={{height: yOffset + 20}} />}
        {members.map((kpi, idx) => renderKpis(kpi, idx))}
        {toTop && <div style={{height: yOffset + 20}} />}
      </div>
    </div>
  );
};

KPILoops.defaultProps = {
  members: [],
  yOffset: 0,
  direction: 'to-bottom',
};
