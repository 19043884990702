import {useMemo} from 'react';
import {ChildRenderer} from '../../../core/child-renderer.component';
import {ExperimentContextFigure} from '../../../../types';
import {ExperimentContextProvider} from '../../../../contexts/experiment.context';
import {keyBy} from 'lodash';
import {CONTROL_COLOR, VARIANT_COLORS} from './experiment-context-viewer.consts';

interface OwnProps extends ExperimentContextFigure {
  className?: string;
}

type AllProps = OwnProps;

export const ExperimentContextViewer: React.FC<OwnProps> = (props: AllProps) => {
  const {data, children} = props;
  const {status, metrics, variants, winnerVariantKey} = data;

  const metricMap = useMemo(() => keyBy(metrics, 'signalId'), [metrics]);
  const variantMap = useMemo(
    () =>
      Object.fromEntries(
        variants.map((x, index) => {
          const color = x.color || (x.isControl ? CONTROL_COLOR : VARIANT_COLORS[index - 1]);
          return [x.key, {...x, color}];
        })
      ),
    [variants]
  );

  return (
    <ExperimentContextProvider
      status={status}
      metrics={metricMap}
      variants={variantMap}
      winnerVariantKey={winnerVariantKey}
    >
      <ChildRenderer children_={children} />
    </ExperimentContextProvider>
  );
};

ExperimentContextViewer.defaultProps = {};
