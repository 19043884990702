import {EnhancedLine, EnhancedRegularPolygon} from '../../../../core/konva/components';
import * as React from 'react';

interface OwnProps {
  x: number;
  y: number;
  color: string;
  direction?: 'down' | 'up';
}

export const CHEVRON_WIDTH = 12;

type AllProps = OwnProps;

export const ChartChevron: React.FC<AllProps> = (props: AllProps) => {
  const {x, y, color, direction} = props;

  return (
    <EnhancedRegularPolygon
      x={x}
      y={y}
      fill={color}
      sides={3}
      radius={CHEVRON_WIDTH / 2}
      rotation={direction === 'down' ? 180 : 0}
    />
  );
};

ChartChevron.defaultProps = {
  direction: 'down',
};
