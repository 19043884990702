import * as React from 'react';
import {EnhancedLine} from '../../../../core/konva/components';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {useContext, useMemo} from 'react';
import {StageContext} from '../../../../core/konva/stage-context.component';
import {colorAlphaTransformer} from '../../../../utils/colors';

interface OwnProps {
  points: number[];
  color: string;
  width: number;
  highlight?: boolean;
  fill?: string;
}

type AllProps = ShapeProps & OwnProps;

export const Polygon: React.FC<AllProps> = (props: AllProps) => {
  const {points, color, width, highlight, onClick, tooltipData, fill} = props;

  const context = useContext(StageContext);
  const {style} = context;
  const fillLinearGradient = useMemo(
    () => ({
      fillLinearGradientStartPoint: {x: 0, y: 0},
      fillLinearGradientEndPoint: {x: width, y: 100},
      fillLinearGradientColorStops: [
        0,
        colorAlphaTransformer(color, 0.4),
        0.25,
        colorAlphaTransformer(color, 0.6),
        1,
        colorAlphaTransformer(color, 0.7),
      ],
    }),
    [color, width]
  );

  return (
    <EnhancedLine
      {...props}
      tooltipEnabled={Boolean(tooltipData)}
      tooltipData={tooltipData}
      onClick={onClick}
      points={points}
      closed={true}
      stroke={highlight ? style?.highlightColor : 'transparent'}
      strokeWidth={3}
      strokeEnabled={true}
      fill={fill}
      {...fillLinearGradient}
    />
  );
};
