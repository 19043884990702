import * as React from 'react';
import {EnhancedGroup, EnhancedLine, EnhancedRect} from '../../../../../core/konva/components';
import {KPIElement, KPIElementDirection} from '../../../../../types/growth-map.types';
import {CSSProperties, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Html} from '../../../../../core/konva/hoc/html.hoc';
import {MergeKPIs} from '../merge-kpis/merge-kpis.component';
import {uniqueId, sum} from 'lodash';
import {Group} from 'react-konva';
import {Transform} from 'konva/types/Util';
import {TotalCount} from '../../generic-components/total-count/total-count.component';
import {DirectionArrow} from '../../generic-components/direction-arrow/direction-arrow.component';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import pluralize from 'pluralize';
import {colorAlphaTransformer} from '../../../../../utils/colors';

interface OwnProps {
  x?: number;
  y?: number;
  title?: string;
  model: string;
  members: KPIElement[];
  onClick?: (e, target?: any) => void;
  color?: string;
  direction?: KPIElementDirection;
  isExpanded?: boolean;
  round?: number;
  visibleCount?: number;
  htmlStyle?: CSSProperties;
  style?: CSSProperties;
  onKPIClicked?: (kpi: KPIElement) => void;
}

type AllProps = OwnProps;

const LINE_STROKE = 4;
const LINE_DELTA_X = 140;
const LINE_DELTA_Y = 220;
const MERGE_KPI_X_OFFSET = 100;
const MERGE_KPI_DOT_CLASS_NAME = 'source-dot';

export const Sources: React.FC<AllProps> = (props: AllProps) => {
  const {
    x,
    y,
    members,
    onClick,
    color,
    direction,
    visibleCount,
    htmlStyle,
    model,
    round,
    onKPIClicked,
    isExpanded,
  } = props;
  // Refs
  const {controller} = useContext(StageContext);
  const dotsGroupRef = useRef(null);
  const mergeKpiRef = useRef<HTMLDivElement>(null);
  const mergeKpiId = useMemo(() => uniqueId('kpi-sources'), []);
  const modelPlural = useMemo(() => (model ? pluralize(model) : ''), [model]);
  // Sum all kpi's member values
  const total = useMemo(
    () => Number(sum(members.map(m => m.value || 0)).toFixed(round)),
    [members, round]
  );
  // The html component size [width, height] (MergeKPIComponent)
  const [htmlComponentSize, setHtmlComponentSize] = useState([0, 0]);
  // Set to true to show all KPI's
  const [seeAll, setSeeAll] = useState(false);
  // Where are the html component (MergeKPIComponent) dots are - calculated in useEffect
  const [dotsPositions, setDotsPositions] = useState<number[][]>([]);
  // The y offset from initial Y
  const yOffset = useMemo(
    () => y + LINE_DELTA_Y * (direction === 'to-top' ? -1 : 1),
    [y, direction]
  );
  const mergeKPIEndingX = useMemo(
    () => mergeKpiRef.current?.clientWidth / 3 || 0,
    [mergeKpiRef.current]
  );
  // Generating the line positions
  const linePositions = useMemo(() => {
    const points = [x, y];
    points.push(x, y + yOffset / 2);
    points.push(x + LINE_DELTA_X + mergeKPIEndingX, y + yOffset / 2);
    points.push(x + LINE_DELTA_X + mergeKPIEndingX, y + yOffset);
    points.push(0, y + yOffset);
    return points;
  }, [x, y, yOffset, mergeKPIEndingX]);
  // Calculating for each dot position its correlated line (The fork shape lines)
  const mergeDotsLines = useMemo(
    () =>
      dotsPositions.map(p => [
        p[0] + 10,
        p[1],
        htmlComponentSize[0],
        p[1],
        htmlComponentSize[0],
        0,
      ]),
    [htmlComponentSize, dotsPositions]
  );
  // Populating htmlComponentSize
  useEffect(() => {
    setHtmlComponentSize([
      mergeKpiRef.current?.clientWidth || 0,
      mergeKpiRef.current?.clientHeight || 0,
    ]);
  }, [mergeKpiRef, seeAll]);
  // Populates and calculating dotsPositions - [ html (x,y) => canvas (x,y) ] using konva transformer.
  useEffect(() => {
    if (!mergeKpiRef.current) {
      return;
    }
    // Creating a canvas transformer that gets an absolute x,y position and transforms it to its correlated x,y on the canvas.
    const tr: Transform = dotsGroupRef.current?.getAbsoluteTransform().copy().invert();
    // By settings the id and dotClassName of MergeKPIComponent,
    // we are able to query the document in order to get the dot's positions
    // const elem = document.getElementById(mergeKpiId);
    const dots = mergeKpiRef.current.querySelectorAll('.' + MERGE_KPI_DOT_CLASS_NAME);
    // Get parent bbox - more info https://stackoverflow.com/questions/26423335/elements-coordinates-relative-to-its-parent
    const parentBBox = controller.root.getBoundingClientRect();
    const dotsPositions = Array.from(dots)
      .map(d => {
        const bbox = d.getBoundingClientRect();
        return tr.point({
          // 1 for border, size / 2 for centering
          x: bbox.x - parentBBox.x + 1 + bbox.width / 2,
          y: bbox.y - parentBBox.y + 1 + bbox.height / 2,
        });
      })
      .map(p => [p.x, p.y]);
    setDotsPositions(dotsPositions);
  }, [seeAll, htmlComponentSize, mergeKpiRef, controller.root]);

  if (members.length === 0) {
    return null;
  }

  return (
    <EnhancedGroup opacity={Number(htmlStyle.opacity)} x={x} y={y}>
      <EnhancedLine
        stroke={'rgba(81, 83, 112, 1)'}
        strokeWidth={LINE_STROKE}
        points={linePositions}
        lineJoin={'round'}
        lineCap={'round'}
        roundedCorners
      />

      <EnhancedGroup x={x - MERGE_KPI_X_OFFSET} y={yOffset}>
        <EnhancedRect
          x={0}
          y={0}
          width={htmlComponentSize[0]}
          height={htmlComponentSize[1]}
          fill={'#323553'}
          cornerRadius={12}
          centerY
        />
        <Group ref={dotsGroupRef}>
          {mergeDotsLines.map((l, idx) => (
            <EnhancedLine
              key={idx}
              stroke={'rgba(81, 83, 112, 1)'}
              strokeWidth={LINE_STROKE}
              points={l}
              lineJoin={'round'}
              lineCap={'round'}
              roundedCorners
            />
          ))}
        </Group>
        <Html
          divProps={{
            style: {...htmlStyle, zIndex: Number(htmlStyle.zIndex) + 1},
          }}
        >
          <MergeKPIs
            title={`source of ${modelPlural}`}
            id={mergeKpiId}
            dotClassName={MERGE_KPI_DOT_CLASS_NAME}
            members={members}
            color={color}
            ref={mergeKpiRef}
            isExpanded={seeAll}
            membersExpanded={isExpanded}
            count={visibleCount}
            onExpand={() => setSeeAll(!seeAll)}
            onKPIClicked={kpi => onKPIClicked(kpi)}
            model={model}
          />
        </Html>
      </EnhancedGroup>

      <EnhancedGroup x={x + LINE_DELTA_X + mergeKPIEndingX} y={yOffset}>
        <Html divProps={{style: htmlStyle}}>
          <TotalCount
            model={modelPlural}
            color={colorAlphaTransformer(color, 0.3, true, '#252745')}
            count={total}
          />
        </Html>
        <Html divProps={{style: htmlStyle}}>
          <DirectionArrow
            style={{
              position: 'absolute',
              top: `${LINE_DELTA_Y / 4}px`,
              transform: 'translateX(-50%)',
            }}
          />
        </Html>
      </EnhancedGroup>
    </EnhancedGroup>
  );
};

Sources.defaultProps = {
  x: 0,
  y: 0,
  direction: 'to-top',
  visibleCount: 3,
  round: 3,
};
