import * as React from 'react';
import {NullIf} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {QueryBlock} from '../components/query-block/query-block.component';
import {InlineToken} from '../components/parts';
import {queryBuilderClasses} from '../query-builder.component';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {useQueryElement} from '../query-builder.hooks';

interface OwnProps {
  data: NullIf;
  inline?: boolean;
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const NullIfBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, className, disabled, inline, box} = props;
  const {localErrors, actions} = useQueryElement(props);

  return (
    <QueryBlock
      label={'Null IF'}
      disabled={disabled}
      actions={actions}
      className={className}
      inline={inline}
      box={box}
    >
      <InlineToken>Exp 1</InlineToken>
      <QueryElementBuilder
        error={Boolean(localErrors.expression1)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'expression1')}
        data={data.expression1 as any}
        inline
      />
      <InlineToken>Exp 2</InlineToken>
      <QueryElementBuilder
        error={Boolean(localErrors.expression2)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'expression2')}
        data={data.expression2 as any}
        inline
      />
    </QueryBlock>
  );
};
