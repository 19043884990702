import {JourneyDriversScopeEvent} from '../../../../../types';
import {
  ExtendedJourneyDriversScopeEvent,
  FilterEventsMode,
  IEventsFilters,
  JourneyDriversScopeFilters,
} from './journey-drivers-scope.consts';

export const generateEventOptions = (events: JourneyDriversScopeEvent[]) =>
  events.map(k => ({
    label: k.name,
    value: k.name,
  }));

export const createInitialFilters = (
  filters: JourneyDriversScopeFilters,
  adoptionRangeMin,
  adoptionRangeMax
): IEventsFilters => {
  const minAdoption = filters?.sequence_filter_event_min_adoption || adoptionRangeMin;
  const maxAdoption = filters?.sequence_filter_event_max_adoption || adoptionRangeMax;
  const eventsFilterMode = filters?.sequence_filter_excluded_events
    ? FilterEventsMode.EXCLUDED
    : FilterEventsMode.INCLUDED;
  const filteredEvents =
    filters?.sequence_filter_excluded_events || filters?.sequence_filter_included_events;
  return {
    adoptionRange: [minAdoption, maxAdoption],
    eventsFilterMode,
    filteredEvents,
  };
};

export const extendEventsData = (
  events: JourneyDriversScopeEvent[],
  totalEntities: number,
  isPercentageValue: boolean
) => {
  return events.map(e => ({
    ...e,
    adoption: (e.adoptionAbs / totalEntities) * 100,
    uplift: e.goalInAdopters / e.goalInNonAdopters - 1,
  }));
};

export const filterEventsData = (
  data: ExtendedJourneyDriversScopeEvent[],
  filters: IEventsFilters
) => {
  return data.filter(e => {
    const {adoptionRange, filteredEvents, eventsFilterMode} = filters;
    const hasFilteredEvents = Boolean(filteredEvents?.length);

    if (e.adoption < adoptionRange[0] || e.adoption > adoptionRange[1]) {
      return false;
    }

    if (hasFilteredEvents && eventsFilterMode === FilterEventsMode.INCLUDED) {
      return filteredEvents.includes(e.name);
    }

    if (hasFilteredEvents && eventsFilterMode === FilterEventsMode.EXCLUDED) {
      return !filteredEvents.includes(e.name);
    }

    return true;
  });
};
