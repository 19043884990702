import * as React from 'react';
import classes from '../../../chart.module.scss';
import {useEffect, useMemo, useRef, useState} from 'react';
import {getLeftPosition, getTopPosition} from '../../../chart.utils';

export interface TooltipData {
  label?: string | JSX.Element;
  value?: string | JSX.Element;
  footer?: string | JSX.Element;
}

interface OwnProps {
  x: number;
  y: number;
  show?: boolean;
  style?: any;
  data?: TooltipData;
  rootSize: {width: number; height: number};
  xOffset?: number;
  yOffset?: number;
}

type AllProps = OwnProps;
const CHART_TOOLTIP_ID = 'LineChartTooltip';

export const LineChartTooltip: React.FC<AllProps> = (props: AllProps) => {
  const {x, y, show, style, data: dataFromProps, rootSize, xOffset, yOffset} = props;
  const [data, setData] = useState(dataFromProps);
  const tooltipRef = useRef<HTMLDivElement>();

  useEffect(() => {
    // make sure the data is not disappear when fade out
    if (dataFromProps === null) {
      return;
    }
    setData(dataFromProps);
  }, [dataFromProps]);

  const tooltipCurrent: HTMLDivElement = useMemo(() => {
    if (tooltipRef.current) {
      return tooltipRef.current;
    }
    return document.getElementById(CHART_TOOLTIP_ID) as HTMLDivElement;
  }, [tooltipRef.current]);
  // We are doing so in render because of race condition using useMemo
  const left = getLeftPosition(tooltipCurrent, x, rootSize?.width, xOffset);
  const top = getTopPosition(tooltipCurrent, y, rootSize?.height, yOffset);

  return (
    <div
      id={CHART_TOOLTIP_ID}
      ref={tooltipRef}
      style={{
        top: top,
        left,
        opacity: show ? 1 : 0,
        fontFamily: style?.fontName,
      }}
      className={classes.LineChartTooltip}
    >
      {data && (
        <>
          {data.label && <div className={classes.Top}>{data.label}</div>}
          <div className={classes.Content}>{data.value}</div>
        </>
      )}
      {data && data.footer && <div className={classes.Footer}>{data.footer}</div>}
    </div>
  );
};
