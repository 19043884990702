import {action, OnSuccessActionHook} from 'front-core';
import {
  AddMetricToTreeDTO,
  AddInputMetricDTO,
  RemoveMetricFromTreeDTO,
  SetKPITreeMetricPositionsDTO,
  RemoveInputMetricDTO,
  ChangeInputMetricRelationshipTypeDTO,
  SetKPITreeMetricFormulaDTO,
} from '../../objects/dto/metric-tree.dto';

export enum MetricTreeActionType {
  ADD_INPUT_METRIC = '@@metric-tree/ADD_INPUT_METRIC',
  ADD_METRIC_TO_TREE = '@@metric-tree/ADD_METRIC_TO_TREE',
  REMOVE_METRIC_FROM_TREE = '@@metric-tree/REMOVE_METRIC_FROM_TREE',
  REMOVE_INPUT_METRIC = '@@metric-tree/REMOVE_INPUT_METRIC',
  SET_KPI_TREE_METRIC_POSITIONS = '@@metric-tree/SET_KPI_TREE_METRIC_POSITIONS',
  SET_KPI_TREE_METRIC_FORMULA = '@@metric-tree/SET_KPI_TREE_METRIC_FORMULA',
  CHANGE_INPUT_METRIC_RELATIONSHIP_TYPE = '@@metric-tree/CHANGE_INPUT_METRIC_RELATIONSHIP_TYPE',
}

export const addInputMetric = (data: AddInputMetricDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricTreeActionType.ADD_INPUT_METRIC, data, {onSuccess});

export const removeInputMetric = (data: RemoveInputMetricDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricTreeActionType.REMOVE_INPUT_METRIC, data, {onSuccess});

export const setKPITreeMetricPositions = (
  data: SetKPITreeMetricPositionsDTO,
  onSuccess?: OnSuccessActionHook
) =>
  action(MetricTreeActionType.SET_KPI_TREE_METRIC_POSITIONS, data, {
    onSuccess,
  });

export const addMetricToTree = (data: AddMetricToTreeDTO, onSuccess?: OnSuccessActionHook) =>
  action(MetricTreeActionType.ADD_METRIC_TO_TREE, data, {onSuccess});

export const changeInputMetricRelationshipType = (
  data: ChangeInputMetricRelationshipTypeDTO,
  onSuccess?: OnSuccessActionHook
) => action(MetricTreeActionType.CHANGE_INPUT_METRIC_RELATIONSHIP_TYPE, data, {onSuccess});

export const removeMetricFromTree = (
  data: RemoveMetricFromTreeDTO,
  onSuccess?: OnSuccessActionHook
) => action(MetricTreeActionType.REMOVE_METRIC_FROM_TREE, data, {onSuccess});

export const setKPITreeMetricFormula = (
  data: SetKPITreeMetricFormulaDTO,
  onSuccess?: OnSuccessActionHook
) => action(MetricTreeActionType.SET_KPI_TREE_METRIC_FORMULA, data, {onSuccess});
