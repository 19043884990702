import {every, isArray, max, range} from 'lodash';
import {FunnelDataset, NumberDataset} from '../../charts/chart-data.types';
import {ExtendedFunnelDataset, FunnelChartMode} from './funnel-chart.component';
import {safeDivision} from 'front-core';
import {DATASET_COLORS} from '../../charts/chart.consts';

export const fixFunnelDataset = (
  datasets: FunnelDataset[] | NumberDataset[],
  sort: boolean = true
): [FunnelDataset[], number] => {
  const datasetDataLength: number = max(
    datasets.map(ds => {
      if (isArray(ds?.data)) {
        return ds.data.length;
      }
      return max([ds.data.fromInitial.length, ds.data.fromPrevious.length]);
    })
  );
  let fixedDatasets: FunnelDataset[] = datasets.map(ds => {
    const {data: currentDsData, ...restOfDs} = ds;
    const maxRange = range(0, datasetDataLength);

    // Backward compatability handling for NumberDataset
    if (Array.isArray(currentDsData)) {
      const dataValues = maxRange.map(i => (currentDsData[i] ? Number(currentDsData[i]) : 0));

      return {
        ...restOfDs,
        data: {
          fromPrevious: dataValues,
          fromInitial: dataValues,
        },
      };
    }

    return {
      ...restOfDs,
      data: {
        fromPrevious: maxRange.map(i =>
          ds.data.fromPrevious[i] ? Number(ds.data.fromPrevious[i]) : 0
        ),
        fromInitial: maxRange.map(i =>
          ds.data.fromInitial[i] ? Number(ds.data.fromInitial[i]) : 0
        ),
        trends: ds.data.trends,
      },
    };
  });

  if (sort) {
    fixedDatasets = fixedDatasets.sort((a, b) => b.data.fromPrevious[0] - a.data.fromPrevious[0]);
  }

  return [fixedDatasets, datasetDataLength];
};

export const extendFunnelDataset = (
  funnelDataset: FunnelDataset,
  idx: number
): ExtendedFunnelDataset => {
  const values = funnelDataset.data.fromPrevious;
  const funnelStart = values[0];

  const color = DATASET_COLORS[idx % DATASET_COLORS.length];
  const conversionFromStart = [];
  const conversionFromPrev = [];
  for (const [idx, value] of values.entries()) {
    if (idx === 0) {
      continue;
    }
    conversionFromStart.push(safeDivision(value, funnelStart));
    conversionFromPrev.push(safeDivision(values[idx], values[idx - 1]));
  }

  return {
    ...funnelDataset,
    conversionFromStart,
    conversionFromPrev,
    color,
  };
};

export const generateStepTitle = (idx: number, total: number) => {
  if (idx === 0) {
    return 'Funnel start';
  }
  if (idx === total - 1) {
    return 'Funnel completion';
  }
  return `Step ${idx + 1}`;
};

export const generateClipPath = (baseNumber: number, fromNumber: number, toNumber: number) => {
  const start = max([fromNumber / baseNumber, 0.06]);
  const end = max([toNumber / baseNumber, 0.06]);
  const startOffset = (1 - start) / 2;
  const endOffset = (1 - end) / 2;

  return `polygon(
    0% ${startOffset * 100}%, 
    100% ${endOffset * 100}%,
    100% ${(1 - endOffset) * 100}%,
    0% ${(1 - startOffset) * 100}%
  )`;
};

export const getModeSettings = (
  funnelDataset: FunnelDataset[]
): {defaultMode: FunnelChartMode; modeOptions: FunnelChartMode[]} => {
  const modeOptions = [
    FunnelChartMode.TOTALS,
    FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP,
    FunnelChartMode.CONVERSION_FROM_INITIAL_STEP,
  ];
  const showTrendsOption = every(funnelDataset, f => f.data.trends && f.data.trends.length > 0);
  if (showTrendsOption) {
    modeOptions.push(FunnelChartMode.TRENDS);
  }

  return {
    defaultMode: FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP,
    modeOptions,
  };
};
