import * as React from 'react';
import classNames from 'classnames';
import {
  CommandType,
  RCAExperimentExplainer,
  RCAExperimentExplainerFigureOptions,
} from '../../../../../../types';
import classes from './experiment-explainer-item.module.scss';
import {ExplainabilityLabelDisplay} from '../../../components/explainability-label-display/explainability-label-display.component';
import {useCallback, useContext, useMemo, useState} from 'react';
import {ImpactPPDisplay} from '../../../../../shared/display-columns/impact-pp-display.component';
import {ArrowUpRightFromSquareRegularIcon} from '../../../../../../../../simple/controls/icons/icons.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {ModelType} from '../../../../../../../../../consts/model-type';
import {ToggleOpenButton} from '../../../../../../../../simple/controls/toggle-open-button/toggle-open-button.component';
import {ExperimentVariantsTable} from '../experiment-variants-table/experiment-variants-table.component';
import {TooltipIfOverflow} from '../../../../../../../../simple/generic/tooltips/tooltips.component';

interface Props {
  experiment: RCAExperimentExplainer;
  options: RCAExperimentExplainerFigureOptions;
  className?: string;
}

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_EXPERIMENT_EXPLAINER_FIGURE;

export const ExperimentExplainerItem: React.FC<Props> = (props: Props) => {
  const {experiment, options, className} = props;
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const [showVariants, setShowVariants] = useState(false);
  const selectedVariant = useMemo(
    () =>
      experiment.variants
        .filter(v => !v.isControl)
        .sort((a, b) => b.explainabilityScore - a.explainabilityScore)[0],
    [experiment]
  );
  const onExperimentClick = useCallback(
    () =>
      emitEvent({
        type: CommandType.REF,
        payload: {
          type: ModelType.EXPERIMENT,
          refId: experiment.experimentId,
        },
      }),
    [emitEvent, experiment]
  );
  const analysisType = t(TransKeysPrefix.ANALYSIS_TYPE_NAME[experiment.analysisTypeId.toString()]);

  return (
    <div
      className={classNames(
        classes.ExperimentExplainerItem,
        selectedVariant.isSignificant === false && classes.NotSignificant,
        showVariants && classes.Expanded,
        className
      )}
    >
      <div className={classes.Row}>
        <div className={classes.AnalysisTypeName}>{analysisType}</div>
        <div className={classNames(classes.Cell, classes.Name)}>
          <div className={classes.ExperimentName} onClick={onExperimentClick}>
            <TooltipIfOverflow title={experiment.experimentName}>
              <span className={classes.ExperimentNameText}>{experiment.experimentName}</span>
            </TooltipIfOverflow>
            <ArrowUpRightFromSquareRegularIcon />
          </div>
        </div>
        <div className={classes.Cell}>
          <ExplainabilityLabelDisplay
            label={selectedVariant.explainabilityLabel}
            isSignificant={selectedVariant.isSignificant}
            modelName={'this variant'}
          />
        </div>
        {selectedVariant.isSignificant && (
          <div className={classNames(classes.Cell, classes.Description)}>
            <span className={classes.Text}>
              {t(TransKeysPrefix.EXPERIMENT_DESCRIPTION_SENTENCE, {name: selectedVariant.name})}
            </span>
            <ImpactPPDisplay
              value={selectedVariant.ctcPp}
              outOfValue={experiment.populationKpiTrend}
            />
          </div>
        )}
        {!selectedVariant.isSignificant && (
          <div className={classNames(classes.Cell, classes.Description)}>
            <span>{t(TransKeysPrefix.INSIGNIFICANT_EXPERIMENT_DESCRIPTION_SENTENCE)}</span>
          </div>
        )}
        <div className={classNames(classes.Cell, classes.ActionsCell)}>
          <ToggleOpenButton
            openLabel={t(TransKeysPrefix.HIDE_ALL_VARIANTS_BUTTON_TEXT)}
            closedLabel={t(TransKeysPrefix.VIEW_ALL_VARIANTS_BUTTON_TEXT)}
            isOpen={showVariants}
            onClick={() => setShowVariants(isOpen => !isOpen)}
          />
        </div>
      </div>
      <div className={classNames(classes.VariantsTableWrapper, showVariants && classes.Open)}>
        <ExperimentVariantsTable experiment={experiment} options={options} />
      </div>
    </div>
  );
};
