import * as React from 'react';
import classes from './block-viewer.module.scss';
import {ChildRenderer} from '../../core/child-renderer.component';
import classNames from 'classnames';
import {CSSProperties, useState} from 'react';
import {Block, BlockOptions} from '../../../types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {ChildRendererInjectedProps} from '../../../document-viewer.types';

export interface OwnProps extends Block, Partial<ChildRendererInjectedProps> {
  options?: BlockOptions;
  style?: CSSProperties;
  className?: string;
}

export const BlockViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {options, title, className} = props;
  const [isClosed, setIsClosed] = useState(options?.startClosed || false);

  return (
    <div
      style={props.style}
      className={classNames(
        classes.BlockViewer,
        isClosed && classes.Closed,
        props.darkMode && classes.DarkMode,
        title && classes.HasTitle,
        options?.startClosed === true && classes.Collapsable,
        className
      )}
    >
      {options?.startClosed === true && isClosed === false && (
        <div className={classNames(classes.ClosedOverlay, classes.Open)}>
          <div onClick={_ => setIsClosed(true)} className={classes.SeeMore}>
            See Less
            <ArrowDropDownIcon className={classes.Icon} />
          </div>
        </div>
      )}
      {isClosed && (
        <div className={classes.ClosedOverlay}>
          <div onClick={_ => setIsClosed(false)} className={classes.SeeMore}>
            See More
            <ArrowDropDownIcon className={classes.Icon} />
          </div>
        </div>
      )}
      {title && <div className={classes.Title}>{title}</div>}
      {props.children.map((c, index) => (
        <div className={classes.Block} key={`block_${c.type}_${index}`}>
          <ChildRenderer children_={c} />
        </div>
      ))}
    </div>
  );
};

BlockViewer.defaultProps = {
  options: {},
};
