import {get} from 'lodash';
import {BoundaryBox} from '../../components/complex/charts/chart-inner.types';

export {capitalize, number2k} from 'front-core';

export const calculateBbox = (
  width: number = 0,
  height: number = 0,
  padding: number[] = [0, 0, 0, 0], // top, right, bottom, left (clock-wise)
  options: {x: number; y: number} = {x: 0, y: 0}
): BoundaryBox => {
  return {
    width: width - (get(padding, 1, 0) + get(padding, 3, 0)),
    height: height - (get(padding, 0, 0) + get(padding, 2, 0)),
    x: options.x + get(padding, 3, 0),
    y: options.y + get(padding, 0, 0),
  };
};

// https://github.com/gdenisov/cardinal-spline-js
export const curveLine = (
  points: number[],
  tension = 0.5,
  numOfSeg = 20,
  close = false
): number[] => {
  let pts: number[],
    i = 1,
    l = points.length,
    rPos = 0,
    rLen = (l - 2) * numOfSeg + 2 + (close ? 2 * numOfSeg : 0),
    res = new Float32Array(rLen),
    cache = new Float32Array((numOfSeg + 2) * 4),
    cachePtr = 4;

  pts = points.slice(0);

  if (close) {
    // insert end point as first point
    pts.unshift(points[l - 1]);
    pts.unshift(points[l - 2]);
    // first point as last point
    pts.push(points[0], points[1]);
  } else {
    // copy 1. point and insert at beginning
    pts.unshift(points[1]);
    pts.unshift(points[0]);
    // duplicate end-points
    pts.push(points[l - 2], points[l - 1]);
  }

  // cache inner-loop calculations as they are based on t alone
  cache[0] = 1; // 1,0,0,0

  for (; i < numOfSeg; i++) {
    const st = i / numOfSeg,
      st2 = st * st,
      st3 = st2 * st,
      st23 = st3 * 2,
      st32 = st2 * 3;

    cache[cachePtr++] = st23 - st32 + 1; // c1
    cache[cachePtr++] = st32 - st23; // c2
    cache[cachePtr++] = st3 - 2 * st2 + st; // c3
    cache[cachePtr++] = st3 - st2; // c4
  }

  // 0,1,0,0
  cache[++cachePtr] = 1;

  // calc. points
  parse(pts, cache, l);

  if (close) {
    pts = [];
    pts.push(points[l - 4], points[l - 3], points[l - 2], points[l - 1]); // second last and last
    pts.push(points[0], points[1], points[2], points[3]); // first and second
    parse(pts, cache, 4);
  }

  function parse(pts, cache, l) {
    for (let i = 2, t; i < l; i += 2) {
      const pt1 = pts[i],
        pt2 = pts[i + 1],
        pt3 = pts[i + 2],
        pt4 = pts[i + 3],
        t1x = (pt3 - pts[i - 2]) * tension,
        t1y = (pt4 - pts[i - 1]) * tension,
        t2x = (pts[i + 4] - pt1) * tension,
        t2y = (pts[i + 5] - pt2) * tension;

      for (t = 0; t < numOfSeg; t++) {
        const c = t << 2, //t * 4;
          c1 = cache[c],
          c2 = cache[c + 1],
          c3 = cache[c + 2],
          c4 = cache[c + 3];

        res[rPos++] = c1 * pt1 + c2 * pt3 + c3 * t1x + c4 * t2x;
        res[rPos++] = c1 * pt2 + c2 * pt4 + c3 * t1y + c4 * t2y;
      }
    }
  }

  // add last point
  l = close ? 0 : points.length - 2;
  res[rPos++] = points[l];
  res[rPos] = points[l + 1];

  return Array.from(res);
};

export const toRadians = d => d * (Math.PI / 180);
