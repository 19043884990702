import * as React from 'react';
import {WindowBlock} from '../../types';
import {isFunction} from 'lodash';

export interface OwnProps extends WindowBlock {
  index?: number;
}

export const WindowBlockViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {render} = props;
  if (isFunction(render)) {
    return render(props);
  }
  return render;
};
