import * as React from 'react';
import classNames from 'classnames';
import {ConfidenceIntervalColumnOptions} from '../../../../../types';
import classes from './data-columns.module.scss';
import {useMemo} from 'react';
import {get, min, max, isNumber} from 'lodash';
import {Tooltip} from '@material-ui/core';
import {exists, toFixedNumber} from 'front-core';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {ColorUtil} from '../../../../../document-viewer.utils';
import {getDataValueKey} from '../../smart-table.utils';
import {DataColumnProps} from '../../smart-table.types';
import {colorAlphaTransformer} from '../../../../../../../../utils/colors';

interface OwnProps extends DataColumnProps<ConfidenceIntervalColumnOptions> {}

type AllProps = OwnProps;

const GOOD_COLOR = ColorUtil.getColor('good');
const GOOD_COLOR_LOW = colorAlphaTransformer(GOOD_COLOR, 0.4);
const BAD_COLOR = ColorUtil.getColor('bad');
const BAD_COLOR_LOW = colorAlphaTransformer(BAD_COLOR, 0.4);

const ConfidenceIntervalDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, dataColumnOptions, className} = props;
  const {upperDataKey, lowerDataKey} = column.typeOptions;
  const valueDataKey = useMemo(() => getDataValueKey(column, 'valueDataKey'), [column]);
  const value = useMemo(() => get(data, valueDataKey), [valueDataKey, data]);
  const upper = useMemo(() => get(data, upperDataKey), [data, upperDataKey]);
  const lower = useMemo(() => get(data, lowerDataKey), [data, lowerDataKey]);
  const higherIsBetter = useMemo(
    () => (exists(column.typeOptions.higherIsBetter) ? column.typeOptions.higherIsBetter : true),
    [column]
  );
  const maxAbsValue = useMemo(
    () => max([Math.abs(column.metadata.minValue), Math.abs(column.metadata.maxValue)]),
    [column]
  );
  const minValue = useMemo(
    () => min([Math.sign(column.metadata.minValue) * maxAbsValue, 0]),
    [column, maxAbsValue]
  );
  const maxValue = useMemo(
    () => max([Math.sign(column.metadata.maxValue) * maxAbsValue, 0]),
    [column, maxAbsValue]
  );
  const total = useMemo(() => maxValue - minValue, [maxValue, minValue]);
  const unit = useMemo(
    () => dataColumnOptions?.unit || column.options.unit,
    [column.options.unit, dataColumnOptions]
  );

  const valuePosition = useMemo(() => ((value - minValue) / total) * 100, [minValue, total, value]);
  const zeroPosition = useMemo(() => ((0 - minValue) / total) * 100, [minValue, total]);
  const colors = useMemo(
    () => ({
      GOOD_COLOR: higherIsBetter ? GOOD_COLOR : BAD_COLOR,
      GOOD_COLOR_LOW: higherIsBetter ? GOOD_COLOR_LOW : BAD_COLOR_LOW,
      BAD_COLOR: higherIsBetter ? BAD_COLOR : GOOD_COLOR,
      BAD_COLOR_LOW: higherIsBetter ? BAD_COLOR_LOW : GOOD_COLOR_LOW,
    }),
    [higherIsBetter]
  );

  return (
    <div className={classNames(classes.ConfidenceIntervalDataColumn, className)}>
      <div className={classes.MainValue}>
        {toFixedNumber(value, 2)}
        {unit ? unit : ''}
      </div>
      <div className={classes.Box}>
        <div className={classes.BoxPadding}>
          <div
            style={{
              left: `${((0 - minValue) / total) * 100}%`,
            }}
            className={classes.Zero}
          />
          <div
            style={{
              left: `${((value - minValue) / total) * 100}%`,
              backgroundColor: value > 0 ? colors.GOOD_COLOR : colors.BAD_COLOR,
            }}
            className={classes.Value}
          />
          <div
            style={{
              left: `${min([zeroPosition, valuePosition])}%`,
              width: `calc(${Math.abs(valuePosition - zeroPosition)}% + 1px)`,
              backgroundColor: value > 0 ? colors.GOOD_COLOR_LOW : colors.BAD_COLOR_LOW,
            }}
            className={classes.Area}
          />
          {isNumber(lower) && isNumber(upper) && (
            <Tooltip
              placement={'top'}
              title={`[${toFixedNumber(lower, 3)}${unit ? unit : ''}, ${toFixedNumber(upper, 3)}${
                unit ? unit : ''
              }]`}
            >
              <div
                style={{
                  left: `${((lower - minValue) / total) * 100}%`,
                  width: `${((upper - lower) / total) * 100}%`,
                }}
                className={classes.CIWrapper}
              >
                <div className={classes.CI} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

const protector: Protector = (props: OwnProps) => {
  const {column, data} = props;
  const value = get(data, getDataValueKey(column, 'valueDataKey'));
  return exists(value);
};

export const ConfidenceIntervalDataColumn = withDataColumnProtector(protector)(
  ConfidenceIntervalDataColumnComponent
);
