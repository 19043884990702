import * as React from 'react';
import classes from './chip.module.scss';
import classNames from 'classnames';
import {Tooltip} from '@material-ui/core';

export enum ChipVariant {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARN = 'warn',
  ERROR = 'error',
  RUNNING = 'running',
  EXAMPLE = 'example',
  FOLLOW_UP = 'follow_up',
  CAUSAL = 'causal',
  NEW = 'new',
  DARK = 'dark',
  PRIMARY_KPI = 'primary_kpi',
  DISABLED = 'disabled',
}

interface OwnProps {
  label: string;
  onClick?: (e) => void;
  className?: string;
  variant?: ChipVariant | string;
  size?: 'xsmall' | 'small' | 'medium';
  icon?: any;
  caps?: boolean;
  bold?: boolean;
  helperText?: string;
  color?: string;
}

type AllProps = OwnProps;

export const Chip: React.FC<AllProps> = (props: AllProps) => {
  const {
    label,
    className,
    helperText,
    variant,
    size,
    icon: Icon,
    caps,
    bold,
    color,
    onClick,
  } = props;

  const render = (
    <div
      className={classNames(
        classes.Chip,
        onClick && classes.Clickable,
        caps && classes.Caps,
        variant && classes[variant],
        size && classes[size],
        bold && classes.Bold,
        className
      )}
      onClick={onClick}
      style={color ? {backgroundColor: color} : {}}
    >
      {Icon && <Icon className={classes.Icon} />}
      {label}
    </div>
  );

  if (helperText) {
    return (
      <Tooltip title={helperText} placement={'top'}>
        {render}
      </Tooltip>
    );
  }

  return render;
};

Chip.defaultProps = {
  caps: true,
  size: 'medium',
};
