import * as React from 'react';
import classes from './kpi-status-line.module.scss';
import classNames from 'classnames';
import {ElementDataType, KPISample} from '../../../../../types/growth-map.types';
import {CSSProperties, useMemo} from 'react';
import {TrendingIcon} from '../../generic-components/trending-icon/trending-icon.component';
import moment from 'moment';
import pluralize from 'pluralize';
import {number2k} from 'front-core';

interface OwnProps {
  className?: string;
  samples: KPISample[];
  higherIsBetter?: boolean;
  type?: ElementDataType;
  model?: string;
  preview?: boolean;
  noWrap?: boolean;
  round?: number;
  style?: CSSProperties;
}

type AllProps = OwnProps;

export const KPIStatusLine: React.FC<AllProps> = (props: AllProps) => {
  const {samples, className, type, model, higherIsBetter, preview, noWrap, round, style} = props;

  const percent: number | null = useMemo(() => {
    if (samples.length < 2) {
      return null;
    }
    if (samples[1].value === 0) {
      return null;
    }
    try {
      const v = samples[0].value / samples[1].value;
      return Number((v * 100 - 100).toFixed(2));
    } catch (e) {
      return null;
    }
  }, [samples]);
  const trendingUp = useMemo(() => {
    if (samples.length < 2) {
      return null;
    }
    return samples[0].value > samples[1].value;
  }, [samples]);
  const direction: boolean = useMemo(() => {
    if (trendingUp === null) {
      return null;
    }
    return higherIsBetter ? trendingUp : !trendingUp;
  }, [higherIsBetter, trendingUp]);
  const since: string = useMemo(() => {
    if (samples.length < 2) {
      return null;
    }
    return moment(samples[1].timestamp).fromNow();
  }, [samples]);
  const values: any = useMemo(
    () => ({
      current: samples[0] ? number2k(samples[0].value, round) : null,
      prev: samples[1] ? number2k(samples[1]?.value, round) : null,
    }),
    [round, samples]
  );
  const suffix = useMemo(() => (type === 'percentage' ? '%' : null), [type]);
  const modelPlural = useMemo(() => {
    if (model) {
      let modelPlural = model ? pluralize(model) : '';
      return type === 'percentage' ? `of ${modelPlural}`.toLowerCase() : modelPlural;
    }
    return '';
  }, [model, type]);

  return (
    <div
      style={style}
      className={classNames(classes.KPIStatusLine, noWrap && classes.NoWrap, className)}
    >
      <span className={classes.Bold}>
        {values.current}
        {suffix} {modelPlural}
      </span>
      <span className={classNames(classes.SecondaryText, preview && classes.Hidden)}>
        was {values.prev}
        {suffix}
      </span>
      <div className={classes.Divider} />
      {trendingUp !== null && (
        <TrendingIcon
          higherIsBetter={higherIsBetter}
          className={classes.Trend}
          trendingUp={trendingUp}
        />
      )}
      {direction !== null && (
        <span
          className={classNames(
            preview && classes.Preview,
            direction === true && classes.Positive,
            direction === false && classes.Negative
          )}
        >
          {Math.abs(percent)}%
        </span>
      )}
      <span className={classNames(classes.SecondaryText, preview && classes.Hidden)}>
        Since {since}
      </span>
    </div>
  );
};

KPIStatusLine.defaultProps = {
  higherIsBetter: true,
  samples: [],
  round: 2,
};
