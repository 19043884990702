import {
  NotSignificantUsersChange,
  RCAInsightSegmentSentenceParts,
  RCAInsightSentence,
} from '../rca-insights.model.ts';
import {
  ChangeInTotalEntitiesSentencePart,
  SegmentNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NotSignificantUsersChange;
  sentenceParts: RCAInsightSegmentSentenceParts;
}
type AllProps = OwnProps;

const NotSignificantUsersChangeFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {changeInTotalEntities, entityChangeDescription, entity} = formattedSentenceParts;
  const {segmentViewData} = sentenceParts;

  return (
    <SentenceContainer>
      <SegmentNameSentencePart data={segmentViewData} />
      <SentencePart text={'had'} />
      <ChangeInTotalEntitiesSentencePart changeInTotalEntities={changeInTotalEntities} />
      <SentencePart
        text={`${entityChangeDescription} ${entity} compared to the analyzed period.`}
      />
    </SentenceContainer>
  );
};

export {NotSignificantUsersChangeFormatter};
