import * as React from 'react';
import classes from './index-switch.module.scss';
import {range} from 'lodash';
import classNames from 'classnames';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

interface OwnProps {
  current: number; // starts from 0
  count: number;
  onChange: (index: number) => void;
  color?: string;
}

type AllProps = OwnProps;

export const IndexSwitch: React.FC<AllProps> = (props: AllProps) => {
  const {current, count, onChange, color} = props;

  const changeIndex = index => {
    let newIndex = index % count;
    if (newIndex < 0) {
      newIndex = count + newIndex;
    }
    onChange(newIndex);
  };

  return (
    <div className={classes.IndexSwitch}>
      <div onClick={_ => changeIndex(current - 1)} className={classes.Arrow}>
        <ArrowLeftIcon style={{color}} className={classes.Icon} />
      </div>
      <div className={classes.Indexes}>
        {range(0, count).map(i => (
          <div
            key={i}
            onClick={_ => changeIndex(i)}
            style={i === current ? {backgroundColor: color} : {}}
            className={classNames(classes.Index, i === current && classes.Selected)}
          >
            {i + 1}
          </div>
        ))}
      </div>
      <div onClick={_ => changeIndex(current + 1)} className={classes.Arrow}>
        <ArrowRightIcon style={{color}} className={classes.Icon} />
      </div>
    </div>
  );
};

IndexSwitch.defaultProps = {
  color: 'rgba(52, 131, 255, 1)',
};
