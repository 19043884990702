import {useCallback} from 'react';
import {AIChatMessageSuggestionDTO} from '../../../../../../../../objects/dto/ai-chat.dto';
import {MessageSuggestions} from '../../../message-suggestions/message-suggestions.component';
import {BaseMessageRendererProps} from '../../message-viewer.types';
import {AmplitudeEvent} from '../../../../../../../../constants/amplitude-event';
import {useAmplitude} from '../../../../../../../../core/hooks/amplitude.hook';

type AllProps = BaseMessageRendererProps<{}>;

export const ShowSuggestionsMessage = (props: AllProps) => {
  const {onSubmitMessage} = props;
  const notify = useAmplitude();

  const onSelectSuggestion = useCallback(
    (suggestion: AIChatMessageSuggestionDTO) => {
      notify(AmplitudeEvent.AI_CHATBOT_USER_CLICKED_SUGGESTION, {
        suggestion,
        context: 'show_suggestions_message',
      });

      onSubmitMessage(suggestion.message);
    },
    [onSubmitMessage, notify]
  );

  return <MessageSuggestions onSelectSuggestion={onSelectSuggestion} />;
};
