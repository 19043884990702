import * as React from 'react';
import classNames from 'classnames';
import {RCAPopulationExplainerFigure} from '../../../../types';
import classes from './rca-population-size-explainer-viewer.module.scss';
import {useContext, useMemo, useState} from 'react';
import {RCAFigureViewerContext} from '../rca-figure/rca-figure-viewer.context';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {TrendChip} from '../../../../../../simple/data-display/trend-chip/trend-chip.component';
import pluralize from 'pluralize';
import {ExplainabilityLabelDisplay} from '../components/explainability-label-display/explainability-label-display.component';
import {FancyBlock} from '../../../../../../simple/data-display/fancy-block/fancy-block.component';
import {LineChart} from '../../../../../charts/charts/line-chart/line-chart.component';
import {ToggleOpenButton} from '../../../../../../simple/controls/toggle-open-button/toggle-open-button.component';

interface Props extends RCAPopulationExplainerFigure {
  className?: string;
}

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_POPULATION_SIZE_EXPLAINER_VIEWER;

export const RCAPopulationSizeExplainerViewer: React.FC<Props> = (props: Props) => {
  const {data, options, className} = props;
  const {t} = useDocumentTranslation();
  const {uplift} = useContext(RCAFigureViewerContext);
  const [showChart, setShowChart] = useState(false);
  const {kpiSamples, explainabilityLabel, isSignificant, trendChange, granularity} = data;

  const secondaryLine = useMemo(() => {
    if (!isSignificant) {
      return t(TRANS_KEYS_PREFIX.SECONDARY_LINE.INSIGNIFICANT);
    }
    let trend_text = '';
    if (uplift > 0) {
      trend_text = 'spike';
    } else {
      trend_text = 'drop';
    }
    return t(TRANS_KEYS_PREFIX.SECONDARY_LINE[explainabilityLabel.toUpperCase()], {trend_text});
  }, [explainabilityLabel, isSignificant, uplift, t]);
  const chartProps = useMemo(() => {
    if (kpiSamples.length === 0) {
      return;
    }
    const kpiDataset = kpiSamples.map(sample => ({
      x: sample.datetime,
      y: options.isPercentageValue ? sample.value * 100 : sample.value,
      dashed: sample.isPartial,
    }));
    const populationDataset = kpiSamples.map(sample => ({
      x: sample.datetime,
      y: sample.entityCount,
      dashed: sample.isPartial,
    }));

    return {
      id: 'rca-population',
      datasets: [
        {
          id: 'kpi',
          label: options.kpiName,
          data: kpiDataset,
        },
        {
          id: 'population',
          label: t(TRANS_KEYS_PREFIX.LABELS.POPULATION_SIZE),
          data: populationDataset,
          yAxis: 'secondary',
        },
      ],
      options: {
        xLabel: 'Date',
        yLabelSuffix: options.isPercentageValue ? '%' : undefined,
        labels: {
          timeUnit: granularity,
          type: 'date',
          dateFormat: 'MMM D',
          capitalize: false,
        },
        yAxisMaxTicks: 5,
      },
    } as any;
  }, [kpiSamples, t, granularity, options]);

  return (
    <div className={classNames(classes.RCAPopulationSizeExplainerViewer, className)}>
      <div className={classNames(classes.Header, classes[explainabilityLabel])}>
        <div className={classes.Main}>
          <span>{t(TRANS_KEYS_PREFIX.MAIN_SENTENCE.PREFIX)}</span>
          <TrendChip value={trendChange * 100} size={'small'} />
          <span>
            {t(TRANS_KEYS_PREFIX.MAIN_SENTENCE.SUFFIX, {
              entity: pluralize(options.entity).toLowerCase(),
              name: options.referenceSignalName,
            })}
          </span>
          <span>{secondaryLine}</span>
        </div>
        <div className={classes.Explainability}>
          <span className={classes.Label}>{t(TRANS_KEYS_PREFIX.EXPLAINABILITY_LABEL_PREFIX)}</span>
          <ExplainabilityLabelDisplay
            label={explainabilityLabel}
            isSignificant={isSignificant}
            modelName={'the change in population size'}
          />
        </div>
        <div className={classes.Actions}>
          <ToggleOpenButton
            onClick={() => setShowChart(!showChart)}
            isOpen={showChart}
            openLabel={t(TRANS_KEYS_PREFIX.ACTIONS.HIDE_GRAPH)}
            closedLabel={t(TRANS_KEYS_PREFIX.ACTIONS.SHOW_GRAPH)}
          />
        </div>
      </div>
      {showChart && (
        <FancyBlock title={t(TRANS_KEYS_PREFIX.CHART_TITLE)}>
          <div className={classes.ChartWrapper}>{chartProps && <LineChart {...chartProps} />}</div>
        </FancyBlock>
      )}
    </div>
  );
};
