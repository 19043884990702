import {Button} from 'ui-components';
import {
  AIChatBotAnalysisType,
  RunAnalysesRecommendationMessageData,
} from '../../../../../../../../objects/models/ai-chat.model';
import {BaseMessageRendererProps} from '../../message-viewer.types';
import {useCallback, useContext} from 'react';
import {PanelsContext} from '../../../../../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../../../../../constants/panels';
import {ANALYSIS_TYPE_ID_PATH_PARAM} from '../../../../../../../../constants/app-routes';
import {AnalysisTypeId} from '../../../../../../../../constants/analysis-type-id';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../../../../../constants/translation-keys';
import {useAmplitude} from '../../../../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../../../constants/amplitude-event';

type AllProps = BaseMessageRendererProps<RunAnalysesRecommendationMessageData>;

const AnalysisTypeIdToAITypeMap = {
  [AIChatBotAnalysisType.RELEASE_IMPACT]: AnalysisTypeId.RELEASE_IMPACT,
  [AIChatBotAnalysisType.GOAL_DRIVERS]: AnalysisTypeId.GOAL_DRIVERS,
  [AIChatBotAnalysisType.HABIT_MOMENTS]: AnalysisTypeId.HABIT_MOMENTS,
  [AIChatBotAnalysisType.USER_JOURNEY]: AnalysisTypeId.USER_JOURNEY,
  [AIChatBotAnalysisType.EVENTS_DISCOVERY]: AnalysisTypeId.EVENTS_DISCOVERY,
  [AIChatBotAnalysisType.POWER_USERS]: AnalysisTypeId.POWER_USERS,
};

export const RunAnalysesRecommendationMessage = (props: AllProps) => {
  const {message} = props;
  const {t} = useTranslation();
  const notify = useAmplitude();
  const {openPrimaryPanel} = useContext(PanelsContext);

  const onClickRunAnalysis = useCallback(() => {
    notify(AmplitudeEvent.AI_CHATBOT_USER_CLICKED_RUN_ANALYSIS, {
      analysisType: message?.content?.analysisType,
    });
    openPrimaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
      [ANALYSIS_TYPE_ID_PATH_PARAM]: AnalysisTypeIdToAITypeMap[message?.content?.analysisType],
    });
  }, [openPrimaryPanel, notify, message]);

  return (
    <div>
      <Button onClick={onClickRunAnalysis}>
        {t(TransKeys.GEN_AI_BOT.RUN_ANALYSIS_RECOMMENDATION_MESSAGE.CTA.LABEL, {
          analysisName: message?.content?.analysisType,
        })}
      </Button>
    </div>
  );
};
