import {useContext, useMemo} from 'react';
import {ColumnType, SmartTableFigureColumn} from '../../../../types';
import {SmartTableContext} from '../smart-table.context';

export const useTableColumn = (
  column: SmartTableFigureColumn<any>
): {column: SmartTableFigureColumn<any>} => {
  const {columnsState} = useContext(SmartTableContext);
  const columnState = useMemo(() => columnsState[column.key], [column.key, columnsState]);

  const actualColumn = useMemo(() => {
    switch (column.type) {
      case ColumnType.SWITCH_COLUMN:
        return column.typeOptions.columns[columnState?.switchColumnIndex || 0];
    }
    return column;
  }, [column, columnState]);

  return {
    column: actualColumn,
  };
};
