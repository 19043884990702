import {useMemo} from 'react';
import {SmartTableFigureColumn} from '../../../../types';
import {TableMutedTest} from '../components/table-muted-text.component';

export type Protector = (props: any) => boolean;

export interface RequiredProps {
  column: SmartTableFigureColumn<any>;
  data: any;
}

export function withDataColumnProtector(protector: Protector) {
  return function dataColumnProtector<P>(
    WrappedComponent: React.ComponentType<P & RequiredProps>
  ): React.ComponentType<P & RequiredProps> {
    return props => {
      const {column} = props;
      const shouldRender = useMemo(() => protector(props), [props]);

      if (!shouldRender) {
        return <TableMutedTest text={column.options.placeholderText || '--'} />;
      }

      return <WrappedComponent {...props} />;
    };
  };
}
