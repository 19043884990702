import {createToastMessage, Model, ToastActionType} from './store.utils';
import {showToastMessage} from './interface/interface.actions';

export const analysisToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('analysis', type);
  return showToastMessage(message.message, message.type);
};

export const experimentToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('experiment', type);
  return showToastMessage(message.message, message.type);
};

export const homepageSubscriptionToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('homepage_subscription', type);
  return showToastMessage(message.message, message.type);
};

export const workflowToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('workflow', type);
  return showToastMessage(message.message, message.type);
};

export const opportunityToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('opportunity', type);
  return showToastMessage(message.message, message.type);
};

export const goalToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('goal', type);
  return showToastMessage(message.message, message.type);
};

export const analysisFolderToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('analysis_folder', type);
  return showToastMessage(message.message, message.type);
};

export const annotationToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('annotation', type);
  return showToastMessage(message.message, message.type);
};

export const featureToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('feature', type);
  return showToastMessage(message.message, message.type);
};

export const contentToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('content', type);
  return showToastMessage(message.message, message.type);
};

export const tableToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('table', type);
  return showToastMessage(message.message, message.type);
};

export const tableEventsToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('table_event', type);
  return showToastMessage(message.message, message.type);
};

export const metricToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('metric', type);
  return showToastMessage(message.message, message.type);
};

export const metricCategoryToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('metric_category', type);
  return showToastMessage(message.message, message.type);
};

export const milestoneToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('milestone', type);
  return showToastMessage(message.message, message.type);
};

export const eventGroupToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('event_group', type);
  return showToastMessage(message.message, message.type);
};

export const experimentAutomationToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('experiment_automation', type);
  return showToastMessage(message.message, message.type);
};

export const segmentToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('segment', type);
  return showToastMessage(message.message, message.type);
};

export const segmentCategoryToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('segment_category', type);
  return showToastMessage(message.message, message.type);
};

export const signalToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('signal', type);
  return showToastMessage(message.message, message.type);
};

export const funnelToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('funnel', type);
  return showToastMessage(message.message, message.type);
};

export const userSettingToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('user_setting', type);
  return showToastMessage(message.message, message.type);
};

export const memberToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('member', type);
  return showToastMessage(message.message, message.type);
};
export const teamToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('team', type);
  return showToastMessage(message.message, message.type);
};
export const sourceToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('source', type);
  return showToastMessage(message.message, message.type);
};

export const reactionToastCreator = (modelType: Model, type: ToastActionType) => {
  const message = createToastMessage(modelType, type);
  return showToastMessage(message.message, message.type);
};

export const shareToastCreator = (type: ToastActionType) => {
  const message = createToastMessage('share', type);
  return showToastMessage(message.message, message.type);
};
