import {EnhancedGroup, EnhancedRect, EnhancedText} from '../../../../../../core/konva/components';
import {TREND_PADDING_TOP} from '../line-chart.component';
import {ChartChevron, CHEVRON_WIDTH} from '../../../components/chart-chevron.component';
import * as React from 'react';
import {useMemo} from 'react';

export interface TrendMarkProps {
  height: number;
  upperXBound: number;
  lowerXBound: number;
  fontName: string;
  trendDescriptionText: string;
  trendDescriptionTextWidth: number;
  trendDescriptionTextColor?: string;
  trendRectColor?: string;
}
type AllProps = TrendMarkProps;

export const TREND_DEFAULT_TEXT = 'CURRENT TREND';
const TREND_MARK_RECT_COLOR = 'rgba(171, 85, 248, 0.5)';
const TREND_DESCRIPTION_COLOR = 'rgba(171, 85, 248, 1)';
const TrendMark: React.FC<AllProps> = (props: AllProps) => {
  const {
    fontName,
    lowerXBound,
    upperXBound,
    height,
    trendDescriptionText,
    trendDescriptionTextWidth,
    trendDescriptionTextColor,
    trendRectColor,
  } = props;

  const middle = useMemo(() => (upperXBound - lowerXBound) / 2, [upperXBound, lowerXBound]);
  const textXPosition = useMemo(
    () => middle - trendDescriptionTextWidth / 2,
    [middle, trendDescriptionTextWidth]
  );

  return (
    <EnhancedGroup x={lowerXBound} y={height}>
      <EnhancedText
        x={textXPosition}
        y={-height - TREND_PADDING_TOP + 16}
        fontFamily={fontName}
        text={trendDescriptionText}
        fill={trendDescriptionTextColor || TREND_DESCRIPTION_COLOR}
      />
      <ChartChevron
        color={trendDescriptionTextColor || TREND_DESCRIPTION_COLOR}
        x={middle}
        y={-height - 8}
      />
      <EnhancedRect
        y={-height}
        width={upperXBound - lowerXBound - 2}
        height={height - 1}
        cornerRadius={7}
        stroke={trendRectColor || TREND_MARK_RECT_COLOR}
        strokeWidth={2}
      />
    </EnhancedGroup>
  );
};

TrendMark.defaultProps = {
  trendDescriptionTextColor: TREND_DESCRIPTION_COLOR,
  trendRectColor: TREND_MARK_RECT_COLOR,
};

export {TrendMark};
