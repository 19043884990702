import * as React from 'react';
import classNames from 'classnames';
import classes from './viewer-options.module.scss';
import {Tooltip} from '@material-ui/core';

export interface ViewerOption {
  onClick?: (e) => void;
  icon?: any;
  text?: string;
  helperText?: string;
  hidden?: boolean;
  active?: boolean;
}

interface OwnProps {
  className?: string;
  title: string;
  options: ViewerOption[];
}

type AllProps = OwnProps;

export const ViewerOptions: React.FC<AllProps> = (props: AllProps) => {
  const {title, options, className} = props;

  return (
    <div className={classNames(classes.ViewerOptions, className)}>
      <div className={classes.Options}>
        <div className={classes.Label}>{title}</div>
        {options.map(
          (o, idx) =>
            !o.hidden && (
              <Tooltip key={idx} placement={'top'} title={o.helperText || ''}>
                <div
                  onClick={o.onClick}
                  className={classNames(classes.Option, o.active && classes.Active)}
                >
                  {o.icon && <o.icon className={classes.Icon} />}
                  {o.text && <div className={classes.Status}>{o.text}</div>}
                </div>
              </Tooltip>
            )
        )}
      </div>
    </div>
  );
};

ViewerOptions.defaultProps = {};
