import {ReactNode, useCallback, useState} from 'react';
import {ChevronDownSolidIcon, ChevronRightSolidIcon} from '../../controls/icons/icons.component';
import classes from './collapsible.module.scss';
import classNames from 'classnames';

type CollapsibleProps = {
  title: ReactNode;
  content: ReactNode;
  isOpen?: boolean;
};

export const Collapsible = (props: CollapsibleProps) => {
  const {title, content} = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => setIsOpen(state => !state), []);
  return (
    <div className={classes.Collapsible}>
      <div className={classes.Header} onClick={handleClick}>
        <ChevronRightSolidIcon className={classNames(classes.Icon, isOpen && classes.IsOpen)} />
        {title}
      </div>
      {isOpen && <div className={classes.Content}>{content}</div>}
    </div>
  );
};
