import {
  ExperimentAutomation,
  ExperimentAutomationType,
} from '../models/experiment-automation.model';
import yup from '../../config/yup.config';
import {values} from 'lodash';

import {querySchemaValidator} from './query-builder.dto.ts';

export type ExperimentAutomationDTO = Partial<ExperimentAutomation>;

export const experimentAutomationDTOValidator = yup.object().shape({
  id: yup.number(),
  name: yup.string().required(),
  type: yup.string().oneOf(values(ExperimentAutomationType)).required(),
  threshold: yup.number().required(),
  keySignalId: yup.number().nullable(),
  keySignalDefinition: querySchemaValidator(),
  keySignalDataType: yup.string().required(),
  variantSignalId: yup.number().nullable(),
  variantSignalDefinition: yup.object().when('type', {
    is: ExperimentAutomationType.AB_TEST,
    then: querySchemaValidator(),
    otherwise: yup.object().nullable(),
  }),
  variantSignalDataType: yup.string().when('type', {
    is: ExperimentAutomationType.AB_TEST,
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  populationFilter: querySchemaValidator(false),
  defaultGoalId: yup.number().required(),
  defaultSecondaryGoalIds: yup.array().of(yup.number()).nullable(),
});
