import {useCallback, useMemo} from 'react';
import {cloneDeep, get, keys, range, set} from 'lodash';
import {QueryBuilderFactory} from 'ui-components';
import {SQL_TEMPLATE_PARAMETER_MAPPING as PARAMETER_MAPPING} from '../query-builders.config';
import {
  METADATA_KEY,
  PARAMETERS_METADATA_KEY,
} from '../../../../../constants/parameters-saved-keys';
import {TableEntity} from '../../../../../objects/models/table.model';
import {BaseSqlBuilder} from './base-custom-sql-builder.component';

const QUERY_BUILDER_NAME = 'CustomSqlQueryBuilder';
export const SQL_PROPERTY_TEMPLATE_NAME = 'custom_sql';

interface OwnProps {
  query: any;
  onChange?: (parameters: any) => void;
  entityContext: TableEntity;
  errors?: any;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const mapper = (value: any) => ({
  table_type: get(value, PARAMETER_MAPPING.table_type),
  table_granularity: get(value, PARAMETER_MAPPING.table_granularity),
  sql_definition: get(value, PARAMETER_MAPPING.sql_definition),
  entity_id_column: get(value, PARAMETER_MAPPING.entity_id_column),
  value_column: get(value, PARAMETER_MAPPING.value_column),
  event_name_column: get(value, PARAMETER_MAPPING.event_name_column),
  timestamp_column: get(value, PARAMETER_MAPPING.timestamp_column),
  dimension_aggregation: get(value, PARAMETER_MAPPING.dimension_aggregation),
  time_aggregation: get(value, PARAMETER_MAPPING.time_aggregation),
  data_type: get(value, PARAMETER_MAPPING.data_type),
  slug: get(value, PARAMETER_MAPPING.slug),
});

export const createSqlPropertyInitialQuery = () => {
  const q = QueryBuilderFactory.createTemplate();
  q.template = SQL_PROPERTY_TEMPLATE_NAME;
  q.parameters = range(12).map(() => undefined);

  q[PARAMETERS_METADATA_KEY] = {
    [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: QUERY_BUILDER_NAME,
  };
  return q;
};

export const CustomSqlQueryBuilder = (props: AllProps) => {
  const {query, errors, onChange, entityContext, disabled, className} = props;

  const mappedValue = useMemo(() => mapper(query), [query]);
  const mappedErrors = useMemo(() => mapper(errors), [errors]);

  const mappedOnChange = useCallback(
    changes => {
      if (disabled) {
        return;
      }
      const newValue = query ? cloneDeep(query) : createSqlPropertyInitialQuery();
      for (const k of keys(changes)) {
        set(newValue, PARAMETER_MAPPING[k], changes[k]);
      }
      newValue[PARAMETERS_METADATA_KEY] = {
        [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: QUERY_BUILDER_NAME,
      };
      onChange(newValue);
    },
    [onChange, query, disabled]
  );

  return (
    <BaseSqlBuilder
      query={mappedValue}
      onChange={mappedOnChange}
      errors={mappedErrors}
      className={className}
      entityContext={entityContext}
      disabled={disabled}
    />
  );
};
