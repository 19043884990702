import {useState, useContext} from 'react';
import {ExperimentConclusionFigure} from '../../../../types/figures.types';
import classes from './experiment-conclusion-viewer.module.scss';
import classNames from 'classnames';
import {
  ArrowRightLightIcon,
  ChevronDownRegularIcon,
  TrophyStarSolidIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {IconButton} from '../../../../../../simple/controls/icon-button/icon-button.component';
import {withStopPropagation} from 'front-core';
import {ExperimentContext} from '../../../../contexts/experiment.context';
import {
  ValidationsIconMap,
  useExperimentValidations,
} from './hooks/use-experiment-validations.hook';
import {HourGlassHalfRegularIcon} from '../../../../../../simple/controls/icons/icons.component';

interface OwnProps extends ExperimentConclusionFigure {
  className?: string;
}

type AllProps = OwnProps;

export const ExperimentConclusionViewer: React.FC<AllProps> = props => {
  const {data} = props;
  const {title, validations} = data;
  const {variants, ...context} = useContext(ExperimentContext);

  // Backward compatibility, we moved the props from data to context
  const winnerVariantKey = data.winnerVariantKey || context.winnerVariantKey;

  const {t} = useDocumentTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const StatusIcon = winnerVariantKey ? TrophyStarSolidIcon : HourGlassHalfRegularIcon;
  const {validationsStatus, validationsSummary, onValidationDeepDive, ValidationsIcon} =
    useExperimentValidations(validations);

  return (
    <div className={classNames(classes.ExperimentConclusionFigure, isOpen && classes.Open)}>
      <div
        className={classNames(classes.Conclusion, winnerVariantKey && classes.HasWinner)}
        onClick={withStopPropagation(() => setIsOpen(!isOpen))}
      >
        <div className={classes.Title}>
          <div className={classes.IconWrapper}>
            <StatusIcon className={classNames(classes.Icon, winnerVariantKey && classes.Winner)} />
            {ValidationsIcon && (
              <ValidationsIcon
                className={classNames(classes.ValidationIcon, classes[validationsStatus])}
              />
            )}
          </div>
          <div className={classes.Label}>
            {winnerVariantKey ? (
              <div className={classes.WinnerVariant}>
                Winner:
                <span className={classes.VariantName}>{variants[winnerVariantKey].name}</span>
              </div>
            ) : (
              title
            )}
          </div>
        </div>
        {validations && (
          <div className={classes.ValidationsSummary}>
            <ValidationsIcon
              className={classNames(classes.ValidationIcon, classes[validationsStatus])}
            />
            <div className={classes.ValidationTitle}>
              {t(
                TransKeys.DOCUMENT_VIEWER.AB_TEST_CONCLUSION_FIGURE.VALIDATIONS.STATUSES[
                  validationsStatus
                ]
              )}
            </div>
            <div className={classes.ValidationDescription}>{validationsSummary}</div>
            <ChevronDownRegularIcon className={classes.CaretIcon} />
          </div>
        )}
      </div>
      {validations && (
        <div className={classes.Validations}>
          {validations.map((validation, index) => {
            const {title, description, status, command} = validation;
            const ValidationIcon = ValidationsIconMap[status];

            return (
              <div key={index} className={classes.Validation}>
                {ValidationIcon && (
                  <div className={classes.Icon}>
                    <ValidationIcon
                      className={classNames(classes.ValidationIcon, classes[status])}
                    />
                  </div>
                )}
                <div className={classes.Title}>{title}</div>
                <div className={classes.Description}>{description}</div>
                {command && (
                  <IconButton
                    icon={ArrowRightLightIcon}
                    onClick={withStopPropagation(() => onValidationDeepDive(command))}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

ExperimentConclusionViewer.defaultProps = {};
