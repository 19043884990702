import {JourneyDriversScopeEvent} from '../../../../../types/figures.types';
export interface IEventsFilters {
  adoptionRange: [number, number];
  eventsFilterMode: FilterEventsMode;
  filteredEvents?: string[];
}

export interface JourneyDriversScopeFilters {
  sequence_filter_event_min_adoption: number;
  sequence_filter_event_max_adoption: number;
  sequence_filter_included_events?: string[];
  sequence_filter_excluded_events?: string[];
}

export interface ExtendedJourneyDriversScopeEvent extends JourneyDriversScopeEvent {
  adoption: number;
  uplift: number;
}

export enum FilterEventsMode {
  INCLUDED = 'included',
  EXCLUDED = 'excluded',
}

export const FILTER_EVENTS_MODE_OPTIONS = [
  {value: FilterEventsMode.INCLUDED, label: 'Include'},
  {value: FilterEventsMode.EXCLUDED, label: 'Exclude'},
];
