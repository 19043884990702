import {Stage as KonvaStage} from 'konva/types/Stage';
import {StageSize, TooltipParams} from './stage.types';
import {removeUndefinedKeys} from 'front-core';

export interface StageController {
  root: HTMLDivElement;
  stage: KonvaStage;
  setOptions: (options: StageControllerOptions) => void;
  goto: (params: {
    x?: number;
    y?: number;
    offsetX?: number;
    offsetY?: number;
    scale?: number;
    duration?: number;
    onFinish?: () => void;
  }) => void;
  zoom: (amount: number, duration?: number) => void;
  getSize: () => StageSize;
  measureTextWidth: (text: string, font: string, size: number, weight?: number) => number;
  createLinearGradient: (
    x0: number,
    y0: number,
    x1: number,
    y1: number,
    steps: Array<[number, string]>
  ) => CanvasGradient;
  setTooltip: (params: TooltipParams | null) => void;
  fixScale: (scale: number) => number;
}

export interface StageControllerOptions {
  minScale?: number;
  maxScale?: number;
  disableZoomOut?: boolean;
}

export class StageControllerImpl implements StageController {
  private localCanvas = document.createElement('canvas');

  constructor(
    public stage: KonvaStage,
    public root: HTMLDivElement | null,
    private tooltipHandler?: (params: TooltipParams) => void,
    private options: StageControllerOptions = {}
  ) {}

  goto(params: {
    x?: number;
    y?: number;
    offsetX?: number;
    offsetY?: number;
    scale?: number;
    duration?: number;
    onFinish?: () => void;
  }): void {
    const {x, y, scale, offsetX, offsetY, duration, onFinish} = params;

    if (!this.stage) {
      return;
    }

    const _scale = this.fixScale(scale || this.stage.scaleX());
    this.stage.to(
      removeUndefinedKeys({
        x: x,
        y: y,
        scaleX: _scale,
        scaleY: _scale,
        offsetX: offsetX,
        offsetY: offsetY,
        duration: duration ? duration : 0,
        onFinish,
      })
    );
  }

  // https://stackoverflow.com/a/62979561/15549815
  zoom(amount: number, duration = 0): void {
    const newScale = this.fixScale(this.stage.scaleX() + amount);
    const oldScale = this.stage.scaleX();

    const center = {
      x: this.stage.width() / 2,
      y: this.stage.height() / 2,
    };
    const relatedTo = {
      x: (center.x - this.stage.x()) / oldScale,
      y: (center.y - this.stage.y()) / oldScale,
    };
    const newPosition = {
      x: center.x - relatedTo.x * newScale,
      y: center.y - relatedTo.y * newScale,
    };

    this.stage.to({
      ...newPosition,
      scaleX: newScale,
      scaleY: newScale,
      duration: duration ? duration : 0,
    });
  }

  getSize(): StageSize {
    return {
      width: this.stage.width(),
      height: this.stage.height(),
    };
  }

  measureTextWidth(text: string, font: string, size: number, weight: number = 500): number {
    const ctx = this.localCanvas.getContext('2d');
    ctx.font = `${weight} ${size}px ${font}`;
    return ctx.measureText(text).width;
  }

  createLinearGradient(
    x0: number,
    y0: number,
    x1: number,
    y1: number,
    steps: Array<[number, string]>
  ): CanvasGradient {
    const ctx = this.localCanvas.getContext('2d');
    const gradient = ctx.createLinearGradient(x0, y0, x1, y1);
    steps.forEach(s => gradient.addColorStop(...s));
    return gradient;
  }

  setTooltip(params: TooltipParams | null): void {
    this.tooltipHandler && this.tooltipHandler(params);
  }

  setOptions(options: StageControllerOptions): void {
    this.options = options;
  }

  fixScale(scale: number) {
    let {disableZoomOut, maxScale, minScale} = this.options;
    minScale = disableZoomOut ? 1 : minScale;
    minScale = minScale || 0.1;
    maxScale = maxScale || 10;
    return Math.min(Math.max(scale, minScale), maxScale);
  }
}
