import {NetworkRequestCreator, HttpClientContext} from 'front-core';
import {useCallback, useContext, useState} from 'react';
import {get} from 'lodash';
import {Extractor} from '../components/forms/inputs.types';

export interface RemoteSourceOptions {
  type?: 'source-list' | 'source';
  networkRequest: NetworkRequestCreator<any, any>;
  transformer?: (data: any) => any;
  requestPayload?: any;
  onError?: (err: any) => any;
  onSuccess?: (data: any) => any;
}

export function extractValue(value, extractor: Extractor | string) {
  if (typeof extractor === 'string') {
    return get(value, extractor);
  }
  if (typeof extractor === 'function') {
    return extractor(value);
  }
}

export const useRemoteSource = (source: RemoteSourceOptions) => {
  const http = useContext(HttpClientContext);
  const [isLoading, setIsLoading] = useState(false);

  const exec = useCallback(
    async (value: any = undefined, requestPayload: any = undefined) => {
      const {type = 'source'} = source;
      const nr = source.networkRequest(value, {
        ...(source.requestPayload || {}),
        ...(requestPayload || {}),
      });
      try {
        setIsLoading(true);
        let res: any = await http.exec(nr);
        setIsLoading(false);
        if (type === 'source-list' && source.transformer) {
          res = res.map(source.transformer);
        }
        if (type === 'source' && source.transformer) {
          res = source.transformer(res);
        }
        if (source?.onSuccess) {
          source.onSuccess(res);
        }
        return res;
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        if (source?.onError) {
          source.onError(e);
        }
        return null;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [http]
  );

  return {
    exec,
    isLoading,
  };
};

export interface RemoteSourceStatedOptions extends RemoteSourceOptions {
  initialValue?: any;
}

export interface UseRemoteSourceStatedReturnType<T> {
  source: T;
  isLoading: boolean;
  exec: (value?: any, requestPayload?: any) => Promise<void>;
  clear: () => void;
}

export const useRemoteSourceStated = <T = any>(
  source: RemoteSourceStatedOptions
): UseRemoteSourceStatedReturnType<T> => {
  const {exec: exec_, isLoading} = useRemoteSource(source);
  const {initialValue} = source;
  const [value, setValue] = useState<T>(source.initialValue);

  const exec = useCallback(
    async (value: any = undefined, requestPayload: any = undefined) => {
      const res = await exec_(value, requestPayload);
      setValue(res);
    },
    [exec_]
  );
  const clear = useCallback(() => setValue(initialValue), [setValue, initialValue]);

  return {
    source: value,
    isLoading,
    exec,
    clear,
  };
};
