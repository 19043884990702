import * as React from 'react';
import classes from './link.module.scss';
import classNames from 'classnames';

interface OwnProps {
  children: any;
  icon?: any;
  onClick: (e) => void;
  className?: string;
  underline?: boolean;
  caps?: boolean;
  disabled?: boolean;
  inline?: boolean;
}

type AllProps = OwnProps;

export const Link: React.FC<AllProps> = (props: AllProps) => {
  const {children, underline, onClick, icon: Icon, disabled, caps, inline, className} = props;

  return (
    <span
      onClick={!disabled ? onClick : undefined}
      className={classNames(
        classes.Link,
        underline && classes.Underline,
        disabled && classes.Disabled,
        caps && classes.Caps,
        inline && classes.Inline,
        className
      )}
    >
      {Icon && <Icon className={classes.Icon} />} {children}
    </span>
  );
};

Link.defaultProps = {
  underline: true,
};
