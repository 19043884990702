import * as React from 'react';
import {useCallback, useContext} from 'react';
import {SentenceFigure, NavigationType, CommandType} from '../../../types';
import {Sentence} from '../../../../sentence';
import {DocumentCommandEmitterContext} from '../../../contexts/document-command-emitter.context';

interface OwnProps extends Omit<SentenceFigure, 'type'> {
  className?: string;
  partClassName?: string;
  boxBackgroundColor?: string;
}

type AllProps = OwnProps;

export const SentenceViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, options, className} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);

  const onPartClicked = useCallback(
    part => {
      if (part.name === 'COMMAND') {
        emitEvent({
          type: part.type,
          payload: {
            documentId: part.parameters[0],
            type: part.parameters[1] || NavigationType.PAGE,
          },
        });
        return;
      }
      if (part.name === 'REF') {
        emitEvent({
          type: CommandType.REF,
          payload: {
            type: part.type,
            refId: part.parameters[0],
          },
        });
      }
    },
    [emitEvent]
  );

  return (
    <div className={className}>
      <Sentence
        text={data}
        size={options.size}
        textCenter={options.textCenter}
        delimiter={options.delimiter}
        onClick={onPartClicked}
      />
    </div>
  );
};

SentenceViewer.defaultProps = {
  options: {},
};
