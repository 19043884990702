import * as React from 'react';
import {BookOpenCoverIcon, BulbIcon} from '../../controls/icons/icons.component';
import {Button} from '../../controls/button/button.component';
import {useLocalStorage} from '../../../../hooks/use-local-storage.hook';
import classes from './see-once-tip-box.module.scss';
import {useCallback} from 'react';
import classNames from 'classnames';

interface OwnProps {
  content: any;
  dismissKey?: string;
  className?: string;
}

type AllProps = OwnProps;

export const TipBox: React.FC<AllProps> = (props: AllProps) => {
  const {dismissKey, content, className} = props;
  const [seen, setSeen] = useLocalStorage('tip-box', {});

  const setSeeOnceTrue = useCallback(
    () => setSeen(prevSeen => ({...prevSeen, [dismissKey]: true})),
    [setSeen, dismissKey]
  );

  if (dismissKey && seen[dismissKey]) {
    return null;
  }

  return (
    <div className={classNames(classes.PromptBox, className)}>
      <div className={classes.IconWrapper}>
        <BookOpenCoverIcon className={classes.Icon} />
      </div>
      <div className={classes.Content}>
        <div className={classes.Text}>{content}</div>
        {dismissKey && (
          <div className={classes.Dismiss}>
            <Button onClick={setSeeOnceTrue} variant={'outlined'}>
              Got it
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
