import * as React from 'react';
import classNames from 'classnames';
import classes from '../smart-table.module.scss';
import {useCallback, useContext, useMemo} from 'react';
import {SmartTableContext} from '../smart-table.context';
import {filterData} from '../logic/filter';
import {groupBy, keys} from 'lodash';
import {TableFilterType} from '../smart-table.types';
import {removeUndefinedKeys} from 'front-core';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

const INTERNAL_FILER_KEY = '__table_group_by__';

export const TableGroupByFilters: React.FC<AllProps> = (props: AllProps) => {
  const {className} = props;
  const {data, filters, options, setFilters} = useContext(SmartTableContext);
  const {groupByDataKey, groupByLabel} = options;
  const filterValue: Set<string> = useMemo(
    () => new Set(filters[INTERNAL_FILER_KEY]?.value || []),
    [filters, groupByDataKey]
  );
  const groups = useMemo(() => {
    if (!groupByDataKey) {
      return [];
    }
    const filteredData = filterData(data, filters, [INTERNAL_FILER_KEY]);
    const groups = groupBy(filteredData, groupByDataKey);
    return keys(groups).map(k => ({
      value: k,
      count: groups[k].length,
    }));
  }, [data, filters, groupByDataKey]);

  const onFilterClicked = useCallback(
    (value: any) => {
      if (filterValue.has(value)) {
        filterValue.delete(value);
      } else {
        filterValue.add(value);
      }
      if (filterValue.size === 0) {
        return setFilters(filters =>
          removeUndefinedKeys({
            ...filters,
            [INTERNAL_FILER_KEY]: undefined,
          })
        );
      }
      setFilters(filters =>
        removeUndefinedKeys({
          ...filters,
          [INTERNAL_FILER_KEY]: {
            type: TableFilterType.LIST,
            value: Array.from(filterValue),
            properties: {dataKey: groupByDataKey},
          },
        })
      );
    },
    [filterValue, groupByDataKey, setFilters]
  );

  if (groups.length <= 1) {
    return null;
  }

  return (
    <div className={classNames(classes.TableGroupByFilters, className)}>
      {groupByLabel && <span className={classes.Label}>{groupByLabel}</span>}
      {groups.map(g => (
        <div
          key={g.value}
          onClick={_ => onFilterClicked(g.value)}
          className={classNames(
            classes.FilterChip,
            filterValue.has(g.value) && classes.Active,
            filterValue.size > 0 && !filterValue.has(g.value) && classes.Out
          )}
        >
          {g.value} ({g.count})
        </div>
      ))}
    </div>
  );
};

TableGroupByFilters.defaultProps = {};
