import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';
import Rainbow from 'rainbowvis.js';
import {useMemo} from 'react';
import {Tooltip} from '@material-ui/core';
import {exists, number2k, safeDivision} from 'front-core';
import TransKeys from 'translations';
import {useTranslation} from '../../../../../../core/translations/use-translation';

interface OwnProps {
  min: number;
  max: number;
  value: number;
  valueUpper?: number;
  valueLower?: number;
  mode?: 'default' | 'range';
  minColor?: string;
  maxColor?: string;
  invalidColor?: string;
  higherIsBetter?: boolean;
  suffix?: string;
  tooltipLabel?: string;
  showDiff?: boolean;
  square?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const DEFAULT_MIN_COLOR = '#8D94AE';
const DEFAULT_MAX_COLOR = '#01C366';

export const GradientLabelDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {
    value,
    valueLower,
    valueUpper,
    max,
    min,
    mode,
    suffix,
    minColor,
    maxColor,
    invalidColor,
    higherIsBetter: higherIsBetter_,
    tooltipLabel,
    showDiff,
    square,
    className,
  } = props;
  const colorGradient = useMemo(() => {
    const colorGradient = new Rainbow();
    colorGradient.setSpectrum(minColor, maxColor);
    return colorGradient;
  }, [minColor, maxColor]);
  const higherIsBetter = useMemo(
    () => (exists(higherIsBetter_) ? higherIsBetter_ : true),
    [higherIsBetter_]
  );
  const bg = useMemo(() => {
    const position = safeDivision(value - min, max - min);
    if (position === null) {
      return min === max ? maxColor : minColor;
    }
    try {
      return `#${colorGradient.colorAt(position * 100)}`;
    } catch (e) {
      return minColor;
    }
  }, [value, max, min, minColor, maxColor]);

  const diff = useMemo(() => {
    if (!showDiff) {
      return null;
    }
    return safeDivision(value - min, min) * 100;
  }, [showDiff, min, value]);

  const isDiffPositive = higherIsBetter ? diff > 0 : diff < 0;

  if ((!exists(valueUpper) || !exists(valueLower)) && mode === 'range') {
    return <>{value ? `${number2k(value)}${suffix}` : '-'}</>;
  }

  let title =
    mode === 'default' && exists(valueLower) && exists(valueUpper)
      ? `${tooltipLabel ? tooltipLabel : ''} ${number2k(valueLower)}${
          suffix ? suffix : ''
        } - ${number2k(valueUpper)}${suffix ? suffix : ''}`
      : '';

  return (
    <Tooltip title={title} placement={'top'}>
      <div className={classNames(classes.GradientLabelDisplay, className)}>
        {mode === 'range' && (
          <>
            <div className={classes.Circle} style={{backgroundColor: invalidColor}} />
            {number2k(valueLower)}
            {suffix} - {number2k(valueUpper)}
            {suffix}
          </>
        )}
        {mode === 'default' && (
          <>
            <div
              className={classNames(classes.Circle, square && classes.Square)}
              style={{backgroundColor: bg}}
            />
            {number2k(value)}
            {suffix}
            {exists(diff) && diff !== 0 && (
              <div
                className={classNames(
                  classes.Diff,
                  !isDiffPositive && classes.Negative,
                  isDiffPositive && classes.Positive
                )}
              >
                ({Math.sign(diff) === 1 && '+'}
                {number2k(diff)}%)
              </div>
            )}
          </>
        )}
      </div>
    </Tooltip>
  );
};

GradientLabelDisplay.defaultProps = {
  mode: 'default',
  minColor: DEFAULT_MIN_COLOR,
  maxColor: DEFAULT_MAX_COLOR,
  invalidColor: DEFAULT_MIN_COLOR,
};
