import {useCallback, useMemo, useRef} from 'react';
import {
  BuildingsLightIcon,
  DialogLayout,
  DropdownButton,
  PopoverWrapper,
  TeamIconChip,
  UserSolidIcon,
} from 'ui-components';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {Team} from '../../../../../../objects/models/team.model';
import {Divider, Tooltip} from '@material-ui/core';
import classes from './homepage-selector.module.scss';
import classNames from 'classnames';
import {useCurrentUser} from '../../../../../../core/hooks/use-user.hook';
import {User} from '../../../../../../objects/models/user.model';
import {noop} from 'lodash';

type HomepageSelectorProps = {
  onChange?: (value: number) => void;
  selectedHomepageId: number;
  viewOnly?: boolean;
};

export const HomepageSelector = (props: HomepageSelectorProps) => {
  const {onChange, viewOnly, selectedHomepageId} = props;

  const selectorRef = useRef<any>();
  const user: User = useCurrentUser();
  const {teams, actualTeams, userHomepageId, name: companyName} = useProductData();

  const defaultTeam: Team = useMemo(() => teams.find(t => t.isDefaultTeam), [teams]);

  const onSelect = useCallback(
    (hmId: number) => {
      onChange(hmId);
      selectorRef.current?.close();
    },
    [onChange, selectorRef]
  );

  const rootOptionsList = useMemo(() => {
    const options = [];

    options.push({
      label: `${user.firstName} ${user.lastName} (you)`,
      id: userHomepageId,
      onSelect: () => onSelect(userHomepageId),
      icon: (
        <div className={classNames(classes.IconChip, classes.UserIconChip)}>
          <UserSolidIcon />
        </div>
      ),
    });

    if (defaultTeam?.homepageId) {
      options.push({
        label: (
          <div>
            <span className={classes.CompanyName}>{companyName}</span> - All company
          </div>
        ),
        id: defaultTeam.homepageId,
        onSelect: () => onSelect(defaultTeam.homepageId as unknown as number),
        icon: (
          <div className={classes.IconChip}>
            <BuildingsLightIcon />
          </div>
        ),
      });
    }
    return options;
  }, [defaultTeam, userHomepageId, user, companyName, onSelect]);

  const teamsHomepageList = useMemo(
    () =>
      actualTeams.map(team => ({
        id: team.homepageId,
        label: team.name,
        onSelect: () => onSelect(team.homepageId),
        icon: <TeamIconChip name={team.name} color={team.color} />,
      })),
    [actualTeams, onSelect]
  );

  const selectedItem = useMemo(
    () =>
      [...teamsHomepageList, ...rootOptionsList].find(option => option.id === selectedHomepageId),
    [teamsHomepageList, rootOptionsList, selectedHomepageId]
  );

  const label = useMemo(() => {
    if (selectedItem.id === userHomepageId) {
      return (
        <>
          {selectedItem?.icon}
          {user.firstName}
        </>
      );
    }
    if (selectedItem.id === defaultTeam?.homepageId) {
      return <>{selectedItem?.icon} All company</>;
    }
    return <>Team {selectedItem?.icon}</>;
  }, [selectedItem, defaultTeam?.homepageId, user.firstName, userHomepageId]);

  if (viewOnly) {
    return (
      <Tooltip title={selectedItem?.label}>
        <div>
          <DropdownButton
            onClick={noop}
            hideArrow
            className={classNames(classes.DropdownButton, classes.ViewOnly)}
            label={<div className={classes.SelectedOption}>{label}</div>}
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <PopoverWrapper
      ref={selectorRef}
      placement="bottom"
      popoverDirection="left"
      buttonRenderer={props => (
        <Tooltip title={selectedItem?.label}>
          <div>
            <DropdownButton
              {...props}
              className={classes.DropdownButton}
              label={<div className={classes.SelectedOption}>{label}</div>}
            />
          </div>
        </Tooltip>
      )}
    >
      <DialogLayout>
        {rootOptionsList.map(rootOptionItem => {
          return (
            <div
              onClick={rootOptionItem.onSelect}
              className={classes.Item}
              data-selected={selectedHomepageId === rootOptionItem.id}
            >
              {rootOptionItem.icon}
              {rootOptionItem.label}
            </div>
          );
        })}
        {teamsHomepageList?.length > 0 && (
          <>
            <Divider />
            <div className={classes.TeamsTitle}>Teams</div>
            {teamsHomepageList.map(teamHomepageListItem => (
              <div
                className={classes.Item}
                onClick={teamHomepageListItem.onSelect}
                data-selected={selectedHomepageId === teamHomepageListItem.id}
              >
                {teamHomepageListItem.icon}
                {teamHomepageListItem.label}
              </div>
            ))}
          </>
        )}
      </DialogLayout>
    </PopoverWrapper>
  );
};
