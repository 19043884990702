import * as React from 'react';
import classNames from 'classnames';
import classes from './filters.module.scss';

interface OwnProps {
  label: string;
  children: any;
  clearable?: boolean;
  onClear?: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const FilterWrapper: React.FC<AllProps> = (props: AllProps) => {
  const {label, children, clearable, onClear, className} = props;

  return (
    <div className={classNames(classes.FilterWrapper, className)}>
      <div className={classes.Header}>
        <div className={classes.Label}>{label}</div>
        <div className={classes.Actions}>
          {clearable && onClear && (
            <span className={classes.ClearButton} onClick={_ => onClear()}>
              clear
            </span>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

FilterWrapper.defaultProps = {};
