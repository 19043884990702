import * as React from 'react';
import classNames from 'classnames';
import classes from './dialog-layout.module.scss';

interface OwnProps {
  children: any;
  className?: string;
}

type AllProps = OwnProps;

export const DialogLayout: React.FC<AllProps> = (props: AllProps) => {
  const {children, className} = props;

  return <div className={classNames(classes.DialogLayout, className)}>{children}</div>;
};

DialogLayout.defaultProps = {};
