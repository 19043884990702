import {useContext, useMemo, useState} from 'react';
import {ChildRenderer} from '../../core/child-renderer.component';
import {DocumentElementType, Section, SectionStatus} from '../../../types';
import classes from './section-viewer.module.scss';
import {ChildRendererInjectedProps} from '../../../document-viewer.types';
import classNames from 'classnames';
import {
  ChevronDownIcon,
  EllipsisIcon,
  CircleInfoRegularIcon,
} from '../../../../../simple/controls/icons/icons.component';
import {DocumentIcon} from '../../internal-viewers/document-icon.component';
import {Tooltip} from '@material-ui/core';
import {ActionsDropdown} from '../../../../../simple/controls/actions-dropdown/actions-dropdown.component';
import {IconButton} from '../../../../../simple/controls/icon-button/icon-button.component';
import {get} from 'lodash';
import {sanitize} from 'front-core';
import {useDocumentTracking} from '../../../hooks/use-document-tracking.hook';
import {DocumentCommandEmitterContext} from '../../../contexts/document-command-emitter.context';

export interface OwnProps extends Section, Partial<ChildRendererInjectedProps> {
  className?: string;
}

export const SectionViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {id, slug, title, commands = [], options, className} = props;
  const {icon, startClosed, status: statusFromOptions, helperText, muted} = options;
  const {trackItemToggled} = useDocumentTracking(id, DocumentElementType.SECTION);
  const [isOpen, setIsOpen_] = useState(!startClosed);
  const {emitEvent} = useContext(DocumentCommandEmitterContext);

  const setIsOpen = (isOpen: boolean) => {
    trackItemToggled(isOpen, {title});
    setIsOpen_(isOpen);
  };

  const actions = useMemo(
    () =>
      commands.map(c => ({
        title: get(c.payload, 'text'),
        onClick: () => emitEvent(c),
      })),
    [commands]
  );

  // todo: remove me, this is backward comp, once changed in analyses
  const status = useMemo(
    () => (muted ? SectionStatus.NOT_MEANINGFUL : statusFromOptions),
    [statusFromOptions, muted]
  );

  const helperTextRender = useMemo(
    () =>
      helperText ? <div dangerouslySetInnerHTML={{__html: sanitize(helperText)}} /> : undefined,
    [helperText]
  );

  return (
    <div
      className={classNames(
        classes.SectionViewer,
        isOpen && classes.Open,
        classes[status],
        className
      )}
    >
      <div id={slug || id} className={classes.Header}>
        <IconButton
          className={classes.DDIcon}
          onClick={() => setIsOpen(!isOpen)}
          icon={ChevronDownIcon}
          border={false}
        />
        {icon && <DocumentIcon icon={icon} className={classes.SectionIcon} />}
        <div className={classes.Title} onClick={() => setIsOpen(!isOpen)}>
          {title}
        </div>
        {helperTextRender && (
          <Tooltip title={helperTextRender} interactive placement={'top'}>
            <CircleInfoRegularIcon className={classes.HelperTextIcon} />
          </Tooltip>
        )}
        {actions.length > 0 && (
          <ActionsDropdown
            className={classes.Actions}
            actions={actions}
            buttonComponent={props => (
              <IconButton
                className={classes.DDIcon}
                icon={EllipsisIcon}
                border={false}
                {...props}
              />
            )}
          />
        )}
      </div>
      <div className={classes.Content}>
        <ChildRenderer children_={props.children} />
      </div>
    </div>
  );
};

SectionViewer.defaultProps = {
  options: {},
};
