export enum ArithmeticFormulaOP {
  ADD = '+',
  SUB = '-',
  // div cannot be last! (it is used as a regex character)
  DIV = '/',
  MUL = '*',
}

export interface ArithmeticFormulaBuilderVariable {
  name: string;
  value: number | string;
}

export interface ArithmeticFormulaBuilderProps {
  value?: string;
  onChange: (value: string) => void;
  variables?: ArithmeticFormulaBuilderVariable[];
  variableName?: string;
}
