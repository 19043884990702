import {useCallback, useMemo} from 'react';
import {debounce, groupBy, values} from 'lodash';
import * as React from 'react';
import {BaseDataset} from '../../charts/chart-data.types';

const DOUBLE_CLICK_EVENT = 2;

interface UseChartLegendProps {
  setDisplayedDatasetIds: (datasetIds: string[]) => void;
  displayedDatasetIds: string[];
  datasets: BaseDataset[];
}

export const useChartLegend = (props: UseChartLegendProps) => {
  const {setDisplayedDatasetIds, displayedDatasetIds, datasets} = props;
  const datasetLabels = useMemo(
    () =>
      values(
        groupBy(
          datasets.map(d => ({
            datasetId: d.id,
            name: d.label,
            isActive: displayedDatasetIds.indexOf(d.id) > -1,
            color: d.color,
          })),
          'datasetId'
        )
      ).map(d => d[0]),
    [displayedDatasetIds, datasets]
  );

  const onLegendClick = useCallback(
    debounce((e: React.MouseEvent<HTMLElement>, datasetId: number | string) => {
      const ds = datasets.find(ds => ds.id === datasetId);
      const currentDisplayedDatasets = new Set(
        e?.detail === DOUBLE_CLICK_EVENT
          ? []
          : displayedDatasetIds.length
            ? displayedDatasetIds
            : []
      );
      if (currentDisplayedDatasets.has(ds.id)) {
        currentDisplayedDatasets.delete(ds.id);
      } else {
        currentDisplayedDatasets.add(ds.id);
      }
      setDisplayedDatasetIds(
        currentDisplayedDatasets.size === 0
          ? datasets.map(d => d?.id)
          : Array.from(currentDisplayedDatasets)
      );
    }, 250),
    [setDisplayedDatasetIds, displayedDatasetIds, datasets]
  );

  return {
    datasetLabels,
    onLegendClick,
  };
};
