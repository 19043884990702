import {RemoteListConfig} from '../../store/remote-lists/remote-list.types';
import {useDispatch, useSelector} from 'react-redux';
import {JsonParam, useQueryParams} from 'use-query-params';
import {getListsSelector, getReducedLoadingStateSelector} from '../../store/store.selectors';
import {useCallback, useEffect, useMemo} from 'react';
import {get, isEmpty, keys, pick, set} from 'lodash';
import {
  appendList,
  createList,
  removeList,
  replaceList,
} from '../../store/remote-lists/remote-list.actions';
import {getNextSort, removeUndefinedKeys} from 'front-core';

export interface UseRemoteListConfig {
  config: RemoteListConfig;
  filtersDef?: any[];
  filterKeys?: string[];
  defaultFilters?: any;
  itemsPerPage?: number;
  syncQueryFilters?: boolean;
  // propagated to appendList and replaceList actions
  switchRequest?: boolean;
}

const DEFAULT_FILTERS = {};

export const useRemoteList = (props: UseRemoteListConfig) => {
  const {
    defaultFilters = DEFAULT_FILTERS,
    filtersDef,
    filterKeys: filterKeysFromConfig,
    config,
    syncQueryFilters,
    itemsPerPage: itemsPerPageFromProps,
    switchRequest,
  } = props;
  const dispatch = useDispatch();
  const [query, setQuery] = useQueryParams({filters: JsonParam});
  const listsData = useSelector(state => getListsSelector(config.listKey)(state)[config.listKey]);
  const filters = useMemo(() => listsData?.query || {}, [listsData]);
  const isLoading = useSelector(state => getReducedLoadingStateSelector(config.listKey)(state));
  const filterKeys = useMemo(
    () => filterKeysFromConfig ?? filtersDef?.map(f => f.key) ?? [],
    [filterKeysFromConfig, filtersDef]
  );
  const itemsPerPage = useMemo(
    () => itemsPerPageFromProps || get(defaultFilters, 'itemsPerPage', 10),
    [defaultFilters, itemsPerPageFromProps]
  );
  const replaceList_ = useCallback(
    newFilters => dispatch(replaceList(config.listKey, newFilters, 'replace', switchRequest)),
    [dispatch, config, switchRequest]
  );
  const getQueryFilters = useCallback(
    filters => {
      const queryFilters = pick(removeUndefinedKeys(filters), filterKeys);
      for (const k of keys(defaultFilters)) {
        if (queryFilters[k] === defaultFilters[k]) {
          delete queryFilters[k];
        }
      }
      return queryFilters;
    },
    [filterKeys, defaultFilters]
  );
  const onFiltersChange = useCallback(
    requestedFilters => {
      const undefinedFilters = filterKeys.reduce(
        (acc, curr) => set(acc, curr, defaultFilters[curr]),
        {}
      );
      const overrideFilters = {page: 1};
      const newFilters = {
        ...defaultFilters,
        ...filters,
        ...undefinedFilters,
        ...requestedFilters,
        ...overrideFilters,
      };
      const queryFilters = getQueryFilters(newFilters);
      syncQueryFilters &&
        setQuery({filters: isEmpty(queryFilters) ? undefined : queryFilters}, 'replaceIn');
      replaceList_(removeUndefinedKeys(newFilters));
    },
    [filterKeys, replaceList_, defaultFilters, filters, getQueryFilters, setQuery, syncQueryFilters]
  );
  const onLoadMore = useCallback(() => {
    listsData.meta?.hasNext &&
      dispatch(
        appendList(config.listKey, {page: listsData.meta?.page + 1}, 'append', switchRequest)
      );
  }, [dispatch, listsData, config, switchRequest]);
  const onPageChange = useCallback(
    (page: number) => {
      dispatch(replaceList(config.listKey, {page}, 'append', switchRequest));
    },
    [dispatch, config, switchRequest]
  );
  const onRefresh = useCallback(() => replaceList_(filters), [replaceList_, filters]);
  const onSort = useCallback(
    (
      key: string,
      notify: boolean = true
    ): {
      order: string;
      orderBy: string;
    } => {
      const {order, orderBy} = getNextSort(key, filters);
      notify && onFiltersChange({...filters, order, orderBy});
      return {order, orderBy};
    },
    [onFiltersChange, filters]
  );

  useEffect(() => {
    dispatch(createList(config));
    return () => {
      dispatch(removeList(config.listKey));
    };
  }, [dispatch, config]);

  useEffect(() => {
    const filtersFromQuery = removeUndefinedKeys(query.filters);
    const allFilters = {
      ...defaultFilters,
      ...filtersFromQuery,
      itemsPerPage,
      page: 1,
    };
    dispatch(replaceList(config.listKey, allFilters, 'replace', switchRequest));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, defaultFilters, config, itemsPerPage]);

  return {
    listsData,
    filters,
    isLoading,
    onFiltersChange,
    replaceList: replaceList_,
    onLoadMore,
    onPageChange,
    onRefresh,
    onSort,
  };
};
