import {TrendCompositeSegments} from '../../../types';
import {ExtendedTrendCompositeSegments} from './trend-composite-segments-viewer.component';
import {safeDivision} from 'front-core';

export const extendTrendCompositeSegments = (
  composites: TrendCompositeSegments[],
  totalEntities: number,
  expectedTotalEntities: number
): ExtendedTrendCompositeSegments[] => {
  return composites.map(c => ({
    ...c,
    key: `${c.segments.map(s => `${s.name}_${s.signalId}`).join('_')}`,
    share: safeDivision(c.count, totalEntities),
    expectedShare: safeDivision(c.expectedCount, expectedTotalEntities),
    uplift: safeDivision(c.value - c.expectedValue, c.expectedValue),
    segments: c.segments.map(s => ({
      ...s,
      share: safeDivision(s.count, totalEntities),
      uplift: safeDivision(s.value - s.expectedValue, s.expectedValue),
      expectedShare: safeDivision(s.expectedCount, expectedTotalEntities),
    })),
  }));
};
