import {NRC} from './index';
import {RequestType, toSnakeCase} from 'front-core';
import {
  ValidateAnalysisTimeframeDTO,
  ValidateSignalBoundingDaysRequestDTO,
  ValidateNameDTO,
  ValidateSqlDTO,
  ValidateRootCauseAnalysisSignalTimeframeDTO,
} from '../objects/dto/validations.dto';
import {SignalDataType, SignalType} from '../objects/models/signal.model.ts';
import {MilestoneType} from '../objects/models/milestone.model.ts';

export const validateNameNetworkRequest: NRC<ValidateNameDTO, void> = (
  payload: ValidateNameDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/name`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateSqlNetworkRequest: NRC<ValidateSqlDTO, void> = (payload: ValidateSqlDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/sql`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: (response: any) => response.data,
});

export const validateAnalysisTimeframeRequest: NRC<ValidateAnalysisTimeframeDTO, void> = (
  payload: ValidateAnalysisTimeframeDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/analysis-signal-timeframe`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateSignalBoundingDaysRequest: NRC<ValidateSignalBoundingDaysRequestDTO, void> = (
  payload: ValidateSignalBoundingDaysRequestDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/signal-bounding-days`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateSignalIdsNetworkRequest: NRC<any, void> = (ids: number[]) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/validate-signal-ids`,
  body: {
    signalIds: ids,
  },
  bodyTransformer: toSnakeCase,
});

export const validateRootCauseAnalysisSignalTimeframeRequest: NRC<
  ValidateRootCauseAnalysisSignalTimeframeDTO,
  void
> = (payload: ValidateRootCauseAnalysisSignalTimeframeDTO) => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/root-cause-analysis-signal-timeframe`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateMetricDefinitionNetworkRequest: NRC<
  {definition: any; entity: any; metricType: any},
  void
> = payload => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/metric-definition`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateSegmentDefinitionNetworkRequest: NRC<
  {definition: any; entity: any; signalDataType: SignalDataType; signalType: SignalType},
  void
> = payload => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/segment-definition`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateMilestoneDefinitionNetworkRequest: NRC<
  {definition: any; entity: any; milestoneType: MilestoneType},
  void
> = payload => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/milestone-definition`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateEventGroupDefinitionNetworkRequest: NRC<
  {definition: any; entity: any; signalDataType: SignalDataType},
  void
> = payload => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/event-group-definition`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateFeatureDefinitionNetworkRequest: NRC<
  {definition: any; entity: any},
  void
> = payload => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/feature-definition`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateContentDefinitionNetworkRequest: NRC<
  {definition: any; entity: any; signalDataType: SignalDataType},
  void
> = payload => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/content-definition`,
  body: payload,
  bodyTransformer: toSnakeCase,
});

export const validateFunnelDefinitionNetworkRequest: NRC<
  {definition: any; entity: any},
  void
> = payload => ({
  method: RequestType.POST,
  relativeUrl: `/app/validations/funnel-definition`,
  body: payload,
  bodyTransformer: toSnakeCase,
});
