import * as React from 'react';
import {useMemo} from 'react';
import {exists} from 'front-core';
import classes from './bold-term.module.scss';

interface OwnProps {
  className?: string;
  children: string;
  term: string;
  caseSensitive?: boolean;
}

type AllProps = OwnProps;

export const BoldTerm: React.FC<AllProps> = (props: AllProps) => {
  const {className, children, term, caseSensitive} = props;

  const render = useMemo(() => {
    if (!exists(term)) {
      return children;
    }
    let parts;
    if (caseSensitive) {
      parts = children.split(term);
    } else {
      parts = children.toLowerCase().split(term.toLowerCase());
    }
    if (parts.length === 1) {
      return children;
    }
    let ccc = 0;
    return parts.map((p, idx) => {
      const x = (
        <React.Fragment key={idx}>
          {children.substr(ccc, p.length)}
          {idx < parts.length - 1 && (
            <span className={classes.Highlight}>
              {children.substr(ccc + p.length, term.length)}
            </span>
          )}
        </React.Fragment>
      );
      ccc += p.length + term.length;
      return x;
    });
  }, [children, term]);

  return <span className={className}>{render}</span>;
};

BoldTerm.defaultProps = {
  caseSensitive: false,
};
