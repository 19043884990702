import {chartClasses} from '../../complex/charts/chart.consts';
import {EmptyState} from '../data-display/empty-state/empty-state.component';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from 'react';

export function ErrorFallback({error = undefined, resetErrorBoundary = undefined} = {}) {
  return (
    <div className={chartClasses.ErrorState}>
      <EmptyState
        background={false}
        image={WarningIcon}
        title={'Sorry, something went wrong'}
        subTitle={'Internal error, the view could not be presented at this moment'}
      />
    </div>
  );
}
