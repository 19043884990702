import * as React from 'react';
import classNames from 'classnames';
import {queryBuilderClasses} from '../query-builder.component';
import {IconButton} from '../../../simple/controls/icon-button/icon-button.component';

export const InlineToken: React.FC<any> = ({children, className}) => (
  <span className={classNames(queryBuilderClasses.InlineToken, className)}>{children}</span>
);

export const MutedText: React.FC<any> = ({children, className}) => (
  <span className={classNames(queryBuilderClasses.MutedText, className)}>{children}</span>
);

export const InlineText: React.FC<any> = ({children, className}) => (
  <span className={classNames(queryBuilderClasses.InlineText, className)}>{children}</span>
);

export const QueryBuilderIconButton = props => (
  <IconButton {...props} className={classNames(queryBuilderClasses.IconButton, props.className)} />
);
