import * as React from 'react';
import classNames from 'classnames';
import {Protector, withDataColumnProtector} from '../../hoc/data-column-protector.hoc';
import {CheckIcon, CloseIcon} from '../../../../../../../simple/controls/icons/icons.component';
import classes from './data-columns.module.scss';
import {useMemo} from 'react';
import {DataColumnProps} from '../../smart-table.types';

interface OwnProps extends DataColumnProps<void> {}

type AllProps = OwnProps;

const BooleanDataColumnComponent: React.FC<AllProps> = (props: AllProps) => {
  const {column, data, className} = props;

  const value = useMemo(() => data[column.key], [column.key, data]);
  return (
    <div className={classNames(classes.BooleanDataColumn, className)}>
      {value ? <CheckIcon className={classes.Icon} /> : <CloseIcon className={classes.Icon} />}
    </div>
  );
};

const protector: Protector = (props: OwnProps) =>
  props.data[props.column.key] === true || props.data[props.column.key] === false;
export const BooleanDataColumn = withDataColumnProtector(protector)(BooleanDataColumnComponent);
