import * as React from 'react';
import classes from './filters.module.scss';
import {FilterWrapper} from './filter-wrapper.component';
import {useCallback, useEffect, useMemo} from 'react';
import {FilterComponentProps} from './filter-component.types';
import classNames from 'classnames';
import {Range} from 'react-input-range';
import {isEqual} from 'lodash';
import {TableFilterType} from '../../smart-table.types';
import {fixedMaxValue, fixedMinValue} from '../../smart-table.utils';
import {NumberRangeSliderInput} from '../../../../../../../forms/inputs/number-range-slider-input/number-range-slider-input.component';

interface OwnProps extends FilterComponentProps {
  formatLabel?: (v, unit?: string) => string;
  dataKey: string;
  unit?: string;
  filterType?: TableFilterType;
  className?: string;
}

type AllProps = OwnProps;

export const NumberRangeFilter: React.FC<AllProps> = (props: AllProps) => {
  const {
    label,
    filteredData,
    filter,
    onChange: onChangeFromProps,
    onClear,
    formatLabel,
    dataKey,
    filterKey,
    unit,
    filterType: filterTypeFromProps,
    className,
  } = props;
  const value = filter?.value;
  const filterData = useMemo(
    () => filteredData.map(item => item[dataKey]),
    [dataKey, filteredData]
  );
  const minValue = useMemo(() => fixedMinValue(filterData), [filterData]);
  const maxValue = useMemo(() => fixedMaxValue(filterData), [filterData]);

  const filterType = filterTypeFromProps || TableFilterType.NUMBER_RANGE;
  const clearable = useMemo(
    () => value && (value.min !== minValue || value.max !== maxValue),
    [maxValue, minValue, value]
  );

  useEffect(() => {
    if (isEqual(value, {min: minValue, max: maxValue})) {
      onClear(dataKey);
    }
  }, [onClear, maxValue, minValue, value, dataKey]);

  const onChange = useCallback(
    value => {
      onChangeFromProps(filterKey, {
        type: filterType,
        value: value,
        properties: {dataKey},
      });
    },
    [filterKey, onChangeFromProps, filterType, dataKey]
  );
  const stepSize = useMemo(() => {
    const diff = Math.abs((value?.max || maxValue) - (value?.min || minValue));
    if (diff < 5) {
      return 0.1;
    }
    if (diff < 0.1) {
      return 0.01;
    }
    return 1;
  }, [value, minValue, maxValue]);

  if (maxValue === minValue) {
    return null;
  }

  return (
    <FilterWrapper
      clearable={clearable}
      onClear={() => onClear(dataKey)}
      label={`${label}`}
      className={classNames(classes.NumberRangeFilter, className)}
    >
      <div className={classNames(classes.RangeWrapper, !clearable && classes.HideRange)}>
        <NumberRangeSliderInput
          maxValue={maxValue}
          minValue={minValue}
          onChange={value => onChange(value as Range)}
          step={stepSize}
          // @ts-ignore
          value={value}
          //@ts-ignore
          formatLabel={v => formatLabel(v, unit)}
        />
      </div>
    </FilterWrapper>
  );
};

NumberRangeFilter.defaultProps = {
  formatLabel: (v, unit) => `${v.toLocaleString()}${unit ? unit : ''}`,
};
