import {ColumnType, CommandType, SmartTableFigureColumn} from '../../../types';
import {
  DateIcon,
  DollarIcon,
  ExternalLink,
  HashtagIcon,
  HeaderIcon,
  HSquareIcon,
  CircleInfoRegularIcon,
  PercentageIcon,
  PieChartIcon,
  TachometerIcon,
  TimesSquareIcon,
} from '../../../../../simple/controls/icons/icons.component';
import {flatten, get, isArray, max, min} from 'lodash';
import {createComparator} from 'front-core';

export function getIconForColumn(column: SmartTableFigureColumn<any>): any {
  if (column.options.unit === '%') {
    return PercentageIcon;
  }
  if (column.options.unit === '$') {
    return DollarIcon;
  }

  switch (column.asType || column.type) {
    case ColumnType.NUMERIC:
    case ColumnType.VERSUS:
    case ColumnType.GRADIENT:
      return HashtagIcon;
    case ColumnType.CHANGE:
      return PercentageIcon;
    case ColumnType.INFO:
      return CircleInfoRegularIcon;
    case ColumnType.LABEL_VALUE:
      return HeaderIcon;
    case ColumnType.PROGRESS:
      return PieChartIcon;
    case ColumnType.CONFIDENCE_INTERVAL:
      return TachometerIcon;
    case ColumnType.BOOLEAN:
      return TimesSquareIcon;
    case ColumnType.DATE:
      return DateIcon;
    default:
      return HSquareIcon;
  }
}

export const getDataValueKey = (column: SmartTableFigureColumn<any>, typeOptionsKey: string) => {
  return column.typeOptions[typeOptionsKey] || column.key;
};

export const uniqueValues = arr => Array.from(new Set(arr));

const mapValue = (d: any, placeholderText: string = null) => ({
  label: d?.toString() || placeholderText || 'Empty',
  value: d,
});

export const getColumnOptions = (data: any[], key: string, placeholderText?: string): any[] =>
  uniqueValues(
    flatten(Array.from(data.map(d => get(d, key)).filter(v => v !== null))).sort(createComparator())
  ).map(d =>
    isArray(d) ? d.map(d => mapValue(d, placeholderText)) : mapValue(d, placeholderText)
  );

export const fixedMinValue = (values: number[]) =>
  values.length > 0 ? Math.floor(min(values)) : null;
export const fixedMaxValue = (values: number[]) =>
  values.length > 0 ? Math.ceil(max(values)) : null;
