import * as React from 'react';
import classes from '../chart.module.scss';
import {useEffect, useRef, useState} from 'react';
import {getTopPosition, getLeftPosition} from '../chart.utils';

export interface TooltipData {
  label?: string;
  value?: string | JSX.Element;
  datasetName?: string;
  color?: string;
}

interface OwnProps {
  x: number;
  y: number;
  show?: boolean;
  style?: any;
  data?: TooltipData;
  rootSize: {width: number; height: number};
}

type AllProps = OwnProps;

export const ChartTooltip: React.FC<AllProps> = (props: AllProps) => {
  const {x, y, show, style, data: dataFromProps, rootSize} = props;
  const [data, setData] = useState(dataFromProps);
  const tooltipRef = useRef<HTMLDivElement>();

  useEffect(() => {
    // make sure the data is not disappear when fade out
    if (dataFromProps === null) {
      return;
    }
    setData(dataFromProps);
  }, [dataFromProps]);

  // We are doing so in render because of race condition using useMemo
  const left = getLeftPosition(tooltipRef.current, x, rootSize?.width);
  const top = getTopPosition(tooltipRef.current, y, rootSize?.height);

  return (
    <div
      ref={tooltipRef}
      style={{
        top: top,
        left,
        opacity: show ? 1 : 0,
        fontFamily: style.fontName,
      }}
      className={classes.Tooltip}
    >
      {data && (
        <>
          <div className={classes.Top}>
            {data.label && <div className={classes.Label}>{data.label}</div>}
            {data.datasetName && (
              <div className={classes.Dataset}>
                {data.color && (
                  <div className={classes.Dot} style={{backgroundColor: data.color}} />
                )}
                <div className={classes.DatasetName}>{data.datasetName}</div>
              </div>
            )}
          </div>
          <div className={classes.Content}>{data.value}</div>
        </>
      )}
    </div>
  );
};
