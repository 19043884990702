import * as React from 'react';
import {RawQuery, SqlElement} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {useMemo} from 'react';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {QueryBlock} from '../components/query-block/query-block.component';
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CopyIcon,
} from '../../../simple/controls/icons/icons.component';
import {useQueryArray, useQueryElement} from '../query-builder.hooks';
import {get} from 'lodash';
import classes from '../query-builder.module.scss';

interface OwnProps {
  data: RawQuery;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const RawQueryBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled} = props;
  const {localErrors, actions} = useQueryElement(props);
  const dependencies = useMemo(() => data.dependencies || [], [data]);
  const {addElement, cloneElement, moveElement} = useQueryArray(dependencies, data =>
    onChange(concatPath(path, 'dependencies'), data)
  );

  const renderElement = (e: SqlElement, idx: number) => {
    const actions = [
      {
        icon: ArrowUpIcon,
        label: 'Up',
        onClick: () => moveElement(idx, idx - 1),
        hidden: idx === 0,
      },
      {
        icon: ArrowDownIcon,
        label: 'Down',
        onClick: () => moveElement(idx, idx + 1),
        hidden: idx === dependencies.length - 1,
      },
      {
        icon: CopyIcon,
        label: 'Duplicate',
        onClick: () => cloneElement(idx),
      },
    ];

    return (
      <QueryElementBuilder
        key={idx}
        actions={actions}
        error={Boolean(get(localErrors, `dependencies.${idx}`))}
        path={concatPath(path, `dependencies.${idx}`)}
        data={e as any}
      />
    );
  };

  return (
    <QueryTree label={'Raw Query'} actions={actions} disabled={disabled} className={className}>
      <QueryBlock label={`Query`} disabled={disabled} collapsible>
        <textarea
          className={classes.Textarea}
          value={data.value}
          onChange={e => onChange(concatPath(path, 'value'), e.target.value)}
          disabled={disabled}
        />
      </QueryBlock>
      <QueryTree
        label={'Dependencies'}
        disabled={disabled}
        actions={[
          {
            icon: AddIcon,
            label: 'Add',
            onClick: addElement,
          },
        ]}
      >
        {data.dependencies.map((e, idx) => renderElement(e, idx))}
      </QueryTree>
    </QueryTree>
  );
};
