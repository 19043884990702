import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {QueryBlock} from '../components/query-block/query-block.component';
import {Condition, ConditionOperator, SqlElementType} from '../query-builder.types';
import {InlineToken} from '../components/parts';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {values} from 'lodash';
import {Select} from '../../../forms/inputs/select/select.component';
import {queryBuilderClasses} from '../query-builder.component';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {concatPath, QueryBuilderFactory} from '../query-builder.utils';
import {ConditionOperatorName} from '../query-builder.config';
import {useQueryElement} from '../query-builder.hooks';
import {withViewMode} from '../core/with-view-mode.hoc';
import {QueryAction} from '../components/query-actions/query-actions.component';
import {AddIcon} from '../../../simple/controls/icons/icons.component';

interface OwnProps {
  data: Condition;
  inline?: boolean;
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

const NO_RIGHT_ELEMENT = [ConditionOperator.IS_NULL, ConditionOperator.IS_NOT_NULL];

export const ConditionBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, wrapWith, className, disabled, inline, box} = props;
  const {localErrors, actions: actions_} = useQueryElement(props);
  const options = useMemo(
    () =>
      values(ConditionOperator).map(k => ({
        label: ConditionOperatorName[k].toUpperCase(),
        value: k,
      })),
    []
  );
  const onOpChange = useCallback(
    (op: ConditionOperator) => {
      const newData = {...data};
      newData.op = op;
      if (op === ConditionOperator.BETWEEN) {
        newData.right = [{}, {}] as any;
      }
      if (NO_RIGHT_ELEMENT.indexOf(op) > -1) {
        newData.right = QueryBuilderFactory.createLiteral(true);
      }
      onChange(path, newData);
    },
    [data, onChange, path]
  );
  const actions: QueryAction[] = useMemo(
    () => [
      {
        label: 'And Condition',
        onClick: () => wrapWith(SqlElementType.AND_CONDITION),
        icon: AddIcon,
        extended: true,
      },
      {
        label: 'Or Condition',
        onClick: () => wrapWith(SqlElementType.OR_CONDITION),
        icon: AddIcon,
        extended: true,
      },
      ...actions_,
    ],
    [actions_, wrapWith]
  );

  const renderRight = () => {
    if (NO_RIGHT_ELEMENT.indexOf(data.op) > -1) {
      return null;
    }
    if (data.op === ConditionOperator.BETWEEN) {
      return (
        <>
          <InlineToken>Start</InlineToken>
          <QueryElementBuilder
            error={Boolean(localErrors.right)}
            className={queryBuilderClasses.Inline}
            path={concatPath(path, 'right.0')}
            data={data.right[0] as any}
            box={false}
            inline
          />
          <InlineToken>End</InlineToken>
          <QueryElementBuilder
            error={Boolean(localErrors.right)}
            className={queryBuilderClasses.Inline}
            path={concatPath(path, 'right.1')}
            data={data.right[1] as any}
            box={false}
            inline
          />
        </>
      );
    }

    return (
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'right')}
        data={data.right as any}
        box={false}
        inline
      />
    );
  };

  return (
    <QueryBlock
      disabled={disabled}
      className={className}
      label={'Condition'}
      actions={actions}
      inline={inline}
      box={box}
    >
      <InlineToken>IF</InlineToken>
      <QueryElementBuilder
        error={Boolean(localErrors.left)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'left')}
        data={data.left}
        box={false}
        inline
      />
      <Select
        className={queryBuilderClasses.Inline}
        dropdownButtonClassName={queryBuilderClasses.Button}
        placeholder={'Operator'}
        value={data.op}
        error={Boolean(localErrors.op)}
        onChange={v => onOpChange(v as ConditionOperator)}
        searchable={false}
        clearable={false}
        sortValues={false}
        options={{options}}
        disabled={disabled}
      />
      {renderRight()}
    </QueryBlock>
  );
};

ConditionBuilderComponent.defaultProps = {};

export const ConditionViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, className} = props;

  return (
    <QueryBlock className={className} disabled={true} inline={true} box={false} fitContent>
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'left')}
        data={data.left}
        box={false}
        inline
      />
      <InlineToken>{ConditionOperatorName[data.op]}</InlineToken>
      {data.op === ConditionOperator.BETWEEN && (
        <>
          <InlineToken>Start</InlineToken>
          <QueryElementBuilder
            className={queryBuilderClasses.Inline}
            path={concatPath(path, 'right.0')}
            data={data.right[0] as any}
            box={false}
            inline
          />
          <InlineToken>End</InlineToken>
          <QueryElementBuilder
            className={queryBuilderClasses.Inline}
            path={concatPath(path, 'right.1')}
            data={data.right[1] as any}
            box={false}
            inline
          />
        </>
      )}
      {data.op !== ConditionOperator.BETWEEN && (
        <QueryElementBuilder
          className={queryBuilderClasses.Inline}
          path={concatPath(path, 'right')}
          data={data.right as any}
          box={false}
          inline
        />
      )}
    </QueryBlock>
  );
};

export const ConditionBuilder = withViewMode(ConditionBuilderComponent, ConditionViewerComponent);
