import {ColumnType, SmartTableFigureColumn} from '../../../../types';
import {getDataValueKey} from '../smart-table.utils';
import {isArray, keyBy} from 'lodash';
import {parseSentence, sentencePartsToString} from '../../../../../sentence/sentence.utils';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_INPUT_DATE_FORMAT,
  EXPORT_DATE_FORMAT,
} from '../../../../../../../consts/ui';
import moment from 'moment';
import {exists, toFixedNumber} from 'front-core';

interface SmartTableCSVConfig {
  columnKey: string;
  // the title for the csv column
  title: string;
  // the order in the csv
  order: number;
  // extractor is used to extract the data
  extractor: (value: any) => any;
}

const withUnit = (value, unit) => (value ? (unit ? `${value}${unit}` : value) : null);
const maybeArray = value => (isArray(value) ? value.join(',') : value);
const fixValue = (value: any, column: SmartTableFigureColumn<any>) => {
  if (!exists(value)) {
    return column.options.placeholderText || '';
  }
  if (typeof value === 'string') {
    if (value.includes(',')) {
      value = `"${value}"`;
    }
  }
  return value;
};

const IGNORE_COLUMN_TYPES = [ColumnType.COMMAND, ColumnType._RENDER_COLUMN];

/**
 * Given smart table data and columns, this function will generate a csv file and auto download it for client.
 * @param data
 * @param columns
 */
export const exportSmartTableAsCSV = (data: any[], columns: SmartTableFigureColumn<any>[]) => {
  const exportConfig: SmartTableCSVConfig[] = [];
  const columnsQueue = [...columns];
  let i = 0;

  for (const c of columnsQueue) {
    if (IGNORE_COLUMN_TYPES.indexOf(c.asType || c.type) > -1) {
      continue;
    }

    const defaultConfig: SmartTableCSVConfig = {
      columnKey: c.key,
      title: c.title,
      order: i,
      extractor: data => data[c.options.exportDataKey || c.key],
    };

    switch (c.asType || c.type) {
      case ColumnType.DATE: {
        let {dateInputFormat, endDateDataKey} = c.typeOptions;
        dateInputFormat = dateInputFormat || DEFAULT_INPUT_DATE_FORMAT;
        exportConfig.push({
          ...defaultConfig,
          extractor: value =>
            value[c.key]
              ? moment.utc(value[c.key], dateInputFormat).format(EXPORT_DATE_FORMAT)
              : null,
        });
        if (endDateDataKey) {
          exportConfig.push({
            title: `${defaultConfig.title} end date`,
            columnKey: endDateDataKey,
            order: defaultConfig.order + 0.1,
            extractor: value =>
              value[endDateDataKey]
                ? moment.utc(value[endDateDataKey], dateInputFormat).format(EXPORT_DATE_FORMAT)
                : null,
          });
        }
        break;
      }
      case ColumnType.BOOLEAN: {
        exportConfig.push({
          ...defaultConfig,
          extractor: value => (value[c.key] === true ? 'v' : 'x'),
        });
        break;
      }
      case ColumnType.GRADIENT:
      case ColumnType.CONFIDENCE_INTERVAL:
      case ColumnType.NUMERIC: {
        const valueDataKey = getDataValueKey(c, 'valueDataKey');
        const {upperDataKey, lowerDataKey} = c.typeOptions;
        const {unit} = c.options;
        exportConfig.push({
          ...defaultConfig,
          extractor: value => withUnit(value[valueDataKey], unit),
        });
        if (upperDataKey) {
          exportConfig.push({
            ...defaultConfig,
            title: `${defaultConfig.title} upper bound`,
            order: defaultConfig.order + 0.1,
            extractor: value => withUnit(value[upperDataKey], unit),
          });
        }
        if (lowerDataKey) {
          exportConfig.push({
            ...defaultConfig,
            title: `${defaultConfig.title} lower bound`,
            order: defaultConfig.order + 0.2,
            extractor: value => withUnit(value[lowerDataKey], unit),
          });
        }
        break;
      }
      case ColumnType.VERSUS: {
        const valueDataKey = getDataValueKey(c, 'valueDataKey');
        const {versusDataKey} = c.typeOptions;
        const {unit} = c.options;
        exportConfig.push({
          ...defaultConfig,
          extractor: value => withUnit(value[valueDataKey], unit),
        });
        if (versusDataKey) {
          exportConfig.push({
            ...defaultConfig,
            title: `${c.title} base value`,
            extractor: value => withUnit(value[versusDataKey], unit),
          });
        }
        break;
      }
      case ColumnType.ADOPTION_RATE: {
        const valueDataKey = getDataValueKey(c, 'valueDataKey');
        const {totalDataKey, totalSuffix} = c.typeOptions;
        const {unit} = c.options;
        exportConfig.push({
          ...defaultConfig,
          extractor: value => withUnit(value[valueDataKey], unit),
        });
        if (totalDataKey) {
          exportConfig.push({
            ...defaultConfig,
            title: `${c.title} #${totalSuffix}`,
            extractor: value => value[totalDataKey],
          });
        }
        break;
      }
      case ColumnType.OUT_OF: {
        const valueDataKey = getDataValueKey(c, 'valueDataKey');
        const {outOfDataKey} = c.typeOptions;
        const {unit} = c.options;
        exportConfig.push({
          ...defaultConfig,
          extractor: value => withUnit(value[valueDataKey], unit),
        });
        if (outOfDataKey) {
          exportConfig.push({
            ...defaultConfig,
            title: `${c.title} base value`,
            extractor: value => withUnit(value[outOfDataKey], unit),
          });
        }
        break;
      }
      case ColumnType.PROGRESS: {
        const valueDataKey = getDataValueKey(c, 'valueDataKey');
        const {totalDataKey} = c.typeOptions;
        // see core.ts - ColumnType.PROGRESS
        exportConfig.push({
          ...defaultConfig,
          extractor: value => withUnit(value[`${valueDataKey}_progress`], '%'),
        });
        exportConfig.push({
          ...defaultConfig,
          title: `${c.title} base`,
          order: defaultConfig.order + 0.1,
          extractor: value => value[valueDataKey],
        });
        if (totalDataKey) {
          exportConfig.push({
            ...defaultConfig,
            title: `${c.title} total`,
            order: defaultConfig.order + 0.2,
            extractor: value => value[totalDataKey],
          });
        }
        break;
      }
      case ColumnType.CHANGE: {
        exportConfig.push({
          ...defaultConfig,
          extractor: value => withUnit(value[c.key], '%'),
        });
        break;
      }
      case ColumnType.LABEL_VALUE: {
        const valueDataKey = getDataValueKey(c, 'valueDataKey');
        const {labelDataKey, labelDataTitle, valueDataTitle} = c.typeOptions;
        exportConfig.push({
          ...defaultConfig,
          title: valueDataTitle || valueDataKey,
          extractor: value => maybeArray(value[valueDataKey]),
        });
        if (labelDataKey) {
          exportConfig.push({
            ...defaultConfig,
            title: labelDataTitle || labelDataKey,
            order: defaultConfig.order + 0.1,
            extractor: value => maybeArray(value[labelDataKey]),
          });
        }
        break;
      }
      case ColumnType.SEQUENCE: {
        const {mode} = c.typeOptions;
        exportConfig.push({
          ...defaultConfig,
          extractor: value =>
            mode === 'arrow' ? value[c.key].join(' -> ') : value[c.key].join(','),
        });
        break;
      }
      case ColumnType.SWITCH_COLUMN: {
        const {columns} = c.typeOptions;
        columnsQueue.splice(i + 1, 0, ...columns);
        break;
      }
      case ColumnType.INFO: {
        exportConfig.push({
          ...defaultConfig,
          title: c.title || 'Info',
          extractor: value => value[c.key],
        });
        break;
      }
      case ColumnType.SENTENCE: {
        exportConfig.push({
          ...defaultConfig,
          extractor: value => {
            const parts = parseSentence(value[c.key], c.typeOptions.delimiter || '|');
            return sentencePartsToString(parts);
          },
        });
        break;
      }
      default: {
        exportConfig.push(defaultConfig);
        break;
      }
    }
    // move iterator index + 1
    i++;
  }
  const columnsMap = keyBy(columnsQueue, 'key');
  const rows = [];
  const exportConfigOrdered = exportConfig.sort((a, b) => a.order - b.order);

  // generate column names row
  rows.push(exportConfigOrdered.map(c => c.title));
  // generate data rows
  for (const d of data) {
    const dataRow = [];
    for (const ec of exportConfigOrdered) {
      const extractor = ec.extractor;
      let value = extractor(d);
      value = fixValue(value, columnsMap[ec.columnKey]);
      dataRow.push(value);
    }
    rows.push(dataRow);
  }
  const csvContent = rows.map(e => e.join(',')).join('\n');
  // download file code
  const blob = new Blob(['\ufeff', csvContent]);
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = 'export.csv';
  document.body.appendChild(a);
  a.click();
};
