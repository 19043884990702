import {composition, exists, OnSuccessActionHook} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {
  getHomepageSettingsNetworkRequest,
  getHomepageSubscriptionsNetworkRequest,
} from '../../../../http/homepage.network-requests';
import {HOMEPAGE_ID_PATH_PARAM, SUBSCRIPTION_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {
  Button,
  CampaignIcon,
  FancyHeader,
  ModalLayout,
  PlusLightIcon,
  useRemoteSourceStated,
} from 'ui-components';
import classes from './homepage-subscriptions-list-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {HomepageSelector} from '../../components/homepage-summary/components/homepage-selector/homepage-selector.component';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import {PanelKey} from '../../../../constants/panels';
import {SubscriptionListItem} from './components/subscription-list-item/subscription-list-item.component';
import {ModelKey} from '../../../../constants/model-key';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {useRemoteList} from '../../../../core/hooks/use-remote-list.hook';
import {homepageSubscriptionToastCreator} from '../../../../store/toasts.actions';
import {useDispatch} from 'react-redux';
import {
  createHomepageSubscription,
  deleteHomepageSubscriptionConfirmed,
} from '../../../../store/homepage/homepage.actions';
import {FormProvider, useForm} from 'react-hook-form';
import {
  HomepageSubscription,
  HomepageSubscriptionInitiatedFrom,
  HomepageSubscriptionVIA,
} from '../../../../objects/models/homepage.model';
import {HomepageSubscriptionListPanelEmptyState} from './components/homepage-subscription-list-panel-empty-state/homepage-subscription-list-panel-empty-state.component';
import {createDefaultSubscription} from '../homepage-subscription-panel/homepage-subscription-panel.utils';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {createEmailAndSlackFormValidator} from '../../components/homepage-summary/components/homepage-quick-subscription-form/homepage-quick-subscription-form.component';

type HomepageSubscriptionsListPanelComponentProps = {
  homepageId?: number;
  onClose?: () => void;
  disabled?: boolean;
  onSubmit?: (data: Partial<HomepageSubscription>) => void;
  onSuccess?: OnSuccessActionHook;
};

const createListKey = (key: string = 'SUBSCRIPTION') => `HOMEPAGE_SUBSCRIPTIONS/${key}`;

const createValidator = (userEmail?: string) => {
  return yup
    .object()
    .shape(createEmailAndSlackFormValidator(userEmail))
    .test(
      'at-least-one-required',
      'Must fill either email or slackChannels',
      obj =>
        (obj.emails && exists(obj.emails[0]?.trim())) ||
        (obj.slackChannels && obj.slackChannels.length > 0)
    );
};

const HomepageSubscriptionsListPanelComponent = (
  props: HomepageSubscriptionsListPanelComponentProps
) => {
  const {homepageId: homepageIdFromProps, onClose, onSuccess, onSubmit: onSubmitFromProps} = props;

  const dispatch = useDispatch();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {defaultHomepageId} = useProductData();
  const currentUser = useCurrentUser();
  const [selectedHomepageId, setSelectedHomepageId] = useState(
    homepageIdFromProps ?? defaultHomepageId
  );
  const {t} = useTranslation();

  const config = useMemo(
    () => ({
      listKey: createListKey(),
      actionKey: createListKey(),
      request: () => getHomepageSubscriptionsNetworkRequest(selectedHomepageId),
      onError: () => [homepageSubscriptionToastCreator('GET_ERROR')],
      modelKey: ModelKey.HOMEPAGE_SUBCRIPTION,
    }),
    [selectedHomepageId]
  );

  const {isLoading, listsData, onRefresh} = useRemoteList({
    config,
  });

  const {source: homepageSettings, exec: getHomepageSettings} = useRemoteSourceStated({
    networkRequest: getHomepageSettingsNetworkRequest,
  });

  useEffect(() => {
    getHomepageSettings(selectedHomepageId);
  }, [getHomepageSettings, selectedHomepageId]);

  const subscriptions = useMemo(() => listsData?.list ?? [], [listsData]);

  const onAddHomepageSubscription = useCallback(
    () =>
      openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_PANEL, {
        [HOMEPAGE_ID_PATH_PARAM]: selectedHomepageId,
      }),
    [openSecondaryPanel, selectedHomepageId]
  );

  const onEditHomepageSubscription = useCallback(
    (subscriptionId: number) =>
      openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_PANEL, {
        [HOMEPAGE_ID_PATH_PARAM]: selectedHomepageId,
        [SUBSCRIPTION_ID_PATH_PARAM]: subscriptionId,
      }),
    [openSecondaryPanel, selectedHomepageId]
  );

  const onDeleteHomepageSubscription = useCallback(
    (subscriptionId: number) =>
      dispatch(
        deleteHomepageSubscriptionConfirmed({homepageId: selectedHomepageId, subscriptionId})
      ),
    [dispatch, selectedHomepageId]
  );

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.HOMEPAGE_SUBCRIPTION) {
        onRefresh();
      }
    };

    dispatch(registerActionListener(CoreActionsType.MODEL_CREATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    dispatch(registerActionListener(CoreActionsType.MODEL_DELETED, listener));

    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_CREATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
      dispatch(removeActionListener(CoreActionsType.MODEL_DELETED, listener));
    };
  }, [dispatch, onRefresh]);

  const shouldShowEmptyState = useMemo(() => subscriptions?.length === 0, [subscriptions]);

  const onSubmit = useCallback(
    (data: {email?: string; slackChannels?: string[]}) => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onActionSuccess = (res, action) => {
        onSuccess && onSuccess(res, action);
        onClose();
        openSecondaryPanel(PanelKey.HOMEPAGE_SUBSCRIPTION_SUCCESS_PANEL, {
          subscription: res,
          homepageId: homepageSettings.id,
          action: 'created',
        });
      };

      const via = [];

      if (exists(data.emails?.[0]?.trim())) {
        via.push(HomepageSubscriptionVIA.MAIL);
      }

      if (data.slackChannels?.length > 0) {
        via.push(HomepageSubscriptionVIA.SLACK);
      }

      const defaults = createDefaultSubscription(homepageSettings);
      dispatch(
        createHomepageSubscription(
          {
            ...defaults,
            isActive: true,
            initiatedFrom: HomepageSubscriptionInitiatedFrom.EMPTY_STATE,
            via,
            ...data,
            homepageId: homepageSettings.id,
          },
          onActionSuccess
        )
      );
    },
    [dispatch, onSuccess, onSubmitFromProps, onClose, openSecondaryPanel, homepageSettings]
  );

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(createValidator(currentUser?.email)),
  });

  const {handleSubmit} = formMethods;

  return (
    <div className={classes.HomepageSubscriptionPanelContainer}>
      <ModalLayout
        footer={
          subscriptions?.length === 0 && (
            <div className={classes.Footer}>
              <Button onClick={onClose} variant={'outlined'}>
                {t(TransKeys.GENERAL.ACTIONS.CANCEL)}
              </Button>
              <Button onClick={handleSubmit(onSubmit)}>{t(TransKeys.GENERAL.ACTIONS.SAVE)}</Button>
            </div>
          )
        }
      >
        <FancyHeader
          icon={CampaignIcon}
          title={t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.HEADER_TITLE)}
        />
        {isLoading && <GenericLoading />}
        <div className={classes.Content}>
          <div className={classes.ActionsHeader}>
            <HomepageSelector
              onChange={setSelectedHomepageId}
              selectedHomepageId={selectedHomepageId}
            />
            {!shouldShowEmptyState && (
              <Button onClick={onAddHomepageSubscription} icon={PlusLightIcon}>
                {t(TransKeys.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL.ACTIONS.CREATE)}
              </Button>
            )}
          </div>
          <div className={classes.ListContainer}>
            {!isLoading && shouldShowEmptyState && (
              <FormProvider {...formMethods}>
                <HomepageSubscriptionListPanelEmptyState
                  title={t(TransKeys.HOMEPAGE_SUBSCRIPTIONS_LIST_PANEL.EMTY_STATE.DESCRIPTION)}
                  onOpenAdvancedOptions={onAddHomepageSubscription}
                />
              </FormProvider>
            )}
            {subscriptions?.map(subscription => (
              <SubscriptionListItem
                key={subscription.id}
                subscription={subscription}
                onEdit={onEditHomepageSubscription}
                onDelete={onDeleteHomepageSubscription}
              />
            ))}
          </div>
        </div>
      </ModalLayout>
    </div>
  );
};

export const HomepageSubscriptionsListPanel = composition(
  HomepageSubscriptionsListPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct
);
