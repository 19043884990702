import * as React from 'react';
import classNames from 'classnames';
import classes from './similar-button.module.scss';
import {take} from 'lodash';
import {MAX_SIMILAR_TO_SHOW} from '../../../../segment-explainers-table.consts';
import {
  HtmlTooltip,
  TooltipIfOverflow,
} from '../../../../../../../../../../../../simple/generic/tooltips/tooltips.component';
import {withStopPropagation} from 'front-core';
import TransKeys from 'translations';
import {ExplainerTableItem} from '../explainers-table-item/explainers-table-item.component';
import {useDocumentTranslation} from '../../../../../../../../../../hooks/use-document-translation.hook';
import {generateSegmentName} from '../../../../segment-explainers-table.utils';

interface OwnProps {
  item: ExplainerTableItem;
  isExpanded: boolean;
  onExpand: () => void;
  className?: string;
}

type AllProps = OwnProps;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

export const SimilarButton: React.FC<AllProps> = (props: AllProps) => {
  const {item, isExpanded, onExpand, className} = props;
  const {t} = useDocumentTranslation();

  return (
    <HtmlTooltip
      interactive={false}
      title={
        !isExpanded ? (
          <div className={classes.SimilarTooltip}>
            <div className={classes.Title}>
              {t(TRANS_KEYS_PREFIX.SIMILAR_SEGMENTS_TOOLTIP.TITLE, {
                count: item.group.length,
              })}
            </div>
            {take(item.group, MAX_SIMILAR_TO_SHOW).map(s => (
              <TooltipIfOverflow title={generateSegmentName(s)}>
                <div key={s.key} className={classes.SimilarRow}>
                  {generateSegmentName(s)}
                </div>
              </TooltipIfOverflow>
            ))}
            {item.group.length > MAX_SIMILAR_TO_SHOW && (
              <div key={'more'} className={classes.More}>
                {item.group.length - MAX_SIMILAR_TO_SHOW} more...
              </div>
            )}
            <div className={classes.Info}>
              {t(TRANS_KEYS_PREFIX.SIMILAR_SEGMENTS_TOOLTIP.BUTTON)}
            </div>
          </div>
        ) : (
          ''
        )
      }
    >
      <div
        className={classNames(classes.SimilarButton, className)}
        onClick={withStopPropagation(() => onExpand())}
      >
        {item.group.length > 1 && isExpanded
          ? t(TransKeys.GENERAL.ACTIONS.SEE_LESS)
          : t(TRANS_KEYS_PREFIX.SIMILAR_SEGMENTS_BUTTON, {
              count: item.group.length - 1,
            })}
      </div>
    </HtmlTooltip>
  );
};
