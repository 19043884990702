import * as React from 'react';
import classNames from 'classnames';
import classes from './icon-button.module.scss';
import {Tooltip} from '@material-ui/core';
import {forwardRef} from 'react';

interface OwnProps {
  icon: any;
  tooltipText?: any;
  onClick: (e) => void;
  className?: string;
  iconClassName?: string;
  border?: boolean;
  size?: 'small' | 'normal' | 'large';
  disabled?: boolean;
  active?: boolean;
  ref?: any;
}

type AllProps = OwnProps;

export const IconButton: React.FC<AllProps> = forwardRef(
  (props: AllProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      onClick: onClick_,
      icon: Icon,
      tooltipText,
      className,
      iconClassName,
      border,
      size,
      disabled,
      active,
    } = props;

    const onClick = e => {
      e.preventDefault();
      e.stopPropagation();
      !disabled && onClick_(e);
    };

    return (
      <Tooltip title={tooltipText} placement={'top'} interactive={false}>
        <div
          ref={ref}
          className={classNames(
            classes.IconButton,
            classes[size],
            border && classes.Border,
            disabled && classes.Disabled,
            active && classes.Active,
            className
          )}
          onClick={onClick}
        >
          <Icon className={classNames(classes.Icon, iconClassName)} />
        </div>
      </Tooltip>
    );
  }
);

IconButton.defaultProps = {
  tooltipText: '',
  border: true,
  size: 'normal',
  disabled: false,
};
