import React, {useContext, useMemo, useState} from 'react';
import classes from './release-impact-feature-effect-on-metric-viewer.module.scss';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {ExperimentContext} from '../../../../contexts/experiment.context';
import {AnalysisAlgoMode, ReleaseImpactFeatureEffectOnMetricFigure} from '../../../../types';
import classNames from 'classnames';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {
  FlexibleTable,
  FlexibleTableColumn,
} from '../../../shared/general/flexible-table/flexible-table.component';
import TransKeys from 'translations';
import {SwitchAlgoMode} from '../../../shared/general/switch-treatment-adoption-mode/switch-algo-mode.component';
import {exists, number2k, safeDivision, withStopPropagation} from 'front-core';
import {TooltipIfOverflow} from '../../../../../../simple/generic/tooltips/tooltips.component';
import {StarIcon} from '../../../../../../simple/controls/icons/icons.component';
import {Chip, ChipVariant} from '../../../../../../simple/data-display/chip/chip.component';
import {UpliftDisplay} from '../../../shared/display-columns/uplift-display.component';
import {secondsToDuration} from '../../../../document-viewer.utils';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';
import {HoverHelperTip} from '../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';

interface OwnProps extends ReleaseImpactFeatureEffectOnMetricFigure {
  className?: string;
}

type AllProps = OwnProps;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_FEATURE_EFFECT_ON_METRIC_FIGURE;

export const ReleaseImpactFeatureEffectOnMetricFigureViewer: React.FC<AllProps> = (
  props: AllProps
) => {
  const {data, className} = props;
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {metrics} = useContext(ExperimentContext);
  const [mode, setMode] = useState(
    data.causal && data.causal.length > 0
      ? AnalysisAlgoMode.CAUSATION
      : AnalysisAlgoMode.CORRELATION
  );
  const showSwitchMode =
    data.causal && data.causal.length > 0 && data.correlative && data.correlative.length > 0;

  const items = (mode === AnalysisAlgoMode.CAUSATION ? data.causal : data.correlative) || [];
  const tableData: any[] = useMemo(() => {
    return items.map(item => ({
      ...item,
      ...(metrics[item.signalId] || {}),
      avgSecondsToGoalInAdoptersPost: item.avgSecondsToGoalInAdoptersPost
        ? secondsToDuration(item.avgSecondsToGoalInAdoptersPost)
        : null,
      avgSecondsToGoalInNonAdoptersPost: item.avgSecondsToGoalInNonAdoptersPost
        ? secondsToDuration(item.avgSecondsToGoalInNonAdoptersPost)
        : null,
      uplift: safeDivision(
        item.goalInAdoptersPost - item.goalInNonAdoptersPost,
        item.goalInNonAdoptersPost
      ),
      viewMetricCommand: {
        type: 'REF',
        payload: {
          type: 'SIGNAL',
          refId: item.signalId,
        },
      },
    }));
  }, [metrics, items]);

  const tableColumns: FlexibleTableColumn<any>[] = useMemo(() => {
    return [
      {
        key: 'metric',
        title: t(TRANS_KEYS_PREFIX.HEADERS.METRIC.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.HEADERS.METRIC.SUB_TITLE),
        weight: 1.5,
        render: (item: any) => (
          <div className={classes.Name}>
            {item.isPrimary && (
              <Chip
                size={'xsmall'}
                icon={StarIcon}
                label={'PRIMARY'}
                variant={ChipVariant.PRIMARY_KPI}
                className={classNames(classes.PrimaryChip)}
              />
            )}
            <TooltipIfOverflow title={item.name}>
              <div
                onClick={
                  item.signalId
                    ? withStopPropagation(() => emitEvent(item.viewMetricCommand))
                    : undefined
                }
                className={classNames(classes.Text, item.signalId && classes.Clickable)}
              >
                {item.name}
              </div>
            </TooltipIfOverflow>
          </div>
        ),
      },
      {
        key: 'kpi_value',
        title: t(TRANS_KEYS_PREFIX.HEADERS.KPI_VALUE.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.HEADERS.KPI_VALUE.SUB_TITLE),
        weight: 1,
        render: item => (
          <div className={classes.Column}>
            <span className={classes.Value}>
              {number2k(
                item.isPercentageValue ? item.goalInAdoptersPost * 100 : item.goalInAdoptersPost
              )}
              {item.isPercentageValue ? '%' : ''}
            </span>
            <span className={classes.VS}>vs.</span>
            <span className={classes.Secondary}>
              {number2k(
                item.isPercentageValue
                  ? item.goalInNonAdoptersPost * 100
                  : item.goalInNonAdoptersPost
              )}
              {item.isPercentageValue ? '%' : ''}
            </span>
          </div>
        ),
      },
      {
        key: 'uplift',
        title: t(TRANS_KEYS_PREFIX.HEADERS.UPLIFT.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.HEADERS.UPLIFT.SUB_TITLE),
        weight: 1,
        render: item => (
          <div className={classes.Goal}>
            <UpliftDisplay uplift={item.uplift} />
          </div>
        ),
      },
      {
        key: 'avg_time',
        title: t(TRANS_KEYS_PREFIX.HEADERS.AVG_TIME.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.HEADERS.AVG_TIME.SUB_TITLE),
        weight: 1.5,
        render: item => {
          if (
            !exists(item.avgSecondsToGoalInAdoptersPost) ||
            !exists(item.avgSecondsToGoalInNonAdoptersPost)
          ) {
            return (
              <div className={classes.Column}>
                <span>--</span>
                <HoverHelperTip title={t(TRANS_KEYS_PREFIX.AVG_TIME_EMPTY_STATE)} />
              </div>
            );
          }
          return (
            <div className={classes.Column}>
              <span className={classes.Value}>{item.avgSecondsToGoalInAdoptersPost}</span>
              <span className={classes.VS}>vs.</span>
              <span className={classes.Secondary}>{item.avgSecondsToGoalInNonAdoptersPost}</span>
            </div>
          );
        },
      },
    ];
  }, [t]);

  return (
    <DocumentTitleWrapper
      title={t(TRANS_KEYS_PREFIX.TITLE)}
      className={classNames(classes.ReleaseImpactFeaturesSummaryFigureViewer, className)}
    >
      {showSwitchMode && (
        <SwitchAlgoMode algoMode={mode} onSwitchAlgoMode={setMode} className={classes.SwitchMode} />
      )}
      <FlexibleTable columns={tableColumns} data={tableData} />
    </DocumentTitleWrapper>
  );
};

ReleaseImpactFeatureEffectOnMetricFigureViewer.defaultProps = {};
