import * as React from 'react';
import {CSSProperties, useCallback, useMemo} from 'react';
import {ColumnType, SmartTableFigure} from '../../../types';
import {SmartTableContextProvider} from './smart-table.context';
import {SmartTable} from './components/smart-table.component';
import {get} from 'lodash';
import {Checkbox} from '../../../../../forms/inputs/checkbox/checkbox.component';
import classes from './smart-table.module.scss';
import {ChildRendererInjectedProps, ViewerComponentMode} from '../../../document-viewer.types';
import classNames from 'classnames';
import {DocumentTitleWrapper} from '../title-section-viewer/title-section-viewer.component';

interface SmartTableFigureInternalProps {
  onClick?: (item: any) => void;
  selectedKey?: string;
}

export interface OwnProps extends SmartTableFigure, SmartTableFigureInternalProps {
  style?: CSSProperties;
  darkMode?: boolean;
  className?: string;
}

export const SmartTableViewer: React.FC<OwnProps> = (
  props: OwnProps & Partial<ChildRendererInjectedProps>
) => {
  const {
    id,
    data,
    columns: columnsFromProps,
    options: optionsFromProps,
    partitions,
    dataKey,
    changeParameters,
    componentMode,
    title,
    className,
    darkMode,
    // internal
    onClick,
    selectedKey,
  } = props;
  const highlightIdsSet = useMemo(
    () => new Set(optionsFromProps['highlightIds'] || []),
    [optionsFromProps.highlightIds]
  );
  const onHighlightData = useCallback(
    (key: string) => {
      if (highlightIdsSet.has(key)) {
        highlightIdsSet.delete(key);
      } else {
        highlightIdsSet.add(key);
      }
      changeParameters({
        options: {
          highlightIds: Array.from(highlightIdsSet),
        },
      });
    },
    [changeParameters, highlightIdsSet]
  );
  const columns = useMemo(() => {
    const filteredColumns = columnsFromProps.filter(c => !c.hidden);
    if (componentMode !== ViewerComponentMode.HIGHLIGHT_MODE) {
      return filteredColumns;
    }
    return [
      {
        key: 'select',
        type: ColumnType._RENDER_COLUMN,
        title: '',
        options: {
          width: '4rem',
        },
        render: item => (
          <div className={classes.Center}>
            <Checkbox
              multi
              checked={highlightIdsSet.has(item[dataKey])}
              onChange={() => onHighlightData(item[dataKey])}
            />
          </div>
        ),
      },
      ...filteredColumns,
    ];
  }, [columnsFromProps, componentMode, dataKey, highlightIdsSet, onHighlightData]);
  const options = useMemo(() => {
    const ops = {...optionsFromProps};
    if (optionsFromProps['highlightIds']) {
      if (!ops.data) {
        ops.data = {};
      }
      for (const id of optionsFromProps['highlightIds']) {
        ops.data[id] = {
          ...(get(ops.data, id) || {}),
          color: 'highlight',
        };
      }
    }
    return ops;
  }, [optionsFromProps]);

  return (
    <DocumentTitleWrapper title={title} className={classNames(classes.SmartTableViewer, className)}>
      <SmartTableContextProvider
        id={id}
        data={data}
        options={options}
        dataKey={dataKey}
        columns={columns}
        partitions={partitions}
        onClick={onClick}
        selectedKey={selectedKey}
      >
        <SmartTable className={className} darkMode={darkMode} />
      </SmartTableContextProvider>
    </DocumentTitleWrapper>
  );
};

SmartTableViewer.defaultProps = {
  data: [],
  options: {},
};
