import {useCallback, useMemo, useState} from 'react';
import {
  ReleaseImpactSummaryValidationConfidenceLevel,
  ReleaseImpactSummaryValidations,
  ReleaseImpactSummaryValidationType,
} from '../../../../../../types/figures.types';
import {getValidationIcon, getValidationsCounts} from '../../validations.utils';
import classes from './validations-explainers.module.scss';
import classNames from 'classnames';
import {Collapsible} from '../../../../../../../../simple/generic/collapsible/collapsible.component';
import {ValidationStatusChip} from './validation-status-chip.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {ValidationsMoreInfoPanel} from './validations-more-info-panel.component';

type ValidationsExplainersProps = {validations: ReleaseImpactSummaryValidations};

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS;

export const ValidationsExplainers = (props: ValidationsExplainersProps) => {
  const {validations} = props;
  const {t} = useDocumentTranslation();
  const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
  const [moreInfoModalSelectedValidation, setMoreInfoModalSelectedValidation] = useState(null);
  const {validationConfidenceLevel, validationResults} = validations;

  const Icon = useMemo(() => {
    return getValidationIcon(validations);
  }, [validations]);

  const generalSummary = useMemo(() => {
    let statusKey = 'PASSED_COMPLETELY';
    if (validationConfidenceLevel !== ReleaseImpactSummaryValidationConfidenceLevel.HIGH) {
      statusKey = 'WARNING';
    }

    const {passCount, totalCount} = getValidationsCounts(validations);

    if (
      validationConfidenceLevel === ReleaseImpactSummaryValidationConfidenceLevel.HIGH &&
      passCount !== totalCount
    ) {
      statusKey = 'PASSED_PARTIALLY';
    }

    return t(TransKeysPrefix.GENERAL_SUMMARY[statusKey].DESCRIPTION);
  }, [t, validationConfidenceLevel, validations]);

  const getValidationName = useCallback(
    (validationType: ReleaseImpactSummaryValidationType) => {
      return t(TransKeysPrefix.TYPES[validationType.toUpperCase()].LABEL);
    },
    [t]
  );

  const onClickMoreInfo = (validationKey: string) => {
    setMoreInfoModalSelectedValidation(validationKey);
    setMoreInfoModalOpen(true);
  };

  const onCloseMoreInfoModal = () => {
    setMoreInfoModalOpen(false);
    setMoreInfoModalSelectedValidation(null);
  };

  return (
    <>
      <div className={classes.ValidationsExplainers}>
        <div className={classes.ValidationsGeneralSummary}>
          <Icon
            className={classNames(
              classes.Icon,
              validationConfidenceLevel === ReleaseImpactSummaryValidationConfidenceLevel.HIGH &&
                classes.HighConfidence
            )}
          />
          <div className={classes.Content}>{generalSummary}</div>
        </div>
        <div className={classes.TestResultsListContainer}>
          <div className={classes.Title}>
            {t(
              TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE.VALIDATIONS
                .TEST_RESULTS_LIST_TITLE
            )}
          </div>
          <div className={classes.TestResultsList}>
            {Object.keys(validationResults).map(validationKey => {
              return (
                <Collapsible
                  title={
                    <div className={classes.CollapsibleTitle}>
                      <div>
                        {getValidationName(validationKey as ReleaseImpactSummaryValidationType)}
                      </div>
                      <ValidationStatusChip passed={validationResults[validationKey].conclusion} />
                    </div>
                  }
                  content={
                    <div className={classes.CollapsibleContent}>
                      {validationResults[validationKey].conclusion ? (
                        <div>
                          {t(
                            TransKeysPrefix.TYPES[validationKey.toUpperCase()]
                              .VALIDATION_PASSED_MESSAGE
                          )}
                        </div>
                      ) : (
                        <>
                          <div>
                            {t(TransKeysPrefix.TYPES[validationKey.toUpperCase()].EXPLAINER, {
                              ...validationResults[validationKey].data,
                            })}
                          </div>
                          <div>
                            {t(
                              TransKeysPrefix.TYPES[validationKey.toUpperCase()]
                                .MITIGATION_STEPS_MESSAGE,
                              validationResults[validationKey].data
                            )}
                          </div>
                          <div
                            className={classes.MoreInfo}
                            onClick={() => onClickMoreInfo(validationKey)}
                          >
                            {t(TransKeys.GENERAL.LABELS.MORE_INFO)}
                          </div>
                        </>
                      )}
                    </div>
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
      {moreInfoModalOpen && (
        <ValidationsMoreInfoPanel
          validationConfidenceLevel={validationConfidenceLevel}
          selectedValidationType={moreInfoModalSelectedValidation}
          selectedValidation={validationResults[moreInfoModalSelectedValidation]}
          onClose={onCloseMoreInfoModal}
        />
      )}
    </>
  );
};
