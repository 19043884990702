import {useCallback, useMemo, useState} from 'react';
import {
  Button,
  FancyHeader,
  FolderIcon,
  LabelWrapper,
  LiteralValueType,
  ModalLayout,
  QueryBuilder,
  QueryBuilderConfig,
  SwitchActions,
} from 'ui-components';
import {EVENT_GROUP_ID_PATH_PARAM} from '../../../../constants/app-routes';
import classes from './event-group-form-panel.module.scss';
import {useTranslation} from 'react-i18next';
import TransKeys from '../../../../constants/translation-keys';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {SharedSelectionKeys} from '../../../../constants/shared-selection-keys';
import {useDispatch, useSelector} from 'react-redux';
import {getReducedLoadingStateSelector} from '../../../../store/store.selectors';
import {sharedClasses} from '../../../shared';
import {yupResolver} from '@hookform/resolvers/yup';
import {ActionKey} from '../../../../constants/action-key';
import {composition} from 'front-core';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {FormHiddenInputs} from '../../../shared/form/components/form-hidden-inputs.component';
import {
  TextareaFormInput,
  TextFormInput,
} from '../../../shared/form/components/shared-form-input.component';
import {contactSupport, preventSubmitOnEnter} from '../../../../utils/general.utils';
import {queryBuilderModelConfig} from '../../../../constants/query-builder';
import {withLoadBefore} from '../../../../core/hoc/with-load-before.hoc';
import {
  OptionTab,
  OptionTabs,
} from '../../../shared/components/general/options-tabs/options-tabs.component';
import {TabHeader} from '../../../shared/components/general/tab-header/tab-header.component';
import {NoteText} from '../../../shared/components/general/note-text/note-text.component';
import {getComponentForQuery} from '../../../shared/core/query-builders/query-builders.utils';
import {useAmplitude} from '../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../constants/amplitude-event';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {TableEntity, TableEntityBinding} from '../../../../objects/models/table.model';
import {TitleWithIcon} from '../../../shared/components/general/title/title.component';
import {EventGroup, EventGroupType} from '../../../../objects/models/event-group.model';
import {getEventGroupNetworkRequest} from '../../../../http/event-groups.network-requests';
import {
  createEventGroup,
  updateEventGroup,
} from '../../../../store/event-groups/event-groups.actions';
import {eventGroupDTOValidator} from '../../../../objects/dto/event-group.dto';
import {TableEventsValueQueryBuilder} from '../../../shared/core/query-builders/table-events-value-query-builder/table-events-value-query-builder.component';
import {EventGroupQueryBuilder} from '../../../shared/core/query-builders/event-group-query-builder/event-group-query-builder.component';
import {get} from 'lodash';
import {LITERAL_TYPE_TO_SIGNAL_DATA_TYPE} from '../../../../constants/literal-type-to-signal-data-type';
import {SignalDataType} from '../../../../objects/models/signal.model';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {withModalInactiveSourceHandler} from '../../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {withDisableDemoProduct} from '../../../../core/hoc/with-disable-demo-product.hoc';
import {getEntityIcon} from '../../../../constants/entity.consts';
import {DMPSignalValidationError} from '../../components/dmp-signal-validation-error/dmp-signal-validation-error.component.tsx';

interface OwnProps {
  eventGroup?: EventGroup;
  data: Partial<EventGroup>;
  onSubmit?: (data: Partial<EventGroup>) => void;
  onClose?: () => void;
  panelId?: string;
  cloneMode?: boolean;
  disabled?: boolean;
  [EVENT_GROUP_ID_PATH_PARAM]?: number;
}

type AllProps = OwnProps;

const createEmptyEventGroup = (): Partial<EventGroup> => ({
  name: '',
  shortDescription: '',
  type: EventGroupType.EVENT_GROUP,
});

const SELECTED_EVENT_GROUP_KEY = SharedSelectionKeys.EVENT_GROUP_FORM__EVENT_GROUP;

const isCustomQueryBuilder = query => {
  if (!query) {
    return false;
  }
  return getComponentForQuery(query) === undefined;
};

const TABS = {
  [EventGroupType.EVENT_GROUP]: {
    key: EventGroupType.EVENT_GROUP,
    title: 'Events',
    helperText:
      'Group events into a group. This group can be used later to set the scope of drivers in an analysis.',
  },
  [EventGroupType.EVENT_PROPERTIES]: {
    key: EventGroupType.EVENT_PROPERTIES,
    title: 'Event Properties',
    helperText:
      'Group by event property. This grouping can be used later to set the scope of drivers in an analysis.',
  },
};

const createTableEventFilters = (entityContext: undefined) => ({
  entityBinding: TableEntityBinding.TWO_WAY,
  entityContext: entityContext,
});

const getSignalDataTypeFromLiteralType = (t: LiteralValueType) =>
  get(LITERAL_TYPE_TO_SIGNAL_DATA_TYPE, t);

const EventGroupFormPanelComponent = (props: AllProps) => {
  const {
    eventGroup = {} as any,
    data = createEmptyEventGroup(),
    onClose,
    cloneMode,
    onSubmit: onSubmitFromProps,
    disabled,
  } = props;
  const notify = useAmplitude();
  const {productEntities, productEntitiesMap, defaultTableEntity} = useProductData();
  const {demoProductValidator} = useDemoProduct();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const user = useCurrentUser();
  const overrideData = useMemo(() => (cloneMode ? {id: undefined} : {}), [cloneMode]);
  const formMethods = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...data,
      entity: defaultTableEntity,
      ...eventGroup,
      ...overrideData,
    } as any,
    resolver: yupResolver(demoProductValidator || eventGroupDTOValidator.noUnknown()),
  });
  const {
    handleSubmit,
    formState: {errors, isValidating, isSubmitting},
    watch,
    control,
    setValue,
    clearErrors,
  } = formMethods;
  const signalDefinition = watch('signalDefinition');
  const entity = watch('entity');
  const signalDefinitionErrors = get(errors, 'signalDefinition');
  const [selectedTab, setSelectedTab_] = useState<string>(
    eventGroup.type || EventGroupType.EVENT_GROUP
  );
  const setSelectedTab = useCallback(
    (tab: string) => {
      setSelectedTab_(tab);
      setValue('signalDefinition', null);
      setValue('type', tab);
      clearErrors();
    },
    [setSelectedTab_, setValue, clearErrors]
  );
  const isLoading = useSelector(state =>
    getReducedLoadingStateSelector(
      ActionKey.CREATE_EVENT_GROUP,
      ActionKey.UPDATE_EVENT_GROUP
    )(state)
  );
  // only takes from the initial data - for backward compatibility
  const isCustomQuery = useMemo(() => isCustomQueryBuilder(signalDefinition), [signalDefinition]);
  const queryBuilderConfig: QueryBuilderConfig = useMemo(
    () => ({
      modelConfig: queryBuilderModelConfig,
    }),
    []
  );
  const onSwitchEntity = useCallback(
    (entity: TableEntity) => {
      setValue('entity', entity);
      setValue('signalDefinition', null);
      clearErrors();
    },
    [setValue, clearErrors]
  );
  const entityOptions = useMemo(
    () =>
      productEntities.map(p => ({
        key: p.key,
        label: p.name,
        onClick: () => onSwitchEntity(p.key),
        isActive: entity === p.key,
        icon: getEntityIcon(p.key),
      })),
    [productEntities, entity, onSwitchEntity]
  );
  const editMode = Boolean(watch('id'));
  const onSubmit = useCallback(
    data => {
      if (onSubmitFromProps) {
        onSubmitFromProps(data);
        return;
      }
      const onSuccess = () => {
        onClose();
      };
      const action = editMode
        ? updateEventGroup(data, onSuccess)
        : createEventGroup(data, onSuccess);
      dispatch(action);
      notify(AmplitudeEvent.EVENT_GROUP_SAVE_CLICKED, {
        userId: user.id,
      });
    },
    [dispatch, editMode, onSubmitFromProps, onClose, user, notify]
  );
  const tabWrapper = useCallback(
    (tabKey: string, children: any) => (
      <div className={classes.EventGroupFormTab} key={tabKey}>
        <TabHeader size={'small'} title={TABS[tabKey]?.title} subTitle={TABS[tabKey]?.helperText} />
        <DMPSignalValidationError error={errors.signalDefinition} marginBottom />
        {children}
        <NoteText
          text={t(TransKeys.SUPPORT.MISSING_EVENT_OR_PROPERTY)}
          buttonText={t(TransKeys.SUPPORT.SUPPORT_BUTTON_TEXT)}
          onButtonClicked={contactSupport}
        />
      </div>
    ),
    [t, errors]
  );
  const tabs: OptionTab[] = useMemo(() => {
    const filters = {
      entityBinding: TableEntityBinding.TWO_WAY,
      entityContext: entity,
    };
    return [
      {
        key: EventGroupType.EVENT_GROUP,
        title: TABS[EventGroupType.EVENT_GROUP].title,
        helperText: TABS[EventGroupType.EVENT_GROUP].helperText,
        render: () =>
          tabWrapper(
            EventGroupType.EVENT_GROUP,
            <Controller
              render={({field}) => (
                <EventGroupQueryBuilder
                  query={field.value}
                  onChange={v => {
                    field.onChange(v);
                    setValue('signalDataType', SignalDataType.STRING);
                  }}
                  errors={signalDefinitionErrors}
                  filters={filters}
                />
              )}
              name={'signalDefinition'}
              control={control}
            />
          ),
      },
      {
        key: EventGroupType.EVENT_PROPERTIES,
        title: TABS[EventGroupType.EVENT_PROPERTIES].title,
        helperText: TABS[EventGroupType.EVENT_PROPERTIES].helperText,
        render: () =>
          tabWrapper(
            EventGroupType.EVENT_PROPERTIES,
            <Controller
              render={({field}) => (
                <TableEventsValueQueryBuilder
                  query={field.value}
                  onChange={field.onChange}
                  onColumnTypeChange={t =>
                    setValue('signalDataType', getSignalDataTypeFromLiteralType(t))
                  }
                  errors={signalDefinitionErrors}
                  filters={createTableEventFilters(entity)}
                  thenText={'And select the property to group by'}
                  multiEvents={false}
                  multiSelection
                  sameTypeThen
                />
              )}
              name={'signalDefinition'}
              control={control}
            />
          ),
      },
    ];
  }, [control, signalDefinitionErrors, tabWrapper, entity, setValue]);
  const containerStyle = useMemo(() => ({height: '80vh', width: '50vw', minWidth: '80rem'}), []);
  const isDisabled = useMemo(
    () => isLoading || isSubmitting || isValidating || disabled,
    [isLoading, isSubmitting, isValidating, disabled]
  );

  return (
    <div style={containerStyle}>
      <ModalLayout
        footer={
          <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
            {t(TransKeys.GENERAL.ACTIONS.SAVE)}
          </Button>
        }
      >
        {(isLoading || isValidating) && <GenericLoading />}
        <div className={classes.EventGroupForm}>
          <FancyHeader
            icon={FolderIcon}
            title={t(
              editMode
                ? TransKeys.EVENT_GROUP_FORM.TITLE_EDIT
                : TransKeys.EVENT_GROUP_FORM.TITLE_CREATE
            )}
            subTitle={t(TransKeys.EVENT_GROUP_FORM.MAIN_TITLE)}
            onClose={onClose}
            className={classes.EventGroupFormHeader}
          />

          <FormProvider {...formMethods}>
            <form
              className={sharedClasses.Form}
              onKeyDown={preventSubmitOnEnter}
              onSubmit={e => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
              }}
            >
              <FormHiddenInputs names={['id']} />
              <div className={sharedClasses.FormContent}>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextFormInput
                      label={t(TransKeys.EVENT_GROUP_FORM.INPUTS.NAME.LABEL)}
                      placeholder={t(TransKeys.EVENT_GROUP_FORM.INPUTS.NAME.PLACEHOLDER)}
                      name={'name'}
                      required
                    />
                  </div>
                </div>
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <TextareaFormInput
                      placeholder={t(TransKeys.EVENT_GROUP_FORM.INPUTS.DESCRIPTION.PLACEHOLDER)}
                      label={t(TransKeys.EVENT_GROUP_FORM.INPUTS.DESCRIPTION.LABEL)}
                      name={'shortDescription'}
                    />
                  </div>
                </div>
                {entityOptions.length > 1 && (
                  <div className={sharedClasses.Block}>
                    <div className={sharedClasses.Input}>
                      <LabelWrapper label={t(TransKeys.EVENT_GROUP_FORM.INPUTS.ENTITY)} fullWidth>
                        {!editMode && (
                          <SwitchActions
                            actions={entityOptions}
                            showActionsLabel
                            disabled={editMode}
                          />
                        )}
                        {editMode && (
                          <TitleWithIcon
                            text={productEntitiesMap[entity].name}
                            icon={getEntityIcon(entity)}
                            size={'medium'}
                          />
                        )}
                      </LabelWrapper>
                    </div>
                  </div>
                )}
                <div className={sharedClasses.Block}>
                  <div className={sharedClasses.Input}>
                    <LabelWrapper label={t(TransKeys.GENERAL.LABELS.DEFINE)} fullWidth>
                      {!isCustomQuery && (
                        <OptionTabs
                          tabs={tabs}
                          selectedKey={selectedTab}
                          onTabChange={setSelectedTab}
                        />
                      )}
                      {isCustomQuery && (
                        <Controller
                          render={({field}) => (
                            <QueryBuilder
                              config={queryBuilderConfig}
                              query={field.value}
                              onChange={field.onChange}
                              errors={errors['signalDefinition']}
                              disabled
                            />
                          )}
                          name={'signalDefinition'}
                          control={control}
                        />
                      )}
                    </LabelWrapper>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalLayout>
    </div>
  );
};

const EventGroupFormPanel = composition<AllProps>(
  EventGroupFormPanelComponent,
  withModalInactiveSourceHandler,
  withDisableDemoProduct,
  withLoadBefore({
    eventGroup: {
      selectedKey: SELECTED_EVENT_GROUP_KEY,
      actionKey: SELECTED_EVENT_GROUP_KEY,
      request: getEventGroupNetworkRequest,
      mapPayloadFromProps: props => props[EVENT_GROUP_ID_PATH_PARAM],
      shouldCall: props => props[EVENT_GROUP_ID_PATH_PARAM] !== undefined,
    },
  })
);

export default EventGroupFormPanel;
