import {Metric, MetricType, MetricValueType} from '../models/metric.model';
import yup from '../../config/yup.config';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import httpClientService from '../../services/http-client.service';
import {
  validateMetricDefinitionNetworkRequest,
  validateNameNetworkRequest,
} from '../../http/validations.network-requests';
import {SegmentCategory} from '../models/segment.model';
import {AggregationMode} from '../models/signal.model';
import {nullableValues} from '../../utils/yup.utils';
import {modelEventPropertiesDataSchema} from './model-event-properties.dto';
import {exists} from 'front-core';

import {querySchemaValidator, apiQuerySchemaValidator} from './query-builder.dto.ts';

export type MetricDTO = Partial<Metric>;
export type MetricCategoryDTO = Partial<SegmentCategory>;

export interface SwapCategoriesDTO {
  metricCategoryId: number;
  index: number;
  newIndex: number;
}

export interface SetMetricOwnerDTO {
  metricId: number;
  userId: number;
}

export interface UpdateTeamMetricsDTO {
  teamId: number;
  metrics: number[];
}

export interface AddMetricToTeamDTO {
  teamId: number;
  metricId: number;
}

export interface GetMetricGranularityOptionsDTO {
  definition: any;
  entity: TableEntity;
  metricType: MetricType;
}

export interface GetRCAForMetricByDateDTO {
  metricId: number;
  date: string;
  granularity: string;
}

export const metricDTOValidator = yup.object().shape(
  {
    id: yup.number().optional().nullable(),
    name: yup
      .string()
      .required()
      .test('unique_name', 'Name already exists', async function (name: string) {
        const {id} = this.parent;
        try {
          await httpClientService.exec(
            validateNameNetworkRequest({
              name,
              existingId: id,
              modelType: 'metric',
            })
          );
          return true;
        } catch (e) {
          return false;
        }
      }),
    type: yup.string().required(),
    entity: yup.string().oneOf(values(TableEntity)).required(),
    categoryId: yup.number().nullable(),
    shortDescription: yup.string().nullable(),
    signalDefinition: apiQuerySchemaValidator({
      networkRequest: validateMetricDefinitionNetworkRequest,
      extractData: data => ({
        definition: data.signalDefinition,
        entity: data.entity,
        metricType: data.type,
      }),
    }),
    signalPopulationFilter: querySchemaValidator(false),
    higherIsBetter: yup.boolean().required(),
    aggregationMode: yup
      .mixed()
      .oneOf(nullableValues(AggregationMode))
      .test({
        name: 'aggregationMode',
        test: function (aggregationMode) {
          const {type} = this.parent;
          if (!aggregationMode && !type) {
            return this.createError({
              message: `No aggregation mode and no metric type`,
            });
          }
          if (!aggregationMode && type) {
            if (type === MetricType.USAGE || type === MetricType.REVENUE) {
              return this.createError({
                message: `Aggregation mode is required for metric type ${type}`,
              });
            }
          }
          return true;
        },
      }),
    eventProperties: modelEventPropertiesDataSchema,
    sampledGranularities: yup
      .array()
      .of(yup.string())
      .min(1)
      .required('At least one granularity is required'),
    partialUnit: yup.string().when('partialCount', {
      is: partialCount => exists(partialCount),
      then: yup.string().oneOf(['day', 'week', 'month']).required(),
      otherwise: yup.string().nullable(),
    }),
    partialCount: yup.number().when('partialUnit', {
      is: partialUnit => exists(partialUnit),
      then: yup.number().min(0).required(),
      otherwise: yup.number().nullable(),
    }),
    valueType: yup.string().oneOf(nullableValues(MetricValueType)).optional().nullable(),
  },
  // @ts-ignore
  ['partialUnit', 'partialCount']
);

export const metricCategoryValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup.string().required(),
});
