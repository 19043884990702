import * as React from 'react';
import {UnifiedRCASegmentationItem} from '../../../../../../rca-segmentation-extended.types';
import {withStopPropagation} from 'front-core';
import {TooltipIfOverflow} from '../../../../../../../../../../../../simple/generic/tooltips/tooltips.component';
import classes from './segment-group-name-display.module.scss';
import {CompositeDisplay} from '../../../../../../../../../shared/display-columns/composite-display.component';
import classNames from 'classnames';
import {useMemo} from 'react';
import {useDocumentTranslation} from '../../../../../../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {SimilarityIndication} from '../similarity-indication/similarity-indication.component';
import {generateSegmentName} from '../../../../segment-explainers-table.utils';
import {ArrowRightLongLightIcon} from '../../../../../../../../../../../../simple/controls/icons/icons.component';

interface Props {
  item: UnifiedRCASegmentationItem;
  primary?: UnifiedRCASegmentationItem;
  onSignalClicked: (signalId: number) => void;
  showSimilarity?: boolean;
  className?: string;
}

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

export const SegmentNameDisplay: React.FC<Props> = (props: Props) => {
  const {item, primary, onSignalClicked, showSimilarity, className} = props;
  const isComposite = item.segmentKeys && item.segmentKeys.length > 1;
  const {t} = useDocumentTranslation();

  const similarityProps = useMemo(() => {
    let similarityProps: any;
    let helperText = '';

    if (showSimilarity) {
      similarityProps = {
        similarityType: item.similarityType,
        isPrimary: item.isPrimaryInGroup,
      };
      if (item.similarityType) {
        helperText = t(
          TRANS_KEYS_PREFIX.SIMILARITY_TYPE[item.similarityType.toUpperCase()]?.HELPER,
          {segment: generateSegmentName(primary)}
        );
      }
      if (item.isPrimaryInGroup) {
        helperText = t(TRANS_KEYS_PREFIX.SIMILARITY_TYPE.PRIMARY.HELPER);
      }
      similarityProps.helperText = helperText;
    }
    return similarityProps;
  }, [item, showSimilarity]);

  const render = (item: UnifiedRCASegmentationItem) => (
    <div className={classes.ContentWrapper} key={item.key}>
      <TooltipIfOverflow title={item.segmentGroupName}>
        <div
          className={classes.SegmentGroup}
          onClick={withStopPropagation(() => onSignalClicked(item.signalId))}
        >
          {item.segmentGroupName}
        </div>
      </TooltipIfOverflow>
      <ArrowRightLongLightIcon />
      <TooltipIfOverflow title={item.segmentName}>
        <div className={classes.SegmentName}>{item.segmentName}</div>
      </TooltipIfOverflow>
    </div>
  );

  let content = null;
  if (isComposite) {
    content = (
      <CompositeDisplay prefix={'AND'} className={classes.Composite}>
        {item.segments.map(s => render(s))}
      </CompositeDisplay>
    );
  } else {
    content = render(item);
  }

  return (
    <div className={classNames(classes.SegmentGroupNameDisplay, className)}>
      {showSimilarity && <SimilarityIndication {...similarityProps} />}
      {content}
    </div>
  );
};
