import * as React from 'react';
import {useEffect, useState} from 'react';
import {merge, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, mapTo, throttleTime} from 'rxjs/operators';

interface OwnProps {
  children: any;
  placeholder?: any;
  timeout?: number;
}

type AllProps = OwnProps;

export const ResizeRender: React.FC<AllProps> = (props: AllProps) => {
  const {timeout, placeholder, children} = props;
  const [show, setShow] = useState(true);

  useEffect(() => {
    const subject = new Subject<void>();
    const onResize = () => subject.next();
    window.addEventListener('resize', onResize);

    const obs1: any = subject.pipe(mapTo(true), debounceTime(timeout));
    const obs2: any = subject.pipe(mapTo(false), throttleTime(timeout));
    const subs = merge(obs1, obs2).pipe(distinctUntilChanged()).subscribe(setShow);

    return () => {
      subs.unsubscribe();
      window.removeEventListener('resize', onResize);
    };
  }, [timeout]);

  return show ? children : placeholder;
};

ResizeRender.defaultProps = {
  placeholder: null,
  timeout: 500,
};
