import {ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './analysis-result-list.module.scss';
import {
  AnalysisResult,
  AnalysisResultStatus,
} from '../../../../objects/models/analysis-result.model';
import {FlexHorizontal} from '../../../shared/components/layout/flex-layout/general-flex-layouts.component.';
import {
  ModelActionItem,
  ModelActionsDropdown,
} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component';
import {
  ArchiveIcon,
  ArrowRightLightIcon,
  Chip,
  ChipVariant,
  ClockIcon,
  CopyIcon,
  EditIcon,
  EyeIcon,
  HoverHelperTip,
  IconButton,
  InteractionContext,
  InteractionType,
  LoopsIcon,
  ModalLayout,
  MoreIcon,
  RenameIcon,
  RerunIcon,
  ScheduledAnalysisIcon,
  ShareIcon,
  StarLightIcon,
  StarSolidIcon,
  StopIcon,
  ToggleIconButton,
  TooltipIfOverflow,
} from 'ui-components';
import TransKeys from '../../../../constants/translation-keys';
import {Modal, Tooltip} from '@material-ui/core';
import cronstrue from 'cronstrue';
import {toLocalTime, withStopPropagation} from '../../../../utils/general.utils';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {AnalysisParameters} from '../analysis-parameters/analysis-parameters.component';
import {AppSources} from '../../../../constants/app-sources';
import {useTranslation} from 'react-i18next';
import {ANALYSIS_RESULT_ID_PATH_PARAM, AppRoutes} from '../../../../constants/app-routes';
import {sharedClasses} from '../../../shared';
import i18n from '../../../../config/i18n.config';
import {isAnalysisResultProcessing} from '../../../../utils/analysis-result.utils';
import {AnalysisResultActions} from '../../hooks/use-analysis-result-actions.hook';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';
import {useCurrentUser} from '../../../../core/hooks/use-user.hook';
import {translateAnalysisFolderName} from '../../pages/analyses-main/analyses.utils';
import {AnalysisResultStatusDisplay} from '../analysis-result-status-display/analysis-result-status-display.component';
import moment from 'moment';
import {QuerySqlTabs} from '../../../shared/core/query-sql-tabs/query-sql-tabs.component';
import {exists} from 'front-core';
import {GridTable} from '../../../shared/components/general/grid-table/grid-table.component';
import {TableColumn} from '../../../shared/components/general/grid-table/grid-table.types';
import {BetterNavLink} from '../../../shared/core/override/better-nav-link.component.tsx';

interface OwnProps extends AnalysisResultActions {
  data: AnalysisResult[];
  onFolderClicked?: (analysisFolderId: number) => void;
  paginationMode?: 'pages' | 'load-more';
  onPageChange?: (page: number) => void;
  onLoadMore?: () => void;
  onSort?: (key: string) => void;
  isLoading?: boolean;
  order?: 'asc' | 'desc';
  orderBy?: string;
  total: number;
  page: number;
  perPage?: number;
  emptyStateProps?: any;
  emptyStateRow?: any;
  showFolderColumn?: boolean;
  showActionsColumn?: boolean;
  showStar?: boolean;
  disableEditing?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const IS_NEW_DIFF_DAYS = 3;

const getChipVariantForResult = (
  analysisResult: AnalysisResult
): {variant: ChipVariant; label: string; helperText?: string} => {
  if (analysisResult.deprecatedByUser?.fullName) {
    return {
      variant: ChipVariant.WARN,
      label: i18n.t(TransKeys.GENERAL.LABELS.DEPRECATED),
      helperText: i18n.t(TransKeys.ANALYSIS_RESULT.DEPRECATION_TOOLTIP_TITLE, {
        fullName: analysisResult.deprecatedByUser.fullName,
      }),
    };
  }
  if (analysisResult.isExample) {
    return {
      variant: ChipVariant.EXAMPLE,
      label: i18n.t(TransKeys.GENERAL.LABELS.EXAMPLE),
    };
  }
  if (analysisResult.isFollowUp) {
    return {
      variant: ChipVariant.FOLLOW_UP,
      label: i18n.t(TransKeys.GENERAL.LABELS.FOLLOW_UP),
    };
  }
  // calc is new
  const isNew =
    moment(analysisResult.createdOn).add(IS_NEW_DIFF_DAYS, 'd').isAfter(moment()) &&
    !analysisResult.isViewed &&
    !analysisResult.isDismissed;

  if (isNew) {
    return {
      variant: ChipVariant.NEW,
      label: i18n.t(TransKeys.GENERAL.LABELS.NEW),
    };
  }
  return undefined;
};

export const isRunningForUser = (status: AnalysisResultStatus) =>
  [
    AnalysisResultStatus.RUNNING,
    AnalysisResultStatus.QUEUED,
    AnalysisResultStatus.REQUESTED,
  ].indexOf(status) > -1;

export const AnalysisResultList = (props: AllProps) => {
  const {
    data,
    onFolderClicked,
    order,
    orderBy,
    onView,
    onExplore,
    onDelete,
    onStop,
    onEdit,
    disableEditing,
    onShare,
    onSchedule,
    onRerun,
    onRename,
    onDuplicate,
    onPageChange,
    onSort,
    onChangeFolder,
    total,
    page,
    perPage,
    isLoading,
    showFolderColumn = true,
    showActionsColumn = true,
    showStar = true,
    className,
    emptyStateRow,
    emptyStateProps,
    paginationMode = 'pages',
  } = props;
  const isAdmin = useIsAdmin();
  const {t} = useTranslation();
  const user = useCurrentUser();
  const {postMessage} = useContext(InteractionContext);
  const [viewQuery, setViewQuery] = useState(undefined);
  const generateActionsForResult = useCallback(
    (analysisResult: AnalysisResult): ModelActionItem[] => {
      const isOwner = analysisResult.requestedByUserId === user.id;
      const isDeprecated = Boolean(analysisResult.deprecatedByUser?.fullName);
      const isDeprecatedHelperText = isDeprecated
        ? {
            helperText: t(TransKeys.ANALYSIS_RESULT.DEPRECATION_TOOLTIP_TITLE, {
              fullName: analysisResult.deprecatedByUser.fullName,
            }),
          }
        : {};

      return [
        {
          key: 'view',
          title: t(TransKeys.GENERAL.ACTIONS.VIEW),
          onClick: _ => onView(analysisResult),
          icon: EyeIcon,
          showEnabled: true,
        },
        {
          key: 'rename',
          title: t(TransKeys.GENERAL.ACTIONS.RENAME),
          onClick: _ => onRename(analysisResult),
          icon: RenameIcon,
          disabled: !isOwner || analysisResult.isExample,
          hide: analysisResult.deletedAt !== null,
          showEnabled: true,
        },
        {
          key: 'duplicate',
          title: t(TransKeys.GENERAL.ACTIONS.DUPLICATE),
          onClick: _ => onDuplicate(analysisResult),
          icon: CopyIcon,
          hide: analysisResult.deletedAt !== null,
          showEnabled: true,
        },
        {
          key: 'stop',
          title: t(TransKeys.GENERAL.ACTIONS.STOP),
          onClick: _ => onStop(analysisResult),
          icon: StopIcon,
          hide:
            !isAnalysisResultProcessing(analysisResult, true) || analysisResult.deletedAt !== null,
          disabled: !isOwner || isDeprecated,
          ...isDeprecatedHelperText,
        },
        {
          key: 'edit',
          title: t(TransKeys.GENERAL.ACTIONS.EDIT),
          onClick: _ => onEdit(analysisResult),
          icon: EditIcon,
          disabled: disableEditing || !isOwner || isDeprecated,
          hide: analysisResult.deletedAt !== null,
          ...isDeprecatedHelperText,
          showEnabled: true,
        },
        {
          key: 'move-to-folder',
          title: t(TransKeys.GENERAL.ACTIONS.MOVE_TO_FOLDER),
          onClick: _ => onChangeFolder(analysisResult),
          icon: ArrowRightLightIcon,
          disabled: analysisResult.isExample,
          hide: analysisResult.deletedAt !== null,
          showEnabled: true,
        },
        {
          key: 'archive',
          title:
            analysisResult.deletedAt === null
              ? t(TransKeys.GENERAL.ACTIONS.ARCHIVE)
              : t(TransKeys.GENERAL.ACTIONS.UNARCHIVE),
          onClick: _ => onDelete(analysisResult),
          icon: ArchiveIcon,
          showEnabled: false,
        },
        {
          key: 'schedule',
          title: !analysisResult.cronExp
            ? t(TransKeys.GENERAL.ACTIONS.SCHEDULE)
            : t(TransKeys.GENERAL.ACTIONS.EDIT_SCHEDULE),
          onClick: _ => onSchedule(analysisResult),
          icon: ClockIcon,
          disabled:
            !isOwner ||
            isDeprecated ||
            analysisResult.isExample ||
            isRunningForUser(analysisResult.status),
          ...isDeprecatedHelperText,
          hide: analysisResult.deletedAt !== null,
          helperText: isRunningForUser(analysisResult.status) ? 'already running' : '',
          showEnabled: true,
        },
        {
          key: 'rerun',
          title: t(TransKeys.GENERAL.ACTIONS.RERUN),
          onClick: _ => onRerun(analysisResult),
          icon: RerunIcon,
          disabled: isDeprecated || analysisResult.isExample,
          ...isDeprecatedHelperText,
          hide: analysisResult.deletedAt !== null,
        },
      ];
    },
    [
      t,
      onView,
      onDelete,
      onSchedule,
      onRerun,
      onStop,
      onEdit,
      disableEditing,
      onDuplicate,
      onChangeFolder,
      onRename,
      user,
    ]
  );

  const contentWrapper = useCallback(
    (analysisResult: AnalysisResult, children: ReactNode) => (
      <BetterNavLink
        key={analysisResult.id}
        className={sharedClasses.UnstyledLink}
        to={AppRoutes.viewAnalysis(analysisResult.analysisId, {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: analysisResult.id,
        })}
      >
        {children}
      </BetterNavLink>
    ),
    []
  );
  const onGoalClicked = useCallback(
    (analysisResult: AnalysisResult) => {
      if (analysisResult.analysisGoal.modelType) {
        postMessage({
          type: InteractionType.REFERENCE,
          payload: {
            modelId: analysisResult.analysisGoal.modelId,
            modelType: analysisResult.analysisGoal.modelType.toUpperCase(),
          },
        });
      } else {
        setViewQuery(analysisResult.parameters[analysisResult.analysisGoal.parameterName]);
      }
    },
    [postMessage, setViewQuery]
  );
  const columns: TableColumn[] = useMemo(
    () => [
      {
        key: 'name',
        title: t(TransKeys.GENERAL.HEADERS.NAME),
        sortable: false,
        width: '38rem',
        stretch: true,
        sticky: 'left',
        render: (analysisResult: AnalysisResult) => {
          const chipProps = getChipVariantForResult(analysisResult);
          return (
            <div className={classes.NameColumn}>
              <div className={classes.Start}>
                {showStar && (
                  <ToggleIconButton
                    titleChecked={t(TransKeys.GENERAL.ACTIONS.UNEXPLORE)}
                    titleUnchecked={t(TransKeys.GENERAL.ACTIONS.EXPLORE)}
                    onChange={_ => onExplore(analysisResult)}
                    isChecked={analysisResult.isExplored}
                    icon={StarLightIcon}
                    checkedIcon={StarSolidIcon}
                    className={classes.FavButton}
                    border={false}
                    size={'small'}
                  />
                )}
              </div>
              <AnalysisResultStatusDisplay
                analysisResult={analysisResult}
                className={classes.Status}
              />
              <div className={classes.Main}>
                <div className={classes.Primary}>
                  {chipProps && <Chip className={classes.Chip} {...chipProps} />}
                  <TooltipIfOverflow
                    title={analysisResult.userAnalysisName || analysisResult.analysisName}
                  >
                    <span
                      className={classNames(
                        classes.AnalysisName,
                        !analysisResult.isViewed && classes.Bold
                      )}
                    >
                      {analysisResult.userAnalysisName || analysisResult.analysisName}
                    </span>
                  </TooltipIfOverflow>
                  {analysisResult.userNotes && (
                    <HoverHelperTip title={analysisResult.userNotes} className={classes.Helper} />
                  )}
                </div>
                <div className={classes.Secondary}>
                  <span className={classes.AnalysisType}>{analysisResult.analysisTypeName}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        key: 'goal',
        title: t(TransKeys.GENERAL.LABELS.KPI),
        width: '18rem',
        render: (analysisResult: AnalysisResult) => {
          if (!analysisResult.analysisGoal) {
            return null;
          }
          return (
            <TooltipIfOverflow title={analysisResult.analysisGoal.name}>
              <div
                className={classNames(classes.GoalRef, !analysisResult.isViewed && classes.Bold)}
                onClick={withStopPropagation(() => onGoalClicked(analysisResult))}
              >
                {analysisResult.analysisGoal.name}
              </div>
            </TooltipIfOverflow>
          );
        },
      },
      {
        key: 'createdBy',
        title: t(TransKeys.GENERAL.LABELS.CREATED_BY),
        width: '14rem',
        render: (analysisResult: AnalysisResult) => {
          const isInternalUser =
            analysisResult.requestedByUser.isInternalUser ||
            analysisResult.requestedByUser.id === null;
          return (
            <div className={classNames(classes.Label, !analysisResult.isViewed && classes.Bold)}>
              {isInternalUser && (
                <Tooltip
                  title={isAdmin ? analysisResult.requestedByUser.fullName || '' : ''}
                  placement={'top'}
                  interactive={false}
                >
                  <div className={classes.Loops}>
                    <LoopsIcon className={classes.LoopsIcon} />
                    Loops
                  </div>
                </Tooltip>
              )}
              {!isInternalUser && (
                <TooltipIfOverflow title={analysisResult.requestedByUser.fullName}>
                  <span className={classes.Text}>{analysisResult.requestedByUser.fullName}</span>
                </TooltipIfOverflow>
              )}
            </div>
          );
        },
      },
      {
        key: 'createdOn',
        title: t(TransKeys.GENERAL.LABELS.CREATED),
        sortable: true,
        width: showActionsColumn ? '15rem' : '18rem',
        render: (analysisResult: AnalysisResult) => {
          const text = `${toLocalTime(analysisResult.createdOn, TIME_FORMATS.READABLE_DATE_TIME)}`;
          return (
            <div className={classNames(classes.Label, classes.CreatedOn)}>
              <TooltipIfOverflow title={text}>
                <span className={classes.Text}>{text}</span>
              </TooltipIfOverflow>
              {analysisResult.cronExp && (
                <Tooltip
                  placement={'top'}
                  title={cronstrue.toString(analysisResult.cronExp, {
                    verbose: true,
                  })}
                >
                  <ScheduledAnalysisIcon className={classes.ScheduledAnalysisIcon} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        key: 'analysisFolder',
        title: t(TransKeys.GENERAL.LABELS.FOLDER),
        width: '14rem',
        sortable: true,
        hidden: !showFolderColumn,
        render: (a: AnalysisResult) => {
          const folderName = translateAnalysisFolderName(
            a.analysisFolder.name,
            a.analysisFolder.isPrivateOther
          );
          if (a.analysisFolder.isPrivateOther) {
            return <div className={classes.NoAccess}>{folderName}</div>;
          }
          return (
            <TooltipIfOverflow title={folderName} interactive={false}>
              <div
                className={classes.AnalysisFolder}
                onClick={withStopPropagation(() => onFolderClicked(a.analysisFolderId))}
              >
                {folderName}
              </div>
            </TooltipIfOverflow>
          );
        },
      },
      {
        key: 'actions',
        title: '',
        sortable: false,
        width: '12rem',
        align: 'right',
        sticky: 'right',
        hidden: !showActionsColumn,
        render: (analysisResult: AnalysisResult) => {
          return (
            <FlexHorizontal verticalAlignCenter spacing>
              <AnalysisParameters
                className={classes.InlineAction}
                label={t(TransKeys.GENERAL.LABELS.PARAMETERS)}
                analysisResultId={analysisResult.id}
                appSource={AppSources.ANALYSIS_RESULTS_MAIN__VIEW_PARAMETERS}
                asIcon
              />
              <IconButton
                tooltipText={t(TransKeys.GENERAL.ACTIONS.SHARE)}
                icon={ShareIcon}
                onClick={() => onShare(analysisResult)}
              />
              <ModelActionsDropdown
                actions={generateActionsForResult(analysisResult)}
                label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
                icon={MoreIcon}
                iconDropdown
              />
            </FlexHorizontal>
          );
        },
      },
    ],
    [
      t,
      onExplore,
      onShare,
      onFolderClicked,
      onGoalClicked,
      generateActionsForResult,
      showFolderColumn,
      showActionsColumn,
      showStar,
      isAdmin,
    ]
  );
  const rowClassName = useCallback(analysisResult => {
    if (analysisResult.isViewed) {
      return classes.ViewedRow;
    }
  }, []);

  return (
    <>
      <GridTable
        className={classNames(classes.AnalysisResultList, className)}
        data={data}
        dataKey={'id'}
        columns={columns}
        pagination={Boolean(onPageChange)}
        paginationMode={paginationMode}
        onPageChange={(_, page) => onPageChange(page + 1)}
        total={total}
        page={page - 1}
        perPage={perPage}
        onSort={onSort ? (_, key) => onSort(key) : undefined}
        order={order}
        orderBy={orderBy}
        emptyStateProps={emptyStateProps}
        emptyStateRow={emptyStateRow}
        contentWrapper={contentWrapper}
        rowClassName={rowClassName}
        isLoading={isLoading}
      />
      {viewQuery && (
        <Modal
          onClick={e => e.stopPropagation()}
          className={classes.QueryModalWrapper}
          open={exists(viewQuery)}
          onClose={() => setViewQuery(undefined)}
        >
          <ModalLayout className={classes.Modal} onClose={() => setViewQuery(undefined)}>
            <div className={classes.QueryBuilder}>
              <QuerySqlTabs query={viewQuery} />
            </div>
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};
