import {
  RCAInsightSegmentSentenceParts,
  RCAInsightSentence,
  SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease,
} from '../rca-insights.model.ts';
import {
  ChangeInShareOfEntitiesSentencePart,
  SegmentNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease;
  sentenceParts: RCAInsightSegmentSentenceParts;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {entity, entityChangeDescription, changeInShareOfEntities, presentTensePerformanceVsAvg} =
    formattedSentenceParts;
  const {segmentViewData} = sentenceParts;

  return (
    <SentenceContainer>
      <SegmentNameSentencePart data={segmentViewData} />
      <SentencePart text={`share of ${entity} ${entityChangeDescription}d by`} />
      <ChangeInShareOfEntitiesSentencePart changeInShareOfEntities={changeInShareOfEntities} />
      <SentencePart
        text={`vs the analyzed period. This segment is ${presentTensePerformanceVsAvg} vs the average.`}
      />
    </SentenceContainer>
  );
};

export {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter};
