import {AICMessageFeedback} from '../models/ai-chat.model';

export interface ASKAIChatDTO {
  aiConversionThreadId: number;
  message: string;
}

export interface AIChatMessageFeedbackDTO {
  aiConversationMessageId: number;
  feedback: AICMessageFeedback;
}

export interface UpdateAIChatThreadDTO {
  threadId: number;
  title: string;
}

export type AIChatMessageSuggestionDTO = {
  type: AIChatMessageSuggestionType;
  message: string;
};

export enum AIChatMessageSuggestionType {
  DROP = 'drop',
  COMPARE = 'compare',
  USER = 'user',
}
