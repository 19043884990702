import {XTicksRangeTimeUnit} from './chart-data.types';

export interface BoundaryBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export enum AnnotationStatus {
  IDLE = 'idle',
  DISMISS = 'dismiss',
  ACKNOWLEDGE = 'acknowledge',
}

export interface AxisProperties {
  range?: number[];
  label?: string;
  suffix?: string;
  minValue?: number;
  maxValue?: number;
  maxTicks?: number;
}

export interface XAxisProperties extends AxisProperties {
  isTimeRangeMS?: boolean;
  timeUnit?: XTicksRangeTimeUnit;
}

export enum AXIS_VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
