import classes from './message-viewer.module.scss';
import {MESSAGE_RENDERER_MAPPING} from './message-viewer.config';
import {FallbackMessage} from './components/fallback-message/fallback-message.component';
import {BaseMessageRendererProps} from './message-viewer.types';
import classNames from 'classnames';
import {
  AICMessageFeedback,
  AIConversationRole,
} from '../../../../../../objects/models/ai-chat.model';
import {CodeLightIcon, IconButton, ModalLayout, ThumbDownIcon, ThumpUpIcon} from 'ui-components';
import {Modal, Tooltip} from '@material-ui/core';
import {useIsAdmin} from '../../../../../../core/hooks/use-is-admin.hook';
import {useState} from 'react';
import {ImagesResource} from '../../../../../../assets/images';
import TransKeys from '../../../../../../constants/translation-keys';
import {useTranslation} from 'react-i18next';

interface Props extends BaseMessageRendererProps<any> {
  onMessageFeedback: (messageId: number, feedback: AICMessageFeedback) => void;
}

export const MessageViewer = (props: Props) => {
  const {message, onMessageFeedback} = props;
  const {t} = useTranslation();
  const Component = MESSAGE_RENDERER_MAPPING[message.type] || FallbackMessage;
  const [showDebug, setShowDebug] = useState(false);
  const isAdmin = useIsAdmin();

  return (
    <>
      <div
        className={classNames(
          classes.MessageViewer,
          message.role === AIConversationRole.USER ? classes.UserMessage : classes.BotMessage
        )}
      >
        {message.role === AIConversationRole.ASSISTANT && (
          <div className={classes.Avatar} onClick={isAdmin ? () => setShowDebug(true) : undefined}>
            <Tooltip title={'Loops Bot'} placement={'top'} interactive={false}>
              <img src={ImagesResource.app.loopsAIAssistantPurple} className={classes.ChatAvatar} />
            </Tooltip>
          </div>
        )}
        <div
          className={classNames(
            classes.MessageContent,
            message.role == null && classes.NoRoleMessageContent,
            message.role === AIConversationRole.ASSISTANT && classes.BotMessageContent,
            message.role === AIConversationRole.USER && classes.UserMessageContent
          )}
        >
          <Component {...props} />
          {message.role === AIConversationRole.ASSISTANT && (
            <div className={classes.Feedback}>
              <IconButton
                border={false}
                tooltipText={
                  <span>{t(TransKeys.GEN_AI_BOT.MESSAGE.ACTION.THUMBS_UP.TOOLTIP)}</span>
                }
                className={classNames(
                  classes.FeedbackButton,
                  message.feedback === AICMessageFeedback.CORRECT &&
                    classes.FeedbackButtonActiveGreen
                )}
                icon={ThumpUpIcon}
                onClick={() => onMessageFeedback(message.id, AICMessageFeedback.CORRECT)}
              />
              <IconButton
                border={false}
                tooltipText={
                  <span>{t(TransKeys.GEN_AI_BOT.MESSAGE.ACTION.THUMBS_DOWN.TOOLTIP)}</span>
                }
                className={classNames(
                  classes.FeedbackButton,
                  message.feedback === AICMessageFeedback.INCORRECT &&
                    classes.FeedbackButtonActiveRed
                )}
                icon={ThumbDownIcon}
                onClick={() => onMessageFeedback(message.id, AICMessageFeedback.INCORRECT)}
              />
            </div>
          )}
        </div>
      </div>
      {showDebug && (
        <Modal
          onClick={e => e.stopPropagation()}
          className={classes.DebugModalWrapper}
          open={showDebug}
          onClose={() => setShowDebug(false)}
        >
          <ModalLayout className={classes.Modal}>
            <textarea className={classes.Textarea}>{JSON.stringify(message, null, 2)}</textarea>
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};
