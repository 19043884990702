import {safeDivision} from 'front-core';
import {KPISegmentationType, RetentionSegmentationItem} from '../../../../types';
import {ExtendedRetentionSegmentationItem} from './retention-segmentation-viewer.component';

const TYPE_MAPPING = {
  [KPISegmentationType.POSITIVE]: KPISegmentationType.POSITIVE,
  [KPISegmentationType.INSIGNIFICANT_POSITIVE]: KPISegmentationType.INSIGNIFICANT,
  [KPISegmentationType.NO_RECOMMENDATION]: KPISegmentationType.NO_RECOMMENDATION,
};

export const extendRetentionSegmentationItems = (
  items: RetentionSegmentationItem[],
  goalValue: number,
  totalEntities: number
): ExtendedRetentionSegmentationItem[] => {
  let newItems: ExtendedRetentionSegmentationItem[] = items as any;
  newItems = newItems.map(item => {
    const size = item.count;
    const share = safeDivision(size, totalEntities);
    const uplifts = [];
    const avgUplift = [];
    for (let i = 0; i < item.buckets.length; i++) {
      uplifts.push(
        safeDivision(
          item.buckets[i].goalInSegment - item.buckets[i].goalInNonSegment,
          Math.abs(item.buckets[i].goalInNonSegment)
        )
      );
      avgUplift.push(safeDivision(item.buckets[i].goalInSegment - goalValue, Math.abs(goalValue)));
    }
    const arrayBucketValues = item.buckets.reduce(
      (acc, curr) => ({
        goalInSegment: [...acc.goalInSegment, curr.goalInSegment],
        goalInNonSegment: [...acc.goalInNonSegment, curr.goalInNonSegment],
        bucketCount: [...acc.bucketCount, curr.count],
        potentialLift: [...acc.potentialLift, curr.potentialLift],
        potentialLiftUpper: [...acc.potentialLiftUpper, curr.potentialLiftUpper],
        potentialLiftLower: [...acc.potentialLiftLower, curr.potentialLiftLower],
        helperText: [...acc.helperText, curr.helperText],
        info: [...acc.info, curr.info],
        type: [...acc.type, TYPE_MAPPING[curr.type]],
        actualType: [...acc.type, curr.type],
      }),
      {
        goalInSegment: [],
        goalInNonSegment: [],
        potentialLift: [],
        potentialLiftUpper: [],
        potentialLiftLower: [],
        helperText: [],
        info: [],
        type: [],
        bucketCount: [],
      }
    );

    return {
      ...item,
      share: share,
      uplift: uplifts,
      avgUplift: avgUplift,
      ...arrayBucketValues,
      // useSimulator interface
      value: item.stableValue,
    };
  });

  return newItems;
};
