import {MetricSampleData} from '../../../types';

export const getFirstAndLastPartialSamples = (samples: MetricSampleData[]) => {
  let firstPartial = null;
  let lastPartial = null;

  for (const sample of samples) {
    if (sample.isPartial) {
      firstPartial = firstPartial === null ? sample : firstPartial;
      lastPartial = sample;
    }
  }

  return {
    firstPartial,
    lastPartial,
  };
};
