import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import {
  DocumentElementType,
  PowerUsersSegmentationFigure,
  PowerUsersSegmentationItem,
} from '../../../../types';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {groupBy, keys} from 'lodash';
import {exists} from 'front-core';
import {PowerUsersSegmentationDataModeTable} from './components/power-users-segmentation-tables/power-users-segmentation-data-mode-table.component';
import {PowerUsersSegmentationModal} from './components/power-users-segmentation-modal/power-users-segmentation-modal.component';
import {extendPowerUsersSegmentationItems} from './power-users-segmentation-viewer.utils';
import classes from './power-users-segmentation-viewer.module.scss';
import TransKeys from 'translations';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {Select} from '../../../../../../forms/inputs/select/select.component';
import {UserGroupLightIcon} from '../../../../../../simple/controls/icons/icons.component';

interface OwnProps extends PowerUsersSegmentationFigure {
  className?: string;
}

type AllProps = OwnProps;

export interface ExtendedPowerUsersSegmentationItem extends PowerUsersSegmentationItem {
  key: string;
  uplift: number;
  share: number;
  groupInsignificant?: boolean;
  valueInNonSegment: number;
}

export interface PowerUsersSegmentGroup {
  name: string;
  segments: ExtendedPowerUsersSegmentationItem[];
}

const MIN_GROUPS_FOR_SEARCH = 6;

interface Filters {
  group?: string;
  // shareOfUsers?: {min: number; max: number};
}

const DEFAULT_FILTERS: Filters = {
  group: null,
  // shareOfUsers: null,
};

export const PowerUsersSegmentationFigureViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data: dataFromProps, options, className} = props;
  const {t} = useDocumentTranslation();
  const {trackModalOpened, trackFilter} = useDocumentTracking(
    id,
    DocumentElementType.POWER_USERS_SEGMENTATION_FIGURE
  );
  const [selectedGroup, setSelectedGroup] = useState<PowerUsersSegmentGroup>(null);
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(
    id,
    DEFAULT_FILTERS,
    'filters'
  );
  const data = useMemo(
    () => ({
      ...dataFromProps,
      items: extendPowerUsersSegmentationItems(
        dataFromProps.items,
        dataFromProps.totalEntityCount,
        dataFromProps.powerPercentile
      ),
    }),
    [dataFromProps]
  );
  const tableData = useMemo(() => {
    let tableData = data.items;
    if (filters.group) {
      tableData = tableData.filter(i => i.segmentGroupName[0] === filters.group);
    }
    return tableData;
  }, [data, filters]);
  const itemsGroupedBySegmentGroup = useMemo(
    () => groupBy(data.items, 'segmentGroupName.0'),
    [data.items]
  );
  const groupNames = useMemo(() => keys(itemsGroupedBySegmentGroup), [itemsGroupedBySegmentGroup]);
  const groupOptions = useMemo(
    () => groupNames.map(o => ({value: o, label: o})),
    [data.items, groupNames]
  );
  const onGroupSelected = useCallback(
    (groupName: string) => {
      if (!exists(groupName)) {
        setSelectedGroup(null);
        return;
      }
      const group = itemsGroupedBySegmentGroup[groupName];
      setSelectedGroup({
        name: groupName,
        segments: group,
      });
      trackModalOpened('view_segment_group', {name: groupName});
    },
    [itemsGroupedBySegmentGroup, trackModalOpened]
  );
  const onFilterChange = useCallback(
    (newFilters: Filters) => {
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      }));
      const filterKey = keys(newFilters)[0];
      trackFilter(filterKey, {
        filter: newFilters[filterKey],
      });
    },
    [setFilters, trackFilter]
  );

  return (
    <div className={classNames(classes.PowerUsersSegmentationViewer, className)}>
      <div className={classes.TabsWrapper}>
        <div className={classes.Filters}>
          <Select
            className={classes.Filter}
            dropdownButtonClassName={classes.Select}
            placeholder={t(
              TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS.GROUP_NAME.LABEL
            )}
            value={filters.group}
            onChange={group => onFilterChange({group} as any)}
            clearable
            searchable={groupNames.length > MIN_GROUPS_FOR_SEARCH}
            icon={UserGroupLightIcon}
            options={{
              options: groupOptions,
            }}
          />
        </div>
      </div>
      <div className={classes.Content}>
        <PowerUsersSegmentationDataModeTable
          figureId={id}
          data={data}
          options={options}
          items={tableData}
          totalEntities={data.totalEntityCount}
          onSeeAll={onGroupSelected}
        />
      </div>
      <div className={className}>
        {selectedGroup && (
          <PowerUsersSegmentationModal
            group={selectedGroup}
            groupOptions={groupNames}
            onSelectGroup={onGroupSelected}
            entity={options.entity}
            totalEntities={data.totalEntityCount}
          />
        )}
      </div>
    </div>
  );
};

PowerUsersSegmentationFigureViewer.defaultProps = {};
