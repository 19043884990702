import {FreeTextDialog} from '../../forms/dialogs/free-text-dialog.component';
import {BooleanDialog} from '../../forms/dialogs/boolean-dialog.component';
import {EnumDialog} from '../../forms/dialogs/enum-dialog.component';
import {DateDialog} from '../../forms/dialogs/date-dialog.component';
import {DateRangeDialog} from '../../forms/dialogs/date-range-dialog.component';
import {APICallDialog} from '../../forms/dialogs/api-call-dialog.component';
import {InputType} from '../../forms/inputs.types';

export const FilterComponents: any = {
  [InputType.FREE_TEXT]: FreeTextDialog,
  [InputType.BOOLEAN]: BooleanDialog,
  [InputType.ENUM]: EnumDialog,
  [InputType.DATE]: DateDialog,
  [InputType.DATE_RANGE]: DateRangeDialog,
  [InputType.API_CALL]: APICallDialog,
};
