import * as React from 'react';
import {ShapeProps} from '../../../../../../core/konva/hoc/enhanced-shape.hoc';
import {EnhancedCircle, EnhancedGroup, EnhancedRect} from '../../../../../../core/konva/components';
import {colorAlphaTransformer} from '../../../../../../utils/colors';

interface OwnProps {
  y: number;
  width: number;
  circleX: number;
  rectX: number;
  color: string;
  value: number;
  upper: number;
  lower: number;
  datasetLabel: string;
}

type AllProps = ShapeProps & OwnProps;

export const DumbbellItem: React.FC<AllProps> = (props: AllProps) => {
  const {x, y, width, color, circleX, rectX, value, upper, lower, datasetLabel, onClick} = props;

  return (
    <EnhancedGroup onClick={onClick} x={x}>
      <EnhancedRect
        y={y}
        x={rectX}
        width={width}
        height={6}
        cornerRadius={8}
        strokeWidth={1}
        stroke={color}
        fillLinearGradientStartPoint={{x: width, y: 0}}
        fillLinearGradientEndPoint={{x: 0, y: 0}}
        fillLinearGradientColorStops={[
          0,
          colorAlphaTransformer(color, 0.4, true),
          0.5,
          colorAlphaTransformer(color, 0.6, true),
          1,
          colorAlphaTransformer(color, 0.7, true),
        ]}
        centerY
        listening={false}
      />
      <EnhancedGroup
        x={circleX}
        y={y}
        tooltipEnabled
        tooltipData={{
          value: (
            <>
              <div>
                Lower: <strong>{Number(lower.toFixed(3))}</strong>
              </div>
              <div>
                Value: <strong>{Number(value.toFixed(3))}</strong>
              </div>
              <div>
                Upper: <strong>{Number(upper.toFixed(3))}</strong>
              </div>
            </>
          ),
          datasetName: datasetLabel,
          color: color,
        }}
      >
        <EnhancedCircle x={0} y={0} radius={8} fill={'#ffffff'} strokeWidth={1} stroke={color} />
        <EnhancedCircle x={0} y={0} radius={3.5} fill={color} strokeWidth={1} stroke={color} />
      </EnhancedGroup>
    </EnhancedGroup>
  );
};
