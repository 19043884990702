import * as React from 'react';
import {CSSProperties, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import classes from './multi-select.module.scss';
import {APICallInputOptions, EnumInputOption, EnumInputOptions, InputType} from '../../../../index';
import sharedClasses from '../../../shared.module.scss';
import {Popover} from '@material-ui/core';
import {EnumDialog} from '../../dialogs/enum-dialog.component';
import {APICallDialog} from '../../dialogs/api-call-dialog.component';
import {InputChipInline} from '../../related/input-chip-inline/input-chip-inline.component';
import {uniqueId} from 'lodash';

interface OwnProps {
  value: string[] | number[];
  label?: string;
  options: EnumInputOptions | APICallInputOptions;
  placeholder?: string;
  onChange: (value: string[] | number[]) => void;
  allowEdit?: boolean;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean; // not fully implemented
  error?: boolean; // not fully implemented
  freeText?: boolean;
  style?: CSSProperties;
}

type AllProps = OwnProps;

const generateIdForInput = (prefix: string, key: string) => `${prefix}_input_${key}`;

export const MultiSelect: React.FC<AllProps> = (props: AllProps) => {
  const {
    value,
    label,
    options,
    placeholder,
    onChange,
    className,
    fullWidth,
    disabled,
    error,
    freeText,
    allowEdit,
    style,
  } = props;
  // input ref for handle fix in safari of trigger focus when popover is closed
  const inputRef = useRef(null);
  const [enumOptions, setEnumOptions] = useState<EnumInputOption[]>((options as any).options || []);
  const isAPISelect = useMemo(() => Boolean((options as any).networkRequest), [options]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const inputPopoverAnchor = useRef<HTMLDivElement>();
  const [showPopover, setShowPopover] = useState(false);
  const [editIndex, setEditIndex] = useState(undefined);
  const multiSelectId = useMemo(() => uniqueId('multi-select'), []);
  const enumOptionsValues = useMemo(() => new Set(enumOptions.map(e => e.value)), [enumOptions]);

  useEffect(() => {
    setEnumOptions((options as any).options || []);
  }, [options]);

  const handleClose = () => {
    setShowPopover(false);
    setAnchorEl(null);
    setEditIndex(undefined);
  };
  const onDialogValueChange = selectedValue => {
    let newValue = [...value];
    newValue[editIndex] = selectedValue;
    newValue = Array.from(new Set(newValue));
    onChange(newValue as any);
    handleClose();
  };
  const onTempValueChange = e => {
    if (disabled) {
      return;
    }
    setEditIndex(value.length);
    setAnchorEl(inputPopoverAnchor.current);
    setShowPopover(true);
    // fix for safari!
    inputRef.current.blur();
  };
  const onDelete = idx => {
    const newValue = [...value];
    newValue.splice(idx, 1);
    onChange(newValue as any);
  };
  const onEdit = idx => {
    const newAnchor = document.getElementById(generateIdForInput(multiSelectId, idx));
    setAnchorEl(newAnchor);
    setEditIndex(idx);
    setShowPopover(true);
  };
  return (
    <div
      style={style}
      className={classNames(
        classes.MultiSelect,
        fullWidth && classes.FullWidth,
        showPopover && classes.Active,
        error && classes.Error,
        disabled && classes.Disabled,
        className
      )}
    >
      <div className={classNames(classes.SelectInput)}>
        {value?.map((v, idx) => (
          <InputChipInline
            id={generateIdForInput(multiSelectId, idx)}
            key={idx}
            inputType={isAPISelect ? InputType.API_CALL : InputType.ENUM}
            freeText={freeText}
            options={options}
            label={label}
            value={v}
            className={classes.SelectedInline}
            onDelete={() => onDelete(idx)}
            muted={disabled}
            onEdit={
              allowEdit
                ? enumOptionsValues.has(v) || isAPISelect
                  ? () => onEdit(idx)
                  : undefined
                : undefined
            }
            isActive={idx === editIndex}
            variant={'light'}
            editable={!disabled}
          />
        ))}
        <div ref={inputPopoverAnchor} />
        <input
          type={'text'}
          disabled={disabled || showPopover}
          className={classes.TempInput}
          onFocus={onTempValueChange}
          placeholder={placeholder}
          ref={inputRef}
        />
      </div>
      <Popover
        className={sharedClasses.BlankPaper}
        open={showPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: sharedClasses.BlankPaper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {!isAPISelect && (
          <EnumDialog
            options={{options: enumOptions}}
            freeText={freeText}
            value={value}
            onSubmit={onDialogValueChange}
            hideSelectedOption
          />
        )}
        {isAPISelect && (
          <APICallDialog
            value={editIndex !== undefined ? value[editIndex] : undefined}
            options={options as any}
            onSubmit={onDialogValueChange}
            onOptionsChange={setEnumOptions}
            selectedValues={value}
            hideSelectedOption
          />
        )}
      </Popover>
    </div>
  );
};

MultiSelect.defaultProps = {
  value: [],
  allowEdit: false,
};
