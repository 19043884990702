import * as React from 'react';
import {EnhancedGroup, EnhancedRect} from '../../../../core/konva/components';
import {useMemo} from 'react';
import {min} from 'lodash';

interface OwnProps {
  x: number;
  y: number;
  upperDiff: number;
  lowerDiff: number;
  color?: string;
  maxWidth?: number;
}

type AllProps = OwnProps;

export const ConfidenceInterval: React.FC<AllProps> = (props: AllProps) => {
  const {x, y, color, lowerDiff, upperDiff, maxWidth} = props;
  const width = useMemo(() => min([maxWidth, 10]), [maxWidth]);

  return (
    <EnhancedGroup x={x} y={y}>
      <EnhancedRect
        x={0}
        y={-upperDiff}
        width={2}
        height={upperDiff + lowerDiff}
        cornerRadius={7}
        fill={color}
        centerX
      />
      <EnhancedRect
        x={0}
        y={-upperDiff}
        width={width}
        height={2}
        cornerRadius={7}
        fill={color}
        centerX
      />
      <EnhancedRect
        x={0}
        y={lowerDiff}
        width={width}
        height={2}
        cornerRadius={7}
        fill={color}
        centerX
      />
    </EnhancedGroup>
  );
};

ConfidenceInterval.defaultProps = {
  color: 'red',
};
