import {KPISegmentationItem, KPISegmentationType} from '../../../../types';
import {SimulatorItemType} from '../../../../hooks/use-simulator.hook';
import {exists, safeDivision} from 'front-core';
import {ExtendedKPISegmentationItem} from './kpi-segmentation-viewer.component';

const TYPE_MAPPING = {
  [KPISegmentationType.POSITIVE]: KPISegmentationType.POSITIVE,
  [KPISegmentationType.NEGATIVE]: KPISegmentationType.NEGATIVE,
  [KPISegmentationType.INSIGNIFICANT_POSITIVE]: KPISegmentationType.INSIGNIFICANT,
  [KPISegmentationType.INSIGNIFICANT_NEGATIVE]: KPISegmentationType.INSIGNIFICANT,
  [KPISegmentationType.NO_RECOMMENDATION]: KPISegmentationType.NO_RECOMMENDATION,
};

const INSIGNIFICANT_SEGMENTS = [
  KPISegmentationType.INSIGNIFICANT,
  KPISegmentationType.NO_RECOMMENDATION,
];

export const extendKPISegmentationItems = (
  items: KPISegmentationItem[],
  totalEntities: number,
  goalValue: number,
  higherIsBetter?: boolean
): ExtendedKPISegmentationItem[] => {
  let newItems: ExtendedKPISegmentationItem[] = items as any;
  const simulatorType = (type: KPISegmentationType) => {
    if (
      type === KPISegmentationType.POSITIVE ||
      type === KPISegmentationType.INSIGNIFICANT_POSITIVE
    ) {
      return SimulatorItemType.POSITIVE;
    }
    if (
      type === KPISegmentationType.NEGATIVE ||
      type === KPISegmentationType.INSIGNIFICANT_NEGATIVE
    ) {
      return SimulatorItemType.NEGATIVE;
    }
  };
  newItems = newItems.map(i => {
    const share = safeDivision(i.count, totalEntities);
    const isInformative = exists(i.isInformative) ? i.isInformative : true;
    const type = TYPE_MAPPING[i.type];
    const item = {
      ...i,
      type: type,
      actualType: i.type,
      uplift: safeDivision(i.goalInSegment - i.goalInNonSegment, Math.abs(i.goalInNonSegment)),
      informativeUplift: safeDivision(
        i.goalInSegment - i.goalInInformativeNonSegment,
        Math.abs(i.goalInInformativeNonSegment)
      ),
      avgUplift: safeDivision(i.goalInSegment - goalValue, Math.abs(goalValue)),
      share,
      groupInsignificant: type === KPISegmentationType.INSIGNIFICANT || isInformative === false,
      // useSimulator interface
      value: share,
      insignificant: INSIGNIFICANT_SEGMENTS.indexOf(TYPE_MAPPING[i.type]) > -1,
      simulationType: simulatorType(i.type),
      isInformative,
    };
    if (i.type === KPISegmentationType.NEGATIVE) {
      item.potentialLift = share;
      item.value = i.goalInSegment;
      // we need negative to be treated as positive
      item.simulationType = higherIsBetter
        ? SimulatorItemType.POSITIVE
        : SimulatorItemType.NEGATIVE;
    }
    return item;
  });

  return newItems;
};
