import {RCACompositeSegments} from '../../../../types';
import {safeDivision} from 'front-core';
import {ExtendedRCACompositeSegments} from './rca-composite-segments-viewer.component';

export const extendRCACompositeSegments = (
  composites: RCACompositeSegments[],
  totalEntities: number,
  expectedTotalEntities: number
): ExtendedRCACompositeSegments[] => {
  return composites.map(c => ({
    ...c,
    key: `${c.segments.map(s => `${s.name}_${s.signalId}`).join('_')}`,
    share: safeDivision(c.count, totalEntities),
    expectedShare: safeDivision(c.expectedCount, expectedTotalEntities),
    uplift: safeDivision(c.value - c.expectedValue, c.expectedValue),
    segments: c.segments.map(s => ({
      ...s,
      key: s.signalId,
      share: safeDivision(s.count, totalEntities),
      uplift: safeDivision(s.value - s.expectedValue, s.expectedValue),
      expectedShare: safeDivision(s.expectedCount, expectedTotalEntities),
    })),
  }));
};
