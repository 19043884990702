// Options
export type KPIElementDirection = 'to-top' | 'to-bottom';

export interface KPIElementOptions {
  direction?: KPIElementDirection;
  round?: number;
}

// Data
export interface KPISample {
  value: number;
  timestamp: string;
  segmentId?: number;
}

export interface KPIGoal {
  value: number;
  date: string;
}

export type ElementDataType = 'numeric' | 'currency' | 'percentage';

export type KPIElementData = {
  name: string;
  symbol: string;
  description?: string;
  type: ElementDataType;
  higherIsBetter: boolean;
  opportunitiesCount: number;
  loopsCount: number;
  samples: KPISample[];
  predictions: KPISample[];
  goal: KPIGoal;
  category?: string;
  isNorthStar?: boolean;
};

// Interfaces
export enum ElementType {
  KPI_GROUP = 'KPIGroup',
  KPI_ELEMENT = 'KPIElement',
  GROWTH_MAP = 'GrowthMap',
}

export enum ProductArea {
  ACQUISITION = 'ACQUISITION',
  ONBOARDING = 'ONBOARDING',
  ACTIVATION = 'ACTIVATION',
  ENGAGEMENT = 'ENGAGEMENT',
  RETENTION = 'RETENTION',
  CHURN = 'CHURN',
}

export enum GroupMembersType {
  LIST = 'LIST',
  FUNNEL = 'FUNNEL',
  LOOPS = 'LOOPS',
  SOURCES = 'SOURCES',
}

export enum MapElementType {
  NORTH_STAR_GROUP = 'NORTH_STAR_GROUP',
  PRIMARY_GROUP = 'PRIMARY_GROUP',
  SECONDARY_GROUP = 'SECONDARY_GROUP',
  CONVERSION_RATE = 'CONVERSION_RATE',
}

export interface KPIElement {
  type: ElementType;
  mapElementType?: MapElementType;
  metricId: number;
  data: KPIElementData; // The data for each information to display
  options: KPIElementOptions;
  title?: string;
  value?: number;
  position: number; // [0 - 1]
}

export interface KPIGroup extends KPIElement {
  type: ElementType.KPI_GROUP;
  members: KPIElement[] | KPIElement[][]; // [][] - for loops
  membersType: GroupMembersType;
  area?: ProductArea;
}

export interface GrowthMapOptions {
  minWidth?: number;
  model?: string;
}

export interface ExtendedKPIElement extends KPIElement {
  layerIndex: number;
  zIndex: number;
  isFocused: boolean;
  isExpanded: boolean;
}

export interface IGrowthMap {
  type: ElementType.GROWTH_MAP;
  members: KPIElement[][]; // [][] - for forks
  options?: GrowthMapOptions;
}
