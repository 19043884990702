import {forIn} from 'lodash';
import {
  ShieldCheckSolidIcon,
  ShieldExclamationSolidIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {exists} from 'front-core';
import {ReleaseImpactSummaryValidations} from '../../../../types';

export const getValidationIcon = (validations: ReleaseImpactSummaryValidations) => {
  const {passCount, totalCount} = getValidationsCounts(validations);

  if (passCount === totalCount) {
    return ShieldCheckSolidIcon;
  }
  return ShieldExclamationSolidIcon;
};

export const getValidationsCounts = (validations: ReleaseImpactSummaryValidations) => {
  const {validationResults, validationConfidenceLevel} = validations;
  if (!exists(validationResults)) {
    return {totalCount: 0, passCount: 0};
  }

  const totalCount = Object.keys(validationResults).length;

  let passCount = 0;
  forIn(validationResults, (validation, key) => {
    if (validation.conclusion) {
      passCount++;
    }
  });
  return {totalCount, passCount};
};
