import yup from '../../config/yup.config';
import {isArray, isEmpty, values} from 'lodash';
import {
  AggregationFunction,
  BinaryOperator,
  ConditionOperator,
  DateBinaryOperator,
  LiteralValueType,
  NumericFunctionType,
  Part,
  SqlElementType,
  WindowFunctionType,
} from 'ui-components';
import {exists, NetworkRequestCreator, toArray} from 'front-core';
import {TestConfig} from 'yup/lib/util/createValidation';
import httpClientService from '../../services/http-client.service';
import {validateSignalIdsNetworkRequest} from '../../http/validations.network-requests';
import {TIME_UNIT_OPTIONS} from '../../modules/analyses/analysis-forms/analysis-parameters/shared-validators.ts';
import {SQL_PROPERTY_TEMPLATE_NAME} from '../../modules/shared/core/query-builders/custom-sql-query-builder/custom-sql-query-builder.component.tsx';
import {TableType} from '../models/table.model.ts';
import {AggregationStrategy, SignalDataType} from '../models/signal.model.ts';
import {BEHAVIORAL_CHURN_TEMPLATE_NAME} from '../../modules/shared/core/query-builders/behavioral-churn-query-builder/behavioral-churn-query-builder.component.tsx';
import {HABIT_MOMENT_TEMPLATE_NAME} from '../../modules/shared/core/query-builders/habit-moment-query-builder/habit-moment-query-builder.component.tsx';
import {METADATA_KEY} from '../../constants/parameters-saved-keys.ts';
import {ComputedTemplatesNames} from '../../constants/query-builder.ts';

const LITERAL_VALIDATION_BY_TYPE = {
  [LiteralValueType.STRING]: yup.string().required(),
  [LiteralValueType.INTEGER]: yup.number().required(),
  [LiteralValueType.FLOAT]: yup.number().required(),
  [LiteralValueType.DATE]: yup.string().required(),
  [LiteralValueType.BOOLEAN]: yup.boolean().required(),
  [LiteralValueType.STRING_LIST]: yup.array().of(yup.mixed().nullable()).min(1),
  [LiteralValueType.INTEGER_LIST]: yup.array().of(yup.number()).min(1),
  [LiteralValueType.FLOAT_LIST]: yup.array().of(yup.number()).min(1),
  [LiteralValueType.NULL]: yup.mixed().nullable(),
};

const _parametersValidator = v => {
  switch (typeof v) {
    case 'string':
      return yup.string().required();
    case 'number':
      return yup.number().required();
    case 'boolean':
      return yup.boolean().required();
    case 'object':
      if (isArray(v)) {
        if (typeof v[0] === 'number') {
          return yup.array().of(yup.number()).min(1);
        }
        if (typeof v[0] === 'string') {
          return yup.array().of(yup.string()).min(1);
        }
        if (typeof v[0] === 'object') {
          return yup.array().of(queryElementValidatorFactory());
        }
      }
      if (v === null) {
        return yup.mixed().nullable();
      }
      return queryElementValidatorFactory();
    default:
      return yup.mixed().required();
  }
};

export const validateSignalIds = (): TestConfig<any, any> => ({
  name: 'validate_signal_ids',
  test: async function (signalIds: any, context) {
    const data = toArray(signalIds);
    if (data.length === 0) {
      return true;
    }
    try {
      await httpClientService.exec(validateSignalIdsNetworkRequest(data));
      return true;
    } catch (e: any) {
      return this.createError({
        message: 'Invalid parameter',
        path: context.path,
      });
    }
  },
});

export const _queryElementValidatorByType = (type: SqlElementType) =>
  yup.lazy(obj => {
    return QUERY_ELEMENT_VALIDATOR_BY_TYPE[type];
  });

export const queryElementValidatorFactory = (required = true): any =>
  yup.lazy(obj => {
    if (exists(obj) && obj.type) {
      return QUERY_ELEMENT_VALIDATOR_BY_TYPE[obj.type];
    }
    if (required) {
      return yup
        .object()
        .shape({
          type: yup.string().required(),
        })
        .required();
    }
    return yup.object().nullable();
  });

const QUERY_ELEMENT_VALIDATOR_BY_TYPE = {
  [SqlElementType.CASES]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.CASES]).required(),
    cases: yup.array().of(yup.array().of(queryElementValidatorFactory()).min(2)).min(1),
    else_value: queryElementValidatorFactory(),
  }),
  [SqlElementType.CONDITION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.CONDITION]).required(),
    left: queryElementValidatorFactory(),
    right: yup.mixed().when('op', {
      is: ConditionOperator.BETWEEN,
      then: yup.array().of(queryElementValidatorFactory()).min(2),
      otherwise: queryElementValidatorFactory(),
    }),
    op: yup.string().oneOf(values(ConditionOperator)).required(),
  }),
  [SqlElementType.TABLE_COLUMN]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.TABLE_COLUMN]).required(),
    table_id: yup.number().required(),
    column: yup.string().required(),
  }),
  [SqlElementType.IMPLICIT_COLUMN]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.IMPLICIT_COLUMN]).required(),
    column: yup.string().required(),
    dependencies: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.LITERAL]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.LITERAL]).required(),
    // @ts-ignore
    value: yup.mixed().literalValue(LITERAL_VALIDATION_BY_TYPE),
    value_type: yup.string().oneOf(values(LiteralValueType)),
  }),
  [SqlElementType.OR_CONDITION]: yup.object().shape({
    type: yup
      .string()
      .oneOf([SqlElementType.AND_CONDITION, SqlElementType.OR_CONDITION])
      .required(),
    conditions: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.AND_CONDITION]: yup.object().shape({
    type: yup
      .string()
      .oneOf([SqlElementType.AND_CONDITION, SqlElementType.OR_CONDITION])
      .required(),
    conditions: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.SIGNAL_COLUMN]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.SIGNAL_COLUMN]).required(),
    signal_id: yup.number().required().test(validateSignalIds()),
  }),
  [SqlElementType.NULLIF]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.NULLIF]).required(),
    expression1: queryElementValidatorFactory(),
    expression2: queryElementValidatorFactory(),
  }),
  [SqlElementType.AGGREGATION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.AGGREGATION]).required(),
    function: yup.string().oneOf(values(AggregationFunction)).required(),
    element: queryElementValidatorFactory(),
  }),
  [SqlElementType.NUMERIC_FUNCTION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.NUMERIC_FUNCTION]).required(),
    function: yup.string().oneOf(values(NumericFunctionType)).required(),
    elements: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.TEMPLATE]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.TEMPLATE]).required(),
    template: yup.string().required(),
    parameters: yup
      .array()
      .transform(a => a.filter(i => i !== undefined))
      // @ts-ignore (eachOne is added in yup.utils)
      .eachOne(_parametersValidator),
  }),
  [SqlElementType.BINARY_OPERATION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.BINARY_OPERATION]).required(),
    left: queryElementValidatorFactory(),
    right: queryElementValidatorFactory(),
    op: yup.string().oneOf(values(BinaryOperator)).required(),
  }),
  [SqlElementType.COALESCE]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.COALESCE]).required(),
    elements: yup.array().of(queryElementValidatorFactory()).min(1),
  }),
  [SqlElementType.FILL_NULL]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.FILL_NULL]).required(),
    elements: yup.array().of(queryElementValidatorFactory()).min(1),
    default: _queryElementValidatorByType(SqlElementType.LITERAL),
  }),
  [SqlElementType.DATE_PART]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.DATE_PART]).required(),
    value: queryElementValidatorFactory(),
    part: yup.string().oneOf(values(Part)).required(),
  }),
  [SqlElementType.DATE_BINARY_OPERATION]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.DATE_BINARY_OPERATION]).required(),
    left: queryElementValidatorFactory(),
    right: queryElementValidatorFactory(),
    op: yup.string().oneOf(values(DateBinaryOperator)).required(),
    part: yup.string().oneOf(values(Part)).required(),
  }),
  [SqlElementType.WINDOW]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.WINDOW]).required(),
    function: yup.string().oneOf(values(WindowFunctionType)).required(),
    partition_by: queryElementValidatorFactory(),
    order_by: yup
      .array()
      .of(yup.mixed())
      .length(2)
      // @ts-ignore (byPosition is added in yup.utils)
      .byPosition([queryElementValidatorFactory(), yup.boolean()]),
    parameters: yup
      .array()
      .transform(a => a.filter(i => i !== undefined))
      // @ts-ignore (eachOne is added in yup.utils)
      .eachOne(_parametersValidator),
  }),
  [SqlElementType.TRANSFORMER]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.TRANSFORMER]).required(),
    transformer: yup.string().required(),
    value: queryElementValidatorFactory(),
    parameters: yup
      .array()
      .transform(a => a.filter(i => i !== undefined))
      // @ts-ignore (eachOne is added in yup.utils)
      .eachOne(_parametersValidator),
  }),
  [SqlElementType.RAW_QUERY]: yup.object().shape({
    type: yup.string().oneOf([SqlElementType.RAW_QUERY]).required(),
    value: yup.string().required(),
    dependencies: yup.array().of(queryElementValidatorFactory()).min(0),
  }),
};

export const querySchemaValidator = (required: boolean = true) =>
  yup.lazy(obj => {
    const type = obj?.type;
    if (!exists(type)) {
      return queryElementValidatorFactory(required);
    }
    // templates validator
    switch (type) {
      case SqlElementType.TEMPLATE:
        if (obj?.template === 'bounded_action_ts') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(5)
              // @ts-ignore
              .byPosition([
                queryElementValidatorFactory(true),
                yup.number().required(),
                yup.number().required(),
                yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
                queryElementValidatorFactory(true),
              ]),
          });
        }
        if (obj?.template === 'bounded_actions_ts') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(5)
              // @ts-ignore
              .byPosition([
                yup.array().of(queryElementValidatorFactory(true)).min(1),
                yup.number().required(),
                yup.number().required(),
                yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
                queryElementValidatorFactory(true),
              ]),
          });
        }
        if (obj?.template === 'dod' || obj?.template === 'wow' || obj?.template === 'mom') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(1)
              // @ts-ignore
              .byPosition([yup.array().of(queryElementValidatorFactory(true)).min(1)]),
          });
        }
        if (obj?.template === SQL_PROPERTY_TEMPLATE_NAME) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(12)
              // @ts-ignore
              .byPosition([
                yup.string().required(), // sql_definition
                yup.string().oneOf(values(TableType)).required(), // table_type
                yup.string().oneOf(values(SignalDataType)).required(), // data_type
                yup.string().required(), // entity_id_column
                yup.string().required(), // value_column
                yup.string().oneOf(values(AggregationStrategy)).required(), // dimension_aggregation
                yup.string().nullable(), // slug
                yup.string().when('parameters.1', {
                  is: TableType.STATE,
                  then: yup.string().oneOf(TIME_UNIT_OPTIONS).nullable(),
                  otherwise: yup.string().nullable(),
                }), // table_granularity
                yup.string().when('parameters', {
                  is: () => obj.parameters[1] === TableType.ENTITY_PROPERTIES,
                  then: yup.string().nullable(),
                  otherwise: yup.string().required(),
                }), // timestamp_column
                yup.string().when('parameters.1', {
                  is: () => obj.parameters[1] === TableType.ENTITY_PROPERTIES,
                  then: yup.string().nullable(),
                  otherwise: yup.string().oneOf(values(AggregationStrategy)),
                }), // time_aggregation
                yup.string().nullable(), // event_name_column
                yup.string().nullable(), // partition_ts_column
              ]),
          });
        }
        if (obj?.template === BEHAVIORAL_CHURN_TEMPLATE_NAME) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(3)
              // @ts-ignore
              .byPosition([
                queryElementValidatorFactory(true),
                yup.string().oneOf(TIME_UNIT_OPTIONS).required(),
                yup.number().required(),
              ]),
          });
        }
        if (obj?.template === HABIT_MOMENT_TEMPLATE_NAME) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(6)
              // @ts-ignore
              .byPosition([
                yup.array().of(queryElementValidatorFactory(true)).min(1),
                queryElementValidatorFactory(true),
                yup.string().required(),
                yup.number().required(),
                yup.string().required(),
                yup.number().required(),
              ]),
          });
        }
        if (obj?.template === 'dau' || obj?.template === 'wau' || obj?.template === 'mau') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(1)
              // @ts-ignore
              .byPosition([yup.array().of(queryElementValidatorFactory(true)).min(1)]),
          });
        }
        if (obj?.template === 'l7' || obj?.template === 'l28') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(1)
              // @ts-ignore
              .byPosition([yup.array().of(queryElementValidatorFactory(true)).min(1)]),
          });
        }
        if (obj?.template === 'rate') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(2)
              // @ts-ignore
              .byPosition([queryElementValidatorFactory(true), queryElementValidatorFactory(true)]),
          });
        }
        if (obj?.template === 'subscription_retention_ts') {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(3)
              // @ts-ignore
              .byPosition([
                yup.string().required(),
                yup.number().required(),
                yup.string().required(),
              ]),
            ui_metadata: yup.object().shape({
              [METADATA_KEY.BUILDER_COMPONENT_NAME_KEY]: yup.string().required(),
              [METADATA_KEY.PLAN_SIGNAL_ID_KEY]: yup.number().required(),
              [METADATA_KEY.FIRST_PAYMENT_SIGNAL_ID_KEY]: yup.number().required(),
              [METADATA_KEY.CHURN_SIGNAL_ID_KEY]: yup.number().required(),
            }),
          });
        }
        if (
          [ComputedTemplatesNames.LAST_VALUE, ComputedTemplatesNames.FIRST_VALUE].includes(
            obj?.template
          )
        ) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(3)
              // @ts-ignore
              .byPosition([
                queryElementValidatorFactory(true),
                yup.string().required(),
                yup.string().required(),
              ]),
          });
        }
        if (
          [
            ComputedTemplatesNames.SIMPLE_FIRST_TIME,
            ComputedTemplatesNames.SIMPLE_LAST_TIME,
          ].includes(obj?.template)
        ) {
          return yup.object().shape({
            parameters: yup
              .array()
              .of(yup.mixed())
              .length(1)
              // @ts-ignore
              .byPosition([queryElementValidatorFactory(true)]),
          });
        }
        return QUERY_ELEMENT_VALIDATOR_BY_TYPE[type];
      default:
        return QUERY_ELEMENT_VALIDATOR_BY_TYPE[type];
    }
  });

export const VALIDATE_QUERY_WITH_API_TEST_NAME = 'validate_query_with_api';
export const apiQuerySchemaValidator = (parameters: {
  networkRequest: NetworkRequestCreator<any, any>;
  extractData: (data: any, context: any) => any;
  required?: boolean;
}) => {
  const {networkRequest, extractData, required = true} = parameters;
  return yup.lazy(obj => {
    // validate schema
    const resolvedSchema = querySchemaValidator(required).resolve({value: obj});
    return resolvedSchema.test({
      name: VALIDATE_QUERY_WITH_API_TEST_NAME,
      test: async function (definition, context) {
        // if the schema is valid and the definition exists
        if (
          (await resolvedSchema.isValid(definition)) &&
          exists(definition) &&
          !isEmpty(definition)
        ) {
          try {
            await httpClientService.exec(networkRequest(extractData(context.parent, context)));
            return true;
          } catch (e) {
            return this.createError({
              message: e.data.message,
            });
          }
        }
        return true;
      },
    });
  });
};
