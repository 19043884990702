import * as React from 'react';
import {AnalysisContentItem, AnalysisContentItemSelection} from '../../../../../types';
import {useEffect, useMemo, useState} from 'react';
import {AnalysisContentItemViewer} from '../analysis-content-item-viewer/analysis-content-item-viewer.component';
import {exists} from 'front-core';
import {getKeyFromContentItem} from '../../analysis-figure-viewer.component';

interface OwnProps {
  content: AnalysisContentItem | AnalysisContentItemSelection;
  selectedContentKey?: string;
  onContentSelected?: (key: string) => void;
  [other: string]: any;
}

export interface ContentOption {
  value: string;
  label: string;
  isCausal?: boolean;
}

type AllProps = OwnProps;

export const AnalysisContentRenderer: React.FC<AllProps> = (props: AllProps) => {
  const {id, content, selectedContentKey, onContentSelected, ...rest} = props;
  const [contentOptions, setContentOptions] = useState<ContentOption[]>();

  const selectedContent = useMemo(() => {
    if (content.type === 'item' || !exists(content.type)) {
      return content as AnalysisContentItem;
    } else if (content.type === 'selection') {
      return (content as AnalysisContentItemSelection).data.find(
        item => getKeyFromContentItem(item) === selectedContentKey
      );
    }
  }, [content, selectedContentKey]);

  useEffect(() => {
    if (content.type === 'item' || !exists(content.type)) {
      setContentOptions(undefined);
    } else if (content.type === 'selection') {
      setContentOptions(
        content.data.map(item => ({
          value: getKeyFromContentItem(item),
          label: item.title,
          isCausal: item.isCausal,
        }))
      );
    }
  }, [content, setContentOptions]);

  if (!selectedContent) {
    return null;
  }

  return (
    <AnalysisContentItemViewer
      {...(rest as any)}
      {...selectedContent}
      key={undefined}
      title={content.title || selectedContent.title}
      headerLabels={selectedContent.headerLabels || content.headerLabels}
      infoBox={selectedContent.infoBox || content.infoBox}
      tipBox={selectedContent.tipBox || content.tipBox}
      icon={selectedContent.icon || content.icon}
      id={getKeyFromContentItem(selectedContent)}
      contentOptions={contentOptions}
      onContentSelected={onContentSelected}
    />
  );
};

AnalysisContentRenderer.defaultProps = {};
