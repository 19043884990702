import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import {useContext, useEffect} from 'react';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {SimpleBooleanSelector} from '../../components/ui-selectors/simple-boolean-selector/simple-boolean-selector.component';
import {AnalysisFormProps} from '../../analysis-forms.types';

const LINEARITY_SCHEMA_MAPPING = {boolean_key: 'linearity'};

export const Analysis74Form = (props: AnalysisFormProps) => {
  const {className} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_74', parameters => {
      return {
        [LINEARITY_SCHEMA_MAPPING.boolean_key]: true,
      };
    });
    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_74');
    };
  }, [registerDefaultHandler, removeDefaultHandler]);

  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <SimpleBooleanSelector
        title={'Steps’ order'}
        subTitle={'Choose whether to enforce the funnel’s steps order'}
        className={classes.Parameter}
        helperText={
          'When enabled, users convert only if they follow the order of steps. For example, if a user did step B and then step A, it will considered as if he did A step only. When disable, a user that first did B and then A will be considered as converted between steps, but user that did B and never did A won’t be considered as converted.'
        }
        text={'Follow funnel’s steps order?'}
        onChange={changeParametersValue}
        schemaKeysMapping={LINEARITY_SCHEMA_MAPPING}
        value={parameters}
        errors={errors}
      />
      <TimeFrameSelector
        title={'Time frame'}
        subTitle={'Set the time frame for this analysis.'}
        className={classes.Parameter}
        errors={errors}
        value={parameters}
        onChange={changeParametersValue}
      />
      <SegmentFilterSelector
        onChange={changeParametersValue}
        value={parameters}
        className={classes.Parameter}
        errors={errors}
      />
    </div>
  );
};
