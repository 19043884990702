import {Controller, useFormContext, ControllerRenderProps} from 'react-hook-form';
import {LabelWrapper, LabelWrapperProps} from 'ui-components';
// @ts-ignore
import {ControllerFieldState} from 'react-hook-form/dist/types/controller';

interface OwnProps extends Omit<LabelWrapperProps, 'children'> {
  name: string;
  render: (props: ControllerRenderProps, fieldState: ControllerFieldState) => JSX.Element;
}

export const FormLabelInput = (props: OwnProps) => {
  const {
    name,
    label,
    className,
    required,
    render,
    helperText,
    fullWidth = true,
    maxLength,
    renderRight,
  } = props;
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <LabelWrapper
          required={required}
          label={label}
          error={Boolean(fieldState.error)}
          // @ts-ignore
          helperText={
            Array.isArray(fieldState.error)
              ? fieldState.error
                  .filter(Boolean)
                  .map(e => e.message)
                  .join('')
              : fieldState.error?.message || helperText
          }
          className={className}
          maxLength={maxLength}
          renderRight={renderRight}
          currentLength={field.value?.length}
          fullWidth={fullWidth}
        >
          {render({...field, ref: undefined}, fieldState)}
        </LabelWrapper>
      )}
    />
  );
};
