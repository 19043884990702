import * as React from 'react';
import {EnhancedGroup, EnhancedRect, EnhancedText} from '../../../../core/konva/components';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {useContext} from 'react';
import {ChartContext} from '../chart.context';
import {StageContext} from '../../../../core/konva/stage-context.component';

interface OwnProps {
  text: string;
  isActive?: boolean;
  opacity?: number;
}

type AllProps = ShapeProps & OwnProps;

export const ScatterChartLabel: React.FC<AllProps> = (props: AllProps) => {
  const {
    x,
    y,
    width,
    isActive,
    text,
    opacity,
    onMouseEnter,
    onMouseLeave,
    onClick,
    tooltipEnabled,
    tooltipData,
    zIndex,
  } = props;
  const {style} = useContext(StageContext);
  const {darkMode} = useContext(ChartContext);

  return (
    <EnhancedGroup
      x={x}
      y={y}
      opacity={opacity}
      zIndex={zIndex}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      tooltipEnabled={tooltipEnabled}
      tooltipData={tooltipData}
    >
      <EnhancedRect
        x={0}
        y={0}
        fill={darkMode ? '#ffffff' : 'rgba(239, 240, 246, 1)'}
        width={isActive ? width : 0}
        height={18}
        centerY
        cornerRadius={4}
        duration={0.15}
      />
      <EnhancedText
        x={0}
        y={0}
        height={18}
        width={width}
        text={text}
        fontFamily={style.fontName}
        padding={4}
        fill={'#898EA8'}
        fontSize={12}
        ellipsis={!isActive}
        opacity={isActive ? 1 : 0.5}
        centerY
        duration={0}
      />
    </EnhancedGroup>
  );
};
