import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import {
  AnalysisAlgoMode,
  CommandType,
  DocumentElementType,
  FollowUpType,
  FunnelSegmentationFigure,
  FunnelSegmentationItem,
  FunnelSegmentationItemType,
} from '../../../../types';
import {SimulationMode, useSimulatorMode} from '../../../../hooks/use-simulator-mode.hook';
import {extendFunnelSegmentationItems} from './funnel-segmentation-viewer.utils';
import classes from './funnel-segmentation-viewer.module.scss';
import {groupBy, isArray, keys} from 'lodash';
import {exists} from 'front-core';
import TransKeys from 'translations';
import {FunnelSegmentationDataModeTable} from './components/funnel-segmentation-tables/funnel-segmentation-data-mode-table.component';
import {FunnelSegmentationModal} from './components/funnel-segment-modal/funnel-segment-modal.component';
import {FunnelSegmentationSimulatorMode} from './components/funnel-segmentation-tables/funnel-segmentation-simulator-mode.component';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {AnalysisFigureContext} from '../../../../contexts/analysis-figure.context';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {PendoAnchors, usePendoAnchor} from '../../../../../../../hooks/use-pendo-anchor.hook';
import {
  ExtractSegmentData,
  SegmentFilters,
  SegmentFiltersValue,
  useFilterSegmentItems,
  useTeamAutoSetterForSegments,
} from '../../../shared/segmentation/segment-filters/segment-filters.component';
import {FilterLightIcon} from '../../../../../../simple/controls/icons/icons.component';
import {Select} from '../../../../../../forms/inputs/select/select.component';
import {GlobalDocumentDataContext} from '../../../../contexts/global-document-data/global-document-data.context';

interface OwnProps extends FunnelSegmentationFigure {
  className?: string;
}

type AllProps = OwnProps;

export interface ExtendedFunnelSegmentationItem extends FunnelSegmentationItem {
  actualType: FunnelSegmentationItemType;
  allSegmentCount: number[];
  share: number;

  uplift: number[];
  informativeUplift: number[];
  allSegmentsUplift: number[];

  segmentConversions: number[];
  nonSegmentConversions: number[];
  informativeNonSegmentConversions: number[];
  allSegmentConversions: number[];

  segmentFunnelCompletionConversion: number;
  nonSegmentFunnelCompletionConversion: number;
  informativeNonSegmentFunnelCompletionConversion: number;
  allSegmentFunnelCompletionConversion: number;

  funnelCompletionUplift: number;
  informativeFunnelCompletionUplift: number;
  allSegmentsFunnelCompletionUplift: number;

  potentialLiftUpper: number[];
  potentialLiftLower: number[];
}

export interface FunnelSegmentGroup {
  name: string;
  segments: ExtendedFunnelSegmentationItem[];
}

interface Filters extends SegmentFiltersValue {
  selectedStepIndex?: number;
}

const EXTRACT_SEGMENT_DATA: ExtractSegmentData = {
  signalIdDataKey: 'signalId.0',
  segmentGroupNameDataKey: 'segmentGroupName.0',
  segmentNameDataKey: 'segmentName.0',
  shareDataKey: 'share',
};

export const FunnelSegmentationViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data: dataFromProps, options, className} = props;
  const {t} = useDocumentTranslation();
  const viewerRef = usePendoAnchor(PendoAnchors.FUNNEL_SEGMENTATION_VIEWER);
  const {teamId} = useContext(GlobalDocumentDataContext);
  const {trackModalOpened, trackFilter} = useDocumentTracking(
    id,
    DocumentElementType.FUNNEL_SEGMENTATION_FIGURE
  );
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {parameters: analysisFigureParameters} = useContext(AnalysisFigureContext);
  const data = useMemo(
    () => ({
      ...dataFromProps,
      items: extendFunnelSegmentationItems(dataFromProps.items, dataFromProps.totalEntities),
    }),
    [dataFromProps]
  );
  useTeamAutoSetterForSegments(data.items, EXTRACT_SEGMENT_DATA);
  const defaultFilters: Filters = useMemo(
    () => ({
      selectedStepIndex: exists(analysisFigureParameters.step)
        ? analysisFigureParameters.step
        : null,
    }),
    []
  );
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(
    id,
    defaultFilters,
    'filters'
  );
  const [selectedGroup, setSelectedGroup] = useState<FunnelSegmentGroup>(null);
  const {button: simulatorButton, mode} = useSimulatorMode({
    figureId: id,
    elementType: DocumentElementType.FUNNEL_SEGMENTATION_FIGURE,
    allowDataMode: true,
    isCasual: dataFromProps.mode === AnalysisAlgoMode.CAUSATION,
    label:
      dataFromProps.mode === AnalysisAlgoMode.CAUSATION
        ? t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODES.SIMULATOR_MODE_CAUSAL)
        : t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODES.SIMULATOR_MODE),
  });
  const itemsGroupedBySegmentGroup = useMemo(
    () => groupBy(data.items, 'segmentGroupName.0'),
    [data.items]
  );
  const tableData = useFilterSegmentItems(data.items, teamId, filters, EXTRACT_SEGMENT_DATA);
  const groupNames = useMemo(() => keys(itemsGroupedBySegmentGroup), [itemsGroupedBySegmentGroup]);
  const baseStepsOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i < data.steps.length; i++) {
      options.push({
        value: i - 1,
        label: `${data.steps[i - 1].name} (${i}) → ${data.steps[i].name} (${i + 1})`,
      });
    }
    return options;
  }, [data.steps]);
  const simulatorModeStepsOptions = useMemo(
    () => [
      {
        value: null,
        label: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.LABELS.ANY_STEP),
      },
      ...baseStepsOptions,
    ],
    [baseStepsOptions]
  );
  const dataModeStepsOptions = useMemo(
    () => [
      {
        value: null,
        label: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.LABELS.FUNNEL_COMPLETION, {
          stepsLength: data.steps.length,
        }),
      },
      ...baseStepsOptions,
    ],
    [baseStepsOptions]
  );
  const onGroupSelected = useCallback(
    (groupName: string) => {
      if (!exists(groupName)) {
        setSelectedGroup(null);
        return;
      }
      const group = itemsGroupedBySegmentGroup[groupName];
      setSelectedGroup({
        name: groupName,
        segments: group,
      });
      trackModalOpened('view_segment_group', {name: groupName});
    },
    [itemsGroupedBySegmentGroup, trackModalOpened]
  );
  const onFilterChange = useCallback(
    (newFilters: Filters) => {
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      }));
      const filterKey = keys(newFilters)[0];
      trackFilter(filterKey, {
        filter: newFilters[filterKey],
      });
    },
    [setFilters, trackFilter]
  );
  const onFollowUp = useCallback(
    (goal: number, refDate: number, segment: number, segmentClass: string) => {
      emitEvent({
        type: CommandType.FOLLOW_UP,
        payload: {
          type: FollowUpType.FUNNEL_ANALYSIS_CONVERSION,
          parameters: {
            funnel: data.funnelId,
            goal,
            refDate,
            segment,
            segmentClass: isArray(segmentClass) ? segmentClass : [segmentClass],
          },
        },
      });
    },
    [emitEvent, data.funnelId]
  );

  return (
    <div className={classNames(classes.FunnelSegmentationViewer, className)} ref={viewerRef}>
      <div className={classes.TabsWrapper}>
        <Select
          key={mode}
          dropdownButtonClassName={classes.StepSelect}
          icon={FilterLightIcon}
          prefix={t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.SELECT_FUNNEL_STEP_PREFIX)}
          value={filters.selectedStepIndex}
          onChange={(v: any) => onFilterChange({selectedStepIndex: v})}
          searchable={false}
          sortValues={false}
          options={{
            options:
              mode === SimulationMode.DATA ? dataModeStepsOptions : simulatorModeStepsOptions,
          }}
        />
        <SegmentFilters
          segments={data.items}
          extract={EXTRACT_SEGMENT_DATA}
          filtersValue={filters}
          onFiltersChange={onFilterChange}
          entity={options.entity}
        />
        {simulatorButton}
      </div>
      <div className={classes.Content}>
        {mode === SimulationMode.DATA && (
          <FunnelSegmentationDataModeTable
            figureId={id}
            options={options}
            items={tableData}
            totalEntities={data.totalEntities}
            onSeeAll={onGroupSelected}
            selectedStepIndex={filters.selectedStepIndex}
          />
        )}
        {mode === SimulationMode.SIMULATOR && (
          <FunnelSegmentationSimulatorMode
            figureId={id}
            options={options}
            items={tableData}
            goalValue={data.goalValue}
            steps={data.steps}
            selectedStepIndex={filters.selectedStepIndex}
            onFollowUp={onFollowUp}
            mode={data.mode}
          />
        )}
      </div>
      {selectedGroup && (
        <FunnelSegmentationModal
          figureId={id}
          selectedStepIndex={filters.selectedStepIndex}
          group={selectedGroup}
          groupOptions={groupNames}
          onSelectGroup={onGroupSelected}
          entity={options.entity}
          totalEntities={data.totalEntities}
          stepsOptions={dataModeStepsOptions}
          steps={data.steps}
          onFollowUp={onFollowUp}
        />
      )}
    </div>
  );
};

FunnelSegmentationViewer.defaultProps = {};
