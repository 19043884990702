import {
  ModelSampleSentenceKeys,
  NumberOfUsersMetricSampleInsight,
  UsersAllTimeHighOrLowSentenceParts,
  UsersOngoingTrendSentenceParts,
  UsersSignificantChangeSentenceParts,
} from './objects.ts';
import {useMemo} from 'react';
import {
  extractEntityNameFromProductEntities,
  formatPercentageValueWithPrefix,
} from '../../insights.utils.ts';
import {number2k} from 'front-core';
import {ordinalSuffix} from '../../../../utils/general.utils.ts';
import {ChangeDescription} from '../rca-insights.model.ts';
import {ProductEntities} from '../../../../objects/models/product-data.model.ts';
import {
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import {InfinityLightIcon} from 'ui-components';

interface OwnProps extends NumberOfUsersMetricSampleInsight {
  productEntities?: ProductEntities;
}

type AllProps = OwnProps;

const UsersAllTimeHighOrLowSentenceFormatter = (props: OwnProps) => {
  const {productEntities, sentenceParts, entity, granularityPlural} = props;
  const formattedSpikeDescription = useMemo(
    () =>
      (sentenceParts as UsersAllTimeHighOrLowSentenceParts).isMaximumInPeriod
        ? 'highest'
        : 'lowest',
    [sentenceParts]
  );

  return (
    <SentenceContainer>
      <SentencePart text={`This is the ${formattedSpikeDescription} number of`} />
      <SentencePart bold text={extractEntityNameFromProductEntities(entity, productEntities)} />
      <SentencePart text={`over the last 6 ${granularityPlural}.`} />
    </SentenceContainer>
  );
};

const UsersOngoingTrendAndUsersSignificantChangeSharedElements = (props: OwnProps) => {
  const {productEntities, sentenceParts, entity, xoxGranularity} = props;
  const formattedPercentageValue = useMemo(() => {
    if (sentenceParts.value === null) {
      return <InfinityLightIcon />;
    }
    return formatPercentageValueWithPrefix(sentenceParts.value);
  }, [sentenceParts]);
  return (
    <>
      <SentencePart
        text={`The number of ${extractEntityNameFromProductEntities(entity, productEntities)} ${
          sentenceParts.changeDescription
        }d by`}
      />
      <SentencePart text={formattedPercentageValue} />
      <SentencePart text={`${xoxGranularity},`} />
    </>
  );
};

const UsersOngoingTrendFormatter = (props: OwnProps) => {
  const {
    productEntities,
    sentenceParts: sentencePartsFromProps,
    entity: entityFromProps,
    xoxGranularity,
    granularity,
  } = props;
  const sentenceParts = useMemo(() => {
    const sp = sentencePartsFromProps as UsersOngoingTrendSentenceParts;
    return {
      lastSampleUserCount: number2k(sp.lastSampleUserCount),
      previousSampleUserCount: number2k(sp.previousSampleUserCount),
      trendNumberOfPeriods: ordinalSuffix(sp.trendNumberOfPeriods),
      changeDescriptionPrefix: sp.changeDescription === ChangeDescription.INCREASE ? 'an' : 'a',
      changeDescription: sp.changeDescription,
      entity: extractEntityNameFromProductEntities(entityFromProps, productEntities),
      xoxGranularity,
      granularity,
    };
  }, [sentencePartsFromProps, entityFromProps, productEntities, xoxGranularity, granularity]);

  return (
    <SentenceContainer>
      <UsersOngoingTrendAndUsersSignificantChangeSharedElements {...props} />
      <SentencePart
        text={`${sentenceParts.lastSampleUserCount} vs ${sentenceParts.previousSampleUserCount}, this is the ${sentenceParts.trendNumberOfPeriods} ${sentenceParts.granularity} in a row with ${sentenceParts.changeDescriptionPrefix} ${sentenceParts.changeDescription} trend ${sentenceParts.entity}`}
      />
    </SentenceContainer>
  );
};

const UserSignificantChangeFormatter = (props: OwnProps) => {
  const {sentenceParts: sentencePartsFromProps} = props;
  const sentenceParts = useMemo(() => {
    const sp = sentencePartsFromProps as UsersSignificantChangeSentenceParts;
    return {
      lastSampleUserCount: number2k(sp.lastSampleUserCount),
      previousSampleUserCount: number2k(sp.previousSampleUserCount),
    };
  }, [sentencePartsFromProps]);

  return (
    <SentenceContainer>
      <UsersOngoingTrendAndUsersSignificantChangeSharedElements {...props} />
      <SentencePart
        text={`${sentenceParts.lastSampleUserCount} vs ${sentenceParts.previousSampleUserCount}`}
      />
    </SentenceContainer>
  );
};

const NUMBER_OF_USERS_FORMATTERS_MAPPING = {
  [ModelSampleSentenceKeys.USERS_ALL_TIME_HIGH_OR_LOW]: UsersAllTimeHighOrLowSentenceFormatter,
  [ModelSampleSentenceKeys.USERS_ONGOING_TREND]: UsersOngoingTrendFormatter,
  [ModelSampleSentenceKeys.USERS_SIGNIFICANT_CHANGE]: UserSignificantChangeFormatter,
};

export const NumberOfUsersModelSampleInsightFormatter = (props: AllProps) => {
  const {sentenceKey} = props;
  const Formatter = NUMBER_OF_USERS_FORMATTERS_MAPPING[sentenceKey] || null;
  return Formatter ? <Formatter {...props} /> : null;
};
