import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './homepage-metric-viewer.module.scss';
import {HomepageAnnotation} from '../../../../../../../objects/models/homepage.model';
import {MetricValueType} from '../../../../../../../objects/models/metric.model';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {FlagIcon, PulseIcon, useRemoteSourceStated} from 'ui-components';
import {HomepageMetricChart} from './homepage-metric-chart/homepage-metric-chart.component';
import {getHomepageMetricDataNetworkRequest} from '../../../../../../../http/homepage.network-requests';
import {GenericLoading} from '../../../../../../shared/components/general/generic-loading/generic-loading.component';
import {HomepageModelViewerHeader} from '../components/homepage-model-viewer-header/homepage-model-viewer-header.component';
import {
  useChartXLabelForMetric,
  useHomepageChartConfig,
  useHomepageModelData,
  useModelGranularity,
  useModelSampleTimeframe,
  useRequestRCAForSample,
} from '../../../hooks/homepage-summary.hooks';
import {BaseModelSamplesViewerProps} from '../homepage-model-samples-viewer.types';
import {HomepageTimeframeOptions} from '../components/homepage-timeframe-options/homepage-timeframe-options.component';
import {HomepageGranularityOptions} from '../components/homepage-granularity-options/homepage-granularity-options.component';
import {HomepageChartOptions} from '../components/homepage-chart-options/homepage-chart-options.component';
import {PanelKey} from '../../../../../../../constants/panels';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../../constants/time-formats';
import {PanelsContext} from '../../../../../../../core/contexts/panels.context';
import {SeriesDatetimeAttribute} from '../../../../../../../objects/models/model-sample-series.model';
import {HomepageChartEmptyState} from '../components/homepage-chart-empty-state/homepage-chart-empty-state.component';
import {useTeamFilter} from '../../../../../../../core/contexts/team-filter.context';
import {ChartMode} from '../../../homepage-summary.types';
import {HomepageChartModeOptions} from '../components/homepage-chart-mode-options/homepage-chart-mode-options.component';
import {useAmplitude} from '../../../../../../../core/hooks/amplitude.hook';
import {AmplitudeEvent} from '../../../../../../../constants/amplitude-event';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../../../constants/feature-flags';
import {isArray} from 'lodash';
import {useProductData} from '../../../../../../../core/hooks/use-product-data.hook';

interface OwnProps extends BaseModelSamplesViewerProps {
  metricId: number;
  onCreateEditGoal?: (goalId?: number) => void;
  onViewMetric?: () => void;
  onViewMetricOpportunities?: () => void;
  onViewSegmentation?: () => void;
  datetimeAttribute?: SeriesDatetimeAttribute;
}

type AllProps = OwnProps;

export const HomepageMetricViewer = (props: AllProps) => {
  const {
    metricId,
    toDate,
    onCreateEditGoal,
    onViewMetric,
    onViewMetricOpportunities,
    onViewSegmentation,
    configuration,
    showHeader = true,
    showOptions = true,
    showLegend,
    datetimeAttribute,
    isLoading: isLoadingFromProps,
    className,
  } = props;
  const {t} = useTranslation();
  const {userSettings} = useProductData();
  const notify = useAmplitude();
  const showForecast = useFeatureIsOn(FeatureFlag.HOMEPAGE_PREDICTIONS as any);
  const {
    source: data,
    exec: getMetric,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getHomepageMetricDataNetworkRequest,
  });
  const {openSecondaryPanel} = useContext(PanelsContext);
  const {metrics = [], annotations = []} = data || {};
  const metric = metrics[0];
  const metricInstanceId = metric?.id;
  // Hooks
  const [chartMode, setChartMode] = useState(ChartMode.INCOMPLETE);
  const {granularity, setGranularity, granularityOptions} = useModelGranularity(metric);
  const {reviewedSeries, minSampleDate, maxSampleDate} = useHomepageModelData(metric, granularity);
  const {
    filters,
    isCustomRange,
    showAllPartialPoints,
    onTimeframeOptionSelected,
    onCustomDatesSelected,
    onChangeShowPartialPoints,
    isTimeframeSelected,
  } = useModelSampleTimeframe({toDate});
  const {teamId} = useTeamFilter();
  const {requestRCAForSample: onRCAFollowUp, isLoading: isLoadingRCA} = useRequestRCAForSample(
    configuration,
    reviewedSeries?.granularity,
    teamId
  );
  const {chartConfig, setChartConfig} = useHomepageChartConfig();
  const xLabel = useChartXLabelForMetric(metric, reviewedSeries?.granularity);
  const onAnnotationsClicked = useCallback(
    (annotations: HomepageAnnotation[]) => {
      openSecondaryPanel(PanelKey.HOMEPAGE_ANNOTATIONS_PANEL, {
        date: moment(annotations[0].timestamp).format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
      });
    },
    [openSecondaryPanel]
  );
  const onChangeChartMode = useCallback(
    (mode: ChartMode) => {
      setChartMode(mode);
      if (mode === ChartMode.PREDICTED) {
        notify(AmplitudeEvent.HOMEPAGE_FORECAST_CLICKED);
      }
    },
    [setChartMode, notify]
  );

  const onEditHomepageConfiguration = useCallback(
    () => openSecondaryPanel(PanelKey.HOMEPAGE_CONFIGURATION_PANEL),
    [openSecondaryPanel]
  );

  const anomalyModeText = useMemo(
    () =>
      userSettings?.anomalyMode
        ? t(
            TransKeys.HOMEPAGE_CONFIGURATION_PANEL.MODES[userSettings?.anomalyMode?.toUpperCase()][
              'LABEL'
            ]
          )
        : undefined,
    [t, userSettings]
  );

  const topChartActions = useMemo(
    () => [
      {
        title: (
          <div className={classes.ChangeAnomalyModeAction}>
            Anomly Mode: {anomalyModeText}
            <span className={classes.ChangeLabel}>Change</span>
          </div>
        ),
        onClick: onEditHomepageConfiguration,
        icon: PulseIcon,
      },
      {
        title: (
          <div className={classes.DefineQuarterlyTargetAction}>
            {t(TransKeys.HOMEPAGE.ACTIONS.DEFINE_QUARTERLY_TARGET)}
          </div>
        ),
        icon: FlagIcon,
        onClick: () => onCreateEditGoal(),
        hide: Boolean(metric?.goal) || !onCreateEditGoal,
      },
    ],
    [t, anomalyModeText, onEditHomepageConfiguration, onCreateEditGoal, metric]
  );

  const extraChartActions = useMemo(
    () => [
      {
        title: showAllPartialPoints
          ? t(TransKeys.HOMEPAGE.CHART.ACTIONS.SHOW_FIRST_PARTIAL_POINT)
          : t(TransKeys.HOMEPAGE.CHART.ACTIONS.SHOW_ALL_PARTIAL_POINTS),
        onClick: () => onChangeShowPartialPoints(!showAllPartialPoints),
        hide: isCustomRange,
      },
    ],
    [t, showAllPartialPoints, onChangeShowPartialPoints, isCustomRange]
  );
  const chartSeries = useMemo(() => [reviewedSeries], [reviewedSeries]);

  useEffect(() => {
    metricId &&
      getMetric({
        metricIds: isArray(metricId) ? metricId : [metricId],
        filters: {
          ...filters,
          datetimeAttribute,
        },
      });
  }, [
    getMetric,
    metricId,
    configuration?.anomalyMode,
    configuration?.anomalyThreshold,
    filters,
    datetimeAttribute,
  ]);
  const showEmptyState = !reviewedSeries || reviewedSeries.samples.length === 0;

  if (metric && showEmptyState) {
    return (
      <HomepageChartEmptyState
        text={t(TransKeys.HOMEPAGE.SAMPLING_IN_PROGRESS_EMPTY_STATE)}
        loading
      />
    );
  }

  if (!metric || isLoading) {
    return <GenericLoading />;
  }

  return (
    <div className={classNames(classes.HomepageMetricViewer, className)}>
      {(isLoading || isLoadingRCA) && !isLoadingFromProps && <GenericLoading />}
      {metric && !showEmptyState && showHeader && (
        <HomepageModelViewerHeader
          model={metric}
          onView={onViewMetric}
          onViewOpportunities={onViewMetricOpportunities}
          onViewSegmentation={onViewSegmentation}
          configuration={configuration}
        />
      )}
      {showOptions && (
        <div className={classes.Options}>
          <HomepageGranularityOptions
            granularity={granularity}
            onChange={setGranularity}
            options={granularityOptions}
            disabled={isLoading}
          />
          {showForecast &&
            reviewedSeries.predictedSamples &&
            reviewedSeries.predictedSamples.length > 0 && (
              <HomepageChartModeOptions
                mode={chartMode}
                onChange={onChangeChartMode}
                series={reviewedSeries}
                isPercentageValue={metric.valueType === MetricValueType.PERCENTAGE}
              />
            )}
          <div className={classes.Spacer} />
          <HomepageTimeframeOptions
            filters={filters}
            isCustomRange={isCustomRange}
            onTimeframeOptionSelected={onTimeframeOptionSelected}
            onCustomDatesSelected={onCustomDatesSelected}
            isTimeframeSelected={isTimeframeSelected}
            minSampleDate={minSampleDate}
            maxSampleDate={maxSampleDate}
            disabled={isLoading}
          />
          <HomepageChartOptions
            topActions={topChartActions}
            chartConfig={chartConfig}
            onChange={setChartConfig}
            extraActions={extraChartActions}
            hasTarget={Boolean(metric.goal)}
          />
        </div>
      )}
      <div className={classes.Chart}>
        <HomepageMetricChart
          key={metricInstanceId}
          metricName={metric.name}
          series={chartSeries as any}
          chartConfig={chartConfig}
          confidenceIntervalConfig={configuration}
          isPercentage={metric.valueType === MetricValueType.PERCENTAGE}
          higherIsBetter={metric.higherIsBetter}
          entity={metric.entity}
          goal={metric.goal?.value}
          xLabel={xLabel}
          hasCountEntities={reviewedSeries.hasDenominator}
          annotations={annotations}
          onAnnotationsClicked={onAnnotationsClicked}
          onSampleClicked={sample => onRCAFollowUp(metric, sample)}
          showLegend={showLegend}
          chartMode={chartMode}
        />
      </div>
    </div>
  );
};
