import {createContext, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {
  DocumentElementType,
  SmartTableFigureColumn,
  SmartTableFigureOptions,
  TablePartition,
} from '../../../types';
import {isArray, keys, min} from 'lodash';
import {ColumnStates, Pagination, TableFilters} from './smart-table.types';
import {filterData} from './logic/filter';
import {createInitialColumnsState, prepareData} from './logic/core';
import {exists, removeUndefinedKeys, sortData, Sorting} from 'front-core';
import {exportSmartTableAsCSV} from './logic/export';
import {useTableTracking} from './hooks/use-table-tracking.hook';
import {useDocumentTracking} from '../../../hooks/use-document-tracking.hook';
import {useDocQuery} from '../../../hooks/use-doc-query.hook';

export interface ISmartTableContext {
  options: SmartTableFigureOptions;
  data: any[];
  columns: SmartTableFigureColumn<any>[];
  isPartitionTable: boolean;
  partitions?: TablePartition[];
  columnsState: ColumnStates;
  localData: any[];
  displayData: any[];
  dataKey?: string;
  sorting: Sorting;
  filters: TableFilters;
  pagination: Pagination;
  setColumnsState: (columnStates: SetStateAction<ColumnStates>) => void;
  setLocalData: (data: SetStateAction<any[]>) => void;
  setSorting: (sorting: SetStateAction<Sorting>) => void;
  setFilters: (filters: SetStateAction<TableFilters>) => void;
  setPagination: (pagination: SetStateAction<Pagination>) => void;
  resetPagination: () => void;
  changeColumnState: (
    column: SmartTableFigureColumn<any>,
    change: {[attr: string]: any},
    resetFilterKeys?: string[],
    resetSortingKeys?: string[]
  ) => void;
  exportCSV: () => void;
  documentElementId: string;
  onClick?: (item: any) => void;
  selectedKey?: string;
}

const DEFAULT_CONTEXT_VALUE: ISmartTableContext = {
  dataKey: undefined,
  options: undefined,
  data: undefined,
  columns: undefined,
  partitions: undefined,
  isPartitionTable: undefined,
  columnsState: undefined,
  localData: undefined,
  displayData: undefined,
  sorting: undefined,
  filters: undefined,
  pagination: undefined,
  setColumnsState: undefined,
  setLocalData: undefined,
  setSorting: undefined,
  setFilters: undefined,
  setPagination: undefined,
  resetPagination: undefined,
  changeColumnState: undefined,
  exportCSV: undefined,
  documentElementId: undefined,
  onClick: undefined,
  selectedKey: undefined,
};

export const SmartTableContext = createContext<ISmartTableContext>(DEFAULT_CONTEXT_VALUE);

interface OwnProps {
  id: string;
  data: any[];
  columns: SmartTableFigureColumn<any>[];
  partitions?: TablePartition[];
  options: SmartTableFigureOptions;
  dataKey?: string;
  children: any;
  // internal
  onClick?: (item: any) => void;
  selectedKey?: string;
}

type AllProps = OwnProps;

const createInitialPagination = (perPage: number, dataLen: number) => ({
  page: 0,
  perPage: min([dataLen, exists(perPage) ? perPage : 10]),
});

const DEFAULT_FILTERS = {};
const DEFAULT_SORTING = {};

export const SmartTableContextProvider: React.FC<AllProps> = (props: AllProps) => {
  const {
    id,
    columns: columnsFromProps,
    data: dataFromProps,
    partitions,
    options,
    dataKey,
    children,
    // internal
    onClick,
    selectedKey,
  } = props;
  const [isReady, setIsReady] = useState(false);
  const {data, columns} = useMemo(
    () => prepareData([...dataFromProps], [...columnsFromProps]),
    [columnsFromProps, dataFromProps]
  );
  // Local data (for example the sorted data / filtered)
  const [localData, setLocalData] = useState<any[]>([...data]);
  const [columnsState, setColumnsState] = useState<ColumnStates>(
    createInitialColumnsState(columns)
  );
  const {query: sorting, setQuery: setSorting} = useDocQuery<Sorting>(
    id,
    options.defaultSort || (DEFAULT_SORTING as any),
    'sorting'
  );
  const {query: filters, setQuery: setFilters} = useDocQuery<TableFilters>(
    id,
    DEFAULT_FILTERS,
    'filters'
  );
  const [pagination, setPagination] = useState<Pagination>(
    createInitialPagination(localData.length, options.perPage)
  );
  const isPartitionTable = useMemo(
    () => isArray(partitions) && partitions.length > 0,
    [partitions]
  );

  const {trackExport} = useDocumentTracking(id, DocumentElementType.SMART_TABLE);
  useTableTracking(id, filters, sorting, pagination);

  // Current table displayed data (pagination)
  const displayData: any[] = useMemo(() => {
    if (!options.pagination || isPartitionTable) {
      return localData;
    }
    const from = pagination.page * pagination.perPage;
    const to = from + pagination.perPage;
    return localData.slice(from, to);
  }, [isPartitionTable, localData, options.pagination, pagination.page, pagination.perPage]);

  const changeColumnState = useCallback(
    (
      column: SmartTableFigureColumn<any>,
      change: {[attr: string]: any},
      resetFilterKeys: string[] = [],
      resetSortingKeys: string[] = []
    ) => {
      setColumnsState(columnsState => ({
        ...columnsState,
        [column.key]: {
          ...columnsState[column.key],
          ...change,
        },
      }));
      if (resetFilterKeys.length > 0) {
        const reset = resetFilterKeys.reduce((acc, curr) => {
          acc[curr] = undefined;
          return acc;
        }, {});
        setFilters(filters =>
          removeUndefinedKeys({
            ...filters,
            ...reset,
          })
        );
      }
      if (resetSortingKeys.indexOf(sorting.orderBy) > -1) {
        setSorting({} as any);
      }
    },
    [setColumnsState, setFilters, setSorting, sorting, filters]
  );
  const resetPagination = useCallback(
    () =>
      setPagination({
        page: 0,
        perPage: min([localData.length, exists(options.perPage) ? options.perPage : 10]),
      }),
    [setPagination, localData, options]
  );

  const exportCSV = useCallback(() => {
    exportSmartTableAsCSV(data, columns);
    trackExport();
  }, [data, columns, trackExport]);
  useEffect(() => {
    let newLocalData = filterData(data, filters);
    // Insignificant items should always be last, set primary sort to be the options.significantDataKey if defined
    const sortings = [sorting];
    if (options.significantDataKey) {
      sortings.unshift({
        order: 'desc',
        orderBy: options.significantDataKey,
      });
    }
    newLocalData = sortData(newLocalData, sortings[0], sortings[1]);
    // reset pagination when sorting or applying filters
    setPagination(pagination => ({...pagination, page: 0}));
    setLocalData(newLocalData);
    setIsReady(true);
  }, [data, setLocalData, sorting, filters, options.significantDataKey]);
  // fix perPage when options changed
  useEffect(() => {
    setPagination(createInitialPagination(localData.length, options.perPage));
  }, [options.perPage, localData.length]);

  const contextValue: ISmartTableContext = useMemo(
    () => ({
      options,
      columns,
      isPartitionTable,
      partitions,
      columnsState,
      data,
      localData,
      displayData,
      sorting,
      filters,
      pagination,
      dataKey,
      setColumnsState,
      setLocalData,
      setSorting,
      setFilters,
      setPagination,
      resetPagination,
      changeColumnState,
      exportCSV,
      documentElementId: id,
      onClick,
      selectedKey,
    }),
    [
      columns,
      columnsState,
      isPartitionTable,
      partitions,
      data,
      dataKey,
      displayData,
      filters,
      localData,
      options,
      pagination,
      resetPagination,
      sorting,
      changeColumnState,
      exportCSV,
      id,
      onClick,
      selectedKey,
    ]
  );

  return (
    <SmartTableContext.Provider value={contextValue}>
      {isReady ? children : null}
    </SmartTableContext.Provider>
  );
};

SmartTableContextProvider.defaultProps = DEFAULT_CONTEXT_VALUE;
