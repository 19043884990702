import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {getter} from '../../../../store/store.utils';
import {CORE_STORE_KEY} from '../../../../store/core/core.store';
import {QUICK_OPTIONS as TIME_FRAME_SELECTOR_OPTIONS} from '../../../analyses/analysis-forms/components/ui-selectors/time-frame-selector/time-frame-selector.component';

export const startEndDatesDefaultHandler = () => {
  const productData = getter(CORE_STORE_KEY, 'productData');
  const {defaultSource, defaultTimeframeStart} = productData;
  const productLastValidDate = defaultSource?.lastValidDate;
  const defaultStartFrom = defaultTimeframeStart
    ? TIME_FRAME_SELECTOR_OPTIONS[defaultTimeframeStart]
    : TIME_FRAME_SELECTOR_OPTIONS.l3m;
  const endDate = productLastValidDate ? moment(productLastValidDate) : moment().startOf('d');
  const startDate = endDate.clone().subtract(defaultStartFrom.count, defaultStartFrom.unit);
  return {
    start_date: startDate.format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
    end_date: endDate.format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
  };
};
