import * as React from 'react';
import classNames from 'classnames';
import classes from './sentence.module.scss';
import {useMemo} from 'react';
import moment from 'moment';
import {COMMAND_ICON} from '../document-viewer/components/base-viewers/smart-table-viewer/smart-table.config';
import {IconButton} from '../../simple/controls/icon-button/icon-button.component';
import marked from '../../../config/marked.config';
import {DEFAULT_DATE_FORMAT, DEFAULT_INPUT_DATE_FORMAT} from '../../../consts/ui';
import {exists, sanitize} from 'front-core';
import {every, isArray} from 'lodash';
import {TrendChip} from '../../simple/data-display/trend-chip/trend-chip.component';

interface OwnProps {
  name: string;
  parameters: Array<string | Array<string>>;
  type?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onClick?: () => void;
}

const CommandPart: React.FC<OwnProps> = (props: OwnProps) => {
  const {type, parameters, onClick, className} = props;
  const Icon = useMemo(() => COMMAND_ICON[type as any], [type]);

  return (
    <IconButton
      className={className}
      tooltipText={(parameters[2] as string) || 'Deep dive'}
      icon={Icon}
      onClick={onClick}
    />
  );
};

const TextPart: React.FC<OwnProps> = (props: OwnProps) => {
  const {parameters, className} = props;

  const htmlContent = useMemo(() => marked.parseInline(parameters[0] as string), [parameters]);

  return (
    <div className={classNames(classes.SentencePart, classes.TEXT, className)}>
      <span dangerouslySetInnerHTML={{__html: sanitize(htmlContent)}} />
    </div>
  );
};

const RefPart: React.FC<OwnProps> = (props: OwnProps) => {
  const {onClick, parameters, className, size} = props;

  const clickable = useMemo(() => {
    let ids = parameters[0];
    if (!onClick) {
      return false;
    }
    if (!exists(ids)) {
      return false;
    }
    if (!isArray(ids)) {
      ids = [ids];
    }
    return every(ids, id => Number(id) > 0);
  }, []);
  const main = parameters[1];
  const label = parameters[2];

  return (
    <div
      onClick={clickable ? _ => onClick() : undefined}
      className={classNames(
        classes.SentencePart,
        classes.REF,
        classes[size],
        clickable && classes.Clickable,
        className
      )}
    >
      {label && <span className={classes.Label}>{label}</span>}
      <span className={classes.Main}>{main}</span>
    </div>
  );
};

const ChangePart: React.FC<OwnProps> = (props: OwnProps) => {
  const {parameters, size, className} = props;
  const value: number = useMemo(() => Number(parameters[0]), [parameters]);
  const higherIsBetter: boolean = useMemo(
    () => !(parameters[1] === 'False' || parameters[1] === 'false'),
    [parameters]
  );
  const isSignificant: boolean = useMemo(
    () => parameters[2] === 'True' || parameters[2] === 'true',
    [parameters]
  );

  return (
    <div className={classNames(classes.SentencePart, className)}>
      <TrendChip
        className={classes.Trend}
        size={size}
        value={value}
        higherIsBetter={higherIsBetter}
        isSignificant={isSignificant}
      />
    </div>
  );
};

const DatePart: React.FC<OwnProps> = (props: OwnProps) => {
  const {parameters, className} = props;

  const dateString = useMemo(() => {
    const inputFormat = parameters[1] || DEFAULT_INPUT_DATE_FORMAT;
    const format: string = (parameters[2] as string) || DEFAULT_DATE_FORMAT;
    return moment(parameters[0], inputFormat).format(format);
  }, [parameters]);

  return (
    <div className={classNames(classes.SentencePart, classes.DATE, className)}>{dateString}</div>
  );
};

const ChipPart: React.FC<OwnProps> = (props: OwnProps) => {
  const {parameters, className} = props;

  return (
    <div className={classNames(classes.SentencePart, classes.CHIP, className)}>{parameters[0]}</div>
  );
};

export const SentencePart: React.FC<OwnProps> = (props: OwnProps) => {
  const {size, name, className} = props;

  const Component = useMemo(() => {
    switch (name) {
      case 'REF':
        return RefPart;
      case 'TEXT':
        return TextPart;
      case 'COMMAND':
        return CommandPart;
      case 'DATE':
        return DatePart;
      case 'CHANGE':
        return ChangePart;
      case 'CHIP':
        return ChipPart;
    }
  }, [name]);

  return Component ? (
    <Component {...props} className={classNames(classes[size], className)} />
  ) : null;
};

SentencePart.defaultProps = {};
