import * as React from 'react';
import classes from '../smart-table.module.scss';
import {TableRow} from './table-row.component';
import {useContext, useMemo} from 'react';
import {SmartTableContext} from '../smart-table.context';
import {isArray, groupBy} from 'lodash';
import {TablePartition} from './table-partition.component';
import {TableEmptyState} from './table-empty-state.component';

interface OwnProps {}

type AllProps = OwnProps;

export const TableBody: React.FC<AllProps> = (props: AllProps) => {
  const {data, columns, displayData, isPartitionTable, partitions, options} =
    useContext(SmartTableContext);

  const partitionsData = useMemo(() => {
    if (isPartitionTable) {
      return groupBy(displayData, options.partitionByDataKey);
    }
  }, [isPartitionTable, displayData, options]);

  // Currently not in use - once used we'll need to add collaboration ability
  const renderPartitions = () =>
    partitions.map(p => (
      <TablePartition
        key={p.key}
        name={p.name}
        commands={p.commands}
        items={partitionsData[p.key] || []}
        options={p.options}
      />
    ));

  const renderRows = () => {
    if (data.length === 0) {
      return <TableEmptyState />;
    }
    return displayData.map((d, idx) => <TableRow key={idx} columns={columns} data={d} />);
  };

  return <>{partitionsData ? renderPartitions() : renderRows()}</>;
};

TableBody.defaultProps = {};
