import * as React from 'react';
import {CSSProperties, useCallback, useMemo} from 'react';
import classes from './chart-viewer.module.scss';
import {ChartFigure, ChartOptions, ChartType} from '../../../types';
import {min} from 'lodash';
import {ChartComponents} from '../../../document-viewer.config';
import classNames from 'classnames';
import {ChildRendererInjectedProps, ViewerComponentMode} from '../../../document-viewer.types';
import {DocumentTitleWrapper} from '../title-section-viewer/title-section-viewer.component';

export interface OwnProps
  extends ChartFigure<any, ChartOptions>,
    Partial<ChildRendererInjectedProps> {
  style?: CSSProperties;
  className?: string;
}

const MAX_HEIGHT = 400;

export const ChartViewer: React.FC<OwnProps> = (props: OwnProps & ChildRendererInjectedProps) => {
  const ChartComponent = ChartComponents[props.chartType];
  const {chartType, data, options, componentMode, style, changeParameters, className} = props;

  const onDatasetClick = useCallback(
    (ds: any) => {
      if (componentMode === ViewerComponentMode.HIGHLIGHT_MODE) {
        const currentHighlightIds = new Set(options['highlightIds'] || []);
        if (currentHighlightIds.has(ds.id)) {
          currentHighlightIds.delete(ds.id);
        } else {
          currentHighlightIds.add(ds.id);
        }
        return changeParameters({
          options: {
            highlightIds: Array.from(currentHighlightIds),
          },
        });
      }
    },
    [componentMode, data, options, changeParameters]
  );

  // Defining default height for each chart type
  const height: number = useMemo(() => {
    if (options.maxHeight) {
      return options.maxHeight;
    }
    switch (chartType) {
      case ChartType.FUNNEL_AREA:
        return 0.8 * MAX_HEIGHT;
      case ChartType.LINE:
      case ChartType.BAR:
        return MAX_HEIGHT;
      case ChartType.FUNNEL:
        return undefined;
      case ChartType.DUMBBELL:
        return min([data.length * 50, MAX_HEIGHT]);
      case ChartType.PIE:
        return 0.7 * MAX_HEIGHT;
    }
    return MAX_HEIGHT;
  }, [chartType, data.length, options.maxHeight]);

  if (!ChartComponent) {
    return null;
  }
  return (
    <DocumentTitleWrapper
      title={props.title}
      style={style}
      className={classNames(classes.ChartViewer, className)}
    >
      <div
        style={{
          height: height ? `${height}px` : undefined,
        }}
        className={classes.ChartWrapper}
      >
        <ChartComponent
          {...props}
          datasets={data}
          displayedDatasetIds={options.displayedDatasetIds}
          labels={props.labels}
          options={props.options}
          onDatasetClick={onDatasetClick}
          darkMode={props.darkMode}
        />
      </div>
    </DocumentTitleWrapper>
  );
};

ChartViewer.defaultProps = {
  options: {},
};
