import * as React from 'react';
import classes from './group-info-box.module.scss';
import {InfoBox} from '../../generic-components/info-box/info-box.component';
import {KPIElementData, KPIElementOptions} from '../../../../../types/growth-map.types';
import {IconCounters} from '../../generic-components/icon-counter/icon-counter.component';
import StarIcon from '@material-ui/icons/Star';
import {KPIStatusLine} from '../kpi-status-line/kpi-status-line.component';
import {Progress} from '../../../../simple/data-display/progress/progress.component';
import {useMemo} from 'react';
import classNames from 'classnames';
import {BulbIcon, WandIcon} from '../../../../simple/controls/icons/icons.component';
import {GrowthMapIcon} from '../../generic-components/growth-map-icon/growth-map-icon.component';
import TimelineIcon from '@material-ui/icons/Timeline';

interface OwnProps {
  title: string;
  data: KPIElementData;
  options: KPIElementOptions;
  model: string;
  isExpanded: boolean;
  onExpand: () => void;
  metricId?: number;
  color?: string;
  isNorthStar?: boolean;
  infoboxClassName?: string;
  onOpportunitiesClicked?: (e) => void;
  onLoopsClicked?: (e) => void;
  onKPIClicked?: (e) => void;
}

type AllProps = OwnProps;

const TOP_OFFSET = 50;
const LEFT_OFFSET = -30;
const MAX_WIDTH = 310;
const MIN_WIDTH = 250;

export const GroupInfoBox: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    options,
    onExpand,
    isExpanded,
    color,
    isNorthStar,
    data,
    model,
    infoboxClassName,
    onOpportunitiesClicked,
    onLoopsClicked,
    onKPIClicked,
    metricId,
  } = props;

  const counters = useMemo(
    () => [
      {
        count: data.opportunitiesCount,
        icon: WandIcon,
        iconColor: '#FF69CC',
        label: 'opportunities',
        onClick: onOpportunitiesClicked,
      },
      {
        count: data.loopsCount,
        icon: BulbIcon,
        iconColor: '#FFE27C',
        label: 'growth ideas',
        onClick: onLoopsClicked,
      },
    ],
    [data.loopsCount, data.opportunitiesCount, onOpportunitiesClicked, onLoopsClicked]
  );

  const renderActions = () => {
    if (isExpanded && metricId) {
      return <GrowthMapIcon onClick={onKPIClicked} icon={TimelineIcon} label={'KPI'} />;
    }
    if (!isExpanded) {
      return <IconCounters counters={counters} />;
    }
    return null;
  };
  const renderChildren = () => {
    const showProgress = data.samples?.length > 1 && data.goal?.value;
    const showKPIStatus = data.samples?.length > 0;

    if (!showKPIStatus && !showProgress && !isExpanded) {
      return null;
    }

    return (
      <div className={classes.Content}>
        {showKPIStatus && (
          <KPIStatusLine
            className={classes.InfoItem}
            samples={data.samples}
            higherIsBetter={data.higherIsBetter}
            preview={!isExpanded}
            round={options.round}
            model={model}
          />
        )}
        {showProgress && (
          <Progress
            value={(data.samples[0].value / data.goal.value) * 100}
            max={data.goal.value}
            unit={data.type === 'percentage' ? '%' : undefined}
            label={'Goal'}
            className={classes.InfoItem}
            preview={!isExpanded}
          />
        )}
        {isExpanded && (
          <IconCounters
            className={classes.InfoItem}
            counters={counters}
            isExpanded={true}
            textColor={'#ffffff'}
          />
        )}
      </div>
    );
  };

  return (
    <InfoBox
      icon={isNorthStar ? StarIcon : undefined}
      className={classNames(classes.GroupInfoBox, !isExpanded && classes.Preview, infoboxClassName)}
      style={{
        top: `${TOP_OFFSET * (options.direction === 'to-top' ? -1 : 1)}px`,
        left: `${LEFT_OFFSET}px`,
        transform: `translateY(${options.direction === 'to-top' ? '-100%' : 0})`,
        width: isExpanded ? `${MAX_WIDTH}px` : `${MIN_WIDTH}px`,
      }}
      isActive={isExpanded}
      color={color}
      title={title}
      onClick={onExpand}
      actions={renderActions()}
    >
      {renderChildren()}
    </InfoBox>
  );
};

GroupInfoBox.defaultProps = {};
