import classNames from 'classnames';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {CommandType} from '../../../../../../types';
import {useMemo} from 'react';
import TransKeys from 'translations';
import {exists} from 'front-core';
import {ModelType} from '../../../../../../../../../consts/model-type';
import classes from './power-users-segmentation-modal.module.scss';
import {NextPrevNavigator} from '../../../../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {KPISegmentGroupTable} from '../../../../kpi-analysis/kpi-segmentation-viewer/components/kpi-segment-group-table/kpi-segment-group-table.component';
import {SegmentationModalHeader} from '../../../../../shared/segmentation/segmentation-modal-header/segmentation-modal-header.component';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {PowerUsersSegmentGroup} from '../../power-users-segmentation-viewer.component';

interface OwnProps {
  group: PowerUsersSegmentGroup;
  groupOptions: string[];
  onSelectGroup: (group: string) => void;
  entity: string;
  totalEntities: number;
  className?: string;
}

type AllProps = OwnProps;

function getHelperText(isInformative: boolean, isSignificant: boolean, helperText: string) {
  if (isInformative === false) {
    return {
      nameHelperText: helperText,
    };
  }
  if (!isSignificant) {
    return {
      upliftHelperText: helperText,
    };
  }
  return {};
}

export const PowerUsersSegmentationModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    group,
    groupOptions: groupOptionsFromProps,
    onSelectGroup,
    entity,
    totalEntities,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const groupOptions = useMemo(
    () =>
      groupOptionsFromProps.map(g => ({
        value: g,
        label: g,
      })),
    [groupOptionsFromProps]
  );
  const navigatorProps = useMemo(() => {
    if (groupOptions.length === 0) {
      return;
    }
    const currentIndex = groupOptions.findIndex(i => i.value === group?.name);
    const maxIndex = groupOptions.length - 1;
    return {
      currentIndex,
      maxIndex,
      onNextPrev: (isNext: boolean) =>
        onSelectGroup(groupOptions[currentIndex + (isNext ? 1 : -1)].value),
      nextLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.NEXT_LABEL),
      prevLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.PREV_LABEL),
    };
  }, [groupOptions, group.name, onSelectGroup, t]);
  const tableData = useMemo(
    () =>
      group.segments.map(s => {
        return {
          key: s.key,
          name: s.segmentName[0],
          signalId: s.signalId[0],
          count: s.count,
          share: s.share * 100,
          uplift: s.uplift * 100,
          goalInSegment: s.valueInSegment,
          goalInNonSegment: s.valueInNonSegment,
          isSignificant: s.isSignificant,
          isInformative: s.isInformative,
          command: {
            type: CommandType.REF,
            payload: {
              type: ModelType.SIGNAL,
              refId: s.signalId[0],
            },
          },
          ...getHelperText(s.isInformative, s.isSignificant, s.helperText),
        };
      }),
    [group.segments]
  );

  return (
    <ModalWrapper isOpen={group} onClose={() => onSelectGroup(null)} width={'70vw'} height={'80vh'}>
      <div className={classNames(classes.PowerUsersSegmentationModal, className)}>
        <SegmentationModalHeader
          title={t(TransKeys.DOCUMENT_VIEWER.POWER_USERS_SEGMENTATION_FIGURE.MODAL.TITLE.PREFIX)}
          selectedGroup={group?.name}
          groupOptions={groupOptions}
          onSelectGroup={onSelectGroup}
        />
        <KPISegmentGroupTable
          tableData={tableData}
          entity={entity}
          totalEntities={totalEntities}
          transKeysPrefix={TransKeys.DOCUMENT_VIEWER.POWER_USERS_SEGMENTATION_FIGURE}
        />
        <div className={classes.Spacer} />
        {navigatorProps && (
          <div className={classes.Navigation}>
            <NextPrevNavigator {...navigatorProps} />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

PowerUsersSegmentationModal.defaultProps = {};
