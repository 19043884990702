import * as React from 'react';
import {Aggregation, AggregationFunction} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {QueryBlock} from '../components/query-block/query-block.component';
import {queryBuilderClasses} from '../query-builder.component';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {useMemo} from 'react';
import {values} from 'lodash';
import {AggregationFunctionName} from '../query-builder.config';
import {Select} from '../../../forms/inputs/select/select.component';
import {useQueryElement} from '../query-builder.hooks';
import {withViewMode} from '../core/with-view-mode.hoc';
import {InlineToken} from '../components/parts';

interface OwnProps {
  data: Aggregation;
  inline?: boolean;
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const AggregationBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled, inline, box} = props;
  const {localErrors, actions} = useQueryElement(props);
  const options = useMemo(
    () =>
      values(AggregationFunction).map(o => ({
        label: AggregationFunctionName[o],
        value: o,
      })),
    []
  );

  return (
    <QueryBlock
      label={'Aggregation'}
      actions={actions}
      disabled={disabled}
      className={className}
      inline={inline}
      box={box}
      collapsible={false}
    >
      <Select
        className={queryBuilderClasses.Inline}
        dropdownButtonClassName={queryBuilderClasses.Button}
        placeholder={'Operator'}
        value={data.function}
        error={Boolean(localErrors.function)}
        onChange={v => onChange(concatPath(path, 'function'), v)}
        searchable={false}
        clearable={false}
        options={{options}}
        disabled={disabled}
      />
      <QueryElementBuilder
        error={Boolean(localErrors.element)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'element')}
        data={data.element as any}
        box={false}
        inline
      />
    </QueryBlock>
  );
};

export const AggregationViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, className} = props;

  return (
    <QueryBlock
      label={'Aggregation'}
      disabled={true}
      className={className}
      inline={true}
      box={false}
      collapsible={false}
      fitContent
    >
      <InlineToken>{AggregationFunctionName[data.function]}</InlineToken>
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'element')}
        data={data.element as any}
        box={false}
        inline
      />
    </QueryBlock>
  );
};

export const AggregationBuilder = withViewMode(
  AggregationBuilderComponent,
  AggregationViewerComponent
);
