import * as React from 'react';
import {EnhancedGroup, EnhancedLine, EnhancedText} from '../../../../core/konva/components';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {useContext, useMemo} from 'react';
import {HorizontalGridLayoutInjectedProps} from '../layouts/grid-layout.component';
import {keys} from 'lodash';
import {StageContext} from '../../../../core/konva/stage-context.component';

export interface SplitQuartersDefinition {
  x?: number;
  y?: number;
  labels?: {
    q0?: string;
    q1?: string;
    q2?: string;
    q3?: string;
  };
}

interface OwnProps extends HorizontalGridLayoutInjectedProps {
  split: SplitQuartersDefinition;
  strokeColor?: string;
}

type AllProps = ShapeProps & OwnProps;

export const SplitQuarters: React.FC<AllProps> = (props: AllProps) => {
  const {x, y, width, height, strokeColor, xRange, yRange, split, xTickSize} = props;
  const context = useContext(StageContext);
  const {controller, style} = context;

  const xVerticalPoints = useMemo(() => {
    if (!split.x) {
      return [];
    }
    const totalRange = Math.abs(xRange[0] - xRange[1]);
    const xVertical = ((split.x - xRange[0]) / totalRange) * width;
    return [xVertical, 0, xVertical, height];
  }, [split.x, xRange, width, height]);
  const yHorizontalPoints = useMemo(() => {
    if (!split.y) {
      return [];
    }
    const totalRange = Math.abs(yRange[0] - yRange[1]);
    const yHorizontal = ((split.y - yRange[0]) / totalRange) * height;
    const gridPadding = xTickSize / 2;
    return [0 - gridPadding, height - yHorizontal, width + gridPadding, height - yHorizontal];
  }, [split.y, yRange, height, xTickSize, width]);
  const labels = useMemo(() => {
    if (!split.labels) {
      return [];
    }

    const w0 = xVerticalPoints[0];
    const w1 = width - w0;
    const h0 = yHorizontalPoints[1];
    const h1 = height - h0;

    return keys(split.labels).map(k => {
      const label = split.labels[k];
      const width = controller.measureTextWidth(label, style.fontName, 40);
      const res = {
        label,
        width,
        key: k,
        x: 0,
        y: 0,
      };
      switch (k) {
        case 'q0':
          res.x = w0 + w1 / 2;
          res.y = h0 / 2;
          return res;
        case 'q1':
          res.x = w0 + w1 / 2;
          res.y = h0 + h1 / 2;
          return res;
        case 'q2':
          res.x = w0 / 2;
          res.y = h0 + h1 / 2;
          return res;
        case 'q3':
          res.x = w0 / 2;
          res.y = h0 / 2;
          return res;
      }
      return null;
    });
  }, [split.labels, xVerticalPoints, width, yHorizontalPoints, height, controller, style.fontName]);

  return (
    <EnhancedGroup x={x} y={y} width={width} height={height}>
      <EnhancedLine
        points={xVerticalPoints}
        stroke={strokeColor}
        strokeWidth={2}
        dash={[8, 8]}
        lineJoin={'round'}
      />
      <EnhancedLine
        points={yHorizontalPoints}
        stroke={strokeColor}
        strokeWidth={2}
        dash={[8, 8]}
        lineJoin={'round'}
      />
      {labels.map(l => (
        <EnhancedGroup key={l.key} x={l.x} y={l.y} width={l.width} height={40} center>
          <EnhancedText text={l.label} fontSize={40} fill={strokeColor} />
        </EnhancedGroup>
      ))}
    </EnhancedGroup>
  );
};

SplitQuarters.defaultProps = {
  strokeColor: '#C3C6D8',
};
