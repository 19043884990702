import {forIn} from 'lodash';
import {PopoverWrapper} from '../../../../../../../../simple/generic/popover-wrapper/popover-wrapper.component';
import {ExperimentSummaryVariantItem} from '../../experiment-variants-table.component';
import {useMemo} from 'react';
import {exists} from 'front-core';
import classNames from 'classnames';
import classes from './validations-table-cell.module.scss';
import {
  ReleaseImpactSummaryValidationConfidenceLevel,
  ReleaseImpactSummaryValidations,
} from '../../../../../../types';
import {ChevronRightSolidIcon} from '../../../../../../../../simple/controls/icons/icons.component';
import {getValidationIcon, getValidationsCounts} from '../../validations.utils';
import {ValidationsExplainers} from '../validations-explainers/validations-explainers.component';

type ValidationsTableCellProps = {
  validations: ReleaseImpactSummaryValidations;
};
export const ValidationsTableCell = (props: ValidationsTableCellProps) => {
  const {validations} = props;

  const {validationConfidenceLevel} = validations;

  const {totalCount, passCount} = useMemo(() => {
    return getValidationsCounts(validations);
  }, [validations]);

  const Icon = useMemo(() => getValidationIcon(validations), [validations]);

  return (
    <PopoverWrapper
      placement="bottom"
      popoverDirection="right"
      buttonRenderer={props => (
        <div
          className={classNames(
            classes.ValidationsTableCellButton,
            validationConfidenceLevel === ReleaseImpactSummaryValidationConfidenceLevel.HIGH
              ? classes.HighConfidence
              : classes.MediumConfidence
          )}
          onClick={props.onClick}
        >
          <Icon />
          <div>
            {passCount}/{totalCount} PASSED
          </div>
          <ChevronRightSolidIcon
            className={classNames(classes.ChevronIcon, props.isOpen && classes.IsOpen)}
          />
        </div>
      )}
    >
      <ValidationsExplainers validations={validations} />
    </PopoverWrapper>
  );
};
