import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import classes from './segment-chart-overtime-modal.module.scss';
import {MetricSampleData, RCASegmentationFigureOptions} from '../../../../../../types';
import {SegmentationModalHeader} from '../../../../../shared/segmentation/segmentation-modal-header/segmentation-modal-header.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import classNames from 'classnames';
import {keyBy} from 'lodash';
import {LineChart} from '../../../../../../../charts/charts/line-chart/line-chart.component';
import {SwitchActions} from '../../../../../../../../simple/controls/switch-actions/switch-actions.component';
import pluralize from 'pluralize';
import {UsersIcon} from '../../../../../../../../simple/controls/icons/icons.component';
import {sortData, Sorting} from 'front-core';
import {RCASegmentationViewMode} from '../../../rca-figure/rca-figure-viewer.types';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';
import {SegmentChartItemSelector} from './components/segment-chart-item-selector/segment-chart-item-selector.component';

interface OwnProps {
  initialSegmentKey?: string;
  granularity: string;
  metricSamples: MetricSampleData[];
  viewMode: RCASegmentationViewMode;
  segments: UnifiedRCASegmentationItem[];
  selectedGroupSignalId?: number;
  onSelectGroup?: (signalId: number) => void;
  groupOptions?: {label: string; value: number}[];
  options: RCASegmentationFigureOptions;
}

type AllProps = OwnProps;

export enum ChartMode {
  KPI_VALUE,
  ENTITIES_COUNT,
}

const PRIMARY_ORDER: Sorting = {orderBy: 'isSignificant', order: 'desc'};
const SECONDARY_ORDER_EXPLAIN: Sorting = {
  orderBy: 'explainabilityScore',
  order: 'desc',
};
const SECONDARY_ORDER_CHANGE: Sorting = {
  orderBy: 'interestScore',
  order: 'desc',
};

export const formatNumber = (value: number, isPercentage: boolean) =>
  isPercentage ? value * 100 : value;

export const useSegmentChartOvertimeChartData = (config: {
  selectedSegmentKeys: string[];
  segmentsByKey: {[key: string]: UnifiedRCASegmentationItem};
  options: RCASegmentationFigureOptions;
  metricSamples: MetricSampleData[];
  chartMode: ChartMode;
  granularity: string;
  showAllPopulation: boolean;
}) => {
  const {
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    chartMode,
    granularity,
    showAllPopulation,
  } = config;

  return useMemo(() => {
    const segments = selectedSegmentKeys.map(k => segmentsByKey[k]).filter(s => s);
    const kpiDataset =
      metricSamples && showAllPopulation
        ? {
            id: 'kpi',
            label: options.kpiName,
            data: metricSamples.map(s => ({
              x: s.datetime,
              y:
                chartMode === ChartMode.KPI_VALUE
                  ? formatNumber(s.value, options.isPercentageValue)
                  : s.entityCount,
            })),
          }
        : undefined;
    const segmentsDatasets = segments.map(segment => {
      const data = segment.samples.map(s => ({
        x: s.datetime,
        y:
          chartMode === ChartMode.KPI_VALUE
            ? formatNumber(s.value, options.isPercentageValue)
            : s.entityCount,
      }));
      return {
        id: segment.key,
        label: segment.name,
        data,
      };
    });
    const datasets = kpiDataset ? [kpiDataset, ...segmentsDatasets] : segmentsDatasets;

    if (datasets.length === 0) {
      return null;
    }

    return {
      datasets: datasets,
      options: {
        labels: {
          dateFormat: 'DD MMM',
          type: 'date',
          timeUnit: granularity,
        },
        disableLegend: true,
        yLabelSuffix:
          chartMode === ChartMode.KPI_VALUE
            ? options.isPercentageValue
              ? '%'
              : undefined
            : undefined,
        yAxisMaxTicks: 5,
        xLabel: 'date',
      },
    } as any;
  }, [
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    chartMode,
    granularity,
    showAllPopulation,
  ]);
};

export const SegmentChartOvertimeModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    selectedGroupSignalId,
    initialSegmentKey,
    onSelectGroup,
    granularity,
    metricSamples,
    segments: segmentsFromProps,
    groupOptions,
    options,
    viewMode,
  } = props;
  const {t} = useDocumentTranslation();
  const segments: UnifiedRCASegmentationItem[] = useMemo(
    () =>
      sortData(
        segmentsFromProps,
        PRIMARY_ORDER,
        viewMode === RCASegmentationViewMode.EXPLAIN
          ? SECONDARY_ORDER_EXPLAIN
          : SECONDARY_ORDER_CHANGE
      ).map(s => ({
        ...s,
        name: s.segmentName,
      })),
    [segmentsFromProps, viewMode]
  );
  const [chartMode, setChartMode] = useState(ChartMode.KPI_VALUE);
  const [selectedSegmentKeys, setSelectedSegmentKeys] = useState<string[]>(
    initialSegmentKey ? [initialSegmentKey] : [segments[0].key]
  );
  const [showAllPopulation, setShowAllPopulation] = useState(true);
  const segmentsByKey = useMemo(() => keyBy(segments, 'key'), [segments]);
  const selectedSegmentKeySet = useMemo(() => new Set(selectedSegmentKeys), [selectedSegmentKeys]);
  const onSegmentClicked = useCallback(
    (key: string) => {
      if (selectedSegmentKeySet.has(key)) {
        selectedSegmentKeySet.delete(key);
      } else {
        selectedSegmentKeySet.add(key);
      }
      setSelectedSegmentKeys(Array.from(selectedSegmentKeySet));
    },
    [selectedSegmentKeySet, setSelectedSegmentKeys]
  );
  const chartData = useSegmentChartOvertimeChartData({
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    chartMode,
    granularity,
    showAllPopulation,
  });
  const chartOptions = useMemo(
    () => [
      {
        label: 'KPI Value',
        onClick: () => setChartMode(ChartMode.KPI_VALUE),
        isActive: chartMode === ChartMode.KPI_VALUE,
      },
      {
        label: `# ${pluralize(options.entity)}`,
        onClick: () => setChartMode(ChartMode.ENTITIES_COUNT),
        isActive: chartMode === ChartMode.ENTITIES_COUNT,
      },
    ],
    [chartMode]
  );
  useEffect(() => {
    setSelectedSegmentKeys(initialSegmentKey ? [initialSegmentKey] : [segments[0].key]);
  }, [initialSegmentKey, setSelectedSegmentKeys, segments]);

  return (
    <ModalWrapper
      isOpen={selectedGroupSignalId !== null}
      onClose={() => onSelectGroup(null)}
      width={'110rem'}
      height={'60rem'}
    >
      <div className={classes.SegmentChartOvertimeModal}>
        <SegmentationModalHeader
          title={t(TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.SEGMENT_OVER_TIME_MODAL.TITLE)}
          subTitle={t(
            TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.SEGMENT_OVER_TIME_MODAL.SUB_TITLE
          )}
          selectedGroup={selectedGroupSignalId}
          groupOptions={groupOptions}
          onSelectGroup={onSelectGroup}
        />
        <div className={classes.Main}>
          <SegmentChartItemSelector
            segments={segments}
            selectedSegmentKeySet={selectedSegmentKeySet}
            onSegmentClicked={onSegmentClicked}
            showAllPopulation={showAllPopulation}
            setShowAllPopulation={setShowAllPopulation}
            viewMode={viewMode}
            options={options}
            className={classes.List}
          />
          <div
            className={classNames(
              classes.ChartWrapper,
              options.hasDenominator && classes.HasOptions
            )}
          >
            {options.hasDenominator && (
              <div className={classes.Options}>
                <SwitchActions actions={chartOptions} showActionsLabel />
              </div>
            )}
            <div className={classes.Chart}>
              {chartData && <LineChart {...chartData} key={selectedSegmentKeys.join('.')} />}
              {chartData === null && (
                <div className={classes.EmptyState}>
                  <UsersIcon className={classes.EmptyStateIcon} />
                  {t(
                    TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.SEGMENT_OVER_TIME_MODAL
                      .EMPTY_STATE
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

SegmentChartOvertimeModal.defaultProps = {};
