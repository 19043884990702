import {
  AndCondition,
  Condition,
  ConditionOperator,
  ExtendedSqlElementType,
  LiteralValueType,
  QueryBuilderElementType,
  SqlElementType,
} from './query-builder.types';

export const QueryBuilderFactory = {
  create: (type: QueryBuilderElementType): any => {
    switch (type) {
      case SqlElementType.CASES:
        return QueryBuilderFactory.createCases();
      case SqlElementType.CONDITION:
        return QueryBuilderFactory.createCondition();
      case SqlElementType.TABLE_COLUMN:
        return QueryBuilderFactory.createTableColumn();
      case SqlElementType.IMPLICIT_COLUMN:
        return QueryBuilderFactory.createImplicitColumn();
      case SqlElementType.SIGNAL_COLUMN:
        return QueryBuilderFactory.createSignalColumn();
      case SqlElementType.LITERAL:
        return QueryBuilderFactory.createLiteral();
      case SqlElementType.OR_CONDITION:
        return QueryBuilderFactory.createOrCondition();
      case SqlElementType.AND_CONDITION:
        return QueryBuilderFactory.createAndCondition();
      case SqlElementType.NULLIF:
        return QueryBuilderFactory.createNullIf();
      case SqlElementType.AGGREGATION:
        return QueryBuilderFactory.createAggregation();
      case SqlElementType.NUMERIC_FUNCTION:
        return QueryBuilderFactory.createNumericFunction();
      case SqlElementType.TEMPLATE:
        return QueryBuilderFactory.createTemplate();
      case SqlElementType.BINARY_OPERATION:
        return QueryBuilderFactory.createBinaryOperation();
      case SqlElementType.COALESCE:
        return QueryBuilderFactory.createCoalesce();
      case SqlElementType.FILL_NULL:
        return QueryBuilderFactory.createFillNull();
      case SqlElementType.DATE_PART:
        return QueryBuilderFactory.createDatePart();
      case SqlElementType.DATE_BINARY_OPERATION:
        return QueryBuilderFactory.createDateBinaryOperation();
      case SqlElementType.WINDOW:
        return QueryBuilderFactory.createWindow();
      case SqlElementType.RAW_QUERY:
        return QueryBuilderFactory.createRawQuery();
      case SqlElementType.TRANSFORMER:
        return QueryBuilderFactory.createTransformer();
      case ExtendedSqlElementType.SEGMENT_CONDITION:
        return QueryBuilderFactory.createSegmentCondition();
      case ExtendedSqlElementType.NULL:
        return QueryBuilderFactory.createLiteral(true);
      default:
        return {type};
    }
  },
  wrap: (object: any, wrapperType: SqlElementType): any => {
    const wrapper: AndCondition = QueryBuilderFactory.create(wrapperType);
    const self: Condition = QueryBuilderFactory.create(object.type);

    switch (wrapper.type) {
      case SqlElementType.AND_CONDITION:
      case SqlElementType.OR_CONDITION:
        wrapper.conditions.unshift(self);
        self.right = object.right;
        self.left = object.left;
        self.op = object.op;

        delete object.right;
        delete object.left;
        delete object.op;

        return wrapper;
    }
  },
  createCases: (): any => ({
    type: SqlElementType.CASES,
    else_value: {},
    cases: [QueryBuilderFactory.createCase()],
  }),
  createCase: (): any => [{}, {}],
  createCondition: (): any => ({
    type: SqlElementType.CONDITION,
    left: {},
    right: {},
    op: null,
  }),
  createTableColumn: (): any => ({
    type: SqlElementType.TABLE_COLUMN,
    table_id: null,
    column: '',
  }),
  createLiteral: (literalNull = false): any => ({
    type: SqlElementType.LITERAL,
    value: literalNull ? null : '',
    value_type: literalNull ? LiteralValueType.NULL : null,
  }),
  createOrCondition: (): any => ({
    type: SqlElementType.OR_CONDITION,
    conditions: [{}],
  }),
  createAndCondition: (): any => ({
    type: SqlElementType.AND_CONDITION,
    conditions: [{}],
  }),
  createImplicitColumn: (): any => ({
    type: SqlElementType.IMPLICIT_COLUMN,
    table_name: '',
    column: '',
    dependencies: [{}],
  }),
  createSignalColumn: (signal_id: number = null): any => ({
    type: SqlElementType.SIGNAL_COLUMN,
    signal_id: signal_id,
  }),
  createNullIf: (): any => ({
    type: SqlElementType.NULLIF,
    expression1: {},
    expression2: {},
  }),
  createAggregation: (): any => ({
    type: SqlElementType.AGGREGATION,
    function: null,
    element: {},
  }),
  createNumericFunction: (): any => ({
    type: SqlElementType.NUMERIC_FUNCTION,
    elements: [{}],
  }),
  createTemplate: (template: string = ''): any => ({
    type: SqlElementType.TEMPLATE,
    template: template,
    parameters: [],
  }),
  createBinaryOperation: (): any => ({
    type: SqlElementType.BINARY_OPERATION,
    left: {},
    right: {},
    op: null,
  }),
  createCoalesce: (): any => ({
    type: SqlElementType.COALESCE,
    elements: [QueryBuilderFactory.createCondition()],
  }),
  createFillNull: (): any => ({
    type: SqlElementType.FILL_NULL,
    elements: [{}],
    default: null,
  }),
  createDatePart: (): any => ({
    type: SqlElementType.DATE_PART,
    value: {},
    part: null,
  }),
  createDateBinaryOperation: (): any => ({
    type: SqlElementType.DATE_BINARY_OPERATION,
    left: {},
    right: {},
    op: null,
    part: null,
  }),
  createWindow: (): any => ({
    type: SqlElementType.WINDOW,
    function: null,
    partition_by: {},
    order_by: [{}, true],
    parameters: [],
  }),
  createRawQuery: (): any => ({
    type: SqlElementType.RAW_QUERY,
    dependencies: [],
    value: '',
  }),
  createTransformer: (): any => ({
    type: SqlElementType.TRANSFORMER,
    transformer: '',
    value: {},
    parameters: [],
  }),
  // deprecated
  createSegmentCondition: (): any => ({
    ...QueryBuilderFactory.create(SqlElementType.CONDITION),
    extended_type: ExtendedSqlElementType.SEGMENT_CONDITION,
    op: ConditionOperator.IN,
    left: QueryBuilderFactory.createSignalColumn(),
    right: {
      ...QueryBuilderFactory.create(SqlElementType.LITERAL),
      value_type: LiteralValueType.STRING_LIST,
      value: [],
    },
  }),
};

export const concatPath = (path: string, next: string) => (path ? `${path}.${next}` : next);

export const parentPath = (path: string, levels: number = 1) => {
  const pathParts = path.split('.');
  return pathParts.slice(0, pathParts.length - levels).join('.');
};
