import * as React from 'react';
import {CSSProperties, useMemo} from 'react';
import classes from '../smart-table.module.scss';
import {SmartTableFigureColumn, SmartTableFigureDataOptions} from '../../../../types';
import {TABLE_DATA_COLUMN_BY_TYPE} from '../smart-table.config';
import {DefaultDataColumn} from './data-columns/default-data-column.component';
import {useTableColumn} from '../hooks/use-table-column.hook';
import {ColorUtil} from '../../../../document-viewer.utils';
import classNames from 'classnames';
import {colorAlphaTransformer} from '../../../../../../../utils/colors';
import {useHelperTextDataKey} from '../hooks/use-helper-text-data-key.hook';

interface OwnProps {
  column: SmartTableFigureColumn<any>;
  columnState: any;
  dataOptions: SmartTableFigureDataOptions;
  data: any;
}

type AllProps = OwnProps;

export const TableCell: React.FC<AllProps> = (props: AllProps) => {
  const {column: columnFromProps, data, dataOptions, columnState} = props;
  const {column} = useTableColumn(columnFromProps);
  const helper = useHelperTextDataKey(data, column);
  const DataColumn = useMemo(
    () => TABLE_DATA_COLUMN_BY_TYPE[column.type] || DefaultDataColumn,
    [column]
  );
  const dataColumnOptions = useMemo(
    () => dataOptions.columns?.[column.key] || {},
    [dataOptions, column.key]
  );
  const style: CSSProperties = useMemo(
    () => ({
      color: ColorUtil.getColor(dataColumnOptions.color),
      textAlign: column.options.align,
      backgroundColor: column.options.color
        ? colorAlphaTransformer(ColorUtil.getColor(column.options.color), 0.4)
        : undefined,
    }),
    [column.options, dataColumnOptions]
  );
  const suffixRight = useMemo(() => {
    if (typeof column.options.suffixRight === 'function') {
      return column.options.suffixRight(data);
    }
    return column.options.suffixRight;
  }, [column.options.suffixRight, data]);

  return (
    <td
      style={style}
      className={classNames(
        classes.TableDataCell,
        // add specific styles per type
        classes[column.type],
        classes[`to-${column.options.align}`],
        column.secondary && classes.Secondary,
        column.options.bold && classes.Bold,
        column.options.showOnHover && classes.ShowCellContentOnHover,
        column.options.borderRight && classes.BorderRight,
        column.options.noPadding && classes.NoPadding,
        column.contentClassName
      )}
    >
      <div className={classes.TableDataCellContentWrapper}>
        {helper}
        <DataColumn
          className={classes.Data}
          column={column}
          data={data}
          dataColumnOptions={dataOptions.columns?.[column.key]}
          columnState={columnState}
        />
        {suffixRight && (
          <div className={classNames(classes.SuffixRight, classes.ShowOnHover)}>{suffixRight}</div>
        )}
      </div>
    </td>
  );
};

TableCell.defaultProps = {
  dataOptions: {},
};
