import {DocumentElement, DocumentElementType} from './document.types';
import {FigureElement} from './figures.types';
import {DocumentCommand} from './commands.types';
import {DocumentIconType} from './general.types';

/**
 * Block
 */

export interface BlockOptions {
  startClosed?: boolean;
}

export interface Block extends DocumentElement<void, BlockOptions, FigureElement> {
  type: DocumentElementType.BLOCK;
  title?: string;
}

/**
 * Content Wrapper
 */

export interface ContentWrapper extends DocumentElement<void, void, FigureElement> {
  type: DocumentElementType.CONTENT_WRAPPER;
  title?: string;
  subTitle?: string;
}

/**
 * Section
 */
export enum SectionStatus {
  MEANINGFUL = 'meaningful',
  NOT_MEANINGFUL = 'not_meaningful',
  EMPTY = 'empty',
}

export interface SectionOptions {
  startClosed?: boolean;
  muted?: boolean;
  status?: SectionStatus;
  icon?: DocumentIconType;
  tableOfContentTitle?: string;
  helperText?: string;
}

export interface Section extends DocumentElement<void, SectionOptions, DocumentElement> {
  type: DocumentElementType.SECTION;
  title: string;
  slug?: string;
  commands?: DocumentCommand<any>[];
}

export interface TitleSectionOptions {
  helperText?: string;
}

export interface TitleSection extends DocumentElement<void, TitleSectionOptions, DocumentElement> {
  type: DocumentElementType.TITLE_SECTION;
  title: string;
  slug?: string;
}

// Window block is a unique component which allow the programmer to inject a custom render function
// to be rendered in the document.
export interface WindowBlock {
  type: DocumentElementType._WINDOW_BLOCK;
  render: ((props: any) => JSX.Element) | JSX.Element;
}
