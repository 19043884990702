import {useState} from 'react';
import {LocalStorageManager} from '../utils/local-storage-manager';

export function useLocalStorage<T>(key: string, defaultValue: T, expiryMilliseconds?: number) {
  const [storedValue, setStoredValue] = useState<T>(() =>
    LocalStorageManager.getItem(key, defaultValue)
  );

  const setValue = (value: T | ((existingValue: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    LocalStorageManager.setItem(key, valueToStore, expiryMilliseconds);
  };

  return [storedValue, setValue] as const;
}
