import * as React from 'react';
import classNames from 'classnames';
import classes from './explainers-table-header.module.scss';
import {Sorting} from 'front-core';
import {Tooltip} from '@material-ui/core';
import {SortIndicator} from '../../../../../../../../../../../../simple/data-display/sort-indicator/sort-indicator.component';

interface OwnProps {
  columnKey: string;
  title: string;
  subTitle?: string;
  helperText?: string;
  actions?: any;
  sorting?: Sorting;
  onSort?: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const ExplainersTableHeader: React.FC<AllProps> = (props: AllProps) => {
  const {columnKey, title, subTitle, helperText, sorting, actions, onSort, className} = props;

  return (
    <div className={classNames(classes.ExplainersTableHeader, className)}>
      <div className={classes.Title}>
        <Tooltip
          title={
            helperText ? (
              <div className={classes.HeaderHelper}>
                <div className={classes.Content}>{helperText}</div>
              </div>
            ) : (
              ''
            )
          }
          interactive
          placement={'top'}
        >
          <div
            className={classNames(
              classes.HeaderValue,
              onSort && classes.Sortable,
              sorting?.orderBy === columnKey && classes.Sorted
            )}
            onClick={onSort ? () => onSort() : undefined}
          >
            {title}
            {sorting?.orderBy === columnKey && (
              <SortIndicator className={classes.SortIndicator} order={sorting.order} />
            )}
          </div>
        </Tooltip>
        {actions && actions}
      </div>
      {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
    </div>
  );
};
