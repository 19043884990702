import {Epic, ofType} from 'redux-observable';
import {
  CoreActionsType,
  getAppStatusCounters,
  getProductData,
  getProductDataCompleted,
} from './core.actions';
import {createRequestEpic, createWaitEpic, getWindowSize, toSnakeCase, upsert} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import {AuthActionType, getUserRequest} from '../auth/auth.actions';
import {filter, tap} from 'rxjs/operators';
import {sendAmplitudeData} from '../../config/amplitude.config';
import {CORE_STORE_KEY} from './core.store';
import {generateLocationPayload} from '../../utils/amplitude.utils';
import {getter} from '../store.utils';
import HttpClient from '../../services/http-client.service';
import {
  getAppStatusCountersNetworkRequest,
  getProductDataNetworkRequest,
} from '../../http/app-status.network-requests';
import {afterAppLoadedEpicHandler} from './core.utils';
import {getUserOnboarding} from '../user-onboarding/user-onboarding.actions';
import {getHubspotVisitorTokenNetworkRequest} from '../../http/auth.network-requests';
import {setHubSpotConversation} from '../../config/hubspot.config';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {changeUserDefaultTeam} from '../../http/user-settings.network-requests';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';
import {createUserActivityNetworkRequest} from '../../http/user-activities.network-requests';
import {AnyAction} from 'redux';
import {AUTH_STORE_KEY} from '../auth/auth.store';

export const appFirstActionEpic: Epic = createWaitEpic({
  types: [CoreActionsType.APP_FIRST_ACTION],
  actionKey: ActionKey.APP_FIRST_ACTION,
  actions: payload => [getUserRequest()],
  wait: [[AuthActionType.GET_USER_SUCCESS, AuthActionType.GET_USER_ERROR]],
  finally: () => [upsert(CORE_STORE_KEY, 'appReady', true)],
});

export const initialLoadingEpic: Epic = createWaitEpic({
  types: [CoreActionsType.INITIAL_LOADING],
  actionKey: ActionKey.INITIAL_LOADING,
  actions: payload => [getAppStatusCounters(), getProductData(), getUserOnboarding()],
  wait: [[CoreActionsType.GET_PRODUCT_DATA_COMPLETED]],
  finally: () => [
    upsert(CORE_STORE_KEY, 'initialLoadingCompleted', true),
    ...afterAppLoadedEpicHandler(),
  ],
});

export const getAppStatusCountersEpic: Epic = createRequestEpic(
  {
    types: [CoreActionsType.GET_APP_STATUS_COUNTERS],
    actionKey: ActionKey.GET_APP_STATUS_COUNTERS,
    request: getAppStatusCountersNetworkRequest,
    onSuccess: counters => [upsert(CORE_STORE_KEY, 'appStatusCounters', counters)],
  },
  HttpClient
);

export const getProductDataEpic: Epic = createRequestEpic(
  {
    types: [CoreActionsType.GET_PRODUCT_DATA],
    actionKey: ActionKey.GET_PRODUCT_DATA,
    request: getProductDataNetworkRequest,
    onSuccess: data => [upsert(CORE_STORE_KEY, 'productData', data)],
    finally: () => [getProductDataCompleted()],
  },
  HttpClient
);

export const sendAmplitudeDataEpic: Epic = action$ =>
  action$.pipe(
    ofType(CoreActionsType.NOTIFY_EVENT),
    tap((action: AnyAction) => {
      const {payload: actionPayload} = action;
      const locationPayload = generateLocationPayload();
      const productData = getter(CORE_STORE_KEY, 'productData');
      const currentUserData = getter(AUTH_STORE_KEY, 'user');

      sendAmplitudeData(actionPayload.type, {
        ...actionPayload.payload,
        ...locationPayload,
        user_id: currentUserData?.id,
        // static data
        ...toSnakeCase(getWindowSize()),
        product_id: productData?.id, // deprecated - using user properties instead
        account_status: productData?.accountStatus, // deprecated - using user properties instead,
      });
    }),
    filter(() => false)
  );

export const sendAmplitudeDataOnLocationChangeEpic: Epic = action$ =>
  action$.pipe(
    ofType('@@router/LOCATION_CHANGE'),
    tap((action: AnyAction) => {
      const {location} = action.payload;
      const locationPayload = generateLocationPayload(location);
      const productData = getter(CORE_STORE_KEY, 'productData');

      sendAmplitudeData(AmplitudeEvent.PAGEVIEW, {
        ...locationPayload,
        // static data
        ...toSnakeCase(getWindowSize()),
        product_id: productData?.id, // deprecated - using user properties instead
        account_status: productData?.accountStatus, // deprecated - using user properties instead,
      });
    }),
    filter(() => false)
  );

export const createUserActivityEpic: Epic = createRequestEpic(
  {
    types: [CoreActionsType.CREATE_USER_ACTIVITY],
    actionKey: ActionKey.CREATE_USER_ACTIVITY,
    request: createUserActivityNetworkRequest,
  },
  HttpClient
);

export const getHubspotVisitorTokenEpic: Epic = createRequestEpic(
  {
    types: [CoreActionsType.HUBSPOT_VISITOR_TOKEN_REQUEST],
    actionKey: ActionKey.GET_HUBSPOT_VISITOR_TOKEN,
    request: getHubspotVisitorTokenNetworkRequest,
    onSuccess: res => {
      setHubSpotConversation(res.email, res.token);
      return [];
    },
  },
  HttpClient
);

export const changeDefaultTeamEpic: Epic = createRequestEpic(
  {
    types: [CoreActionsType.CHANGE_DEFAULT_TEAM],
    actionKey: ActionKey.CHANGE_DEFAULT_TEAM,
    request: changeUserDefaultTeam,
    onSuccess: data => [getProductData()],
    onError: err => [showToastMessage(err.data.message, ToastType.ERROR)],
  },
  HttpClient
);
