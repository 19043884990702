import {AnalysisFormProps} from '../../analysis-forms.types';
import classNames from 'classnames';
import classes from '../../analysis-forms.module.scss';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {ParametersFormContext} from '../../../../shared/core/parameters-form/parameters-form.context';
import {useTranslation} from 'react-i18next';
import {startEndDatesDefaultHandler} from '../../../../shared/core/parameters-form/parameters-form-default-handlers.utils';
import {hasError} from '../../../../../utils/general.utils';
import {values} from 'lodash';
import {SegmentFilterSelector} from '../../components/ui-selectors/segment-filter-selector/segment-filter-selector.component';
import {SimpleNumberSelector} from '../../components/ui-selectors/simple-number-selector/simple-number-selector.component';
import {TimeGranularitySelector} from '../../components/ui-selectors/time-granularity-selector/time-granularity-selector.component';
import {TimeFrameSelector} from '../../components/ui-selectors/time-frame-selector/time-frame-selector.component';
import TransKeys from 'translations';
import {ParameterInputWrapper} from '../../../../shared/form/form-layout/parameter-input-wrapper/parameter-input-wrapper.component';
import {BaseSignalBuilder} from '../../../../shared/core/query-builders/signal-builder/base-signal-builder.component';
import {SignalDataType, SignalType} from '../../../../../objects/models/signal.model';
import {ExtendedParameters} from '../../../../shared/form/form-layout/extended-parameters/extended-parameters.component';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';
import {TableEntity, TableEntityBinding} from '../../../../../objects/models/table.model';
import {EntitySelector} from '../../components/ui-selectors/entity-selector/entity-selector.component';

const entitySchemaMapping = {
  entity: 'entity',
};
const ERROR_SIGNAL_MAPPING = {
  signal_id: 'error',
};
const segmentFilterSchemaMapping = {
  population_filter: 'population_filter',
};
const TOP_ERRORS_NUMBER_SCHEMA_MAPPING = {
  number_key: 'top_errors_number',
};
const TIME_GRANULARITY_SCHEMA_MAPPING = {
  time_granularity: 'group_strategy',
};
const EVENTS_BEFORE_NUMBER_SCHEMA_MAPPING = {
  number_key: 'events_before_number',
};
const MIN_GENERAL_SHARE_EVENT_BEFORE_SCHEMA_MAPPING = {
  number_key: 'min_general_share_event_before',
};
const createErrorSignalFilters = entityContext => ({
  type: SignalType.MEASURE,
  data_type: SignalDataType.STRING,
  entity_binding: TableEntityBinding.TWO_WAY,
  entityContext,
});

export const Analysis104Form = (props: AnalysisFormProps) => {
  const {className, onSignalInfo} = props;
  const {errors, parameters, changeParametersValue, registerDefaultHandler, removeDefaultHandler} =
    useContext(ParametersFormContext);
  const [isOpenAdvancedParams, setIsOpenAdvancedParams] = useState(false);
  const {t} = useTranslation();
  const {productEntities, defaultTableEntity, getSignalByTag} = useProductData();
  const entityContext = parameters[entitySchemaMapping.entity];
  const errorSignalFilters = useMemo(
    () => createErrorSignalFilters(entityContext),
    [entityContext]
  );

  useEffect(() => {
    registerDefaultHandler('start_end_dates', startEndDatesDefaultHandler);
    registerDefaultHandler('analysis_104', parameters => {
      const errorSignal = getSignalByTag('error', entityContext);

      const defaults = {};
      defaults[entitySchemaMapping.entity] = defaultTableEntity;
      defaults[TIME_GRANULARITY_SCHEMA_MAPPING.time_granularity] = 'week';
      defaults[TOP_ERRORS_NUMBER_SCHEMA_MAPPING.number_key] = 10;
      defaults[EVENTS_BEFORE_NUMBER_SCHEMA_MAPPING.number_key] = 5;
      defaults[MIN_GENERAL_SHARE_EVENT_BEFORE_SCHEMA_MAPPING.number_key] = 0.01;

      if (errorSignal) {
        defaults[ERROR_SIGNAL_MAPPING.signal_id] = errorSignal.id;
      } else {
        defaults[ERROR_SIGNAL_MAPPING.signal_id] = undefined;
      }

      return defaults;
    });
    return () => {
      removeDefaultHandler('start_end_dates');
      removeDefaultHandler('analysis_104');
    };
  }, [
    registerDefaultHandler,
    removeDefaultHandler,
    defaultTableEntity,
    getSignalByTag,
    entityContext,
  ]);

  const onChangeEntityContext = useCallback(
    (entity: TableEntity) => {
      const resetParameters = {
        [ERROR_SIGNAL_MAPPING.signal_id]: undefined,
      };
      changeParametersValue({
        [entitySchemaMapping.entity]: entity,
        ...resetParameters,
      });
    },
    [changeParametersValue]
  );

  const isOpenAdvancedParamsOrError = useMemo(() => {
    return (
      isOpenAdvancedParams ||
      hasError(errors, [
        ...values(EVENTS_BEFORE_NUMBER_SCHEMA_MAPPING),
        ...values(MIN_GENERAL_SHARE_EVENT_BEFORE_SCHEMA_MAPPING),
        ...values(TOP_ERRORS_NUMBER_SCHEMA_MAPPING),
      ])
    );
  }, [isOpenAdvancedParams, errors]);
  return (
    <div className={classNames(classes.AnalysisForm, className)}>
      <EntitySelector
        className={classes.Parameter}
        value={parameters}
        schemaKeysMapping={entitySchemaMapping}
        onChange={v => onChangeEntityContext(v[entitySchemaMapping.entity])}
        productEntities={productEntities}
      />
      <ParameterInputWrapper
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.ERROR.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.ERROR.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.ERROR.HELPER_TEXT)}
        className={classes.Parameter}
        error={hasError(errors, values(ERROR_SIGNAL_MAPPING))}
      >
        <BaseSignalBuilder
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={ERROR_SIGNAL_MAPPING}
          errors={errors}
          onSignalInfo={onSignalInfo}
          filters={errorSignalFilters}
        />
      </ParameterInputWrapper>
      <TimeGranularitySelector
        title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.GROUP_STRATEGY.TITLE)}
        subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.GROUP_STRATEGY.SUB_TITLE)}
        helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.GROUP_STRATEGY.HELPER_TEXT)}
        className={classes.Parameter}
        value={parameters}
        onChange={changeParametersValue}
        schemaKeysMapping={TIME_GRANULARITY_SCHEMA_MAPPING}
        errors={errors}
      />
      <TimeFrameSelector
        value={parameters}
        onChange={changeParametersValue}
        className={classes.Parameter}
        errors={errors}
      />
      {entityContext && (
        <SegmentFilterSelector
          className={classes.Parameter}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={segmentFilterSchemaMapping}
          errors={errors}
        />
      )}
      <ExtendedParameters
        className={classes.SpaceBottom}
        label={t(TransKeys.GENERAL.LABELS.ADVANCED_PARAMETERS)}
        isOpen={isOpenAdvancedParamsOrError}
        onOpenChanged={() => setIsOpenAdvancedParams(!isOpenAdvancedParams)}
      >
        <SimpleNumberSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.TOP_ERRORS_NUMBER.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.TOP_ERRORS_NUMBER.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.TOP_ERRORS_NUMBER.HELPER_TEXT)}
          className={classes.Parameter}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={TOP_ERRORS_NUMBER_SCHEMA_MAPPING}
          errors={errors}
          minValue={1}
        />
        <SimpleNumberSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.EVENTS_BEFORE_NUMBER.TITLE)}
          subTitle={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.EVENTS_BEFORE_NUMBER.SUB_TITLE)}
          helperText={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.EVENTS_BEFORE_NUMBER.HELPER_TEXT)}
          className={classes.Parameter}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={EVENTS_BEFORE_NUMBER_SCHEMA_MAPPING}
          errors={errors}
          minValue={1}
        />
        <SimpleNumberSelector
          title={t(TransKeys.ANALYSIS_FORMS.ANALYSIS_104.MIN_GENERAL_SHARE_EVENT_BEFORE.TITLE)}
          subTitle={t(
            TransKeys.ANALYSIS_FORMS.ANALYSIS_104.MIN_GENERAL_SHARE_EVENT_BEFORE.SUB_TITLE
          )}
          helperText={t(
            TransKeys.ANALYSIS_FORMS.ANALYSIS_104.MIN_GENERAL_SHARE_EVENT_BEFORE.HELPER_TEXT
          )}
          className={classes.Parameter}
          value={parameters}
          onChange={changeParametersValue}
          schemaKeysMapping={MIN_GENERAL_SHARE_EVENT_BEFORE_SCHEMA_MAPPING}
          errors={errors}
          minValue={0.01}
        />
      </ExtendedParameters>
    </div>
  );
};
