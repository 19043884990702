import {useMemo} from 'react';
import {ChartType, DocumentElementType, RetentionTimeUnit} from '../../../../../types';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {generateRetentionLabels} from '../retention-overview-viewer.utils';

interface UseRetentionChartProps {
  mainDataset: number[];
  projectedDataset?: number[];
  timeUnit: RetentionTimeUnit;
  timeUnitN: number;
  entity: string;
  bucket0?: boolean;
}

export const useRetentionChart = (props: UseRetentionChartProps) => {
  const {mainDataset, projectedDataset, timeUnit, timeUnitN, entity, bucket0} = props;
  const {t} = useDocumentTranslation();

  const chart = useMemo(() => {
    const labels = generateRetentionLabels(timeUnit, timeUnitN, mainDataset.length, bucket0);
    const datasets: any[] = [
      {
        id: `main`,
        label: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CHART.ACTIVE_DS_LABEL),
        data: mainDataset.map((y, idx) => ({x: idx, y})),
      },
    ];
    if (projectedDataset) {
      datasets.push({
        id: `simulated`,
        label: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CHART.SIMULATED_DS_LABEL),
        data: projectedDataset.map((y, idx) => ({x: idx, y})),
        isPredicted: true,
      });
    }

    const chart = {
      id: 'main-chart',
      type: DocumentElementType.CHART,
      chartType: ChartType.LINE,
      labels,
      data: datasets,
      options: {
        yLabelSuffix: '%',
        xLabel: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CHART.X_LABEL),
        yLabel: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CHART.Y_LABEL),
        maxHeight: 240,
        yAxisMaxTicks: 5,
        colors: ['#45ABCC', '#8D94AE'],
        yMin: 0,
      },
    };
    return {
      id: 'retention-chart-wrapper',
      type: DocumentElementType.CONTENT_WRAPPER,
      title: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CHART.TITLE),
      children: [chart],
    };
  }, [mainDataset, projectedDataset, entity, bucket0, t]);

  return chart;
};
