export const CONTROL_COLOR = '#E7EAF3';
export const VARIANT_COLORS = [
  '#ECE0FC',
  '#E0F2FC',
  '#C8EBDC',
  '#FAEACA',
  '#FCE0F1',
  '#CBD8FF',
  '#DFFBD2',
  '#F7F5C8',
];
