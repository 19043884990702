import * as React from 'react';
import {EnhancedEllipse, EnhancedGroup, EnhancedLine} from '../../../../../core/konva/components';
import {useMemo} from 'react';
import {Html} from '../../../../../core/konva/hoc/html.hoc';
import {MinimalInfoBox} from '../../generic-components/minimal-info-box/minimal-info-box.component';
import classes from './conversion-rate.module.scss';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import classNames from 'classnames';
import {KPIElement} from '../../../../../types/growth-map.types';
import {Group} from 'konva/types/Group';
import {KPIStatusLine} from '../kpi-status-line/kpi-status-line.component';

interface OwnProps extends KPIElement {
  x: number;
  y: number;
  zIndex?: number;
  model?: string;
  onClick?: (e, target?: Group) => void;
  opacity?: number;
  isExpanded?: boolean;
  onExpand?: () => void;
  round?: number;
}

type AllProps = OwnProps;

const LINE_HEIGHT = 70;

export const ConversionRate: React.FC<AllProps> = (props: AllProps) => {
  const {
    x,
    y,
    options,
    zIndex,
    onClick,
    opacity,
    isExpanded,
    onExpand,
    data,
    model,
    value: value_,
    round,
  } = props;
  const yOffset = useMemo(
    () => (options.direction === 'to-bottom' ? LINE_HEIGHT : -LINE_HEIGHT),
    [options.direction]
  );
  const linePoints = useMemo(() => [0, 0, 0, yOffset], [yOffset]);
  const isPercent = useMemo(() => data.type === 'percentage', [data.type]);
  const trendingUp = useMemo(() => {
    const samples = data.samples || [];
    const higherIsBetter = data.higherIsBetter || true;
    if (samples.length < 2) {
      return null;
    }
    try {
      if (samples[0]?.value === samples[1]?.value) {
        return null;
      }
      const trend = samples[0]?.value / samples[1]?.value > 1;
      return higherIsBetter ? trend : !trend;
    } catch (e) {
      return null;
    }
  }, [data.samples, data.higherIsBetter]);
  const value = useMemo(() => (value_ ? Number(value_.toFixed(round)) : null), [value_, round]);
  const title = useMemo(
    () => (value ? `${value}${isPercent ? '%' : ''}` : '--'),
    [isPercent, value]
  );
  const trendIcon = useMemo(() => {
    if (trendingUp === null) {
      return undefined;
    }
    return trendingUp ? TrendingUpIcon : TrendingDownIcon;
  }, [trendingUp]);

  return (
    <EnhancedGroup opacity={opacity} onClick={onClick} x={x} y={y}>
      <EnhancedEllipse
        x={0.5}
        y={options.direction === 'to-bottom' ? 1 : -1}
        radiusX={8.2}
        radiusY={7}
        fill={'#353D5C'}
      />
      <EnhancedEllipse x={0} y={0} radiusX={8} radiusY={6} fill={'#4D5880'} />
      <EnhancedLine
        points={linePoints}
        stroke={'#FFFFFF'}
        strokeWidth={2}
        lineJoin={'round'}
        lineCap={'round'}
        roundedCorners
      />
      <EnhancedGroup centerX x={0} y={yOffset}>
        <Html divProps={{style: {zIndex, opacity}}}>
          <MinimalInfoBox
            isActive={isExpanded}
            title={title}
            icon={trendIcon}
            iconColor={trendingUp ? '#62E797' : '#FF6871'}
            className={classNames(
              classes.ConversionRateInfoBox,
              options.direction === 'to-bottom' && classes.ToBottom
            )}
            onClick={() => onExpand && onExpand()}
          >
            {data.samples?.length > 0 && (
              <div className={classes.Content}>
                <KPIStatusLine
                  samples={data.samples}
                  higherIsBetter={data.higherIsBetter}
                  className={classes.StatusLine}
                  round={options.round}
                  type={data.type}
                  model={model}
                />
              </div>
            )}
          </MinimalInfoBox>
        </Html>
      </EnhancedGroup>
    </EnhancedGroup>
  );
};

ConversionRate.defaultProps = {
  options: {},
  zIndex: 10,
  opacity: 1,
  data: {} as any,
};
