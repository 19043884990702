import * as React from 'react';
import {KPIElementData, KPIElementOptions} from '../../../../../types/growth-map.types';
import {SmallInfoBox} from '../../generic-components/small-info-box/small-info-box.component';
import {useMemo} from 'react';
import {KPIStatusLine} from '../kpi-status-line/kpi-status-line.component';
import {GrowthMapIcon} from '../../generic-components/growth-map-icon/growth-map-icon.component';
import TimelineIcon from '@material-ui/icons/Timeline';
import {number2k} from 'front-core';

interface OwnProps {
  title: string;
  data: KPIElementData;
  options: KPIElementOptions;
  model: string;
  isExpanded: boolean;
  onExpand: () => void;
  metricId?: number;
  color?: string;
  value?: number;
  infoboxClassName: string;
  onKPIClicked?: (e) => void;
}

type AllProps = OwnProps;

const MAX_WIDTH = 250;
const MIN_WIDTH = 150;

export const GroupSmallInfoBox: React.FC<AllProps> = (props: AllProps) => {
  const {
    title,
    options,
    onExpand,
    isExpanded,
    color,
    value,
    data,
    model,
    infoboxClassName,
    metricId,
    onKPIClicked,
  } = props;

  const title_ = useMemo(() => {
    if (value) {
      return `${number2k(value)} ${title}`;
    }
    return title;
  }, [title, value]);

  const renderActions = () => {
    if (isExpanded && metricId) {
      return (
        <GrowthMapIcon
          variant={'dark'}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onKPIClicked && onKPIClicked(e);
          }}
          icon={TimelineIcon}
        />
      );
    }
    return null;
  };

  const renderChildren = () => {
    const showKPIStatus = data.samples?.length > 0;

    if (!isExpanded) {
      return null;
    }
    return (
      <div>
        {showKPIStatus && (
          <KPIStatusLine
            style={{maxHeight: '40px'}}
            samples={data.samples}
            higherIsBetter={data.higherIsBetter}
            preview={!isExpanded}
            model={model}
            round={options.round}
          />
        )}
      </div>
    );
  };

  return (
    <SmallInfoBox
      className={infoboxClassName}
      style={{
        top: `${50 * (options.direction === 'to-top' ? -1 : 1)}px`,
        transform: `translateY(${options.direction === 'to-top' ? '-100%' : 0})`,
        width: isExpanded ? `${MAX_WIDTH}px` : `${MIN_WIDTH}px`,
      }}
      actions={renderActions()}
      isActive={isExpanded}
      color={color}
      title={title_}
      onClick={onExpand}
    >
      {renderChildren()}
    </SmallInfoBox>
  );
};

GroupSmallInfoBox.defaultProps = {
  data: {} as any,
  options: {},
};
