import * as React from 'react';
import {DialogLayout} from '../../simple/generic/dialog-layout/dialog-layout.component';
import classNames from 'classnames';
import classes from './dialog.module.scss';
import {DatePicker} from '../inputs/date-picker/date-picker.component';
import {useCallback, useEffect, useState} from 'react';
import {DateRange} from '../inputs.types';
import moment from 'moment';
import {convertDateToUTC} from 'front-core';

interface OwnProps {
  className?: string;
  value?: DateRange;
  onSubmit?: (value: DateRange) => void;
  showQuickOptions?: boolean;
  maxDate?: Date;
  minDate?: Date;
  utc?: boolean;
}

type AllProps = OwnProps;

const QUICK_OPTIONS = [
  {
    count: 7,
    unit: 'd',
    label: 'Last 7 days',
  },
  {
    count: 14,
    unit: 'd',
    label: 'Last 14 days',
  },
  {
    count: 30,
    unit: 'd',
    label: 'Last 30 days',
  },
  {
    count: 3,
    unit: 'month',
    label: 'Last Quarter',
  },
];

export const DateRangeDialog: React.FC<AllProps> = (props: AllProps) => {
  const {value: value_, onSubmit, utc, showQuickOptions, maxDate, minDate, className} = props;

  const [value, setValue] = useState(value_);

  useEffect(() => {
    setValue(value_);
  }, [value_]);

  const onChange = useCallback(
    dates => {
      setValue({from: dates[0], to: dates[1]});
      if (dates[0] && dates[1]) {
        onSubmit({from: dates[0], to: dates[1]});
      }
    },
    [onSubmit]
  );

  const onQuickSelect = useCallback(
    option => {
      const now = moment().startOf('d');
      const prev = now.clone().subtract(option.count, option.unit);
      const dates = [convertDateToUTC(prev.toDate()), convertDateToUTC(now.toDate())];
      onChange(dates);
    },
    [onChange]
  );

  return (
    <DialogLayout className={classNames(classes.DateFilter, className)}>
      <div className={classes.Wrapper}>
        <DatePicker
          // @ts-ignore
          selectsRange
          selected={value?.from}
          startDate={value?.from}
          endDate={value?.to}
          onChange={onChange}
          maxDate={maxDate}
          minDate={minDate}
          utc={utc}
          inline
        />
        {showQuickOptions && (
          <div className={classes.QuickSelect}>
            {QUICK_OPTIONS.map((o, idx) => (
              <div key={idx} onClick={_ => onQuickSelect(o)} className={classes.Option}>
                {o.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </DialogLayout>
  );
};

DateRangeDialog.defaultProps = {
  showQuickOptions: true,
  utc: true,
};
