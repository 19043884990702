import * as React from 'react';
import {useMemo} from 'react';
import {FillNull, SqlElement, SqlElementType} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {queryBuilderClasses} from '../query-builder.component';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {QueryBlock} from '../components/query-block/query-block.component';
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  CopyIcon,
} from '../../../simple/controls/icons/icons.component';
import {useQueryArray, useQueryElement} from '../query-builder.hooks';
import {get} from 'lodash';

interface OwnProps {
  data: FillNull;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const FillNullBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled} = props;
  const {localErrors, onDelete} = useQueryElement(props);
  const elements = useMemo(() => data.elements || [], [data]);
  const {addElement, cloneElement, moveElement, removeElement} = useQueryArray(elements, data =>
    onChange(concatPath(path, 'elements'), data)
  );

  const renderElement = (e: SqlElement, idx: number) => {
    const actions = [
      {
        icon: ArrowUpIcon,
        label: 'Up',
        onClick: () => moveElement(idx, idx - 1),
        hidden: idx === 0,
      },
      {
        icon: ArrowDownIcon,
        label: 'Down',
        onClick: () => moveElement(idx, idx + 1),
        hidden: idx === elements.length - 1,
      },
      {
        icon: CopyIcon,
        label: 'Duplicate',
        onClick: () => cloneElement(idx),
      },
    ];

    const queryElem = (
      <QueryElementBuilder
        key={idx}
        error={Boolean(get(localErrors, `elements.${idx}`))}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, `elements.${idx}`)}
        data={e as any}
      />
    );

    if (!e?.type) {
      return (
        <QueryBlock
          key={idx}
          label={`Query ${idx + 1}`}
          actions={[
            ...actions,
            {
              icon: CloseIcon,
              label: 'Remove',
              onClick: () => removeElement(idx),
            },
          ]}
          disabled={disabled}
        >
          {queryElem}
        </QueryBlock>
      );
    }

    return queryElem;
  };

  return (
    <QueryTree
      label={'Fill Null'}
      actions={[
        {
          icon: AddIcon,
          label: 'Add',
          onClick: addElement,
        },
        {
          icon: CloseIcon,
          label: 'Remove',
          onClick: onDelete,
        },
      ]}
      disabled={disabled}
      className={className}
    >
      {data.elements.map((e, idx) => renderElement(e, idx))}
      <QueryBlock box={false} label={'Default'}>
        <QueryElementBuilder
          accept={[SqlElementType.LITERAL]}
          error={Boolean(localErrors.default)}
          className={queryBuilderClasses.Inline}
          path={concatPath(path, 'default')}
          data={data.default}
        />
      </QueryBlock>
    </QueryTree>
  );
};
