import * as React from 'react';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import classes from '../chart.module.scss';
import {Html} from '../../../../core/konva/hoc/html.hoc';
import classNames from 'classnames';
import {isArray} from 'lodash';
import {exists} from 'front-core';
import {
  ArrowFromLineLightIcon,
  FlagPennantLightIcon,
} from '../../../simple/controls/icons/icons.component';
import {useMemo} from 'react';

export interface LegendLabel {
  datasetId: string | number;
  isActive?: boolean;
  icon?: any;
  name: string;
  color: string | string[];
}

interface OwnProps {
  id: string;
  x: number;
  y: number;
  title?: string;
  labels: LegendLabel[];
  variant?: 'square';
  onLabelClicked?: (label: LegendLabel, e: React.MouseEvent<HTMLElement>) => void;
  descriptionsLegends?: Array<{color: string; description: string}>;
  showAnnotations?: boolean;
  onChangeShowAnnotations?: (showAnnotations: boolean) => void;
  errorBar?: boolean;
  disabled?: boolean;
  onChangeErrorBar?: (errorBar: boolean) => void;
}

type AllProps = ShapeProps & OwnProps;

export const ChartLegend: React.FC<AllProps> = (props: AllProps) => {
  const {
    id,
    x,
    y,
    width,
    height,
    title,
    labels,
    onLabelClicked,
    descriptionsLegends,
    showAnnotations,
    onChangeShowAnnotations,
    onChangeErrorBar,
    errorBar,
    disabled,
  } = props;
  const divProps = useMemo(
    () => ({
      style: {width: `100%`},
    }),
    []
  );
  return (
    <Html groupProps={{x, y, width, height}} divProps={divProps}>
      <div className={classes.ChartLegendContainer} style={{width: `${width}px`}} id={id}>
        <div className={classes.ChartLegend}>
          {title && <span className={classes.LegendLabelTitle}>{title}</span>}
          {labels.map((l, idx) => (
            <div
              key={idx}
              className={classNames(
                classes.LegendLabel,
                l.isActive && classes.Active,
                disabled && classes.Disabled
              )}
              onClick={
                disabled ? undefined : onLabelClicked ? e => onLabelClicked(l, e) : undefined
              }
            >
              {!isArray(l.color) && (
                <span className={classes.Tag} style={{backgroundColor: `${l.color}`}} />
              )}
              {isArray(l.color) &&
                l.color.map((c, idx) => (
                  <span key={idx} className={classes.Tag} style={{backgroundColor: `${c}`}} />
                ))}
              {exists(l.icon) && <l.icon className={classes.Icon} />}
              <span className={classes.Label}>{labels[idx].name}</span>
            </div>
          ))}
          <div className={classes.Spacer} />
          {onChangeShowAnnotations && (
            <div
              className={classNames(
                classes.AnotherToggle,
                showAnnotations && classes.ActiveAnotherToggle
              )}
              onClick={() => onChangeShowAnnotations(!showAnnotations)}
            >
              <FlagPennantLightIcon className={classes.Icon} />
              <span>{showAnnotations ? 'Hide' : 'Show'} Annotations</span>
            </div>
          )}
          {onChangeErrorBar && (
            <div
              className={classNames(classes.AnotherToggle, errorBar && classes.ActiveAnotherToggle)}
              onClick={() => onChangeErrorBar(!errorBar)}
            >
              <ArrowFromLineLightIcon className={classes.Icon} />
              <span>{errorBar ? 'Hide' : 'Show'} Confidence Interval</span>
            </div>
          )}
          {descriptionsLegends && descriptionsLegends.length > 0 && (
            <div className={classes.DescriptionsLegendsContainer}>
              {descriptionsLegends.map((descLegend, index) => (
                <div
                  key={`${descLegend.description}_${index}`}
                  className={classes.DescriptionLegend}
                  style={{color: `${descLegend.color}`}}
                >
                  <div className={classes.Dashes}>
                    <div
                      className={classes.DashPart}
                      style={{backgroundColor: `${descLegend.color}`}}
                    />
                    <div
                      className={classes.DashPart}
                      style={{backgroundColor: `${descLegend.color}`}}
                    />
                    <div
                      className={classes.DashPart}
                      style={{backgroundColor: `${descLegend.color}`}}
                    />
                  </div>
                  <span>{descLegend.description}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Html>
  );
};

ChartLegend.defaultProps = {
  variant: 'square',
  title: 'Legend',
};
