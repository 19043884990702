import * as React from 'react';
import classNames from 'classnames';
import classes from './options-dropdown.module.scss';
import {ForwardedRef, forwardRef, useCallback, useMemo} from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {CheckIcon} from '../icons/icons.component';
import {DialogLayout} from '../../generic/dialog-layout/dialog-layout.component';
import {PopoverWrapper} from '../../generic/popover-wrapper/popover-wrapper.component';

export interface Option {
  selectedLabel?: string;
  label: string;
  value: any;
  disabled?: boolean;
}

interface OwnProps {
  icon?: any;
  label: string;
  options: Option[];
  onChange: (v: any) => void;
  value?: any;
  size?: 'medium' | 'large';
  popoverDirection?: 'left' | 'right';
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const OptionsDropdown: React.FC<AllProps> = forwardRef(
  (props: AllProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      icon: Icon,
      label,
      value,
      options,
      size,
      onChange: onChangeFromProps,
      placeholder,
      popoverDirection,
      disabled,
      className,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const selectedOption = useMemo(() => options.find(o => o.value === value), [options, value]);
    const handleClick = useCallback(
      event => {
        event.stopPropagation();
        event.preventDefault();
        if (disabled || options.length === 0) {
          return;
        }
        setAnchorEl(event.currentTarget);
      },
      [setAnchorEl]
    );
    const handleClose = useCallback(
      event => {
        event?.stopPropagation();
        event?.preventDefault();
        setAnchorEl(null);
      },
      [setAnchorEl]
    );
    const onChange = useCallback(
      (v, e) => {
        onChangeFromProps(v);
        handleClose(e);
      },
      [onChangeFromProps, handleClose]
    );

    return (
      <PopoverWrapper
        popoverDirection={popoverDirection}
        buttonRenderer={props => (
          <div
            ref={ref}
            onClick={props.onClick}
            className={classNames(
              classes.OptionsDropdown,
              props.isOpen && classes.Active,
              classes[size],
              className
            )}
          >
            <div className={classes.OptionsLabel}>
              {Icon && <Icon className={classes.Icon} />}
              <span className={classes.Text}>{label}</span>
            </div>
            <div className={classes.Selected}>
              {!selectedOption && <span className={classes.Placeholder}>{placeholder}</span>}
              {selectedOption && (
                <span className={classes.SelectedLabel}>
                  {selectedOption.selectedLabel || selectedOption.label}
                </span>
              )}
              <ArrowDropDownIcon className={classNames(classes.ActionIcon, classes.Arrow)} />
            </div>
          </div>
        )}
      >
        <DialogLayout className={classes.OptionsPopover}>
          {options.map(o => (
            <div
              onClick={o.disabled ? undefined : e => onChange(o.value, e)}
              key={o.value}
              className={classNames(
                classes.Option,
                o.value === value && classes.Selected,
                o.disabled && classes.Disabled
              )}
            >
              <div className={classes.CheckWrapper}>
                {o.value === value && <CheckIcon className={classes.CheckIcon} />}
              </div>
              {o.label}
            </div>
          ))}
        </DialogLayout>
      </PopoverWrapper>
    );
  }
);

OptionsDropdown.defaultProps = {
  size: 'medium',
  placeholder: 'Select',
  popoverDirection: 'right',
};
