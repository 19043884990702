import * as React from 'react';
import {
  EnhancedGroup,
  EnhancedLine,
  EnhancedRect,
  EnhancedRegularPolygon,
  EnhancedText,
} from '../../../../core/konva/components';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {useContext, useMemo, useState} from 'react';
import {ChartAnnotation as ChartAnnotationModel} from '../chart-data.types';
import {Html} from '../../../../core/konva/hoc/html.hoc';
import {AnnotationTooltip} from '../charts/line-chart/components/annotation-tooltip.component';
import {range} from 'lodash';
import {StageContext} from '../../../../core/konva/stage-context.component';

interface OwnProps {
  timestamp: number;
  x: number;
  annotations: ChartAnnotationModel[];
  inputDateFormat?: string;
  dateFormat: string;
  onAnnotationClicked?: (annotation: ChartAnnotationModel[]) => void;
  xTickSize?: number;
}

type AllProps = ShapeProps & OwnProps;

const INDICATION_HEIGHT = 13;
const COLOR_LOW = 'rgba(58, 113, 217, 0.6)';
const COLOR_HIGH = 'rgba(58, 113, 217, 1)';
const SHOW = {
  shadowColor: 'black',
  shadowBlur: 10,
  shadowOffset: {x: 0, y: 0},
  shadowOpacity: 0.2,
  shadowEnabled: true,
};

export const ChartAnnotation: React.FC<AllProps> = (props: AllProps) => {
  const {style} = useContext(StageContext);
  const {height, x, color, annotations, dateFormat, onAnnotationClicked} = props;
  const [isHover, setIsHover] = useState(false);
  const linePoint = useMemo(() => [0, 0, 0, height], [height]);
  const tooltipHTMLProps = useMemo(() => ({style: {zIndex: 1000}}), []);

  return (
    <EnhancedGroup
      x={x}
      y={props.y}
      width={props.width}
      height={props.height}
      center={props.center}
      centerX={props.centerX}
      centerY={props.centerY}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onAnnotationClicked ? () => onAnnotationClicked(annotations) : undefined}
    >
      {/*<EnhancedLine*/}
      {/*  onMouseEnter={() => setIsHover(true)}*/}
      {/*  onMouseLeave={() => setIsHover(false)}*/}
      {/*  points={linePoint}*/}
      {/*  strokeWidth={1}*/}
      {/*  stroke={isHover ? '#3483FF' : color}*/}
      {/*/>*/}
      {/*<EnhancedRegularPolygon*/}
      {/*  y={-6}*/}
      {/*  x={3}*/}
      {/*  sides={3}*/}
      {/*  radius={8}*/}
      {/*  fill={isHover ? '#3483FF' : color}*/}
      {/*  rotation={90}*/}
      {/*/>*/}
      {/*<Html groupProps={{y: height / 2}} divProps={tooltipHTMLProps}>*/}
      {/*  <AnnotationTooltip*/}
      {/*    show={isHover}*/}
      {/*    annotations={annotations}*/}
      {/*    dateFormat={dateFormat}*/}
      {/*    showFooter={Boolean(onAnnotationClicked)}*/}
      {/*  />*/}
      {/*</Html>*/}

      <EnhancedLine
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        points={linePoint}
        strokeWidth={1}
        stroke={isHover ? COLOR_HIGH : 'transparent'}
        {...SHOW}
      />
      <EnhancedRect
        y={height - INDICATION_HEIGHT / 2}
        x={-INDICATION_HEIGHT / 2}
        fill={isHover ? COLOR_HIGH : COLOR_LOW}
        width={INDICATION_HEIGHT}
        height={INDICATION_HEIGHT}
        shadowEnabled={isHover}
        {...(isHover ? SHOW : {})}
        cornerRadius={2}
      />
      <EnhancedText
        y={height - INDICATION_HEIGHT / 2}
        x={-INDICATION_HEIGHT / 2}
        fontSize={10}
        text={Math.min(annotations.length, 9).toString()}
        fill={'#ffffff'}
        width={INDICATION_HEIGHT}
        height={INDICATION_HEIGHT}
        lineHeight={1.5}
        align={'center'}
        fontFamily={style.fontName}
      />
      {annotations?.length > 0 && (
        <Html groupProps={{y: height + 20}} divProps={tooltipHTMLProps}>
          <AnnotationTooltip
            show={isHover}
            annotations={annotations}
            dateFormat={dateFormat}
            showFooter={Boolean(onAnnotationClicked)}
          />
        </Html>
      )}
    </EnhancedGroup>
  );
};

ChartAnnotation.defaultProps = {
  // color: '',
};
