import {ChartType, DocumentElementType, PowerUsersData} from '../../../../types';
import {PowerUsersChartMode} from './components/power-users-chart/power-users-chart.component';

const LINE_COLOR = '#45ABCC';
const POWER_INDICATION_COLOR = '#D2ADF9';

export interface PowerUsersChartParams {
  label: string;
  items: PowerUsersData[];
  isPercentage: boolean;
  signalName: string;
  powerN?: number;
  powerLineTitle?: string;
  powerLineDescription?: string;
}

export const generateLineChart = (params: PowerUsersChartParams) => {
  const {label, items, isPercentage, signalName, powerN, powerLineTitle, powerLineDescription} =
    params;

  const data = items.map(item => {
    return {
      x: item.value,
      y: isPercentage ? item.shareOfEntity * 100 : item.entityCount,
    };
  });

  const dataset = {
    id: `dataset`,
    label,
    data,
    yAxis: 'primary',
  };

  return {
    type: DocumentElementType.CHART,
    chartType: ChartType.LINE,
    data: [dataset],
    labels: items.map(item => `${item.value}`),
    options: {
      showTooltipPreviousValueDelta: true,
      xLabel: signalName,
      yLabelSuffix: isPercentage ? '%' : '',
      maxHeight: 320,
      colors: [LINE_COLOR],
      yMin: 0,
      yMax: isPercentage ? 100 : undefined,
      lines: [
        {
          position: powerN,
          title: powerLineTitle,
          description: powerLineDescription,
          color: POWER_INDICATION_COLOR,
          direction: 'vertical',
        },
      ],
    },
  };
};

export const generateBarChart = (params: PowerUsersChartParams) => {
  const {label, items, isPercentage, signalName} = params;

  const data = items.map(item => ({
    value: isPercentage ? item.shareOfEntity * 100 : item.entityCount,
  }));

  const labels = items.map(item => item.value);

  const dataset = {
    id: `dataset`,
    label,
    data,
    yAxis: 'primary',
  };

  return {
    type: DocumentElementType.CHART,
    chartType: ChartType.BAR,
    data: [dataset],
    labels,
    options: {
      xLabel: signalName,
      yLabelSuffix: isPercentage ? '%' : '',
      maxHeight: 320,
      yMin: 0,
    },
  };
};
