import * as React from 'react';
import classes from './merge-kpis.module.scss';
import classNames from 'classnames';
import {KPIElement} from '../../../../../types/growth-map.types';
import {ForwardedRef, forwardRef, useCallback, useMemo, useState} from 'react';
import {Dot} from '../../generic-components/dot/dot.component';
import {KPIInfo} from '../kpi-info/kpi-info.component';

interface OwnProps {
  title?: string;
  members?: KPIElement[];
  className?: string;
  color?: string;
  width?: number;
  ref?: any;
  onExpand?: () => void;
  isExpanded?: boolean;
  membersExpanded?: boolean;
  count?: number;
  id?: string;
  model?: string;
  dotClassName?: string;
  onKPIClicked?: (kpi: KPIElement) => void;
}

type AllProps = OwnProps;

export const MergeKPIs: React.FC<AllProps> = forwardRef(
  (props: AllProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      title,
      members,
      className,
      color,
      width,
      isExpanded,
      membersExpanded,
      onExpand,
      count,
      id,
      dotClassName,
      onKPIClicked,
      model,
    } = props;
    const visibleMembers = useMemo(
      () => (isExpanded ? members : members.slice(0, count)),
      [isExpanded, members, count]
    );
    const leftCount = useMemo(() => members.length - count, [members, count]);
    const [openStates, setOpenStates] = useState(members.map(l => false));

    const onExpandMember = useCallback(
      (idx: number) => {
        const newState = [...openStates];
        newState[idx] = !newState[idx];
        setOpenStates(newState);
      },
      [openStates, setOpenStates]
    );

    return (
      <div
        id={id}
        ref={ref}
        style={{width: `${width}px`}}
        className={classNames(classes.MergeKPIs, className)}
      >
        {title && <div className={classes.Title}>{title}</div>}
        <div className={classes.KPIElementList}>
          {visibleMembers.map((m, idx) => (
            <div key={idx} className={classes.KPIElement}>
              <div style={{backgroundColor: color}} className={classes.Rect} />
              <KPIInfo
                withArrow={false}
                className={classes.Label}
                kpi={m}
                isExpanded={openStates[idx] || membersExpanded}
                onExpand={() => onExpandMember(idx)}
                onKPIClicked={m.metricId ? () => onKPIClicked(m) : undefined}
                model={model}
              />
              <Dot
                className={classNames(classes.MergeDot, dotClassName)}
                radius={2.5}
                color={color}
                stroke={null}
              />
            </div>
          ))}
        </div>
        {leftCount > 0 && (
          <div className={classes.SeeMore}>
            <span onClick={_ => onExpand()}>{isExpanded ? `Less` : `${leftCount} More`}</span>
          </div>
        )}
      </div>
    );
  }
);

MergeKPIs.defaultProps = {
  count: 3,
};
