import React, {useContext, useMemo, useRef} from 'react';
import {KonvaNodeComponent} from 'react-konva';
import Konva from 'konva';
import {KonvaNodeEvents} from 'react-konva/lib/ReactKonvaCore';
import {StageContext} from '../stage-context.component';
import {removeUndefinedKeys} from 'front-core';

interface EnhancedShapeProps {
  // Change the cursor to pointer when onClick is presented
  cursorPointer?: boolean;
  // center around both axis
  center?: boolean;
  // center around x (horizontal)
  centerX?: boolean;
  // center around y (vertical)
  centerY?: boolean;
  // tooltip
  tooltipEnabled?: boolean;
  tooltipScale?: number;
  tooltipData?: any;
  onTooltipChange?: (state: boolean) => void;
  duration?: number;
  debug?: boolean; // internal use
}

export type ShapeProps = Konva.NodeConfig & KonvaNodeEvents & EnhancedShapeProps;

export function enhancedShape<Node extends Konva.Node, P = ShapeProps>(
  WrappedComponent: KonvaNodeComponent<Node, P>
): React.ComponentType<P> {
  const WithEnhancedShape: React.FC<P & ShapeProps> = (props: P & ShapeProps) => {
    const {controller} = useContext(StageContext);
    const ref = useRef(null);
    /**
     * Computed
     */
    const offset = useMemo(
      () =>
        removeUndefinedKeys({
          offsetX:
            props.center || props.centerX ? Math.floor(props.width / 2 - 1) || 0 : props.offsetX,
          offsetY: props.center || props.centerY ? props.height / 2 || 0 : props.offsetY,
        }),
      [
        props.center,
        props.centerX,
        props.centerY,
        props.width,
        props.height,
        props.offsetX,
        props.offsetY,
      ]
    );
    /**
     * Handlers
     */
    const onRef = ref_ => {
      ref.current = ref_;
      props.ref && props.ref(ref_);
    };
    /**
     * Render
     */
    const extendedProps: any = useMemo(() => {
      let extendedProps: any = {};

      // https://konvajs.org/docs/styling/Mouse_Cursor.html
      extendedProps.onMouseEnter = e => {
        if (props.onClick && props.cursorPointer) {
          const container = controller.stage.container();
          container.style.cursor = 'pointer';
        }
        if (props.tooltipEnabled) {
          const pos = e.target.getStage().getPointerPosition();
          controller.setTooltip({
            ...pos,
            data: props.tooltipData,
          });
          props.tooltipScale &&
            e.target.to({
              scaleX: props.tooltipScale,
              scaleY: props.tooltipScale,
              duration: 0.25,
            });
          props.onTooltipChange && props.onTooltipChange(true);
        }
        props.onMouseEnter && props.onMouseEnter(e);
      };
      extendedProps.onMouseLeave = e => {
        if (props.onClick && props.cursorPointer) {
          const container = controller.stage.container();
          container.style.cursor = 'default';
        }
        if (props.tooltipEnabled) {
          controller.setTooltip(null);
          props.tooltipScale && e.target.to({scaleX: 1, scaleY: 1, duration: 0.25});
          props.onTooltipChange && props.onTooltipChange(false);
        }
        props.onMouseLeave && props.onMouseLeave(e);
      };
      extendedProps.onClick = props.onClick
        ? e => {
            controller.setTooltip(null);
            props.tooltipScale && e.target.to({scaleX: 1, scaleY: 1, duration: 0.25});
            props.onTooltipChange && props.onTooltipChange(false);
            props.onClick && props.onClick(e);
          }
        : undefined;
      return extendedProps;
    }, [
      props.cursorPointer,
      props.tooltipData,
      props.tooltipScale,
      props.tooltipEnabled,
      props.onTooltipChange,
      props.onMouseLeave,
      props.onMouseEnter,
      props.onClick,
    ]);

    return <WrappedComponent {...props} {...extendedProps} {...offset} ref={onRef} />;
  };

  // @ts-ignore
  WithEnhancedShape.defaultProps = {
    cursorPointer: true,
    tooltipEnabled: false,
    tooltipScale: false,
    tooltipData: null,
    duration: 0.25,
  };

  return WithEnhancedShape;
}
