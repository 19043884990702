import classNames from 'classnames';
import classes from './notice-message.module.scss';
import {ReactNode} from 'react';

interface NoticeMessageProps {
  icon?: any;
  message?: ReactNode;
  confirmLabel?: ReactNode;
  onConfirm?: () => void;
  secondaryLabel?: ReactNode;
  onSecondary?: () => void;
  variant: 'warn' | 'error';
  fullWidth?: boolean;
  className?: string;
}

export const NoticeMessage = (props: NoticeMessageProps) => {
  const {
    icon: Icon,
    message,
    variant,
    confirmLabel,
    onConfirm,
    secondaryLabel,
    onSecondary,
    fullWidth,
    className,
  } = props;

  return (
    <div
      className={classNames(
        classes.Notice,
        fullWidth && classes.FullWidth,
        variant === 'warn' && classes.Warning,
        variant === 'error' && classes.Error,
        className
      )}
    >
      <div className={classes.Head}>
        {Icon && <Icon className={classes.Icon} />}
        <div className={classes.Message}>{message}</div>
      </div>
      <div className={classes.Spacer} />
      <div className={classes.Footer}>
        {secondaryLabel && (
          <div onClick={onSecondary} className={classes.SecondaryButton}>
            {secondaryLabel}
          </div>
        )}
        <div onClick={onConfirm} className={classes.ConfirmButton}>
          {confirmLabel}
        </div>
      </div>
    </div>
  );
};
