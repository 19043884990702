import React, {useCallback, useMemo} from 'react';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import classes from './simulator-slider.module.scss';
import {exists, number2k} from 'front-core';
import classNames from 'classnames';
import {get} from 'lodash';

const BASE_BUCKETS = [5, 10, 50, 100, 500, 1000];

function transformIn(x, buckets) {
  const stepSize = 1 / buckets.length;
  for (const [idx, to] of buckets.entries()) {
    if (x > to) {
      continue;
    }
    const offset = idx * stepSize;
    const from = get(buckets, idx - 1, 0);
    const range = to - from;
    return offset + ((x - from) / range) * stepSize;
  }
}

function transformOut(x, buckets) {
  if (x > 0.9999999) {
    return buckets[buckets.length - 1];
  }
  const stepSize = 1 / buckets.length;
  let bucketIdx = Math.floor(x / stepSize);
  const from = get(buckets, bucketIdx - 1, 0);
  const to = buckets[bucketIdx];
  const range = to - from;
  return from + ((x - transformIn(from, buckets)) * range) / stepSize;
}

const SimulatorSliderTooltip: any = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'transparent !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    maxWidth: 'unset !important',
    border: '0 !important',
    padding: '0 !important',
    borderRadius: 'unset !important',
    fontSize: 'unset !important',
    boxShadow: 'unset !important',
    margin: '4px',
  },
}))(Tooltip);

const DesignedSlider = withStyles({
  root: {
    marginTop: '1.6rem',
    color: '#3483FF',
    '&:hover, &$active': {
      '& $markLabel': {
        opacity: `1 !important`,
      },
    },
    // fix show marks on hover
    '&::after': {
      content: "''",
      width: '100%',
      height: '1.6rem',
      position: 'absolute',
      bottom: '-1.6rem',
    },
  },
  thumb: {
    height: 14,
    width: 14,
    minHeight: 14,
    minWidth: 14,
    backgroundColor: '#3483FF',
    border: '0',
    marginTop: `-5px !important`,
    marginLeft: `-7px !important`,
    zIndex: 2,
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '&$focusVisible': {
      boxShadow: 'none',
    },
  },
  focusVisible: {
    boxShadow: 'none',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#D7DAE9',
  },
  marked: {
    marginBottom: '16px',
  },
  mark: {
    display: 'none',
  },
  markActive: {
    display: 'none',
  },
  markLabel: {
    fontSize: '12px',
    opacity: 0,
    transition: 'all 0.25s',
    color: 'rgba(197, 201, 219, 1)',
  },
  markLabelActive: {
    color: 'rgba(117, 124, 148, 1)',
  },
})(Slider);

const DEFAULT_MARKS = buckets =>
  ['%', ...buckets].map(b => ({
    value: typeof b === 'number' ? transformIn(b, buckets) : 0,
    label: typeof b === 'number' ? number2k(b) : b,
  }));

const popperProps = {disablePortal: true};

function ValueLabelComponent(props) {
  const {children, open, value} = props;
  return (
    <SimulatorSliderTooltip
      open={open}
      placement="top"
      title={<div className={classes.Tooltip}>{value}</div>}
      PopperProps={popperProps}
      interactive={false}
    >
      {children}
    </SimulatorSliderTooltip>
  );
}

// Playground
// https://codesandbox.io/s/material-demo-forked-8st9c5?file=/demo.js

interface OwnProps {
  min: number;
  max: number;
  value: number;
  onChange?: (value: number | null) => void;
  onChangeCommitted?: (value: number | null) => void;
  allowedMax?: number;
  allowedMaxHelper?: string;
  disabled?: boolean;
  suffix?: string;
  sign?: string;
  defaultValue?: number;
  displayValue?: number;
  className?: string;
}

export const SimulatorSlider: React.FC<OwnProps> = (props: OwnProps) => {
  const {
    min: minFromProps,
    max: maxFromProps,
    allowedMax: allowedMaxFromProps,
    value: valueFromProps,
    defaultValue: defaultValueFromProps,
    onChange: onChangeFromProps,
    onChangeCommitted: onChangeCommittedFromProps,
    allowedMaxHelper,
    displayValue,
    disabled,
    sign,
    suffix,
    className,
  } = props;
  const buckets = useMemo(() => {
    let x = [...BASE_BUCKETS];
    const lastBucket = BASE_BUCKETS[BASE_BUCKETS.length - 1];
    if (allowedMaxFromProps && allowedMaxFromProps > lastBucket) {
      x[BASE_BUCKETS.length - 1] = allowedMaxFromProps;
    }
    return x;
  }, [allowedMaxFromProps, maxFromProps]);
  const value = useMemo(() => transformIn(valueFromProps, buckets), [valueFromProps, buckets]);
  const min = useMemo(() => transformIn(minFromProps, buckets), [minFromProps, buckets]);
  const max = useMemo(() => transformIn(maxFromProps, buckets), [maxFromProps, buckets]);
  const defaultValue = useMemo(
    () => transformIn(defaultValueFromProps, buckets),
    [defaultValueFromProps, buckets]
  );
  const allowedMax = useMemo(() => {
    if (!exists(allowedMaxFromProps)) {
      return undefined;
    }
    return transformIn(allowedMaxFromProps, buckets);
  }, [allowedMaxFromProps, buckets]);
  const onChange = useCallback(
    v => {
      if (v === null) {
        onChangeFromProps && onChangeFromProps(null);
        return;
      }
      onChangeFromProps &&
        onChangeFromProps(transformOut(Math.min(v, exists(allowedMax) ? allowedMax : v), buckets));
    },
    [onChangeFromProps, allowedMax, buckets]
  );
  const onChangeCommitted = useCallback(
    v =>
      onChangeCommittedFromProps &&
      onChangeCommittedFromProps(v !== null ? transformOut(v, buckets) : v),
    [onChangeCommittedFromProps, buckets]
  );
  const marks = useMemo(() => {
    const iter = DEFAULT_MARKS(buckets);
    if (iter[iter.length - 1].value === allowedMax) {
      iter[iter.length - 1].label = 'MAX';
    }
    return iter.filter(i => i.value <= allowedMax || i.value <= max);
  }, [min, max, allowedMax, buckets]);
  const allowedMaxStyle = useMemo(() => {
    if (!exists(allowedMax)) {
      return undefined;
    }
    let localMax = allowedMax > max ? allowedMax : max;
    return {
      left: `${(100 * (allowedMax - min)) / (localMax - min)}%`,
    };
  }, [allowedMax, max, min]);

  return (
    <div className={classNames(classes.SimulatorSlider, disabled && classes.Disabled, className)}>
      {min !== max && (
        <div className={classes.RangePicker}>
          <DesignedSlider
            value={value}
            onChange={(e, v) => onChange(v)}
            onChangeCommitted={(e, value) => onChangeCommitted(value)}
            ValueLabelComponent={ValueLabelComponent}
            step={0.00001}
            valueLabelDisplay="on"
            valueLabelFormat={v => `${sign || ''}${number2k(v)}${suffix || ''}`}
            marks={marks}
            scale={x => transformOut(x, buckets)}
            min={min}
            max={max > allowedMax ? max : allowedMax}
            defaultValue={defaultValue}
            disabled={disabled}
          />
          {exists(allowedMax) && allowedMaxStyle && (
            <Tooltip title={allowedMaxHelper || ''} placement={'top'}>
              <div className={classes.AllowedMax} style={allowedMaxStyle}></div>
            </Tooltip>
          )}
        </div>
      )}
      {exists(displayValue) && (
        <div className={classes.CurrentValue}>
          {number2k(displayValue)}
          {suffix}
        </div>
      )}
    </div>
  );
};

SimulatorSlider.defaultProps = {
  min: 0,
  max: 1000,
};
