import classNames from 'classnames';
import classes from './pre-post-period-selector.module.scss';
import {BuilderWrapper} from '../../../../../../shared/components/general/builder-wrapper/builder-wrapper.component.tsx';
import {AnalysisSelectorProps} from '../../analysis-selector.types.ts';
import {DateIcon, DatePickerInput, EditIcon, Select} from 'ui-components';
import {useCallback, useMemo, useState} from 'react';
import {exists} from 'front-core';
import {TIME_FORMATS} from '../../../../../../../constants/time-formats.ts';
import moment from 'moment';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';

interface SchemaKeysMapping {
  pre_release_date: string;
  post_release_date: string;
  days_before: string;
  max_days_from_release: string;
}

const DEFAULT_SCHEMA_KEYS_MAPPING: SchemaKeysMapping = {
  pre_release_date: 'pre_release_date',
  post_release_date: 'post_release_date',
  days_before: 'days_before',
  max_days_from_release: 'max_days_from_release',
};

interface Props extends AnalysisSelectorProps {
  className?: string;
}

enum SelectorType {
  PRE = 'pre',
  POST = 'post',
}

const MAPPED_PARAMETERS = {
  [SelectorType.PRE]: {
    days: 'days_before',
    date: 'pre_release_date',
  },
  [SelectorType.POST]: {
    days: 'max_days_from_release',
    date: 'post_release_date',
  },
};
const maxDate = new Date();
const CUSTOM_OPTION = 'custom';
const OPTIONS = [
  {
    value: 7,
    label: '7 days',
  },
  {
    value: 14,
    label: '14 days',
  },
  {
    value: 28,
    label: '28 days',
  },
  {
    value: CUSTOM_OPTION,
    label: 'Custom',
  },
];

export const PrePostPeriodSelector = (props: Props) => {
  const {
    value,
    onChange,
    schemaKeysMapping = DEFAULT_SCHEMA_KEYS_MAPPING,
    errors,
    className,
  } = props;
  const {t} = useTranslation();
  const [customFields, setCustomFields] = useState({
    [SelectorType.PRE]: exists(value[schemaKeysMapping.pre_release_date]),
    [SelectorType.POST]: exists(value[schemaKeysMapping.post_release_date]),
  });

  const releaseDate = useMemo(
    () => value[schemaKeysMapping.release_date],
    [value, schemaKeysMapping]
  );
  const onSelectorChange = useCallback(
    (type: SelectorType, value: any) => {
      const isCustom = value === CUSTOM_OPTION;
      if (!isCustom) {
        onChange({
          [schemaKeysMapping[MAPPED_PARAMETERS[type].days]]: value,
        });
      }
      setCustomFields(cf => ({
        ...cf,
        [type]: isCustom,
      }));
    },
    [onChange, setCustomFields, schemaKeysMapping]
  );
  const daysToDate = useMemo(() => {
    if (!releaseDate) {
      return;
    }
    const res = {};
    if (value[MAPPED_PARAMETERS[SelectorType.PRE].days]) {
      res[SelectorType.PRE] = moment
        .utc(releaseDate, TIME_FORMATS.PARAMETER_DATE_FORMAT)
        .subtract(value[MAPPED_PARAMETERS[SelectorType.PRE].days], 'days')
        .format(TIME_FORMATS.READABLE_DATE);
    }
    if (value[MAPPED_PARAMETERS[SelectorType.POST].days]) {
      res[SelectorType.POST] = moment
        .utc(releaseDate, TIME_FORMATS.PARAMETER_DATE_FORMAT)
        .add(value[MAPPED_PARAMETERS[SelectorType.POST].days], 'days')
        .format(TIME_FORMATS.READABLE_DATE);
    }
    return res;
  }, [releaseDate, value]);

  const renderSelector = (type: SelectorType) => {
    const parametersMap = MAPPED_PARAMETERS[type];
    const selectorValue = customFields[type] ? CUSTOM_OPTION : value[parametersMap.days];
    return (
      <Select
        options={{options: OPTIONS}}
        value={selectorValue}
        onChange={v => onSelectorChange(type, v)}
        searchable={false}
        clearable={false}
        sortValues={false}
      />
    );
  };
  const renderDateSelector = (type: SelectorType) => {
    let content: any;
    if (customFields[type]) {
      const parameterKey = MAPPED_PARAMETERS[type].date;
      let typeMaxDate = maxDate;
      if (type === SelectorType.PRE) {
        typeMaxDate = moment.utc(releaseDate, TIME_FORMATS.PARAMETER_DATE_FORMAT).toDate();
      }
      return (
        <div className={classNames(classes.DateSelector, !exists(releaseDate) && classes.Disabled)}>
          <DatePickerInput
            className={classes.DatePickerInput}
            placeholder={'Select Date'}
            value={value[parameterKey]}
            onChange={value => onChange({[parameterKey]: value})}
            dateFormat={'DD/MM/YYYY'}
            dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
            maxDate={typeMaxDate}
            error={Boolean(errors[parameterKey])}
            icon={DateIcon}
            utc
          />
        </div>
      );
    } else {
      content = daysToDate ? <span>{daysToDate[type]}</span> : null;
    }
    if (!exists(releaseDate)) {
      content = (
        <span>
          {t(TransKeys.ANALYSIS_FORMS.ANALYSIS_109.PRE_POST_PERIOD_SELECTOR.MISSING_RELEASE_DATE)}
        </span>
      );
    }

    return (
      <div className={classNames(classes.DateSelector, !exists(releaseDate) && classes.Disabled)}>
        {content}
        <EditIcon
          className={classes.CustomButton}
          onClick={exists(releaseDate) ? () => onSelectorChange(type, CUSTOM_OPTION) : undefined}
        />
      </div>
    );
  };

  return (
    <BuilderWrapper className={classNames(classes.PrePostPeriodSelector, className)}>
      <div className={classes.Section}>
        <div className={classes.Title}>
          {t(
            TransKeys.ANALYSIS_FORMS.ANALYSIS_109.PRE_POST_PERIOD_SELECTOR.PRE_RELEASE_PERIOD.TITLE
          )}
        </div>
        <div className={classes.Subtitle}>
          {t(
            TransKeys.ANALYSIS_FORMS.ANALYSIS_109.PRE_POST_PERIOD_SELECTOR.PRE_RELEASE_PERIOD
              .SUB_TITLE
          )}
        </div>
        <div className={classes.Selector}>
          {renderSelector(SelectorType.PRE)}
          {renderDateSelector(SelectorType.PRE)}
        </div>
      </div>
      <div className={classes.Section}>
        <div className={classes.Title}>
          {t(
            TransKeys.ANALYSIS_FORMS.ANALYSIS_109.PRE_POST_PERIOD_SELECTOR.POST_RELEASE_PERIOD.TITLE
          )}
        </div>
        <div className={classes.Subtitle}>
          {t(
            TransKeys.ANALYSIS_FORMS.ANALYSIS_109.PRE_POST_PERIOD_SELECTOR.POST_RELEASE_PERIOD
              .SUB_TITLE
          )}
        </div>
        <div className={classes.Selector}>
          {renderSelector(SelectorType.POST)}
          {renderDateSelector(SelectorType.POST)}
        </div>
      </div>
    </BuilderWrapper>
  );
};
