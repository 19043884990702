import yup from '../../../../../config/yup.config';
import {PARAMETERS_METADATA_KEY} from '../../../../../constants/parameters-saved-keys';
import {values} from 'lodash';
import {TableEntity} from '../../../../../objects/models/table.model';
import {
  querySchemaValidator,
  validateSignalIds,
} from '../../../../../objects/dto/query-builder.dto';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {
  startEndDatesValidator,
  treatmentValidator,
  validateSignalTimeframeBoundingFor,
} from '../shared-validators';
import {ParameterType} from 'ui-components';
import {
  AnalysisParametersTransformer,
  baseAnalysisParametersTransformer,
} from '../shared-transformers';

export const analysis122ParametersValidator = yup.object().shape({
  parameters: yup
    .object()
    .shape({
      [PARAMETERS_METADATA_KEY]: yup.object().nullable(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      goal: yup.number().required().test(validateSignalIds()),
      population_filter: querySchemaValidator(false),
      ...treatmentValidator('treatment_signal_slug'),
    })
    .required(),
  runParameters: startEndDatesValidator
    .required()
    .test({
      name: 'min_3_weeks',
      test: function (dates, context) {
        const startDate = moment(dates.start_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        const endDate = moment(dates.end_date, TIME_FORMATS.PARAMETER_DATE_FORMAT);
        if (Math.floor(endDate.diff(startDate, 'week')) < 6) {
          return this.createError({
            message: 'Minimum time frame must be at least 6 weeks',
            path: `${context.path}.start_date`,
          });
        }
        return true;
      },
    })
    .test(
      validateSignalTimeframeBoundingFor({
        signalIdParameter: 'goal',
        parameterName: 'analysis goal',
      })
    ),
});

export const analysis122ParametersTransformer: AnalysisParametersTransformer = (
  values,
  schema,
  productEntities
) => {
  [values, schema] = baseAnalysisParametersTransformer(values, schema, productEntities);
  const newValues = {...values};

  if ('goal' in schema) {
    schema['goal'].name = 'Goal';
    schema['goal'].order = 2;
  }

  delete schema['treatment_signal_slug'];
  delete schema['treatments_signals'];
  schema['treatment_signal_slug'] = {
    name: 'Scope',
    type: ParameterType.__MERGED_TREATMENTS,
    key: 'treatment_signal_slug',
    order: 4,
  };
  newValues['treatment_signal_slug'] = [
    ...(newValues['treatment_signal_slug'] || []),
    ...(newValues['treatments_signals'] || []),
  ];

  return [newValues, schema];
};
