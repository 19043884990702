import {Milestone} from '../models/milestone.model';
import yup from '../../config/yup.config';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import httpClientService from '../../services/http-client.service';
import {
  validateMilestoneDefinitionNetworkRequest,
  validateNameNetworkRequest,
} from '../../http/validations.network-requests';

import {querySchemaValidator, apiQuerySchemaValidator} from './query-builder.dto.ts';

export type MilestoneDTO = Partial<Milestone>;

export const milestoneDTOValidator = yup.object().shape({
  id: yup.number().optional().nullable(),
  name: yup
    .string()
    .required()
    .test('unique_name', 'Name already exists', async function (name: string) {
      const {id} = this.parent;
      try {
        await httpClientService.exec(
          validateNameNetworkRequest({
            name,
            existingId: id,
            modelType: 'milestone',
          })
        );
        return true;
      } catch (e) {
        return false;
      }
    }),
  type: yup.string().required(),
  entity: yup.string().oneOf(values(TableEntity)).required(),
  shortDescription: yup.string().nullable(),
  signalDefinition: yup.object().when('isComputed', {
    is: false,
    then: apiQuerySchemaValidator({
      networkRequest: validateMilestoneDefinitionNetworkRequest,
      extractData: data => ({
        definition: data.signalDefinition,
        entity: data.entity,
        milestoneType: data.type,
      }),
    }),
    otherwise: querySchemaValidator(true),
  }),
  isComputed: yup.boolean().nullable(),
});
