import {calculateUplift, exists, safeDivision} from 'front-core';
import {SegmentOfReleaseSegmentation} from '../../../../types';
import {ExtendedSegmentOfReleaseSegmentation} from './release-impact-segmentation-viewer.component';

export const extendSegments = (
  segments: SegmentOfReleaseSegmentation[],
  postTotalEntities: number,
  preTotalEntities: number
): ExtendedSegmentOfReleaseSegmentation[] => {
  return segments.map(s => {
    const postShare = safeDivision(s.postCount, postTotalEntities);
    const preShare = safeDivision(s.preCount, preTotalEntities);
    const shareUplift = calculateUplift(postShare, preShare);
    const valueUplift = calculateUplift(s.postValue, s.preValue);

    return {
      ...s,
      postShare,
      preShare,
      shareUplift,
      valueUplift,
      isInformative: exists(s.isInformative) ? s.isInformative : true,
      groupInsignificant: s.isSignificant === false || s.isInformative === false,
    };
  });
};
