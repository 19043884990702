import {isArray} from 'lodash';
import {SentencePartParameters} from './sentence.component';
import {SentencePartData} from './sentence.types';

export const DEFAULT_DELIMITER = '|';
const SENTENCE_REGEX = /\$\{([A-Z_:]+)}\(/gm;

function* iterateText(text: string) {
  const textParts = text.split(' ');
  let temp = '';

  for (const t of textParts) {
    if (t.includes('*') && temp === '') {
      temp = `${t} `;
      continue;
    }
    if (t.includes('*') && temp !== '') {
      temp += t;
      yield temp;
      temp = '';
      continue;
    }
    if (t.includes('*') === false && temp !== '') {
      temp += `${t} `;
      continue;
    }
    yield t;
  }

  if (temp !== '') {
    yield temp;
  }
}

const splitText = (text: string): any[] =>
  Array.from(iterateText(text)).map(i => ({
    name: 'TEXT',
    parameters: [i.trim()],
  }));

const DEFAULT_SENTENCE_PART_VALUE_INDEX = 0;
const SENTENCE_PART_VALUE_INDEX_MAPPING = {
  REF: 1,
};

const transformParameters = (sp: SentencePartParameters): SentencePartParameters => {
  if (sp.name === 'REF') {
    const parameters = [...sp.parameters];
    parameters[0] = parameters[0] ? (parameters[0] as string).split(',') : parameters[0];
    if (isArray(parameters[0]) && parameters[0].length === 1) {
      parameters[0] = parameters[0][0];
    }

    return {
      ...sp,
      parameters,
    };
  }
  return sp;
};

const TEMP_PLACEHOLDER_VALUE = '__##__';

export const parseSentence = (text: string, delimiter: string): SentencePartData[] => {
  let match;
  let lastIndex = 0;
  const parts = [];

  while ((match = SENTENCE_REGEX.exec(text)) !== null) {
    // total = the total match "${signature}("
    const [total, signature] = match;
    const [name, type] = signature.split(':');
    const startParametersIndex = match.index + total.length;
    // openParenthesisCount starts at 1 because it exists in regex
    let openParenthesisCount = 1;
    let currentIndex = startParametersIndex;
    // walking through the text, searching for a matching ")"
    while (openParenthesisCount > 0 && currentIndex < text.length) {
      if (text[currentIndex] === ')') {
        openParenthesisCount--;
      }
      if (text[currentIndex] === '(') {
        openParenthesisCount++;
      }
      currentIndex++;
    }
    // We reached the end of text and did not find any ending ")"
    // break - so this match will be displayed as text
    if (currentIndex === text.length && openParenthesisCount > 0) {
      break;
    }
    // currentIndex should hold the index of ending ")"
    let parametersString = text.substring(startParametersIndex, currentIndex - 1);
    parametersString = parametersString.replace(`/${delimiter}`, TEMP_PLACEHOLDER_VALUE);
    const parameters = parametersString
      .split(delimiter)
      .map(p => p.replace(TEMP_PLACEHOLDER_VALUE, '|'));
    // Adding previous text
    const rest = text.substring(lastIndex, match.index).trim();
    rest && parts.push(...splitText(rest));
    // Adding matched name
    parts.push(transformParameters({name, type, parameters}));
    // Updating lastIndex
    lastIndex = currentIndex;
  }
  // Adding trailing text
  const lastText = text.substring(lastIndex, text.length).trim();
  lastText !== '' && parts.push(...splitText(lastText));
  return parts;
};

export const sentencePartsToString = (parts: SentencePartData[]): string => {
  return parts
    .map(p => {
      const value =
        p.parameters[
          SENTENCE_PART_VALUE_INDEX_MAPPING[p.name] || DEFAULT_SENTENCE_PART_VALUE_INDEX
        ];
      if (p.name === 'REF') {
        return `'${value}'`;
      }
      return value;
    })
    .join(' ');
};
