import {useContext} from 'react';
import TransKeys from 'translations';
import {
  AlertIcon,
  ChartUserIcon,
  FlaskIcon,
  UserMagnifyingGlassLightIcon,
  UsersIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {RCAElementType, RCAMode} from '../../../../types';
import {renderDates} from './components/rca-header/rca-header.utils';
import {RCAFigureViewerContext} from './rca-figure-viewer.context';

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_FIGURE;

export const useRCAElementTitles = (): Partial<
  Record<RCAElementType, {label: string; subtitle?: string; key: RCAElementType; icon: any}>
> => {
  const {t} = useDocumentTranslation();
  const {
    mode,
    rcaPeriods: {comparedDates},
  } = useContext(RCAFigureViewerContext);
  const subtitle =
    mode === RCAMode.LOOPS_ALGO
      ? `(${t(TransKeysPrefix.SECTIONS.SEGMENTATION.LOOPS_MODE.SUBTITLE)})`
      : `(${t(TransKeysPrefix.SECTIONS.SEGMENTATION.COMPARED_MODE.SUBTITLE)} ${renderDates({
          startDate: comparedDates.startDate,
          endDate: comparedDates.endDate,
        })})`;

  return {
    [RCAElementType.SEGMENTATION]: {
      label: t(TransKeysPrefix.SECTIONS.SEGMENTATION.TITLE),
      subtitle,
      key: RCAElementType.SEGMENTATION,
      icon: UsersIcon,
    },
    [RCAElementType.POPULATION_EXPLAINER]: {
      label: t(TransKeysPrefix.SECTIONS.POPULATION.TITLE),
      subtitle,
      key: RCAElementType.POPULATION_EXPLAINER,
      icon: ChartUserIcon,
    },
    [RCAElementType.EXPERIMENTS_EXPLAINER]: {
      label: t(TransKeysPrefix.SECTIONS.EXPERIMENTS.TITLE),
      subtitle,
      key: RCAElementType.EXPERIMENTS_EXPLAINER,
      icon: FlaskIcon,
    },
    // todo: remove this is deprecated
    [RCAElementType.POPULATION_SIZE]: {
      label: t(TransKeysPrefix.SECTIONS.POPULATION.TITLE),
      subtitle,
      key: RCAElementType.POPULATION_SIZE,
      icon: ChartUserIcon,
    },
    // todo: remove this is deprecated
    [RCAElementType.EXPERIMENTS]: {
      label: t(TransKeysPrefix.SECTIONS.EXPERIMENTS.TITLE),
      subtitle,
      key: RCAElementType.EXPERIMENTS,
      icon: FlaskIcon,
    },
    [RCAElementType.ERRORS]: {
      label: t(TransKeysPrefix.SECTIONS.ERRORS.TITLE),
      subtitle,
      key: RCAElementType.ERRORS,
      icon: AlertIcon,
    },
    [RCAElementType._CHANGING_SEGMENTS]: {
      label: t(TransKeysPrefix.SECTIONS.CHANGING_SEGMENTS.TITLE),
      subtitle,
      key: RCAElementType._CHANGING_SEGMENTS,
      icon: UserMagnifyingGlassLightIcon,
    },
  };
};
