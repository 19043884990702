import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {TextInput, Textarea} from 'ui-components';
import {Footer} from '../../footer/footer.component';
import classes from './snowflake-form.module.scss';
import {isEmpty, omit} from 'lodash';
import {SourceWizardFormLayout} from '../../source-wizard-form-layout/source-wizard-form-layout.component';
import {StandardCheckBox} from '../../../../../../shared/components/general/standard-check-box/standard-check-box.component';
import {SourceWizardLabelWrapper} from '../../source-wizard-label-wrapper/source-wizard-label-wrapper.component';
import {
  SnowflakeFormSchema,
  snowflakeSchema,
  SnowflakeSourceAuthTypes,
} from '../../../../../../../objects/dto/source.dto';
import {useCallback} from 'react';

export type SnowflakeFormProps = {
  onSubmit?: (values: Omit<SnowflakeFormSchema, 'authType'>) => void;
};

export const SnowflakeForm = (props: SnowflakeFormProps) => {
  const {onSubmit: onSubmitFromProps} = props;
  const formMethods = useForm({
    resolver: yupResolver(snowflakeSchema),
    defaultValues: {
      authType: SnowflakeSourceAuthTypes.PASSWORD,
      passphrase: null,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: {errors, isValid},
    control,
  } = formMethods;

  const onSubmit = useCallback(
    (values: SnowflakeFormSchema) => {
      if (values.authType === SnowflakeSourceAuthTypes.PASSWORD) {
        onSubmitFromProps?.(omit(values, 'authType', 'key', 'passphrase'));
        return;
      }
      if (values.authType === SnowflakeSourceAuthTypes.KEY) {
        onSubmitFromProps?.(omit(values, 'authType', 'password'));
        return;
      }
    },
    [onSubmitFromProps]
  );

  const authType = watch('authType');

  return (
    <SourceWizardFormLayout
      footer={
        <Footer
          buttonText="Continue"
          onClickSubmit={handleSubmit(onSubmit)}
          disabledCTAButton={!isEmpty(errors) || !isValid}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.SnowflakeForm}>
          <SourceWizardLabelWrapper label="Account Identifier">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'xy12345.us-east-2.aws'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'account'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="Username">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'user'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <Controller
            render={({field}) => (
              <SourceWizardLabelWrapper label="Authentication Type">
                <div className={classes.RadioGroup}>
                  <div className={classes.RadioButton} onClick={() => field.onChange('password')}>
                    <StandardCheckBox radio size="small" checked={field.value === 'password'} />
                    <span>Password</span>
                  </div>
                  <div className={classes.RadioButton} onClick={() => field.onChange('key')}>
                    <StandardCheckBox radio size="small" checked={field.value === 'key'} />
                    <span>RSA Key Pair</span>
                  </div>
                </div>
              </SourceWizardLabelWrapper>
            )}
            name={'authType'}
            control={control}
          />
          {authType === SnowflakeSourceAuthTypes.PASSWORD && (
            <>
              <SourceWizardLabelWrapper label="Password">
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <TextInput
                      size="large"
                      placeholder={'Type here'}
                      value={field.value}
                      onChange={field.onChange}
                      type="password"
                      error={Boolean(error)}
                    />
                  )}
                  name={'password'}
                  control={control}
                />
              </SourceWizardLabelWrapper>
            </>
          )}
          {authType === SnowflakeSourceAuthTypes.KEY && (
            <>
              <SourceWizardLabelWrapper label="Private Key">
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <Textarea
                      fullWidth
                      rows={5}
                      required
                      placeholder={'Enter Private key'}
                      value={field.value}
                      onChange={field.onChange}
                      error={true}
                    />
                  )}
                  name={'key'}
                  control={control}
                />
              </SourceWizardLabelWrapper>
              <SourceWizardLabelWrapper label="Passphrase (optional)">
                <Controller
                  render={({field, fieldState: {error}}) => (
                    <TextInput
                      size="large"
                      placeholder={'Type here'}
                      value={field.value}
                      onChange={field.onChange}
                      type="password"
                      error={Boolean(error)}
                    />
                  )}
                  name={'passphrase'}
                  control={control}
                />
              </SourceWizardLabelWrapper>
            </>
          )}
          <SourceWizardLabelWrapper label="Database Name">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'database'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="Warehouse Name">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                  required
                />
              )}
              name={'warehouse'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="User Role (optional)">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                />
              )}
              name={'role'}
              control={control}
            />
          </SourceWizardLabelWrapper>
          <SourceWizardLabelWrapper label="Catalog (optional)">
            <Controller
              render={({field, fieldState: {error}}) => (
                <TextInput
                  size="large"
                  placeholder={'Type here'}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(error)}
                />
              )}
              name={'catalog'}
              control={control}
            />
          </SourceWizardLabelWrapper>
        </form>
      </FormProvider>
    </SourceWizardFormLayout>
  );
};
