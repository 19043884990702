import * as React from 'react';
import {CSSProperties, useCallback, useEffect, useMemo} from 'react';
import {keyBy} from 'lodash';
import {DocumentElementType, SelectBlock} from '../../../types';
import {ChildRenderer} from '../../core/child-renderer.component';
import classNames from 'classnames';
import classes from './select-block-viewer.module.scss';
import {useOptionsPlacer} from '../options-placer-viewer/options-placer-viewer.component';
import {useDocumentTracking} from '../../../hooks/use-document-tracking.hook';
import {AppTabs} from '../../../../../simple/navigation/app-tabs/app-tabs.component';
import {useDocQuery} from '../../../hooks/use-doc-query.hook';
import {DocumentTitleWrapper} from '../title-section-viewer/title-section-viewer.component';

export interface OwnProps extends SelectBlock {
  className?: string;
  style?: CSSProperties;
}

const calcInitialSelectedViewKey = (data, options) =>
  data.find(d => d.key === options.default)?.key || data[0]?.key;

export const SelectBlockViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {id, title, data = [], options, optionsPlacerOptions, darkMode, className, style} = props;
  const {trackItemTabChanged} = useDocumentTracking(id, DocumentElementType.SELECT_BLOCK);
  const defaultViewKey = useMemo(() => calcInitialSelectedViewKey(data, options), [data, options]);
  const {query: selectedKey, setQuery: setSelectedKey} = useDocQuery<string>(id, defaultViewKey);

  useEffect(() => {
    trackItemTabChanged(selectedKey);
  }, [trackItemTabChanged, selectedKey]);

  // Map for select key to its SelectBlockData
  const tabs = useMemo(
    () =>
      data.map(d => ({
        label: d.label,
        key: d.key,
        render: () => <ChildRenderer children_={d.data} />,
      })),
    [data]
  );
  const tabsMap = useMemo(() => keyBy(tabs, 'key'), [tabs]);
  const visibleTabsCount = useMemo(
    () => (tabs.length === 1 ? 1 : options.visibleTabsCount || 0),
    [tabs, options.visibleTabsCount]
  );
  const renderSelectOptions = useCallback(() => {
    if (tabs.length === 1 && !Boolean(options.forceShowTabs)) {
      return null;
    }
    return (
      <div className={classes.SelectOptions}>
        <AppTabs
          prefix={options.selectLabel}
          onChange={setSelectedKey}
          selected={selectedKey}
          tabs={tabs.map(s => ({
            key: s.key,
            label: s.label,
          }))}
          visibleTabsCount={visibleTabsCount}
          fullWidth
        />
      </div>
    );
  }, [options, visibleTabsCount, setSelectedKey, selectedKey, tabs]);
  const optionPlacer = useMemo(
    () => [
      {
        elementId: props.id,
        renderer: renderSelectOptions,
        align: optionsPlacerOptions?.align,
      },
    ],
    [props.id, renderSelectOptions, optionsPlacerOptions]
  );
  const {isActive: isActiveOptionsPlacer} = useOptionsPlacer(optionPlacer);
  // When props are changed we need to re-evaluate the selected key
  const selectedItem = tabsMap[selectedKey];
  useEffect(() => {
    if (selectedKey === null || selectedItem === undefined) {
      setSelectedKey(calcInitialSelectedViewKey(data, options));
    }
  }, [data, options, setSelectedKey, selectedKey, selectedItem]);

  return (
    <DocumentTitleWrapper
      title={title}
      className={classNames(classes.SelectBlockViewer, className)}
    >
      <div
        style={style}
        className={classNames(classes.TabsSelector, darkMode && classes.DarkMode, className)}
      >
        {tabs.length > 0 && !isActiveOptionsPlacer && (
          <div className={classes.SelectOptionsWrapper}>{renderSelectOptions()}</div>
        )}
        <div className={classes.TabContent}>{selectedItem && selectedItem.render()}</div>
      </div>
    </DocumentTitleWrapper>
  );
};

SelectBlockViewer.defaultProps = {
  options: {},
  data: [],
};
