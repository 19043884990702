import {orderBy} from 'lodash';
import {FunnelOverviewFigureStep, RCAFunnelFigureDataItem} from '../../../../types';

export const sortItemsBySteps = (
  items: RCAFunnelFigureDataItem[],
  steps: FunnelOverviewFigureStep[]
) => {
  const sortedItems = [];

  const sortedSteps = orderBy(steps, 'order');

  sortedSteps.forEach(step => {
    const item = items.find(item => item.fromStepSignalId === step.signalId);
    if (item) {
      sortedItems.push(item);
    }
  });

  const funnelCompletionItem = items.find(
    item =>
      item.fromStepSignalId === sortedSteps[0].signalId &&
      item.toStepSignalId === sortedSteps.at(-1).signalId
  );

  sortedItems.push(funnelCompletionItem);

  return sortedItems;
};
