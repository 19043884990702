import classes from './chart.module.scss';
import {DEFAULT_FONT_NAME} from '../../../consts/ui';
import moment from 'moment';

export const chartClasses = classes;
export const NUMBER_JUMPS = [
  0.01, 0.05, 0.1, 0.2, 0.5, 1, 5, 10, 50, 100, 200, 300, 400, 500, 1000, 1500, 2000, 2500, 5000,
  10000, 15000, 20000, 50000, 100000, 150000, 200000, 500000, 1000000, 1500000, 2000000, 5000000,
  10000000, 50000000,
];
export const DATE_JUMPS = [
  moment.duration(1, 'minute').asMilliseconds(),
  moment.duration(5, 'minute').asMilliseconds(),
  moment.duration(10, 'minute').asMilliseconds(),
  moment.duration(15, 'minute').asMilliseconds(),
  moment.duration(20, 'minute').asMilliseconds(),
  moment.duration(30, 'minute').asMilliseconds(),
  moment.duration(1, 'hour').asMilliseconds(),
  moment.duration(3, 'hour').asMilliseconds(),
  moment.duration(6, 'hour').asMilliseconds(),
  moment.duration(1, 'day').asMilliseconds(),
  moment.duration(2, 'day').asMilliseconds(),
  moment.duration(3, 'day').asMilliseconds(),
  moment.duration(4, 'day').asMilliseconds(),
  moment.duration(7, 'day').asMilliseconds(),
  moment.duration(1, 'month').asMilliseconds(),
  moment.duration(3, 'month').asMilliseconds(),
  moment.duration(4, 'month').asMilliseconds(),
  moment.duration(6, 'month').asMilliseconds(),
  moment.duration(1, 'year').asMilliseconds(),
  moment.duration(2, 'year').asMilliseconds(),
  moment.duration(5, 'year').asMilliseconds(),
];
export const DATASET_COLORS = ['#B8ADF9', '#45ACCC', '#9AB9F3', '#F9ADAD', '#5AD9AC', '#8C94B0'];
export const BASE_STYLE = {
  layoutPadding: [0, 0, 0, 0],
  colors: DATASET_COLORS,
  highlightColor: '#fde695',
  fontName: DEFAULT_FONT_NAME,
};
export const COLOR_NAMES = {
  red: '#F9ADAD',
  green: '#5AD9AC',
  gray: '#bfbfbf',
  highlight: '#fde695',
};
