import * as React from 'react';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {useContext, useMemo, useState} from 'react';
import {flatten} from 'lodash';
import {calculateBbox} from '../../../../../core/konva/stage.utils';
import {
  BaseChartOptions,
  BaseChartProps,
  ChartWithLabelsOptions,
  ChartWithLabelsXYOptions,
  ChartWithMinMaxXYOptions,
  ScatterDataset,
} from '../../chart-data.types';
import {GridLayout} from '../../layouts/grid-layout.component';
import {useRange} from '../../chart.hooks';
import {ChartTooltip} from '../../components/chart-tooltip.component';
import {ScatterChartDatasetContainer} from './scatter-chart-dataset-container.component';
import {SplitQuarters, SplitQuartersDefinition} from '../../components/split-quarters.component';
import {ChartContext, ChartContextProvider} from '../../chart.context';
import {ResizeRender} from '../../../../hoc/resize-render/resize-render.component';
import classes from '../../chart.module.scss';
import {Html} from '../../../../../core/konva/hoc/html.hoc';
import {MoreIcon} from '../../../../simple/controls/icons/icons.component';
import {ActionsDropdown} from '../../../../simple/controls/actions-dropdown/actions-dropdown.component';
import {exists} from 'front-core';

export interface ScatterChartOptions
  extends BaseChartOptions,
    ChartWithLabelsOptions,
    ChartWithLabelsXYOptions,
    ChartWithMinMaxXYOptions {
  rLabel?: string;
  split?: SplitQuartersDefinition;
  showItemsLabels?: boolean;
}

interface OwnProps extends BaseChartProps<ScatterDataset, ScatterChartOptions> {}

type AllProps = OwnProps;

const ScatterChartController: React.FC<AllProps> = (props: AllProps) => {
  const {controller} = useContext(StageContext);
  const {
    onLegendClick,
    displayedDatasets: datasets,
    datasets: allDatasets,
    datasetLabels,
    darkMode,
  } = useContext(ChartContext);
  const {options, onDatasetClick} = props;
  const {
    xLabel,
    yLabel,
    rLabel,
    legendsLabel,
    showLegend,
    split,
    xMin,
    xMax,
    yMin,
    yMax,
    xLabelSuffix: xLabelSuffix_,
    yLabelSuffix: yLabelSuffix_,
  } = options;
  /**
   * Computed properties
   */
  const [showLabels, setShowLabels] = useState<boolean>(
    exists(options.showItemsLabels) ? options.showItemsLabels : false
  );
  const flattedData: any[] = useMemo(() => flatten(datasets.map(d => d.data)), [datasets]);
  const xValues = useMemo(() => flattedData.map(fd => fd.x), [flattedData]);
  const xRange = useRange(xValues, xMin as number, xMax as number);
  const xLabelSuffix = useMemo(() => (xLabelSuffix_ ? xLabelSuffix_ : ''), [xLabelSuffix_]);
  const xAxisProps = useMemo(
    () => ({label: xLabel, suffix: xLabelSuffix, range: xRange}),
    [xLabel, xLabelSuffix, xRange]
  );
  // Primary Y-Axis logic
  const yValues = useMemo(() => flattedData.map(fd => fd.y), [flattedData]);
  const yRange = useRange(yValues, yMin, yMax);
  const yLabelSuffix = useMemo(() => (yLabelSuffix_ ? yLabelSuffix_ : ''), [yLabelSuffix_]);
  const primaryYAxisProp = useMemo(
    () => ({label: yLabel, suffix: yLabelSuffix, range: yRange}),
    [yLabel, yLabelSuffix, yRange]
  );
  const layoutBbox = useMemo(
    () => calculateBbox(controller?.getSize().width, controller?.getSize().height),
    [controller]
  );
  const actions = useMemo(
    () => [
      {
        title: `${showLabels ? 'Hide' : 'Show'} Labels`,
        onClick: () => setShowLabels(sl => !sl),
      },
    ],
    [showLabels]
  );
  /**
   * Render
   */
  return (
    <GridLayout
      {...layoutBbox}
      legendLabels={datasetLabels}
      legendsTitle={legendsLabel || undefined}
      xAxis={xAxisProps}
      yAxis={primaryYAxisProp}
      showLegend={showLegend}
      onLabelClicked={(label, e) => onLegendClick(label.datasetId, e)}
    >
      {props => (
        <>
          <Html groupProps={{x: 0, y: 0}} divProps={{style: {width: '100%'}}}>
            <div className={classes.ScatterActions}>
              <ActionsDropdown
                iconDropdown
                icon={MoreIcon}
                actions={actions}
                label={'Additional Actions'}
              />
            </div>
          </Html>
          {split && <SplitQuarters {...props} split={split} />}
          <ScatterChartDatasetContainer
            {...props}
            datasets={datasets}
            onDatasetClick={onDatasetClick}
            xLabel={xLabel}
            yLabel={yLabel}
            rLabel={rLabel}
            yLabelSuffix={yLabelSuffix}
            xLabelSuffix={xLabelSuffix}
            showItemsLabels={showLabels}
          />
        </>
      )}
    </GridLayout>
  );
};

export const ScatterChart: React.FC<AllProps> = (props: AllProps) => {
  return (
    <div className={classes.ChartContainer}>
      <ResizeRender>
        <ChartContextProvider {...props} TooltipComponent={ChartTooltip}>
          <ScatterChartController {...props} />
        </ChartContextProvider>
      </ResizeRender>
    </div>
  );
};

ScatterChart.defaultProps = {
  options: {
    highlightIds: [],
  },
};
