import {exists} from 'front-core';
import {values} from 'lodash';
import {
  GraphIcon,
  UserMagnifyingGlassLightIcon,
} from '../../../../../../../simple/controls/icons/icons.component';
import {RCAElements, RCAElementType} from '../../../../../types';
import {useRCAElementTitles} from '../use-rca-element-titles.hook';

export const OVERVIEW_ID = 'overview';
type UseRCANavbarElementsParams = {rcaElements: RCAElements};

export const useNavbarElements = (params: UseRCANavbarElementsParams) => {
  const {rcaElements} = params;

  const elementItems = useRCAElementTitles();
  const items = values(elementItems)
    .filter(e => exists(rcaElements[e.key]))
    .map(e => ({
      label: e.label,
      icon: e.icon,
      value: e.key,
    }));

  return [
    {
      label: 'Overview',
      icon: GraphIcon,
      value: OVERVIEW_ID,
    },
    ...items,
    {
      label: elementItems[RCAElementType._CHANGING_SEGMENTS].label,
      icon: elementItems[RCAElementType._CHANGING_SEGMENTS].icon,
      value: elementItems[RCAElementType._CHANGING_SEGMENTS].key,
    },
  ];
};
