import classNames from 'classnames';
import classes from './sampled-model-settings-list.module.scss';
import {
  HomepageSampledModelType,
  HomepageSubscriptionPushType,
  HomepageSubscriptionSampledModelSettings,
  HomepageSubscriptionUnit,
} from '../../../../../../objects/models/homepage.model';
import {Checkbox, TooltipIfOverflow} from 'ui-components';
import {useCallback, useMemo} from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {Tooltip} from '@material-ui/core';
import {StandardCheckBox} from '../../../../../shared/components/general/standard-check-box/standard-check-box.component';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../../constants/feature-flags.ts';

export interface SampledModelsSettings {
  [HomepageSampledModelType.METRIC]: HomepageSubscriptionSampledModelSettings;
  [HomepageSampledModelType.FUNNEL]: HomepageSubscriptionSampledModelSettings;
}

export interface SampledModelsData {
  [HomepageSampledModelType.METRIC]: Array<{id: number; name: string}>;
  [HomepageSampledModelType.FUNNEL]: Array<{id: number; name: string}>;
}

interface OwnProps {
  sampledModelsData: SampledModelsData;
  // sampledModelType: HomepageSampledModelType;
  settings: SampledModelsSettings;
  onChange: (
    modelId: number,
    sampledModelType: HomepageSampledModelType,
    pushType: HomepageSubscriptionPushType
  ) => void;
  onChangeAll: (pushType: HomepageSubscriptionPushType) => void;
  disabled?: boolean;
  className?: string;
  mode: HomepageSubscriptionUnit;
}

type AllProps = OwnProps;

export const SampledModelsSettingsList = (props: AllProps) => {
  const {sampledModelsData, settings, onChange, onChangeAll, disabled, className, mode} = props;
  const {t} = useTranslation();
  const showFunnelSubscriptionConfig = useFeatureIsOn(
    FeatureFlag.FUNNEL_HOMEPAGE_SUBSCRIPTION as string
  );

  const allSelected = useMemo(() => {
    const isAllSelected = pushType =>
      sampledModelsData[HomepageSampledModelType.METRIC].every(
        m => settings[HomepageSampledModelType.METRIC][m.id] === pushType
      ) &&
      sampledModelsData[HomepageSampledModelType.FUNNEL].every(
        f => settings[HomepageSampledModelType.FUNNEL][f.id] === pushType
      );

    return {
      [HomepageSubscriptionPushType.SIGNIFICANT_CHANGE]: isAllSelected(
        HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
      ),
      [HomepageSubscriptionPushType.ALWAYS]: isAllSelected(HomepageSubscriptionPushType.ALWAYS),
      [HomepageSubscriptionPushType.NEVER]: isAllSelected(HomepageSubscriptionPushType.NEVER),
    };
  }, [settings, sampledModelsData]);

  const onDailyAllChange = useCallback(() => {
    const nextHomepageSubscriptionPushType = allSelected[
      HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
    ]
      ? HomepageSubscriptionPushType.NEVER
      : HomepageSubscriptionPushType.SIGNIFICANT_CHANGE;
    onChangeAll(nextHomepageSubscriptionPushType);
  }, [allSelected, onChangeAll]);

  const onDailyChange = useCallback(
    (sampledModelId: number, homepageSampledModelType: HomepageSampledModelType) => {
      const nextHomepageSubscriptionPushType =
        settings[homepageSampledModelType][sampledModelId] ===
        HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
          ? HomepageSubscriptionPushType.NEVER
          : HomepageSubscriptionPushType.SIGNIFICANT_CHANGE;

      onChange(sampledModelId, homepageSampledModelType, nextHomepageSubscriptionPushType);
    },
    [settings, onChange]
  );

  if (mode === HomepageSubscriptionUnit.DAILY) {
    return (
      <div
        className={classNames(classes.MetricSettingsList, disabled && classes.Disabled, className)}
      >
        <div className={classNames(classes.Headers, classes.DailyAlerts)}>
          <Tooltip
            title={t(
              TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.DAILY.HEADERS.ALWAYS
                .HELPER_TEXT
            )}
            placement={'top'}
            interactive={false}
          >
            <div className={classNames(classes.Header, classes.MainHeader)}>
              {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.KPI.TITLE)}
            </div>
          </Tooltip>
          <Tooltip
            title={t(
              TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.DAILY.HEADERS.ALWAYS
                .HELPER_TEXT
            )}
            placement={'top'}
            interactive={false}
          >
            <div className={classNames(classes.Header, classes.SelectorHeader)}>
              <StandardCheckBox
                checked={allSelected[HomepageSubscriptionPushType.SIGNIFICANT_CHANGE]}
                onChange={onDailyAllChange}
                disabled={disabled}
                size={'small'}
              />
              <div className={classes.DailyAlerts}>
                <span>yes</span>
                <span>/</span>
                <span>no</span>
              </div>
            </div>
          </Tooltip>
        </div>
        <div className={classes.Body}>
          {sampledModelsData[HomepageSampledModelType.METRIC].map(m => (
            <div
              className={classNames(
                classes.Row,
                classes.DailyAlerts,
                settings[HomepageSampledModelType.METRIC][m.id] ===
                  HomepageSubscriptionPushType.NEVER && classes.Never
              )}
              key={`${HomepageSampledModelType.METRIC}_${m.id}`}
            >
              <div className={classNames(classes.Cell, classes.MainCell)}>
                <TooltipIfOverflow title={m.name}>
                  <span className={classes.Name}>{m.name}</span>
                </TooltipIfOverflow>
              </div>
              <div className={classNames(classes.Cell, classes.SelectorCell)}>
                <StandardCheckBox
                  checked={
                    settings[HomepageSampledModelType.METRIC][m.id] ===
                    HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
                  }
                  onChange={() => onDailyChange(m.id, HomepageSampledModelType.METRIC)}
                  disabled={disabled}
                  size={'small'}
                />
              </div>
            </div>
          ))}
          {showFunnelSubscriptionConfig &&
            sampledModelsData[HomepageSampledModelType.FUNNEL].map(f => (
              <div
                className={classNames(
                  classes.Row,
                  classes.DailyAlerts,
                  settings[HomepageSampledModelType.FUNNEL][f.id] ===
                    HomepageSubscriptionPushType.NEVER && classes.Never
                )}
                key={`${HomepageSampledModelType.FUNNEL}_${f.id}`}
              >
                <div className={classNames(classes.Cell, classes.MainCell)}>
                  <TooltipIfOverflow title={f.name}>
                    <span className={classes.Name}>{f.name}</span>
                  </TooltipIfOverflow>
                </div>
                <div className={classNames(classes.Cell, classes.SelectorCell)}>
                  <StandardCheckBox
                    checked={
                      settings[HomepageSampledModelType.FUNNEL][f.id] ===
                      HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
                    }
                    onChange={() => onDailyChange(f.id, HomepageSampledModelType.FUNNEL)}
                    disabled={disabled}
                    size={'small'}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(classes.MetricSettingsList, disabled && classes.Disabled, className)}
    >
      <div className={classes.Headers}>
        <Tooltip
          title={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.KPI.HELPER_TEXT
          )}
          placement={'top'}
          interactive={false}
        >
          <div className={classNames(classes.Header, classes.MainHeader)}>
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.KPI.TITLE)}
          </div>
        </Tooltip>
        <Tooltip
          title={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.ALWAYS.HELPER_TEXT
          )}
          placement={'top'}
          interactive={false}
        >
          <div className={classNames(classes.Header, classes.SelectorHeader)}>
            <Checkbox
              checked={allSelected[HomepageSubscriptionPushType.ALWAYS]}
              onChange={() => onChangeAll(HomepageSubscriptionPushType.ALWAYS)}
              disabled={disabled}
            />
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.ALWAYS.TITLE)}
          </div>
        </Tooltip>
        <Tooltip
          title={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.SIGNIFICANT_CHANGE
              .HELPER_TEXT
          )}
          placement={'top'}
          interactive={false}
        >
          <div className={classNames(classes.Header, classes.SelectorHeader)}>
            <Checkbox
              checked={allSelected[HomepageSubscriptionPushType.SIGNIFICANT_CHANGE]}
              onChange={() => onChangeAll(HomepageSubscriptionPushType.SIGNIFICANT_CHANGE)}
              disabled={disabled}
            />
            {t(
              TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.SIGNIFICANT_CHANGE
                .TITLE
            )}
          </div>
        </Tooltip>
        <Tooltip
          title={t(
            TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.NEVER.HELPER_TEXT
          )}
          placement={'top'}
          interactive={false}
        >
          <div className={classNames(classes.Header, classes.SelectorHeader)}>
            <Checkbox
              checked={allSelected[HomepageSubscriptionPushType.NEVER]}
              onChange={() => onChangeAll(HomepageSubscriptionPushType.NEVER)}
              disabled={disabled}
            />
            {t(TransKeys.HOMEPAGE_SUBSCRIPTION_PANEL.METRIC_SETTINGS_LIST.HEADERS.NEVER.TITLE)}
          </div>
        </Tooltip>
      </div>
      <div className={classes.Body}>
        {sampledModelsData[HomepageSampledModelType.METRIC].map(m => (
          <div
            className={classNames(
              classes.Row,
              settings[HomepageSampledModelType.METRIC][m.id] ===
                HomepageSubscriptionPushType.NEVER && classes.Never
            )}
            key={`${[HomepageSampledModelType.METRIC]}_${m.id}`}
          >
            <div className={classNames(classes.Cell, classes.MainCell)}>
              <TooltipIfOverflow title={m.name}>
                <span className={classes.Name}>{m.name}</span>
              </TooltipIfOverflow>
            </div>
            <div className={classNames(classes.Cell, classes.SelectorCell)}>
              <Checkbox
                checked={
                  settings[HomepageSampledModelType.METRIC][m.id] ===
                  HomepageSubscriptionPushType.ALWAYS
                }
                onChange={() =>
                  onChange(
                    m.id,
                    HomepageSampledModelType.METRIC,
                    HomepageSubscriptionPushType.ALWAYS
                  )
                }
                disabled={disabled}
              />
            </div>
            <div className={classNames(classes.Cell, classes.SelectorCell)}>
              <Checkbox
                checked={
                  settings[HomepageSampledModelType.METRIC][m.id] ===
                  HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
                }
                onChange={() =>
                  onChange(
                    m.id,
                    HomepageSampledModelType.METRIC,
                    HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
                  )
                }
                disabled={disabled}
              />
            </div>
            <div className={classNames(classes.Cell, classes.SelectorCell)}>
              <Checkbox
                checked={
                  settings[HomepageSampledModelType.METRIC][m.id] ===
                  HomepageSubscriptionPushType.NEVER
                }
                onChange={() =>
                  onChange(
                    m.id,
                    HomepageSampledModelType.METRIC,
                    HomepageSubscriptionPushType.NEVER
                  )
                }
                disabled={disabled}
              />
            </div>
          </div>
        ))}
        {showFunnelSubscriptionConfig &&
          sampledModelsData[HomepageSampledModelType.FUNNEL].map(f => (
            <div
              className={classNames(
                classes.Row,
                settings[HomepageSampledModelType.FUNNEL][f.id] ===
                  HomepageSubscriptionPushType.NEVER && classes.Never
              )}
              key={`${[HomepageSampledModelType.FUNNEL]}_${f.id}`}
            >
              <div className={classNames(classes.Cell, classes.MainCell)}>
                <TooltipIfOverflow title={f.name}>
                  <span className={classes.Name}>{f.name}</span>
                </TooltipIfOverflow>
              </div>
              <div className={classNames(classes.Cell, classes.SelectorCell)}>
                <Checkbox
                  checked={
                    settings[HomepageSampledModelType.FUNNEL][f.id] ===
                    HomepageSubscriptionPushType.ALWAYS
                  }
                  onChange={() =>
                    onChange(
                      f.id,
                      HomepageSampledModelType.FUNNEL,
                      HomepageSubscriptionPushType.ALWAYS
                    )
                  }
                  disabled={disabled}
                />
              </div>
              <div className={classNames(classes.Cell, classes.SelectorCell)}>
                <Checkbox
                  checked={
                    settings[HomepageSampledModelType.FUNNEL][f.id] ===
                    HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
                  }
                  onChange={() =>
                    onChange(
                      f.id,
                      HomepageSampledModelType.FUNNEL,
                      HomepageSubscriptionPushType.SIGNIFICANT_CHANGE
                    )
                  }
                  disabled={disabled}
                />
              </div>
              <div className={classNames(classes.Cell, classes.SelectorCell)}>
                <Checkbox
                  checked={
                    settings[HomepageSampledModelType.FUNNEL][f.id] ===
                    HomepageSubscriptionPushType.NEVER
                  }
                  onChange={() =>
                    onChange(
                      f.id,
                      HomepageSampledModelType.FUNNEL,
                      HomepageSubscriptionPushType.NEVER
                    )
                  }
                  disabled={disabled}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
