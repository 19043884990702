import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import classes from './kpi-tree.module.scss';
import {
  ModelSeriesGranularity,
  SeriesDatetimeAttribute,
} from '../../../../objects/models/model-sample-series.model';
import {
  ConnectionLineType,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
// @ts-ignore
import {Node} from '@xyflow/react/dist/esm/types/nodes';
import {createdEdges, createNodes, getLayoutedElements} from './kpi-tree.utils';
import {
  DEFAULT_GRANULARITY,
  DISABLE_REACT_FLOW_INTERACTION_PROPS,
  edgeTypes,
  HIDE_REACT_FLOW_ATTRIBUTION,
  NODE_PLACEHOLDER_PORTAL_CLASS_NAME,
  nodeTypes,
  SNAP_GRID,
  TreeMode,
  TreeVariant,
} from './kpi-tree.consts';
import {KPITreeContext, KPITreeContextProvider} from './kpi-tree.context';
import {ExpandIcon, ModalLayout, useMountState, WarningIcon} from 'ui-components';
import {NodePosition} from '../../../../objects/models/metric-tree.model';
import {Modal} from '@material-ui/core';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {useDemoProduct} from '../../../../core/hooks/use-demo-product.hook';
import {useIsAdmin} from '../../../../core/hooks/use-is-admin.hook';
import {KPINodeData} from './components/kpi-node/kpi-node.component';
import {Metric} from '../../../../objects/models/metric.model';
import {KPIPositionPlaceholder} from './components/kpi-position-placeholder/kpi-position-placeholder.component';
// @ts-ignore
import {Connection} from '@xyflow/system/dist/esm/types/general';
import {KPITreeHeader} from './components/kpi-tree-header/kpi-tree-header.component';
import {EmptyStateFullMode} from './components/empty-state-full-mode/empty-state-full-mode.component';
import {EmptyStateSimpleMode} from './components/empty-state-simple-mode/empty-state-simple-mode.component';
import {ConfirmationDialogContext} from '../../../../core/contexts/confirmation-dialog.context';
import {TeamTreeSelector} from './components/tree-team-selector/tree-team-selector.component';
import {NumberParam, useQueryParam} from 'use-query-params';
import {TEAM_ID_PATH_PARAM} from '../../../../constants/app-routes';
import {useProductData} from '../../../../core/hooks/use-product-data.hook';
import {useNavigate} from 'react-router';

interface BaseKPITreeProps {
  metricId?: number;
  granularity?: ModelSeriesGranularity;
  showAdd?: boolean;
  showBack?: boolean;
  showMinimap?: boolean;
  isModal?: boolean;
  variant?: TreeVariant;
  autoLayout?: boolean;
  syncDateInParams?: boolean;
  className?: string;
}

interface KPITreeProps extends BaseKPITreeProps {
  openTreeModalHandler?: (metricId: number) => void;
  toDate?: string;
  onExpandTree?: (query?: any) => void;
  datetimeAttribute?: SeriesDatetimeAttribute;
  teamId?: number;
  setTeamId?: (teamId: number) => void;
}

interface KPITreeControllerProps extends BaseKPITreeProps {}

const KPITreeController = (props: KPITreeControllerProps) => {
  const {
    granularity = DEFAULT_GRANULARITY,
    showMinimap,
    variant = TreeVariant.FULL,
    autoLayout,
    showBack,
  } = props;
  const {
    metricTree,
    onAddInputKPI,
    mode,
    toDate,
    setMode,
    setToDate,
    onAddKPI: onAddKPIFromContext,
    onExpandTree,
    onChangeMetricPositions,
    teamId,
    onChangeTeam,
  } = useContext(KPITreeContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {teams} = useProductData();
  const {setConfirmationDialog} = useContext(ConfirmationDialogContext);
  const [metricToAdd, setMetricToAdd] = useState<Metric>(null);
  const isMounted = useMountState();
  const {isDemoProduct} = useDemoProduct();
  const isAdmin = useIsAdmin();
  const {fitView, getNode} = useReactFlow<Node<KPINodeData>>();

  const addDisabled = useMemo(() => isDemoProduct && !isAdmin, [isDemoProduct, isAdmin]);
  const initialNodes: Node[] = useMemo(
    () => createNodes(metricTree, variant, granularity),
    [metricTree, variant, granularity]
  );
  const initialEdges = useMemo(() => createdEdges(metricTree, variant), [metricTree, variant]);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);

  const onAddKPI = useCallback((metric: Metric) => setMetricToAdd(metric), [setMetricToAdd]);
  const onSelectNewMetricLocation = useCallback(
    (position: NodePosition) => {
      setMetricToAdd(null);
      onAddKPIFromContext(metricToAdd.id, position);
    },
    [onAddKPIFromContext, setMetricToAdd, metricToAdd]
  );
  const onSelectMetricFromEmptyState = useCallback(
    (metric: Metric) => onAddKPIFromContext(metric.id, {x: 0, y: 0}),
    [onAddKPIFromContext]
  );
  const updateNodePositions = useCallback(
    (nodes: Node<KPINodeData>[]) => {
      const items = nodes.reduce((acc, node) => {
        acc.push({
          metricId: node.data.id,
          position: node.position,
        });
        return acc;
      }, []);
      onChangeMetricPositions(items);
    },
    [onChangeMetricPositions]
  );
  const onAutoLayout = useCallback(() => {
    const {nodes, edges} = getLayoutedElements(initialNodes, initialEdges);
    updateNodePositions(nodes);
    setNodes(nodes);
    setEdges(edges);
  }, [initialNodes, initialEdges, setNodes, setEdges, updateNodePositions]);
  const onAutoLayoutConfirmed = useCallback(() => {
    setConfirmationDialog({
      title: t(TransKeys.KPI_TREE.AUTO_LAYOUT_CONFIRMATION.TITLE),
      content: t(TransKeys.KPI_TREE.AUTO_LAYOUT_CONFIRMATION.CONTENT),
      onApprove: () => onAutoLayout(),
    });
  }, [setConfirmationDialog, onAutoLayout, t]);
  const onNodeDragStop = useCallback(
    (e, node) => updateNodePositions([node]),
    [updateNodePositions]
  );
  const onConnect = useCallback(
    (data: Connection) => {
      const targetMetric = getNode(data.target);
      const sourceMetric = getNode(data.source);
      onAddInputKPI(targetMetric.data.id, sourceMetric.data.id);
    },
    [getNode, onAddInputKPI]
  );
  const emptyState = metricTree.metrics.length === 0;
  const kpiMissingInTreeText = useMemo(() => {
    if (!metricTree.rootMetricId || metricTree.rootExistsInTree === true) {
      return null;
    }
    if (metricTree.rootMetricIdTeams && metricTree.rootMetricIdTeams.length > 0) {
      const teamNames = metricTree.rootMetricIdTeams
        .map(teamId => {
          if (teamId === null) {
            return '"All"';
          }
          return `"${teams.find(team => team.id === teamId)?.name}"`;
        })
        .join(', ');
      return t(TransKeys.KPI_TREE.KPI_NOT_IN_TREE_NOTICE_HAS_OTHER_TREES, {
        other_trees: teamNames,
        unit: metricTree.rootMetricIdTeams.length > 1 ? 'trees' : 'tree',
      });
    }
    return t(TransKeys.KPI_TREE.KPI_NOT_IN_TREE_NOTICE);
  }, [metricTree, t, teams]);
  useEffect(() => {
    if (autoLayout && variant === TreeVariant.SIMPLE) {
      const {nodes, edges} = getLayoutedElements(initialNodes, initialEdges);
      setNodes(nodes);
      setEdges(edges);
      return;
    }
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialNodes, initialEdges, autoLayout, variant, setNodes, setEdges]);
  useEffect(() => {
    setTimeout(() => {
      isMounted &&
        fitView({
          duration: 100,
        });
    }, 100);
  }, [metricTree, isMounted, fitView]);

  return (
    <>
      {variant === TreeVariant.FULL && (
        <KPITreeHeader
          mode={mode}
          setMode={setMode}
          onAutoLayout={onAutoLayoutConfirmed}
          onAddKPI={onAddKPI}
          onBack={() => navigate(-1)}
          addDisabled={addDisabled}
          date={toDate}
          setDate={setToDate}
          emptyState={emptyState}
          teamId={teamId}
          onChangeTeam={onChangeTeam}
          className={classes.TreeHeader}
          showBack={showBack}
        />
      )}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineType={ConnectionLineType.SimpleBezier}
        maxZoom={1}
        minZoom={0.2}
        className={classes.ReactFlow}
        onNodesChange={onNodesChange}
        onConnect={onConnect}
        onNodeDragStop={onNodeDragStop}
        nodesDraggable={mode === TreeMode.EDIT}
        nodesConnectable={mode === TreeMode.EDIT}
        elementsSelectable={false}
        snapGrid={SNAP_GRID}
        snapToGrid
        fitView
        onlyRenderVisibleElements
        {...(variant === TreeVariant.SIMPLE || emptyState
          ? DISABLE_REACT_FLOW_INTERACTION_PROPS
          : {})}
        {...HIDE_REACT_FLOW_ATTRIBUTION}
      >
        {variant === TreeVariant.SIMPLE && (
          <Panel position="top-left">
            <TeamTreeSelector teamId={teamId} onChangeTeam={onChangeTeam} />
          </Panel>
        )}
        {kpiMissingInTreeText && (
          <Panel position={'bottom-left'}>
            <div className={classes.RootNotExistsWarn}>
              <WarningIcon />
              <span>{kpiMissingInTreeText}</span>
            </div>
          </Panel>
        )}
        {emptyState && (
          <div className={classes.EmptyState}>
            {variant === TreeVariant.FULL && (
              <EmptyStateFullMode onSelectMetric={onSelectMetricFromEmptyState} />
            )}
            {variant === TreeVariant.SIMPLE && (
              <EmptyStateSimpleMode onDefineTree={() => onExpandTree()} />
            )}
          </div>
        )}
        <div className={NODE_PLACEHOLDER_PORTAL_CLASS_NAME} />
        {metricToAdd && (
          <KPIPositionPlaceholder metric={metricToAdd} onClick={onSelectNewMetricLocation} />
        )}
        {showMinimap && <MiniMap nodeStrokeWidth={3} />}
        {variant === TreeVariant.SIMPLE && (
          <Panel position="top-right">
            <div className={classes.ExpendButton} onClick={() => onExpandTree()}>
              <ExpandIcon />
              {t(TransKeys.KPI_TREE.ACTIONS.EXPAND_TREE)}
            </div>
          </Panel>
        )}
        <Controls showInteractive={false} />
      </ReactFlow>
    </>
  );
};

export const KPITree = (props: KPITreeProps) => {
  const {
    metricId,
    isModal,
    openTreeModalHandler,
    onExpandTree: onExpandTreeFromProps,
    toDate,
    datetimeAttribute,
    autoLayout,
    syncDateInParams,
    teamId: teamIdFromProps,
    setTeamId: setTeamIdFromProps,
    className,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [modalMetricId, setModalMetricId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // team id management
  const [teamId_, setTeamId_] = useQueryParam(TEAM_ID_PATH_PARAM, NumberParam);
  const teamId = teamIdFromProps || teamId_ || null;
  const setTeamId = setTeamIdFromProps || setTeamId_;
  const onChangeTeam = useCallback(
    teamId => setTeamId(teamId === null ? undefined : teamId, 'replace'),
    [setTeamId]
  );
  const onViewKPIGroup = useCallback(
    (metricId: number) => {
      if (openTreeModalHandler) {
        openTreeModalHandler(metricId);
        return;
      }
      if (!isModal) {
        setShowModal(true);
      }
      setModalMetricId(metricId);
    },
    [openTreeModalHandler, setModalMetricId, isModal]
  );
  const onExpandTree = useCallback(() => {
    if (onExpandTreeFromProps) {
      onExpandTreeFromProps({teamId});
      return;
    }
    if (isModal && openTreeModalHandler) {
      openTreeModalHandler(null);
      return;
    }
    setShowModal(true);
    setModalMetricId(null);
  }, [
    onExpandTreeFromProps,
    openTreeModalHandler,
    isModal,
    teamId,
    setModalMetricId,
    setShowModal,
  ]);

  return (
    <>
      <div className={classNames(classes.KPITree, className)} key={metricId}>
        <div className={classes.Content} ref={containerRef}>
          <ReactFlowProvider>
            <KPITreeContextProvider
              containerRef={containerRef}
              metricId={metricId}
              onViewKPIGroup={onViewKPIGroup}
              onExpandTree={onExpandTree}
              datetimeAttribute={datetimeAttribute}
              syncDateInParams={syncDateInParams}
              teamId={teamId}
              onChangeTeam={onChangeTeam}
              toDate={toDate}
            >
              <KPITreeController {...props} />
            </KPITreeContextProvider>
          </ReactFlowProvider>
        </div>
      </div>
      {showModal && (
        <Modal
          onClick={e => e.stopPropagation()}
          className={classes.ModalWrapper}
          open={showModal}
          onClose={() => {
            setShowModal(false);
            setModalMetricId(null);
          }}
        >
          <ModalLayout className={classes.TreeWrapper}>
            <KPITree
              metricId={modalMetricId}
              openTreeModalHandler={onViewKPIGroup}
              showBack={false}
              isModal={true}
              teamId={teamId}
              setTeamId={setTeamId}
              showMinimap={!modalMetricId}
              variant={modalMetricId ? TreeVariant.SIMPLE : TreeVariant.FULL}
              autoLayout={autoLayout}
              className={classes.Large}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};
