import {useEffect} from 'react';
import {CSSProperties, forwardRef, InputHTMLAttributes, useState} from 'react';
import classes from './text-input.module.scss';
import {last, toNumber} from 'lodash';
import {exists} from 'front-core';
import classNames from 'classnames';
import {InputChipInline} from '../../related/input-chip-inline/input-chip-inline.component';
import {InputType} from '../../inputs.types';
import {CloseIcon} from '../../../simple/controls/icons/icons.component';
import {InputButton} from '../../related/input-button/input-button.component';

interface OwnProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur' | 'size'> {
  onChange?: (value: number | string | number[] | string[]) => void;
  onBlur?: (value: number | string | number[] | string[]) => void;
  ref?: any;
  fullWidth?: boolean;
  size?: 'small' | 'large';
  multiple?: boolean;
  unique?: boolean;
  style?: CSSProperties;
  error?: boolean;
  icon?: any;
  leftIcon?: any;
  clearable?: boolean;
  placeholderIsValue?: boolean;
  inputClassName?: string;
  iconClassName?: string;
  rounded?: boolean;
}

export const TextInput: React.FC<OwnProps> = forwardRef((props: OwnProps, ref) => {
  const {
    type,
    placeholder,
    placeholderIsValue,
    onChange,
    onBlur: onBlurFromProps,
    value,
    fullWidth,
    className,
    inputClassName,
    iconClassName,
    style,
    multiple,
    unique,
    disabled,
    error,
    onClick,
    maxLength,
    autoFocus,
    clearable,
    step,
    rounded,
    size = 'small',
    icon: Icon,
    leftIcon: LeftIcon,
  } = props;
  const [intermediateValue, setIntermediateValue] = useState('');

  const onChange_ = e => {
    let value = e.target.value;
    if (type === 'number' && !isNaN(value) && value !== '') {
      value = toNumber(value);
    }
    if (type === 'number' && value === '') {
      value = null;
    }
    if (multiple) {
      return setIntermediateValue(value);
    }
    onChange && onChange(value);
  };

  const getValue = () => (multiple ? intermediateValue : exists(value) ? value : '');

  const onKey = e => {
    if (!multiple) {
      return;
    }
    const arrValue: any = value || [];
    e.preventDefault();
    e.stopPropagation();

    if (e.code === 'Enter') {
      if (intermediateValue === '') {
        return;
      }
      let change = [...arrValue, intermediateValue];
      if (unique) {
        change = Array.from(new Set([...arrValue, intermediateValue]));
      }
      onChange(change);
      setIntermediateValue('');
    }
    if (e.code === 'Backspace') {
      if (intermediateValue !== '') {
        return;
      }
      const change = new Set([...arrValue]);
      change.delete(last(arrValue));
      onChange(Array.from(change));
    }
  };

  const onDeleteItem = v => {
    const arrValue: any[] = multiple ? [...(value as any)] : [];
    const change = new Set(arrValue);
    change.delete(v);
    onChange(Array.from(change));
  };

  const onClear = e => {
    e.stopPropagation();
    return clearable && onChange && onChange(null);
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (placeholder && placeholderIsValue && e.target.value === placeholder) {
      onChange(null);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (placeholder && placeholderIsValue && !e.target.value) {
      onChange(placeholder);
      onBlurFromProps && onBlurFromProps(placeholder);
    }
    if (!multiple) {
      onBlurFromProps && onBlurFromProps(value as any);
      return;
    }
    const arrValue: any = value || [];
    e.preventDefault();
    e.stopPropagation();

    let change = [...arrValue, intermediateValue];
    if (unique) {
      change = Array.from(new Set([...arrValue, intermediateValue]));
    }
    onChange(change.filter(Boolean));
    onBlurFromProps && onBlurFromProps(change);
    setIntermediateValue('');
  };

  useEffect(() => {
    if (placeholder && placeholderIsValue && !value) {
      onChange(placeholder);
    }
  }, [placeholder, placeholderIsValue]);

  return (
    <div
      style={style}
      onClick={onClick}
      className={classNames(
        classes.TextInput,
        fullWidth && classes.FullWidth,
        multiple && classes.Multiple,
        disabled && classes.Disabled,
        error && classes.Error,
        rounded && classes.Rounded,
        size === 'small' && classes.Small,
        size === 'large' && classes.Large,
        className
      )}
    >
      {Icon && <Icon className={classNames(classes.Icon, iconClassName)} />}
      {multiple &&
        (value as any)?.map((v, idx) => (
          <InputChipInline
            key={`${v}_${idx}`}
            className={classes.InputChipInline}
            value={v}
            inputType={InputType.FREE_TEXT}
            onDelete={() => onDeleteItem(v)}
            editable={!disabled}
          />
        ))}
      <input
        disabled={disabled}
        onKeyUp={onKey}
        ref={ref as any}
        className={classNames(
          classes.Input,
          disabled && classes.Disabled,
          clearable && classes.Clearable,
          inputClassName
        )}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange_}
        value={getValue()}
        placeholder={placeholder}
        type={type}
        autoFocus={autoFocus}
        maxLength={maxLength}
        step={step}
        min={props.min}
        max={props.max}
      />
      {multiple && intermediateValue !== '' && <span className={classes.Helper}>Enter</span>}
      {/* TODO! offer support for clearing a multiple Text-Input as currently it looks bad */}
      {!multiple && clearable && Boolean(getValue()) && (
        <InputButton icon={CloseIcon} onClick={onClear} />
      )}
      {LeftIcon && <LeftIcon className={classes.LeftIcon} />}
    </div>
  );
});

TextInput.defaultProps = {
  fullWidth: true,
  multiple: false,
  unique: true,
};
