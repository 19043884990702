import {SampledModelSentences} from '../../../../../../../../../insights/rca/sampled-model-sentences/objects';
import sharedClasses from '../../rca-executive-summery.module.scss';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {TrendModelSampleInsightFormatter} from '../../../../../../../../../insights/rca/sampled-model-sentences/trend-model-sample-insight.component.tsx';
import {PeriodAllTimeHighOrLowModelSampleInsightFormatter} from '../../../../../../../../../insights/rca/sampled-model-sentences/period-all-time-high-or-low-model-sample-insight.component.tsx';
import {NumberOfUsersModelSampleInsightFormatter} from '../../../../../../../../../insights/rca/sampled-model-sentences/number-of-users-model-sample-insight.component.tsx';
import {ProductEntities} from '../../../../../../../../../../objects/models/product-data.model';

interface OwnProps extends SampledModelSentences {
  productEntities: ProductEntities;
}

type AllProps = OwnProps;

export const SampledModelSentencesSection = (props: AllProps) => {
  const {trendInsight, periodAllTimeHighOrLowInsight, numberOfUsersInsight, productEntities} =
    props;
  const {t} = useTranslation();

  if (
    [trendInsight, periodAllTimeHighOrLowInsight, numberOfUsersInsight].every(insight => !insight)
  ) {
    return null;
  }

  return (
    <div className={sharedClasses.ExecutiveSummarySection}>
      <div className={sharedClasses.SubTitle}>
        {t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.SAMPLE_SENTENCES_TITLE)}
      </div>
      <div className={sharedClasses.MetricModelSampleSentencesList}>
        {trendInsight && (
          <>
            <span className={sharedClasses.Bullet} />
            <TrendModelSampleInsightFormatter {...trendInsight} />
          </>
        )}
        {periodAllTimeHighOrLowInsight && (
          <>
            <span className={sharedClasses.Bullet} />
            <PeriodAllTimeHighOrLowModelSampleInsightFormatter {...periodAllTimeHighOrLowInsight} />
          </>
        )}
        {numberOfUsersInsight && (
          <>
            <span className={sharedClasses.Bullet} />
            <NumberOfUsersModelSampleInsightFormatter
              {...numberOfUsersInsight}
              productEntities={productEntities as ProductEntities}
              sentenceKey={numberOfUsersInsight.key}
            />
          </>
        )}
      </div>
    </div>
  );
};
