import telescope from './telescope.svg';
import telescopeV2 from './telescope-v2.svg';
import logoMini from './logo-mini.svg';
import googleLogo from '../../assets/images/logos/google.svg';
import microsoftLogo from '../../assets/images/logos/ms-logo.svg';
import oktaLogo from './logos/okta.svg';

export const ImagesIndex = {
  telescope,
  telescopeV2,
  logoMini,
  companiesLogos: {
    googleLogo,
    microsoftLogo,
    oktaLogo,
  },
};
