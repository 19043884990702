import {
  DocumentComponentsMap,
  DocumentViewerMode,
  ViewerComponentMode,
} from '../../document-viewer.types';
import * as React from 'react';
import {DocumentElement} from '../../types';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {DocumentViewerContext} from '../../contexts/document-viewer.context';
import {UnsupportedViewer} from '../internal-viewers/unsupported-viewer.component';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from '../../../../simple/errors/generic.error';
import {DocumentViewerOptions} from './components/document-viewer-options/document-viewer-options.component';
import classes from '../viewers/viewer.module.scss';
import {AcceptedChildren} from './child-renderer.component';
import {useScreenTracking} from '../../hooks/use-screen-tracking.hook';
import {DocumentCommandEmitterContext} from '../../contexts/document-command-emitter.context';

interface SingleChildRendererProps {
  id?: string;
  // children_ since children is a saved keyword
  children_?: AcceptedChildren;
  index?: number;
  number?: number;
  className?: string;
  // will override the context components map
  componentsMap?: DocumentComponentsMap;
}

export const SingleChildRenderer: React.FC<SingleChildRendererProps> = (
  props: SingleChildRendererProps
) => {
  const {id, number, index, className, componentsMap} = props;
  let children: DocumentElement = props.children_ as any;
  const {
    viewerMode,
    parameters,
    onChangeParameters,
    componentsMap: componentsMapFromContext,
    darkMode,
    showHidden,
  } = useContext(DocumentViewerContext);
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const [componentMode, setComponentMode] = useState(ViewerComponentMode.DEFAULT_MODE);
  const [debuggedChild, setDebuggedChild] = useState(null);
  const ref = useRef<HTMLElement>(null);
  useScreenTracking(id, ref, children);

  const Component: any = {...componentsMapFromContext, ...componentsMap}[children.type];
  // Computed
  const elementParameters = useMemo(() => parameters[children.id] || {}, [parameters, children]);
  const componentOptions = useMemo(() => {
    const childOptions = (children.options as any) || {};
    const overrideOptions = elementParameters.override?.options || {};
    return {
      ...childOptions,
      ...overrideOptions,
    };
  }, [children.options, elementParameters]);
  const onComponentChangedParameters = useCallback(
    (params: any) => {
      onChangeParameters(children.id, {override: params});
    },
    [children.id, onChangeParameters]
  );
  const shouldRenderOptions = useMemo(() => children.type[0] !== '_', [children]);
  const onReset = useCallback(() => setDebuggedChild(null), []);
  const renderedChildren = useMemo(() => debuggedChild || children, [children, debuggedChild]);
  const onDataChanged = useCallback(data => setDebuggedChild(data), []);

  if (!Component) {
    return <UnsupportedViewer type={children.type} />;
  }

  // if is hidden by elementParameters we need to hide it on view mode
  if (elementParameters.isHidden && viewerMode === DocumentViewerMode.VIEW_MODE) {
    return null;
  }
  // if the children is hidden (set directly on the children) we need to hide it
  // We will show it (only in backoffice) if the root flag (defined in DocumentViewer) is set to true
  if (children.isHidden && !showHidden) {
    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {shouldRenderOptions && (
        <DocumentViewerOptions
          className={classes.ExternalAdditions}
          element={renderedChildren}
          elementParameters={elementParameters}
          isMutated={renderedChildren === debuggedChild}
          documentMode={viewerMode}
          componentMode={componentMode}
          onChangeParameters={change => onChangeParameters(renderedChildren.id, change)}
          onChangeViewerMode={mode => setComponentMode(mode)}
          onDataChanged={onDataChanged}
          onReset={onReset}
        />
      )}
      {/* Tracking element for scroll, since Component is not an HTML element */}
      {/* We need to add a forwardRef to the component to track it directly */}
      <span ref={ref} style={{margin: 0, padding: 0, width: 0, height: 0}} />
      <Component
        {...props}
        {...renderedChildren}
        emitEvent={e => emitEvent(e)}
        options={componentOptions}
        children={renderedChildren.children}
        componentMode={componentMode}
        changeParameters={onComponentChangedParameters}
        index={index}
        number={number}
        darkMode={darkMode}
      />
    </ErrorBoundary>
  );
};
